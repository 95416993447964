<div class="header" (click)="toggleExpand()">
    <div class="icon" [class.expanded]="isExpanded">
        <div class="arrow"></div>
    </div>
    <div class="label">{{label}}</div>
    <div class="values">
        <div *ngFor="let key of salesKeys(fixShares(rollups))" [attr.data-sales-date]="key">
            <div *ngIf="getType(rollups[key]?.shares) === 'number'" class="value-shares">{{rollups[key].shares | SigFig}}%</div>
            <div *ngIf="getType(rollups[key]?.shares) !== 'number'" class="value-shares">{{rollups[key].shares}}</div>
            <div *ngIf="showVolume && rollups[key]?.sales !== undefined" class="value-sales">{{rollups[key].sales}}
            </div>
        </div>
    </div>
</div>
<div class="body" [class.transitioning]="transitioning" [class.expanded]="isExpanded">
    <ng-container *ngIf="isExpanded || renderElWhenCollapsed">
        <div *ngFor="let item of sales" class="row" [attr.data-vehicle-model]="item.label">
            <div class="label">{{item.label}}</div>
            <div class="values">
                <div *ngFor="let key of salesKeys(fixShares(item.sales))" [attr.data-sales-date]="key">
                    <div *ngIf="getType(item.sales[key]?.shares) === 'number'" class="value-sales">{{item.sales[key].shares | SigFig}}%</div>
                    <div *ngIf="getType(item.sales[key]?.shares) !== 'number'" class="value-sales">{{item.sales[key].shares}}</div>
                    <div *ngIf="showVolume && item.sales[key]?.sales !== undefined" class="value-sales">{{item.sales[key].sales}}</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
