<div dPopup id="{{!zipZoneMode ? 'zipsales-popup' : 'zonesales-popup'}}" class="zip-sales-popup map-popup">
    <ng-container *ngIf="zips">
        <div class="result-title noselect move-handle">
            <div class="zip-sales-popup-close close" (click)="popup.closePopup()">
                <div class="icon"></div>
            </div>
            <span id="zipcode:{{zips}}" *ngIf="!zipZoneMode">Sales in Zip Code {{zips}}</span>
            <span id="adzone:{{zipZoneDetails.ad_zone}}" *ngIf="zipZoneMode && zipZoneDetails">Sales in Ad Zone
                {{zipZoneDetails.ad_zone}}</span>
        </div>
        <div class="popup-scrollable">
            <div class="zip-details">
                <div *ngIf="!zipZoneMode">
                    <span class="header">Ad Zone:</span>
                    <span *ngIf="zipZoneDetails">{{zipZoneDetails.ad_zone}}</span>
                </div>
                <div *ngIf="zipZoneMode">
                    <span class="header">Zips:</span>
                    <span>{{zips.length}} Zip Codes Shown</span>
                </div>
                <span class="header">Registration DMA:</span>
                <span *ngIf="zipZoneDetails">{{getDmaNames(zipZoneDetails.dmas).join(", ")}}</span>
                <br />
            </div>
            <div class="zip-sales">
                <table class="results-data-table">
                    <thead>
                        <tr>
                            <th></th>
                            <ng-container *ngIf="dates.length > 0; else loadDates">
                                <th *ngFor="let date of dates" class="data">
                                    <pre>{{formatDate(date)}}</pre>
                                </th>
                            </ng-container>
                            <ng-template #loadDates *ngIf="!useCustomDates">
                                <th class="data">
                                    <div class="loading"></div>
                                </th>
                                <th class="data">
                                    <div class="loading"></div>
                                </th>
                                <th class="data">
                                    <div class="loading"></div>
                                </th>
                            </ng-template>
                        </tr>
                    </thead>
                    <ng-container *ngIf="dealerSales.length > 0 || dateGroupSales.length > 0; else loading">
                        <ng-container *ngIf="dealerSales.length > 0 && !useCustomDates;">
                            <tbody *ngFor="let dealer of dealerSales; let last = last">
                                <tr>
                                    <th id="{{dealer.dealer_id}}-name">{{getDealerName(dealer.dealer_id)}}</th>
                                    <ng-container *ngFor="let date of dates">
                                        <td id="{{dealer.dealer_id}}-{{date}}-zzp-entry" class="data">{{showVolume ?
                                            (valueFor(dealer, [date]) || 0) : (valueFor(dealer, [date]) || 0 | SigFig) +
                                            "%"}}</td>
                                    </ng-container>
                                </tr>
                                <tr *ngIf="!last">
                                    <td colspan="4" class="break-row">
                                        <div class="break-line-short"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </ng-container>
                        <ng-container *ngIf="dateGroupSales.length > 0 && useCustomDates;">
                            <tbody *ngFor="let dealer of dateGroupSales; let last = last">
                                <tr>
                                    <th id="{{dealer.dealer_id}}-name">{{getDealerName(dealer.dealer_id)}}</th>
                                    <ng-container *ngFor="let date of dates">
                                        <td id="{{dealer.dealer_id}}-{{date}}-zzp-entry" class="data">{{showVolume ?
                                            (valueForCustom(dealer, [date]) || 0) : (valueForCustom(dealer, [date]) || 0 | SigFig) +
                                            "%"}}</td>
                                    </ng-container>
                                </tr>
                                <tr *ngIf="!last">
                                    <td colspan="4" class="break-row">
                                        <div class="break-line-short"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </ng-container>
                    </ng-container>
                    <ng-template #loading>
                        <ng-container *ngTemplateOutlet="loadingTable"></ng-container>
                        <ng-container *ngTemplateOutlet="loadingTable"></ng-container>
                    </ng-template>

                    <ng-template #loadingTable>
                        <tbody>
                            <tr>
                                <th>
                                    <div class="loading wide"></div>
                                </th>
                                <td class="data">
                                    <div class="loading"></div>
                                </td>
                                <td class="data">
                                    <div class="loading"></div>
                                </td>
                                <td class="data">
                                    <div class="loading"></div>
                                </td>
                            </tr>
                        </tbody>
                    </ng-template>
                </table>
            </div>
        </div>
    </ng-container>
</div>
