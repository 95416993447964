import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DateUtils } from "@at/utils";
import { ChartDatasetElement, ChartLabelElement, DataSet, DataSetGroup } from "app/core/models/chart-data.model";
import { ChartPopupData, ChartPopupDealerModelSales } from "app/core/models/chart-popup.model";
import { DealerSales, ModelItemDetail } from "app/core/models/dealer-sales";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { ChartPopupService } from "app/core/services/chart-popup.service";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { cloneDeep, delay, merge, sortBy, uniqBy } from "lodash";
import * as moment from "moment";
import { Observable, Subscription, combineLatest, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { DateRangeTypes } from "../../../internal";
import { ChartColor } from "../../charts/chart/chart-color.utils";
import { MAX_BAR_THICKNESS, MIN_BAR_THICKNESS, SPACE_BETWEEN_GROUP } from "../../charts/chart/chart-defaults.options";
import { ChartComponent } from "../../charts/chart/chart.component";
import { DateRange, DateShiftComponent } from "../../charts/date-shift/date-shift.component";
import { CardUtils } from "../card/card.utils";

@Component({
    selector: "individual-model-sales-shares-card",
    templateUrl: "./individual-model-sales-shares-card.component.html",
    styleUrls: ["./individual-model-sales-shares-card.component.scss"]
})
export class IndividualModelSalesCardComponent implements OnInit, OnDestroy {
    @ViewChild(DateShiftComponent, { static: true }) dateShift: DateShiftComponent;
    @ViewChild(ChartComponent) chart: ChartComponent;

    @Input() type: "sales" | "shares";
    @Input() weeklyDataSelectorEnabled = false;

    isCardExpanded = false;
    isCardLoading = false;

    private cache: DealerSales[] = undefined;
    // Needs to support rgb strings and Canvas Pattern Objects.
    private colors: any[] = ChartColor.getColorPalette();
    private loadingDealerDetailsSub: Subscription;
    private spotlightDetailsSub: Subscription;
    private filterStateSub: Subscription;
    private datastream: Subscription;
    private resizeSub: Subscription;
    private dealers: Dealer[] = [];

    private dateRange: DateRange;
    private spotlightId: number;

    chartType = "bar";
    chartData: DataSet[];
    chartColors: string[] = [];
    chartLabels: string[][];
    chartLegends: DataSetGroup[];
    limitToDisplay = 12;
    useSalesData: boolean;
    oldestDate = "";
    private minDate;
    private maxDate;

    get title(): string {
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
        this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);
        let suffix = "";
        if (this.dateRange && this.weeklyDataSelectorEnabled) {
            if(this.useSalesData){

                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.ROLLING12){
                    let momentMax = moment(moment(this.maxDate).toDate().setDate(1));
                    suffix = `: ${moment(momentMax.toDate().setMonth(momentMax.toDate().getMonth() - 11)).format("MMM DD, YYYY")} - ${moment(this.maxDate).format("MMM DD, YYYY")},
                    ${moment(this.maxDate).toDate().getFullYear() - 1}, ${moment(this.maxDate).toDate().getFullYear() - 2}
                    `;
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.YTD){
                    let fullWeekDateRange = DateUtils.popUpDateRange(this.useSalesData, this.maxDate,  DateRangeTypes.YTD.toString(),
                        moment(this.minDate).toDate(),  moment(this.maxDate).toDate());

                    suffix = ": " +moment(fullWeekDateRange.split("-")[0]).format("MMM DD, YYYY") + " - " +
                        moment(fullWeekDateRange.split("-")[1]).format("MMM DD, YYYY") + ", "+
                        (moment(fullWeekDateRange.split("-")[1]).toDate().getFullYear() - 1) +", "+
                        (moment(fullWeekDateRange.split("-")[1]).toDate().getFullYear() - 2);
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === "calendar"){
                    let firstOfYear = moment(this.maxDate).toDate().getFullYear()+"0101";
                    suffix = ": " + moment(firstOfYear).format("YYYY") + ", " + (moment(firstOfYear).toDate().getFullYear() - 1) + ", "
                        +(moment(firstOfYear).toDate().getFullYear() -2);
                } if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.MONTHS){
                    let dateRanges: any[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
                    if(dateRanges.length>0){
                        let stringDate = dateRanges[0] + " - " + dateRanges[1];
                        let fullWeekDateRange = DateUtils.popUpDateRange(this.useSalesData, stringDate, this.filterStateService.getFilterValue(FilterName.dateRangeType),
                            moment(this.maxDate).toDate(),  moment(this.maxDate).toDate());
                        suffix = ": " + moment(fullWeekDateRange.split("-")[0]).format("MMM DD, YYYY") + " - " + moment(fullWeekDateRange.split("-")[1]).format("MMM DD, YYYY");
                    }
                }
            } else{

                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.ROLLING12){
                    suffix = ": " + moment(this.dateRange.start).format("MMM YYYY") + " - " + moment(this.dateRange.end).format("MMM YYYY") +
                        `, ${moment(this.dateRange.end).toDate().getFullYear() - 1}, ${moment(this.dateRange.end).toDate().getFullYear() - 2}`;
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.YTD){
                    let startDate = moment(new Date( moment(this.maxDate).toDate().getFullYear(),0,1));
                    suffix = ": " +moment(startDate).format("MMM  YYYY") + " - " +
                        moment(this.maxDate).format("MMM YYYY") + ", "+
                        (moment(this.maxDate).toDate().getFullYear() - 1) +", "+
                        (moment(this.maxDate).toDate().getFullYear() - 2);
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === "calendar"){
                    let startDate = moment(new Date( moment(this.maxDate).toDate().getFullYear(),0,1));
                    suffix = ": " +
                        moment(startDate).format("YYYY") + ", " +
                        (moment(this.maxDate).toDate().getFullYear() - 1) +", "+
                        (moment(this.maxDate).toDate().getFullYear() - 2);
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.MONTHS){
                    const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
                    if(dateRanges.length>0){
                        const orig = DateUtils.dateDisplay([dateRanges[1], dateRanges[0]], DateRangeTypes.MONTHS).split("-");
                        const pretty = `${orig[0].slice(0, 3)} - ${orig[1]}`;
                        suffix = `: ${pretty}, ${DateUtils.getPreviousTwoYears(dateRanges[1])}`;
                    }
                }

            }
        }
        return `${(this.type === "sales" ? "Sales" : "Share")} by Model ${suffix}`;
    }

    get description(): string {
        return `Filter to five or fewer dealers and three or fewer models to view individual models ${(this.type === "sales" ? "sales" : "share")}.`;
    }

    get isDescriptionDisabled(): boolean {
        if (this.dealers.length === 0 || (!this.spotlightId && this.dealers.length > 5) || (this.spotlightId && this.dealers.length > 6)) {
            return false;
        }
        const models = this.filterStateService.getFilterValue(FilterName.models) as string[];
        if (models.length === 0 || models.length > 3) {
            return false;
        }
        return true;
    }

    get isCardDisabled(): boolean {
        if (this.dealers.length === 0 || (!this.spotlightId && this.dealers.length > 5) || (this.spotlightId && this.dealers.length > 6)) {
            return true;
        }
        const models = this.filterStateService.getFilterValue(FilterName.models) as string[];
        if (models.length === 0 || models.length > 3) {
            return true;
        }
        return false;
    }

    constructor(
        private salesDataService: SalesDataService,
        private dealerDataService: DealerDataService,
        private filterStateService: FilterStateService,
        private dmaDataService: DmaDataService,
        private chartPopupService: ChartPopupService
    ) {
        // Update this chart when the window is resize to recalculate the month segmentation.
        this.resizeSub = fromEvent(window, "resize").pipe(
            debounceTime(500))
            .subscribe(this.update.bind(this));
    }

    ngOnInit(): void {
        this.loadingDealerDetailsSub = this.dealerDataService.loadingDealerDetails.pipe(debounceTime(100)).subscribe(this.dealersUpdating.bind(this));
        this.filterStateSub = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.spotlightDetailsSub = this.dealerDataService.spotlightDealerDetails.subscribe(this.spotlightUpdated.bind(this));
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
        this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);

    }

    cardOpened(): void {
        this.isCardExpanded = true;
        this.chartData = [];
        this.dateShift.setDatesToDefault(true);
        // Delay update until next cycle to have the card open so that width can be calculated.
        delay(this.update.bind(this), 0);
    }

    ngOnDestroy(): void {
        this.filterStateSub.unsubscribe();
        this.loadingDealerDetailsSub.unsubscribe();
        this.spotlightDetailsSub.unsubscribe();
        this.resizeSub.unsubscribe();
        this.closeStream();
    }

    cardClosed(): void {
        this.isCardExpanded = false;
    }

    onChartLabelClick(element: ChartLabelElement): void {
        // [MMM, YYYY] => "YYYYMMM"
        const selectedDate = moment(element.label[1] + element.label[0], "YYYYMMM").format("YYYYMM");
        const fetchObj = CardUtils.buildChartModalData(this.salesDataService, this.dealers, selectedDate);

        const stream = new Subscription();
        const processData = (dealerSales: DealerSales[]) => {
            const selectedModels = this.filterStateService.getFilterValue(FilterName.models) as string[];
            const data = {
                position: element.position,
                title: `${moment(element.label[0], "MMM").format("MMMM")} Sales`,
                columns: fetchObj.yearLabels.map(l => l.slice(0, 4)),
                display: this.type,
                type: "models",
                dealers: this.buildModelsValue(this.dealers, dealerSales, fetchObj.yearLabels, selectedModels, true),
                datastream: stream
            } as ChartPopupData;

            this.chartPopupService.showModal(data);
        };

        stream.add(new Observable(o => {
            o.next([]);
            combineLatest(fetchObj.requests).pipe().subscribe(data => o.next(data));
        }).subscribe(processData));
    }

    onChartDatasetClick(element: ChartDatasetElement): void {
        // [MMM, YYYY] => "YYYYMMM"
        const selectedDate = moment(element.label[1] + element.label[0], "YYYYMMM").format("YYYYMM");
        const dealers = [this.dealers.find(d => d.dealer_id === element.datasetMeta[element.datasetIndex].id)];
        const fetchObj = CardUtils.buildChartModalData(this.salesDataService, dealers, selectedDate);
        const stream = new Subscription();
        const processData = (dealerSales: DealerSales[]) => {


            const data = {
                position: element.position,
                title: `${moment(element.label[0], "MMM").format("MMMM")} ${element.model.datasetLabel} Sales`,
                titleMarker: element.model.backgroundColor,
                columns: fetchObj.yearLabels.map(l => l.slice(0, 4)),
                dealers: this.buildModelsValue(dealers, dealerSales, fetchObj.yearLabels, [element.model.datasetLabel], false),
                display: this.type,
                datastream: stream
            } as ChartPopupData;
            this.chartPopupService.showModal(data);
        };

        stream.add(new Observable(o => {
            o.next([]);
            combineLatest(fetchObj.requests).subscribe(data => o.next(data));
        }).subscribe(processData));
    }

    updateDateRange(range: DateRange): void {
        this.dateRange = range;
        this.chartLabels = this.updateLabels(range);
        this.update();
    }

    update(): void {
        // Don't attempt to update if card is not opened.
        if (!this.isCardExpanded) {
            return;
        }

        if (!this.cache) {
            this.fetchSales();
        } else {
            this.updateView(this.cache);
        }
    }

    fetchSales(): void {
        this.isCardLoading = true;

        const requests: Observable<DealerSales>[] = [];
        const months = [];
        for (let i = 0; i < 5; i++) {
            const params = {
                dateRangeType: "months",
                dateRangeEnd: DateUtils.monthYearAddMonths(this.dateShift.maxDate, (12 * -i)),
                optimizeDates: true
            };
            const dateRange = this.filterStateService.getFilterValue(FilterName.dateRanges);
            if (dateRange && this.filterStateService.getFilterValue(FilterName.dateRangeType) === "months") {
                params["dateRangeEnd"] = this.dateRange.end;
            }
            const verifyDates = DateUtils.previousYearInMonths(params.dateRangeEnd);
            months.push(...verifyDates);
            requests.push.apply(requests, this.salesDataService.getSalesForDealers(this.dealers, params).map(ds => {
                return CardUtils.broadcastWhenLoaded(ds, verifyDates, true);
            }));
        }

        this.closeStream();
        this.datastream = combineLatest<DealerSales[]>(requests).subscribe(allSales => {
            const salesData = uniqBy(allSales, "dealer_id");
            this.zeroOutSalesMakeModels(salesData, months);
            this.cache = salesData;
            this.updateView(salesData);
            this.isCardLoading = false;
        });
    }

    updateView(salesData: DealerSales[]): void {
        const cData = [];
        const cLegends = [];
        const currentDate = moment(this.dateRange.end, "YYYYMM");
        const models = this.filterStateService.getFilterValue(FilterName.models) as string[];
        let totalModels = 0;
        let spotlightColors;

        // Iterate over sales for all selected dealers.
        for (let i = 0; i < salesData.length; i++) {
            const sale = salesData[i] as DealerSales;
            const dealer = this.dealers.find(d => {
                return d.dealer_id === sale.dealer_id;
            });
            // Skip if the dealer if they were not found since the dealers could have been changed
            // while sales were loading.
            if (!dealer) {
                continue;
            }

            const isSpotlight = i === 0 && this.spotlightId && this.spotlightId === dealer.dealer_id;

            const legendGroup = {
                label: dealer.dealer_name,
                id: sale.dealer_id,
                dataset: [],
                spotlight: isSpotlight
            } as DataSetGroup;

            // Flatten make-models since the data here is "vehicle make" agnostic.
            const flatModels = this.flatModels(sale);

            // Iterate over vehicle models.
            for (let j = 0; j < models.length; j++) {
                const model = flatModels.find(m => m.model_description === models[j]);
                // Model will not exist if dealer does not sales this particular model.
                if (!model) {
                    continue;
                }
                totalModels++;

                const monthData = {
                    id: sale.dealer_id,
                    label: model.model_description,
                    fill: false,
                    data: [],
                    spotlight: isSpotlight,
                    categoryPercentage: 0.1,
                    barPercentage: 1.0,
                    barThickness: MIN_BAR_THICKNESS,
                    maxBarThickness: MAX_BAR_THICKNESS,
                    barSpacing: 15
                };

                // Create sales/shares map for chart for given date range.
                for (let k = this.limitToDisplay - 1; k >= 0; k--) {
                    const yearMonth = moment(currentDate).subtract(k, "months").format("YYYYMM");
                    // If no sales/shares data exist for month, fill it with 0;
                    monthData.data.push(model[this.type + "Monthly"][yearMonth] || 0);
                }
                cData.push(monthData);
                legendGroup.dataset.push(monthData);
            }
            cLegends.push(legendGroup);

            if (isSpotlight) {
                spotlightColors = ChartColor.getSpotlightPatterns(totalModels);
            }
        }

        const chartWidth = this.chart.elementRef.nativeElement.offsetWidth;
        let segmentWidth = this.calculateSegmentWidth(totalModels, MIN_BAR_THICKNESS, salesData.length - 1);
        let segmentsCount = Math.floor((chartWidth - 100) / segmentWidth);

        // If less then the minimum of three segment (one quarter)
        // recalculate using smaller bar thickness to increase segments size.
        if (segmentsCount < 3) {
            segmentWidth = this.calculateSegmentWidth(totalModels, MAX_BAR_THICKNESS, salesData.length - 1);
            segmentsCount = Math.floor((chartWidth - 100) / segmentWidth);
        }

        // Segment data for viewing. This will show a smaller time frame.
        this.chartData = this.shrinkDataForChart(cData, segmentsCount);
        this.chartLabels = this.updateLabels(this.dateRange).slice(-segmentsCount);
        // If there is a spotlight dealer, minus spotlight colors from total models count.
        // Otherwise just get colors for total model.
        this.chartColors = this.colors = spotlightColors ?
            spotlightColors.concat(ChartColor.getColorPalette(totalModels - spotlightColors.length)) :
            ChartColor.getColorPalette(totalModels);
        this.chartLegends = cLegends;
    }

    dealersUpdating(loading: boolean): void {
        this.isCardLoading = loading;
        // Changes in dealer should always clear out the cache.
        this.cache = undefined;
        if (loading) {
            this.chart.resetState();
        } else {
            let dealers = this.dealerDataService.dealerDetails.value.slice();
            if (dealers.length === 0 || (!this.spotlightId && dealers.length > 5) || (this.spotlightId && dealers.length > 6)) {
                this.dealers = [];
                this.isCardExpanded = false;
            } else {
                // If multiple dma was selected, add dma suffix.
                if (this.filterStateService.isMultiDma()) {
                    dealers = CardUtils.appendDmaSuffix(dealers, this.dmaDataService);
                }

                dealers = sortBy(dealers, "dealer_name");
                // Move spotlight dealer to the front.
                if (this.spotlightId) {
                    const index = dealers.findIndex(d => d.dealer_id === this.spotlightId);
                    this.dealers = dealers.splice(index, 1).concat(dealers);
                } else {
                    this.dealers = dealers;
                }
            }
            this.update();
        }
    }

    filtersUpdated(filters: string[]): void {
        if (filters.length) {
            // Ignore the case where only the dealers filter is updated.
            // Ignore the case where only spotlight include/exclude is toggled.
            if (filters.length === 1 && (filters[0] === FilterName.dealers.toString() || filters[0] === FilterName.include_spotlight.toString())) {
                return;
            }

            // If models selections were changed, determine if card is still in valid state.
            if (filters.indexOf("models") !== -1) {
                const models = this.filterStateService.getFilterValue(FilterName.models) as string[];
                if (models.length === 0 || models.length > 3) {
                    this.cardClosed();
                    return;
                }
            }

            if (filters.includes("use_sales_data")) {
                this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
                this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate : FilterName.minDate);
                this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate);
                this.updateDateRange({ start: this.minDate, end: this.maxDate });
                this.dateShift.setDatesToDefault(true);
                // return;
            }

            if (filters.includes("dateRangeType") || filters.includes("dateRanges")) {
                const dateRange: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
                if (this.filterStateService.getFilterValue(FilterName.dateRangeType) !== "months") {
                    this.updateDateRange({ start: this.dateShift.minDate, end: this.dateShift.maxDate });
                    this.dateShift.setDatesToDefault(true);
                    return;
                } else if (dateRange && this.filterStateService.getFilterValue(FilterName.dateRangeType) === "months") {
                    this.updateDateRange({ start: dateRange[0], end: dateRange[1] });
                    this.dateShift.setDatesToDefault(true);
                    return;
                }
            }

            // Filters changed, reset all previous chart states.
            this.cache = undefined;
            this.chart.resetState();
            this.update();
        }
    }

    spotlightUpdated(dealer: Dealer): void {
        this.spotlightId = dealer ? dealer.dealer_id : undefined;
        this.update();
    }

    updateLabels(range: DateRange): string[][] {
        const labels = [];
        const currentDate = moment(range.end, "YYYYMM");
        for (let i = this.limitToDisplay - 1; i >= 0; i--) {
            const yearMonth = moment(currentDate).subtract(i, "months");
            labels.push([yearMonth.format("MMM") + " " + yearMonth.format("YYYY")]);
        }
        return labels;
    }

    private flatModels(sales: DealerSales): ModelItemDetail[] {
        if (!sales || !sales.makes) {
            return [];
        }
        return sales.makes.reduce((memo, make) => {
            memo.push.apply(memo, make.models);
            return memo;
        }, []);
    }

    private buildModelsValue(dealers: Dealer[], dealerSales: DealerSales[], yearLabels: string[], models: string[], isLabel: boolean): ChartPopupDealerModelSales[] {
        let colorCount = 0;
        return dealers.map((d, i) => {
            const sales = dealerSales.filter(ds => ds.dealer_id === d.dealer_id) || ({} as any);
            const flatSales = sales.length > 0 ? sales.reduce((acc, build) => merge(acc, build)) : {};

            const allModels = this.flatModels(flatSales);
            let values = {};
            const spotlighted = d.dealer_id === this.spotlightId;

            if (models.length <= 1) {
                const modelSales = allModels.find(m => models[0] === m.model_description);
                const monthly = modelSales && modelSales[this.type + "Monthly"];

                // If only one dealer and one model is selected, show totals view.
                if (!isLabel) {
                    values = this.toValuesObject(yearLabels, monthly);
                } else {
                    values[models[0]] = this.toValuesObject(yearLabels, monthly);
                    values[models[0]].spotlight = spotlighted;
                    values[models[0]].color = typeof this.colors[colorCount] === "object" ?
                        ChartColor.toChartJsColor(this.colors[colorCount++].hex) :
                        ChartColor.toChartJsColor(this.colors[colorCount++]);
                }
            } else {
                values = models.reduce((h, modelName) => {
                    const modelSales = allModels.find(m => modelName === m.model_description);
                    const monthly = modelSales && modelSales[this.type + "Monthly"];
                    if (monthly) {
                        h[modelName] = this.toValuesObject(yearLabels, monthly);
                        h[modelName].spotlight = spotlighted;
                        // Get color for dealer-model specific legend color.
                        h[modelName].color = typeof this.colors[colorCount] === "object" ?
                            ChartColor.toChartJsColor(this.colors[colorCount++].hex) :
                            ChartColor.toChartJsColor(this.colors[colorCount++]);
                    }
                    return h;
                }, values);
            }

            return {
                name: spotlighted ? `Spotlighted Dealer: ${d.dealer_name}` : d.dealer_name,
                id: d.dealer_id,
                values
            };
        }) as ChartPopupDealerModelSales[];
    }

    private toValuesObject(yearsMonth: string[], source: { [key: string]: number }): { [key: string]: number } {
        return yearsMonth.reduce((m, l) => {
            const y = l.slice(0, 4);
            m[y] = source ? source[l] : undefined;
            return m;
        }, {});
    }

    private zeroOutSalesMakeModels(sales: DealerSales[], months: string[]): void {
        if (!sales.length) {
            return;
        }
        const sample = sales[0].salesMonthly;
        // Verify that all requested months has been loaded by checking for data in return values.
        for (let i = 0; i < months.length; i++) {
            if (sample[months[i]] === undefined) {
                return;
            }
        }

        for (let i = 0; i < sales.length; i++) {
            const ds = sales[i];
            const flatModels = this.flatModels(ds);
            for (let j = 0; j < flatModels.length; j++) {
                const model = flatModels[j];
                for (let k = 0; k < months.length; k++) {
                    if (model.salesMonthly[months[k]] === undefined) {
                        model.salesMonthly[months[k]] = 0;
                    }
                    if (!model.sharesMonthly[months[k]] === undefined) {
                        model.sharesMonthly[months[k]] = 0;
                    }
                }
            }
        }
    }

    // Width in pixel for a segment: (totalGroupItems * barWidth) + (groupingOffset * groups) + (groupItemOffset * totalGroupItems) + offset between intervals.
    private calculateSegmentWidth(models: number, barThickness: number, groups: number): number {
        return (models * barThickness) + (SPACE_BETWEEN_GROUP * groups) + (1 * models) + barThickness;
    }

    private shrinkDataForChart(dataset: DataSet[], max: number): DataSet[] {
        const copy = cloneDeep(dataset);
        for (let i = 0; i < copy.length; i++) {
            copy[i].data = copy[i].data.slice(-max);
        }
        return copy;
    }

    private closeStream(): void {
        if (this.datastream) {
            if (!this.datastream.closed) {
                this.datastream.unsubscribe();
            }
            this.datastream = undefined;
        }
    }
}
