import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from "@angular/core";
import { Dealer } from "app/core/models/dealers.model";

@Component({
    selector: "results-expand-button",
    templateUrl: "./results-expand-button.component.html",
    styleUrls: ["./results-expand-button.component.scss"]
})
export class ResultsExpandButtonComponent implements OnInit, OnChanges {

    visible = false;
    opened = false;

    @ContentChild("description") descriptionTemplate: TemplateRef<any>;
    @ContentChild("main") mainTemplate: TemplateRef<any>;
    @ContentChild("closed") closedTemplate: TemplateRef<any>;

    @Input() title: string;
    @Input() dealers: Dealer[] = [];
    @Input() defaultOpen = false;
    @Input() index;

    toggleShowHide(): void {
        this.opened = true;
        this.visible = !this.visible;
    }

    ngOnInit(): void {
        if (this.defaultOpen) {
            this.opened = true;
            this.visible = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("results" in changes) {
            this.visible = false;
            this.opened = false;
        }
        if ("defaultOpen" in changes) {
            if (changes.defaultOpen.currentValue) {
                this.visible = true;
                this.opened = true;
            } else {
                this.visible = false;
                this.opened = false;
            }
        }
    }
}
