<selection-section
    [dataService]="salesDataService"
    [filterService]="filterStateService"
    [filterName]="filterName"
    [titleTemplate]="titleTemplate"
    [descriptionTemplate]="descriptionTemplate"
    [doneTemplate]="doneTemplate"
    [excludeSelections]="spotlightedDealerId"
    [radiusRingButtonDisabled]="radiusRingButtonDisabled"></selection-section>

<ng-template #titleTemplate>
    {{title}}
</ng-template>

<ng-template #descriptionTemplate>
    <ng-container *ngIf="filterName === FilterName.zones">
        Based on the filters that you have applied;
        <span *ngIf="topZones.length === 1">
            the top Ad Zone is <span class="bold">{{topZones[0] || '[Loading...]'}}</span>.
        </span>

        <span *ngIf="topZones.length !== 1">
            the top two Ad Zones are
            <span class="bold">{{topZones[0] || '[Loading...]'}}</span>
            and
            <span class="bold">{{topZones[1] || '[Loading...]'}}</span>.
        </span>
        If you choose different Ad Zone filters the cards will display different top zones.
    </ng-container>

    Displaying options in the selected DMA with registration data from the last three years, if available.
</ng-template>

<ng-template #doneTemplate>
    <div #donebutton class="close-button" (click)="closePanel()">
        <div class="label">Done</div>
        <div class="icon"></div>
    </div>
</ng-template>
