<card
    id="dealer-make-by-ad-zone"
    [cardTitle]="title"
    [description]="description"
    [disabled]="isDisabled"
    [isOpen]="isOpen"
    [loading]="isLoading"
    (open)="cardOpened()"
    (close)="cardClosed()"
>
    <chart
        [dataToRender]="data"
        [labelsToRender]="labels"
        [colors]="colors"
        [chartType]="'groupedBars'"
        [chartOptions]="chartOptions"
        [disableLabelInteractions]="true"
    ></chart>
</card>
