<card [cardTitle]="cardTitle" [disabled]="isDisabled" [isOpen]="isOpen && !isDisabled" [loading]="isLoading" [description]="description"
    [paddingless]="true" (open)="cardOpened()" (close)="cardClosed()">
    <div class="data-viz">
        <ng-container *ngIf="visualization === 'chart'; else tableView">
            <chart chartType="horizontalBar" [dataToRender]="barChartData" [colors]="chartColors"></chart>
        </ng-container>
        <ng-template #tableView>
            <zip-zone-table [type]="'zones'" [topZipZones]="topZones" [selectedZipZones]="selectedZones"
                [zipZoneSales]="salesByZones" [colors]="chartColors" [showVolume]="showVolume"
                [isMultiDma]="isMultiDma"></zip-zone-table>
        </ng-template>
        <div class="viz-toggle" (click)="changeVis()">
            <span class="icon" [class.chart]="visualization === 'chart'" [class.table]="visualization === 'table'"></span>
        </div>
    </div>
    <div class="card-minimap-container">
        <mini-map *ngIf="isOpen"  [radiusRingSize]="ringRadius" [spotlightedDealer]="spotlightedDealer" [renderedZips]="zonesByZips" [animateHighlight]="animateMap"></mini-map>
    </div>
</card>
