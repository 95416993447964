import { Injectable } from "@angular/core";

import { CognitoService } from "./cognito.service";
import Optimizely from "./optimizely";

export enum FeatureFlags {
    AA_MakeVsMake = "AA_MakeVsMake",
    AA_MakeVsSegment = "AA_MakeVsSegment",
    AA_MarketShare = "AA_MarketShare",
    AA_Composite = "AA_Composite",
    AA_MarketAnalysis = "AA_MarketAnalysis",
    AA_PSA = "AA_PSA",
    AA_DateRanges_Simplified_Flows = "AA_DateRanges_Simplified_Flows",
    AA_Export_DropDown = "AA_Export_DropDown",
    AA_Create_Schedule_Button = "aa_create_schedule_button",
    AA_Custom_Zips = "aa_custom_zips",
    AA_Multiple_Makes_PPT = "aa_multiple_makes_ppt",
    AA_Custom_Replace_Advanced_Verbiage = "aa_custom_replace_advanced_verbiage",
    AA_Optional_Subtitles = "aa_optional_subtitles",
    AA_Download_Dealer_Sales_Comparison = "aa_download_dealer_sales_comparison",
    AA_Sales_And_Shares_By_Top_Zip = "aa_sales_and_shares_by_top_zip",
    AA_Change_Composite_Competitors_Proximity = "change_composite_competitors_proximity",
    AA_Enable_Sales_Toggle = "aa_enable_sales_toggle"
}

export enum FeatureFlagsVariables {
    AA_DateRanges_Simplified_Flows = "AA_DateRanges_Simplified_Flows",
    AA_Export_DropDown = "AA_Export_DropDown",
    AA_Create_Schedule_Button = "showCreateScheduleButton"
}

export enum UserGroups {
    Admin = "Admins"
}

@Injectable()
export class FeatureFlagService {

    client: any;

    constructor(private cognitoService: CognitoService) {
        this.isFeatureEnabled = this.isFeatureEnabled.bind(this);
    }

    userInGroup(group: UserGroups): boolean {
        return this.cognitoService.getUserGroups().includes(group.toString());
    }

    viewAdminFeature(): boolean {
        return this.userInGroup(UserGroups.Admin);
    }

    async viewPsa(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_PSA);
    }

    async viewMakeVsMake(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_MakeVsMake);
    }
    async viewMakeVsSegment(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_MakeVsSegment);
    }

    async viewMarketShare(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_MarketShare);
    }

    async viewMarketAnalysis(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_MarketAnalysis);
    }

    async viewComposite(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_Composite);
    }

    async viewMultipleMakes(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_Multiple_Makes_PPT);
    }

    async viewDateRanges(): Promise<boolean> {
        return await this.isVariableEnabled(FeatureFlags.AA_DateRanges_Simplified_Flows, FeatureFlagsVariables.AA_DateRanges_Simplified_Flows);
    }

    async viewExportDropDown(): Promise<boolean> {
        return await this.isVariableEnabled(FeatureFlags.AA_Export_DropDown, FeatureFlagsVariables.AA_Export_DropDown);
    }

    async viewCreateSchedule(): Promise<boolean> {
        return await this.isVariableEnabled(FeatureFlags.AA_Create_Schedule_Button, FeatureFlagsVariables.AA_Create_Schedule_Button);
    }

    async viewCustomVerbage(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_Custom_Replace_Advanced_Verbiage);
    }

    async viewOptionalRequiredSubtitles(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_Optional_Subtitles);
    }

    async viewDownloadDealerSalesComparison(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_Download_Dealer_Sales_Comparison);
    }

    async viewSalesAndSharesByTopZip(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_Sales_And_Shares_By_Top_Zip);
    }

    async viewChangeCompetitorsProximity(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_Change_Composite_Competitors_Proximity);
    }

    async viewSalesToggle(): Promise<boolean> {
        return await this.isFeatureEnabled(FeatureFlags.AA_Enable_Sales_Toggle);
    }

    async isFeatureEnabled(featureFlag: FeatureFlags): Promise<boolean> {
        if (!this.client) {
            this.client = await Optimizely.getInstance();
        }

        // If it still cannot connect
        if (!this.client) {
            return false;
        }

        const email = this.cognitoService.getUserEmail();
        const isEnabled = this.client.isFeatureEnabled(featureFlag.toString(), email, { str_emailAddress: email });
        if (isEnabled) {
            return isEnabled;
        }
        return false;
    }

    async isVariableEnabled(featureFlag: FeatureFlags, featureFlagVariable: FeatureFlagsVariables): Promise<boolean> {
        if (!this.client) {
            this.client = await Optimizely.getInstance();
        }

        // If it still cannot connect
        if (!this.client) {
            return false;
        }

        const email = this.cognitoService.getUserEmail();
        const isEnabled = await this.client.isFeatureEnabled(featureFlag.toString(), email, { str_emailAddress: email });
        if (isEnabled) {
            return this.client.getFeatureVariable(featureFlag.toString(), featureFlagVariable, email, { str_emailAddress: email });
        }
        return false;
    }
}
