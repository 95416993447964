import { HttpClient } from "@angular/common/http";
import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { PresentationFilter } from "@at/core";
import * as Sentry from "@sentry/browser";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import { FilterState } from "app/core/state/state";
import { debounce, difference, omit, xor } from "lodash";
import { Observable, Subject, of } from "rxjs";
import { flatMap } from "rxjs/operators";

import { DealerSalesRangeFilterHelper } from "../../helpers/dealer-sales-range-filter.helper";
import { FilterName } from "../../models/filter-name.enum";
import { SalesRangeFilterModel } from "../../models/sales-range-filter.model";
import { UserPreferences } from "../../models/user-preferences.enum";
import { UserCookieService } from "../../services/user-cookie.service";
import { HttpServiceBase } from "../services/http-service-base.service";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// export interface UserPreferences { }

@Directive()
@Injectable()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FilterBaseService extends HttpServiceBase {
    @Output() filtersUpdated = new EventEmitter<string[]>();
    filtersDataUpdateVal = new Subject<any>();

    currentFilters = {};
    filterDefaults: any = {};
    resettableFilters: string[] = [];
    clientOnlyFilters: string[] = [];
    arrayFilters: string[] = [];
    filterNameCache = {};

    cookiePrefix = "";

    // A property to store user selection / states.
    userPreferences = {};

    protected filtersChanged: string[] = [];

    constructor(
        protected http: HttpClient,
        protected userCookieService: UserCookieService,
        protected ngrxFilterStateService: NgrxFilterStateService
    ) {
        super(http);
        // Debounce filters changed from within the same event cycle to emit them together.
        this.emitValue = debounce(this.emitValue.bind(this), 0);
    }

    setupCurrentFilters(authenticated: boolean): void {
        // wait to setup the filters until the user is authenticated
        if (authenticated) {
            this.ngrxFilterStateService.setDefaultFilterState(this.filterDefaults);
            for (const name in this.filterDefaults) {
                if (name === FilterName.buyer_dma_code.toString()) {
                    this.setDefaultFilterValue(FilterName.buyer_dma_code, this.getFilterValue(FilterName.dma)); // sets buyer_dma_code to default exclude
                }
                if (this.userCookieService.storedValueExists(name, this.cookiePrefix) && this.userCookieService.getStoredValue(name, this.arrayFilters, this.cookiePrefix) !== undefined) {
                    this.ngrxFilterStateService.setFilter(name, this.userCookieService.getStoredValue(name, this.arrayFilters, this.cookiePrefix));
                } else {
                    this.ngrxFilterStateService.setFilter(name, this.filterDefaults[name]);
                }
                this.filtersChanged.push(name);
            }
            if (this.filtersChanged.length) {
                this.filtersChanged.push("filters-setup"); // this notifies any subscribed element that the emitted filters where emitted from this function
            }
            this.emitChange();
        }
    }

    setDefaultFilterValue(filterName: FilterName, value: any): void {
        if (filterName && typeof value !== "undefined") {
            this.ngrxFilterStateService.setDefaultFilter(filterName, value);
        }
    }

    clientOnlyChanges(changed: string[]): boolean {
        return difference(changed, this.clientOnlyFilters).length === 0;
    }

    getCurrentApiFilters(exclude: (string | string[]) = null, ...rest: string[]): Partial<FilterState> {
        const ex = [].concat(exclude, rest).concat(this.clientOnlyFilters.slice());
        let filters = this.ngrxFilterStateService.getCurrentFilterState();
        let currentPartialFilterState = omit<Partial<FilterState>>(filters, ex);
        return currentPartialFilterState;
    }

    resetResettableFilters(exclude: string[] = []): void {
        let resettableFilters = this.ngrxFilterStateService.getCurrentResettableFilterState();
        for (let i = 0; i < resettableFilters.length; i++) {
            const filter = resettableFilters[i];
            if (!exclude.includes(filter)) {
                this.ngrxFilterStateService.setFilter(filter, (filter in this.filterDefaults) ? this.filterDefaults[filter] : null);
                this.userCookieService.setStoredValue(filter, null, this.cookiePrefix);
            }
        }
        this.filtersUpdated.emit(this.resettableFilters.slice().concat("Filters-Reset"));
        this.filtersDataUpdateVal.next(this.resettableFilters.slice().concat("Filters-Reset"));
    }

    resetFilter(filterName: FilterName): void {
        const value = (filterName.toString() in this.filterDefaults) ? this.filterDefaults[filterName.toString()] : null;
        this.ngrxFilterStateService.setFilter(filterName.toString(), value);
        this.userCookieService.setStoredValue(filterName.toString(), null, this.cookiePrefix);
        this.filtersUpdated.emit([filterName.toString()]);
        this.filtersDataUpdateVal.next([filterName.toString()]);

    }

    resetFilters(filterNames: FilterName[]): void {
        const resettedFilters = [];
        for (let i = 0; i < filterNames.length; i++) {
            resettedFilters.push(filterNames[i].toString());
            const value = (resettedFilters[i] in this.filterDefaults) ? this.filterDefaults[resettedFilters[i]] : null;
            this.ngrxFilterStateService.setFilter(resettedFilters[i], value);
            this.userCookieService.setStoredValue(resettedFilters[i], null, this.cookiePrefix);
        }
        this.filtersUpdated.emit(resettedFilters);
        this.filtersDataUpdateVal.next(resettedFilters);

    }

    canResetFilters(exclude?: string[]): boolean {
        const resettableFilters = exclude ?
            this.ngrxFilterStateService.getCurrentResettableFilterState().filter((resettableFilter) => !exclude.includes(resettableFilter)) :
            this.ngrxFilterStateService.getCurrentResettableFilterState();

        for (let i = 0; i < resettableFilters.length; i++) {
            const r = resettableFilters[i];
            const c = this.ngrxFilterStateService.getCurrentFilterState()[r];
            const defaultValue = this.filterDefaults[r];

            // Default for filters with no value by default is null.
            if (c !== undefined && defaultValue !== undefined) {
                if (Array.isArray(c)) {  // Array
                    if (c.length !== defaultValue.length && xor(defaultValue, c).length > 0) {
                        return true;
                    }
                } else if (c !== defaultValue) {     // String && Number
                    return true;
                }
            }
        }
        return false;
    }

    getFilterValue<T>(name: FilterName): T {
        return this.ngrxFilterStateService.getFilter(name);
    }

    getSalesRangeValue(): SalesRangeFilterModel {
        const json: string = this.ngrxFilterStateService.getFilter(FilterName.sales_range);
        if (json) {
            return JSON.parse(json);
        }
        return { min: null, max: null };
    }

    setFilterValue(name: FilterName, value: any): void {
        // This happens if there's a filter in a cookie that then
        // gets removed from the code. Bam errors! This if statement
        // prevents them.
        if (!name) {
            return;
        }

        if (name === FilterName.dma) {
            this.ngrxFilterStateService.setFilter(FilterName.buyer_dma_code, value);
        }

        this.ngrxFilterStateService.setFilter(name, value);
        this.userCookieService.setStoredValue(name.toString(), value, this.cookiePrefix);
        this.emitChange(name.toString());
    }

    appendSalesData(value: any): void {
        this.ngrxFilterStateService.appendSalesData(value);
    }

    removeFilterValue(name: FilterName): void {
        this.ngrxFilterStateService.setFilter(name, this.filterDefaults[name]);
        this.userCookieService.setStoredValue(name.toString(), null, this.cookiePrefix);
        this.emitChange(name.toString());
    }

    emitChange(filterName: string = null): void {
        Sentry.configureScope(scope => {
            scope.setExtra("appliedFilters", this.ngrxFilterStateService.getCurrentFilterState());
        });
        this.filtersChanged.push(filterName);
        this.emitValue();
    }

    private emitValue(): void {
        // Don't emit empty changes as those will cause listeners to react and update even if there are no changes.
        if (this.filtersChanged.length) {
            this.filtersUpdated.emit(this.filtersChanged.slice());
            this.filtersDataUpdateVal.next(this.filtersChanged.slice());
            this.filtersChanged = [];
        }
    }

    getFilterOptions(filterName: FilterName, keys: string[] | number[] = [], excludedFilters: string[] = []): Observable<any[]> {
        let filters;
        if (keys.length) {
            filters = this.getCurrentApiFilters(excludedFilters);
            filters[filterName] = keys;
        } else {
            filters = this.getCurrentApiFilters(excludedFilters.concat(filterName.toString()));
        }
        // Add filter to align dmas to syscodes
        if (filterName === FilterName.zones) {
            filters.dma_code = this.ngrxFilterStateService.getFilter(FilterName.dma).slice();
        }
        filters["filtername"] = filterName.toString();

        // If we're on the dealers filter and sales range is set, remove the dealers and fetch a new set of dealers based on sales range
        return filterName === FilterName.dealers
            ? this.getDealerFilters(filterName, filters)
            : DealerSalesRangeFilterHelper.handleSalesRangeFilters<any>(filters,
                () => this.fetchSalesRangeFilteredFilterOptions(filterName, filters),
                (f) => this.fetchFilterNames(filterName, f));
    }

    private getDealerFilters(filterName: FilterName, filters: any): Observable<any[]> {
        return filters[FilterName.sales_range.toString()]
            ? this.fetchSalesRangeFilteredFilterOptions(filterName, filters)
            : this.fetchFilterNames(filterName, filters);
    }

    private fetchSalesRangeFilteredFilterOptions(filterName: FilterName, filters: any): Observable<any[]> {

        delete filters[FilterName.dealers.toString()];
        const salesRangeOnlyFilters = omit<any>(filters, [FilterName.makes.toString(), FilterName.segments.toString(), FilterName.models.toString(), FilterName.zips.toString(), FilterName.zones.toString()]);
        salesRangeOnlyFilters["filtername"] = FilterName.dealers.toString();
        return this.fetchFilterNames(FilterName.dealers, salesRangeOnlyFilters)
            .pipe(flatMap(dealers => {
                if (dealers.length > 0) {
                    filters[FilterName.dealers.toString()] = dealers.map(dealer => dealer.id);
                    delete filters[FilterName.sales_range.toString()];
                    return this.fetchFilterNames(filterName, filters);
                } else {
                    return of([]);
                }
            }));
    }

    fetchFilterNames(filterName: FilterName, filters: any): Observable<any> {
        const url = `${this.rootUrl}/filter/names`;
        if (this.ngrxFilterStateService.getFilter(FilterName.use_sales_data)) {
            filters["use_sales_data"] = "true";
        }

        const options = this.createRequestOptions(filters);
        return this.fetchStrings(url, options).pipe(flatMap(result => {
            const arrangedNameIds = this.arrangeNameId(result, filterName);
            this.saveFilterNamesToCache(arrangedNameIds, filterName);
            return of(arrangedNameIds);
        }));
    }

    saveFilterNamesToCache(options: any[], filterName: FilterName): void {
        if (options && options.length && options[0]["id"]) {
            const filterNameString = filterName.toString();
            for (let i = 0; i < options.length; i++) {
                if (!this.filterNameCache[filterNameString]) {
                    this.filterNameCache[filterNameString] = {};
                }
                this.filterNameCache[filterNameString][options[i]["id"]] = options[i]["name"];
            }
        }
    }
    getFilterNamesFromIds(ids: number[] | string[], filterName: FilterName): Observable<{ [id: number]: string }> {
        if (ids && filterName) {
            const filterNameString = filterName.toString();
            const cachedItems = {} as { [id: number]: string };
            const noCachedItems = [];
            for (let i = 0; i < ids.length; i++) {
                const itemId = ids[i];
                if (this.filterNameCache[filterNameString] && this.filterNameCache[filterNameString][itemId]) {
                    cachedItems[itemId] = this.filterNameCache[filterNameString][itemId];
                } else {
                    noCachedItems.push(itemId);
                }
            }
            if (!noCachedItems.length) { // return items if all ids were found in cache
                return of(cachedItems);
            }
            // Check if is numeric dma
            const currentDMA = this.ngrxFilterStateService.getFilter(FilterName.dma);
            const isDmaNum = currentDMA.length > 0 && parseInt(currentDMA[0], 10);
            const filters = {
                filtername: filterNameString,
                dma: isDmaNum ? currentDMA : [this.ngrxFilterStateService.getFilter(FilterName.dealer_dma_code)],
                new_used_flag: this.ngrxFilterStateService.getFilter(FilterName.new_used_flag)
            };
            filters[filterNameString] = noCachedItems;

            return this.fetchFilterNames(filterName, filters).pipe(flatMap(names => {
                for (let j = 0; j < names.length; j++) {
                    if (!this.filterNameCache[filterNameString]) {
                        this.filterNameCache[filterNameString] = {};
                    }
                    this.filterNameCache[filterNameString]["id"] = cachedItems[names[j]["id"]] = names[j]["name"];
                }
                return of(cachedItems);
            }));
        } else {
            return of({});
        }
    }

    setUserPreference(setting: UserPreferences, value: any): void {
        if (!setting) {
            return;
        }

        this.userPreferences[setting] = value;
        this.userCookieService.setStoredValue(setting.toString(), value, "preferences");
    }

    getUserPreference(setting: UserPreferences): any {
        if (!setting) {
            return;
        }

        if (!this.userPreferences[setting]) {
            this.userPreferences[setting] = this.userCookieService.getStoredValue(setting.toString(), this.arrayFilters, "preferences");
        }

        return this.userPreferences[setting];
    }

    protected arrangeNameId(rawOptions: any[], filterName: FilterName): any[] {
        const options = [];
        let idKey; let nameKey;
        switch (filterName) {
            case FilterName.dealers:
                idKey = "dealer_id";
                nameKey = "dealer_name";
                break;
            case FilterName.zones:
                idKey = "sys_code";
                nameKey = "ncc_name";
                break;
        }
        if (idKey) {
            for (let i = 0; i < rawOptions.length; i++) {
                options.push({
                    id: rawOptions[i][idKey],
                    name: idKey === "sys_code" ? rawOptions[i][nameKey].replace("Spectrum/", "") : rawOptions[i][nameKey],
                    volume: rawOptions[i].volume ? rawOptions[i].volume : 0
                });
            }
            return options;
        }
        return rawOptions;
    }
}
