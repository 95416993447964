import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { PresentationTypes } from "app/core/models/presentation-type.enum";
import { SpotlightSalesAreas } from "app/core/models/spotlight.enum";
import { FeatureFlagService } from "app/core/services/feature-flag.service";
import { PresentationFilterService } from "../../../services/presentation-filter.service";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { FilterName } from "../../../models/filter-name.enum";
import { CognitoService } from "../../../services/cognito.service";
import { DealerDataService } from "../../../services/dealer-data.service";
import { SalesDataService } from "../../../services/sales-data.service";
import { AbstractPPTDataService } from "../../services/ppt-data.service.abstract";
import { ProgressItem } from "../progress-item/progress-item";
import { ZipZoneService } from "../../../services/zip-zone.service";
import { SalesDataTypes } from "app/core/models/sales-data";

@Component({ template: "" })
export class AbstractPresentationDetailsComponent implements OnInit, OnDestroy {
    @Input() showResults = false;
    @Input() showExportArea = true;

    FilterName = FilterName; // allows use of enum in html

    userEmail = "";

    showRing = false;

    processingRequest = false;
    canFiltersBeReset = false;
    presentationBuilding = false;
    canPresentationBuild = false;
    requestError = false;
    ihsSegmentsEnabled = false;
    competitorsFF = false;
    progressItems = new Array<ProgressItem>();

    presentationSubscription: Subscription;
    chipCountSubscription: Subscription;

    spotlightDealerState = <ProgressItem>({ visited: false });
    salesDataState = <ProgressItem>({ visited: true });
    dmaState = <ProgressItem>({ visited: true });
    makesState = <ProgressItem>({ visited: false });
    datesState = <ProgressItem>({ visited: false });
    ringRadiusState = <ProgressItem>({ visited: false });
    modelsState = <ProgressItem>({ visited: false });
    zonesState = <ProgressItem>({ visited: false });
    competitorsState = <ProgressItem>({ visited: false });
    segmentsState = <ProgressItem>({ visited: false });
    ihsSegmentsState = <ProgressItem>({ visited: false });
    dateRangeType: DateRangeTypes;

    constructor(
        protected presentationFilterService: PresentationFilterService,
        protected salesDataService: SalesDataService,
        protected cognitoService: CognitoService,
        protected pptDataService: AbstractPPTDataService,
        protected dealerDataService: DealerDataService,
        protected zipZoneService: ZipZoneService,
        protected featureFlagService: FeatureFlagService
    ) { }

    protected async showModal(): Promise<void> {}

    protected async closeModal(): Promise<void> {}

    async ngOnInit(): Promise<void> {
        this.presentationSubscription = this.presentationFilterService.filtersUpdated.subscribe(this.presentationFiltersUpdated.bind(this));
        this.chipCountSubscription = this.presentationFilterService.chipCountUpdated.subscribe(this.presentationFiltersUpdated.bind(this));
        this.userEmail = this.cognitoService.getUserEmail();
        this.presentationFiltersUpdated([FilterName.report_building.toString(), FilterName.show_ring.toString()]);
        this.ihsSegmentsEnabled = this.presentationFilterService.getFilterValue<boolean>(FilterName.use_ihs_segments);
        this.dateRangeType = this.presentationFilterService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        this.competitorsFF = await this.featureFlagService.viewChangeCompetitorsProximity();
    }

    ngOnDestroy(): void {
        this.presentationSubscription.unsubscribe();
        this.chipCountSubscription.unsubscribe();
    }

    resetFilters(): void {
        if (this.canFiltersBeReset) {
            this.presentationFilterService.resetResettableFilters();
            this.requestError = false;
        }
    }

    presentationFiltersUpdated(changes: string[]): void {
        if (changes.includes(FilterName.report_building.toString())) {
            this.presentationBuilding = this.presentationFilterService.getFilterValue<boolean>(FilterName.report_building);
            if (this.presentationBuilding) {
                this.showModal();
            }
        }

        if (changes.includes(FilterName.show_ring.toString())) {
            this.showRing = this.presentationFilterService.getFilterValue<boolean>(FilterName.show_ring);
        }
        if (changes.some((change) => this.presentationFilterService.presentationFilters.includes(change))) {
            this.requestError = false;
        }
        this.canFiltersBeReset = this.presentationFilterService.canResetFilters();
        this.canPresentationBuild = this.presentationFilterService.arePresentationFiltersReady();
        this.ihsSegmentsEnabled = this.presentationFilterService.getUseSegmentsFlag();
        if (changes.includes(FilterName.spotlight_dealer.toString())) {
            let spotlightDealerId = this.presentationFilterService.getFilterValue<number>(FilterName.spotlight_dealer);
            if (!spotlightDealerId) {
                this.spotlightDealerState.values = [];
            } else {
                let spotlightDealerArr = [spotlightDealerId];
                this.presentationFilterService.getFilterNamesFromIds(spotlightDealerArr, FilterName.dealers).pipe(take(1)).subscribe(values => {
                    const res = Object.keys(values).length ? Object.values(values) : undefined;
                    if (res) {
                        this.spotlightDealerState.values = res;
                        this.spotlightDealerState.visited = true;
                    } else {
                        this.spotlightDealerState.values = [];
                    }
                });
            }
        }
        if (changes.includes(FilterName.ring_radius.toString())) {
            this.ringRadiusState.values = [this.presentationFilterService.getFilterValue<any[]>(FilterName.ring_radius)];
            this.ringRadiusState.visited = true;
        }
        if (changes.includes(FilterName.makes.toString())) {
            const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.makes);
            this.makesState.values = val.length > 0 ? val : [];
            this.makesState.visited = this.presentationFilterService.isVisited(FilterName.makes);
        }
        if (changes.includes(FilterName.dates.toString()) && this.presentationFilterService.getFilterValue(FilterName.dates)) {
            const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.dates);
            this.datesState.values = val.length > 0 ? val : [];
            this.datesState.visited = this.presentationFilterService.isVisited(FilterName.dates);
        }
        if (changes.includes(FilterName.models.toString())) {
            const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.models);
            this.modelsState.values = val.length > 0 ? val : [];
            this.modelsState.visited = this.presentationFilterService.isVisited(FilterName.models);
        }
        if (changes.includes(FilterName.segments.toString())) {
            const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.segments);
            this.segmentsState.values = val.length > 0 ? val : [];
            this.segmentsState.visited = this.presentationFilterService.isVisited(FilterName.segments);
        }
        if (changes.includes(FilterName.use_sales_data.toString()) || !this.salesDataState.values) {
            const val = this.presentationFilterService.getFilterValue(FilterName.use_sales_data);
            const str: any[] = val ? [SalesDataTypes.SALES] : [SalesDataTypes.REGISTRATION];
            str[0] = str[0].charAt(0).toUpperCase() + str[0].slice(1) + " Data";
            this.salesDataState.values = str;
        }
        if (changes.includes(FilterName.dma.toString())) {
            const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.dma);
            this.dmaState.values = this.presentationFilterService.getDmaNames(val);
            // Dma values will be undefined if searching by geography
            // This sets values to selected state/city
            if (this.dmaState.values[0] === undefined) {
                this.dmaState.values = val;
            }
            this.dmaState.visited = this.presentationFilterService.isVisited(FilterName.dma);
        }
        if (changes.includes(FilterName.ihs_segments.toString())) {
            const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.ihs_segments);
            this.ihsSegmentsState.values = val.length > 0 ? val : [];
            this.ihsSegmentsState.visited = this.presentationFilterService.isVisited(FilterName.ihs_segments);
        }
        if (changes.includes(FilterName.dealers.toString())) {
            const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.dealers);
            this.presentationFilterService.getFilterNamesFromIds(val, FilterName.dealers).pipe(take(1)).subscribe(values => {
                this.competitorsState.values = Object.keys(values).length ? Object.values(values) : [];
            });
            this.competitorsState.visited = this.presentationFilterService.isVisited(FilterName.dealers);
        }
        if (changes.includes(FilterName.zones.toString())) {
            const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.zones);
            this.presentationFilterService.getFilterNamesFromIds(val, FilterName.zones).pipe(take(1)).subscribe(values => {
                this.zonesState.values = Object.keys(values).length ? Object.values(values) : [];
            });
            this.zonesState.visited = this.presentationFilterService.isVisited(FilterName.zones);
        }
    }

    getTopModel(selectedZoneId: string): Observable<string[]> {
        const filterOverrides = {
            zones: [selectedZoneId],
            dealers: [this.presentationFilterService.getFilterValue<number>(FilterName.spotlight_dealer)],
            makes: this.presentationFilterService.getFilterValue(FilterName.makes),
            models: [],
            zips: [],
            segments: [],
            ihs_segments: []
        };

        // Get top model for the selected ad zone
        const model = this.salesDataService.getTopOptions("models", 1, filterOverrides);
        return model;
    }

    buildPresentation(): void {
        let heapReport = this.cognitoService.generateHeapReport();
        this.cognitoService.heapTrack("custom_flow_ppt_results", heapReport);

        this.requestError = false;
        this.processingRequest = true;

        const dealer: number = this.presentationFilterService.getFilterValue(FilterName.spotlight_dealer);
        const selected_dealer = this.presentationFilterService.getFilterValue(FilterName.selected_dealer);
        const dealer_dma = selected_dealer ? selected_dealer["dealer_dma_code"] : "Not Set";
        const dateRangeType = this.presentationFilterService.getFilterValue<string>(FilterName.dateRangeType);
        const newUsedFlag = this.presentationFilterService.getFilterValue(FilterName.new_used_flag);

        this.pptDataService.calculateTopZones(this.presentationFilterService.getFilterValue(FilterName.zones), 2, {
            dealers: dealer,
            new_used_flag: newUsedFlag,
            dma: [dealer_dma],
            dealer_dma_code: [dealer_dma],
            buyer_dma_code: [dealer_dma],
        })
            .toPromise()
            .then(topZones => {
                if (topZones && topZones.length > 0) {
                    this.presentationFilterService.setFilterValue(FilterName.top_zones_names, topZones.map(z => z.value));
                    this.presentationFilterService.setFilterValue(FilterName.top_zones_sys_codes, topZones.map(z => z.id as string));
                    const topAdZoneModels: string[] = [];
                    return this.getTopModel(topZones[0].id).toPromise().then(topModel1 => {
                        topAdZoneModels.push(topModel1[0]);
                    }).then(() => {
                        if (topZones[1]) {
                            return this.getTopModel(topZones[1].id).toPromise().then(topModel2 => {
                                topAdZoneModels.push(topModel2[0]);
                            }).then(() => {
                                this.presentationFilterService.setFilterValue(FilterName.top_ad_zone_models, topAdZoneModels);
                            });
                        } else {
                            this.presentationFilterService.setFilterValue(FilterName.top_ad_zone_models, topAdZoneModels);
                            return;
                        }
                    })
                        .then(() => {
                            const makes: [] = this.presentationFilterService.getFilterValue(FilterName.makes);
                            if (makes && makes.length > 1) {
                                const zipPerModel = {};
                                for (let i = 0; i < makes.length; i++) {
                                    this.salesDataService.getZipZoneForDealersSales([dealer], "zips", [], {
                                        zips: [],
                                        zones: [],
                                        makes,
                                        dateRangeType
                                    });
                                }
                                return;

                            } else {
                                return this.salesDataService.getZipZoneForDealersSales([dealer], "zips", [], {
                                    zips: [],
                                    zones: [],
                                    makes,
                                    dateRangeType
                                });
                            }
                        }).then(dealerSales => {
                            const salesArea = this.presentationFilterService.getFilterValue(FilterName.sales_area_selection) === SpotlightSalesAreas.Primary ? "psa" : "";
                            if (dealerSales && dealerSales.length > 0) {
                                const sales = dealerSales.find(sale => sale.dealer_id === dealer);
                                const zips = sales.sales.filter(sale => sale.area !== null && (salesArea === "" || sale.area === salesArea)).map(sale => sale.location);
                                this.presentationFilterService.setFilterValue(FilterName.zips, zips);
                            }
                            // this.exportToastService.open(this.toastConfig);
                        })
                        .then(() => {
                            this.presentationFilterService.startPresentationBuild(PresentationTypes.COMPOSITE)
                                .then(
                                    () => {
                                        this.processingRequest = false;
                                    },
                                    (error) => {
                                        console.error("PPT Build Error", error);
                                        this.requestError = true;
                                        this.processingRequest = false;
                                        // this.exportToastService.open(this.errorConfig);
                                    }
                                );
                        });
                }
            });
    }

    buildWorkbook(): void {
        this.presentationFilterService.createWorkbook();
        // this.requestError = false;
        // this.processingRequest = true;

        // const dealer: number = this.presentationFilterService.getFilterValue(FilterName.spotlight_dealer);
        // // if city state selection set the dma to number rather than text of city state
        // const isGeo = this.presentationFilterService.getFilterValue(FilterName.geosearch);

        // if (isGeo) {
        //     const dmaNum = this.presentationFilterService.getFilterValue(FilterName.spotlight_dealer);
        // }

        // this.pptDataService.calculateTopZones(this.presentationFilterService.getFilterValue(FilterName.zones), 2, {
        //     dealers: dealer
        // })
        //     .toPromise()
        //     .then(async topZones => {
        //         if (topZones && topZones.length > 0) {
        //             this.presentationFilterService.setFilterValue(FilterName.top_zones_names, topZones.map(z => z.value));
        //             this.presentationFilterService.setFilterValue(FilterName.top_zones_sys_codes, topZones.map(z => z.id as string));
        //             const topAdZoneModels: string[] = await this.getTopModel(topZones[0].id).toPromise();
        //             if (topZones[1]) {
        //                 topAdZoneModels.push((await this.getTopModel(topZones[1].id).toPromise())[0]);
        //             }
        //             this.presentationFilterService.setFilterValue(FilterName.top_ad_zone_models, topAdZoneModels);
        //         }
        //     })
        //     .then(() => {
        //         const makes: [] = this.presentationFilterService.getFilterValue(FilterName.makes);
        //         if (makes.length > 1) {
        //             const zipPerModel = {};
        //             for (let i = 0; i < makes.length; i++) {
        //                 this.salesDataService.getZipZoneForDealersSales([dealer], "zips", [], {
        //                     zips: [],
        //                     zones: [],
        //                     makes: makes,
        //                     dateRangeType: this.presentationFilterService.getFilterValue(FilterName.dateRangeType)
        //                 });

        //             }
        //             return;

        //         } else {
        //             return this.salesDataService.getZipZoneForDealersSales([dealer], "zips", [], {
        //                 zips: [],
        //                 zones: [],
        //                 makes: makes,
        //                 dateRangeType: this.presentationFilterService.getFilterValue(FilterName.dateRangeType)
        //             });
        //         }


        //     }).then(dealerSales => {
        //         if (dealerSales && dealerSales.length > 0) {
        //             const sales = dealerSales.find(sale => sale.dealer_id === dealer);
        //             const zips = sales.sales.filter(sale => sale.area !== null).map(sale => sale.location);
        //             this.presentationFilterService.setFilterValue(FilterName.zips, zips);
        //         }
        //     })
        //     .then(this.presentationFilterService.startPresentationBuild.bind(this.presentationFilterService))
        //     .then(
        //         () => this.processingRequest = false,
        //         (error) => {
        //             console.error("PPT Build Error", error);
        //             this.requestError = true;
        //             this.processingRequest = false;
        //         }
        //     );
    }
}
