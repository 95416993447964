// optimizely.js
import { Client, createInstance } from "@optimizely/optimizely-sdk";
import { LOG_LEVEL } from "@optimizely/optimizely-sdk/lib/utils/enums";
import { environment } from "environment";

class Optimizely {
    private static instance = null;

    static async getInstance(): Promise<Client> {
        if (this.instance) {
            return Promise.resolve(this.instance);
        }

        const instance = createInstance({
            logLevel: LOG_LEVEL.ERROR,
            sdkKey: environment.optimizelySdkKey,
            datafileOptions: {
                autoUpdate: false,
                urlTemplate: "https://cdn.optimizely.com/datafiles/%s.json"
            }
        });

        const ready = await instance.onReady();

        if (!ready.success) {
            console.error("Failed to initialize Optimizely instance", ready.reason);
            return null;
        }

        this.instance = instance;

        return instance;
    }

}

export default Optimizely;
