import { createAction, props } from "@ngrx/store";
import { FilterName } from "app/core/models/filter-name.enum";

import { FilterState } from "../state";

/* Here we define each action type which is used when creating actions */
export const FILTER_STATE_UPDATED = "[FilterState] Updated";
export const FILTER_STATE_OVERWRITE = "[FilterState] Overwritten";
export const FILTER_STATE_APPEND = "[FilterState] Append";

/* Here we actually create the necessary actions needed to maintain our FilterState */
export const setFilter = createAction(
    FILTER_STATE_UPDATED,
    props<{filterName: string | FilterName, value: any}>()
);
export const setFilterStateObject = createAction(
    FILTER_STATE_OVERWRITE,
    props<{filterState: FilterState}>()
);
export const appendToSalesDataFilter= createAction(
    FILTER_STATE_APPEND,
    props<{value: any}>()
);
