export const DealerMapping = {
    id: "dealer_id",
    name: "dealer_name",
    dma_code: "dealer_dma_code",
    address: "dealer_address",
    city: "dealer_city",
    state: "dealer_state",
    zip: "dealer_zip",
    lat: "dealer_latitude",
    lng: "dealer_longitude",
    latitude: "dealer_latitude",
    longitude: "dealer_longitude",
    misplaced: "dealer_misplaced"
};
