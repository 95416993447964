import { Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";

import { ConfirmModalConfig, ConfirmModalService } from "../../services/confirm-modal.service";

export enum ModalIcon {
    STOP = "STOP",
    WARNING = "WARNING",
    STANDARD = "STANDARD"
}

export interface ConfirmModalView {
    id: string;
    icon: ModalIcon;
    header: string;
    message?: string;
    confirmLabelText?: string;
    cancelLabelText?: string;
    contentTmplRef?: TemplateRef<any>;
    contentTmplData?: any;
    validDma?: boolean;
}

@Component({
    selector: "confirm-modal",
    templateUrl: "./confirm-modal.component.html",
    styleUrls: ["./confirm-modal.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
    // For template usage.
    ModalIcon = ModalIcon;
    config: ConfirmModalConfig;

    displayCurtain = false;
    displayModal = false;
    transitioning = false;
    transitionTimeout: number;

    private openSubscription: Subscription;
    private closeSubscription: Subscription;

    constructor(public confirmModalService: ConfirmModalService) { }

    ngOnInit(): void {
        this.openSubscription = this.confirmModalService.open.subscribe(this.showModal.bind(this));
        this.closeSubscription = this.confirmModalService.close.subscribe(this.closeModal.bind(this));
    }

    ngOnDestroy(): void {
        this.openSubscription.unsubscribe();
        this.closeSubscription.unsubscribe();
    }

    async showModal(config: ConfirmModalConfig): Promise<void> {
        this.config = config;
        this.displayCurtain = true;
        this.transitioning = true;
        clearTimeout(this.transitionTimeout);
        this.transitionTimeout = await window.setTimeout(() => {
            this.transitioning = false;
            this.displayModal = true;
        }, 500);
    }

    async closeModal(): Promise<void> {
        this.displayModal = false;
        this.transitioning = true;
        clearTimeout(this.transitionTimeout);
        this.transitionTimeout = await window.setTimeout(() => {
            this.displayCurtain = false;
            this.transitioning = false;
            this.config = undefined;
        }, 500);
    }

    async confirm(): Promise<void> {
        await this.closeModal();
        this.config.confirm(this.config.viewConfig);
    }

    async cancel(e: any, iconFlag?: boolean): Promise<void> {
        await this.closeModal();
        this.config.cancel(e, iconFlag);
    }
}
