import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";

import { ChartPopupData } from "../models/chart-popup.model";

@Injectable()
export class ChartPopupService {
    public readonly open = new Subject<ChartPopupData>();
    public readonly close = new Subject<void>();

    private opened = false;
    private datastream: Subscription;

    get isOpened(): boolean {
        return this.opened;
    }

    showModal(data: ChartPopupData): void {
        // Datastream was changed unsubscribed from previous.
        if (this.datastream !== undefined && this.datastream !== data.datastream) {
            this.datastream.unsubscribe();
        }
        this.opened = true;
        this.datastream = data.datastream;
        this.open.next(data);
    }

    hideModal(): void {
        if (this.datastream) {
            this.datastream.unsubscribe();
            this.datastream = undefined;
        }
        this.opened = false;
        this.close.next(undefined);
    }
}
