<card
    id="{{cardId}}-card"
    [cardTitle]="cardTitle"
    [description]="cardDescription"
    [descriptionDisabled]="cardDescriptionDisabled"
    [disabled]="cardDisabled"
    [isOpen]="cardOpen"
    [loading]="cardLoading"
    (open)="cardOpened()"
    (close)="cardClosed()">
    <dropdown
        id="select-kite_dealer-make-seg-adzone"
        [items]="dropdown"
        [selected]="defaultDropDown"
        (change)="dropdownChanged($event)"
        leadingText="Ad Zone Displayed:"></dropdown>
    <chart
        id="{{cardId}}-chart"
        [chartType]="'groupedBars'"
        [dataToRender]="chartData"
        [labelsToRender]="chartLabels"
        [colors]="chartColors"
        [chartOptions]="chartOptions"
        [disableLabelInteractions]="true"></chart>
</card>
