/* eslint-disable indent */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FeatureFlagService } from "@at/core";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { FilterName } from "app/core/models/filter-name.enum";
import { ClientMetadata } from "app/core/models/metadata.model";
import { MonthMap } from "app/core/models/month-mapping";
import { SalesDataTypes } from "app/core/models/sales-data";
import { SpotlightCompetitors } from "app/core/models/spotlight.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import { isEqual } from "lodash";
import * as moment from "moment";

import { MetadataService } from "../../../services/metadata.service";
import { ISalesDataToggleState, SalesDataSelectorComponent } from "../../data-source-selector/data-source-selector.component";
import { IDateRangeToggleState } from "../../date-range-selector/date-range-selector.component";
import { ToastComponent } from "../../toast/toast.component";
import { IFilterToggleState } from "../filter-toggle-button/filter-toggle-button.component";

@Component({
    selector: "filter-panel",
    templateUrl: "./filter-panel.component.html",
    styleUrls: ["./filter-panel.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class FilterPanelComponent implements OnInit, OnDestroy {
    filterStateSubscription: any;
    FilterName = FilterName; // this is here for proper template binding
    currentDma: number[];
    dataLastUpdate: string;
    spotlightSelectionOpen = false;
    spotlightOnly = false;
    spotlightDealerId: number;
    ihsSegmentsEnabled = false;
    weeklyDataSelectorEnabled = false;
    canViewDateRanges: boolean;
    DateRangeTypes = DateRangeTypes;
    SalesDataTypes = SalesDataTypes;
    useSalesData = false;
    salesDataEnabled: boolean;

    @Input() toastComponent: ToastComponent;

    @ViewChild("filterPanelContent") filterPanelContent: ElementRef;
    secondDmaValueSelected = true;

    constructor(
        private filterStateService: FilterStateService,
        private metadataService: MetadataService,
        private ngrxFilterStateService: NgrxFilterStateService,
        private featureFlagService: FeatureFlagService
    ) {
        this.filtersUpdated([FilterName.competitors_selection.toString(),
            FilterName.dma.toString(), FilterName.spotlight_dealer.toString(), FilterName.use_ihs_segments.toString()]);

    }

    async ngOnInit(): Promise<void> {
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.salesDataEnabled = await this.featureFlagService.viewSalesToggle();
        if (this.salesDataEnabled) {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        } else {
            this.useSalesData = false;
            this.filterStateService.setFilterValue(FilterName.use_sales_data, false);
        }
        this.metadataService.metadata.subscribe((metadata: ClientMetadata) => this.dataLastUpdate = this.formatDate(this.useSalesData ? metadata.maxSalesDate : metadata.maxDate));
        this.weeklyDataSelectorEnabled = await this.featureFlagService.viewSalesToggle();
    }

    filtersUpdated(changes: string[]): void {
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        if (changes.includes("dma")) {
            this.setCurrentDma(this.filterStateService.getFilterDma());
            const buyerDmaCode = this.filterStateService.getFilterValue(FilterName.buyer_dma_code);

            this.secondDmaValueSelected = isEqual(this.currentDma, buyerDmaCode) ?? true;
        }
        if (changes.includes(FilterName.competitors_selection.toString())) {
            this.spotlightOnly = this.filterStateService.getFilterValue(FilterName.competitors_selection) === SpotlightCompetitors.SpotlightOnly;
        }
        if (changes.includes(FilterName.spotlight_dealer.toString())) {
            this.spotlightDealerId = this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer);
        }

        if (changes.includes(FilterName.use_ihs_segments.toString())) {
            this.ihsSegmentsEnabled = this.filterStateService.getFilterValue<boolean>(FilterName.use_ihs_segments);
        }
    }

    ngOnDestroy(): void {
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
    }

    updateToggleValue(state: IFilterToggleState): void {
        if (!state) {
            return;
        }

        if (state.name === FilterName.new_used_flag) {
            if (this.useSalesData) {
                return;
            }
            if (state.value.toString() === "1") {
                this.filterStateService.setFilterValue(FilterName.disable_sales_data_menu, true);
                this.filterStateService.setFilterValue(FilterName.use_sales_data, false);
            } else {
                this.filterStateService.setFilterValue(FilterName.disable_sales_data_menu, false);
            }
        }

        this.filterStateService.setFilterValue(state.name, state.value);
    }

    updateDataSource(state: ISalesDataToggleState): void {
        if (!state) {
            return;
        }

        this.filterStateService.setFilterValue(state.name, state.value);
    }

    toggleDateRange(state: IDateRangeToggleState): void {
        if (!state) {
            return;
        }
        const dateRanges = this.filterStateService.getFilterValue<[]>(FilterName.dateRanges);
        if (state.value === DateRangeTypes.MONTHS && dateRanges && dateRanges.length < 5) {
            return;
        }

        this.filterStateService.setFilterValue(state.name, state.value);
    }

    // String should be in the format of  "YYYYMM".
    formatDate(s: string): string {
        return moment(s, "YYYYMMDD").format("MMM YYYY");
    }

    setCurrentDma(current: number[]): void {
        this.currentDma = current;
    }
}
