import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { CognitoService } from "../services/cognito.service";

export const InterceptorSkipHeader = "X-Skip-Method-Interceptor";

@Injectable()
export class RequestMethodInterceptor implements HttpInterceptor {

    constructor(private auth: CognitoService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (request.urlWithParams.length > 5000) {
            const constructedParams = {};
            request.params.keys().map((key) => {
                constructedParams[key] = request.params.get(key);
            });
            request = request.clone({
                method: "POST",
                body: JSON.stringify(constructedParams),
                params: new HttpParams()
            });
        }
        return next.handle(request);
    }
}
