<div class="dealers-sales-comparison">
    <div *ngIf="canViewDownloadDealerSalesComparison" [placement]="'left'" [dTooltip]="downloadInstructions"
        [showTooltip]="!downloadable" class="download-excel-file" (click)="initiateExcelDownload()">
        <div class="download-title">
            <span [class]="downloadable ? 'title-active':'title-inactive'">Download Dealer Sales Comparison</span>
        </div>
        <div class="download-icon">
            <span [class]="downloadable ? 'icon-active':'icon-inactive'"></span>
        </div>
    </div>
    <table class="results-data-table" *ngIf="dealers?.length">
<!--        <thead>-->
            <tr>
                <th></th>
                <ng-container *ngIf="displayDates?.length; else loading">
                    <th class="data" *ngFor="let date of displayDates">
                        <pre>{{date}}</pre>
                    </th>
                </ng-container>
            </tr>
<!--        </thead>-->

        <tbody class="dealer-details" id="{{dealersYearlySalesDisplay.groupByValue}}:sales-comparison-table"
            *ngFor="let dealerYearlySales of dealersYearlySalesDisplay; let last = last">
            <tr>
                <th>{{dealerYearlySales.groupByValue}}</th>
                <td class="data" *ngFor="let date of dates" id="{{dealerYearlySales.dealers}}-{{date}}-dsc-entry">
                    {{showVolume ?
                    valueFor(dealerYearlySales, date)?.toLocaleString("en") :
                    (valueFor(dealerYearlySales, date)?.toLocaleString("en") | SigFig) }}

                    {{keyBase === 'sales' ? '': valueFor(dealerYearlySales, date) === 'n/a' ? '': '%'}}
                </td>
            </tr>
            <tr *ngIf="!last || !!dealersToLoad.length">
                <td colspan="4" class="break-row">
                    <div class="break-line-short"></div>
                </td>
            </tr>
        </tbody>

        <tbody class="dealer-details" id="loading-rows:sales-comparison-table"
            *ngFor="let number of dealersToLoad; let last = last">
            <tr>
                <th>
                    <div class="wide loading"></div>
                </th>
                <ng-container *ngTemplateOutlet="loading"></ng-container>
            </tr>
            <tr *ngIf="!last">
                <td colspan="4" class="break-row">
                    <div class="break-line-short"></div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="load-more" (click)="loadMoreDealersYearlySales()" *ngIf="!allLoaded() && !!!dealersToLoad.length">Load
        More
    </div>
    <div class="load-more disabled" *ngIf="!allLoaded() && !!dealersToLoad.length">
        <div class="loading"></div>
    </div>
</div>

<ng-template #loading>
    <th class="data">
        <div class="loading"></div>
    </th>
    <th class="data">
        <div class="loading"></div>
    </th>
    <th class="data">
        <div class="loading"></div>
    </th>
</ng-template>

<ng-template #downloadInstructions>
    <div style="max-width:220px">
        Narrow your search criteria to activate the download button.
    </div>
</ng-template>
