<!-- SVG pin icons from zeplin, then encoded with https://yoksel.github.io/url-encoder/ -->
<div #map id="map" [class.extend-left]="extendMapLeft" [class.extend-right]="extendMapRight">
    <map-heatmap-legend [leftPanelOpen]="leftPanelOpen" [legendInfo]="heatmapScales"></map-heatmap-legend>
</div>
<dealer-share-popup [dealer]="selectedDealer"></dealer-share-popup>
<zip-zone-sales-popup [zipZoneMode]="zipZoneMode()" [zips]="selectedZips"></zip-zone-sales-popup>
<map-filter-controls #filterControls [rightPanelOpen]="rightPanelOpen"></map-filter-controls>


<div class="hiddenComponents">
    <img id="dealer-pin"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='24' viewBox='0 0 18 24'%3E%3Cdefs%3E%3Cpath id='b' d='M28 367.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5m0-9.5c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7'/%3E%3Cfilter id='a' width='157.1%25' height='140%25' x='-21.4%25' y='-15%25' filterUnits='objectBoundingBox'%3E%3CfeMorphology in='SourceAlpha' operator='dilate' radius='.5' result='shadowSpreadOuter1'/%3E%3CfeOffset dx='1' dy='1' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'/%3E%3CfeColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0'/%3E%3C/filter%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate%28-20 -357%29'%3E%3Cuse fill='%23000' filter='url%28%23a%29' xlink:href='%23b'/%3E%3Cuse fill='%23FFF' stroke='%230077BC' xlink:href='%23b'/%3E%3C/g%3E%3C/svg%3E%0A" />
    <img id="dealer-pin-focused"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='24' viewBox='0 0 18 24'%3E%3Cdefs%3E%3Cpath id='b' d='M57 367.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5m0-9.5c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7'/%3E%3Cfilter id='a' width='157.1%25' height='140%25' x='-21.4%25' y='-15%25' filterUnits='objectBoundingBox'%3E%3CfeMorphology in='SourceAlpha' operator='dilate' radius='.5' result='shadowSpreadOuter1'/%3E%3CfeOffset dx='1' dy='1' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'/%3E%3CfeColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0'/%3E%3C/filter%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate%28-49 -357%29'%3E%3Cuse fill='%23000' filter='url%28%23a%29' xlink:href='%23b'/%3E%3Cuse fill='%230077BC' stroke='%23FFF' xlink:href='%23b'/%3E%3C/g%3E%3C/svg%3E%0A" />
    <img id="dealer-pin-spotlight"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='26' viewBox='0 0 20 26'%3E%3Cdefs%3E%3Cpath id='b' d='M660 498.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5m0-9.5c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7'/%3E%3Cfilter id='a' width='157.1%25' height='140%25' x='-21.4%25' y='-15%25' filterUnits='objectBoundingBox'%3E%3CfeMorphology in='SourceAlpha' operator='dilate' radius='.5' result='shadowSpreadOuter1'/%3E%3CfeOffset dx='1' dy='1' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'/%3E%3CfeColorMatrix in='shadowBlurOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0'/%3E%3C/filter%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate%28-651 -487%29'%3E%3Cuse fill='%23000' filter='url%28%23a%29' xlink:href='%23b'/%3E%3Cuse fill='%23FAA41A' stroke='%23FFF' xlink:href='%23b'/%3E%3C/g%3E%3C/svg%3E%0A" />
    <img id="dealer-pin-1"
        src="data:image/svg+xml,%3Csvg width='18' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='m28,367.5a2.5,2.5 0 0 1 0,-5a2.5,2.5 0 0 1 0,5m0,-9.5c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7' id='b'/%3E%3Cfilter filterUnits='objectBoundingBox' y='-15%25' x='-21.4%25' height='140%25' width='157.1%25' id='a'%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3C/filter%3E%3C/defs%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='602' width='802' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg id='svg_1' fill-rule='evenodd' fill='none'%3E%3Cuse id='svg_2' x='-20' y='-357' xlink:href='%23b' filter='url(%23a)' fill='%23000'/%3E%3Cuse id='svg_3' x='-20' y='-357' xlink:href='%23b' stroke='%230077BC' fill='%23FFF'/%3E%3C/g%3E%3Cellipse ry='4.46095' rx='4.42997' id='svg_4' cy='8.12474' cx='7.94672' stroke-width='1.5' stroke='%23ffffff' fill='%23ffffff'/%3E%3Ctext xml:space='preserve' text-anchor='start' font-family='Trebuchet MS, Gadget, sans-serif' font-size='14' id='svg_7' y='13.51506' x='3.88849' stroke-width='0' stroke='%23FFF' fill='%230077BC'%3E1%3C/text%3E%3C/g%3E%3C/svg%3E%0A"/>
    <img id="dealer-pin-2"
        src="data:image/svg+xml,%3Csvg width='18' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='m28,367.5a2.5,2.5 0 0 1 0,-5a2.5,2.5 0 0 1 0,5m0,-9.5c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7' id='b'/%3E%3Cfilter filterUnits='objectBoundingBox' y='-15%25' x='-21.4%25' height='140%25' width='157.1%25' id='a'%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3C/filter%3E%3C/defs%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='602' width='802' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg id='svg_1' fill-rule='evenodd' fill='none'%3E%3Cuse id='svg_2' x='-20' y='-357' xlink:href='%23b' filter='url(%23a)' fill='%23000'/%3E%3Cuse id='svg_3' x='-20' y='-357' xlink:href='%23b' stroke='%230077BC' fill='%23FFF'/%3E%3C/g%3E%3Cellipse ry='4.46095' rx='4.42997' id='svg_4' cy='8.12474' cx='7.94672' stroke-width='1.5' stroke='%23ffffff' fill='%23ffffff'/%3E%3Ctext xml:space='preserve' text-anchor='start' font-family='Trebuchet MS, Gadget, sans-serif' font-size='14' id='svg_7' y='13.51506' x='4.57003' stroke-width='0' stroke='%23FFF' fill='%230077BC'%3E2%3C/text%3E%3C/g%3E%3C/svg%3E%0A"/>
    <img id="dealer-pin-3"
        src="data:image/svg+xml,%3Csvg width='18' height='24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='m28,367.5a2.5,2.5 0 0 1 0,-5a2.5,2.5 0 0 1 0,5m0,-9.5c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7' id='b'/%3E%3Cfilter filterUnits='objectBoundingBox' y='-15%25' x='-21.4%25' height='140%25' width='157.1%25' id='a'%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3C/filter%3E%3C/defs%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='602' width='802' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg id='svg_1' fill-rule='evenodd' fill='none'%3E%3Cuse id='svg_2' x='-20' y='-357' xlink:href='%23b' filter='url(%23a)' fill='%23000'/%3E%3Cuse id='svg_3' x='-20' y='-357' xlink:href='%23b' stroke='%230077BC' fill='%23FFF'/%3E%3C/g%3E%3Cellipse ry='4.46095' rx='4.42997' id='svg_4' cy='8.12474' cx='7.94672' stroke-width='1.5' stroke='%23ffffff' fill='%23ffffff'/%3E%3Ctext style='cursor: move;' xml:space='preserve' text-anchor='start' font-family='Trebuchet MS, Gadget, sans-serif' font-size='14' id='svg_7' y='13.51506' x='4.57003' stroke-width='0' stroke='%23FFF' fill='%230077BC'%3E3%3C/text%3E%3C/g%3E%3C/svg%3E%0A"/>
    <img id="dealer-pin-4"
        src="data:image/svg+xml,%3Csvg width='18' height='24.000000000000004' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='b' d='m28,367.5a2.5,2.5 0 0 1 0,-5a2.5,2.5 0 0 1 0,5m0,-9.5c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7'/%3E%3Cfilter id='a' width='157.1%25' height='140%25' x='-21.4%25' y='-15%25' filterUnits='objectBoundingBox'%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3C/filter%3E%3C/defs%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect x='-1' y='-1' width='3.11524' height='3.48698' id='canvas_background' fill='none'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg fill='none' fill-rule='evenodd' id='svg_1'%3E%3Cuse fill='%23000' filter='url(%23a)' xlink:href='%23b' y='-357' x='-20' id='svg_2'/%3E%3Cuse fill='%23FFF' stroke='%230077BC' xlink:href='%23b' y='-357' x='-20' id='svg_3'/%3E%3C/g%3E%3Cellipse fill='%23ffffff' stroke='%23ffffff' stroke-width='1.5' cx='7.94672' cy='8.12474' id='svg_4' rx='4.42997' ry='4.46095'/%3E%3Ctext fill='%230077BC' stroke='%23FFF' stroke-width='0' x='3.6675' y='13.87607' id='svg_7' font-size='14' font-family='Trebuchet MS, Gadget, sans-serif' text-anchor='start' xml:space='preserve'%3E4%3C/text%3E%3C/g%3E%3C/svg%3E%0A"/>
    <img id="dealer-pin-5"
        src="data:image/svg+xml,%3Csvg width='18' height='24.000000000000004' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='m28,367.5a2.5,2.5 0 0 1 0,-5a2.5,2.5 0 0 1 0,5m0,-9.5c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7' id='b'/%3E%3Cfilter filterUnits='objectBoundingBox' y='-15%25' x='-21.4%25' height='140%25' width='157.1%25' id='a'%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3C/filter%3E%3C/defs%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='6.33213' width='5.2491' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg id='svg_1' fill-rule='evenodd' fill='none'%3E%3Cuse id='svg_2' x='-20' y='-357' xlink:href='%23b' filter='url(%23a)' fill='%23000'/%3E%3Cuse id='svg_3' x='-20' y='-357' xlink:href='%23b' stroke='%230077BC' fill='%23FFF'/%3E%3C/g%3E%3Cellipse ry='4.46095' rx='4.42997' id='svg_4' cy='8.12474' cx='7.94672' stroke-width='1.5' stroke='%23ffffff' fill='%23ffffff'/%3E%3Ctext xml:space='preserve' text-anchor='start' font-family='Trebuchet MS, Gadget, sans-serif' font-size='14' id='svg_7' y='13.87607' x='4.4675' stroke-width='0' stroke='%23FFF' fill='%230077BC'%3E5%3C/text%3E%3C/g%3E%3C/svg%3E%0A"/>
</div>
