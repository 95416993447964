import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

// Turns on Angular's prod mode
if (environment.production) {
    enableProdMode();
}

const isNotPowerpointRequest = localStorage.getItem("agent") !== "puppeteer";

if (environment.heapAnalytics && isNotPowerpointRequest) {
    window["heap"].load(environment.heapAnalytics);
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window["ngRef"]) {
        window["ngRef"].destroy();
    }
    window["ngRef"] = ref;

    // Otherwise, log the boot error
}).catch(err => console.error(err));
