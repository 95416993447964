import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FilterName, FilterStateService } from "@at/core";

@Component({
    selector: "toggle-button",
    templateUrl: "./toggle-button.component.html",
    styleUrls: ["./toggle-button.component.scss"]
})
export class ToggleButtonComponent {
  @Input() optionOne;
  @Input() optionOneLabel;
  @Input() optionTwo;
  @Input() optionTwoLabel;
  @Input() disabled = false;
  @Input() set optionTwoSelected(value: boolean) {
      this._optionTwoSelected = value;
      this.optionTwoSelectedChange.emit(value);
  };
  get optionTwoSelected(): boolean {
      return this._optionTwoSelected;
  };
  @Output() optionTwoSelectedChange = new EventEmitter<boolean>();
  private _optionTwoSelected = true;

  constructor() {}
}
