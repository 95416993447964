import { Injectable } from "@angular/core";
import { BaseChipValue, FilterName } from "@at/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class NextButtonService {

    private nextButtonSubject = new Subject<Event>();
    private previousButtonSubject = new Subject<Event>();
    private sendFilterNameSubject = new Subject<FilterName>();
    private sendChipValuesSubject = new Subject<BaseChipValue>();

    emitNext(event: Event): void {
        this.nextButtonSubject.next(event);
    }

    emitPrevious(event: Event): void {
        this.previousButtonSubject.next(event);
    }

    onNextClicked(): Observable<any> {
        return this.nextButtonSubject.asObservable();
    }

    onPreviousClicked(): Observable<any> {
        return this.previousButtonSubject.asObservable();
    }

    sendCurrentFilter(filter: FilterName): void {
        this.sendFilterNameSubject.next(filter);
    }

    subscribeCurrentFilter(): Observable<any> {
        return this.sendFilterNameSubject.asObservable();
    }

    sendSelectionStatus(chipValue: BaseChipValue): void {
        this.sendChipValuesSubject.next(chipValue);
    }

    subscribeSelectionStatus(): Observable<any> {
        return this.sendChipValuesSubject.asObservable();
    }

}
