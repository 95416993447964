import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { ChartPopupData, ChartPopupDealerModelSales, ChartPopupDealerSales } from "app/core/models/chart-popup.model";
import { Subscription } from "rxjs";

import { ChartPopupService } from "../../../services/chart-popup.service";
import { PopupDirective } from "../../directives/popup.directive";

@Component({
    selector: "chart-popup",
    templateUrl: "./chart-popup.component.html",
    styleUrls: ["./chart-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartPopupComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(PopupDirective) popup: PopupDirective;

    data: ChartPopupData;

    private openSubscription: Subscription;
    private closeSubscription: Subscription;

    // A boolean to switch between total vs dealers sales.
    get isDealerSales(): boolean {
        // Models type popup view should always be in dealer sales view.
        return this.data && (this.data.dealers.length > 1 || this.data.type === "models");
    }

    constructor(
        private elementRef: ElementRef,
        private cdRef: ChangeDetectorRef,
        private chartPopupService: ChartPopupService,
        private sanitization: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.openSubscription = this.chartPopupService.open.subscribe(this.show.bind(this));
        this.closeSubscription = this.chartPopupService.close.subscribe(this.hide.bind(this));
    }

    ngAfterViewInit(): void {
        // Since this component is purely a visual component and the list only changes
        // when new data is pushed (selection on graph), detaching from automatic
        // change detection to prevent unnecessary view update.
        this.cdRef.detach();
    }

    ngOnDestroy(): void {
        this.openSubscription.unsubscribe();
        this.closeSubscription.unsubscribe();
    }

    // Previous is the next value in the array but, represents the previous year.
    compareToPreviousYear(current: number, previous: number): { decrease: boolean; equal: boolean; increase: boolean } {
        // Not of number values are placeholder and should render with the "equal" style.
        if (isNaN(current) || isNaN(previous)) {
            return { decrease: false, equal: true, increase: false };
        }

        if (!current && !previous) {
            return { decrease: false, equal: true, increase: false };
        } else if (previous === undefined) {
            return { decrease: false, equal: true, increase: false };
        } else if (current < previous) {
            return { decrease: true, equal: false, increase: false };
        } else if (current === previous) {
            return { decrease: false, equal: true, increase: false };
        } else {
            // current > previous
            return { decrease: false, equal: false, increase: true };
        }
    }

    uniqueIdForData(dealer: ChartPopupDealerSales, year: string): string {
        return `${dealer.id}-chartpopup-${year}`;
    }

    // Return all makes in the values object.
    getMakes(dealer: ChartPopupDealerModelSales): string[] {
        return Object.keys(dealer.values);
    }

    show(data: ChartPopupData): void {
        this.update(data);
        // If chart is already visible, don't attempt reopen it.
        if (this.popup.visible) {
            return;
        }

        // Default to total sales popup size.
        let popupSize = { height: 115, width: 235 };
        // If the chart is a dealers models sales chart, determine the default height based on number of dealers selected.
        if (this.isDealerSales) {
            popupSize = data.dealers.length > 1 ? { height: 320, width: 360 } : { height: 175, width: 360 };
        }
        const left = data.position.x - popupSize.width - 10;
        let top = data.position.y;
        top = Math.min(window.innerHeight - popupSize.height - 20, top);
        this.popup.setPosition(left, top);
        this.popup.openPopup();
    }

    update(data: ChartPopupData): void {
        if (!data) {
            // console.log(`Attempting to update chart popup with undefined data`);
        }
        this.data = data;
        this.cdRef.detectChanges();
    }

    hide(): void {
        this.data = undefined;
        this.cdRef.detectChanges();
        this.popup.closePopup();
    }

    getColor(item: any): SafeStyle {
        const color = item.color.backgroundColor;
        return item.spotlight ?
            this.sanitization.bypassSecurityTrustStyle(`repeating-linear-gradient(-45deg, transparent, transparent 1px, ${color}, ${color} 4px)`) :
            color;
    }

    markerColor(marker: any): SafeStyle {
        return typeof marker === "object" ?
            this.sanitization.bypassSecurityTrustStyle(`repeating-linear-gradient(-45deg, transparent, transparent 1px, ${marker.hex}, ${marker.hex} 4px)`) :
            marker;
    }
}
