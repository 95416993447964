<card cardTitle="{{cardTitle}} {{displayDates}}" description="{{description}}"
    [disabled]="isDisabled" [isOpen]="isExpanded" [loading]="isLoading" [paddingless]="true" (open)="cardOpened()"
    (close)="cardClosed()">
    <div class="card-minimap-container">
        <mini-map
            *ngIf="isExpanded"
            [spotlightedDealer]="spotlitDealer"
            [dealers] = "dealers"
            [renderedZips]="scaledZips"
            [animateHighlight]="animateMap"
            [showHeatmapLegend]="true"
            [useHeatmapColors]="true"
            [heatmapScales]="scaledZipLevels"
            disclaimer="* Up to Top 25 ZIP Codes displayed on the map, some labels may not be visible."  [radiusRingSize]="ringRadius">
        </mini-map>
    </div>
    <div class="card-controls">
        <dropdown id="ZipsDisplaySelector" [items]="dropdown" [selected]="selectedDropDown"
            (change)="dropdownChanged($event)" leadingText="ZIP Codes Displayed:"></dropdown>
        <button class="export-zip-codes" data-tracking="zip-code-card-export-zips" (click)="exportZipCodes()">
            Export ZIP Codes
        </button>
    </div>
    <primary-and-secondary-sales-zip-table
        [type]="'zips'"
        [zipZoneSales]="salesByZip"
        [showVolume]="showVolume"
        [limit]="selectedDropDown"
        [isMultiDma]="isMultiDMA"
        [showTop25Zips]="canViewUpdatedSalesAndSharesByTopZip">
    </primary-and-secondary-sales-zip-table>
</card>
