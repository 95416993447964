<table class="sbz-headers">
    <tbody>
        <tr class="overview">
            <td [attr.column-name]="'type'">{{type === "zips" ? "Zip Codes" : "Ad Zones"}}</td>
            <td *ngIf="showVolume" [attr.column-name]="'sales'">Sales</td>
            <td [attr.column-name]="'shares'">% of Sales</td>
            <td></td>
        </tr>
        <tr *ngIf="showVolume" class="overview">
            <td [attr.column-name]="'type'">All {{type === "zips" ? "Zips" : "Zones"}} Total</td>
            <td [attr.column-name]="'sales'">{{totalVolume}}</td>
            <td [attr.column-name]="'shares'">100%</td>
            <td></td>
        </tr>
        <tr class="overview">
            <td [attr.column-name]="'type'">Top {{topZipZoneVolumes.length}} {{type === "zips" ? "Zips" : "Zones"}}
                Total</td>
            <td *ngIf="showVolume" [attr.column-name]="'sales'">{{topZipZoneTotalVolume}}</td>
            <td [attr.column-name]="'shares'">{{topZipZoneShares}}%</td>
            <td></td>
        </tr>
    </tbody>
</table>

<table class="sbz-content">
    <tbody>
        <tr *ngFor="let zzv of topZipZoneVolumes; let i = index" [attr.row-index]="i + 1">
            <td>
                <span class="badge" [style.background]="topZipZoneColors[i]?.backgroundColor"></span> {{zzv.zip}}
            </td>
            <td *ngIf="showVolume" [attr.column-name]="'sales'">{{zzv.volume}}</td>
            <td [attr.column-name]="'shares'">{{zzv.shares}}%</td>
            <td></td>
        </tr>
    </tbody>
</table>
