import { DOCUMENT } from "@angular/common";
import { Component, ComponentFactoryResolver, ComponentRef, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { OverlayService, OverlayStatus } from "app/core/services/overlay.service";
import { Subscription } from "rxjs";
import { OverlayOutletDirective } from "../directives/overlay-outlet.directive";
import { FilterSalesRangePanelComponent } from "../filter/filter-sales-range-panel/filter-sales-range-panel.component";
import { FilterSelectionPanelComponent } from "../filter/filter-selection-panel/filter-selection-panel.component";
import { LoadingPanelComponent } from "../loading-panel/loading-panel.component";
import { SpotlightSelectionPanelComponent } from "../spotlight-selection-panel/spotlight-selection-panel.component";


@Component({
    selector: "overlay",
    templateUrl: "./overlay.component.html",
    styleUrls: ["./overlay.component.scss"]
})
export class OverlayComponent implements OnInit, OnDestroy {
    private overlayStateSubscription: Subscription;
    filterName: FilterName;
    oldScrollTop: number;
    @ViewChild(OverlayOutletDirective) view: OverlayOutletDirective;

    @Input() leftPanelOpen;

    hidden = true;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private overlayService: OverlayService,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    ngOnInit(): void {
        this.overlayStateSubscription = this.overlayService.overlayState.subscribe(this.updateOverlay.bind(this));
    }

    updateOverlay(overlayStatus: OverlayStatus): void {
        if (overlayStatus.visible) {
            if (overlayStatus.spotlight) {
                this.showSpotlightPanel();
            } else if (overlayStatus.sales_range) {

                this.showSalesRangePanel();

            } else {
                if (overlayStatus.filterName) {
                    this.showSelectionPanel(overlayStatus.filterName as FilterName);
                } else {
                    this.showLoadingPanel();
                }
            }
        } else {
            this.hide();
        }
    }

    showSalesRangePanel(): void {
        this.oldScrollTop = this.document.body.scrollTop;
        this.document.body.scrollTop = 0;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FilterSalesRangePanelComponent);
        const viewContainer = this.view.viewContainerRef;
        viewContainer.clear();
        viewContainer.createComponent(componentFactory);
        this.hidden = false;
        this.document.body.classList.add("overlay-open");
    }

    showLoadingPanel(): void {
        if (this.hidden !== true) {
            return;
        }
        this.filterName = null;
        this.oldScrollTop = this.document.body.scrollTop;
        this.document.body.scrollTop = 0;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingPanelComponent);
        const viewContainer = this.view.viewContainerRef;
        viewContainer.clear();
        viewContainer.createComponent(componentFactory);
        this.hidden = false;
        this.document.body.classList.add("overlay-open");
    }

    showSelectionPanel(name: FilterName): void {
        this.filterName = name;
        this.oldScrollTop = this.document.body.scrollTop;
        this.document.body.scrollTop = 0;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FilterSelectionPanelComponent);
        const viewContainer = this.view.viewContainerRef;
        viewContainer.clear();
        const component = viewContainer.createComponent(componentFactory) as ComponentRef<FilterSelectionPanelComponent>;
        component.instance.filterName = name;
        this.hidden = false;
        this.document.body.classList.add("overlay-open");
    }

    showSpotlightPanel(): void {
        this.oldScrollTop = this.document.body.scrollTop;
        this.document.body.scrollTop = 0;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SpotlightSelectionPanelComponent);
        const viewContainer = this.view.viewContainerRef;
        viewContainer.clear();
        viewContainer.createComponent(componentFactory);
        this.hidden = false;
        this.document.body.classList.add("overlay-open");
    }

    hide(): void {
        if (this.hidden) {
            return;
        }
        this.filterName = null;
        this.hidden = true;
        this.view.viewContainerRef.clear();
        this.document.body.classList.remove("overlay-open");
        this.document.body.scrollTop = this.oldScrollTop;
    }

    ngOnDestroy(): void {
        if (this.overlayStateSubscription) {
            this.overlayStateSubscription.unsubscribe();
        }
        this.hide();
    }
}
