<div class="dealer-results-details">
    <table class="results-data-table">
        <thead>
            <tr>
                <th></th>
                <ng-container *ngIf="dates.length; else loadingHeader">
                    <th class="data" *ngFor="let date of dates">
                        <pre>{{formatDate(date)}}</pre>
                    </th>
                </ng-container>

                <ng-template #loadingHeader>
                    <th class="data">
                        <div class="loading"></div>
                    </th>
                    <th class="data">
                        <div class="loading"></div>
                    </th>
                    <th class="data">
                        <div class="loading"></div>
                    </th>
                </ng-template>
            </tr>
        </thead>

        <tbody id="{{salesOwnerId}}-results-details-table">
            <ng-container *ngIf="total && isMultiDma; else singleDma">
                <ng-container *ngIf="salesSource; else loadingMultiDma">
                    <tr class="table-row" *ngFor="let row of salesSource; let i = index">
                        <th [class.sub-cat]="i > 0">{{row.dmaName}}</th>
                        <td id="{{row.id}}-sales-{{date}}-entry" class="data" *ngFor="let date of dates">
                            {{row.sales[date]?.toLocaleString("en")}}</td>
                    </tr>
                </ng-container>

                <ng-template #loadingMultiDma>
                    <tr class="table-row" *ngTemplateOutlet="loading"></tr>
                </ng-template>
            </ng-container>

            <ng-template #singleDma>
                <tr id="dealer-sales-result-table" class="table-row" *ngIf="showVolume">
                    <ng-container *ngIf="salesSource; else loading">
                        <th>Sales</th>
                        <td id="{{salesOwnerId}}-sales-{{date}}-entry" class="data" *ngFor="let date of dates">
                            {{salesSource[date]?.toLocaleString("en")}}</td>
                    </ng-container>
                </tr>
            </ng-template>

            <tr id="dealer-shares-result-table" class="table-row" *ngIf="!total">
                <ng-container *ngIf="dealerYearlySales.loaded.value; else loading">
                    <th>Share</th>

                    <td id="{{salesOwnerId}}-shares-{{date}}-entry" class="data" *ngFor="let date of dates">
                        {{formatData(sharesSource[date])| SigFig}}{{ getType(formatData(sharesSource[date])) ==='number' ? '%': ''}}
                    </td>
                </ng-container>
            </tr>

            <ng-template #loading>
                <th>
                    <div class="wide loading"></div>
                </th>
                <td class="data">
                    <div class="loading"></div>
                </td>
                <td class="data">
                    <div class="loading"></div>
                </td>
                <td class="data">
                    <div class="loading"></div>
                </td>
            </ng-template>
        </tbody>
    </table>
</div>
