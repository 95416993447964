import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { DomUtils } from "@at/utils";
import { FilterName } from "app/core/models/filter-name.enum";
import { UserPreferences } from "app/core/models/user-preferences.enum";
import { CognitoService } from "app/core/services/cognito.service";
import { FeatureFlagService } from "app/core/services/feature-flag.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { OverlayService } from "app/core/services/overlay.service";
import { environment } from "environment";
import { Subscription } from "rxjs";

import { IFilterToggleState } from "../filter/filter-toggle-button/filter-toggle-button.component";

@Component({
    selector: "user-menu-panel",
    templateUrl: "./user-menu-panel.component.html",
    styleUrls: ["./user-menu-panel.component.scss"]
})
export class UserMenuPanelComponent implements OnInit, OnDestroy {
    show = true;
    userEmail = "";
    changePasswordSubmenu = false;
    closing = false;

    FilterName = FilterName; // this is here for proper template binding

    appLastUpdated: string = environment.appLastUpdated;
    supportEmail: string = environment.techEmail;

    private menuSubscription: Subscription;

    constructor(
        private overlayService: OverlayService,
        private cognitoService: CognitoService,
        private filterStateService: FilterStateService,
        private featureFlagService: FeatureFlagService,
    ) { }

    ngOnInit(): void {
        this.userEmail = this.cognitoService.getUserEmail();
        this.menuSubscription = this.overlayService.menuState.subscribe((show) => {
            this.closing = false;
            this.show = show;
            this.overlayService.applyNoScroll(this.show);
        });
    }

    ngOnDestroy(): void {
        this.menuSubscription.unsubscribe();
    }

    signOut(): void {
        this.cognitoService.signOut();
        this.closeMenu();
    }

    closeMenu(): void {
        this.overlayService.applyNoScroll(this.show);
        this.closing = true;
        this.changePasswordSubmenu = false;
        setTimeout(() => {
            this.overlayService.hideMenuPanel();
        }, 600); // this ms should match animation time in scss file
    }

    updateToggleValue(state: IFilterToggleState): void {
        if (!state) {
            return;
        }
        this.filterStateService.setFilterValue(state.name, state.value);
    }

    updateSegmentsValue(state: IFilterToggleState): void {
        this.updateToggleValue(state);

        // force reset of User Preferences because these can contain Segments
        this.filterStateService.setUserPreference(UserPreferences.threeYearTrendsCard, null);

        if (Boolean(state.value) === true) {
            this.filterStateService.resetFilter(FilterName.segments);
        } else {
            this.filterStateService.resetFilter(FilterName.ihs_segments);
        }
    }

    @HostListener("document:keydown", ["$event"])
    processGlobalHotkeys(event: KeyboardEvent): void {
        if (event["_stopPropagation"]) {
            return;
        }
        if (event.key === "Escape" || event.code === "27") {
            if (this.show) {
                this.closeMenu();
                DomUtils.stopEventPropagation(event);
            }
        }
    }

    isAdmin(): boolean {
        return this.featureFlagService.viewAdminFeature();
    }
}
