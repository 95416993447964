import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Component({
    selector: "split-button",
    templateUrl: "./split-button.component.html",
    styleUrls: ["./split-button.component.scss"]
})
export class SplitButtonComponent {
  @Input() buttonText = "Top 5";
  @Input() isDisabled = false;

  @Input() optionOneLabel = "Market";
  @Input() optionOneValue = "market";
  @Input() optionOneDisabled = false;

  @Input() optionTwoLabel = "Spotlight Dealer";
  @Input() optionTwoValue = "spotlight";
  @Input() optionTwoDisabled = false;

  @Input() actionButtonClass = "top5";

  @Input() selectedItem = "market";
  @Output() itemChanges = new EventEmitter<string>();

  showDropdown = false;

  constructor() { }

  toggleDropdown(): void {
      this.showDropdown = !this.showDropdown;
  }

  activateMode(): void {
      this.itemChanges.emit(this.selectedItem);
  }

  changeMode(mode: string): void {
      this.selectedItem = mode;

      this.itemChanges.emit(mode);
  }

  @HostListener("document:click", ["$event"]) clickedOutside(event: MouseEvent): void {
      // Click events not coming from the button icon should close the menu.
      if (!(event.target as HTMLElement).className.includes("button")) {
          this.showDropdown = false;
      }
  }
}
