import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Polygon } from "@turf/helpers";
import { MapFilterControlsComponent } from "app/core/components/map-filter-controls/map-filter-controls.component";
import { MapUtils, MapUtilsResponse } from "app/core/components/map/map-utils";
import { DealerSharePopupComponent } from "app/core/components/results/dealer-share-popup/dealer-share-popup.component";
import { ZipZoneSalesPopupComponent } from "app/core/components/results/zip-zone-sales-popup/zip-zone-sales-popup.component";
import { ToastComponent } from "app/core/components/toast/toast.component";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { SpotlightSalesAreas } from "app/core/models/spotlight.enum";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetricsService } from "app/core/services/metrics.service";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import { HeatmapScaleLevels, SalesDataService } from "app/core/services/sales-data.service";
import { ZipZoneService } from "app/core/services/zip-zone.service";
import { environment } from "environment";
import { Feature, FeatureCollection, GeometryObject } from "geojson";
import { assign, debounce, flatten, uniqBy, without, xor } from "lodash";
import * as _ from "lodash";
import { GeoJSONGeometry, LngLatBoundsLike } from "mapbox-gl";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

let addMapTestingHarness;

export interface MapMouseFeaturesEvent extends mapboxgl.MapMouseEvent {
    // this interface is absent from the typings, implements exactly what the event returns.
    features?: Feature<GeometryObject>[];
}

@Component({ template: "" })
export abstract class AbstractMapComponent implements OnInit, OnDestroy {
    @ViewChild(ZipZoneSalesPopupComponent) zipZoneSalesPopupComponent: ZipZoneSalesPopupComponent;
    @ViewChild(DealerSharePopupComponent) dealerSharePopupComponent: DealerSharePopupComponent;
    @ViewChild("filterControls") mapFilterControlsComponent: MapFilterControlsComponent;
    @ViewChild("map", { static: true }) mapElement: ElementRef;

    @Input() extendMapLeft = false;
    @Input() extendMapRight = false;
    @Input() leftPanelOpen = true;
    @Input() rightPanelOpen = true;
    @Input() toastComponent: ToastComponent;

    map: mapboxgl.Map;
    mapUtils: MapUtils = new MapUtils();

    selectedZips: string[];
    selectedDealer: Dealer;
    spotlightedDealer: Dealer;
    zipcodeHeatMapLevels: string[] = [];
    zoomBoundingBox: LngLatBoundsLike;
    recentClick: boolean;
    heatmapScales: HeatmapScaleLevels[] = [];
    mapType: String;

    spotlightDealerDetailsSubscription: Subscription;
    filterStateServiceSubscription: Subscription;

    radiusRingFeature: MapUtilsResponse;
    mapInitialized = false;
    zipVolumeSubscription: Subscription;
    heatmapScalingsSubscription: Subscription;
    dealerDetailsSubscription: Subscription;
    animationTimer: ReturnType<typeof setInterval | typeof clearTimeout>;

    zipCodeLayerKey = "ZCTA5CE10"; // Magic key from US census shape file data.
    radiusRingId = "radius-ring";
    mapConfig: mapboxgl.MapboxOptions = {
        container: "map",
        style: "mapbox://styles/mediasolutions/ck3egoxxl0a8e1dqfr2wsyddf", // Auto Tool 3.4 Prod - Owner: Tim Case (migrated to newer Mapbox Studio)
        zoom: 3,
        minZoom: 2,
        center: [-98.35, 39.50], // [lng,lat] Geographic center of continental usa
        attributionControl: false
    };

    styleSourceKeys: { sourceId: string; sourceLayerId: string };

    constructor(
        protected salesDataService: SalesDataService,
        protected dealerDataService: DealerDataService,
        protected filterStateService: FilterStateService,
        protected zipZoneService: ZipZoneService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected ngrxFilterStateService: NgrxFilterStateService,
        protected metricsService: MetricsService,
        mapType: String
    ) {
        (mapboxgl as any).accessToken = environment.mapbox.accessToken;
        this.zipCodeClick = this.zipCodeClick.bind(this);
        this.updateRadiusRing = debounce(this.updateRadiusRing.bind(this), 50);
        this.mapType = mapType;
    }

    ngOnInit(): void {
        this.map = new mapboxgl.Map(this.mapConfig).addControl(new mapboxgl.AttributionControl({ compact: true }));
        this.map.once("load", this.initializeMap.bind(this));

        const saveStyleSourceId = (sourceData) => {
            if (sourceData.isSourceLoaded && sourceData.source.type === "vector") {
                this.map.off("sourcedata", saveStyleSourceId);
                this.styleSourceKeys = {
                    sourceId: sourceData.sourceId,
                    sourceLayerId: sourceData.style.stylesheet.layers.find(l => l.id.includes("zipcodes-heatmap"))["source-layer"]
                };
            }
        };
        this.map.on("sourcedata", saveStyleSourceId);
    }

    ngOnDestroy(): void {
        this.map.remove();
        clearTimeout(this.animationTimer as any);

        if (this.dealerDetailsSubscription) {
            this.dealerDetailsSubscription.unsubscribe();
        }
        if (this.zipVolumeSubscription) {
            this.zipVolumeSubscription.unsubscribe();
        }
        if (this.heatmapScalingsSubscription) {
            this.heatmapScalingsSubscription.unsubscribe();
        }
        if (this.spotlightDealerDetailsSubscription) {
            this.spotlightDealerDetailsSubscription.unsubscribe();
        }
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
        }
    }

    abstract dealerPinClick(e: MapMouseFeaturesEvent): void;
    abstract updatePinHighlight(): void;
    abstract updatePinLayerFilters(): void;
    abstract zipCodeClick(e: MapMouseFeaturesEvent): void;

    zipZoneMode(): boolean {
        return this.salesDataService.zipZoneMode;
    }

    zoomMapToFeatures(): void {
        let updateZoom = false;

        const dealers = this.dealerDataService.dealerDetails.getValue();
        let combinedCoordinates = this.mapUtils.determineCoordinatesForDealers(this.spotlightedDealer, dealers);

        // zooms map to include the spotlighted dealer
        if (this.spotlightedDealer || (dealers && dealers.length)) {
            updateZoom = true;
        }

        // fit to radius ring
        if (this.radiusRingFeature && this.radiusRingFeature.bbox) {
            // ne & sw corners of radius ring bounding box
            combinedCoordinates = this.mapUtils.enlargeBoundingBox(combinedCoordinates, this.radiusRingFeature.bbox[0]);
            combinedCoordinates = this.mapUtils.enlargeBoundingBox(combinedCoordinates, this.radiusRingFeature.bbox[1]);
            updateZoom = true;
        }

        if (updateZoom) {
            this.map.setPitch(0);
            this.zoomBoundingBox = [combinedCoordinates.sw, combinedCoordinates.ne];
            this.map.fitBounds(this.zoomBoundingBox, { maxZoom: 9, padding: 60 });
        }
    }

    resetMapZoomLevel(): void {
        this.map.fitBounds(this.zoomBoundingBox, { maxZoom: 9, padding: 60 });
    }

    addEmptyDealerLayers(): void {
        this.mapUtils.addPinHighlightLayer(this.map); // Set up source and layer for radius circle.
        this.mapUtils.addEmptyIconLayer(this.map, "dealerPins", "dealerPinIcon"); // empty layer for unselected dealer pins
        this.mapUtils.addEmptyIconLayer(this.map, "dealerPinsFocused", "dealerPinFocusedIcon", ["==", ["get", "dealer_id"], ""]); // empty layer for focused dealer pin
        this.mapUtils.addEmptyIconLayer(this.map, "dealerPinsSpotlight", "dealerPinSpotlightIcon", ["==", ["get", "dealer_id"], ""]); // empty layer for spotlight dealer pin
    }

    updateRenderedZipVolume(scaledZips: string[][]): void {
        const startTime = Date.now();
        if (!this.mapInitialized) {
            return;
        }
        this.zipcodeHeatMapLevels = [];

        for (let i = 0; i < scaledZips.length; i++) {
            this.zipcodeHeatMapLevels.push("zipcodes-heatmap-" + i);
            this.map.setFilter(this.zipcodeHeatMapLevels[i], ["in", this.zipCodeLayerKey].concat(scaledZips[i]));
        }

        this.map.setFilter("zipcodes-select", ["==", this.zipCodeLayerKey, ""]);
        this.map.setFilter("zipcodes-select-border", ["==", this.zipCodeLayerKey, ""]);

        this.updateZipClicks();

        this.makeHeatMapLevelsVisible();
        this.metricsService.sendMetric({
            metricName: "update_render_zip_volume",
            metricValue: (Date.now() - startTime) / 1000.0,
            unit: "Seconds",
            dimensions: [
                {
                    Name: "Map Type",
                    Value: this.mapType as string
                }
            ]
        });
    }

    makeHeatMapLevelsVisible(): void {
        const makeLevelsVisible = (sourceData) => {
            if (sourceData.isSourceLoaded) {
                this.map.off("sourcedata", makeLevelsVisible);

                for (let i = 0; i < this.zipcodeHeatMapLevels.length; i++) {
                    this.map.setLayoutProperty(this.zipcodeHeatMapLevels[i], "visibility", "visible");
                }

                this.map.setLayoutProperty("zipcodes-select-border", "visibility", "visible");
            }
        };

        this.map.on("sourcedata", makeLevelsVisible);
    }

    updateHeatmapScalings(scaledZipsLevels: HeatmapScaleLevels[]): void {
        this.heatmapScales = scaledZipsLevels;

        // push down changes to the heatmap legend component
        // it won't rerender without this line due to the OnPush change detection strategy
        if (!this.changeDetectorRef["destroyed"]) {
            this.changeDetectorRef.detectChanges();
        }
    }

    excludeZipCodeFromHeatmap(excludeZips: string[]): void {
        for (let j = 0; j < excludeZips.length; j++) {
            const zip = excludeZips[j];
            for (let i = 0; i < this.zipcodeHeatMapLevels.length; i++) {
                let filter = this.map.getFilter(this.zipcodeHeatMapLevels[i]);
                if (filter.includes(zip)) {
                    filter = without(filter, zip);
                    this.map.setFilter(this.zipcodeHeatMapLevels[i], filter);
                    break;
                }
            }
        }
    }

    resetZipCodeExclusion(): void {
        const scaledZips = this.salesDataService.scaledZips.value;
        for (let i = 0; i < this.zipcodeHeatMapLevels.length; i++) {
            const filter = this.map.getFilter(this.zipcodeHeatMapLevels[i]);
            if (scaledZips[i].length !== (filter.length - 2)) {
                this.map.setFilter(this.zipcodeHeatMapLevels[i], ["in", this.zipCodeLayerKey].concat(scaledZips[i]));
                return;
            }
        }
    }

    updateRenderedDealers(dealers: Dealer[]): void {
        const startTime = Date.now();
        const mutableDealers = _.cloneDeep(dealers);
        if (!this.mapInitialized) {
            return;
        }

        if (this.dealerSharePopupComponent && this.dealerSharePopupComponent.popup) {
            this.dealerSharePopupComponent.popup.closePopup();
        }
        if (this.zipZoneSalesPopupComponent && this.zipZoneSalesPopupComponent.popup) {
            this.zipZoneSalesPopupComponent.popup.closePopup();
        }

        if (this.spotlightedDealer && !mutableDealers.find(dealer => dealer.dealer_id === this.spotlightedDealer.dealer_id)) {
            mutableDealers.push(this.spotlightedDealer);
            let dealersFilterValue = this.ngrxFilterStateService.getFilter(FilterName.dealers);
            let dealersFilterValueType = typeof (dealersFilterValue[0]);
            if (dealersFilterValueType !== "number") {
                this.ngrxFilterStateService.setFilter(FilterName.dealers, mutableDealers);
            }
            this.updateSpotlightCursor();
        }

        const data = this.mapUtils.createDataSource(mutableDealers);
        (this.map.getSource("dealerPins") as mapboxgl.GeoJSONSource).setData(data);

        const onLoad = async (loaded) => {
            if (loaded.dataType === "source" && loaded.isSourceLoaded) {
                this.map.off("data", onLoad);
                this.updatePinLayerFilters();
                await this.updateRadiusRing();
                this.updatePinHighlight();
            }
        };
        this.map.on("data", onLoad);
    }

    getPopupStartPosition(width: number): { left: number; top: number } {
        if (typeof this.zipZoneSalesPopupComponent !== "undefined" && this.zipZoneSalesPopupComponent.popup.visible) {
            return this.zipZoneSalesPopupComponent.popup.getPosition();
        }
        if (typeof this.dealerSharePopupComponent !== "undefined" && this.dealerSharePopupComponent.popup.visible) {
            return this.dealerSharePopupComponent.popup.getPosition();
        }

        const top = this.toastComponent && this.toastComponent.show ? 168 : 80;
        const left = (this.mapElement.nativeElement.offsetLeft
            + this.mapElement.nativeElement.offsetWidth) - (width + 20);
        return { left, top };
    }

    setRecentClick(): void {
        this.recentClick = true;
        setTimeout(() => this.recentClick = false, 10);
    }

    initializeMap(): void {
        const startTime = Date.now();
        const pin = document.getElementById("dealer-pin") as HTMLImageElement;
        const pinFocused = document.getElementById("dealer-pin-focused") as HTMLImageElement;
        const pinSpotlight = document.getElementById("dealer-pin-spotlight") as HTMLImageElement;

        this.map.addImage("dealerPinIcon", pin);
        this.map.addImage("dealerPinFocusedIcon", pinFocused);
        this.map.addImage("dealerPinSpotlightIcon", pinSpotlight);

        this.map.on("click", "dealerPins", this.dealerPinClick.bind(this));
        this.map.on("click", "dealerPinsSpotlight", this.dealerPinClick.bind(this));

        this.map.on("mouseenter", "dealerPins", this.onMouseEnter);
        this.map.on("mouseleave", "dealerPins", this.onMouseLeave);

        this.addEmptyDealerLayers();
        this.addZoneBorderLayer(this.map);
        this.updateZipClicks();
        this.mapInitialized = true;

        this.salesDataService.updateZipVolume();

        this.zipVolumeSubscription = this.salesDataService.scaledZips.pipe(debounceTime(300)).subscribe(this.updateRenderedZipVolume.bind(this));
        this.heatmapScalingsSubscription = this.salesDataService.scaledZipLevels.pipe(debounceTime(300)).subscribe(this.updateHeatmapScalings.bind(this));

        this.dealerDetailsSubscription = this.dealerDataService.dealerDetails.pipe(debounceTime(300)).subscribe(this.updateRenderedDealers.bind(this));
        this.spotlightDealerDetailsSubscription = this.dealerDataService.spotlightDealerDetails.subscribe(this.updateSpotlightedDealer.bind(this));
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.filtersUpdated([FilterName.show_ring.toString()]);

        // Used by E2E tests
        addMapTestingHarness(this.map);

        this.metricsService.sendMetric({
            metricName: "initialize_map",
            metricValue: (Date.now() - startTime) / 1000.0,
            unit: "Seconds",
            dimensions: [
                {
                    Name: "Map Type",
                    Value: this.mapType as string
                }
            ]
        });
    }

    updateZipClicks(): void {
        for (let i = 0; i < this.zipcodeHeatMapLevels.length; i++) {
            this.map.off("click", this.zipcodeHeatMapLevels[i], this.zipCodeClick);
            this.map.on("click", this.zipcodeHeatMapLevels[i], this.zipCodeClick);
        }
    }

    addZoneBorderLayer(map: mapboxgl.Map, layerId: string = "zone-border", sourceId: string = "zone-border-source", fillColor?: string): MapUtilsResponse {
        map.addSource(sourceId, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: []
            }
        });
        map.addLayer({
            id: layerId,
            type: "line",
            layout: {
                visibility: "visible"
            },
            paint: {
                "line-color": "#0077bc",
                "line-width": 3
            },
            source: sourceId
        });
        if (fillColor) {
            map.addLayer({
                id: `${layerId}-paint`,
                type: "fill",
                layout: {
                    visibility: "visible"
                },
                paint: {
                    "fill-color": fillColor,
                    "fill-opacity": 0.3
                },
                source: sourceId
            });
        }
        // eslint-disable-next-line object-shorthand
        return { layerId: layerId, sourceId: sourceId };
    }

    // Fetch selected features from the pin layer and use that to set the features on the selected layer.
    // This is necessary because "juked" pin locations are randomly generated upon initial pin creation
    // and creating the pins again will lose the "juked" position.
    setPinFocus(layer: string, dealerId: number): void {
        const fc = (this.map.getSource("dealerPins") as any).serialize() as { data: FeatureCollection<GeometryObject> };
        const feature = fc.data.features.find(f => f.properties.dealer_id === dealerId);
        const selectedData = {
            type: "FeatureCollection",
            features: feature ? [feature] : []
        } as FeatureCollection<GeoJSONGeometry>;
        (this.map.getSource(layer) as mapboxgl.GeoJSONSource).setData(selectedData);
    }

    async filtersUpdated(changes: string[]): Promise<void> {
        const startTime = Date.now();
        if (this.spotlightedDealer) {
            if (changes.includes(FilterName.show_ring.toString()) || changes.includes(FilterName.ring_radius.toString())) {
                await this.updateRadiusRing();
            }
            if (changes.includes(FilterName.zip_zone_flag.toString()) && this.salesAreaIsRadius()) {
                await this.filterZipsOnRadiusRing();
            }
        }
        this.metricsService.sendMetric({
            metricName: "change_map",
            metricValue: (Date.now() - startTime) / 1000.0,
            unit: "Seconds",
            dimensions: [
                {
                    Name: "Map Type",
                    Value: this.mapType as string
                }
            ]
        });
    }

    async updateRadiusRing(): Promise<void> {
        if (this.spotlightedDealer) {
            if (this.ngrxFilterStateService.getFilter(FilterName.show_ring)) {
                const options = {
                    id: this.radiusRingId,
                    center: [this.spotlightedDealer.dealer_longitude, this.spotlightedDealer.dealer_latitude],
                    radiusInMiles: this.ngrxFilterStateService.getFilter(FilterName.ring_radius)
                };
                this.radiusRingFeature = this.mapUtils.addRadiusRingLayer(this.map, options);
                if (this.mapInitialized && this.styleSourceKeys && this.salesAreaIsRadius()) {
                    await this.filterZipsOnRadiusRing();
                }
            } else {
                this.removeRadiusRing();
            }
        } else {
            this.removeRadiusRing();
        }
    }

    removeRadiusRing(): void {
        // remove old radius ring, shadow and ring bounding box
        this.mapUtils.removeLayer(this.map, this.radiusRingId);
        this.mapUtils.removeSource(this.map, this.radiusRingId);
        this.radiusRingFeature = undefined;
    }

    async filterZipsOnRadiusRing(): Promise<void> {
        // MapboxGl has not implemented array.includes functionality for mapbox expressions
        // e.g ["in", 1, [1, 2]]. It can only currently looks for properties within an object.
        // https://github.com/mapbox/mapbox-gl-js/pull/7197
        let zipsHash = await this.filterStateService.getFullFilterOptions(FilterName.zips, [FilterName.sales_range.toString()]).toPromise();

        zipsHash = zipsHash.reduce((m, z) => {
            m[z.buyer_zip] = true;
            return m;
        }, {});


        // Get features within viewport that has in the zip hash.
        // This may return multiple instance of the same feature if the feature spans over multiple tiles.
        const features = this.map.querySourceFeatures(
            this.styleSourceKeys.sourceId,
            {
                sourceLayer: this.styleSourceKeys.sourceLayerId,
                filter: ["has", ["to-string", ["get", "ZCTA5CE10"]], ["literal", zipsHash]]
            }
        );

        const uniq = {};
        // Check for intersections between features and the radius circle.
        const intersectingFeatures = features.reduce((m, f) => {
            if (!uniq[f.id] && this.mapUtils.polygonsIntersect((this.radiusRingFeature.feature as Feature<Polygon>).geometry, f.geometry as any)) {
                m.push(f);
                uniq[f.id] = true;
            }
            return m;
        }, []);

        let intersectingZips = intersectingFeatures.map(f => f.properties[this.zipCodeLayerKey]);
        if (this.ngrxFilterStateService.getFilter(FilterName.zip_zone_flag) === "zone") {
            intersectingZips = flatten(await this.zipZoneService.getZipsForZonesByZips(intersectingZips));
        }

        // Set the zips filter to the intersecting zips.
        if (intersectingZips.length > 0 && xor(this.ngrxFilterStateService.getFilter(FilterName.sales_area_zips), intersectingZips).length > 0) {
            this.ngrxFilterStateService.setFilter(FilterName.sales_area_zips, intersectingZips);
            this.filterStateService.emitChange("zips");
        }
    }

    async updateSpotlightedDealer(dealer: Dealer): Promise<void> {
        const startTime = Date.now();
        this.spotlightedDealer = dealer && dealer.dealer_id ? dealer : null;
        this.updateSpotlightCursor();
        this.updatePinHighlight();
        this.updatePinLayerFilters();
        await this.updateRadiusRing();
        this.zoomMapToFeatures();
        this.metricsService.sendMetric({
            metricName: "update_spotlight_dealer",
            metricValue: (Date.now() - startTime) / 1000.0,
            unit: "Seconds",
            dimensions: [
                {
                    Name: "Map Type",
                    Value: this.mapType as string
                }
            ]
        });
    }

    updateSpotlightCursor(): void {
        if (this.spotlightedDealer) {
            const spotlight = this.dealerDataService.dealerDetails.getValue().find((dealer) => dealer.dealer_id === this.spotlightedDealer.dealer_id);
            if (spotlight && this.ngrxFilterStateService.getFilter(FilterName.include_spotlight) === "include") {
                this.map.on("mouseenter", "dealerPinsSpotlight", this.onMouseEnter);
                this.map.on("mouseleave", "dealerPinsSpotlight", this.onMouseLeave);
                return;
            }
        }
        this.map.off("mouseenter", "dealerPinsSpotlight", this.onMouseEnter);
        this.map.off("mouseleave", "dealerPinsSpotlight", this.onMouseLeave);
    }

    protected onMouseEnter(): void {
        // 'this' is the map context because it was implicitly bound.
        (this as any).getCanvas().style.cursor = "pointer";
    }

    protected onMouseLeave(): void {
        // 'this' is the map context because it was implicitly bound.
        (this as any).getCanvas().style.cursor = "";
    }

    protected salesAreaIsRadius(): boolean {
        return this.ngrxFilterStateService.getFilter(FilterName.sales_area_selection) === SpotlightSalesAreas.Radius;
    }
}

// Used by E2E tests
(() => {
    let resolveFunc;
    const initialized = new Promise((resolve) => {
        resolveFunc = resolve;
    });
    initialized["resolve"] = resolveFunc;
    const mapTesting = {
        initialized: () => initialized
    };
    window["mapTesting"] = mapTesting;
})();

addMapTestingHarness = (map: mapboxgl.Map): void => {
    const mapTesting = assign(window["mapTesting"], {
        distinctDealerPinsOnMap: () => {
            const canvas = map.getCanvas();
            const features = map.queryRenderedFeatures([[0, 0], [canvas.width, canvas.height]], { layers: ["dealerPins"] });
            return uniqBy(features.map(f => f.properties), "dealer_id");
        },
        clickMapXY: (x, y) => {
            const lngLat = map.unproject([x, y]);
            map["fire"]("click", { point: [x, y], lngLat }); // Hack for getting around private events, no other way to simulate a mouse click on map
        },
        clickMapLngLat: (lng, lat) => {
            const point = map.project([lng, lat]);
            map["fire"]("click", { point, lngLat: [lng, lat] });
        },
        mapDataChanged: () => new Promise<number>((resolve) => {
            map.once("data", () => setTimeout(resolve, 0));
        }),
        mapZoomChanged: () => new Promise<number>((resolve) => {
            if (map.isMoving()) {
                map.once("zoomend", () => setTimeout(resolve, 0));
            } else {
                setTimeout(resolve, 0);
            }
        }),
        mapZoomComplete: () => new Promise((resolve) => {
            if (map.isMoving()) {
                map.once("zoomend", () => setTimeout(resolve, 0));
                const iv = setInterval(() => {
                    if (!map.isMoving()) {
                        setTimeout(resolve, 0);
                        clearInterval(iv);
                    }
                }, 50);
            } else {
                setTimeout(resolve, 0);
            }
        }),
        mapStylingLoaded: () => new Promise((resolve) => {
            if (map.isStyleLoaded()) {
                setTimeout(resolve, 0);
            } else {
                map.once("styledata", () => setTimeout(resolve, 0));
                const iv = setInterval(() => {
                    if (map.isStyleLoaded()) {
                        setTimeout(resolve, 0);
                        clearInterval(iv);
                    }
                }, 50);
            }
        })
    });
    window["mapTesting"] = mapTesting;
    mapTesting["initialized"]().resolve();
};
