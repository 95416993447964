<selection-section
    [dataService]="salesDataService"
    [filterService]="filterStateService"
    [filterName]="FilterName.dealers"
    [titleTemplate]="titleTemplate"
    [doneTemplate]="doneTemplate"
    [topXButtonDisabled]="topXButtonDisabled"
    [selectionsLimit]="selectionsLimit"
    [clearButtonDisabled]="clearButtonDisabled"
    [searchButtonDisabled]="searchButtonDisabled"
    [radiusRingButtonDisabled]="radiusRingButtonDisabled"
    [excludeSpotlightedDealer]="false"
    [sortAreaDisabled]="true"
    [searchOpenState]="searchOpenState"></selection-section>

<ng-template #titleTemplate>
    {{title}}
</ng-template>

<ng-template #doneTemplate>
    <div #donebutton class="close-button" (click)="closePanel()">
        <div class="label">Done</div>
        <div class="icon"></div>
    </div>
</ng-template>
