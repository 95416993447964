export class DiagonalPattern {
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;

    private defaults: { [key: string]: any } = {
        size: 20,
        backgroundColor: "white",
        strokeStyle: "black",
        lineWidth: 1
    };

    constructor(private configs?: { [key: string]: any }) {
        this.createDefaults(configs);
    }

    draw(backgroundColor: string, opacity: number = 1): CanvasPattern {
        if (backgroundColor && backgroundColor[0] !== "#") {
            backgroundColor = "#" + backgroundColor;
        }

        // Create a new canvas and drawing context.
        this.setupCanvas();

        // Setup pattern specifics.
        this.context.fillStyle = this.hexToRGBA(backgroundColor || this.configs.size.backgroundColor, opacity);
        this.context.strokeStyle = this.configs.strokeStyle;
        this.context.lineWidth = this.configs.lineWidth;

        // Draw pattern.
        this.render();

        // HACK: Allow CanvasPattern to modify it's own opacity and return a new version of itself.
        const pattern = this.context.createPattern(this.canvas, "repeat") as any;
        pattern.hex = backgroundColor;
        pattern.opacity = (value: number): CanvasPattern => this.draw(backgroundColor, value);
        return pattern;
    }

    drawDiagonalLine(offsetX: number = 0, offsetY: number = 0): void {
        const size = this.configs.size;
        const halfSize = size / 2;
        const gap = 1;

        this.context.moveTo((halfSize - gap) - offsetX, (gap * -1) + offsetY);
        this.context.lineTo((size + 1) - offsetX, (halfSize + 1) + offsetY);
        this.context.closePath();
    }

    private createDefaults(configs: { [key: string]: any }): { [key: string]: any } {
        for (const key in this.defaults) {
            if (!configs[key]) {
                configs[key] = this.defaults[key];
            }
        }

        return configs;
    }

    private render(): void {
        const halfTile = this.configs.size / 2;
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

        this.context.beginPath();
        this.drawDiagonalLine();
        this.drawDiagonalLine(halfTile, halfTile);
        this.context.stroke();
    }

    private setupCanvas(): void {
        this.canvas = document.createElement("canvas");
        this.canvas.width = this.configs.size;
        this.canvas.height = this.configs.size;

        this.context = this.canvas.getContext("2d");
        this.context.translate(this.configs.size, 0);
        this.context.rotate(90 * Math.PI / 180);
    }

    private hexToRGBA(hex: string, alpha: number = 1): string {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})` : null;
    }
}

