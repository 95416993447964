import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environment";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/pipeable";
import * as R from "fp-ts/Record";
import * as S from "fp-ts/Semigroup";
import { Observable, Subject } from "rxjs";
import { HttpServiceBase } from "../base/services/http-service-base.service";

// eslint-disable-next-line import/no-deprecated
const lastSemigroup = S.getLastSemigroup<string>();

type Params = Record<string, string | string[]>;

type Headers = Record<string, string>;

interface HttpOpts {
  observe: "response";
  responseType: "text";
  params: Params;
  headers: Headers;
  withCredentials: boolean;
}

interface HttpOptsCfg {
  hasJson: boolean;
  withCsrf: boolean;
  withBearer?: string;
  params: O.Option<Params>;
}

@Injectable()
export class USPSZipService extends HttpServiceBase {

    private csrf: O.Option<string> = O.none;
    protected path = "/autoanalyzer";

    constructor(
        protected http: HttpClient
    ) {
        super(http);
    }

    private getHeaders(): Headers {
        const headers: [string, O.Option<string>][] = [
            ["Accept", O.some("*/*")],
            ["Content-Type", O.some("*/*")],
            ["X-Skip-Token-Interceptor", O.some("x-skip-token-interceptor")],
            ["X-Skip-Interceptor", O.some("x-skip-interceptor")]
        ];

        // eslint-disable-next-line import/no-deprecated
        return pipe(
            headers,
            A.filterMap(([name, maybeValue]) =>
            // eslint-disable-next-line import/no-deprecated
                pipe(
                    maybeValue,
                    O.map<string, [string, string]>(value => [name, value]),
                ),
            ),
            // eslint-disable-next-line import/no-deprecated
            R.fromFoldable(lastSemigroup, A.array),
        );
    }

    private getHttpOpts(cfg: HttpOptsCfg): HttpOpts {
        return {
            observe: "response",
            responseType: "text",
            // eslint-disable-next-line import/no-deprecated
            params: pipe(
                cfg.params,
                O.getOrElse(() => ({})),
            ),
            withCredentials: true,
            headers: this.getHeaders(),
        };
    }

    private buildXMLRequest(zips: string[]): string {
        let xml = `<CityStateLookupRequest USERID="${environment.uspsCharterUser}">`;
        zips.forEach((zip, index) => {
            xml += `<ZipCode ID='${index}'><Zip5>${zip}</Zip5></ZipCode>`;
        });
        xml += "</CityStateLookupRequest>";
        return xml;
    }

    validateZips(zips: string[]): Promise<any> {
        const url = `${this.rootUrl}${this.path}/validatezips`;
        let XML = this.buildXMLRequest(zips);
        return this.postInterfaceNoCache(
            url,
            this.createRequestOptions({ XML })
        ).toPromise();
    }

}
