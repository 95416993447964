<div class="map-filter-controls noselect" [class.right-panel-open]="rightPanelOpen" [class.mvm]="isMvm">
    <ng-container *ngIf="!visibleState">
        <div class="initial-state-icon" (click)="openControls()" [dTooltip]="zipzoneToggle"
            [placement]="rightPanelOpen ? 'right' : 'left'"></div>
    </ng-container>
    <ng-container *ngIf="visibleState">
        <div class="filter-controls map-popup noselect">
            <div class="result-title noselect">
                Map Settings
                <div class="zip-zone-popup-close close" (click)="closeControls()">
                    <div class="icon"></div>
                </div>
            </div>
            <filter-toggle-button class="toggle-area" label="Heat Map" optionOneLabel="Zip" optionOne="zip"
                optionTwoLabel="Zone" optionTwo="zone" filterName="{{FilterName.zip_zone_flag}}"
                (toggle)="updateToggleValue($event)"></filter-toggle-button>
        </div>
    </ng-container>
</div>

<ng-template #zipzoneToggle>
    Displaying {{zipZoneValue === 'zone' ? 'ad zone' : 'zip code'}} heat map
</ng-template>
