import { Component, OnDestroy, OnInit } from "@angular/core";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { OverlayService } from "app/core/services/overlay.service";
import { environment } from "environment";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";

@Component({
    selector: "offline-panel",
    templateUrl: "./offline-panel.component.html",
    styleUrls: ["./offline-panel.component.scss"]
})
export class OfflinePanelComponent implements OnInit, OnDestroy {

    techEmail = environment.techEmail;
    show = false;
    showBackground = false;
    maintenance = false;

    reloadTimeout: ReturnType<typeof setTimeout>;;

    private offlineSubscription: Subscription;
    private loadingSub: Subscription;

    constructor(
        private overlayService: OverlayService,
        private dealerDataService: DealerDataService,
        private cookieService: CookieService
    ) { }

    ngOnInit(): void {
        this.offlineSubscription = this.overlayService.offlineState.subscribe((errorCode: number) => {
            // Any error code will display the offline panel.
            this.show = errorCode === null || errorCode === undefined ? false : true;
            this.maintenance = errorCode === 503; // server unavailable error code will display the maintenance page
            this.overlayService.applyNoScroll(this.show);
            if (!this.show && this.reloadTimeout) { // clear the timeout if the offline overlay is not active
                clearTimeout(this.reloadTimeout);
            }
            if (this.show && !this.reloadTimeout) { // set a timeout to reload client
                this.reloadTimeout = setTimeout(() => {
                    this.refresh();
                }, 60 * 60 * 1000); // 1 hour
            }
        });
        this.loadingSub = this.dealerDataService.dealerDetails.subscribe(this.allowVisibleBackground.bind(this));
    }

    allowVisibleBackground(dealers: any[]): void {
        this.showBackground = dealers.length ? true : false;
    }

    ngOnDestroy(): void {
        this.offlineSubscription.unsubscribe();
        this.loadingSub.unsubscribe();
    }

    refresh(): void {
        this.cookieService.deleteAll(); // clear all cookies in case the filters parameters have changed between different versions
        window.location.reload();
    }
}
