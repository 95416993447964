import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CoreModule as KiteCoreModule } from "@kite/angular-kite";
/** Kite Imports **/
import { ChartsModule } from "ng2-charts";
import { CookieService } from "ngx-cookie-service";

import { AbstractPresentationDetailsComponent } from "./base/components/details/details.component.abstract";
import { PresentationNavItemComponent } from "./base/components/nav-item/nav-item.component";
import { ProgressItemComponent } from "./base/components/progress-item/progress-item.component";
import { AbstractSelectionPanelComponent } from "./base/components/selection-panel/selection-panel.component.abstract";
import { FILTER_STATE, NGRX_TOKEN } from "./base/components/tokens/tokens";
import { AbstractPageComponent } from "./base/pages/presentation/page.component.abstract";
import { FilterBaseService } from "./base/services/filter-base.service";
import { AccordionComponent } from "./components/accordion/accordion.component";
import { CardComponent } from "./components/cards/card/card.component";
import { DealerMakeByAdZoneCardComponent } from "./components/cards/dealer-make-by-ad-zone-card/dealer-make-by-ad-zone-card.component";
import { DealerMakeSegmentInAdZoneCardComponent } from "./components/cards/dealer-make-segment-in-ad-zone/dealer-make-segment-in-ad-zone.component";
import { DealerOpportunityInAdZoneCardComponent } from "./components/cards/dealer-opportunity-in-ad-zone/dealer-opportunity-in-ad-zone.component";
import { DealerSalesSharesCardComponent } from "./components/cards/dealer-sales-shares-card/dealer-sales-shares-card.component";
import { DealerZoneByModelsComponent } from "./components/cards/dealer-zone-by-models/dealer-zone-by-models.component";
import { IndividualModelSalesCardComponent } from "./components/cards/individual-model-sales-shares-card/individual-model-sales-shares-card.component";
import { MarketMakeSalesCardComponent } from "./components/cards/market-make-sales-card/market-make-sales-card.component";
import { PrimaryAndSecondarySalesByZipCardComponent } from "./components/cards/primary-and-secondary-sales-by-zip-card-component/primary-and-secondary-sales-by-zip-card.component";
import { SalesByAdZoneCardComponent } from "./components/cards/sales-by-ad-zone-card-component/sales-by-ad-zone-card.component";
import { SalesByZipCardComponent } from "./components/cards/sales-by-zip-card-component/sales-by-zip-card.component";
import { SalesComparisonByAdZoneCardComponent } from "./components/cards/sales-comparison-by-zone-card/sales-comparison-by-zone-card.component";
import { ThreeYearPivotCardComponent } from "./components/cards/three-year-pivot-card/three-year-pivot-card.component";
import { Top5CompetitorsCardComponent } from "./components/cards/top-5-competitors-card/top-5-competitors-card.component";
import { YearOverYearCardComponent } from "./components/cards/year-over-year-card/year-over-year-card.component";
import { ChartLegendComponent } from "./components/charts/chart-legend/chart-legend.component";
import { ChartPopupComponent } from "./components/charts/chart-popup/chart-popup.component";
import { ChartComponent } from "./components/charts/chart/chart.component";
import { DateShiftComponent } from "./components/charts/date-shift/date-shift.component";
import { CollapsiblePanelComponent } from "./components/collapsible-panel/collapsible-panel.component";
import { CompetitorsAccordionComponent } from "./components/competitors-accordion/competitors-accordion.component";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { CreateScheduleButtonComponent } from "./components/create-schedule-button/create-schedule-button.component";
import { CreateScheduleModalComponent } from "./components/create-schedule-modal/create-schedule-modal.component";
import { CustomZipMapComponent } from "./components/custom-zip-map/custom-zip-map.component";
import { DateRangeSelectorComponent } from "./components/date-range-selector/date-range-selector.component";
import { ClickBlurDirective } from "./components/directives/click-blur.directive";
import { ClickOutsideDirective } from "./components/directives/click-outside.directive";
import { OverlayOutletDirective } from "./components/directives/overlay-outlet.directive";
import { PopupDirective } from "./components/directives/popup.directive";
import { SwipeDetectorDirective } from "./components/directives/swipe-detector.directive";
import { TooltipDirective } from "./components/directives/tooltip.directive";
import { DmaSelectionComponent } from "./components/dma-selection/dma-selection.component";
import { DragAndDropFileUploadComponent } from "./components/drag-and-drop-file-upload/drag-and-drop-file-upload.component";
import { DragDirective } from "./components/drag-and-drop-file-upload/drag-and-drop.directive";
import { DropDownComponent } from "./components/dropdown/dropdown.component";
import { ExportToastComponent } from "./components/export-toast/export-toast.component";
import { FilterCounterButtonComponent } from "./components/filter/filter-counter-button/filter-counter-button.component";
import { FilterExpandButtonComponent } from "./components/filter/filter-expand-button/filter-expand-button.component";
import { OpenPanelService } from "./components/filter/filter-expand-button/open-panel.service";
import { FilterPanelComponent } from "./components/filter/filter-panel/filter-panel.component";
import { FilterRadioButtonComponent } from "./components/filter/filter-radio-button/filter-radio-button.component";
import { FilterResetButtonComponent } from "./components/filter/filter-reset-button/filter-reset-button.component";
import { FilterSalesRangePanelComponent } from "./components/filter/filter-sales-range-panel/filter-sales-range-panel.component";
import { FilterSelectionPanelComponent } from "./components/filter/filter-selection-panel/filter-selection-panel.component";
import { FilterSpotlightButtonComponent } from "./components/filter/filter-spotlight-button/filter-spotlight-button.component";
import { FilterSpotlightMenuComponent } from "./components/filter/filter-spotlight-menu/filter-spotlight-menu.component";
import { FilterToggleButtonComponent } from "./components/filter/filter-toggle-button/filter-toggle-button.component";
import { FullScreenComponent } from "./components/full-screen/full-screen.component";
import { HighlightedTextComponent } from "./components/highlighted-text/highlighted-text.component";
import { LoadingPanelComponent } from "./components/loading-panel/loading-panel.component";
import { MapControlsComponent } from "./components/map-controls/map-controls.component";
import { MapFilterControlsComponent } from "./components/map-filter-controls/map-filter-controls.component";
import { MapHeatmapLegendComponent } from "./components/map-heatmap-legend/map-heatmap-legend.component";
import { MapComponent } from "./components/map/map.component";
import { MultiDealerMapComponent } from "./components/map/multi-dealer-map.component";
import { MiniMapComponent } from "./components/mini-map/mini-map.component";
import { ModalSelectionSectionComponent } from "./components/modal-selection-section/modal-selection-section.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { OfflinePanelComponent } from "./components/offline-panel/offline-panel.component";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { DisplayFig } from "./components/pipes/display-fig.pipe";
import { SigFig } from "./components/pipes/sigfig.pipe";
import { PresentationDetailsComponent } from "./components/presentation-details/details.component";
import { PrimaryAndSecondarySalesZipTableComponent } from "./components/primary-and-secondary-sales-zip-table/primary-and-secondary-sales-zip-table.component";
import { DealerMakesModelsDetailsComponent } from "./components/results/dealer-makes-models-details/dealer-makes-models-details.component";
import { DealerResultsDetailsComponent } from "./components/results/dealer-results-details/dealer-results-details.component";
import { DealerSharePopupComponent } from "./components/results/dealer-share-popup/dealer-share-popup.component";
import { DealersSalesComparisonComponent } from "./components/results/dealers-sales-comparison/dealers-sales-comparison.component";
import { MakeModelCompositionComponent } from "./components/results/make-model-composition/make-model-composition.component";
import { ResultsExpandButtonComponent } from "./components/results/results-expand-button/results-expand-button.component";
import { ResultsPanelComponent } from "./components/results/results-panel/results-panel.component";
import { SalesAccordionComponent } from "./components/results/sales-accordion/sales-accordion.component";
import { SpotlightToggleComponent } from "./components/results/spotlight-toggle/spotlight-toggle.component";
import { ZipZoneSalesPopupComponent } from "./components/results/zip-zone-sales-popup/zip-zone-sales-popup.component";
import { SalesByZipTableComponent } from "./components/sales-zip-table/sales-zip-table.component";
import { SearchComponent } from "./components/search/search.component";
import { BaseSelectionSectionComponent } from "./components/selection-section/selection-section.component";
import { SplitButtonComponent } from "./components/split-button/split-button.component";
import { SpotlightSelectionPanelComponent } from "./components/spotlight-selection-panel/spotlight-selection-panel.component";
import { ToastComponent } from "./components/toast/toast.component";
import { ToggleButtonComponent } from "./components/toggle-button/toggle-button.component";
import { TooltipComponent } from "./components/tooltip/tooltip.component";
import { TopZipsHeaderTableComponent } from "./components/top-zips-header-table/top-zips-header-table.component";
import { UserChangePasswordComponent } from "./components/user-menu-panel/user-change-password/user-change-password.component";
import { UserMenuPanelComponent } from "./components/user-menu-panel/user-menu-panel.component";
import { ZipUploadModalComponent } from "./components/zip-upload/zip-upload-modal/zip-upload-modal.component";
import { ZipUploadStatusComponent } from "./components/zip-upload/zip-upload-status/zip-upload-status.component";
import { ZipZoneTableComponent } from "./components/zip-zone-table/zip-zone-table.component";
import { ZipsAccordionComponent } from "./components/zips-accordion/zips-accordion.component";
import { ZonesAccordionComponent } from "./components/zones-accordion/zones-accordion.component";
import { BoldTextPipe, GLOBAL_STATE } from "./internal";
import { AuthGuard } from "./services/auth.guard";
import { AutoAnalyzerService } from "./services/auto-analyzer.service";
import { ChartPopupService } from "./services/chart-popup.service";
import { CognitoService } from "./services/cognito.service";
import { ConfirmModalService } from "./services/confirm-modal.service";
import { DealerDataService } from "./services/dealer-data.service";
import { DealerSalesAreaService } from "./services/dealer-sales-area.service";
import { DmaDataService } from "./services/dma-data.service";
import { ExportToastService } from "./services/export-toast.service";
import { FeatureFlagService } from "./services/feature-flag.service";
import { FilterStateService } from "./services/filter-state.service";
import { MarketMaxService } from "./services/market-max.service";
import { MetadataService } from "./services/metadata.service";
import { NextButtonService } from "./services/next-button.service";
import { NgrxFilterStateService } from "./services/ngrx-filter-state.service";
import { OverlayService } from "./services/overlay.service";
import { PopupService } from "./services/popup.service";
import { PresentationCookieService } from "./services/presentation-cookie.service";
import { PresentationFilterService } from "./services/presentation-filter.service";
import { RequestMonitoringService } from "./services/request-monitoring.service";
import { SalesCache } from "./services/sales-cache";
import { SalesDataService } from "./services/sales-data.service";
import { SalesRangeSaveService } from "./services/sales-range-save.service";
import { SalesRangeValidationService } from "./services/sales-range-validation.service";
import { SpotlightService } from "./services/spotlight.service";
import { TopZipsHeaderService } from "./services/top-zips-header.service";
import { TopZipsService } from "./services/top-zips.service";
import { UnAuthGuard } from "./services/un-auth.guard";
import { UnknownSalesService } from "./services/unknown-sales.service";
import { UserCookieService } from "./services/user-cookie.service";
import { USPSZipService } from "./services/usps.zip.service";
import { ZipZoneService } from "./services/zip-zone.service";
import { MetricsService } from "./services/metrics.service";
import { SalesDataSelectorComponent } from "./components/data-source-selector/data-source-selector.component";
import { SimpleSalesDataSelectorComponent } from "./components/simple-data-source-selector/simple-data-source-selector.component";
import {DisplayPercentagePipe} from "./components/pipes/display-percentage.pipe";
import {DetailsService} from "./services/details.service";

@NgModule({
    declarations: [
        AbstractPresentationDetailsComponent,
        AbstractSelectionPanelComponent,
        AbstractPageComponent,
        ProgressItemComponent,
        AccordionComponent,
        CardComponent,
        Top5CompetitorsCardComponent,
        SalesDataSelectorComponent,
        SimpleSalesDataSelectorComponent,
        DateRangeSelectorComponent,
        ExportToastComponent,
        DealerMakeByAdZoneCardComponent,
        DealerMakeSegmentInAdZoneCardComponent,
        DealerOpportunityInAdZoneCardComponent,
        DealerZoneByModelsComponent,
        IndividualModelSalesCardComponent,
        MarketMakeSalesCardComponent,
        PrimaryAndSecondarySalesByZipCardComponent,
        SalesByAdZoneCardComponent,
        SalesByZipCardComponent,
        SalesComparisonByAdZoneCardComponent,
        ThreeYearPivotCardComponent,
        YearOverYearCardComponent,
        ChartLegendComponent,
        ChartPopupComponent,
        PresentationNavItemComponent,
        DealerSalesSharesCardComponent,
        ChartComponent,
        DateShiftComponent,
        CollapsiblePanelComponent,
        ConfirmModalComponent,
        CreateScheduleButtonComponent,
        CreateScheduleModalComponent,
        ClickBlurDirective,
        ClickOutsideDirective,
        OverlayOutletDirective,
        UserChangePasswordComponent,
        PopupDirective,
        SwipeDetectorDirective,
        TooltipDirective,
        DmaSelectionComponent,
        MakeModelCompositionComponent,
        DropDownComponent,
        FilterCounterButtonComponent,
        FilterExpandButtonComponent,
        FilterPanelComponent,
        FilterRadioButtonComponent,
        FilterResetButtonComponent,
        FilterSalesRangePanelComponent,
        FilterSelectionPanelComponent,
        FilterSpotlightButtonComponent,
        FilterSpotlightMenuComponent,
        FilterToggleButtonComponent,
        FullScreenComponent,
        HighlightedTextComponent,
        LoadingPanelComponent,
        MapControlsComponent,
        MapFilterControlsComponent,
        MapHeatmapLegendComponent,
        MapComponent,
        MultiDealerMapComponent,
        MiniMapComponent,
        CustomZipMapComponent,
        ModalSelectionSectionComponent,
        NavbarComponent,
        OfflinePanelComponent,
        OverlayComponent,
        DisplayFig,
        SigFig,
        DisplayPercentagePipe,
        BoldTextPipe,
        PrimaryAndSecondarySalesZipTableComponent,
        DealerMakesModelsDetailsComponent,
        DealerResultsDetailsComponent,
        DealerSharePopupComponent,
        DealersSalesComparisonComponent,
        ResultsExpandButtonComponent,
        ResultsPanelComponent,
        SalesAccordionComponent,
        SpotlightToggleComponent,
        ZipZoneSalesPopupComponent,
        SalesByZipTableComponent,
        SearchComponent,
        BaseSelectionSectionComponent,
        SpotlightSelectionPanelComponent,
        SplitButtonComponent,
        ToastComponent,
        ToggleButtonComponent,
        TooltipComponent,
        TopZipsHeaderTableComponent,
        UserMenuPanelComponent,
        ZipZoneTableComponent,
        CompetitorsAccordionComponent,
        ZipsAccordionComponent,
        ZonesAccordionComponent,
        PresentationDetailsComponent,
        ZipUploadModalComponent,
        ZipUploadStatusComponent,
        DragAndDropFileUploadComponent,
        DragDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ChartsModule,
        KiteCoreModule
    ],
    providers: [
        FilterBaseService
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        ChartsModule,
        KiteCoreModule,
        AccordionComponent,
        ProgressItemComponent,
        CardComponent,
        ExportToastComponent,
        Top5CompetitorsCardComponent,
        DealerMakeByAdZoneCardComponent,
        DealerMakeSegmentInAdZoneCardComponent,
        DealerSalesSharesCardComponent,
        DealerOpportunityInAdZoneCardComponent,
        DealerZoneByModelsComponent,
        IndividualModelSalesCardComponent,
        MarketMakeSalesCardComponent,
        PrimaryAndSecondarySalesByZipCardComponent,
        SalesByAdZoneCardComponent,
        SalesByZipCardComponent,
        SalesComparisonByAdZoneCardComponent,
        ThreeYearPivotCardComponent,
        YearOverYearCardComponent,
        ChartLegendComponent,
        ChartPopupComponent,
        PresentationNavItemComponent,
        ChartComponent,
        DateShiftComponent,
        CollapsiblePanelComponent,
        ConfirmModalComponent,
        CreateScheduleButtonComponent,
        CreateScheduleModalComponent,
        ClickBlurDirective,
        ClickOutsideDirective,
        OverlayOutletDirective,
        UserChangePasswordComponent,
        PopupDirective,
        SwipeDetectorDirective,
        TooltipDirective,
        DmaSelectionComponent,
        MakeModelCompositionComponent,
        DropDownComponent,
        FilterCounterButtonComponent,
        FilterExpandButtonComponent,
        FilterPanelComponent,
        FilterRadioButtonComponent,
        FilterResetButtonComponent,
        FilterSalesRangePanelComponent,
        FilterSelectionPanelComponent,
        FilterSpotlightButtonComponent,
        FilterSpotlightMenuComponent,
        FilterToggleButtonComponent,
        FullScreenComponent,
        HighlightedTextComponent,
        LoadingPanelComponent,
        MapControlsComponent,
        MapFilterControlsComponent,
        MapHeatmapLegendComponent,
        MapComponent,
        MultiDealerMapComponent,
        MiniMapComponent,
        CustomZipMapComponent,
        NavbarComponent,
        OfflinePanelComponent,
        OverlayComponent,
        DisplayFig,
        SigFig,
        PrimaryAndSecondarySalesZipTableComponent,
        DealerMakesModelsDetailsComponent,
        DealerResultsDetailsComponent,
        DealerSharePopupComponent,
        DealersSalesComparisonComponent,
        ResultsExpandButtonComponent,
        ResultsPanelComponent,
        SalesAccordionComponent,
        SpotlightToggleComponent,
        ZipZoneSalesPopupComponent,
        SalesByZipTableComponent,
        SearchComponent,
        BaseSelectionSectionComponent,
        SpotlightSelectionPanelComponent,
        SplitButtonComponent,
        ToastComponent,
        ToggleButtonComponent,
        TooltipComponent,
        TopZipsHeaderTableComponent,
        UserMenuPanelComponent,
        SalesDataSelectorComponent,
        SimpleSalesDataSelectorComponent,
        DateRangeSelectorComponent,
        ZipZoneTableComponent,
        CompetitorsAccordionComponent,
        ZipsAccordionComponent,
        ZonesAccordionComponent,
        PresentationDetailsComponent,
        ZipUploadModalComponent,
        ZipUploadStatusComponent,
        DragAndDropFileUploadComponent,
    ]
})

export class CoreModule {
    // constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    //     if (parentModule) {
    //         throw new Error(
    //             "CoreModule is already loaded. Import it in the AppModule only");
    //     }
    // }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                SalesDataService,
                DealerSalesAreaService,
                FeatureFlagService,
                CookieService,
                OpenPanelService,
                OverlayService,
                PopupService,
                RequestMonitoringService,
                SalesCache,
                SalesRangeSaveService,
                SpotlightService,
                FilterStateService,
                UserCookieService,
                PresentationCookieService,
                NgrxFilterStateService,
                ExportToastService,
                { provide: GLOBAL_STATE, useExisting: FilterStateService, },
                { provide: FILTER_STATE, useExisting: PresentationFilterService },
                { provide: NGRX_TOKEN, useExisting: NgrxFilterStateService },
                TopZipsHeaderService,
                TopZipsService,
                UnAuthGuard,
                UnknownSalesService,
                ZipZoneService,
                AuthGuard,
                ChartPopupService,
                CognitoService,
                ConfirmModalService,
                DealerDataService,
                DmaDataService,
                MetadataService,
                MetricsService,
                MarketMaxService,
                SalesRangeValidationService,
                AutoAnalyzerService,
                NextButtonService,
                USPSZipService,
                DetailsService
            ]
        };
    }
}
