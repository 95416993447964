import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[dragAndDrop]"
})
export class DragDirective {
    @Output() file: EventEmitter<File> = new EventEmitter();

    @HostBinding("style.background") private background = "#f3f3f3";

    constructor() { }

    @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "#999";
    }

    @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "#f3f3f3";
    }

    @HostListener("drop", ["$event"]) public onDrop(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = "#f3f3f3";

        const file = evt.dataTransfer.files[0];
        this.file.emit(file);
    }
}
