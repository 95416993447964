import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreModule } from "@at/core/module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { AdminRoutingModule } from "./admin-routing.module";
import { AdminPageComponent } from "./pages/admin-page.component";
import { AdminManagementService } from "./services/admin-management.service";

@NgModule({
    declarations: [
        AdminPageComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        NgxDatatableModule,
        AdminRoutingModule
    ],

    providers: [
        AdminManagementService
    ]
})
export class AdminModule { }
