<div class="dma-selection-component">
    <button class="dma-select-button" dClickBlur (click)="openMenu($event)" *ngIf="!menuOpen">
        <span class="selected-dma">
            <span class="dma-chip" *ngIf="dmas.length===1; else multidma">
                {{dmas[0].longName}}
            </span>
            <ng-template #multidma>
                <span class="dma-chip" *ngFor="let dma of sortDmas('shortName')" [placement]="'bottom'"
                    [dTooltip]="dma.longName">
                    {{dma.shortName}}
                </span>
            </ng-template>
        </span>
    </button>
    <div class="dma-select-area" (dClickOutside)="closeMenu()" *ngIf="menuOpen">
        <button class="close-button" (click)="closeMenu()">
            <div class="icon"></div>
        </button>

        <div *ngIf="dmas.length===0" class="header error">
            You must select 1 DMA.
        </div>
        <div *ngIf="dmas.length>=3" class="header">
            The maximum number of DMAs have been selected.
        </div>
        <div *ngIf="dmas.length>0 && dmas.length < 3" class="header">
            Select 1-3 DMAs.
        </div>
        <div class="dma-search-holder">
            <input #search class="dma-search" type="text" placeholder="Search for DMA..." [(ngModel)]="searchInput"
                (input)="onSearchChange()" (focus)="searchFocus=true" (blur)="searchFocus=false" />
            <button class="clear-search" (click)="clearSearch()" [class.visible]="searchInput.length">
                <div class="icon"></div>
            </button>
        </div>

        <div class="selected-options">
            <label class="chip-label selections" id="chip-label-selections-{{dma.id}}"
                *ngFor="let dma of sortDmas('longName')">
                <div class="chip selected selections" (click)="removeDma(dma.id, $event)">
                    <span class="icon"></span>
                    <span class="name">{{dma.longName}}</span>
                </div>
            </label>
            <div class="clearfix"></div>
        </div>
        <div class="options chips" *ngIf="filteredDma.length > 0">
            <div class="separator"></div>
            <label class="chip-label" id="chip-label-{{dma}}" *ngFor="let dma of filteredDma; let i = index">
                <button (click)="selectDma(dma, $event)" #options class="dma-option-button chip"
                    [disabled]="dmas.length>=3" [class.disabled]="dmas.length>=3" (focus)="onFocusDmaButton(i)"
                    (blur)="onBlurDmaButton()">
                    <span class="icon"></span>
                    <highlighted-text [needle]="searchInput" [haystack]="getDmaName(dma)"></highlighted-text>
                </button>
            </label>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
