import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractMapComponent, MapMouseFeaturesEvent } from "app/core/base/components/map/map.component.abstract";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { AutoAnalyzerService } from "app/core/services/auto-analyzer.service";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetricsService } from "app/core/services/metrics.service";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import { PresentationFilterService } from "app/core/services/presentation-filter.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { ZipZoneService } from "app/core/services/zip-zone.service";
import { FeatureCollection, GeometryObject } from "geojson";
import { GeoJSONGeometry } from "mapbox-gl";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({ template: "" })
export class MultiDealerMapComponent extends AbstractMapComponent implements OnInit, OnDestroy {

    selectedDealers: Dealer[] = [];
    dealers: Dealer[];

    presentationSubscription: Subscription;
    accordionSubscription: Subscription;
    zoneSubscription: Subscription;

    constructor(
        protected salesDataService: SalesDataService,
        protected dealerDataService: DealerDataService,
        protected filterStateService: FilterStateService,
        protected zipZoneService: ZipZoneService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected presentationFilterService: PresentationFilterService,
        protected autoAnalyzerService: AutoAnalyzerService,
        protected ngrxFilterStateService: NgrxFilterStateService,
        protected metricsService: MetricsService
    ) {
        super(salesDataService, dealerDataService, filterStateService, zipZoneService, changeDetectorRef, ngrxFilterStateService, metricsService, "Multi Dealer Map");
        this.presentationSubscription = this.presentationFilterService.filtersUpdated.subscribe((filters) => {
            this.updatePresentationFilters(filters);
        });
        this.accordionSubscription = this.presentationFilterService.accordionIds.subscribe((message) =>
            this.handleAccordionClick(message)
        );
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe((filters) => {
            this.updateFilters(filters);
        });
        this.zoneSubscription = this.presentationFilterService.zone.subscribe((message) =>
            this.zoneAccordionClick(message)
        );
        this.selectedZips = [];

    }

    ngOnInit(): void {
        super.ngOnInit();
        this.map.on("error", (error) => {
            this.autoAnalyzerService.logMapboxCall(error, "error");
        });
    }

    ngOnDestroy(): void {
        if (this.presentationSubscription) {
            this.presentationSubscription.unsubscribe();
        }
        if (this.accordionSubscription) {
            this.accordionSubscription.unsubscribe();
        }
        if (this.zoneSubscription) {
            this.zoneSubscription.unsubscribe();
        }
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
        }
        super.ngOnDestroy();
    }

    updatePresentationFilters(changes: string[]): void {
        const activeSelection = this.ngrxFilterStateService.getFilter(FilterName.active_selection);
        if (!changes.includes("filters-setup") && activeSelection === "results") {
            this.updateDealers();
            this.updateRadiusRing();

            if (changes.includes("top_zips")) {
                this.setZipZoneFilters();
            }
        }
    }

    updateFilters(changes: string[]): void {
        if (changes.includes("zip_zone_flag")) {
            this.setZipZoneFilters();
        }
    }

    // Toggle between zip and zone mode
    setZipZoneFilters(): void {
        if (this.ngrxFilterStateService.getFilter(FilterName.selected_dealer)) {
            let zips = this.presentationFilterService.getFilterValue<any[]>(FilterName.top_zips)["zips"];

            const formattedZips = zips.map((zip) => ({ zip: zip.location, volume: zip.sales }));

            if (this.ngrxFilterStateService.getFilter(FilterName.zip_zone_flag) === "zone") {
                this.salesDataService.calculateZoneVolumes(formattedZips);
            } else {
                this.salesDataService.volumeDetailKey = "volume";
                this.salesDataService.zipVolume.next(formattedZips);
                this.salesDataService.updateHeatmapScalings(formattedZips);
            }

        }
    }

    updateDealers(): void {
        const dealer: number = this.ngrxFilterStateService.getFilter(
            FilterName.selected_dealer
        );
        const activeSelection = this.ngrxFilterStateService.getFilter(
            FilterName.active_selection
        );

        if (dealer && activeSelection === "results") {
            this.map.on("click", "dealerPinsFocused", this.dealerPinClick.bind(this));
            this.spotlightedDealer = this.presentationFilterService.getFilterValue(
                FilterName.selected_dealer
            );
            this.dealers = this.presentationFilterService.getFilterValue(
                FilterName.top_competitors
            );
            this.updateRenderedDealers(this.dealers);
            this.zoomMapToFeatures();
        }
    }

    handleAccordionClick(dealer: any): void {
        if (dealer !== "default message" && this.presentationFilterService.getFilterValue(FilterName.active_selection) === "results") {
            this.setSelectedDealers(dealer);
            this.setRecentClick();
            this.updatePinLayerFilters();
            this.updatePinHighlight();
        }
    }

    setSelectedDealers(dealer: Dealer): void {
        if (!this.selectedDealers.includes(dealer)) {
            this.selectedDealers.push(dealer);
        } else {
            this.selectedDealers = this.selectedDealers.filter(
                (d) => d.dealer_id !== dealer.dealer_id
            );
        }
    }

    // override - multiple dealers
    dealerPinClick(e: MapMouseFeaturesEvent): void {
        const features = this.map.queryRenderedFeatures(e.point);
        if (!(features && features.length)) {
            return;
        }
        if (this.recentClick) {
            return;
        }

        const f = features[0];
        const dealer_id = f.properties["dealer_id"];
        const foundDealer = this.dealers.find((d) => d.dealer_id === dealer_id);
        if (
            this.spotlightedDealer &&
            foundDealer.dealer_id === this.spotlightedDealer.dealer_id &&
            this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) !== "include"
        ) {
            return;
        }

        this.selectedDealer = foundDealer;
        this.setSelectedDealers(this.selectedDealer);
        // Emit event and pass dealer info to details component for accordion interaction.
        this.presentationFilterService.handleDealerPinClick(this.selectedDealer);

        this.setRecentClick();
        this.updatePinLayerFilters();
        this.updatePinHighlight();
    }

    // override - multiple dealers
    updatePinHighlight(): void {
        const source = (this.map.getSource("dealerPins") as any).serialize() as {
            data: FeatureCollection<GeometryObject>;
        };
        const features = [...this.selectedDealers, this.spotlightedDealer].reduce((m, d) => {
            if (d) {
                const feature = source.data.features.find((f) => f.properties.dealer_id === d.dealer_id);
                if (feature) {
                    m.push(feature);
                }
            }
            return m;
        }, []);

        // Highlight dealer and animate if they exist.
        const fc = { type: "FeatureCollection", features } as FeatureCollection<GeoJSONGeometry>;
        (this.map.getSource("dealer-highlight-source") as mapboxgl.GeoJSONSource).setData(fc);
        if (features.length) {
            this.animationTimer = this.mapUtils.animatePinHighlight(this.map, this.animationTimer, "dealer-highlight");
        }
    }

    // override - multiple dealers
    updatePinLayerFilters(): void {
        let selectedDealerId = 0;
        let spotlightId = 0;
        const pinFilter = [];

        if (this.selectedDealers.length > 0) {
            for (let i = 0; i < this.selectedDealers.length; i++) {
                pinFilter.push(this.selectedDealers[i].dealer_id);
            }
            this.setPinsFocus("dealerPinsFocused", pinFilter);
        }

        if (this.spotlightedDealer) {
            if (
                this.selectedDealer &&
                this.spotlightedDealer.dealer_id === this.selectedDealer.dealer_id
            ) {
                selectedDealerId = 0;
            }
            spotlightId = this.spotlightedDealer.dealer_id;
            pinFilter.push(this.spotlightedDealer.dealer_id);
            this.setPinFocus("dealerPinsSpotlight", spotlightId);
        }

        this.map.setFilter("dealerPins", ["!in", "dealer_id"].concat(pinFilter));
        this.map.setFilter(
            "dealerPinsFocused",
            ["in", "dealer_id"].concat(pinFilter)
        );
        this.map.setFilter("dealerPinsSpotlight", [
            "==",
            ["get", "dealer_id"],
            spotlightId,
        ]);
        this.updateZipClicks();
        if (!this.changeDetectorRef["destroyed"]) {
            this.changeDetectorRef.detectChanges();
        }
    }

    setPinsFocus(layer: string, dealerIds: number[]): void {
        const fc = (this.map.getSource("dealerPins") as any).serialize() as {
            data: FeatureCollection<GeometryObject>;
        };
        const features = fc.data.features.filter(
            (f) => dealerIds.indexOf(f.properties.dealer_id) !== -1
        );
        const selectedData = {
            type: "FeatureCollection",
            features: features ? features : [],
        } as FeatureCollection<GeoJSONGeometry>;
        (this.map.getSource(layer) as mapboxgl.GeoJSONSource).setData(selectedData);

        this.map.on("mouseenter", "dealerPinsFocused", this.onMouseEnter);
        this.map.on("mouseleave", "dealerPinsFocused", this.onMouseLeave);
    }

    // override - multiple dealers
    zoomMapToFeatures(): void {
        let updateZoom = false;
        const ne = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];
        const sw = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];

        // Helper to enlargen bounding box for zooming.
        const enlargeBoundingBox = (coordinates: number[]): void => {
            if (coordinates[0] && coordinates[1]) {
                // to prevent fitting map to null island
                if (coordinates[0] > ne[0] || ne[0] === Number.NEGATIVE_INFINITY) {
                    ne[0] = coordinates[0];
                }
                if (coordinates[1] > ne[1] || ne[1] === Number.NEGATIVE_INFINITY) {
                    ne[1] = coordinates[1];
                }
                if (coordinates[0] < sw[0] || sw[0] === Number.POSITIVE_INFINITY) {
                    sw[0] = coordinates[0];
                }
                if (coordinates[1] < sw[1] || sw[1] === Number.POSITIVE_INFINITY) {
                    sw[1] = coordinates[1];
                }
            }
        };

        // zooms map to include the spotlighted dealer
        if (this.spotlightedDealer) {
            enlargeBoundingBox([
                this.spotlightedDealer.dealer_longitude,
                this.spotlightedDealer.dealer_latitude,
            ]);
            updateZoom = true;
        }

        // fit to dealers
        if (this.dealers && this.dealers.length) {
            for (let i = 0; i < this.dealers.length; i++) {
                enlargeBoundingBox([
                    this.dealers[i].dealer_longitude,
                    this.dealers[i].dealer_latitude,
                ]);
            }
            updateZoom = true;
        }

        // fit to radius ring
        if (this.radiusRingFeature && this.radiusRingFeature.bbox) {
            // ne & sw corners of radius ring bounding box
            enlargeBoundingBox(this.radiusRingFeature.bbox[0]);
            enlargeBoundingBox(this.radiusRingFeature.bbox[1]);
            updateZoom = true;
        }

        if (updateZoom) {
            this.map.resize();
            this.map.setPitch(0);
            this.zoomBoundingBox = [sw, ne];
            this.map.fitBounds(this.zoomBoundingBox, { maxZoom: 9, padding: 60 });
        }
    }

    zipCodeClick(e: MapMouseFeaturesEvent): void {
        const features = this.map.queryRenderedFeatures(e.point, { layers: this.zipcodeHeatMapLevels });
        if (!(features && features.length)) {
            return;
        }
        if (this.recentClick) {
            return;
        }
        this.setRecentClick();
        const f = features[0];
        let zips = [f.properties[this.zipCodeLayerKey]];
        if (this.salesDataService.volumeDetailKey === "adZoneVolume") {
            zips = this.zipZoneService.getAllZipsInZoneByZip(zips[0]);
        }
        this.selectedZips = zips;

        this.resetZipCodeExclusion();
        this.excludeZipCodeFromHeatmap(zips);

        this.map.setFilter("zipcodes-select", ["in", this.zipCodeLayerKey].concat(zips));
        this.map.setFilter("zipcodes-select-border", ["in", this.zipCodeLayerKey].concat(zips));

        const position = this.getPopupStartPosition(320);

        if (this.mapFilterControlsComponent.visibleState) {
            this.mapFilterControlsComponent.closeControls();
        }

        if (this.dealerSharePopupComponent.popup.visible) {
            this.dealerSharePopupComponent.popup.closePopup();
        }

        this.zipZoneSalesPopupComponent.popup.openPopup();
        this.zipZoneSalesPopupComponent.popup.setPosition(position.left, position.top);

        this.zipZoneSalesPopupComponent.popup.closed.pipe(take(1)).subscribe(() => {
            if (zips.includes(this.selectedZips[0])) {
                this.selectedZips = [];
                this.map.setFilter("zipcodes-select", ["==", this.zipCodeLayerKey, ""]);
                this.map.setFilter("zipcodes-select-border", ["==", this.zipCodeLayerKey, ""]);
                this.resetZipCodeExclusion();
                if (!this.changeDetectorRef["destroyed"]) {
                    this.changeDetectorRef.detectChanges();
                }
            }
        });
        this.changeDetectorRef.detectChanges();
    }

    zoneAccordionClick(zips: string[]): void {
        if (zips.length) {
            // Reset zips highlighting if the clicked zone is already selected
            if (this.selectedZips && !this.selectedZips.some(r => zips.indexOf(r) >= 0)) {
                this.selectedZips.push(...zips);
                const zipsToHighlight = [].concat.apply([], this.selectedZips);

                this.map.setFilter(
                    "zipcodes-select-border",
                    ["in", this.zipCodeLayerKey].concat(zipsToHighlight)
                );
            } else if (this.selectedZips) {
                this.selectedZips = this.selectedZips.filter((z) => !zips.includes(z));
                const zipsToHighlight = [].concat.apply([], this.selectedZips);

                this.map.setFilter(
                    "zipcodes-select-border",
                    ["in", this.zipCodeLayerKey].concat(zipsToHighlight)
                );
            }
        }
    }
}
