<table class="sbz-headers" [ngClass]="{'smaller-cells': smallerCells}">
    <thead>
        <tr id="zips-table-header-headers" class="overview">
            <th [attr.column-name]="'type'"></th>
            <th id="zip-sales-header-header" *ngIf="showVolume" [attr.column-name]="'sales'">Sales</th>
            <th [attr.column-name]="'shares'">% of Sales</th>
        </tr>
    </thead>
    <tbody>
        <tr id="top-zips-total" class="overview" *ngIf="limit.label !== 'All'">
            <td [attr.column-name]="'type'">Top {{limit.value}} ZIPs Total</td>
            <td *ngIf="showVolume" [attr.column-name]="'sales'">{{topZipZoneTotalVolume}}</td>
            <td [attr.column-name]="'shares'">
                {{((this.topZipZoneTotalVolume / this.totalVolume) | percent:'.2-2') || 0}}</td>
        </tr>
        <tr *ngIf="showTop25Zips" id="top-25-zips-total" class="overview">
            <td [attr.column-name]="'type'">Top 25 ZIPs Total</td>
            <td *ngIf="showVolume" [attr.column-name]="'sales'">{{this.top25ZipZoneTotalVolume}}</td>
            <td [attr.column-name]="'shares'">
                {{((this.top25ZipZoneTotalVolume / this.totalVolume) | percent:'.2-2') || 0}}</td>
        </tr>
        <tr *ngIf="!hidePrimaryAndSecondary" id="primary-sales-area-total" class="overview">
            <td [attr.column-name]="'type'">Primary Sales Area</td>
            <td *ngIf="showVolume" [attr.column-name]="'sales'">{{this.primarySalesTotal}}</td>
            <td [attr.column-name]="'shares'">
                {{((this.primarySalesTotal / this.totalVolume) | percent:'.2-2') || 0}}</td>
        </tr>
        <tr *ngIf="!hidePrimaryAndSecondary" id="secondary-sales-area-total" class="overview">
            <td [attr.column-name]="'type'">Secondary Sales Area</td>
            <td *ngIf="showVolume" [attr.column-name]="'sales'">{{this.secondarySalesTotal}}</td>
            <td [attr.column-name]="'shares'">
                {{((this.secondarySalesTotal / this.totalVolume) | percent:'.2-2') || 0}}</td>
        </tr>
        <tr id="all-zips-total" *ngIf="showVolume && !hidePrimaryAndSecondary" class="overview">
            <td [attr.column-name]="'type'">All ZIPs ({{zipZoneSales.length}} Total)</td>
            <td [attr.column-name]="'sales'">{{totalVolume}}</td>
            <td [attr.column-name]="'shares'">100%</td>
        </tr>
    </tbody>
</table>
