import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DmaDataService } from "app/core/services/dma-data.service";
import { environment } from "environment";
import { take } from "rxjs/operators";

import { ChartComponent } from "../charts/chart/chart.component";
import { MiniMapComponent } from "../mini-map/mini-map.component";

(() => {
    window["slideIsReady"] = false;
})();

let intervalId: number;

@Component({
    selector: "full-screen",
    templateUrl: "./full-screen.component.html",
    styleUrls: ["./full-screen.component.scss"]
})
export class FullScreenComponent implements OnInit, OnChanges {
    @Input() isLoading = true;
    @Input() noResults = false;

    @ContentChild(MiniMapComponent) mapComponent: MiniMapComponent;
    @ContentChild(ChartComponent) chartComponent: ChartComponent;

    private mapIsReady = false;
    private chartIsReady = false;

    hideNav = true;

    constructor(
        private dmaDataService: DmaDataService,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
            this.hideNav = "hide_nav" in params;
            // When hiding the nav, the DmaDataService does not initialize
            // the list of dmas.
            if (this.hideNav) {
                this.dmaDataService.loadAllDmas();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("isLoading" in changes) {
            this.bindReadyEventListeners();
        }
    }

    private bindReadyEventListeners(): void {
        if (this.isLoading) {
            clearTimeout(intervalId);
            window["slideIsReady"] = false;
        } else {
            setTimeout(() => {
                this.bindMapListener();
                this.bindChartListener();
                // Make the initial call to setWindowReady
                // in case there isn't a map or chart
                // component. In that case, the slide is ready
                this.setWindowReady();
            }, 50);
        }

        if (!environment.production) {
            console.log(`Slide is ready => ${window["slideIsReady"]}`);
        }
    }

    private bindChartListener(): void {
        if (!this.chartComponent) {
            // If there is no chart, set this
            // to true so we can set the slide
            // ready later.
            this.chartIsReady = true;
        } else {
            this.startChartReadyInterval();
        }
    }

    private bindMapListener(): void {
        const map = this.mapComponent && this.mapComponent.map;
        if (map) {
            window["slideIsReady"] = false;
            // Remove existing event handler so we can add a new one
            // without causing a memory leak ;)
            map.off("idle", () => this.setMapReady());
            map.on("idle", () => this.setMapReady());
        } else {
            // If there is no map, lets set
            // this to true so we can set
            // the slide ready later.
            this.mapIsReady = true;
        }
    }

    /** Creates an interval that periodically checks the
     * window object for "chartAnimationComplete" === true.
     */
    private startChartReadyInterval(): void {
        if (intervalId) {
            clearInterval(intervalId);
        }
        // Check to see if the chart has finished rendering. This
        // "chartAnimationComplete" variable is set in the
        // chart.component.ts file.
        intervalId = window.setInterval(() => {
            this.chartIsReady = window["chartAnimationComplete"] === true;
            this.setWindowReady();
        }, 1000);
    }

    private setMapReady(): void {
        this.mapIsReady = true;
        this.setWindowReady();
    }

    private setWindowReady(): void {

        if ((this.mapIsReady && this.chartIsReady) || this.noResults) {
            window["slideIsReady"] = true;
            // Clean up this interval since we no longer
            // care about checking if the slide is ready
            clearInterval(intervalId);
        }

        if (!environment.production) {
            // console.log(`Slide is ready => ${window["slideIsReady"]}`);
        }
    }
}
