import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { DropDownItem } from "app/core/components/dropdown/dropdown.component";
import { DataSet } from "app/core/models/chart-data.model";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { FilterName } from "app/core/models/filter-name.enum";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetadataService } from "app/core/services/metadata.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { get } from "lodash";
import * as moment from "moment";
import { Observable, Subscription, combineLatest, fromEvent } from "rxjs";
import { debounceTime, filter, flatMap, skipWhile, take } from "rxjs/operators";

import { ChartColor } from "../../charts/chart/chart-color.utils";
import { ChartComponent } from "../../charts/chart/chart.component";
import { ChartComponentUtils } from "../../charts/chart/chart.component.utils";
import { CardUtils } from "../card/card.utils";

@Component({
    selector: "dealer-opportunity-in-ad-zone-card",
    templateUrl: "./dealer-opportunity-in-ad-zone.component.html",
    styleUrls: ["./dealer-opportunity-in-ad-zone.component.scss"]
})
export class DealerOpportunityInAdZoneCardComponent implements OnInit, OnDestroy {
    @ViewChildren(ChartComponent) charts: QueryList<ChartComponent>;

    title = "Spotlight Dealer Opportunity in Top Ad Zones";
    spotlightDealer = "";
    spotlightDealerId: number;
    model = "";
    segment = "";
    zoneName = "";
    description = "";
    disableDescription = "";
    isDisabled = true;
    isOpen = false;
    isLoading = false;
    noResults = false;
    dateRangeType = "rolling";
    dateRange = "2018";
    minDate;
    maxDate;

    dropdown: DropDownItem[] = [];
    defaultDropDown: DropDownItem;
    modelExplicitlySelected = false;
    customTicks: number[][] = [];
    chartData: DataSet[] = [];
    chartLabels: string[][] = [];
    chartIndex = 0;

    chartOptions = {
        layout: {
            padding: { // padding for sides of chart
                right: 0,
                left: 0,
                top: 0,
                bottom: 0
            }
        },
        scales: {
            xAxes: [
                {
                    barSpacing: 15,
                    categoryPercentage: 1.0,
                    barPercentage: 1.0,
                    barThickness: "flex",
                    maxBarThickness: 80,
                    gridLines: { display: true, offsetGridLines: true },
                    ticks: { display: false, padding: 0 }
                },
                {
                    type: "category",
                    // in order to have scale labels on the bottom of the chart,
                    // while having the original labels location on the top of the chart, a second axis is needed.
                    id: "bottom-x-axis",
                    gridLines: { display: false },
                    offset: true,
                    ticks: {
                        display: true,
                        autoSkip: false,
                        padding: 5,
                        maxRotation: 0,
                        beginAtZero: false,
                        callback: (value, idx, values) => {
                            if ((this.chartIndex + 1) > this.customTicks.length) {
                                return;
                            }
                            let retVal = "";
                            this.customTicks[this.chartIndex].forEach(tick => {
                                retVal += `  ${Number(tick).toFixed(2)}%  `;
                            });
                            return retVal;
                        }
                    },
                    afterBuildTicks: (chartObj) => {
                        switch (chartObj.id) {
                            case "bottom-x-axis-1":
                                this.chartIndex = 0;
                                chartObj.id = "bottom-x-axis";
                                break;
                            case "bottom-x-axis-2":
                                this.chartIndex = 1;
                                chartObj.id = "bottom-x-axis";
                                break;
                            case "bottom-x-axis-3":
                                this.chartIndex = 2;
                                chartObj.id = "bottom-x-axis";
                                break;
                        }
                        if (chartObj.hasOwnProperty("ticks") && this.customTicks[this.chartIndex].length >= 1) {
                            chartObj.tickValues = chartObj.ticks;
                        }
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Share Totals"
                    }
                }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Share Range",
                    fontStyle: 600,
                    fontFamily: "Open Sans",
                    fontColor: "#404040"
                }
            }]
        },
        plugins: {
        }
    };

    labels: string[][] = [];

    selectedZone: DropDownItem;
    selectedZoneId: number[];

    dealerShareOfModel = "";
    dealerShareOfModelColors: string[] = ChartColor.getColorPalette(6).slice(0, 2);
    dealerShareOfModelData: DataSet[] = [];


    modelShareOfSegment = "";
    modelShareOfSegmentColors: string[] = ChartColor.getColorPalette(6).slice(2, 4);
    modelShareOfSegmentData: DataSet[] = [];

    dealerShareOfSegment = "";
    dealerShareOfSegmentColors: string[] = ChartColor.getColorPalette(6).slice(4, 6);
    dealerShareOfSegmentData: DataSet[] = [];

    private BARS_PER_CHART = 2;
    private filterStateSubscription: Subscription;
    private resizeSubscription: Subscription;
    groupSize: number;

    constructor(
        protected filterStateService: FilterStateService,
        protected salesDataService: SalesDataService,
        protected metadataService: MetadataService,
        protected dealerDataService: DealerDataService
    ) {
        // Update this chart when the window is resized to recalculate the chart segmentation.
        this.resizeSubscription = fromEvent(window, "resize").pipe(
            debounceTime(100))
            .subscribe(this.updateChartSize.bind(this));
    }

    ngOnInit(): void {
        this.metadataService.metadata.pipe(skipWhile(i => !i || !i.maxDate), take(1)).subscribe(meta => {
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);

            const dateRange = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            if (this.dateRangeType === DateRangeTypes.MONTHS && dateRange && dateRange.length >= 5) {
                this.setupDateRanges();
            }
            if (!(this.dateRangeType === DateRangeTypes.MONTHS)) {
                this.updateSalesData();
            }
            // this.dateRange = meta.maxDate;
            this.updateDates();
            this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
            // Since the filtersUpdated subscription was delayed, it has to be manually trigger to set up the card states.
            this.filtersUpdated([FilterName.spotlight_dealer.toString(), FilterName.makes.toString()]);
        });
    }

    ngOnDestroy(): void {
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }

    setupDateRanges(): void {
        const dateRange = this.filterStateService.getFilterValue(FilterName.dateRanges);
        this.minDate = dateRange[0];
        this.maxDate = dateRange[1];
        // calculate grouping
        const dateEnd = moment(this.maxDate, "YYYYMM");
        const dateStart = moment(dateRange[0], "YYYYMM");

        this.groupSize = dateEnd.diff(dateStart, "months");
    }

    cardOpened(): void {
        this.isOpen = true;
        this.updateSalesData();
    }

    cardClosed(): void {
        this.isOpen = false;
    }

    async filtersUpdated(filters: string[]): Promise<void> {
        let updateRequired = true;
        const conditions = {
            spotlit: {
                isValid: !!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer),
                text: "Spotlight a dealer to view"
            },
            include_spotlit: {
                isValid: this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) === "include",
                text: "toggle 'Spotlight' to Include"
            }
        };
        if (filters.includes(FilterName.dateRangeType.toString()) || filters.includes(FilterName.dateRanges.toString())) {
            this.updateDates();
            const dateRange = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            if (this.dateRangeType === DateRangeTypes.MONTHS && dateRange && dateRange.length >= 5) {
                this.setupDateRanges();
            } else if (this.dateRangeType === DateRangeTypes.MONTHS) {
                updateRequired = false;
            }
        }

        this.description = CardUtils.getRequirementDescription(conditions);
        this.isDisabled = this.description.length > 0;
        // Add additional helper text that are not part of the card open requirements.
        this.description += `${this.description.length > 0 ? ". " : ""}To focus on a specific Model(s) or Ad Zone(s) modify the filter panel to the left.`;

        if (this.isDisabled) {
            this.cardClosed();
        } else if (updateRequired) {
            await this.updateDropDown();
            this.updateSalesData();
        }
    }

    async updateSalesData(): Promise<void> {
        if (this.isDisabled || !this.isOpen || !this.selectedZone) {
            return;
        }
        this.isLoading = true;

        this.customTicks = []; // reset custom ticks

        // Get top model for the selected ad zone
        const topModelRequest = this.getTopModel();

        // arrow functions call instance methods so that instance properties are available in the methods called,
        // otherwise each observable in the chain would have its own context and not have access to the instance properties
        topModelRequest.pipe(
            filter((model: string[]) => this.hasTopModel(model)),
            flatMap((model: string[]) => {
                this.model = model[0];

                return this.getSegmentForModel(model);
            }),
            filter((segment: { segment: string; volume: number }[]) => {
                if (segment.length < 1) {
                    this.setNoResults();
                    return false;
                }
                return true;
            }),
            flatMap((segment: { segment?: string; ihs_segment?: string; volume: number }[]) => {
                if (this.filterStateService.getFilterValue<boolean>(FilterName.use_ihs_segments)) {
                    this.segment = segment[0].ihs_segment;
                } else {
                    this.segment = segment[0].segment;
                }

                return this.getSalesForDealerAndMarket();
            }))
            .subscribe(sales => {
                this.customTicks = []; // reset custom ticks
                this.labels = [];
                this.updateChartData(sales as {});

                if (this.customTicks.indexOf([this.dealerShareOfModelData[0].shares[0], this.dealerShareOfModelData[1].shares[0]]) === -1) {
                    this.customTicks.push([this.dealerShareOfModelData[0].shares[0], this.dealerShareOfModelData[1].shares[0]]);
                }
                if (this.customTicks.indexOf([this.modelShareOfSegmentData[0].shares[0], this.modelShareOfSegmentData[1].shares[0]]) === -1) {
                    this.customTicks.push([this.modelShareOfSegmentData[0].shares[0], this.modelShareOfSegmentData[1].shares[0]]);
                }
                if (this.customTicks.indexOf([this.dealerShareOfSegmentData[0].shares[0], this.dealerShareOfSegmentData[1].shares[0]]) === -1) {
                    this.customTicks.push([this.dealerShareOfSegmentData[0].shares[0], this.dealerShareOfSegmentData[1].shares[0]]);
                }
                this.charts.get(0).chartOptions.scales.xAxes[1].id = "bottom-x-axis-1";
                this.charts.get(1).chartOptions.scales.xAxes[1].id = "bottom-x-axis-2";
                this.charts.get(2).chartOptions.scales.xAxes[1].id = "bottom-x-axis-3";
                setTimeout(() => this.updateChartSize.call(this), 50);
                this.isLoading = false;
            });
    }

    getSalesForDealerAndMarket(): Observable<any> {
        const selectedZone = this.selectedZone ? [this.selectedZone.value] : [];
        const zips = this.filterStateService.getFilterValue(FilterName.zips);
        // the default filter overrides to pass to each sales method below; the spread operator is used
        // to "override the overrides"
        const filterOverrides = {
            zips,
            models: [],
            dealers: [],
            makes: [],
            segments: [],
            ihs_segments: []
        };

        let segmentsFilter;

        if (this.filterStateService.getFilterValue<boolean>(FilterName.use_ihs_segments)) {
            segmentsFilter = {
                ihs_segments: [this.segment]
            };
        } else {
            segmentsFilter = {
                segments: [this.segment]
            };
        }

        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            filterOverrides["dateRangeStart"] = this.minDate;
            filterOverrides["dateRangeEnd"] = this.maxDate;
        }

        // Get all sales in this zone for the top or selected model
        const zoneSalesByModelRequest = this.salesDataService.getBuyerSalesForZones(selectedZone, { ...filterOverrides, models: [this.model] });

        // Get the dealer's sale for the top or selected model in this zone
        const dealerZoneSalesByModelRequest = this.salesDataService.getSalesForDealersByZipZone([this.spotlightDealerId], "zones", { ...filterOverrides, models: [this.model], zones: selectedZone });

        // Get all sales in this zone for this segment
        const zoneSalesBySegmentRequest = this.salesDataService.getBuyerSalesForZones(selectedZone, { ...filterOverrides, ...segmentsFilter });

        // Get the dealer's sale for this segment in this zone
        const dealerZoneSalesBySegmentRequest = this.salesDataService.getSalesForDealersByZipZone([this.spotlightDealerId], "zones", { ...filterOverrides, ...segmentsFilter, zones: selectedZone });

        return combineLatest([zoneSalesByModelRequest, zoneSalesBySegmentRequest, dealerZoneSalesByModelRequest, dealerZoneSalesBySegmentRequest]);

    }

    getSegmentForModel(model: string[]): Observable<any> {
        const filterName = this.filterStateService.getFilterValue<boolean>(FilterName.use_ihs_segments) ? FilterName.ihs_segments : FilterName.segments;

        const filterOverrides = {
            zones: [this.selectedZoneId],
            dealer_id: this.spotlightDealerId,
            models: model,
            dma: this.filterStateService.getFilterValue<number>(FilterName.dma),
            new_used_flag: this.filterStateService.getFilterValue<number>(FilterName.new_used_flag),
            filtername: filterName
        };

        const segmentRequest = this.filterStateService.fetchFilterNames(filterName, filterOverrides);

        return segmentRequest;
    }

    getTopModel(): Observable<any> {
        this.selectedZoneId = this.selectedZone ? [this.selectedZone.value] : [];
        const models = this.filterStateService.getFilterValue<string>(FilterName.models);
        const zips = this.filterStateService.getFilterValue(FilterName.zips);

        this.modelExplicitlySelected = models.length > 0 ? true : false;
        this.spotlightDealerId = this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer);
        this.spotlightDealer = this.dealerDataService.getDealerName(this.spotlightDealerId);

        const filterOverrides = {
            zones: this.selectedZoneId,
            dealers: [this.spotlightDealerId],
            // have to manually add models because `getTopOptions` excludes it
            models,
            zips,
            segments: [],
            volume: null
        };

        // Get top model for the selected ad zone
        return this.salesDataService.getTopOptions("models", 1, filterOverrides);
    }

    hasTopModel(model: string[]): boolean {
        if (model.length < 1) {
            this.setNoResults();
            return false;
        }

        return true;
    }

    private setNoResults(): void {
        this.isLoading = false;
        this.isDisabled = true;
        this.noResults = true;
        this.description = "No results for current filters.";
        this.cardClosed();
    }

    updateChartData(sales: object): void {
        // A dealer may not sell the selected model or segment, so default to 0
        // because we still want to show the other 1 or 2 sections of this chart (0 here does not count as "no results")
        let dealerModelSales = 0;
        let dealerSegmentSales = 0;

        const zoneModelArray = get(sales, "[0]", []);
        let zoneModelSales = 0;
        //looping through the array of zones and adding up the sales
        zoneModelArray.forEach(zone => {
            zoneModelSales += zone.sales;
        });

        const zoneSegmentArray = get(sales, "[1]", []);
        let zoneSegmentSales = 0;
        zoneSegmentArray.forEach(zone => {
            zoneSegmentSales += zone.sales;
        });

        if (sales[2].length) {
            dealerModelSales = get(sales, "[2][0].sales[0].sales", 0);
        }

        if (sales[3].length) {
            dealerSegmentSales = get(sales, "[3][0].sales[0].sales", 0);
        }

        this.updateDealerShareOfModelSection(zoneModelSales, dealerModelSales);
        this.updateModelShareOfSegmentSection(zoneSegmentSales, zoneModelSales);
        this.updateDealerShareOfSegmentSection(zoneSegmentSales, dealerSegmentSales);
    }

    updateChartSize(): void {
        // Don't attempt to update if the card is not opened.
        if (!this.isOpen) {
            return;
        }

        const charts = this.charts.toArray();

        const chartWidth = charts[0].elementRef.nativeElement.offsetWidth;
        // (chart width - magic number via trial and error) / number of bars per chart
        const barSize = (chartWidth - 150) / this.BARS_PER_CHART;

        this.charts.get(0).chartOptions.scales.xAxes[0].maxBarThickness = barSize;
        this.charts.get(1).chartOptions.scales.xAxes[0].maxBarThickness = barSize;
        this.charts.get(2).chartOptions.scales.xAxes[0].maxBarThickness = barSize;

        this.charts.get(0).forceRedraw();
        this.charts.get(1).forceRedraw();
        this.charts.get(2).forceRedraw();
    }

    private updateModelShareOfSegmentSection(segmentSales: number, modelSales: any): void {
        this.modelShareOfSegmentData = [];

        const modelSaleRatio = Number(modelSales / segmentSales);

        const modelSalesPercent = !Number.isFinite(modelSaleRatio) ? 0 : modelSaleRatio * 100;

        const remainingZoneSales = 100 - modelSalesPercent;

        this.modelShareOfSegment = `${modelSalesPercent.toFixed(2)}%`;

        this.modelShareOfSegmentData.push({
            data: [modelSalesPercent],
            shares: [modelSalesPercent],
            label: `${this.model} share of ${this.segment} sales in zone`,
            id: "model-share-of-segment-sales"
        } as DataSet);

        this.labels.push([modelSalesPercent.toFixed(2).toString() + "%                                     " + remainingZoneSales.toFixed().toString() + "%"]);

        this.modelShareOfSegmentData.push({
            data: [remainingZoneSales],
            shares: [remainingZoneSales],
            label: `Remaining share of ${this.segment} sales in zone`,
            id: "remaining-share-of-segment-sales-1"
        } as DataSet);
    }

    private updateDealerShareOfModelSection(zoneSales: number, dealerSales: number): void {
        this.dealerShareOfModelData = [];

        const dealerSaleRatio = Number(dealerSales / zoneSales);

        const dealerSalesPercent = !Number.isFinite(dealerSaleRatio) ? 0 : dealerSaleRatio * 100;

        const remainingZoneSales = 100 - dealerSalesPercent;

        this.dealerShareOfModel = `${dealerSalesPercent.toFixed(2)}%`;

        this.dealerShareOfModelData.push({
            data: [dealerSalesPercent],
            shares: [dealerSalesPercent],
            label: `Dealer's share of ${this.model} sales in zone`,
            id: "dealer-share-of-model-sales"
        } as DataSet);

        this.labels.push([dealerSalesPercent.toFixed(2).toString() + "%                                     " + remainingZoneSales.toFixed().toString() + "%" as string]);

        this.dealerShareOfModelData.push({
            data: [remainingZoneSales],
            shares: [remainingZoneSales],
            label: `Remaining share of ${this.model} sales in zone`,
            id: "remaining-share-of-model-sales"
        } as DataSet);
    }

    private updateDealerShareOfSegmentSection(zoneSales: number, dealerSales: number): void {
        this.dealerShareOfSegmentData = [];

        const dealerSaleRatio = Number(dealerSales / zoneSales);

        const dealerSalesPercent = !Number.isFinite(dealerSaleRatio) ? 0 : dealerSaleRatio * 100;

        const remainingZoneSales = 100 - dealerSalesPercent;

        this.dealerShareOfSegment = `${dealerSalesPercent.toFixed(2)}%`;

        this.dealerShareOfSegmentData.push({
            data: [dealerSalesPercent],
            shares: [dealerSalesPercent],
            label: `Dealer's share of ${this.segment} sales in zone`,
            id: "dealer-share-of-segment-sales"
        } as DataSet);

        this.labels.push([dealerSalesPercent.toFixed(2).toString() + "%                                     " + remainingZoneSales.toFixed().toString() + "%" as string]);

        this.dealerShareOfSegmentData.push({
            data: [remainingZoneSales],
            shares: [remainingZoneSales],
            label: `Remaining share of ${this.segment} sales in zone`,
            id: "remaining-share-of-segment-sales-2"
        } as DataSet);
    }


    private updateDates(): void {
        this.dateRangeType = this.filterStateService.getFilterValue<string>(FilterName.dateRangeType);
    }

    dropdownChanged(selected: DropDownItem): void {
        this.selectedZone = selected;
        this.updateSalesData();
        this.zoneChanged();
    }

    async zoneChanged(): Promise<void> {
        const zoneId = this.selectedZone.value;

        const zone = await this.filterStateService.getFilterNamesFromIds([zoneId], FilterName.zones)
            .toPromise();

        this.zoneName = zone[zoneId];
    }

    private async updateDropDown(): Promise<void> {
        const zones = await this.salesDataService.calculateTopZones(
            this.filterStateService.getFilterValue(FilterName.zones),
            2,
            {
                // top 2 zones should always be for the selected dealer with no other filters applied (except for zones themselves)
                dealers: [this.filterStateService.getFilterValue(FilterName.spotlight_dealer)],
                makes: [],
                models: [],
                zips: [],
                segments: [],
                ihs_segments: []
            }
        ).toPromise();

        // If two or more adzones were selected, set dropdown values to the top two.
        this.dropdown = zones.map((zone, i) => ({ label: `${i + 1}: ${zone.value}`, value: zone.id }));

        // See if previous zone selection is still present in drop down, otherwise set selected as top zone as default.
        this.selectedZone = this.defaultDropDown = this.selectedZone && this.dropdown.find(dd => dd.value === this.selectedZone.value) || this.dropdown[0];

        this.zoneChanged();
    }
}
