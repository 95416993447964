import { ChangeDetectorRef, Component, HostListener, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { ConfirmModalService, ConfirmModalView, ModalIcon, OverlayService } from "@at/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { finalize } from "rxjs/operators";

import { AdminManagementService, UserInfo } from "../services/admin-management.service";

enum TABS {
    ADD,
    EDIT
}

enum MESSAGING_STATUS {
    NONE = "none",
    SUCCESS = "success",
    ERROR = "error"
}

enum USER_GROUPS {
    ADMIN = "Admin",
    STANDARD = "Standard"
}

interface Tab {
    label: string;
    active: boolean;
    view: TABS;
}

interface UserAccountDetail {
    email: string;
    username: string;
    status: string;
    createdDate: Date;
    group: string[];
    actions: {
        user: UserAccountDetail;
        delete: boolean;
        modifyGroup: boolean;
        reset: boolean;
    };
}

interface ToastMessage {
    status: MESSAGING_STATUS;
    message: string;
}

interface Checkbox {
    label: string;
    value: string;
    checked: boolean;
}

const MESSAGE_MAP: { [key: string]: ToastMessage } = {
    VALIDATION_ERROR: {
        status: MESSAGING_STATUS.ERROR,
        message: "ERROR: Please correct the issues below before resubmitting."
    },
    CREATE_SUCCESS: {
        status: MESSAGING_STATUS.SUCCESS,
        message: "SUCCESS: Users have been successfully added."
    },
    CREATE_ERROR: {
        status: MESSAGING_STATUS.ERROR,
        message: "ERROR: Something went wrong. We weren't able to add the user(s)."
    },
    DELETE_SUCCESS: {
        status: MESSAGING_STATUS.SUCCESS,
        message: "SUCCESS: User has been successfully deleted."
    },
    DELETE_ERROR: {
        status: MESSAGING_STATUS.ERROR,
        message: "ERROR: Something went wrong. We weren't able to delete the user."
    },
    MODIFY_GROUP_SUCCESS: {
        status: MESSAGING_STATUS.SUCCESS,
        message: "SUCCESS: User group has been modified."
    },
    MODIFY_GROUP_ERROR: {
        status: MESSAGING_STATUS.ERROR,
        message: "ERROR: Something went wrong. We weren't able to modify the groups of the user."
    },
    RESET_SUCCESS: {
        status: MESSAGING_STATUS.SUCCESS,
        message: "SUCCESS: User has been successfully reset."
    },
    RESET_ERROR: {
        status: MESSAGING_STATUS.SUCCESS,
        message: "ERROR: Something went wrong. We weren't able to reset the user."
    }
};

@Component({
    selector: "admin-page",
    templateUrl: "./admin-page.component.html",
    styleUrls: ["./admin-page.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AdminPageComponent implements OnInit {
    @ViewChild(DatatableComponent) ngxDatatable: DatatableComponent;
    @ViewChild("hdrTmpl") hdrTmpl: TemplateRef<any>;
    @ViewChild("rowNumberTmpl", { static: true }) rowNumberTmpl: TemplateRef<any>;
    @ViewChild("rowActionsTmpl", { static: true }) rowActionsTmpl: TemplateRef<any>;
    @ViewChild("rowGroupsTmpl") rowGroupsTmpl: TemplateRef<any>;
    @ViewChild("rowDateTmpl") rowDateTmpl: TemplateRef<any>;
    @ViewChild("rowValueTmpl") rowValueTmpl: TemplateRef<any>;

    @ViewChild("modifyGroupTmpl") modifyGroupTmpl: TemplateRef<any>;

    TABS = TABS; // For template usage.
    tabsList = [
        { id: "users-add", label: "Add New Users", active: true, view: TABS.ADD },
        { id: "users-edit", label: "Manage Existing Users", active: false, view: TABS.EDIT }
    ];
    activeTab: Tab = this.tabsList[0];

    usersInput = "";
    allowedRegex = /[^A-Za-z0-9'\-_\.@]/g;
    toast: ToastMessage;
    isInvalid = false;

    editColumns = [];
    editRows: UserAccountDetail[] = [];
    defaultSort = [{ prop: "createdDate", dir: "desc" }];

    sortKey = "createdDate";
    sortDir = "desc";
    loading = false;

    actionsMenuUser: UserAccountDetail;
    actionsMenuLoc: { left: string; top: string } = { left: "0px", top: "0px" };

    searchTerm = "";
    private completeRows: UserAccountDetail[] = [];

    constructor(
        private adminManagementService: AdminManagementService,
        private overlayService: OverlayService,
        private confirmModalService: ConfirmModalService,
        private changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.configureTableColumns();
    }

    @HostListener("document:click", ["$event"]) clickedOutside(event: MouseEvent): void {
        // Click events not coming from the action icon should close the menu.
        if (this.actionsMenuUser && !(event.target as HTMLElement).className.includes("action-arrow")) {
            this.actionsMenuUser = undefined;
        }
    }

    @HostListener("document:scroll", ["$event"]) scroll(event: MouseEvent): void {
        // Scrolling while the action menu is displayed should close the action menu.
        if (this.actionsMenuUser) {
            this.actionsMenuUser = undefined;
        }
    }

    switchTab(tab: Tab): void {
        for (let i = 0; i < this.tabsList.length; i++) {
            this.tabsList[i].active = this.tabsList[i] === tab ? true : false;
        }

        this.activeTab = tab;
        if (tab.view === TABS.EDIT) {
            this.changeDetector.detectChanges();

            // Work around for a bug that prevent ColumnMode.flex not resizing columns.
            // https://github.com/swimlane/ngx-datatable/issues/919
            this.ngxDatatable.columnMode = ColumnMode.force;
            this.refreshUserList();
        }
    }

    closeMessage(): void {
        this.toast = undefined;
    }

    submit(): void {
        this.isInvalid = !this.validUsersInput(this.usersInput);
        // Do not submit if users list is delimited incorrectly.
        if (this.isInvalid) {
            this.updateToaster(MESSAGE_MAP.VALIDATION_ERROR);
            return;
        }

        const inputs = this.usersInput.split("\n");
        const users = [];
        for (let i = 0; i < inputs.length; i++) {
            // Ignore user error of adding empty lines.
            if (inputs[i] !== "") {
                // Do not submit if one email in the users list is not a valid email.
                if (this.isValidEmail(inputs[i])) {
                    users.push(inputs[i]);
                } else {
                    this.updateToaster(MESSAGE_MAP.VALIDATION_ERROR);
                    this.isInvalid = true;
                    return;
                }
            }
        }

        this.isInvalid = false;
        this.overlayService.showLoadingPanel();

        this.adminManagementService.postAddUsers(users)
            .pipe(finalize(() => this.overlayService.hidePanel())
            ).subscribe(
                (resp) => this.updateToaster(MESSAGE_MAP.CREATE_SUCCESS),
                (err) => {
                    const toastError = MESSAGE_MAP.CREATE_ERROR;

                    for(let error of err.error.errors){
                        if(error?.exception && error.exception === "UsernameExistsException"){
                            toastError.message = "ERROR: We weren't able to add the user(s) " +
                                "because this user(s) already exists. Please use a different email and try again.";
                        }
                    }
                    this.updateToaster(toastError);
                }
            );
    }

    sortClass(key: string): void {
        if (key === this.sortKey) {
            if (this.sortDir === "asc") {
                this.sortDir = "desc";
            } else if (this.sortDir === "desc") {
                this.sortDir = "none";
            } else {
                this.sortDir = "asc";
            }
        } else {
            this.sortKey = key;
            this.sortDir = "asc";
        }
    }

    toggleActionMenu(event: MouseEvent, item: any): void {
        this.actionsMenuLoc = { left: event.x - 5 + "px", top: event.y + 15 + "px" };
        this.actionsMenuUser = this.actionsMenuUser === item.user ? undefined : item.user;
    }

    async deleteUser(user: UserAccountDetail): Promise<void> {
        this.actionsMenuUser = undefined;

        const config = {
            viewConfig: {
                id: "user-delete",
                icon: ModalIcon.STOP,
                header: "Do you want to delete this user?",
                message: `You're about to delete ${user.email}. Please confirm that you would like to continue. NOTE: This cannot be undone.`,
                confirmLabelText: "DELETE",
                validDma: true
            } as ConfirmModalView,
            confirm: () => {
                this.overlayService.showLoadingPanel();
                this.adminManagementService.postDeleteUser(user.email)
                    .pipe(finalize(() => this.overlayService.hidePanel())
                    ).subscribe(
                        (resp) => {
                            this.updateToaster(MESSAGE_MAP.DELETE_SUCCESS);
                            this.refreshUserList();
                        },
                        (err) => this.updateToaster(MESSAGE_MAP.DELETE_ERROR)
                    );
            },
            cancel: () => { }
        };
        this.confirmModalService.showModal(config);
    }

    async modifyUserGroups(user: UserAccountDetail): Promise<void> {
        this.actionsMenuUser = undefined;
        const config = {
            viewConfig: {
                id: "user-change-group",
                icon: ModalIcon.WARNING,
                header: "Do you want to change the users group?",
                contentTmplRef: this.modifyGroupTmpl,
                contentTmplData: {
                    message: `Please select the user group(s) you would like ${user.email} to have access to.`,
                    groups: [
                        { label: USER_GROUPS.ADMIN, value: "admin", checked: user.group.includes(USER_GROUPS.ADMIN) }
                    ] as Checkbox[]
                },
                validDma: true
            } as ConfirmModalView,
            confirm: (view: ConfirmModalView) => {
                const add = [];
                const remove = [];
                this.overlayService.showLoadingPanel();

                for (const c of view.contentTmplData.groups) {
                    if (c.checked) {
                        if (!user.group.includes(c.label)) {
                            add.push(c.value);
                        }
                    } else {
                        if (user.group.includes(c.label)) {
                            remove.push(c.value);
                        }
                    }
                }

                this.adminManagementService.postModifyUserGroups(user.username, add, remove) // modifying groups requires the use of the username, not the email address
                    .pipe(finalize(() => this.overlayService.hidePanel())
                    ).subscribe(
                        (resp) => {
                            this.updateToaster(MESSAGE_MAP.MODIFY_GROUP_SUCCESS);
                            this.refreshUserList();
                        },
                        (err) => this.updateToaster(MESSAGE_MAP.MODIFY_GROUP_ERROR)
                    );
            },
            cancel: () => { }
        };
        this.confirmModalService.showModal(config);
    }

    async resetUser(user: UserAccountDetail): Promise<void> {
        this.actionsMenuUser = undefined;
        this.overlayService.showLoadingPanel();
        this.adminManagementService.postResetUsers([user.email])
            .pipe(finalize(() => this.overlayService.hidePanel())
            ).subscribe(
                (resp) => {
                    this.updateToaster(MESSAGE_MAP.RESET_SUCCESS);
                    this.refreshUserList();
                },
                (err) => this.updateToaster(MESSAGE_MAP.RESET_ERROR)
            );
    }

    async updateSearch(input: string): Promise<void> {
        this.searchTerm = input.toLowerCase();

        if (this.searchTerm) {
            this.editRows = this.completeRows.filter((row: UserAccountDetail) => row.email.toLowerCase().includes(this.searchTerm));
        } else {
            this.editRows = this.completeRows;
        }

        // Pan to top.
        this.ngxDatatable.offset = 0;
    }

    private refreshUserList(): void {
        this.loading = true;
        this.adminManagementService.getUserList().subscribe(userDetails => {
            this.editRows = this.completeRows = (userDetails as any).users.map(this.mapUserAccountDetail.bind(this));
            this.loading = false;
        });
    }

    // Verify the input is only new line delimited.
    private validUsersInput(string: string): boolean {
        return string.match(/[,; \t]/) === null;
    }

    private isValidEmail(email: string): boolean {
        return email.search(/@{1}[a-zA-Z0-9]+\.{1}[a-zA-Z0-9]+$/) !== -1;
    }

    private updateToaster(message: ToastMessage): void {
        this.toast = message;
    }

    private mapUserAccountDetail(user: UserInfo): UserAccountDetail {
        const group = [];
        if (user.isAdmin) {
            group.push(USER_GROUPS.ADMIN);
        }
        if (group.length === 0) {
            group.push(USER_GROUPS.STANDARD);
        }

        const status = this.getStatus(user);
        const details = {
            email: user.email,
            username: user.userName,
            status,
            createdDate: new Date(user.userCreatedDate),
            group,
            actions: {
                user: undefined,
                delete: true,
                modifyGroup: true,
                reset: !(status === "Active" || status === "Other")
            }
        };

        // Circular binding to reference parent user details object.
        details.actions.user = details;
        return details;
    }

    private getStatus(userInfo: UserInfo): string {
        if (userInfo.status === "CONFIRMED") {
            return "Active";
        } else if (userInfo.status === "FORCE_CHANGE_PASSWORD") {
            // If the account was created more then 30 days ago, the temp password is no longer valid
            // and the account is locked from all access.
            if (new Date(userInfo.userCreatedDate).getTime() + (1000 * 60 * 60 * 24 * 30) < Date.now() &&
                new Date(userInfo.userLastModifiedDate).getTime() + (1000 * 60 * 60 * 24 * 30) < Date.now()) {
                return "Locked";
            } else {
                return "Password Sent";
            }
        } else {
            return "Other";
        }
    }


    private configureTableColumns(): void {
        this.editColumns = [
            {
                cellTemplate: this.rowNumberTmpl,
                headerClass: "datatable-index-row",
                name: "",
                prop: "",
                minWidth: 40,
                maxWidth: 40,
                cellClass: "datatable-index-row",
                frozenLeft: true,
                sortable: false,
                resizeable: false,
                flexGrow: 0
            },
            {
                cellTemplate: this.rowActionsTmpl,
                name: "",
                prop: "actions",
                minWidth: 35,
                maxWidth: 35,
                sortable: false,
                resizeable: false,
                flexGrow: 0
            },
            {
                headerTemplate: this.hdrTmpl,
                cellTemplate: this.rowValueTmpl,
                name: "Email Address",
                prop: "email",
                flexGrow: 4
            },
            {
                headerTemplate: this.hdrTmpl,
                cellTemplate: this.rowValueTmpl,
                name: "Status",
                prop: "status",
                flexGrow: 2
            },
            {
                headerTemplate: this.hdrTmpl,
                cellTemplate: this.rowDateTmpl,
                name: "User Created Date",
                prop: "createdDate",
                flexGrow: 2
            },
            {
                headerTemplate: this.hdrTmpl,
                cellTemplate: this.rowGroupsTmpl,
                name: "User Group",
                prop: "group",
                resizeable: false,
                flexGrow: 2
            }
        ];
    }
}
