import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { FilterName } from "../models/filter-name.enum";
import { PresentationFilter } from "../models/filter.model";
import { CognitoService } from "../services/cognito.service";
import * as FilterStateActions from "../state/actions/filter-state-change.actions";
import { setDefaultFilter, setFilterDefaultObject } from "../state/actions/filter-state-defaults.actions";
import { setResettableFilters } from "../state/actions/resettable-filters.actions";
import { FilterDefaultState, FilterState } from "../state/state";

@Directive()
@Injectable({
    providedIn: "root"
})
export class NgrxFilterStateService {
    @Output() filtersUpdated = new EventEmitter<string[]>();
    resetableFilters: string[];

    constructor(
        protected store: Store<{ filters: FilterState, resettableFilters: string[], filterDefaults: PresentationFilter }>
    ) { }

    /**
     * Fetches a specific Filter Value from state and then returns it.
     *
     * @param filterName    string | FilterName  Takes a string or FilterName and returns the value as an Observable.
     * @returns             Observable<any>      Returns an Observable of the specific Filter value for the name passed.
     */
    getFilterValueAsObservable(filterName: string | FilterName): Observable<any> {
        return this.store.select(state => state.filters[filterName.toString()]);
    }

    /**
     * Gets a Filter Value from the state object by name.
     *
     * @param filterName    string | FilterName Takes a string or FilterName and just returns the raw value.
     * @returns             any                 Returns the Filter Value based on the name passed and returns it.
     */
    getFilter(filterName: string | FilterName): any {
        let value: any;
        this.store.subscribe(state => value = state.filters[filterName.toString()]);
        return value;
    }

    /**
     * Sets a Filter Value by passing a name and a value.
     *
     * @param filterName    string | FilterName Takes a string or FilterName as the index of where to set the value.
     * @param value         any                 The value you want to set.
     * @returns             void
     */
    setFilter(filterName: string | FilterName, value: any): void {
        let filter = (typeof filterName !== "string") ? filterName : filterName.toString();
        this.store.dispatch(FilterStateActions.setFilter({ filterName: filter, value }));
        this.filtersUpdated.emit([filterName.toString()]);
    }

    appendSalesData(value: any): void {
        this.store.dispatch(FilterStateActions.appendToSalesDataFilter({ value }));
    }

    /**
     * Gets the full FilterState Object and returns it
     *
     * @returns FilterState    returns the full FilterState Object (not sure why we are saying this is a string[] and then returning any)
     */
    getCurrentFilterState(): FilterState {
        let filters: any;
        this.store.subscribe(state => filters = state.filters);
        return filters;
    }

    /**
     * Sets the current filter state object essentially setting every value thats currently there.
     *
     * @param filterObject  FilterState     The full FilterState object.
     * @returns             void
     */
    setCurrentFilterState(filterObject: FilterState): void {
        for (const key in Object.keys(filterObject)) {
            if (!this.isFilterResettable(key)) {
                delete filterObject[key];
            }
        }
        this.store.dispatch(FilterStateActions.setFilterStateObject({ filterState: filterObject }));
    }

    /**
     * Gets the FilterState Defaults and returns them
     *
     * @returns FilterDefaultState  The default Filter object
     */
    getDefaultFilterState(): FilterDefaultState {
        let filterDefaultState: any;
        this.store.subscribe(state => filterDefaultState = state.filterDefaults);
        return filterDefaultState;
    }

    /**
     * Takes the full FilterStateDefaults Objects and sets it as the default set Filters for a given route
     *
     * @param defaultFilterState FilterDefaultState     The FilterStateDefaults Object.
     * @returns void
     */
    setDefaultFilterState(defaultFilterState: FilterDefaultState): void {
        for (const key in Object.keys(defaultFilterState)) {
            if (!this.isFilterResettable(key)) {
                delete defaultFilterState[key];
            }
        }
        this.store.dispatch(setFilterDefaultObject({ filterDefaults: defaultFilterState }));
    }

    /**
     * Gets a single default filter value and returns it by filterName
     *
     * @param filterName    FilterName     Accepts a specific FilterName prop
     * @returns             any            Returns the filter default value
     */
    getDefaultFilter(filterName: FilterName): any {
        let filter: any;
        this.store.subscribe(state => filter = state.filterDefaults[filterName]);
        return filter;
    }

    /**
     * Takes a default FilterName and value and sets it in the filterDefaults
     *
     * @param filterName    FilterName      Accepts a specific FilterName prop
     * @param value         any             Accepts any value type
     * @returns             void
     */
    setDefaultFilter(filterName: FilterName, value: any): void {
        this.store.dispatch(setDefaultFilter({ filterName: filterName.toString(), value }));
    }

    /**
     * Gets the current string array of resettable FilterNames and returns it.
     *
     * @returns string[]    array of strings representing the current resettable filter names.
     */
    getCurrentResettableFilterState(): string[] {
        let resettableFilters: string[];
        this.store.subscribe(state => resettableFilters = state.resettableFilters);
        return resettableFilters;
    }

    /**
     * Sets which filters are resettable for a given route.  These are injected into each route module.
     *
     * @param filters   string[]    Array of strings which are names of filters that can be reset.
     * @returns void
     */
    setResettableFilters(filters: string[]): void {
        this.store.dispatch(setResettableFilters({ resettableFilters: filters }));
    }

    /**
     * Protected method that checks if a given filter name is resettable.
     *
     * @param filterName    string | FilterName     Accepts a string or filter name that you want to check.
     * @returns             boolean     Is the filter resettable?
     */
    protected isFilterResettable(filterName: string | FilterName): boolean {
        let filters: string[];
        this.store.subscribe(state => filters = state.resettableFilters);
        return filters.includes(filterName.toString());
    }
}
