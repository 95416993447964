import { environment } from "environment";
import { Color } from "ng2-charts";

import { DiagonalPattern } from "./diagonal-pattern";

interface Palette {
    [key: number]: string[];
}

export class ChartColor {
    private static opaque_default = 0.15;
    private static pattern = new DiagonalPattern({ strokeStyle: "rgba(255, 255, 255, 0.5)", lineWidth: 1.25 });

    static get opaque(): number {
        return this.opaque_default;
    }

    static set opaque(alpha: number) {
        this.opaque_default = alpha;
    }

    static getColorPalette(size: number = 15): string[] {
        if (size > 15) {
            if (!environment.production) {
                console.warn(`Requesting ${size} from color palette of maximum 15.`);
            }
            size = 1;
        }
        const palettes: Palette = {
            0: [],
            1: ["0077bc"],
            2: ["0077bc", "003057"],
            3: ["75cbc6", "0077bc", "003057"],
            4: ["75cbc6", "0077bc", "003057", "009e8c"],
            5: ["75cbc6", "0077bc", "003057", "637b46", "009e8c"],
            6: ["75cbc6", "0077bc", "003057", "874263", "637b46", "009e8c"],
            7: ["75cbc6", "0077bc", "003057", "874263", "637b46", "7fcb78", "009e8c"],
            8: ["75cbc6", "0077bc", "003057", "874263", "c62525", "637b46", "7fcb78", "009e8c"],
            9: ["75cbc6", "0077bc", "003057", "874263", "c62525", "f17373", "637b46", "7fcb78", "009e8c"],
            10: ["75cbc6", "0077bc", "003057", "874263", "c62525", "f17373", "f4ba94", "637b46", "7fcb78", "009e8c"],
            11: ["75cbc6", "0077bc", "003057", "874263", "c62525", "f17373", "f4ba94", "e27d27", "637b46", "7fcb78", "009e8c"],
            12: ["75cbc6", "0077bc", "003057", "874263", "c62525", "f17373", "f4ba94", "ece35a", "e27d27", "637b46", "7fcb78", "009e8c"],
            13: ["75cbc6", "0077bc", "003057", "874263", "c62525", "f17373", "f4ba94", "ece35a", "faae1a", "e27d27", "637b46", "7fcb78", "009e8c"],
            14: ["75cbc6", "0077bc", "003057", "874263", "c62525", "f17373", "f4ba94", "ece35a", "faae1a", "e27d27", "b59809", "637b46", "7fcb78", "009e8c"],
            15: ["75cbc6", "0077bc", "035585", "003057", "874263", "c62525", "f17373", "f4ba94", "ece35a", "faae1a", "e27d27", "b59809", "637b46", "7fcb78", "009e8c"]
        };
        return palettes[size].slice();
    }

    /**
     * These colors must match the zipcodes-heatmap-[0-6] layer styles in Mapbox.
     */
    static getHeatmapPalette(): string[] {
        return [
            "ffffff",
            "dbf0fa",
            "91cff2",
            "1c87c4",
            "ece25b",
            "faaf19",
            "c62424"
        ];
    }

    static getSpotlightPalette(size: number = 3): string[] {
        const palettes = {
            0: [],
            1: ["faae1a"],
            2: ["faae1a", "e27d27"],
            3: ["faae1a", "e27d27", "ece35a"]
        };
        return palettes[size].slice();
    }

    static getSpotlightPatterns(size: number = 3): any[] {
        return this.getSpotlightPalette(size).map(hex => ({ backgroundColor: this.pattern.draw(hex), hex }));
    }

    static toChartJsColor(color: any, alpha: number = 1): Color {
        if (this.isCanvasPattern(color)) {
            return color;
        } else {
            const rgba = this.toRGBAString(color, alpha);
            return { backgroundColor: rgba, borderColor: rgba };
        }
    }

    static toChartJsColorArray(colors: any[], opaque?: boolean): any[] {
        const alpha = opaque ? this.opaque_default : 1;
        return colors.map(i => this.isCanvasPattern(i) ? i : this.toChartJsColor(i, alpha));
    }

    static toRGBAString(hex: string, alpha: number = 1): string {
        return this.hexToRGBA(hex, alpha);
    }

    static toggleColorOpacity(color: any, opaque: boolean = false): string | CanvasPattern {
        return this.isCanvasPattern(color) ? this.togglePatternOpacity(color, opaque) : this.toggleRGBAOpacity(color, opaque);
    }

    static toggleRGBAOpacity(rgba: string, opaque: boolean = false): string {
        return this.setRGBAOpacity(rgba, opaque ? this.opaque_default : 1);
    }

    static togglePatternOpacity(pattern: any, opaque: boolean = false): CanvasPattern {
        return pattern.opacity(opaque ? this.opaque_default : 1);
    }

    static setRGBAOpacity(rgba: string, opacityValue: number): string {
        const parsed = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return `rgba(${parsed[1]}, ${parsed[2]}, ${parsed[3]}, ${opacityValue})`;
    }

    private static hexToRGBA(hex: string, alpha: number = 1): string {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})` : null;
    }

    private static isCanvasPattern(value: any): boolean {
        return typeof value === "object";
    }
}
