<div id="simple-data-source-selector-{{ id }}" class="simple-data-source-selector">
    <kite-radio-group class="buttons">
        <div class="center-buttons">
            <div class="button-container" *ngFor="let option of options">
                <div class="button" (click)="selectOption(option)">
                    <div class="radio-selector">
                        <kite-radio-button
                            class="kite-radio"
                            [value]="option.value"
                            [(ngModel)]="selectedOption"
                            ngDefaultControl
                            [checked]="selectedOption === option.value"
                            >
                            {{option.label}}
                        </kite-radio-button>
                    </div>
                    <div class="image">
                        <div *ngIf="option.value === SalesDataTypes.REGISTRATION">
                            <img src="../../../../assets/images/registration-icon.png" />
                        </div>
                        <div *ngIf="option.value === SalesDataTypes.SALES">
                            <img src="../../../../assets/images/sales-icon.png" />
                        </div>
                    </div>
                    <div class="description">
                        {{option.text}}
                    </div>
                </div>
            </div>
        </div>
    </kite-radio-group>
</div>
