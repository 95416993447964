import { Injectable } from "@angular/core";
import { HttpServiceBase } from "@at/core";
import { chunk } from "lodash";
import { Observable, concat } from "rxjs";

export interface AdminManageUserParams {
    action: "create" | "reset" | "delete";
    group?: "admin";    // Standard should not have any group assignment.
    users: string[];
}

interface ActionError {
    user: string;
    message: string;
}

export interface UserInfo {
    userName: string;
    email: string;
    userCreatedDate: Date;
    userLastModifiedDate: Date;
    status: string;
    isAdmin: boolean;
}

export interface ManageUsersResponse {
    action: "create" | "delete";
    group?: "admin";
    created?: string[];
    deleted?: string[];
    edited?: string[];
    reset?: string[];
    errors: ActionError[];
}

export interface AdminGetUsersResponse {
    count: number;
    users: UserInfo[];
    errors: ActionError[];
}

export interface AdminPostUserGroupsParams {
    user: string;
    add?: string[];
    remove?: string[];
}

export interface AdminPostUserGroupsResponse {
    user: string;
    added?: string[];
    removed?: string[];
    errors: ActionError[];
}

@Injectable()
export class AdminManagementService extends HttpServiceBase {

    // Returns a response object; the ManageUsersResponse[] is to satisfy the postInterface's generic <T[]>.
    postAddUsers(emails: string[], reset?: boolean): Observable<ManageUsersResponse> {
        const url = `${this.rootUrl}/admin/users`;
        emails = emails.map(user => user.toLowerCase()); // force usernames to lowercase
        let params: AdminManageUserParams;
        const chunks = chunk(emails, 10).map((userChunk: string[]) => {
            // Currently only supports creating "standard" users (users in no group).
            if(reset){
                params = { action: "reset", users: userChunk } as AdminManageUserParams;
            }else{
                params = { action: "create", users: userChunk } as AdminManageUserParams;

            }
            return this.postInterface<ManageUsersResponse>(url, params);
        });

        // Queues users creation request to prevent exceeding API limit.
        return concat(...chunks);
    }

    getUserList(): Observable<AdminGetUsersResponse> {
        const url = `${this.rootUrl}/admin/users`;
        const options = this.createRequestOptions();
        return this.fetchInterfaceNoCache<AdminGetUsersResponse>(url, options);
    }

    postDeleteUser(email: string): Observable<ManageUsersResponse> {
        const url = `${this.rootUrl}/admin/users`;
        const params = { action: "delete", users: [email.toLowerCase()] } as AdminManageUserParams;
        return this.postInterface<ManageUsersResponse>(url, params);
    }

    postModifyUserGroups(email: string, add: string[], remove: string[]): Observable<AdminPostUserGroupsResponse> {
        const url = `${this.rootUrl}/admin/users/groups`;
        const params = { user: email.toLowerCase(), } as AdminPostUserGroupsParams;

        if (add && add.length > 0) {
            params.add = add;
        }
        if (remove && remove.length > 0) {
            params.remove = remove;
        }
        return this.postInterface<AdminPostUserGroupsResponse>(url, params);
    }

    // Reset uses the same endpoint and logic as add user. The endpoint
    // determines if the user needs to be added or reset base on their status.
    postResetUsers(emails: string[]): Observable<ManageUsersResponse> {
        return this.postAddUsers(emails, true);
    }

}
