import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "SigFig" })
export class SigFig implements PipeTransform {
    static staticTransform(value: number): string {
        // If value is not a number, it is a placeholder value e.g. dash for non-existing value.
        if (isNaN(Number(value))) {
            return "" + value;
        }
        const decimalPipe = new DecimalPipe("en-US");
        if(value%1===0){
            return decimalPipe.transform(value, "1.0-0");
        }
        if (value >= 100 || value <= -100) {

            return decimalPipe.transform(value, "3.2-2");
        }
        if (value >= 10.0 || value <= -10.0) {
            return decimalPipe.transform(value, "2.2-2");
        }
        if (value >= .01 || value <= -.01) {
            return decimalPipe.transform(value, "1.2-2");
        }
        return "0";
    }

    transform(value: number): string {
        return SigFig.staticTransform(value);
    }
}
