import { ThrowStmt } from "@angular/compiler";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { CognitoService } from "app/core/services/cognito.service";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import * as _ from "lodash";

import { FilterName } from "../../models/filter-name.enum";
import { PresentationTypes } from "../../models/presentation-type.enum";
import { AutoAnalyzerService } from "../../services/auto-analyzer.service";
import { DmaDataService } from "../../services/dma-data.service";
import { FilterStateService } from "../../services/filter-state.service";
import { NextButtonService } from "../../services/next-button.service";
import { PresentationFilterService } from "../../services/presentation-filter.service";
import { BaseSelectionSectionComponent, FilterSort } from "../selection-section/selection-section.component";
import { TooltipComponent } from "../tooltip/tooltip.component";

@Component({
    selector: "modal-selection-section",
    templateUrl: "./modal-selection-section.component.html",
    styleUrls: [
        "./modal-selection-section.component.scss",
        "./filter-overlay-styles.scss",
        "../selection-section/selection-section.component.scss"
    ]
})
// This component lives inside the create-tv-schedule modal component via template ref
export class ModalSelectionSectionComponent extends BaseSelectionSectionComponent implements OnInit {
    presentationType: string;
    dmaNames;
    allDmaLoadedSubscription;
    loadedOptions = false;
    spotlightDealerId: number;
    dmas: any = [];
    zones: any = [];
    makes: any = [];
    segments: any = [];
    selectionsLimit = 5;
    makeChipValues;
    segmentChipValues;
    zoneChipValues;

    zoneChipsToRender: any = [];
    makeChipsToRender: any = [];
    segmentChipsToRender: any = [];
    audienceAdZones: any = [];
    autoToolAdZones: any = [];
    audienceMakes: any = [];
    audienceSegments: any = [];
    convertedAudienceSegment: string;
    convertedMake: string;


    @ViewChild(TooltipComponent, { static: true }) tooltip: TooltipComponent;

    constructor(
        public presentationFilterService: PresentationFilterService,
        protected dmaDataService: DmaDataService,
        private filterStateService: FilterStateService,
        private autoAnalyzerService: AutoAnalyzerService,
        public nextButtonService: NextButtonService,
        protected ngrxFilterStateService: NgrxFilterStateService,
        public cognitoService: CognitoService,
    ) {
        super(dmaDataService, nextButtonService, cognitoService);
    }


    ngOnInit(): void {
        const val = this.presentationFilterService.getFilterValue<any[]>(FilterName.dma);
        this.dmaNames = this.presentationFilterService.getDmaNames(val);
        this.spotlightDealerId = this.filterStateService.getFilterValue(FilterName.spotlight_dealer);
        this.presentationType = this.presentationFilterService.presentationType;
        this.zones = this.presentationFilterService.getFilterValue(FilterName.top_zones);
        this.getAudienceAppAdZones(val).then(syscodes => {

            this.audienceAdZones = syscodes;
            // Check which presentation flow we're on.
            if (this.presentationType === PresentationTypes.MAKE_VS_MAKE) {
                this.getAudienceAppMakes().then(results => {

                    this.audienceMakes = Object.entries(results);
                    this.presentationFilterService.getFilterByDealerId(this.spotlightDealerId).then(makes => {
                        this.makes = makes;
                        this.setUpChips();
                    });
                });
            } else if (this.presentationType === PresentationTypes.MAKE_VS_SEGMENT) {
                this.getAudienceAppSegments().then(results => {

                    this.audienceSegments = Object.entries(results);
                    this.presentationFilterService.getFilterByDealerId(this.spotlightDealerId, FilterName.segments).then(segments => {
                        this.segments = segments;
                        this.setUpChips();
                    });
                });
            } else {
                this.setUpChips();
            }
        });
    }

    setUpChips(): void {
        this.loadedOptions = false;
        this.sortSelection = FilterSort.Sales;
        this.selectedSelections.next(this.filterState);
        this.zoneChipValues = this.generateChipValues(this.zones, FilterName.zones);

        if (this.presentationType === PresentationTypes.MAKE_VS_MAKE) {
            this.makeChipValues = this.generateChipValues(this.makes, FilterName.makes);

            this.checkValidMakes(this.makeChipValues, this.audienceMakes);
        } else if (this.presentationType === PresentationTypes.MAKE_VS_SEGMENT) {
            this.segmentChipValues = this.generateChipValues(this.segments, FilterName.segments);
            this.checkValidSegments(this.segmentChipValues, this.audienceSegments);
        }

        this.checkValidAdZones(this.zoneChipValues, this.audienceAdZones);
        this.setDefaultZoneSelections();
        // Rendered chips are set here.
        this.onSearchInputChanged(this.searchTerm);
        this.setExportedSelections();
        this.loadedOptions = true;
    }

    // First two ad zones are always preselected.
    setDefaultZoneSelections(): void {
        this.chipChanged(this.zoneChipValues[0]);
        if (this.zoneChipValues[1]) {
            this.chipChanged(this.zoneChipValues[1]);
        }
    }

    chipChanged(value: any): void {
        value.isSelected = !value.isSelected;
        this.selector = (value[0] && value[0]["id"]) ? "id" : "name";
        if (value.isSelected && this.selectionsLimit !== 0 && this.selectedOptions.length >= this.selectionsLimit) {
            this.selectedOptions.pop().isSelected = false; // if selected options is greater than the limit, remove the last option and unselect
        }
        if (value.isSelected) {
            let clonedState = _.cloneDeep(this.ngrxFilterStateService.getCurrentFilterState());
            let localVal = _.cloneDeep(value);
            clonedState.selected_options.push(localVal);
            this.ngrxFilterStateService.setCurrentFilterState(clonedState);
        } else {
            this.selectedOptions = this.selectedOptions.filter(e => e[this.selector] !== value[this.selector]);
            this.presentationFilterService.setFilterValue(FilterName.selected_options, this.selectedOptions);
        }
        this.setExportedSelections();
    }

    // Passing in chip type so we can parse selected options
    generateChipValues(rawChips: any[], chipType: FilterName): any[] {
        this.selectedOptions = [];
        const ret: any[] = [];

        for (let i = 0; i < rawChips.length; i++) {
            let c = null;

            if (rawChips[i]["id"]) {
                c = {
                    name: rawChips[i].name,
                    id: rawChips[i].id,
                    volume: rawChips[i].sales,
                    isSelected: this.filterState.includes(rawChips[i]["id"]),
                    dma: rawChips[i].dma_code,
                    index: i + 1,
                    filterName: chipType,
                    isValid: true,
                    syscode: rawChips[i].sys_code,
                    nickname: rawChips[i].name,
                };
            } else {
                const filterName = this.getKeyName(rawChips[i]);

                c = {
                    name: rawChips[i][filterName],
                    volume: rawChips[i].sales,
                    isSelected: this.filterState.includes(rawChips[i][filterName]),
                    index: i + 1,
                    filterName: chipType,
                    isValid: true,
                    syscode: rawChips[i].sys_code,
                    nickname: rawChips[i].name,
                };
            }
            if (c.isSelected) {
                this.selectedOptions.push(c);
            }
            ret.push(c);
        }
        return ret;
    }

    onSearchInputChanged(input: string): void {
        // Prevent this function from executing if this component was destroyed prior to data return.
        this.searchTerm = input;
        if (this.isDestroyed) {
            return;
        }
        this.zoneChipsToRender = this.getSearchedValues(input, this.zoneChipValues);
        if (this.presentationType === PresentationTypes.MAKE_VS_MAKE) {
            this.makeChipsToRender = this.getSearchedValues(input, this.makeChipValues);
        } else if (this.presentationType === PresentationTypes.MAKE_VS_SEGMENT) {
            this.segmentChipsToRender = this.getSearchedValues(input, this.segmentChipValues);
        }
    }

    getSearchedValues(searchTerm: string, chipValues: any): any[] {
        if (searchTerm && searchTerm.length) {
            const phrase = searchTerm.toLowerCase();
            const results = chipValues.filter(d => d.name.toLowerCase().match(phrase));
            return results;
        } else {
            return chipValues;
        }
    }

    toggleSort(value: boolean): void {
        //const isAlphabetical = FilterSort.Alphabetical === this.sortSelection;
        // Comment the above out because the event value is now sent from toggle.button.component
        if (!value) {
            this.sortSelection = FilterSort.Sales;

            // sort happens in place, so pass in a copy of the array in order to update the entire collection at once
            this.zoneChipsToRender = this.sortByVolume([...this.zoneChipsToRender]);
            if (this.presentationType === PresentationTypes.MAKE_VS_MAKE) {
                this.makeChipsToRender = this.sortByVolume([...this.makeChipsToRender]);
            } else if (this.presentationType === PresentationTypes.MAKE_VS_SEGMENT) {
                this.segmentChipsToRender = this.sortByVolume([...this.segmentChipsToRender]);
            }
        } else {
            this.sortSelection = FilterSort.Alphabetical;

            this.zoneChipsToRender = this.sortByName([...this.zoneChipsToRender]);
            if (this.presentationType === PresentationTypes.MAKE_VS_MAKE) {
                this.makeChipsToRender = this.sortByName([...this.makeChipsToRender]);
            } else if (this.presentationType === PresentationTypes.MAKE_VS_SEGMENT) {
                this.segmentChipsToRender = this.sortByName([...this.segmentChipsToRender]);
            }
        }
    }

    trackById(index: number, chip: any): number {
        return chip.id;
    }

    checkValidAdZones(autoToolZones: any[], audienceAdZones: any[]): void {

        if (audienceAdZones) {

            for (let i = 0; i < autoToolZones.length; i++) {
                for (let j = 0; j < audienceAdZones.length; j++) {
                    if (autoToolZones[i].syscode !== audienceAdZones[j].syscode) {
                        autoToolZones[i].isValid = false;
                    } else {
                        autoToolZones[i].isValid = true;
                        break;
                    }
                }
            }
        }

    }

    private getAudienceAppAdZones(code: any): Promise<any> {
        return this.autoAnalyzerService.getDMA(code).then(response => {
            if (response.isSuccess) {
                return response.results.syscodes;
            } else {
                console.error("No Ad Zones");
                return;
            }
        }).catch((error) => {
            console.error(error);
            return;
        });
    }

    checkValidMakes(autoToolMakes: any[], audienceMakes: any[]): void {

        if (audienceMakes) {
            for (let i = 0; i < autoToolMakes.length; i++) {
                for (let j = 0; j < audienceMakes.length; j++) {

                    this.convertedMake = audienceMakes[j][0];

                    // Convert chevy to chevrolet due to naming convention differences
                    if (audienceMakes[j][0] === "Chevy") {
                        this.convertedMake = "Chevrolet";
                    } else if (audienceMakes[j][0] === "Mercedes") {
                        this.convertedMake = "Mercedes-Benz";
                    }

                    if (autoToolMakes[i].name !== this.convertedMake) {
                        autoToolMakes[i].isValid = false;
                    } else {
                        autoToolMakes[i].isValid = true;
                        autoToolMakes[i].nickname = audienceMakes[j][0];
                        break;
                    }
                }
            }
        }
    }

    private getAudienceAppMakes(): Promise<any> {
        return this.autoAnalyzerService.getMakes().then(response => {
            if (response.isSuccess) {
                return response.results;
            } else {
                console.error("No Makes");
                return;
            }
        }).catch((error) => {
            console.error(error);
            return;
        });
    }

    private getAudienceAppSegments(): Promise<any> {
        return this.autoAnalyzerService.getSegments().then(response => {
            if (response.isSuccess) {
                return response.results;
            } else {
                console.error("No Segments");
                return;
            }
        }).catch((error) => {
            console.error(error);
            return;
        });
    }

    checkValidSegments(autoToolSegments: any[], audienceSegments: any[]): void {
        if (audienceSegments) {
            for (let i = 0; i < autoToolSegments.length; i++) {
                for (let j = 0; j < audienceSegments.length; j++) {
                    // Convert strings
                    this.convertedAudienceSegment = this.convertSegment(audienceSegments[j]);
                    if (autoToolSegments[i].name !== this.convertedAudienceSegment) {
                        autoToolSegments[i].isValid = false;
                    } else {
                        autoToolSegments[i].isValid = true;
                        autoToolSegments[i].nickname = audienceSegments[j][0];
                        break;
                    }
                }
            }
        }
    }

    convertSegment(segment: any): string {

        if (segment[0] === "lux_xover_mid_size_suv") {
            return "Luxury Cross-Over & Mid-Size Suv";
        } else if (segment[0] === "lux_full_size_suv") {
            return "Luxury Full Size Suv";
        } else if (segment[0] === "lux_sedan_sports_veh") {
            return "Luxury Sedan Sports Vehicle";
        } else if (segment[0] === "lux_compact") {
            return "Luxury Compact";
        } else if (segment[0] === "xover_mid_size_suv") {
            return "Cross-Over & Mid-Size Suv";
        } else if (segment[0] === "large_suv_minivans") {
            return "Large Suv & Minivan";
        } else if (segment[0] === "non_lux_full_size_sedan") {
            return "Non-Luxury Full-Size Sedan";
        } else if (segment[0] === "non_lux_mid_size_sedan") {
            return "Non-Luxury Mid-Size Sedan";
        } else if (segment[0] === "non_lux_sedan_suv") {
            return "Non-Luxury Sedan Sports Vehicle";
        } else if (segment[0] === "econ_cars") {
            return "Economy Car";
        } else if (segment[0] === "new_truck") {
            return "Truck";
        } else if (segment[0] === "used_car") {
            return "Used Car";
        } else if (segment[0] === "used_truck_suv_xover") {
            return "Used Truck/SUV/CUV";
        } else if (segment[0] === "new_alt_fuel_veh") {
            return "New Alternative Fuel Vehicle";
        } else if (segment[0] === "used_alt_fuel_veh") {
            return "Used Alternative Fuel Vehicle";
        } else if (segment[0] === "minivans") {
            return "Minivans";
        } else if (segment[0] === "non_lux_large_suv") {
            return "Non-Lux Large SUV";
        } else if (segment[0] === "three_qtr_1ton_truck") {
            return "3 Quarter to 1 Ton Pickup Truck";
        } else if (segment[0] === "halfton_truck") {
            return "Half Ton Full Size Pickup Truck";
        } else if (segment[0] === "mid_size_truck") {
            return "Mid-Size Pickup Truck";
        }

    }


}
