import { Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { FilterName } from "app/core/models/filter-name.enum";
import { ClientMetadata } from "app/core/models/metadata.model";
import { FilterStateService } from "app/core/services/filter-state.service";
import * as moment from "moment";
import { BehaviorSubject, Subscription } from "rxjs";

import { MetadataService } from "../../../services/metadata.service";

export interface DateRange {
    start: string;
    end: string;
}

@Component({
    selector: "date-shift",
    templateUrl: "./date-shift.component.html",
    styleUrls: ["./date-shift.component.scss"],
    encapsulation: ViewEncapsulation.None
})

export class DateShiftComponent implements OnInit, OnChanges, OnDestroy {
    @Input() limitToDisplay = 12;
    @Input() stepInterval = 1; // Number of months per navigation, defaults to one month.
    @Input() rangeType: DateRangeTypes = DateRangeTypes.ROLLING12;
    @Input() reversed = false;

    @Output() public readonly dateRange: BehaviorSubject<DateRange> = new BehaviorSubject<DateRange>({ start: "", end: "" });

    metadataServiceSubscription: Subscription;
    pastDisabled = true; // go back in time button
    futureDisabled = true; // go forward in time button

    maxDate: string;
    minDate: string;
    useSalesData: boolean;

    private defaultMaxDate: string;
    private defaultMinDate: string;
    private defaultMaxSalesDate: string;
    private defaultMinSalesDate: string;

    constructor(
        private metadataService: MetadataService,
        private filterStateService: FilterStateService
    ) { }

    ngOnInit(): void {
        this.metadataServiceSubscription = this.metadataService.metadata.subscribe((metadata: ClientMetadata) => {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.rangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            this.defaultMinDate = metadata.minDate;
            this.defaultMaxDate = metadata.maxDate;
            this.defaultMinSalesDate = metadata.minSalesDate;
            this.defaultMaxSalesDate = metadata.maxSalesDate;
            this.minDate = this.useSalesData ? this.defaultMinSalesDate : this.defaultMinDate;
            this.maxDate = this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate;

            if (this.rangeType === DateRangeTypes.YEARS) {
                this.minDate = this.useSalesData ? metadata.minSalesDate.slice(0, 4) : metadata.minDate.slice(0, 4);
                this.maxDate = this.useSalesData ? metadata.maxSalesDate.slice(0, 4) : metadata.maxDate.slice(0, 4);
            }

            this.setDatesToDefault();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.use_sales_data || (changes.rangeType &&
            changes.rangeType.currentValue !== changes.rangeType.previousValue &&
            this.minDate && this.maxDate)) {
            this.rangeType = changes.rangeType.currentValue as DateRangeTypes;

            if (this.filterStateService.getFilterValue(FilterName.dateRangeType) !== this.rangeType) {
                this.filterStateService.setFilterValue(FilterName.dateRangeType, this.rangeType);
            }
            const dateRange = this.filterStateService.getFilterValue(FilterName.dateRanges);
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);

            if (this.rangeType === DateRangeTypes.MONTHS && dateRange !== undefined) {
                this.minDate = dateRange[4];
                this.maxDate = dateRange[1];
            } else if (this.rangeType === DateRangeTypes.YEARS) {
                this.minDate = this.useSalesData ? this.defaultMinSalesDate.slice(0, 4) : this.defaultMinDate.slice(0, 4);
                this.maxDate = this.useSalesData ? this.defaultMaxSalesDate.slice(0, 4) : this.defaultMaxDate.slice(0, 4);
            } else if (this.rangeType === DateRangeTypes.YTD || this.rangeType === DateRangeTypes.ROLLING12) {
                this.minDate = this.useSalesData ? this.defaultMinSalesDate : this.defaultMinDate;
                this.maxDate = this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate;
            }

            this.setDatesToDefault();
        }
    }

    ngOnDestroy(): void {
        if (this.metadataServiceSubscription) {
            this.metadataServiceSubscription.unsubscribe();
        }
    }

    setDatesToDefault(yearUseMonthFlag?: boolean): void {
        let start;
        const dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
        const dateRange = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);

        if (dateRangeType === DateRangeTypes.MONTHS) {
            if (this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.MONTHS && dateRange && dateRange.length >= 5) {
                this.maxDate = dateRange[1];
            }
            start = DateUtils.monthYearAddMonths(this.maxDate, -(this.limitToDisplay - 1));
        } else if (dateRangeType === DateRangeTypes.ROLLING12 || dateRangeType === DateRangeTypes.YTD) {
            this.maxDate = this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate;
            start = DateUtils.monthYearAddMonths(this.maxDate, -(this.limitToDisplay - 1));
            if (start.length > 0 && this.useSalesData) {
                start += "01";
            }
        } else {
            const limitYearsToDisplay = 3;
            if (yearUseMonthFlag) {
                this.maxDate = this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate;
                this.minDate = this.useSalesData ? this.defaultMinSalesDate : this.defaultMinDate;
            } else {
                this.minDate = this.useSalesData ? this.defaultMinSalesDate.slice(0, 4) : this.defaultMinDate.slice(0, 4);
                this.maxDate = this.useSalesData ? this.defaultMaxSalesDate.slice(0, 4) : this.defaultMaxDate.slice(0, 4);
                if (this.maxDate.length === 6) {
                    this.maxDate = this.maxDate.slice(0, 4);
                }
                if (this.minDate.length === 6) {
                    this.minDate = this.minDate.slice(0, 4);
                }
            }
            start = (Number(this.maxDate) - (limitYearsToDisplay - 1)).toString();
        }

        const range = {
            start,
            end: this.maxDate
        };
        this.dateRange.next(range);
        this.evaluateButtonDisabled();
    }

    dateNavigate(previous: boolean = true): void {
        let start = this.dateRange.value.start;
        let end = this.dateRange.value.end;

        if (this.rangeType !== DateRangeTypes.YEARS) {
            if (previous && !this.pastDisabled) { // step backwards in time
                start = DateUtils.monthYearAddMonths(start, -this.stepInterval);
                end = DateUtils.monthYearAddMonths(end, -this.stepInterval);
            } else if (!previous && !this.futureDisabled) { // step forward in time
                start = DateUtils.monthYearAddMonths(start, this.stepInterval);
                end = DateUtils.monthYearAddMonths(end, this.stepInterval);
            }
            if (this.useSalesData) {
                start += "01";
                const endYr = parseInt(end.substring(0, 4), 10);
                const maxYr = parseInt(this.defaultMaxSalesDate.substring(0, 4), 10);
                if (endYr === maxYr) {
                    end += this.defaultMaxSalesDate.substring(6, 8);
                } else {
                    end = DateUtils.appendLastDayOfMonth(end);
                }
            }
        } else {
            if (previous && !this.pastDisabled) { // step backwards in time
                start = (Number(start) - this.stepInterval).toString();
                end = (Number(end) - this.stepInterval).toString();
            } else if (!previous && !this.futureDisabled) { // step forward in time
                start = (Number(start) + this.stepInterval).toString();
                end = (Number(end) + this.stepInterval).toString();
            }
        }

        this.dateRange.next({ start, end });
        this.evaluateButtonDisabled();
    }

    evaluateButtonDisabled(): void {
        this.pastDisabled = this.futureDisabled = false;
        if (this.dateRange.value.start <= this.minDate) {
            this.pastDisabled = true;
        }
        if (!this.pastDisabled && this.rangeType === DateRangeTypes.MONTHS) {
            // Block the case where navigating month increments into the past results in no data.
            const further = DateUtils.monthYearAddMonths(this.dateRange.value.start, -this.stepInterval);
            if (further < this.minDate) {
                this.pastDisabled = true;
            }
        }
        if (this.dateRange.value.end >= this.maxDate) {
            this.futureDisabled = true;
        }
        if (!this.futureDisabled && this.rangeType === DateRangeTypes.MONTHS) {
            // Block the case where navigating month increments into the future results in no data.
            const further = DateUtils.monthYearAddMonths(this.dateRange.value.end, this.stepInterval);
            if (further > this.maxDate) {
                this.futureDisabled = true;
            }
        }
    }

    disableNavigation(): void {
        this.pastDisabled = true;
        this.futureDisabled = true;
    }

    enableNavigation(): void {
        this.evaluateButtonDisabled();
    }

    getDefaultMin(useSalesData: boolean = false): string {
        return useSalesData ? this.defaultMinSalesDate : this.defaultMinDate;
    }
}
