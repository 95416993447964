import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { CognitoService } from "app/core/services/cognito.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { OverlayService } from "app/core/services/overlay.service";
import { PopupService } from "app/core/services/popup.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { SpotlightService } from "app/core/services/spotlight.service";
import { isEqual } from "lodash";
import { Subscription } from "rxjs";

import { BaseSelectionSectionComponent } from "../selection-section/selection-section.component";

@Component({
    selector: "spotlight-selection-panel",
    templateUrl: "./spotlight-selection-panel.component.html",
    styleUrls: ["./spotlight-selection-panel.component.scss"],
})
export class SpotlightSelectionPanelComponent implements OnInit, OnDestroy {
    title = "You may select up to 1 dealer to be in the spotlight.";

    FilterName = FilterName;
    selectionsLimit = 1;
    clearButtonDisabled = true;
    searchButtonDisabled = true;
    topXButtonDisabled = true;
    radiusRingButtonDisabled = true;
    searchOpenState = true;

    private filterStateServiceSubscription: Subscription;
    private selectionSelectionsSubscription: Subscription;

    @ViewChild(BaseSelectionSectionComponent, { static: true }) selectionSection: BaseSelectionSectionComponent;

    constructor(
        public filterStateService: FilterStateService,
        private overlayService: OverlayService,
        private popupService: PopupService,
        private spotlightService: SpotlightService,
        public salesDataService: SalesDataService,
        private cognitoService: CognitoService,
    ) { }

    ngOnInit(): void {
        this.popupService.blockPopup();
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.onFiltersUpdated.bind(this));
        this.selectionSelectionsSubscription = this.selectionSection.selectedSelections.subscribe(this.getSelectionText.bind(this));
        this.selectionSection.filterService = this.filterStateService;
        this.onFiltersUpdated([FilterName.new_used_flag.toString()]); // initialize filter variable
    }

    ngOnDestroy(): void {
        this.popupService.unblockPopup();
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
            this.filterStateServiceSubscription = undefined;
        }
        if (this.selectionSelectionsSubscription) {
            this.selectionSelectionsSubscription.unsubscribe();
            this.selectionSelectionsSubscription = undefined;
        }
        this.saveSelections();
    }

    saveSelections(): void {
        const selections = this.selectionSection.selectedSelections.getValue();
        const selection = selections.length ? selections[0] : null;

        if (isEqual(selection, this.filterStateService.getFilterValue(FilterName.spotlight_dealer))) {
            return;
        }
        this.spotlightService.setSpotlight(selection);
    }

    closePanel(): void {
        this.overlayService.hidePanel();

        this.cognitoService.setHeapReportParams("dma", this.filterStateService.getFilterValue(FilterName.dma));
        const heapReport = this.cognitoService.generateHeapReport();
        this.cognitoService.heapTrack("custom_flow_spotlight_results", heapReport);
    }

    onFiltersUpdated(which: string[]): void {
        if (which.includes(FilterName.dma.toString())) {
            this.closePanel();
        }
        if (which.includes(FilterName.new_used_flag.toString()) || which.includes(FilterName.buyer_dma_code.toString())) {
            this.selectionSection.setupChips();
            this.selectionSection.filterState = [this.filterStateService.getFilterValue(FilterName.spotlight_dealer)] || [];
        }
    }

    getSelectionText(values: string[] | number[]): void {
        if (values.length > 0 && values[0] !== null) {
            this.title = "The maximum number of dealers have been selected.";
        } else {
            this.title = "You may select up to 1 dealer to be in the spotlight.";
        }
    }
}
