import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FilterName, FilterStateService } from "@at/core";
import { FeatureFlagService } from "app/core/services/feature-flag.service";
import { OverlayService } from "app/core/services/overlay.service";

@Component({
    selector: "navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
    showNavItems = true;
    canViewComposite = true;

    constructor(
        private overlayService: OverlayService,
        private router: Router,
        private featureFlagService: FeatureFlagService,
        private filterStateService: FilterStateService
    ) {
        this.router.events.subscribe(() => this.hideNavItems());
        this.hideNavItems();
        this.canDoComposite();
    }

    ngOnInit() {
        (window as any).Appcues.loadLaunchpad("#appcues-launchpad", {
            position: "left",
            icon: "/assets/icons/list.png",
            // header: "<h1>Title</h1>",
            // footer: "<p>Footer</p>",
        });
    }

    async canDoComposite(): Promise<void> {
        this.canViewComposite = await this.featureFlagService.viewComposite();
    }

    hideNavItems(): void {
        if (!(this.router.url.includes("trends") || this.router.url.includes("insights") || this.router.url.includes("map"))) {
            this.showNavItems = false;
        } else {
            this.showNavItems = true;
        }
    }

    openMenu(): void {
        this.overlayService.showMenuPanel();
    }

}
