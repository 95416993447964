<div class="collapsible-panel" dSwipeDetector [ngClass]="{'collapse-left':collapseLeft, 'collapse-right':collapseRight,
    open:panelState.value===PanelState.Open, closed:panelState.value===PanelState.Closed,
    opening:panelState.value===PanelState.Opening, closing:panelState.value===PanelState.Closing,'overlay-hide':overlayHide}">
    <div class="content-wrapper">
        <ng-content></ng-content>
    </div>
    <div class="panel-toggle-strip" [class.hovering]="hovering">
        <div class="move-handle-icon"></div>
    </div>
    <div class="panel-toggle-strip strip-target" (click)="toggleStripClick()" (mouseleave)="hideStrip()" (mouseover)="showStrip()">
    </div>
</div>
<div class="collapsible-panel ghost-placeholder" [ngClass]="{'collapse-left':collapseLeft, 'collapse-right':collapseRight,
open:panelState.value===PanelState.Open, closed:panelState.value===PanelState.Closed,
opening:panelState.value===PanelState.Opening, closing:panelState.value===PanelState.Closing,'overlay-hide':overlayHide}"></div>
