import { appLastUpdated, version } from "./version";

export const environment = {
    name: "dev",
    production: false,
    pullRequest: false,
    loginBypass: false,
    mapbox: {
        accessToken: "pk.eyJ1IjoibWVkaWFzb2x1dGlvbnMiLCJhIjoiY2pqdDNvNWpvMDE0dzNwcWlsOHJ3MG1ociJ9.PLy22vxlBRTMT6GDpCXn2g"
    },
    apiBaseUrl: "https://dev.services.spectrumreach.io/automotive",
    heapAnalytics: "827116418", // dev account
    aws: {
        region: "us-east-1",
        identityPoolId: "us-east-1:84584599-1d91-4d47-9d7e-664e6a978168",
        userPoolId: "us-east-1_5gG09tQc0",
        clientId: "boulqi5qb5km1qrdjidsvja4c",
    },

    techEmail: "reachme@charter.com",
    appLastUpdated,
    version,
    optimizelySdkKey: "CMidxQXtJihHyCFuwuamCv",
    aaBaseUrl: "https://uat01.omniapp.tv/",
    aaAPIUrl: "https://uat01-api.omniapp.tv/",
    aaBaseAPIPath: "api/omni/v1/auto-analyzer/",
    uspsCharterUser: "177CHART6825"
};
