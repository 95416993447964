import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { DomUtils } from "@at/utils";
import { FilterName } from "app/core/models/filter-name.enum";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SpotlightService } from "app/core/services/spotlight.service";
import { compact, flattenDeep, uniq, without, xor } from "lodash";

import { DmaNames } from "./dma-mapping";

@Component({
    selector: "dma-selection",
    templateUrl: "./dma-selection.component.html",
    styleUrls: ["./dma-selection.component.scss"]
})
export class DmaSelectionComponent implements OnInit, OnDestroy {
    recentDmaIds: number[] = [];
    focusedDmaButton: number;
    menuOpen = false;
    searchInput = "";
    filteredDma: number[] = [];
    searchFocus: boolean;
    dmas: { id: number; longName: string; shortName: string }[] = [];

    private filterStateServiceSubscription: any;
    private allDmaLoadedSubscription: any;

    @ViewChild("search") dmaSearchInputEl: ElementRef;
    @ViewChildren("options") optionButtonEls: QueryList<ElementRef>;

    constructor(
        private filterStateService: FilterStateService,
        private dmaDataService: DmaDataService,
        private dealerDataService: DealerDataService,
        private spotlightService: SpotlightService) { }

    ngOnInit(): void {
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.updateCurrentDma.bind(this));
        this.allDmaLoadedSubscription = this.dmaDataService.allDmasLoaded.subscribe((loaded) => {
            if (loaded) {
                this.updateCurrentDma(["dma"]);
                this.recentDmaIds = this.dmaDataService.getRecentDmas();
            }
        });
        this.dmaDataService.loadAllDmas();
    }

    ngOnDestroy(): void {
        this.filterStateServiceSubscription.unsubscribe();
        this.allDmaLoadedSubscription.unsubscribe();
    }

    updateCurrentDma(changes: string[]): void {
        if (changes.includes("dma")) {
            this.updateDmas(this.dmaDataService.getCurrentDmaId());
        }
    }

    addToRecentDma(dmaId: number): void {
        let recent = without(this.recentDmaIds.slice(), dmaId);
        recent.unshift(dmaId);
        recent = compact(flattenDeep(uniq(recent)));
        recent = recent.slice(0, 11);
        this.filterStateService.setRecentDma(recent);
        this.recentDmaIds = recent;
    }

    openMenu(event: MouseEvent): void {
        this.menuOpen = !this.menuOpen;
        this.searchInput = "";
        this.onSearchChange();
        DomUtils.stopEventPropagation(event);
        this.focusedDmaButton = -1;

        setTimeout(() => {
            this.focusSearch();
        }, 0);
        // setTimeout allows for the nativeElement to be bound properly to the dmaSearchInput ViewChild
    }

    closeMenu(): void {
        this.menuOpen = false;
        this.saveDmaFilterState();
    }

    onSearchChange(): void {
        if (this.searchInput.length > 0) {
            const cleanedInput = this.searchInput.toLowerCase().replace(/[^0-9a-z]/g, ""); // remove non-alpha
            let num = 0;
            this.filteredDma = this.dmaDataService.getAllDmaIds().filter(dma => {
                const searchValue = `${this.getDmaName(dma)}, ${dma}, ${this.getDmaName(dma, true)}`;
                const cleanedDmaName = searchValue.toLowerCase().replace(/[^0-9a-z]/g, ""); // replace all non-alpha with empty string
                if (!this.dmas.map(currentDma => currentDma.id).includes(dma) && cleanedDmaName.includes(cleanedInput) && num < 11) {
                    num++;
                    return true;
                } else {
                    return false;
                }
            });
        } else {
            this.filteredDma = without(this.recentDmaIds, ...this.dmas.map(dma => dma.id));
        }
    }

    focusNext(): void {
        const opts = this.optionButtonEls.toArray();
        if (opts.length > this.focusedDmaButton + 1) {
            const element = opts[this.focusedDmaButton + 1];
            element.nativeElement.focus();
        }
    }

    focusPrevious(): void {
        if (this.searchFocus) {
            return;
        }
        if (this.focusedDmaButton === 0) {
            this.focusSearch();
            return;
        }
        const opts = this.optionButtonEls.toArray();
        if (opts.length > this.focusedDmaButton - 1) {
            const element = opts[this.focusedDmaButton - 1];
            element.nativeElement.focus();
        }
    }

    focusSearch(): void {
        if (this.dmaSearchInputEl) {
            const val = this.searchInput;
            this.dmaSearchInputEl.nativeElement.focus();
            setTimeout(() => {
                // this moves cursor to end of line, silly but apparently the only method.
                this.dmaSearchInputEl.nativeElement.value = "";
                this.dmaSearchInputEl.nativeElement.value = val;
            }, 0);
        }
    }

    onFocusDmaButton(index: number): void {
        this.focusedDmaButton = index;
    }

    onBlurDmaButton(): void {
        this.focusedDmaButton = -1;
    }

    selectDma(dmaId: number, event?: Event): void {
        let current = this.dmas.map(dma => dma.id).slice();
        current.unshift(dmaId);
        current = uniq(current).slice(0, 3);
        this.updateDmas(current);
        this.addToRecentDma(dmaId);
        this.onSearchChange();
        DomUtils.stopEventPropagation(event);
    }

    removeDma(dmaId: number, event?: Event): void {
        let current = this.dmas.map(dma => dma.id).slice();
        current = without(current, dmaId);
        this.updateDmas(current);
        this.onSearchChange();
        DomUtils.stopEventPropagation(event);
    }

    saveDmaFilterState(): void {
        const previous = this.filterStateService.getFilterDma();
        const next = this.dmas.map(dma => dma.id).slice(0, 3);
        if (xor(previous, next).length > 0 && next.length > 0) {
            const spotlightDealer = this.dealerDataService.spotlightDealerDetails.getValue();
            if (spotlightDealer && !next.includes(spotlightDealer.dealer_dma_code)) {
                this.spotlightService.clearSpotlight();
            }
            this.filterStateService.setFilterValue(FilterName.dma, next);
            //this.filterStateService.setFilterValue(FilterName.buyer_dma_code, next);
            this.filterStateService.resetResettableFilters();
        }
        this.updateDmas(this.filterStateService.getFilterDma());
    }

    updateDmas(currentDmas: number[]): void {
        this.dmas = this.getDmaNames(currentDmas);
    }

    getDmaName(dmaId: number, short: boolean = false): string {
        if (short) {
            // Used short name mapping, else use truncated short name.
            return DmaNames[dmaId] ? DmaNames[dmaId].shortName : this.dmaDataService.getShortDmaName(dmaId);
        }
        return this.dmaDataService.getDmaName(dmaId);
    }

    getDmaNames(dmaIds: number[]): { id: number; longName: string; shortName: string }[] {
        return dmaIds.map(dmaId => ({ id: dmaId, longName: this.getDmaName(dmaId), shortName: this.getDmaName(dmaId, true) }));
    }

    sortDmas(property: string): { id: number; longName: string; shortName: string }[] {
        if (!this.dmas) {
            return;
        }
        return this.dmas.sort((a, b) => a[property] > b[property] ? 1 : a[property] === b[property] ? 0 : -1);
    }

    clearSearch(): void {
        this.dmaSearchInputEl.nativeElement.value = "";
        this.dmaSearchInputEl.nativeElement.dispatchEvent(new Event("input"));
        this.focusSearch();
    }

    @HostListener("document:keydown", ["$event"])
    processGlobalHotkeys(event: KeyboardEvent): void {
        if (event["_stopPropagation"]) {
            return;
        }
        if (!this.menuOpen) {
            return;
        }
        if (event.key === "Escape" || event.code === "27") {
            if (this.searchInput === "") {
                this.closeMenu();
            } else {
                this.clearSearch();
            }
            DomUtils.stopEventPropagation(event);
            return;
        }
        if (event.key === "ArrowDown" || event.code === "40") {
            this.focusNext();
            DomUtils.stopEventPropagation(event);
            return;
        }
        if (event.key === "ArrowUp" || event.code === "38") {
            this.focusPrevious();
            DomUtils.stopEventPropagation(event);
            return;
        }
        if ((event.key === "Enter" || event.code === "13") &&
            this.searchFocus && this.filteredDma.length > 0 && this.dmas.length < 3) {
            this.selectDma(this.filteredDma[0]);
            DomUtils.stopEventPropagation(event);
            return;
        }
    }
}
