import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { AsyncSubject, Observable, Subscription, throwError as observableThrowError } from "rxjs";
import { catchError, delay, tap } from "rxjs/operators";

import { RequestMonitoringService } from "../services/request-monitoring.service";

export const InterceptorSkipHeader = "X-Skip-Interceptor";

@Injectable()
export class ClientCacheInterceptor implements HttpInterceptor, OnDestroy {

    private cache: { [name: string]: AsyncSubject<HttpEvent<any>> } = {};
    private cacheInterval: ReturnType<typeof setInterval>;
    private cacheSubscription: Subscription;

    constructor(private reqMonSrv: RequestMonitoringService) {
        this.cacheInterval = setInterval(() => {
            this.cache = {};
        }, 60000 * 60 * 24); // 60000ms in one minute * 60 minutes * 24 hours = 1 day
    }

    ngOnDestroy(): void {
        clearInterval(this.cacheInterval);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // A workaround to prevent certain GET request from retrieving from cache.
        if (request.headers.has(InterceptorSkipHeader)) {
            const headers = request.headers.delete(InterceptorSkipHeader);
            let clone = request.clone({ headers });
            if (request.method === "POST") {
                clone = request.clone({ headers, body: request.body });
            }
            this.reqMonSrv.addToQueue(clone);
            return next.handle(clone).pipe(tap(() => this.reqMonSrv.removeFromQueue(clone)));
        }

        if (request.method !== "GET" && !request.headers.has(InterceptorSkipHeader)) {
            this.reqMonSrv.addToQueue(request);
            return next.handle(request).pipe(tap(() => this.reqMonSrv.removeFromQueue(request)));
        }

        this.reqMonSrv.addToQueue(request);

        const cachedResponse = this.cache[request.urlWithParams] || null;
        if (cachedResponse) {
            this.reqMonSrv.removeFromQueue(request);
            return cachedResponse.pipe(delay(0));
        }
        const subject = this.cache[request.urlWithParams] = new AsyncSubject<HttpEvent<any>>();
        this.cacheSubscription = next.handle(request)
            .pipe(catchError((err: HttpErrorResponse, retry: Observable<HttpEvent<any>>): Observable<any> => {
                // If the access token timed out, token.interceptor.ts will renew the token.
                // Remove request from cache to prevent bad request results and continue request event.
                if (err.status === 401) {
                    this.reqMonSrv.removeFromQueue(request);
                    delete this.cache[request.urlWithParams];
                    return next.handle(request);
                }
                return observableThrowError(err);
            })
            , tap(event => {
                if (event instanceof HttpResponse) {
                    subject.next(event);
                    subject.complete();
                    this.reqMonSrv.removeFromQueue(request);
                }
            })).subscribe(); // must subscribe to actually kick off request!
        return subject;
    }
}
