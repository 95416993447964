import { Component, Input } from "@angular/core";

import { AccordionComponent } from "../../accordion/accordion.component";

// e.g. { "2018": { share: 10, sales: 125 } }
interface DateSalesMap {
    [key: number]: { shares: any; sales?: any; };
}

// Copying the interface in favor of a more specific name.
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesAccordionHeaderRollup extends DateSalesMap { }

export interface SalesAccordionSalesItem {
    label: string;
    sales: DateSalesMap;
}

@Component({
    selector: "sales-accordion",
    templateUrl: "./sales-accordion.component.html",
    styleUrls: ["../../accordion/accordion.component.scss", "./sales-accordion.component.scss"]
})
export class SalesAccordionComponent extends AccordionComponent {
    @Input() rollups: SalesAccordionHeaderRollup;
    @Input() sales: SalesAccordionSalesItem[];
    @Input() showVolume: boolean;

    Number = Number; // For template use.

    fixShares(item: SalesAccordionHeaderRollup): SalesAccordionHeaderRollup {
        for (const key in item) {
            if (this.getType(item[key].sales) === "string") {
                item[key].shares = "n/a";
                item[key].sales = "n/a";
            }
        }
        return item;
    }

    salesKeys(item: SalesAccordionHeaderRollup): string[] {
        return Object.keys(item).sort((d1, d2) => Number(d2) - Number(d1));
    }

    getType(item: any): string {
        if (item === null) {
            return "null";
        } else if (Array.isArray(item)) {
            return "array";
        }
        return typeof item;
    }
}
