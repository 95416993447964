<div class="card-header" [class.loading]="loading" [class.transitioning]="transitioning" [class.disabled]="disabled"
    [class.expanded]="isOpen"
    (click)="!disabled && toggleOpenClosed()">
    <div class="details">
        <div class="title">{{cardTitle}}</div>
        <div class="description" *ngIf="!descriptionDisabled">{{description}}</div>
    </div>
    <div class="icon">
        <span class="arrow" [class.expanded]="isOpen"></span>
    </div>
</div>
<div class="card-content" [class.paddingless]="paddingless" [class.transitioning]="transitioning"
    [class.expanded]="isOpen"
    [class.loading]="loading">
    <ng-container *ngIf="isOpen">
        <div class="loading-pane" *ngIf="loading || transitioning">
            <div class="loading-icon"></div>
        </div>
        <ng-content></ng-content>
    </ng-container>
</div>
