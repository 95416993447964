import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FeatureFlagService } from "@at/core";
import { CognitoService } from "app/core/services/cognito.service";
import { PresentationFilterService } from "app/core/services/presentation-filter.service";

import { FilterName } from "../../../models/filter-name.enum";

@Component({
    selector: "presentation-nav-item",
    templateUrl: "./nav-item.component.html",
    styleUrls: ["./nav-item.component.scss"]
})
export class PresentationNavItemComponent {

    @Input() enabledItem = false;
    @Input() filterName: FilterName;
    @Input() title = "";
    @Input() subtitle = "";
    @Input() menuNumber = "";
    @Input() activeFilter;
    @Output() navClicked = new EventEmitter();

    canViewOptionalSubtitles: boolean;

    constructor(
        private cognitoService: CognitoService,
        private presentationFilterService: PresentationFilterService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.canViewOptional();
    }

    async canViewOptional(): Promise<void> {
        this.canViewOptionalSubtitles = await this.featureFlagService.viewOptionalRequiredSubtitles();
    }

    navClick(): void {
        if (this.enabledItem) {
            this.navClicked.emit(this.filterName);
        }
        if (this.title === "Results") {
            //sends heap report data in simplified flows
            let geography = this.presentationFilterService.getFilterValue(FilterName.dma);
            const useSalesData = this.presentationFilterService.getFilterValue<boolean>(FilterName.use_sales_data);

            if (geography[0]) {
                this.cognitoService.setHeapReportParams("geography", geography);
            }

            if (useSalesData) {
                this.cognitoService.setHeapReportParams("dataSource", "weekly_sales");
            } else {
                this.cognitoService.setHeapReportParams("dataSource", "monthly_registration");
            }

            // The following code will eventually be needed based on the needs of the team using Heap Analytics
            // Currently dataSourceDateType is set to default of Rolling12 when the Results page is entered.
            // and dataSourceDateRange will still be null. In order to track these values, the Heap tracking code
            // for dataSourceDateType and dataSourceDateRange needs to be added to the toggleDateRange method
            // of the page.component.ts for Make v Make, Make v Segment and Marketplace.
            //
            // Once the decision to track dataSourceDateType and dataSourceDateRange is made, the following code
            // may be uncommented, and similar code should be added to the 3 page.component.ts toggleDateRange methods.

            // const dateRangeType = this.presentationFilterService.getFilterValue<string>(FilterName.dateRangeType);
            // this.cognitoService.setHeapReportParams("dataSourceDateType", dateRangeType);

            // if (dateRangeType === DateRangeTypes.MONTHS) {
            //     const dateRanges = this.presentationFilterService.getFilterValue<string[]>(FilterName.dateRanges);
            //     let date = `${dateRanges[0]}-${dateRanges[1]}`;
            //     const maxSalesDate = moment(this.presentationFilterService.getFilterValue(FilterName.maxSalesDate));
            //     const minSalesDate = moment(this.presentationFilterService.getFilterValue(FilterName.minSalesDate));
            //     const dates = DateUtils.popUpDateRange(useSalesData, date, dateRangeType, minSalesDate.toDate(), maxSalesDate.toDate());

            //     this.cognitoService.setHeapReportParams("dataSourceDateRange", dates);
            // }

            const heapReport = this.cognitoService.generateHeapReport();
            this.cognitoService.heapTrack("simplified_flow_results", heapReport);
        }
    }
}
