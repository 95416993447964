import * as bbox from "@turf/bbox";
import circle from "@turf/circle";
import { AllGeoJSON, Feature, MultiPolygon, Polygon, polygon } from "@turf/helpers";
import intersect from "@turf/intersect";
import { buffer, centroid, union } from "@turf/turf";
import { Dealer } from "app/core/models/dealers.model";
import { chunk, some } from "lodash";
import { CirclePaint, GeoJSONGeometry, Layer, LngLatBoundsLike } from "mapbox-gl";
import * as hasher from "object-hash";
import { ChartColor } from "../charts/chart/chart-color.utils";


export interface CircleOptions {
    center: number[]; // lat, lng
    radiusInMiles: number;
    id?: string;
}

export interface CombinedCoordinates {
    ne: number[];
    sw: number[];
}

export interface MapUtilsResponse {
    sourceId?: string;
    layerId?: string;
    bbox?: LngLatBoundsLike;
    feature?: Feature<Polygon>;
}

export class MapUtils {

    addRadiusRingLayer(map: mapboxgl.Map, options: CircleOptions): MapUtilsResponse {
        options.id = options.id || "radius-ring";

        // Add data source for radius ring if it does not exist.
        if (!map.getSource(options.id)) {
            map.addSource(options.id, {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });
        }

        // Add radius ring layer if it does not exist.
        if (!map.getLayer(options.id)) {
            map.addLayer({
                id: options.id,
                type: "line",
                source: options.id,
                layout: {},
                paint: {
                    "line-color": "#0077bc",
                    "line-width": 4
                }
            } as Layer);
        }

        // 360 straight lines make up this circle.
        const polygonCircle = circle(options.center, options.radiusInMiles, { steps: 360, units: "miles" });
        // bbox.default returns all coordinates in a flat array e.g. [lat1, lat2, lng1, lng2].
        // Chunk to convert to [[lat1, lat2], [lng1, lng2]].
        const boundingBox: LngLatBoundsLike = chunk(bbox.default(polygonCircle), 2);
        // Update radius ring polygon.
        (map.getSource(options.id) as mapboxgl.GeoJSONSource).setData(polygonCircle);
        return { layerId: options.id, bbox: boundingBox, feature: polygonCircle };
    }

    expandBoundingBox(map: mapboxgl.Map, options: CircleOptions): MapUtilsResponse{
        options.id = options.id || "radius-ring";

        // Add data source for radius ring if it does not exist.
        if (!map.getSource(options.id)) {
            map.addSource(options.id, {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });
        }

        const polygonCircle = circle(options.center, options.radiusInMiles, { steps: 360, units: "miles" });
        const boundingBox: LngLatBoundsLike = chunk(bbox.default(polygonCircle), 2);
        (map.getSource(options.id) as mapboxgl.GeoJSONSource).setData(polygonCircle);
        return { layerId: options.id, bbox: boundingBox, feature: polygonCircle };
    }

    addPinHighlightLayer(map: mapboxgl.Map, layerId: string = "dealer-highlight", sourceId: string = "dealer-highlight-source"): MapUtilsResponse {
        map.addSource(sourceId, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: []
            }
        });
        map.addLayer({
            id: layerId,
            type: "circle",
            layout: {
                visibility: "visible"
            },
            source: sourceId,
            paint: {
                "circle-radius": 20,
                "circle-color": "white",
                "circle-opacity": 1
            } as CirclePaint
        });
        // eslint-disable-next-line object-shorthand
        return { layerId: layerId, sourceId: sourceId };
    }

    // Mapbox filter expression is an array of string which can contain inner array e.g. ["==", ["get", "prop"], 1]]
    addEmptyIconLayer(map: mapboxgl.Map, layerId: string, icon: string, filter?: any): void {
        if (typeof map.getLayer(layerId) === "undefined") {
            map.addLayer({
                id: layerId,
                type: "symbol",
                layout: {
                    "icon-allow-overlap": true,
                    "icon-image": icon
                },
                source: ({
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: []
                    }
                } as any)
            });
            if (filter) {
                map.setFilter(layerId, filter);
            }
        }
    }

    removeLayer(map: mapboxgl.Map, id: string): void {
        if (!map || !map.getLayer(id)) {
            return;
        }
        map.removeLayer(id);
    }

    removeSource(map: mapboxgl.Map, id: string): void {
        if (!map || !map.getSource(id)) {
            return;
        }
        map.removeSource(id);
    }

    polygonsIntersect(source: Polygon, other: Polygon | MultiPolygon): boolean {
        if (!source || !other) {
            return false;
        }
        // Turf does not support intersect between Polygon and MultiPolygon.
        // Break each MultiPolygon into a polygon and check for at least intersection.
        if (other.type === "Polygon") {
            return !!intersect(source, other);
        } else if (other.type === "MultiPolygon") {
            return some(other.coordinates, (coords) => !!intersect(source, polygon(coords)));
        } else {
            return undefined;
        }
    }

    createDataSource(dealers: Dealer[]): GeoJSON.FeatureCollection<GeoJSONGeometry> {
        const existingLocations = {};
        const jukePosition = (latitude: number, longitude: number): { latitude: number; longitude: number } => {
            if (!(latitude in existingLocations)) {
                existingLocations[latitude] = [{ latitude, longitude }];
                return { latitude, longitude };
            }
            for (let i = 0; i < existingLocations[latitude].length; i++) {
                const e = existingLocations[latitude][i];
                if (e.longitude === longitude) {
                    longitude += (Math.random() * .0001);
                    latitude += (Math.random() * .0001);
                    return jukePosition(latitude, longitude);
                }
            }
            existingLocations[latitude].push({ latitude, longitude });
            return { latitude, longitude };
        };

        const entries = [];
        for (let i = 0; i < dealers.length; i++) {
            const d = dealers[i];
            if (d.dealer_latitude && d.dealer_longitude) { // to prevent rendering pin at null island
                const position = jukePosition(d.dealer_latitude, d.dealer_longitude);
                entries.push({
                    type: "Feature",
                    properties: {
                        dealer_id: d.dealer_id,
                        dealer_name: d.dealer_name
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [position.longitude, position.latitude]
                    }
                });
            }
        }
        return {
            type: "FeatureCollection",
            features: entries
        };
    }

    determineCoordinatesForDealers(spotlightedDealer?: Dealer, dealers?: Dealer[]): CombinedCoordinates {
        let combinedCoordinates = {
            ne: [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY],
            sw: [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY]
        };

        // zooms map to include the spotlighted dealer
        if (spotlightedDealer) {
            combinedCoordinates = this.enlargeBoundingBox(combinedCoordinates, [spotlightedDealer.dealer_longitude, spotlightedDealer.dealer_latitude]);
        }

        // fit to dealers
        if (dealers && dealers.length) {
            for (let i = 0; i < dealers.length; i++) {
                combinedCoordinates = this.enlargeBoundingBox(combinedCoordinates, [dealers[i].dealer_longitude, dealers[i].dealer_latitude]);
            }
        }

        return combinedCoordinates;
    }

    enlargeBoundingBox(cooridnateValues: CombinedCoordinates, coordinates: number[]): CombinedCoordinates {
        if (coordinates[0] && coordinates[1]) { // to prevent fitting map to null island
            if (coordinates[0] > cooridnateValues.ne[0] || cooridnateValues.ne[0] === Number.NEGATIVE_INFINITY) {
                cooridnateValues.ne[0] = coordinates[0];
            }
            if (coordinates[1] > cooridnateValues.ne[1] || cooridnateValues.ne[1] === Number.NEGATIVE_INFINITY) {
                cooridnateValues.ne[1] = coordinates[1];
            }
            if (coordinates[0] < cooridnateValues.sw[0] || cooridnateValues.sw[0] === Number.POSITIVE_INFINITY) {
                cooridnateValues.sw[0] = coordinates[0];
            }
            if (coordinates[1] < cooridnateValues.sw[1] || cooridnateValues.sw[1] === Number.POSITIVE_INFINITY) {
                cooridnateValues.sw[1] = coordinates[1];
            }
        }

        return cooridnateValues;
    };

    animatePinHighlight(map: mapboxgl.Map, animationTimer: ReturnType<typeof setInterval | typeof clearTimeout>, highlightLayerId: string): ReturnType<typeof setInterval | typeof clearTimeout> {
        if (typeof animationTimer !== "undefined") {
            clearTimeout(animationTimer);
        }

        let animationRadiusIncrease = false;
        animationTimer = setInterval(() => {
            let radius = map.getPaintProperty(highlightLayerId, "circle-radius");
            if (radius <= 18) {
                animationRadiusIncrease = true;
            } else if (radius >= 20) {
                animationRadiusIncrease = false;
            }
            radius = animationRadiusIncrease ? radius += .25 : radius -= .25;
            map.setPaintProperty(highlightLayerId, "circle-radius", radius);
        }, 100);

        return animationTimer;
    }

    reduceZipsToZones(map: mapboxgl.Map, zips: string[], styleSourceKeys: { sourceId: string; sourceLayerId: string }, color?: string): Feature<Polygon | MultiPolygon> {
        const zipPolys = zips.reduce((m, z) => {
            m[z] = true;
            return m;
        }, {});

        const zipHash = hasher(zips);

        let features;
        // get zip features from mapbox that are in the list of zips
        features = map.querySourceFeatures(
            styleSourceKeys.sourceId,
            {
                sourceLayer: styleSourceKeys.sourceLayerId,
                filter: ["has", ["to-string", ["get", "ZCTA5CE10"]], ["literal", zipPolys]]
            }
        );

        if (features.length === 0) {
            return undefined;
        }

        const uniq = {};
        //  reduce the duplicate geometries that span tiles but are same feature
        features = features.reduce((m, f) => {
            f = buffer(f, 0);
            if (!uniq[f.id]) {
                m.push(f);
                uniq[f.id] = true;
            } else {
                const featInd = m.findIndex(fe => fe.id === f.id);
                try {
                    if(m[featInd] && f){
                        const combined = union(f, m[featInd]);
                        combined.id = f.id;
                        m[featInd] = combined;
                    }
                } catch(error) {
                }
            }
            return m;
        }, []);
        let mergedPoly = features[0];
        if(features.length === 2){
            // combine all the non duplicate geometries
            mergedPoly = union(features[0], features[1]) as Feature<Polygon | MultiPolygon>;
            // const mergedPoly = union.apply(this, ...features) as Feature<Polygon | MultiPolygon>;
        }
        mergedPoly.properties.color = color;

        // remove all but the outer ring(s) as we only want a boundary
        if (mergedPoly.geometry.type === "MultiPolygon") {
            for (let i = 0; i < mergedPoly.geometry.coordinates.length; i++) {
                if (mergedPoly.geometry.coordinates[i].length > 1) {
                    mergedPoly.geometry.coordinates[i].length = 1;
                }
            }
        } else {
            mergedPoly.geometry.coordinates = <number[][][]>[mergedPoly.geometry.coordinates[0]];
        }

        mergedPoly.properties.id = zipHash;

        return mergedPoly;
    }

    getCentroids(map: mapboxgl.Map, features: Feature<AllGeoJSON>[]): Feature<GeoJSON.Point>[] {
        const centroids: Feature<GeoJSON.Point>[] = [];

        features.forEach(feature => {
            centroids.push(centroid(feature.geometry, feature.properties));
        });

        return centroids;
    }

    reduceZips(map: mapboxgl.Map, zips: string[], styleSourceKeys: { sourceId: string; sourceLayerId: string }): Feature<Polygon>[] {

        const zipPolys = zips.reduce((m, z) => {
            m[z] = true;
            return m;
        }, {});

        const zipHash = hasher(zips);

        let features;
        // get zip features from mapbox that are in the list of zips
        features = map.querySourceFeatures(
            styleSourceKeys.sourceId,
            {
                sourceLayer: styleSourceKeys.sourceLayerId,
                filter: ["has", ["to-string", ["get", "ZCTA5CE10"]], ["literal", zipPolys]]
            }
        );

        const colors = ChartColor.getColorPalette(15);

        features.forEach((feature, i) => {
            feature.properties.color = `#${colors[15 - i]}`;

        });

        if (features.length === 0) {
            return undefined;
        }



        return features;
    }
}
