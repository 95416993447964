import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DataSet } from "app/core/models/chart-data.model";
import { ChartOptions } from "app/core/models/chart-options.model";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { FilterName } from "app/core/models/filter-name.enum";
import { SalesQueryOptions } from "app/core/services/sales-data.service";
import { Subscription } from "rxjs";
import { concatMap, map, skipWhile, take } from "rxjs/operators";

import { CardUtils } from "../card/card.utils";
import { ThreeYearPivotCardComponent } from "../three-year-pivot-card/three-year-pivot-card.component";

@Component({
    selector: "dealer-zone-by-models",
    templateUrl: "./dealer-zone-by-models.component.html"
})
export class DealerZoneByModelsComponent
    extends ThreeYearPivotCardComponent
    implements OnInit, OnDestroy {
    @Input() weeklyDataSelectorEnabled = false;
    hideTwoYearsAgo: boolean;
    cardTitleBase = "Spotlight Dealer Sales by Models for Ad Zone";
    cardTitle = "Spotlight Dealer Sales by Models for Ad Zone";
    cardDisabled = true;
    cardId = "dealer-zone-by-models";
    salesDataSubscription: Subscription;
    customTicks: number[][] = [];
    useSalesData = false;
    // Chart options overrides.
    chartOptions: ChartOptions = {
        borderRadius: 0.15,
        scales: {
            xAxes: [
                {
                    gridLines: { display: true, offsetGridLines: true },
                    ticks: {
                        autoSkip: false, // stops the chart from removing labels based on chart width
                        display: true
                    }
                },
                {
                    type: "category",
                    // in order to have scale labels on the bottom of the chart,
                    // while having the original labels location on the top of the chart, a second axis is needed.
                    id: "bottom-x-axis",
                    gridLines: { display: false },
                    offset: true,
                    ticks: {
                        display: true,
                        autoSkip: false,
                        padding: 5,
                        maxRotation: 0,
                        beginAtZero: false,
                        callback: (value, idx, values) => {
                            if (idx + 1 > this.customTicks.length) {
                                return;
                            }
                            let retVal = "";
                            this.customTicks[idx].forEach((tick) => {
                                retVal += `  ${Number(tick).toFixed(2)}%  `;
                            });
                            return retVal;
                        }
                    },
                    afterBuildTicks: (chartObj) => {
                        if (
                            chartObj.hasOwnProperty("ticks") &&
                            this.customTicks[0].length > 1
                        ) {
                            chartObj.ticks = chartObj.ticks.slice(
                                0,
                                this.customTicks[0].length * 3 - 1
                            );
                            // chartObj.ticks.stepSize = this.customTicks[0].length;
                            if (chartObj.hasOwnProperty("tickValues")) {
                                this.chart.setXAxisScaleCustom(
                                    chartObj.ticks.slice(0, this.customTicks[0].length * 3 - 1)
                                );
                            }
                        }
                    }
                }
            ],
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: "Volume",
                        fontStyle: 600,
                        fontFamily: "Open Sans",
                        fontColor: "#404040"
                    }
                }
            ]
        },
        customOptions: {
            groupedBars: {
                leftOffset: 0
            }
        },
        plugins: {}
    };

    ngOnInit(): void {
        super.ngOnInit();
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
    }

    ngOnDestroy(): void {
        if (this.salesDataSubscription) {
            this.salesDataSubscription.unsubscribe();
        }
    }

    /**
     * The user changed the value of a filter, here we update the currently selected values by the user
     *
     * @param filters {string[]}    An array of filter names that were updated
     *
     * @returns void       Returns void
     */
    filtersUpdated(filters: string[]): void {
        const conditions = {
            spotlit: {
                isValid: !!this.filterStateService.getFilterValue<number>(
                    FilterName.spotlight_dealer
                ),
                text: "spotlight a dealer"
            },
            dma: {
                isValid: !this.filterStateService.isMultiDma(),
                text: "select only 1 dma"
            },
            // dateRange: {
            //     isValid: this.filterStateService.getFilterValue<string>(FilterName.dateRangeType) === "rolling"
            //         || this.filterStateService.getFilterValue<string>(FilterName.dateRangeType) === "ytd",
            //     text: "Set Years filter to Rolling 12 Month or YTD"
            // },
            zone: {
                isValid:
                    this.filterStateService.getFilterValue<string[]>(FilterName.zones)
                        .length === 1,
                text: "select exactly one Ad Zone"
            },
            include_spotlit: {
                isValid:
                    this.filterStateService.getFilterValue<string>(
                        FilterName.include_spotlight
                    ) === "include",
                text: "toggle 'Spotlight' to Include"
            }
        };
        const dateRanges: string[] = this.filterStateService.getFilterValue(
            FilterName.dateRanges
        );

        if (filters.includes(FilterName.use_sales_data.toString())) {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate : FilterName.minDate);
            this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate);
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);

            this.date = this.useSalesData ? this.maxDate.slice(0, 6) : this.maxDate;
            if (this.dateRangeType === "months") {
                if (dateRanges && dateRanges.length > 0) {
                    this.setupDateRanges();
                } else {
                    return;
                }
            }
            // this.updateTitle();
        }

        this.cardDescription = CardUtils.getRequirementDescription(conditions);
        this.cardDisabled = this.cardDescription.length > 0;
        // Add additional helper text that are not part of the card open requirements.
        const includesDateRangeType = filters.includes(
            FilterName.dateRangeType.toString()
        );
        const includesDateRange = filters.includes(
            FilterName.dateRanges.toString()
        );

        if (includesDateRange || includesDateRangeType) {

            if (this.dateRangeType === "months") {
                if (dateRanges && dateRanges.length > 0) {
                    this.updateTitle();
                    this.setupDateRanges();
                } else {
                    return;
                }
            } else {
                this.updateTitle();
            }
        }

        if (this.cardDisabled) {
            this.cardClosed();
            this.updateTitle();
        } else if (!this.cardDisabled) {
            this.updateSalesData();
            this.updateTitle();
        }
    }

    updateSalesData(): void {
        if (this.cardDisabled || !this.cardOpen) {
            return;
        }

        this.cardLoading = true;
        const queryOptions: SalesQueryOptions = this.getSalesQueryOptions();

        // sorting is accomplished by sending a object, with the key being the column to sort by, and the value being ASC or DESC.
        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            queryOptions.sort[`t${this.maxDate}_volume`] = "DESC";
        } else if (this.dateRangeType === DateRangeTypes.YEARS) {
            queryOptions.sort[`t${this.date.slice(0, 4)}_volume`] = "DESC";
        } else {
            queryOptions.sort[`t${this.date.slice()}_volume`] = "DESC";
        }

        const groupByfilterValues = this.filterStateService.getFilterValue<any[]>(
            this.groupBySelection
        );
        const filtersOverride = this.getFilterOverrides();

        // Get sales grouped by models for spotlit dealer.
        this.salesDataSubscription = this.salesDataService
            .getGroupBySalesDetails(
                queryOptions,
                groupByfilterValues,
                this.groupBySelection,
                filtersOverride
            )
            .pipe(
                skipWhile((i) => !i),
                take(1),
                concatMap((ds) =>
                    // Get sales grouped by models for the dealer's top 5 models to calculate shares.
                    this.salesDataService
                        .getGroupBySalesDetails(
                            queryOptions,
                            groupByfilterValues,
                            this.groupBySelection,
                            {
                                dealers: [],
                                [this.groupBySelection]: ds.map((d) => d.groupByValue)
                            }
                        )
                        .pipe(map((zms) => [ds, zms]))
                )
            )
            .subscribe((datasets) => {
                const dealerModelSales = datasets[0];
                const zoneModelSales = datasets[1];

                if (!dealerModelSales.length) {
                    this.noResults = true;
                    this.cardLoading = false;
                    this.cardClosed();
                    this.cardDescription = "No results for current filters.";
                    return;
                } else {
                    this.noResults = false;
                }
                // WEEKLY WORKING
                // if (this.useSalesData) {
                //     if (this.hideTwoYearsAgo) {
                //         for (let i in dealerModelSales) {
                //             const keys = Object.keys(dealerModelSales[i].sales);
                //             delete dealerModelSales[i].sales[keys[0]];
                //             if (this.dateRangeType === DateRangeTypes.ROLLING12) {
                //                 delete dealerModelSales[i].sales[keys[1]];
                //             }
                //         }
                //     } else {
                //         for (let i in dealerModelSales) {
                //             const keys = Object.keys(dealerModelSales[i].sales);
                //             dealerModelSales[i].sales[keys[0]] = 0;
                //             if (this.dateRangeType === DateRangeTypes.ROLLING12) {
                //                 dealerModelSales[i].sales[keys[1]] = 0;
                //             }
                //         }
                //     }
                // }

                this.adjustBarSpacing(dealerModelSales.length);
                this.chartData = [];
                this.labelsRef = [];
                let dateKeys;
                let currentDateKey;

                // Get volume keys by date type
                switch (this.dateRangeType) {
                    // if custom groups merge data and parse
                    // case DateRangeTypes.YTD:
                    //     const asdf = DateUtils.ytdMonthRange(this.date);
                    //     this.groupSize = asdf.length;
                    //     dateKeys = this.groupDates(Object.keys(dealerModelSales[0].sales));
                    //     break;
                    case DateRangeTypes.MONTHS: {
                        // get begin date group size
                        // if (this.groupSize) {
                        dateKeys = this.groupDates(Object.keys(dealerModelSales[0].sales));
                        dateKeys = Object.values(dateKeys).sort((a, b) => b[0] - a[0]);
                        // }
                        break;
                    }
                    case DateRangeTypes.YEARS: {
                        dateKeys = Object.keys(dealerModelSales[0].sales);
                        break;
                    }
                    default:
                        // Volume keys by date ASC.
                        dateKeys = Object.keys(dealerModelSales[0].sales).sort(
                            (a: string, b: string) => parseInt(b, 10) - parseInt(a, 10)
                        );
                        currentDateKey = dateKeys[dateKeys.length - 1];
                }
                const groupByMapZoneTotals = {};
                // Create a map to more quickly access sales values.

                const groupByMap = dealerModelSales.reduce((output, item) => {
                    output[item.groupByValue] = item.sales;

                    // Create an object representing shares for each date.
                    const zoneTotals = {};

                    for (const k in item.sales) {
                        zoneTotals[k] = zoneModelSales.find(
                            (sale) => sale.groupById === item.groupByValue
                        ).sales[k];
                    }
                    groupByMapZoneTotals[item.groupByValue] = zoneTotals;

                    // Add groupBy label to chart labels reference array.
                    this.labelsRef.push(item.groupByValue as string);
                    return output;
                }, {});

                this.labelsRef.sort((a, b) => (groupByMap[b][currentDateKey] / groupByMapZoneTotals[b][currentDateKey]) - (groupByMap[a][currentDateKey] / groupByMapZoneTotals[a][currentDateKey]));

                dateKeys.forEach((dateKey) => {
                    const pivotDateData = {
                        id: dateKey,
                        label: this.formatDate(dateKey),
                        data: [],
                        shares: []
                    } as DataSet;

                    // Use the sorted labels to retrieve sales in order to populate chart data.
                    this.labelsRef.forEach((label) => {
                        let data = 0;
                        let zoneTotal = 0;
                        if (Array.isArray(dateKey)) {
                            dateKey.forEach((date) => {
                                data = data + groupByMap[label][date] || 0;
                                zoneTotal = zoneTotal + groupByMapZoneTotals[label][date];
                            });
                        } else {
                            data = groupByMap[label][dateKey] || 0;
                            zoneTotal = groupByMapZoneTotals[label][dateKey] || 0;
                        }

                        pivotDateData.data.push(data || 0);
                        const decimal = data / zoneTotal;
                        pivotDateData.shares.push(isNaN(decimal) ? 0 : decimal * 100 || 0);
                    });

                    this.chartData.push(pivotDateData);
                });
                this.customTicks = [];
                // WEEKLY WORKING
                // if (this.useSalesData) {
                //     if (this.hideTwoYearsAgo) {
                //         for (let i = 0; i <= this.chartData[0].shares.length - 1; i++) {
                //             this.customTicks.push([this.chartData[0].shares[i], this.chartData[1].shares[i]]);
                //         }
                //     } else {
                //         for (let i = 0; i <= this.chartData[0].shares.length - 1; i++) {
                //             this.customTicks.push([this.chartData[1].shares[i], this.chartData[2].shares[i]]);
                //         }
                //     }
                // } else {
                for (let i = 0; i <= this.chartData[0].shares.length - 1; i++) {
                    this.customTicks.push([this.chartData[0].shares[i], this.chartData[1].shares[i], this.chartData[2].shares[i]]);
                }
                // }

                setTimeout(this.updateChartSize.bind(this), 0); // allows the chart canvas to start to render to enable formating the label to the correct size
                this.cardLoading = false;
            });
    }

    getFilterOverrides(): { [key: string]: any } {
        return {
            dealers: [
                this.filterStateService.getFilterValue<number>(
                    FilterName.spotlight_dealer
                )
            ]
        };
    }

    setHideTwoYearsAgo(value: boolean) {
        this.hideTwoYearsAgo = value;
    }
}
