<div (click)="click()" data-cy="reset" class="filter-reset-button noselect" [ngClass]="{canReset:canReset}">
    <div class="details">
        Applied Filters
    </div>

    <div class="sub-details">
        Clear All
        <span class="reset-icon"></span>
    </div>
</div>
