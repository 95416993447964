<top-zips-header-table [showVolume]="showVolume" [limit]="limit" [zipZoneSales]="zipZoneSales"
    [isMultiDma]="isMultiDma" [showTop25Zips]="showTop25Zips"></top-zips-header-table>

<section class="sales-area-zips">
    <div class="sales-area-tables">
        <div class="table-wrapper">
            <sales-zip-table id="primary-sales-zips"
                title="Primary Sales Area ({{primarySalesByZip.length}} of {{primarySalesCount}})"
                [salesByZip]="primarySalesByZip" [showVolume]="showVolume" [totalVolume]="totalVolume">
            </sales-zip-table>
        </div>

        <div class="table-wrapper">
            <sales-zip-table id="secondary-sales-zips"
                title="Secondary Sales Area ({{secondarySalesByZip.length}} of {{secondarySalesCount}})"
                [salesByZip]="secondarySalesByZip" [showVolume]="showVolume" [totalVolume]="totalVolume">
            </sales-zip-table>
        </div>
    </div>
</section>
