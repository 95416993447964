import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AdminModule } from "@at/admin/module";
import { AuthGuard, ClientCacheInterceptor, FILTER_STATE, FilterSalesRangePanelComponent, FilterSelectionPanelComponent, FilterStateService, GLOBAL_STATE, LoadingPanelComponent, NGRX_TOKEN, NgrxFilterStateService, PresentationFilterService, RequestMethodInterceptor, SpotlightSelectionPanelComponent, TokenInterceptor, TooltipComponent, UnAuthGuard, reducers } from "@at/core";
import { CoreModule } from "@at/core/module";
import { DownloadUtils } from "@at/utils";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import * as Sentry from "@sentry/browser";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";

Sentry.init({
    dsn: "https://ab5e88191fa34860867195b62057cf2d@sentry.srdata.io/3",
    environment: environment.name,
    whitelistUrls: [
        "spectrumreach.io"
    ]
});

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        CoreModule.forRoot(),
        AdminModule,
        BrowserModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            autoPause: false
        })
    ],
    entryComponents: [
        FilterSalesRangePanelComponent,
        FilterSelectionPanelComponent,
        SpotlightSelectionPanelComponent,
        LoadingPanelComponent,
        TooltipComponent
    ],
    providers: [
        AuthGuard,
        UnAuthGuard,
        DownloadUtils,
        {
            provide: GLOBAL_STATE,
            useExisting: FilterStateService
        },
        {
            provide: FILTER_STATE,
            useExisting: PresentationFilterService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientCacheInterceptor,
            multi: true
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: TimeoutInterceptor,
        //     multi: true
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: OfflineInterceptor,
        //     multi: true
        // },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ApiVersionInterceptor,
        //     multi: true
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestMethodInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
