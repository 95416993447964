import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ZipVolume } from "app/core/models/zip-volume.model";
import { ZipZoneSale, ZipZoneSales } from "app/core/models/zip-zone.model";

import { ChartColor } from "../charts/chart/chart-color.utils";

export interface ZipZoneVolumeComparison extends ZipVolume {
    shares: any;
}

@Component({
    selector: "zip-zone-table",
    templateUrl: "./zip-zone-table.component.html",
    styleUrls: ["./zip-zone-table.component.scss"]
})
export class ZipZoneTableComponent implements OnChanges {
    @Input() type: "zips" | "zones";
    @Input() topZipZones: string[] = [];
    @Input() selectedZipZones: string[] = [];
    @Input() zipZoneSales: ZipZoneSales[] = [];
    @Input() colors: string[] = [];
    @Input() showVolume = true;
    @Input() isMultiDma = false;

    topZipZoneVolumes: ZipZoneVolumeComparison[] = [];
    topZipZoneTotalVolume: number;
    topZipZoneShares: any;
    topZipZoneColors: string[] = [];
    totalVolume: number;

    ngOnChanges(changes: SimpleChanges): void {
        if ("zipZoneSales" in changes) {
            this.updateZipVolume();
        }
        if ("colors" in changes) {
            this.topZipZoneColors = ChartColor.toChartJsColorArray(changes.colors.currentValue);
        }
    }

    updateZipVolume(): void {
        const dealerZipZoneSales = this.zipZoneSales[0];
        // The selected dealer does not yet have any sales for the current year / rolling 12.
        if (!dealerZipZoneSales) {
            this.totalVolume = 0;
            this.topZipZoneTotalVolume = 0;
            this.topZipZoneShares = 0;
            this.topZipZoneVolumes = [];
            return;
        }

        this.totalVolume = dealerZipZoneSales.sales.reduce((sum, zip) => sum + zip.sales, 0);
        this.topZipZoneTotalVolume = 0;
        this.topZipZoneVolumes = [];

        for (let i = 0; i < dealerZipZoneSales.sales.length; i++) {
            const zv = dealerZipZoneSales.sales[i];
            // If part of top X zips/zones, add to total.
            if (this.topZipZoneVolumes.length < this.topZipZones.length && this.includesZone(this.topZipZones, zv)) {
                this.topZipZoneTotalVolume += zv.sales;
                // If part of selected zips/zones add to shown volumes.
                if (this.includesZone(this.selectedZipZones, zv)) {
                    this.topZipZoneVolumes.push({
                        zip: this.isMultiDma || zv.undefinedLocation ? zv.locationWithDma : zv.location,
                        volume: zv.sales,
                        shares: this.shares(zv.sales)
                    });
                }
            }
        }

        this.topZipZoneVolumes.sort((z1, z2) => z2.volume - z1.volume);
        this.topZipZoneShares = this.shares(this.topZipZoneTotalVolume);
    }

    private includesZone(zones: string[], lookup: ZipZoneSale): boolean {
        return this.isMultiDma || lookup.undefinedLocation ?
            zones.includes(lookup.locationWithDma) :
            zones.includes(lookup.location);
    }

    private shares(sales: any): any {
        return this.totalVolume === 0 ? 0 : Number(((sales / this.totalVolume) * 100).toFixed(2));
    }
}
