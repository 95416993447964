import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewContainerRef, ViewRef } from "@angular/core";
import { DomUtils } from "@at/utils";
import { Subscription } from "rxjs";
import { TooltipComponent } from "../tooltip/tooltip.component";


export type Placement = "left" | "right" | "bottom" | "top";

export type Alignment = "edge" | "center";


class ContentRef {
    constructor(public nodes: any[], public viewRef?: ViewRef, public componentRef?: ComponentRef<any>) { }
}

class Trigger {
    constructor(public open: string, public close: string) { }
}

// tooltip concept from bootstrap tooltip:
// https://github.com/ng-bootstrap/ng-bootstrap/blob/master/src/tooltip/tooltip.ts
// note that this version is quite different and doesn't use multiple different components as the original does.

@Directive({
    selector: "[dTooltip]"
})
export class TooltipDirective implements OnInit, OnDestroy, OnChanges {

    componentSubscription: Subscription;
    positionTimeout: ReturnType<typeof setTimeout>;
    showTimeout: ReturnType<typeof setTimeout>;
    hideTimeout: ReturnType<typeof setTimeout>;

    componentFactory: ComponentFactory<TooltipComponent>;
    tooltipComponent: ComponentRef<TooltipComponent>;
    contentRef: ContentRef;

    // on the element you use templates like:
    // <ng-template #tooltipTemplate>my tooltip content</ng-template>
    // <div [dTooltip]="tooltipTemplate">other content</div>
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input() dTooltip: string | TemplateRef<any>;

    // on the element, you can change triggers like:
    // <div [triggers]="'mousedown:mouseup'" ... >
    @Input() triggers = "mouseenter:mouseleave, touchstart:touchend";

    @Input() placement: Placement = "right";

    @Input() margin = 10;

    @Input() alignment: Alignment = "center";

    @Input() tooltipZIndex = 1000;

    @Input() shiftTop = 0;

    @Input() showTooltip = true;

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {
    }

    ngOnInit(): void {
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory<TooltipComponent>(TooltipComponent);
        const triggers = this.parseTriggers(this.triggers);
        triggers.forEach(trigger => {
            this.renderer.listen(this.elementRef.nativeElement, trigger.open, () => this.open());
            this.renderer.listen(this.elementRef.nativeElement, trigger.close, () => this.close());
        });
    }

    ngOnDestroy(): void {
        this.close(true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("showTooltip" in changes && !changes.showTooltip.currentValue) {
            this.close();
        }
    }

    parseTriggers(triggers: string): Trigger[] {
        const list = triggers.split(",");
        return list.map(tgr => {
            tgr = tgr.trim();
            const pair = tgr.split(":");
            return new Trigger(pair[0].trim(), pair[1] ? pair[1].trim() : pair[0].trim());
        });
    }

    open(immediate: boolean = false): void {
        if (this.showTooltip) {
            const op = () => {
                if (!this.tooltipComponent) {
                    this.createTooltipComponent();
                }
                this.positionTimeout = setTimeout(() => {
                    if (this.tooltipComponent) {
                        this.positionPopup();
                        this.tooltipComponent.instance.show();
                    }
                }, 80);
            };
            clearTimeout(this.positionTimeout);
            clearTimeout(this.hideTimeout);
            clearTimeout(this.showTimeout);
            if (immediate) {
                op();
            } else {
                this.showTimeout = setTimeout(op, 20);
            }
        }
    }

    close(immediate: boolean = false): void {
        if (this.tooltipComponent) {
            this.tooltipComponent.instance.hide();
        }
        const cl = () => {
            this.destroyTooltipComponent();
        };
        clearTimeout(this.positionTimeout);
        clearTimeout(this.hideTimeout);
        clearTimeout(this.showTimeout);
        if (immediate) {
            cl();
        } else {
            this.hideTimeout = setTimeout(cl, 500);
        }
    }

    private createTooltipComponent(): void {
        this.contentRef = this.createContentRef(this.dTooltip);
        this.tooltipComponent = this.viewContainerRef.createComponent(this.componentFactory, this.viewContainerRef.length, this.injector, this.contentRef.nodes);
        window.document.querySelector("body").appendChild(this.tooltipComponent.location.nativeElement);
        this.tooltipComponent.changeDetectorRef.detectChanges();
        this.tooltipComponent.changeDetectorRef.markForCheck();
        this.componentSubscription = this.tooltipComponent.instance.contentChanged.subscribe(this.positionPopup.bind(this));
    }

    private destroyTooltipComponent(): void {
        if (this.componentSubscription) {
            this.componentSubscription.unsubscribe();
        }
        this.componentSubscription = null;
        if (this.tooltipComponent) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.tooltipComponent.hostView));
        }
        this.tooltipComponent = null;
        if (this.contentRef && this.contentRef.viewRef) {
            this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.contentRef.viewRef));
        }
        this.contentRef = null;
    }

    private createContentRef(content: string | TemplateRef<any>, context?: any): ContentRef {
        if (!content) {
            return new ContentRef([]);
        } else if (content instanceof TemplateRef) {
            const viewRef = this.viewContainerRef.createEmbeddedView(<TemplateRef<TooltipComponent>>content, context);
            return new ContentRef([viewRef.rootNodes], viewRef);
        } else {
            return new ContentRef([[this.renderer.createText(`${content}`)]]);
        }
    }

    private positionPopup(): void {
        if (!(this.tooltipComponent && this.elementRef)) {
            return;
        }
        this.tooltipComponent.changeDetectorRef.detectChanges();
        this.tooltipComponent.changeDetectorRef.markForCheck();

        const hostElPosition = DomUtils.offset(this.elementRef.nativeElement, false);
        const tipPosition = DomUtils.offset(this.tooltipComponent.location.nativeElement, false);

        const targetElement = this.tooltipComponent.location.nativeElement;
        let topVal = 0;
        let leftVal = 0;

        let halfHostHeight = 0;
        let halfHostWidth = 0;
        let halfHeight = 0;
        let halfWidth = 0;

        if (this.alignment === "center") {
            halfHostHeight = Math.round(hostElPosition.height / 2);
            halfHostWidth = Math.round(hostElPosition.width / 2);
            halfHeight = Math.round(tipPosition.height / 2);
            halfWidth = Math.round(tipPosition.width / 2);
        }

        switch (this.placement) {
            case "right":
                topVal = hostElPosition.top + halfHostHeight - halfHeight + this.shiftTop;
                leftVal = hostElPosition.right + this.margin;
                break;
            case "top":
                topVal = hostElPosition.top - tipPosition.height - this.margin + this.shiftTop;
                leftVal = hostElPosition.left + halfHostWidth - halfWidth;
                break;
            case "bottom":
                topVal = hostElPosition.bottom + this.margin + this.shiftTop;
                leftVal = hostElPosition.left + halfHostWidth - halfWidth;
                break;
            case "left":
                topVal = hostElPosition.top + halfHostHeight - halfHeight + this.shiftTop;
                leftVal = hostElPosition.left - tipPosition.width - this.margin;
                break;
        }

        this.renderer.setStyle(targetElement, "top", `${topVal}px`);
        this.renderer.setStyle(targetElement, "left", `${leftVal}px`);
        this.renderer.setStyle(targetElement, "z-index", this.tooltipZIndex);
    }
}
