import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { CognitoService, FilterName, FilterStateService } from "@at/core";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    authenticated: boolean;
    hideNav = false;

    private cognitoServiceSubscription: Subscription;

    constructor(
        private cognitoService: CognitoService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private filterStateService: FilterStateService,
        private titleService: Title) { }

    ngOnInit(): void {
        this.cognitoServiceSubscription = this.cognitoService.authenticated.subscribe(this.authenticationUpdated.bind(this));
        this.router.events.subscribe(this.navigationEvent.bind(this));
    }

    navigationEvent(event: Event): void {
        let title = "Spectrum Reach Automotive Intelligence";
        if (event instanceof NavigationEnd) {
            (window as any).Appcues.page();
            switch (event.urlAfterRedirects) {
                case "/home":
                    title += " - Home";
                    break;
                case "/map":
                    title += " - Map";
                    break;
                case "/trends":
                    title += " - Trends";
                    break;
                case "/insights":
                    title += " - Insights";
                    break;
                case "/presentation":
                    title += " - PowerPoint";
                    break;
                default:
            }
            this.titleService.setTitle(title);
        }

        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
            this.hideNav = "hide_nav" in params;

            // Currently used to capture route navigation and presetting of filters for full screen views.
            if (this.router.url.match(/^\/(trends|insights)\/(\w|\-)+\?/)) {
                let hasQueryStringFilters = false;
                const allowedFilters = ["dma", "dealers", "not_dealers", "makes", "dates", "models", "zips", "zones",
                    "spotlight_dealer", "new_used_flag", "dateRangeType", "dateRanges", "buyer_dma_code", "show_volume", "show_ring",
                    "segments", "ihs_segments", "use_ihs_segments", "ring_radius", "display_range", "use_sales_data"];
                for (const key in params) {
                    if (allowedFilters.includes(key)) {
                        this.filterStateService.setFilterValue(FilterName[key], JSON.parse(params[key]));
                        hasQueryStringFilters = true;
                    }
                }

                // If the query string contained any filters, then reset all of the other filters that were not explicitly set.
                if (hasQueryStringFilters) {
                    this.filterStateService.resetResettableFilters(Object.keys(params));
                }
            }
        });
    }

    authenticationUpdated(signedIn: boolean): void {
        this.authenticated = signedIn;
    }

    ngOnDestroy(): void {
        this.cognitoServiceSubscription.unsubscribe();
    }
}
