import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { DomUtils } from "@at/utils";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { PresentationFilterService } from "app/core/services/presentation-filter.service";
import { debounce } from "lodash";
import { Subscription } from "rxjs";

@Component({
    selector: "filter-counter-button",
    templateUrl: "./filter-counter-button.component.html",
    styleUrls: ["./filter-counter-button.component.scss"]
})
export class FilterCounterButtonComponent implements OnInit, OnDestroy {
    @Input() label = "";
    @Input() labelPos: "left" | "right" = "right";
    @Input() disabled = false;

    @Input() filterName: FilterName;
    @Input() filterService: FilterStateService | PresentationFilterService;

    @Input() min = "0"; // this is a string so that validation can work
    @Input() max: string;

    inputFocused = false; // focus state of the text input

    counterValue = this.min;

    private filterServiceSubscription: Subscription;

    inputAreaElement: HTMLInputElement;

    constructor(private elRef: ElementRef) {
        // Debounce setting values to allow for rapid clicks to +/- without immediately triggering setValue.
        this.setValue = debounce(this.setValue.bind(this), 250);
    }

    ngOnInit(): void {
        this.filterServiceSubscription = this.filterService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.filtersUpdated([this.filterName.toString()]);
        this.inputAreaElement = this.elRef.nativeElement.querySelector(".radius-size-input-area");
    }

    ngOnDestroy(): void {
        this.filterServiceSubscription.unsubscribe();
    }

    filtersUpdated(changes: string[]): void {
        if (this.filterName && changes.includes(this.filterName.toString())) {
            this.counterValue = this.filterService.getFilterValue<number>(this.filterName).toString();
        }
    }

    inputChanged(input: string): void {
        if (this.disabled) {
            return;
        }

        const newValue = input.replace(/[^0-9]/g, ""); // remove anything other than a number
        const inputNum = parseInt(newValue, 10);
        if (inputNum >= parseInt(this.min, 10) && (!this.max || inputNum <= parseInt(this.max, 10))) {
            this.counterValue = newValue;
            this.setValue();
        } else if (input === "") { // this allows the input area to be a blank string
            this.inputAreaElement.value = newValue;
        } else {
            // This is a slight hack to display the validated text back in the input, as this.counterValue change will not update the element.
            this.inputAreaElement.value = this.counterValue;
        }
    }

    click(direction: "plus" | "minus"): void {
        if (this.disabled) {
            return;
        }

        let currentValue = parseInt(this.counterValue, 10);
        if (direction === "plus" && (!this.max || currentValue < parseInt(this.max, 10))) {
            currentValue++;
            this.counterValue = currentValue.toString();
            this.setValue();
        } else if (direction === "minus" && currentValue > parseInt(this.min, 10)) {
            currentValue--;
            this.counterValue = currentValue.toString();
            this.setValue();
        } else if (this.counterValue === "") {
            this.counterValue = this.min;
            this.setValue();
        }

        // This is a slight hack to display the validated text back in the input, as this.counterValue change will not update the element.
        this.inputAreaElement.value = this.counterValue;
    }

    setValue(): void {
        if (this.filterName && !this.disabled) {
            this.filterService.setFilterValue(this.filterName, parseInt(this.counterValue, 10));
        }
    }

    onBlur(): void {
        this.inputFocused = false;
        this.counterValue = this.filterService.getFilterValue<number>(this.filterName).toString();
    }

    onFocus(): void {
        this.inputFocused = true;
    }

    @HostListener("document:keydown", ["$event"])
    processGlobalHotkeys(event: KeyboardEvent): void {
        if (event["_stopPropagation"] || !this.inputFocused || this.disabled) {
            return;
        } else if (event.key === "Escape" || event.code === "27" || event.key === "Enter" || event.code === "13") {
            this.inputAreaElement.blur();
            DomUtils.stopEventPropagation(event);
        } else if (event.key === "ArrowDown" || event.code === "40" || event.key === "ArrowLeft" || event.code === "37") {
            this.click("minus");
            DomUtils.stopEventPropagation(event);
        } else if (event.key === "ArrowUp" || event.code === "38" || event.key === "ArrowRight" || event.code === "39") {
            this.click("plus");
            DomUtils.stopEventPropagation(event);
        }
    }
}
