import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FeatureFlagService } from "@at/core";
import { DateUtils, DownloadUtils } from "@at/utils";
import { DropDownItem } from "app/core/components/dropdown/dropdown.component";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { ZipZoneSale, ZipZoneSales } from "app/core/models/zip-zone.model";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetricsService } from "app/core/services/metrics.service";
import { HeatmapScaleLevels, SalesDataService } from "app/core/services/sales-data.service";
import { TopZipsService } from "app/core/services/top-zips.service";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { MetadataService } from "../../../services/metadata.service";
import { MiniMapComponent } from "../../mini-map/mini-map.component";
import { CardUtils } from "../card/card.utils";

@Component({
    selector: "primary-and-secondary-sales-by-zip-card",
    templateUrl: "./primary-and-secondary-sales-by-zip-card.component.html",
    styleUrls: ["./primary-and-secondary-sales-by-zip-card.component.scss"]
})
export class PrimaryAndSecondarySalesByZipCardComponent implements OnInit, OnDestroy {
    @ViewChild(MiniMapComponent) miniMapComponent: MiniMapComponent;

    showVolume = false;
    animateMap = false;

    isDisabled = true;
    isExpanded = false;
    isLoading = true;
    noResults = false;

    spotlitDealer: Dealer = null;
    dealers: Dealer[] = [];
    makes: string[];
    models: string[];
    segments: string[];
    dateRangeType = "calendar";
    displayDates = "Loading...";
    endDate: string;
    endSalesDate: string;
    minDate;
    maxDate;
    cardTitle = "Spotlight Dealer Sales & Share by Top ZIP Codes:";
    description = "";

    dropdown: DropDownItem[] = [
        { label: "All", value: null },
        { label: "10", value: 10 },
        { label: "15", value: 15 },
        { label: "20", value: 20 },
        { label: "25", value: 25 },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "75", value: 75 },
        { label: "100", value: 100 },
        { label: "125", value: 125 },
        { label: "150", value: 150 },
        { label: "175", value: 175 },
        { label: "200", value: 200 }];
    defaultDropDown: DropDownItem;
    selectedDropDown: DropDownItem;

    salesByZip: ZipZoneSale[] = [];
    scaledZips: string[][] = [];
    scaledZipLevels: HeatmapScaleLevels[];
    isMultiDMA: boolean = this.filterStateService.isMultiDma();

    canViewUpdatedSalesAndSharesByTopZip: boolean;

    protected spotlightDealerDetailsSubscription: Subscription;
    protected filterStateSubscription: Subscription;
    ringRadius = 0;

    constructor(
        private featureFlagService: FeatureFlagService,
        protected salesDataService: SalesDataService,
        protected dealerDataService: DealerDataService,
        private metadataService: MetadataService,
        private metricsService: MetricsService,
        protected filterStateService: FilterStateService,
        protected downloadUtils: DownloadUtils = new DownloadUtils(),
        private topZipsService: TopZipsService
    ) {
        this.defaultDropDown = this.dropdown[0];
        this.selectedDropDown = this.defaultDropDown;
        this.canViewTopZips();
    }

    ngOnInit(): void {
        if(this.canViewUpdatedSalesAndSharesByTopZip){
            this.cardTitle = "Sales & Share by Top ZIP Codes:";
        }

        if (this.filterStateService.getFilterValue<boolean>(FilterName.show_ring)) {
            this.ringRadius = this.filterStateService.getFilterValue(FilterName.ring_radius);
        }

        this.metadataService.metadata.pipe(skipWhile(i => !(i && i.maxDate && i.maxSalesDate)), take(1)).subscribe(meta => {
            this.endDate = meta.maxDate;
            this.endSalesDate = meta.maxSalesDate;
            this.updateDisplayDate();

            this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
            this.filtersUpdated([FilterName.dateRangeType.toString(), FilterName.show_volume.toString()]); // this is to initialize the dateRangeType & showVolume variables
            const dateRange = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);

            if (this.dateRangeType === DateRangeTypes.MONTHS && dateRange && dateRange.length >= 2) {
                this.minDate = dateRange[0];
                this.maxDate = dateRange[1];
            }

            this.makes = this.filterStateService.getFilterValue<string[]>(FilterName.makes);
            this.models = this.filterStateService.getFilterValue<string[]>(FilterName.models);
            this.segments = this.filterStateService.getFilterValue<string[]>(FilterName.segments);

            this.spotlightDealerDetailsSubscription = this.dealerDataService.spotlightDealerDetails.subscribe((dealer: Dealer) => {
                this.spotlitDealer = dealer;

                this.checkIfValid();
            });
        });
    }

    async canViewTopZips(): Promise<void> {
        this.canViewUpdatedSalesAndSharesByTopZip = await this.featureFlagService.viewSalesAndSharesByTopZip();
    }

    filtersUpdated(changes: string[]): void {
        // Changing dealers, zips, or zones filters does not affect this card.
        // This is to isolate the filters update only when they are change manually
        // e.g. by hand from the filter stack. In comparison to a bulk update programmatically.
        if (changes.length === 1 &&
            (changes[0] === FilterName.dealers.toString() ||
                changes[0] === FilterName.zips.toString() ||
                changes[0] === FilterName.zones.toString())) {
            return;
        }

        if (changes.includes(FilterName.makes.toString()) || changes.includes(FilterName.models.toString()) || changes.includes(FilterName.segments.toString())) {
            this.makes = this.filterStateService.getFilterValue<string[]>(FilterName.makes);
            this.models = this.filterStateService.getFilterValue<string[]>(FilterName.models);
            this.segments = this.filterStateService.getFilterValue<string[]>(FilterName.segments);

            this.checkIfValid();
        }

        let conditions;

        if(this.canViewUpdatedSalesAndSharesByTopZip){
            conditions = {
                disabled: {
                    isValid: !this.isDisabled,
                    text: "Spotlight a dealer or select at least one Segment, Make, or Model"
                },
                enabled_no_spotlight: {
                    isValid: !(!this.isDisabled && !this.spotlitDealer),
                    text: "Spotlight a dealer to view. To focus on a Segment, Make, or Model, modify the filter panel to the left."
                },
                enabled_spotlight: {
                    isValid: !(!this.isDisabled && this.spotlitDealer),
                    text: "To focus on a Segment, Make, or Model, modify the filter panel to the left."
                }
            };
        } else {
            conditions = {
                spotlight: {
                    isValid: !!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer),
                    text: "spotlight a dealer"
                },
                include_spotlight: {
                    isValid: this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) === "include",
                    text: "toggle 'Spotlight' to Include"
                }
            };
        }

        this.description = CardUtils.getRequirementDescription(conditions);

        if (changes.includes(FilterName.dma.toString())) {
            this.isMultiDMA = this.filterStateService.isMultiDma();
        }

        if (changes.includes(FilterName.show_volume.toString())) {
            this.showVolume = this.filterStateService.getFilterValue<boolean>(FilterName.show_volume);
        }
        if (changes.includes(FilterName.show_ring.toString()) || changes.includes(FilterName.ring_radius.toString())) {
            this.ringRadius = this.filterStateService.getFilterValue(FilterName.ring_radius);
        }

        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        if (
            changes.includes(FilterName.dateRangeType.toString()) ||
            changes.includes(FilterName.dateRanges.toString())
        ) {
            this.dateRangeType = this.filterStateService.getFilterValue<string>(FilterName.dateRangeType);
            if (
                dateRanges &&
                dateRanges.length >= 2 &&
                this.dateRangeType === "months"
            ) {
                this.updateDisplayDate();
            } else if (this.dateRangeType !== "months" && this.endDate) {
                this.updateDisplayDate();
            }
        }

        if (changes.includes(FilterName.new_used_flag.toString()) || changes.includes(FilterName.buyer_dma_code.toString())) {
            this.updateZipVolume();
        }
    }

    ngOnDestroy(): void {
        if (this.spotlightDealerDetailsSubscription) {
            this.spotlightDealerDetailsSubscription.unsubscribe();
        }
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
    }

    checkIfValid(): void {
        if(this.canViewUpdatedSalesAndSharesByTopZip){
            if (!this.spotlitDealer && (!this.makes || this.makes.length === 0) && (!this.models || this.models.length === 0) && (!this.segments || this.segments.length === 0)) {
                this.isExpanded = false;
                this.isDisabled = true;
            } else {
                this.isDisabled = false;
                this.cardClosed();
            }
        } else {
            if (!this.spotlitDealer) {
                this.isExpanded = false;
                this.isDisabled = true;
            } else {
                this.isDisabled = false;
                this.updateZipVolume();
            }
        }
    }

    cardOpened(): void {
        this.isExpanded = true;
        this.selectedDropDown = this.dropdown[0];
        this.loadDealers();
        this.updateZipVolume();
    }

    loadDealers(): void {
        if (this.spotlitDealer) {
            this.dealers = [];
        } else {
            this.dealers = this.dealerDataService.dealerDetails.getValue();
        }
    }

    cardClosed(): void {
        this.isExpanded = false;
        this.selectedDropDown = this.dropdown[0];
    }

    resizeMap(): void {
        if (this.miniMapComponent) {
            this.miniMapComponent.resizeMap();
        }
    }

    updateDisplayDate(): void {
        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        const useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);

        if (dateRanges && dateRanges.length >= 2 && this.filterStateService.getFilterValue(FilterName.dateRangeType) === "months") {
            this.displayDates = `${DateUtils.dateDisplay([dateRanges[1], dateRanges[0]], "months")}`;
        } else {
            this.displayDates = `${DateUtils.displayDateRange(useSalesData ? this.endSalesDate : this.endDate, this.dateRangeType, 1)}`;
        }
        this.updateZipVolume();
    }

    exportZipCodes(): void {
        const startTime = Date.now();
        let csvContent = "Zip Code,Sales,Sale %,Sales Area\n";
        const salesArea = area => {
            switch (area) {
                case "psa":
                    return "Primary";
                case "ssa":
                    return "Secondary";
                default:
                    return "";
            }
        };

        // remove zips that aren't PSA or SSA and limit to just those displayed based on the dropdown
        const zips = this.salesByZip.filter(sale => sale.area !== null).slice(0, this.selectedDropDown.value);
        const total: number = this.salesByZip.reduce((sum, zip) => sum + zip.sales, 0);

        zips.forEach(sale => {
            const row: string = sale.location + "," + sale.sales + "," + ((sale.sales / total) * 100).toFixed(2) + "," + salesArea(sale.area);
            csvContent += row + "\n";
        });

        this.downloadUtils.downloadToBrowser(csvContent, "ZipSales.csv");
        this.metricsService.sendMetric({
            metricName: "excel_duration",
            metricValue: (Date.now() - startTime) / 1000.0,
            unit: "Seconds",
            dimensions: [
                {
                    Name: "Excel Name",
                    Value: "Export Zip Code CSV"
                }
            ]
        });
    }

    async updateZipVolume(): Promise<void> {
        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        const dateParams = {};
        if (dateRanges && this.dateRangeType === DateRangeTypes.MONTHS && dateRanges.length >= 2) {
            this.minDate = dateRanges[0];
            this.maxDate = dateRanges[1];
            dateParams["dateRangeStart"] = this.minDate;
            dateParams["dateRangeEnd"] = this.maxDate;
        }

        if(this.spotlitDealer){
            this.salesDataService.getZipZoneForDealersSales([this.spotlitDealer.dealer_id], "zips", [], {
                zips: [],
                zones: [],
                dateRangeType: this.dateRangeType,
                ...dateParams
            }).then(sales => this.processSales(sales));
        } else {
            this.salesDataService.getZipZoneSales("zips", [], {
                zips: [],
                zones: [],
                dateRangeType: this.dateRangeType,
                ...dateParams
            }).then(sales => this.processSales(sales));
        }
    }

    dropdownChanged(selected: DropDownItem): void {
        this.selectedDropDown = selected;
        this.loadDealers();
        this.updateZipVolume();
    }

    private processSales(sales: ZipZoneSales[] | ZipZoneSale[]): void {
        if (sales.length > 0) {
            this.hasData(sales);
        } else {
            this.noData();
        }
    }

    private hasData(sales: ZipZoneSales[] | ZipZoneSale[]): void {
        this.noResults = false;
        if((sales as ZipZoneSales[])[0].dealer_id){
            this.setAllValueForDropDown((sales[0] as ZipZoneSales).sales);
            this.processZips((sales[0] as ZipZoneSales).sales);
        } else {
            this.setAllValueForDropDown(sales as ZipZoneSale[]);
            this.processZips(sales as ZipZoneSale[]);
        }
        this.isLoading = false;
        this.resizeMap();
    }

    private setAllValueForDropDown(zipZoneSales: ZipZoneSale[]): void {
        const item = this.dropdown.find(ddi => ddi.label === "All");
        item.value = zipZoneSales.length;
    }

    private noData(): void {
        this.salesByZip = [];
        this.noResults = true;
        this.isLoading = false;
    }

    private processZips(dealerSales: ZipZoneSale[]): void {
        const sales = dealerSales;
        this.salesByZip = sales.sort((a, b) => b.sales - a.sales);
        const scalings = this.topZipsService.getScaledZips(sales);
        this.scaledZips = scalings.scaledZips;
        this.scaledZipLevels = scalings.scaledZipLevels;
    }
}
