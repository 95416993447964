import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { FeatureCollection } from "@turf/turf";
import { debounce, flatten } from "lodash";
import { GeoJSONGeometry, LngLatBoundsLike } from "mapbox-gl";
import { MapUtilsResponse } from "../map/map-utils";
import { MiniMapComponent } from "../mini-map/mini-map.component";
import { MetricsService } from "app/core/services/metrics.service";

@Component({
    selector: "custom-zip-map",
    templateUrl: "./custom-zip-map.component.html",
    styleUrls: ["./custom-zip-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomZipMapComponent extends MiniMapComponent implements OnDestroy, OnChanges, AfterViewInit {
    @Input() extendMapLeft = false;
    @Input() extendMapRight = false;
    @Input() leftPanelOpen = true;
    @Input() rightPanelOpen = true;
    radiusRingFeature: MapUtilsResponse;
    zoomBoundingBox: LngLatBoundsLike;
    requireLayersForDealer = true;

    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected elementRef: ElementRef,
        protected metricsService: MetricsService
    ) {
        super(changeDetectorRef, metricsService, elementRef);
    }

    initializeMap(): void {
        // Selected zipcodes do not exist in the minimap since they are not interactive
        this.map.setFilter("zipcodes-select", ["==", this.zipCodeLayerKey, ""]);
        this.map.setFilter("zipcodes-select-border", ["==", this.zipCodeLayerKey, ""]);

        const saveStyleSourceId = (sourceData) => {
            if (sourceData.isSourceLoaded && sourceData.source.type === "vector") {
                this.map.off("sourcedata", saveStyleSourceId);
                this.styleSourceKeys = {
                    sourceId: sourceData.sourceId,
                    sourceLayerId: sourceData.style.stylesheet.layers.find(l => l.id.includes("zipcodes-heatmap"))["source-layer"]
                };
            }
        };
        this.map.on("sourcedata", saveStyleSourceId);

        this.loadPins();

        // Add source for radius highlight circles.
        this.mapUtils.addPinHighlightLayer(this.map, this.highlightLayerId, this.highlightSourceId);

        if (this.dealers) {
            if (this.dealers.length) {
                this.addLayersForDealers();
            } else {
                this.requireLayersForDealer = true;
            }
        }

        this.mapUtils.addEmptyIconLayer(this.map, this.spotlightedDealerLayerId, "dealerPinSpotlightIcon", ["==", "dealer_id", ""]);
        this.addZoneBorderLayer(this.map);

        this.mapInitialized = true;
        if (this.dealers) {
            if (this.dealers.length) {
                this.updateRenderedPins();
                this.updatePinLayerFilters();
                this.updateRenderedZips();
            }
        }

        this.map.on("moveend", (data) => {
            this.updateZoneFeatures();
        });
        this.map.on("zoomend", (data) => {
            this.updateZoneFeatures();
        });
        this.map.once("idle", () => {
            this.updateZoneFeatures();
            debounce(this.fitMapToZips.bind(this), 25)();
        }
        );
    }

    ngAfterViewInit(): void {
        this.mapConfig.container = this.elementRef.nativeElement.querySelector(".minimap");
        this.map = new mapboxgl.Map(this.mapConfig).addControl(new mapboxgl.AttributionControl({ compact: true }));
        this.map.once("load", this.initializeMap.bind(this));
        this.ringText = this.radiusRingSize ? `  Showing a ${this.radiusRingSize} mile ring around the primary dealer.` : "";
    }

    ngOnChanges(changes: SimpleChanges): void {

        let inputs = ["spotlightedDealer", "renderedZips", "heatmapScales", "radiusRingSize", "dealers"];

        inputs.forEach((inputName) => {
            if (this.mapInitialized && changes.hasOwnProperty(inputName) && typeof changes[inputName].currentValue !== "undefined" && changes[inputName].currentValue.length > 0) {
                this[inputName] = changes[inputName].currentValue;
                if (inputName === "dealers" || inputName === "spotlightedDealer") {
                    this.requireLayersForDealer = true;
                    this.updateRenderedPins();
                    this.updatePinLayerFilters();
                }
                this.updateRenderedZips();
                this.ringText = this.radiusRingSize ? `  Showing a ${this.radiusRingSize} mile ring around the primary dealer.` : "";

            }
        });
    }

    public updateHeatmapZips(): void {
        const zipcodesHeatmapLayers = [];

        for (let i = this.renderedZips.length - 1; i > 0; i--) {
            const zips = this.renderedZips[i];
            const filter = ["in", this.zipCodeLayerKey, ...zips];

            if (typeof this.map.getLayer("zipcodes-heatmap-" + i) !== "undefined") {
                this.map.setFilter("zipcodes-heatmap-" + i, filter);
                zipcodesHeatmapLayers.push("zipcodes-heatmap-" + i);
            }
        }
        if (this.zipzoneflag === "zip") {
            this.map.setFilter("zipcodes-names", ["in", this.zipCodeLayerKey].concat(flatten(this.renderedZips)));
            this.toggleLayerVisibility(["zipcodes-names"], "visible");
        }
        this.toggleLayerVisibility(zipcodesHeatmapLayers, "visible");
    }

    updatePinLayerFilters(): void {
        const features = [];

        if (this.requireLayersForDealer && this.dealers.length) {
            this.addLayersForDealers();
            this.map.moveLayer(this.spotlightedDealerLayerId);
            this.requireLayersForDealer = false;
        }

        if (typeof this.dealers !== "undefined" && this.dealers.length >= 1) {
            const filteredDealers = this.dealers.filter(dealer => dealer.dealer_id !== this.spotlightedDealer.dealer_id);

            filteredDealers.forEach((d, i) => {
                const dealerLayerId = this.getDealerLayerId(d.dealer_id);
                const data = this.mapUtils.createDataSource([d]);
                features.push.apply(features, data.features);
                (this.map.getSource(dealerLayerId) as mapboxgl.GeoJSONSource).setData(data);
                this.map.setFilter(dealerLayerId, ["==", "dealer_id", d.dealer_id]);

            });
        }

        let spotlightPinFilter = 0;
        if (this.spotlightedDealer) {
            spotlightPinFilter = this.spotlightedDealer.dealer_id;
            const spotlightData = this.mapUtils.createDataSource([this.spotlightedDealer]);
            features.push.apply(features, spotlightData.features);
            (this.map.getSource("dealerPinsSpotlight") as mapboxgl.GeoJSONSource).setData(spotlightData);
            this.map.setCenter([this.spotlightedDealer.dealer_longitude, this.spotlightedDealer.dealer_latitude]);
            this.map.setZoom(5);
            this.resizeMap();
        }

        // Add radius ring, if the "radiusRingSize" is greater than 0
        if (this.spotlightedDealer && this.radiusRingSize) {
            this.mapUtils.removeLayer(this.map, "radius-ring"); // remove ring layer
            this.mapUtils.removeSource(this.map, "radius-ring"); // remove ring Source
            const options = {
                center: [this.spotlightedDealer.dealer_longitude, this.spotlightedDealer.dealer_latitude],
                radiusInMiles: this.radiusRingSize
            };
            this.radiusRingBoundingBox = this.mapUtils.addRadiusRingLayer(this.map, options).bbox; // add new ring
        }

        // Highlight dealer and animate if they exist.
        if (features.length) {
            const fc = { type: "FeatureCollection", features } as FeatureCollection<GeoJSONGeometry>;
            if (this.useNumberPins) {
                fc.features = [fc.features.find(feature => feature.properties.dealer_id === this.spotlightedDealer.dealer_id)];
            }
            (this.map.getSource(this.highlightSourceId) as mapboxgl.GeoJSONSource).setData(fc);
            if (this.animateHighlight) {
                this.animationTimer = this.mapUtils.animatePinHighlight(this.map, this.animationTimer, this.highlightLayerId);
            }
        }

        this.map.setFilter("dealerPinsSpotlight", ["==", "dealer_id", spotlightPinFilter]);
        this.changeDetectorRef.detectChanges();
    }

    zoomMapToFeatures(): void {
        let updateZoom = false;
        const ne = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY];
        const sw = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];

        // Helper to enlargen bounding box for zooming.
        const enlargeBoundingBox = (coordinates: number[]): void => {
            if (coordinates[0] && coordinates[1]) {
                // to prevent fitting map to null island
                if (coordinates[0] > ne[0] || ne[0] === Number.NEGATIVE_INFINITY) {
                    ne[0] = coordinates[0];
                }
                if (coordinates[1] > ne[1] || ne[1] === Number.NEGATIVE_INFINITY) {
                    ne[1] = coordinates[1];
                }
                if (coordinates[0] < sw[0] || sw[0] === Number.POSITIVE_INFINITY) {
                    sw[0] = coordinates[0];
                }
                if (coordinates[1] < sw[1] || sw[1] === Number.POSITIVE_INFINITY) {
                    sw[1] = coordinates[1];
                }
            }
        };

        // zooms map to include the spotlighted dealer
        if (this.spotlightedDealer) {
            enlargeBoundingBox([
                this.spotlightedDealer.dealer_longitude,
                this.spotlightedDealer.dealer_latitude,
            ]);
            updateZoom = true;
        }

        // fit to dealers
        if (this.dealers && this.dealers.length) {
            for (let i = 0; i < this.dealers.length; i++) {
                enlargeBoundingBox([
                    this.dealers[i].dealer_longitude,
                    this.dealers[i].dealer_latitude,
                ]);
            }
            updateZoom = true;
        }

        // fit to radius ring
        if (this.radiusRingFeature && this.radiusRingFeature.bbox) {
            // ne & sw corners of radius ring bounding box
            enlargeBoundingBox(this.radiusRingFeature.bbox[0]);
            enlargeBoundingBox(this.radiusRingFeature.bbox[1]);
            updateZoom = true;
        }

        if (updateZoom) {
            this.map.resize();
            this.map.setPitch(0);
            this.zoomBoundingBox = [sw, ne];
            this.map.fitBounds(this.zoomBoundingBox, { maxZoom: 9, padding: 60 });
        }
    }

}
