<div class="content-body">
    <div class="messaging" [class.success]="toast?.status === 'success'" [class.error]="toast?.status === 'error'">
        <div class="icon"></div>
        <div class="message">{{toast?.message}}</div>
        <div class="close" (click)="closeMessage()"></div>
    </div>
    <div class="container">
        <div class="header">Manage Users</div>
        <div class="tabs">
            <div class="divider"></div>
            <div
                *ngFor="let tab of tabsList"
                class="tab"
                [class.active]="tab.active"
                [attr.admin-tab-name]="tab.id"
                (click)="switchTab(tab)"
            >
                <div class="top"></div>
                <div class="label">{{tab.label}}</div>
            </div>
        </div>

        <ng-container [ngSwitch]="activeTab.view">
            <div *ngSwitchCase="TABS.ADD" id="tab-add-body">
                <div class="title" [class.invalid]="isInvalid">
                    <span>Users Email Address</span>
                    <span class="required">* Required</span>
                </div>
                <textarea id="admin-add-user-textarea" [(ngModel)]="usersInput" [class.invalid]="isInvalid"></textarea>
                <div class="hint" [class.invalid]="isInvalid">Please add one user per line</div>
                <div id="admin-add-user-submit" class="button" [class.disabled]="usersInput.length === 0" (click)="submit()">Add User(s)</div>
            </div>

            <div *ngSwitchCase="TABS.EDIT" id="tab-edit-body">
                <search
                    [searchTerm]="searchTerm"
                    [placeholder]="'Search by email'"
                    [allowedCharacterRegex]="allowedRegex"
                    (searchUpdated)="updateSearch($event)"
                ></search>
                <ngx-datatable
                    class="manage-user"
                    [columnMode]="'flex'"
                    [columns]="editColumns"
                    [rows]="editRows"
                    [scrollbarV]="true"
                    [loadingIndicator]="loading"
                    [headerHeight]="40"
                    [rowHeight]="30"
                    [sorts]="defaultSort"
                    (scroll)="scroll($event)"
                ></ngx-datatable>
            </div>
        </ng-container>
    </div>
</div>
<overlay class="full-screen fog"></overlay>

<ng-template #hdrTmpl let-column="column" let-sort="sortFn">
    <div class="datatable-custom-header" (click)="sort()" (click)="sortClass(column.prop)" [attr.dt-column-name]="column.prop">
        <div class="datatable-custom-header-label">{{column.name}}</div>
        <div class="sort-icons"
            [class.sort-asc]="column.prop === sortKey && sortDir === 'asc'"
            [class.sort-desc]="column.prop === sortKey && sortDir === 'desc'"
        >
            <div class="sort-asc-icon"></div>
            <div class="sort-desc-icon"></div>
        </div>
    </div>
</ng-template>

<ng-template #rowNumberTmpl let-row="row" let-rowIndex="rowIndex">
    <span [attr.dt-row-index]="rowIndex">{{rowIndex + 1}}</span>
</ng-template>

<ng-template #rowActionsTmpl let-row="row" let-column="column" let-value="value" let-rowIndex="rowIndex">
    <div class="action-cell" [attr.dt-row-index]="rowIndex" [attr.dt-prop-name]="column.prop" [attr.dt-user]="value.user?.email">
        <div *ngIf="!!value" class="action-arrow" (click)="toggleActionMenu($event, value)"></div>
    </div>
</ng-template>

<ng-template #rowDateTmpl let-row="row" let-column="column" let-value="value" let-rowIndex="rowIndex">
    <span [attr.dt-row-index]="rowIndex" [attr.dt-prop-name]="column.prop">{{value?.toLocaleDateString()}}</span>
</ng-template>

<ng-template #rowGroupsTmpl let-row="row" let-column="column" let-value="value" let-rowIndex="rowIndex">
    <span [attr.dt-row-index]="rowIndex" [attr.dt-prop-name]="column.prop">{{value.join(", ")}}</span>
</ng-template>

<ng-template #rowValueTmpl let-row="row" let-column="column" let-value="value" let-rowIndex="rowIndex">
    <span [attr.dt-row-index]="rowIndex" [attr.dt-prop-name]="column.prop">{{value}}</span>
</ng-template>

<div class="action-menu" *ngIf="!!actionsMenuUser" [ngStyle]="actionsMenuLoc">
    <div
        [attr.action-menu-item]="'delete'"
        [class.disabled]="!actionsMenuUser.actions.delete"
        (click)="deleteUser(actionsMenuUser)"
    >Delete User</div>
    <div
        [attr.action-menu-item]="'change-group'"
        [class.disabled]="!actionsMenuUser.actions.modifyGroup"
        (click)="modifyUserGroups(actionsMenuUser)"
    >Change User Group</div>
    <div
        [attr.action-menu-item]="'reset'"
        (click)="resetUser(actionsMenuUser)"
    >Reset User Account</div>
</div>

<ng-template #modifyGroupTmpl let-message="message" let-items="groups">
    <div class="confirm-content">
        <div class="confirm-message">{{message}}</div>
        <div class="confirm-checkbox">
            <div *ngFor="let item of items" (click)="item.checked = !item.checked" [attr.cb-item]="item.label">
                <div class="icon-checkbox" [class.checked]="item.checked"></div> {{item.label}}
            </div>
        </div>
    </div>
</ng-template>
