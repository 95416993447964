import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { defaults, flatten, intersection, omit, uniq } from "lodash";
import * as hasher from "object-hash";
import { Observable, combineLatest, of } from "rxjs";
import { flatMap, map, mergeMap, skipWhile, take } from "rxjs/operators";

import { DealerSalesRangeFilterHelper } from "../../helpers/dealer-sales-range-filter.helper";
import { DmaSales, Sales } from "../../models/dealer-sales";
import { Dealer } from "../../models/dealers.model";
import { FilterName } from "../../models/filter-name.enum";
import { GroupBySales } from "../../models/groupby-sales.model";
import { SalesMapping } from "../../models/sales-mapping";
import { MetadataService } from "../../services/metadata.service";
import { SalesCache } from "../../services/sales-cache";
import { SalesQueryOptions } from "../../services/sales-data.service";
import { UserCookieService } from "../../services/user-cookie.service";
import { FilterBaseService } from "../services/filter-base.service";
import { HttpServiceBase } from "../services/http-service-base.service";

@Injectable()
export abstract class AbstractSalesDataBase extends HttpServiceBase {
    public salesCache = new SalesCache();
    protected lastUpdated;
    protected lastSalesUpdated;

    constructor(
        protected filterBaseService: FilterBaseService,
        protected http: HttpClient,
        protected metadataService: MetadataService,
        protected userCookieService: UserCookieService
    ) {
        super(http);
        this.metadataService.metadata
            .pipe(
                skipWhile((i) => !(i && i.maxDate && i.maxSalesDate))
            )
            .pipe(take(1)
            ).subscribe((meta) => {
                this.lastUpdated = meta.maxDate;
                this.lastSalesUpdated = meta.maxSalesDate;
            });
    }

    getTopOptions(currentFilter: string, limitResults?: number, filterOverrides: { [key: string]: any } = {}, additionalExclusions: string[] = []): Observable<any[]> {
        const filters = this.filterBaseService.getCurrentApiFilters([currentFilter, ...additionalExclusions]);

        Object.assign(filters, filterOverrides);

        filters["filterName"] = currentFilter;
        if (limitResults) {
            filters["limit"] = limitResults;
        }
        const spotlightDealer = this.filterBaseService.getFilterValue<number>(FilterName.spotlight_dealer);
        if (spotlightDealer && currentFilter === "dealers" && !filterOverrides["not_dealers"]) {
            filters["not_dealers"] = [spotlightDealer];
        }
        return DealerSalesRangeFilterHelper.handleSalesRangeFilters<any>(filters,
            () => this.fetchSalesRangeFilteredOptions(filters),
            (f) => this.fetchTopOptions(f));
    }

    private fetchSalesRangeFilteredOptions(filters: object): any {
        delete filters[FilterName.dealers.toString()];
        const salesRangeOnlyFilters = omit<any>(filters, [FilterName.makes.toString(), FilterName.segments.toString(), FilterName.models.toString(), FilterName.zips.toString(), FilterName.zones.toString()]);
        salesRangeOnlyFilters["filtername"] = FilterName.dealers.toString();
        if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
            salesRangeOnlyFilters["use_sales_data"] = "true";
        }
        return this.fetchInterfaces<Dealer>(`${this.rootUrl}/filter/names`, this.createRequestOptions(salesRangeOnlyFilters))
            .pipe(flatMap(dealers => {
                filters[FilterName.dealers.toString()] = dealers.map(dealer => dealer.dealer_id);
                delete filters[FilterName.sales_range.toString()];
                return this.fetchTopOptions(filters);
            }));
    }

    private fetchTopOptions(filters: object): any {
        const url = `${this.rootUrl}/sales/top`;
        if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
            filters["use_sales_data"] = "true";
        }

        const options = this.createRequestOptions(filters);
        return this.fetchInterfaces<(string | number)[]>(url, options);
    }

    getGroupBySalesDetails(
        salesQueryOptions: SalesQueryOptions,
        filterValues: any[] = [],
        groupBySelectionFilter: FilterName = FilterName.dealers,
        filterOverrides: { [key: string]: any } = {},
        sumInGroup: boolean = false
    ): Observable<GroupBySales[]> {
        if (groupBySelectionFilter === FilterName.dealers) {
            if (!salesQueryOptions.group) {
                salesQueryOptions.group = ["dealer", "dealer_name"];
            } else if (!salesQueryOptions.group.includes("dealer_name")) {
                salesQueryOptions.group.push("dealer_name");
            }
        } else if (groupBySelectionFilter === FilterName.zones) {
            if (!salesQueryOptions.group) {
                salesQueryOptions.group = ["ncc_name", "sys_code"];
            } else {
                if (!salesQueryOptions.group.includes("ncc_name")) {
                    salesQueryOptions.group.push("ncc_name");
                }
                if (!salesQueryOptions.group.includes("sys_code") && !salesQueryOptions.group.includes("zones")) {
                    salesQueryOptions.group.push("sys_code");
                }
            }
        } else if (groupBySelectionFilter === FilterName.zips) {
            if (!salesQueryOptions.group) {
                salesQueryOptions.group = ["buyer_zip"];
            }
        }

        return this.getSalesDetails(filterValues, salesQueryOptions, filterOverrides, groupBySelectionFilter).pipe(mergeMap((results) => {
            if (results.length <= 1) {
                return of([]);
            }
            const groupBySalesData = this.processGroupBySalesByDate(results, groupBySelectionFilter, sumInGroup);

            // Select which shares calculation to use
            if (groupBySelectionFilter === FilterName.dealers) {
                return this.processSharesByMarket(groupBySalesData, { dateRangeStart: salesQueryOptions.dateRangeStart, dateRangeEnd: salesQueryOptions.dateRangeEnd, dateRangeType: salesQueryOptions.dateRangeType });
            } else if (groupBySelectionFilter === FilterName.zones || groupBySelectionFilter === FilterName.zips) {
                return of(this.processSharesByZipZone(groupBySalesData, filterOverrides));
            } else {
                return of(groupBySalesData);
            }
        }));
    }

    /***********************************************************************************************************************************
     * getSalesDetails is a base function that returns unprocessed data from the salesVolume endpoint.                                 *
     *                                                                                                                                 *
     * @param {string[] | number[]} groupByfilterValues => filter values that will constrict the returned data, i.e. ['camry', 'crv']       *
     * @param {SalesQueryOptions} salesQueryOptions => object of options that influence the sql query, i.e. { limit: 5, offset: 7 }    *
     * @param { {[key: string]: any} } filterOverrides => object of filters to override from default filters, i.e. { dealers: [1,2,3]} *
     * @param {FilterName} groupByFilterName => the filter that will group the returned data, i.e. FilterName.models                   *
     *                                                                                                                                 *
     * @return {Observable<any[]>} => Returns a Observable of a array of unprocessed volume data                                       *
     **********************************************************************************************************************************/
    getSalesDetails(groupByfilterValues?: string[] | number[], salesQueryOptions: SalesQueryOptions = {}, filterOverrides: { [key: string]: any } = {}, groupByFilterName: FilterName = FilterName.dealers): Observable<any[]> {
        salesQueryOptions = this.createDefaultSalesQueryOptions(salesQueryOptions);
        let filters;
        // Shares number for filtered state should be calculated against the whole DMA.
        if (salesQueryOptions.filterType === "filtered" || salesQueryOptions.filterType === "exclude_dealers") { // if filtered results, then exclude the dealers by default or the groupByFilters
            filters = this.filterBaseService.getCurrentApiFilters(groupByFilterName.toString());
        } else {
            // values passed into getCurrentApiFilters are excluded filters.
            filters = this.filterBaseService.getCurrentApiFilters("dealers", "ihs_segments", "segments", "makes", "models", "zips", "zones");
        }

        if (DealerSalesRangeFilterHelper.shouldApplySalesRangeDealers(filters)) {
            filters[FilterName.dealers.toString()] = this.userCookieService.getSalesRangeFilteredDealers();
        }

        delete filters[FilterName.sales_range.toString()];

        if (!!groupByfilterValues && !!groupByfilterValues[0]) {
            filters[groupByFilterName.toString()] = groupByfilterValues.slice();
        }

        Object.assign(filters, omit<any>(salesQueryOptions, "filterType"));
        Object.assign(filters, filterOverrides);

        const url = `${this.rootUrl}/sales/volume`;
        if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
            filters["use_sales_data"] = "true";
        }

        const options = this.createRequestOptions(filters);
        const salesResponse = this.fetchInterfaces<any>(url, options).pipe(map((results) => results.map((result) => this.cleanKeys(result, SalesMapping))));
        return salesResponse;
    }

    getNationalSalesDetails(salesQueryOptions: SalesQueryOptions = {}): Observable<any[]> {
        salesQueryOptions = this.createDefaultSalesQueryOptions(salesQueryOptions);
        let filters;

        // values passed into getCurrentApiFilters are excluded filters.
        const newFilters = this.filterBaseService.getCurrentApiFilters("dealers", "ihs_segments", "segments", "makes", "models", "zips", "zones", "dma", "buyer_dma_code", "dealer_zip");
        delete newFilters[FilterName.sales_range.toString()];

        // Deleting zips because zips are added regardless of the excluded filters.
        delete newFilters["zips"];

        filters = Object.assign(newFilters, salesQueryOptions);
        const url = `${this.rootUrl}/sales/national`;
        if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
            filters["use_sales_data"] = "true";
        }

        const options = this.createRequestOptions(filters);
        return this.fetchInterfaces<any>(url, options).pipe(map((results) => results.map((result) => this.cleanKeys(result, SalesMapping))));
    }

    processGroupBySalesByDate(resultsData: any[], groupBySelectionFilter: FilterName, sumInGroup: boolean = false): GroupBySales[] {
        if (!resultsData.length) {
            return;
        }
        const volumeKeys = this.extractVolumeKeys(resultsData);

        // Some values have the same unique id and value (e.g. make, models), but
        // others such as dealers have unique id and non unique value
        // e.g. id = 123 and value = "Car Max" / id = 456 and value = "Car Max"
        let groupByIdKey;
        let groupByValueKey;
        switch (groupBySelectionFilter) {
            case FilterName.models:
                groupByIdKey = groupByValueKey = "model_description";
                break;
            case FilterName.makes:
                groupByIdKey = groupByValueKey = "make_description";
                break;
            case FilterName.segments:
                groupByIdKey = groupByValueKey = "segment";
                break;
            case FilterName.ihs_segments:
                groupByIdKey = groupByValueKey = "ihs_segment";
                break;
            case FilterName.dealers:
                groupByIdKey = "dealer_id";
                groupByValueKey = "dealer_name";
                break;
            case FilterName.zones:
                groupByIdKey = "sys_code";
                groupByValueKey = "ncc_name";
                break;
            case FilterName.zips:
                groupByIdKey = groupByValueKey = "buyer_zip";
                break;
            default:
                groupByIdKey = groupByValueKey = groupBySelectionFilter.toString();
        }

        if (resultsData[0].hasOwnProperty(groupByIdKey)) {
            const headerIndex = resultsData.findIndex(resultData => resultData[groupByIdKey] === null);

            if (headerIndex >= 0) {
                resultsData.splice(headerIndex, 1); // seperate the header row
            }
        }

        const processedData: GroupBySales[] = [];
        for (let i = 0; i < resultsData.length; i++) {
            const resultData = resultsData[i];

            let cleanedGroupByValue;

            // Zone names returned by the server contain 'Spectrum/' that need to be removed.
            if (groupBySelectionFilter === FilterName.zones) {
                cleanedGroupByValue = resultData[groupByValueKey].replace("Spectrum/", "");
            } else {
                cleanedGroupByValue = resultData[groupByValueKey];
            }

            const calculatedData: GroupBySales = {
                dma: resultData.dealer_dma_code,
                groupById: resultData[groupByIdKey],
                groupByFilter: groupBySelectionFilter.toString(),
                groupByValue: cleanedGroupByValue,
                sales: {},
                shares: {}
            };
            if (sumInGroup) {
                let sum = 0;
                volumeKeys.forEach(volumeKey => {
                    const date = this.extractDate(volumeKey);
                    sum = sum + resultData[volumeKey];
                });
                calculatedData.sales["group"] = sum;

            } else {
                volumeKeys.forEach(volumeKey => {
                    const date = this.extractDate(volumeKey);
                    calculatedData.sales[date] = resultData[volumeKey];
                });
            }
            processedData.push(calculatedData);
        }
        return processedData;
    }

    // processes shares for GroupBySales data by market total
    processSharesByMarket(groupBySalesData: GroupBySales[], filterOverrides: any): Observable<GroupBySales[]> {
        // Do not process shares percent if there is no dataset as combineLatest() will never
        // complete with an empty array.
        if (!groupBySalesData.length) {
            return of(groupBySalesData);
        }

        const allDmaSales = this.getTotalSalesForDmas(uniq(groupBySalesData.map(result => result.dma)), filterOverrides); // get totals for each dma

        const dmaLoadedSubjects: Observable<boolean>[] = [];
        const dmasSales = {};
        allDmaSales.forEach(dmaSales => {
            dmaLoadedSubjects.push(dmaSales.loaded.pipe(skipWhile(i => !i), take(1)));
            dmasSales[dmaSales.dma_id] = dmaSales;
        });

        return combineLatest(dmaLoadedSubjects).pipe(map((dmasLoaded) => { // wait until all dma sales data is loaded before processing
            if (dmasLoaded.every(dmaLoaded => dmaLoaded)) {
                for (let i = 0; i < groupBySalesData.length; i++) {
                    const dmaSales = dmasSales[groupBySalesData[i].dma];


                    if (filterOverrides.dateRangeType !== DateRangeTypes.MONTHS) {
                        Object.keys(groupBySalesData[i].sales).forEach(dateOfSale => {
                            const value = groupBySalesData[i].sales[dateOfSale];
                            groupBySalesData[i].shares[dateOfSale] = value ?
                                (value / dmaSales[dateOfSale.length > 4 ? "salesMonthly" : "sales"][dateOfSale]) * 100 : 0;
                        });
                    } else {
                        // add all together
                        const value = Object.values<number>(groupBySalesData[i].sales).reduce((acc, curr) => acc + curr);
                        const summedDma = Object.values<number>(dmaSales.salesMonthly).reduce((acc, curr) => acc + curr);
                        groupBySalesData[i].sales = { [`${filterOverrides.dateRangeEnd}`]: value };
                        groupBySalesData[i].shares[filterOverrides.dateRangeEnd] = groupBySalesData[i].sales[filterOverrides.dateRangeEnd] ? (groupBySalesData[i].sales[filterOverrides.dateRangeEnd] / summedDma) * 100 : 0;
                    }
                }
                return groupBySalesData;
            }
        }));
    }

    processSharesByZipZone(groupBySalesData: GroupBySales[], filterOverrides: any): GroupBySales[] {

        // get sum of sales in each date over all zones
        const zipzoneSumByDate = {};
        const keys = Object.keys(groupBySalesData[0].sales);
        for (let i = 0; i < groupBySalesData.length; i++) {
            for (let j = 0; j < keys.length; j++) {
                zipzoneSumByDate[keys[j]] = (zipzoneSumByDate[keys[j]] ? zipzoneSumByDate[keys[j]] : 0) + groupBySalesData[i].sales[keys[j]];
            }
        }

        for (let i = 0; i < groupBySalesData.length; i++) {

            Object.keys(groupBySalesData[i].sales).forEach(dateOfSale => {
                const value = groupBySalesData[i].sales[dateOfSale];
                groupBySalesData[i].shares[dateOfSale] = value ?
                    (value / zipzoneSumByDate[dateOfSale]) * 100 : 0;
            });
        }

        const filterOverrideSort = filterOverrides.zips || filterOverrides.zones;
        let unsorted = groupBySalesData;
        if (filterOverrideSort && Object.keys(filterOverrideSort).length > 0) {
            const result = [];
            filterOverrideSort.forEach((key) => {
                let found = false;
                unsorted = unsorted.filter((item) => {
                    if (!found && item.groupById === key) {
                        result.push(item);
                        found = true;
                        return false;
                    } else {
                        return true;
                    }
                });
            });
            return result;
        } else {
            return groupBySalesData;
        }
    }

    // Return the sales data for each given dma ids.
    getTotalSalesForDmas(dmaIds: number[], salesQueryOptions: SalesQueryOptions = {}, ignoreCache: boolean = false, filterOverrides: { [key: string]: any } = {}): DmaSales[] {
        const dmasSales = [];
        salesQueryOptions = this.createDefaultSalesQueryOptions(salesQueryOptions);
        if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
            salesQueryOptions["use_sales_data"] = "true";
        }
        salesQueryOptions.group = ["make", "model", "dma"];

        for (let i = 0; i < dmaIds.length; i++) {
            // const dmaSales = this.getDmaSalesFromCache(dmaIds[i], ignoreCache);

            const hash = hasher({ dma_id: dmaIds[i], query: salesQueryOptions });
            const dmaSales = this.getDmaSalesFromCache(hash, dmaIds[i], ignoreCache);

            if (salesQueryOptions.optimizeDates) {
                this.optimizeDates([dmaSales], salesQueryOptions);
            }

            if (!(salesQueryOptions.dateRangeStart && salesQueryOptions.dateRangeEnd)) {
                dmaSales.loaded.next(false);
            } else if (!((salesQueryOptions.dateRangeStart in dmaSales.sales || salesQueryOptions.dateRangeStart in dmaSales.salesMonthly) &&
                (salesQueryOptions.dateRangeEnd in dmaSales.sales || salesQueryOptions.dateRangeEnd in dmaSales.salesMonthly))) {
                dmaSales.loaded.next(false);
            }

            if (!dmaSales.loaded.value) {
                // SRAUTO-2452 Sales data will be compared to it's respective DMA.
                // Narrow down DMA to limit results to just the dealer's sales.
                // Also, remove any dealer filters so we get the full DMA's sales.
                const override = Object.assign({ dma: [dmaSales.dma_id], dealers: [] }, filterOverrides);
                // don't assign subscription to const just simply pass the subscription otherwise
                // it'll be stuck in memory, will need to be unsubscribed from SalesCache
                this.salesCache.addDmaSalesSubscription(
                    dmaSales,
                    this.getSalesDetails([], salesQueryOptions, override).subscribe((results) => {
                        const cleanedResults = results.map((result) => this.cleanKeys(result, SalesMapping));
                        this.salesCache.processSales(dmaSales, cleanedResults, dmaSales.dma_id, hash);
                    })
                );
            }

            dmasSales.push(dmaSales);
        }
        return dmasSales;
    }

    // private getDmaSalesFromCache(dmaId: number, ignoreCache: boolean): DmaSales {
    //     if (ignoreCache) {
    //         return this.salesCache.createUncachedDmaSales(dmaId);
    //     }
    //     return this.salesCache.getDmaSales(dmaId) || this.salesCache.createDmaSales(dmaId);
    // }
    private getDmaSalesFromCache(hash: string, dmaId: number, ignoreCache: boolean): DmaSales {
        if (ignoreCache) {
            return this.salesCache.createUncachedDmaSales(hash, dmaId);
        }
        return this.salesCache.getDmaSales(hash) || this.salesCache.createDmaSales(hash, dmaId);
    }

    calculateTopZones(zones: string[] = [], limit: number = 2, filtersOverride: { [key: string]: any } = {}): Observable<{ id: string; value: string }[]> {
        return this.metadataService.metadata.pipe(
            skipWhile(i => !(i && i.maxDate && i.maxSalesDate)),
            take(1),
            mergeMap(meta => {
                const dateRangeType = this.filterBaseService.getFilterValue(FilterName.dateRangeType);

                if (dateRangeType === "calendar") {
                    // only use the year in a string like "201903"
                    this.lastUpdated = meta.maxDate.slice(0, 4);
                    this.lastSalesUpdated = meta.maxSalesDate.slice(0, 4);
                } else {
                    this.lastUpdated = meta.maxDate;
                    this.lastSalesUpdated = meta.maxSalesDate;
                }

                const queryOptions: SalesQueryOptions = {
                    sort: {},
                    limit
                };

                if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
                    queryOptions.sort[`t${this.lastSalesUpdated}_volume`] = "DESC"; // e.g. {t201811_volume: DESC}
                } else {
                    queryOptions.sort[`t${this.lastUpdated}_volume`] = "DESC"; // e.g. {t201811_volume: DESC}
                }

                return this.getGroupBySalesDetails(queryOptions, zones, FilterName.zones, filtersOverride)
                    .pipe(map(zoneResults => zoneResults.map(z => ({ id: z.groupById as string, value: z.groupByValue as string }))));
            }));
    }

    getZipZoneSalesForDateRange(startDate: string, endDate: string, overrides: any, areaType: string): Observable<{}[]> {

        // const dateRangeType = this.filterBaseService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        // const dateRanges = this.filterBaseService.getFilterValue<string[]>(FilterName.dateRanges);

        // queryOptions.sort[`t${maxDate}_volume`] = "DESC"; // e.g. {t201811_volume: DESC}
        overrides.dateRangeStart = startDate;
        overrides.dateRangeEnd = endDate;

        return this.getGroupBySalesDetails({}, [], FilterName[areaType], overrides, true).pipe(
            map(zipZoneResults => zipZoneResults.map(z => {
                //As long as sales data is incomplete a check is needed to make sure sales values are valid.
                const sales = !isNaN(z.sales?.group) ? z.sales : {group: 0};
                const shares = !isNaN(z.shares?.group) ? z.shares : {group: 0};

                return {
                    id: z.groupById as string,
                    value: z.groupByValue as string,
                    sales,
                    shares
                };
            })));

    }

    protected extractDate(salesDataVolumeKey: string): string {
        if (salesDataVolumeKey && salesDataVolumeKey.endsWith("_volume")) {
            return salesDataVolumeKey.match(/t(\d+)_volume/)[1];
        }
        return null;
    }

    protected extractVolumeKeys(salesData: any[]): string[] {
        if (!(salesData && salesData[0])) {
            return [];
        }
        const volumeKeys: string[] = [];
        for (const k in salesData[0]) {
            if (k && k.endsWith("_volume")) {
                volumeKeys.push(k);
            }
        }
        return volumeKeys;
    }

    protected optimizeDates(dealerSales: Sales[], salesQueryOptions: SalesQueryOptions): void {
        if (salesQueryOptions.dateRangeType !== "months" || dealerSales.length === 0) {
            return;
        }
        let start = salesQueryOptions.dateRangeStart;
        let end = salesQueryOptions.dateRangeEnd;

        // get the dates shared by all current dealer sales objects
        const dates = intersection(flatten(dealerSales.map(ds => Object.keys(ds.salesMonthly)))).sort();

        if (dates.length === 0) {
            return;
        }

        const loadedStart = dates[0];
        const loadedEnd = dates[dates.length - 1];
        let previous;

        if (start < loadedStart && end <= loadedEnd) {
            previous = true;
        } else if (start > loadedStart && end > loadedEnd) {
            previous = false;
        } else {
            return;
        }

        while (dates.includes(end)) {
            end = DateUtils.monthYearNavigate(end, previous);
        }
        while (dates.includes(start)) {
            start = DateUtils.monthYearNavigate(start, previous);
        }

        if (start === end) {
            if (previous) {
                start = DateUtils.monthYearAddMonths(start, -12);
            } else {
                end = DateUtils.monthYearAddMonths(end, 12);
            }
            salesQueryOptions.dateRangeStart = start;
            salesQueryOptions.dateRangeEnd = end;
        }
    }

    public createDefaultSalesQueryOptions(salesQueryOptions: SalesQueryOptions = {}): SalesQueryOptions {
        return defaults({}, salesQueryOptions, {
            group: ["dealer_id", "dma", "make", "model"],
            filterType: this.filterBaseService.getFilterValue(FilterName.result_data_filtered)
        });
    }
}
