<button (click)="onCreateTVScheduleClicked($event)" class="create-schedule-button" [disabled]="!isAudienceAppAuth">
    <div class="tooltip-trigger" #tooltip [dTooltip]="showDisableError" [showTooltip]="!isAudienceAppAuth" [placement]="'bottom'">
        <span>Create TV Schedule</span>
    </div>
</button>


<ng-template #showDisableError>
    <div style="max-width: 200px;">
        You do not have an Audience App account, please contact reachme@spectrum.com.
    </div>
</ng-template>

