// this <any> trick is explained here, it is supported without <any> in newer versions of TS
// http://morrisdev.com/2016/09/typescript-enum-saves-time/
export enum FilterName {
    dateRangeType = <any>"dateRangeType",
    date_display = <any>"date_display",
    display_range = <any>"display_range",
    dateRanges = <any>"dateRanges",
    minDate = <any>"minDate",
    maxDate = <any>"maxDate",
    minSalesDate = <any>"minSalesDate",
    maxSalesDate = <any>"maxSalesDate",
    dma = <any>"dma",
    dealers = <any>"dealers",
    not_dealers = <any>"not_dealers",
    makes = <any>"makes",
    dates = <any>"dates",
    models = <any>"models",
    segments = <any>"segments",
    ihs_segments = <any>"ihs_segments",
    use_ihs_segments = <any>"use_ihs_segments",
    zips = <any>"zips",
    zones = <any>"zones",
    new_used_flag = <any>"new_used_flag",
    buyer_dma_code = <any>"buyer_dma_code",
    dealer_dma_code = <any>"dealer_dma_code",
    zip_zone_flag = <any>"zip_zone_flag",
    dealer_zip = <any>"dealer_zip",
    not_dealer_zip = <any>"not_dealer_zip",
    sales_area_zips = <any>"sales_area_zips",
    show_volume = <any>"show_volume",
    last_update = <any>"last_update",
    ring_radius = <any>"ring_radius",
    show_ring = <any>"show_ring",
    result_data_filtered = <any>"result_data_filtered",
    include_spotlight = <any>"include_spotlight",
    spotlight_dealer = <any>"spotlight_dealer",
    spotlightDealerDetails = <any>"spotlightDealerDetails",
    displayDateRanges = <any>"displayDateRanges",
    competitors_selection = <any>"competitors_selection",
    sales_area_selection = <any>"sales_area_selection",
    active_selection = <any>"active_selection",
    report_building = <any>"report_building",
    top_zones_names = <any>"top_zones_names",
    top_zones_sys_codes = <any>"top_zones_sys_codes",
    top_ad_zone_models = <any>"top_ad_zone_models",
    sales_range = <any>"volume",
    results = <any>"results",
    geosearch = <any>"geosearch",
    top_competitors = <any>"top_competitors",
    top_zips = <any>"top_zips",
    top_zones = <any>"top_zones",
    selected_dealer = <any>"selected_dealer",
    selected_options = <any>"selected_options",
    run_id = <any>"run_id",
    aa_query_vars = <any>"aa_query_vars",
    aa_is_Dma_valid = <any>"aa_is_Dma_valid",
    dealer_sales_data = <any>"dealer_sales_data",
    enabled_features = <any>"enabled_features",
    use_sales_data = <any>"use_sales_data",
    disable_sales_data_menu = <any>"disable_sales_data_menu",
    last_sales_update = <any>"last_sales_update",
}
