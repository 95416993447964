import { Injectable } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { OverlayService } from "app/core/services/overlay.service";

@Injectable()
/**
 * This class is responsible for choosing which panel to open when clicking
 * on a <filter-expand-button>.
 */
export class OpenPanelService {

    constructor(
        private overlayService: OverlayService
    ) { }

    openPanel(disabledState: boolean, filterName: FilterName, selected: boolean): void {
        if (!disabledState) {
            this.togglePanel(filterName, selected);
        }
    }

    private togglePanel(filterName: FilterName, selected: boolean): void {
        if (!selected) {
            this.choosePanel(filterName);
            selected = true;
        } else {
            this.overlayService.hidePanel();
        }
    }

    private choosePanel(filterName: FilterName): void {
        switch (filterName) {
            case FilterName.sales_range:
                this.overlayService.showSalesRangePanel();
                break;
            default:
                this.overlayService.showSelectionPanel(filterName);
                break;
        }
    }

}
