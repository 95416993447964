<div>
    <div class="title">{{title}}</div>
    <div class="mm-dates values">
        <ng-container *ngIf="!salesDone; else salesDates">
            <div class="loading"></div>
            <div class="loading"></div>
            <div class="loading"></div>
        </ng-container>
        <ng-template #salesDates>
            <thead style="display: flex; justify-content: end;">
            <tr>
                <th class="data"></th>
                <th class="data" *ngFor="let date of dates">
                    <pre>{{formatDate(date)}}</pre>
                </th>
            </tr>
            </thead>
        </ng-template>
    </div>

    <div class="mm-list">
        <ng-container *ngIf="!salesDone; else makes">
            <div class="mm-item values">
                <ng-container *ngTemplateOutlet="loading"></ng-container>
            </div>
            <div class="mm-item values">
                <ng-container *ngTemplateOutlet="loading"></ng-container>
            </div>
            <div class="mm-item values">
                <ng-container *ngTemplateOutlet="loading"></ng-container>
            </div>
        </ng-container>
        <ng-template #makes>
            <div *ngFor="let s of sales" class="mm-item">
                <sales-accordion [attr.data-vehicle-make]="s.label" [label]="s.label" [rollups]="s.rollups"
                    [sales]="s.items" [showVolume]="showVolume"></sales-accordion>
            </div>
        </ng-template>
    </div>

    <ng-template #loading>
        <div class="wide loading"></div>
        <ng-container *ngTemplateOutlet="loaders"></ng-container>
    </ng-template>

    <ng-template #loaders>
        <div class="loading"></div>
        <div class="loading"></div>
        <div class="loading"></div>
    </ng-template>
</div>
