<div dPopup id="chart-popup" class="chart-popup" [class.wide]="isDealerSales">
    <ng-container *ngIf="data">
        <div class="header move-handle">
            <div *ngIf="!!data.titleMarker" class="legend" [style.background]="markerColor(data.titleMarker)"></div>
            {{data.title}}
            <div class="close" (click)="chartPopupService.hideModal()">
                <div class="icon"></div>
            </div>
        </div>
        <div class="content">
            <!-- Render dealer models sales -->
            <ng-container *ngIf="data.type === 'models'; else default">
                <table *ngFor="let d of data.dealers" class="models">
                    <thead>
                        <tr>
                            <th>
                                <div *ngIf="d.spotlight">Spotlighted Dealer:</div>
                                {{d.name}}
                            </th>
                            <th *ngFor="let col of data.columns">{{col}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let model of getMakes(d)">
                            <th>
                                <div class="row-header">
                                    <div *ngIf="d.values[model].color" class="legend"
                                        [style.background]="getColor(d.values[model])"></div>
                                    <div>{{model}}</div>
                                </div>
                            </th>
                            <td *ngFor="let col of data.columns; let i = index"
                                [ngClass]="compareToPreviousYear(d.values[model][col], d.values[model][data.columns[i + 1]])">
                                <span *ngIf="d.values[model][col] !== undefined; else loading"
                                    id="{{uniqueIdForData(d, col)}}">
                                    {{data.display === 'shares' ? (d.values[model][col] | SigFig) + '%' :
                                    d.values[model][col] | DisplayFig}}
                                    <span class="arrow"></span>
                                </span>
                                <ng-template #loading>
                                    <span class="loading"></span>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

            <!-- Render total sales for dealers dealer -->
            <ng-template #default>
                <table class="totals">
                    <thead>
                        <tr>
                            <th *ngIf="isDealerSales"></th>
                            <th *ngFor="let col of data.columns">{{col}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of data.dealers; let isFirst = first" [class.first-row]="isFirst">
                            <th *ngIf="isDealerSales">
                                <div class="row-header">
                                    <div *ngIf="d.color" class="legend" [style.background]="getColor(d)"></div>
                                    <div>
                                        <div *ngIf="d.spotlight">Spotlighted Dealer:</div>
                                        {{d.name}}
                                    </div>
                                </div>
                            </th>
                            <td *ngFor="let col of data.columns; let i = index"
                                [ngClass]="compareToPreviousYear(d.values[col], d.values[data.columns[i + 1]])">
                                <span *ngIf="d.values[col] !== undefined; else loading"
                                    id="{{uniqueIdForData(d, col)}}">
                                    {{data.display === 'shares' ? (d.values[col] | SigFig) + '%' : d.values[col] |
                                    DisplayFig}}
                                    <span class="arrow"></span>
                                </span>
                                <ng-template #loading>
                                    <span class="loading"></span>
                                </ng-template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>
        </div>
    </ng-container>
</div>
