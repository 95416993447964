import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

import { HttpServiceBase } from "../base/services/http-service-base.service";
import { DmaMapping } from "../models/dma-mapping";
import { Dma } from "../models/dma.model";
import { FilterName } from "../models/filter-name.enum";
import { FilterStateService } from "./filter-state.service";

@Injectable()
export class DmaDataService extends HttpServiceBase {

    allDmasCache = new Map<number, string>();
    readonly allDmasLoaded = new BehaviorSubject<boolean>(false);

    constructor(protected http: HttpClient, protected filterStateService: FilterStateService) {
        super(http);
    }

    private getDmas(): Promise<Dma[]> {
        const url = `${this.rootUrl}/dma/distinct`;
        const useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        const options = this.createRequestOptions(useSalesData ? {
            "use_sales_data": "true"
        } : {});
        return this.fetchInterfaces<Dma>(url, options).toPromise();
    }

    async loadAllDmas(): Promise<void> {
        return this.getDmas().then((dmas) => {
            if (dmas.length) {
                const cleanedDmas = dmas.map((dma) => this.cleanKeys(dma, DmaMapping));
                cleanedDmas.forEach((dma) => this.allDmasCache.set(dma.id, dma.name));
                this.allDmasLoaded.next(true);
            }
        });
    }

    getAllDmaNames(): string[] {
        return Array.from(this.allDmasCache.values());
    }

    getAllDmaIds(): number[] {
        return Array.from(this.allDmasCache.keys());
    }

    getDmaName(dmaId: number): string {
        if (!this.allDmasCache.has(dmaId)) {
            return "Loading...";
        }
        return this.allDmasCache.get(dmaId);
    }

    getShortDmaName(dmaId: number): string {
        let dmaName = this.getDmaName(dmaId);
        dmaName = dmaName.split("-")[0].trim();
        const split = dmaName.split(" ");
        let short = "";
        while (short.length < 11 && split.length) {
            short += split.shift() + " ";
        }
        short = short.trim().replace(/[ &,]*$/, ""); // remove trailing comma
        return short;
    }

    getDmaNames(dmaIds: number[], shortName: boolean = false): string[] {
        return dmaIds.map((dmaId) => shortName ? this.getShortDmaName(dmaId) : this.getDmaName(dmaId));
    }

    getDmaId(dmaName: string): number {
        const cacheEntries = this.allDmasCache.entries();
        for (let i = 0; i <= this.allDmasCache.size; i++) {
            const currentEntry = cacheEntries.next().value;
            if (currentEntry[1] === dmaName) {
                return currentEntry[0];
            }
        }
        return undefined;
    }

    getDmaIds(dmaNames: string[]): number[] {
        return dmaNames.map((dmaName) => this.getDmaId(dmaName));
    }

    getCurrentDmaId(): number[] {
        return this.filterStateService.getFilterValue<number[]>(FilterName.dma);
    }

    getRecentDmas(): number[] {
        return this.filterStateService.getRecentDma();
    }
}
