import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { SpotlightCompetitors, SpotlightSalesAreas } from "app/core/models/spotlight.enum";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FeatureFlagService, FeatureFlags } from "app/core/services/feature-flag.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SpotlightService } from "app/core/services/spotlight.service";
import { USPSZipService } from "app/core/services/usps.zip.service";
import { parse } from "fsp-xml-parser";
import { isNull } from "lodash";
import { Subscription } from "rxjs";
import { skip } from "rxjs/operators";

import { ZipUploadContainer } from "../../drag-and-drop-file-upload/drag-and-drop-file-upload.component";
import { IFilterToggleState } from "../filter-toggle-button/filter-toggle-button.component";

@Component({
    selector: "filter-spotlight-menu",
    templateUrl: "./filter-spotlight-menu.component.html",
    styleUrls: ["./filter-spotlight-menu.component.scss"]
})
export class FilterSpotlightMenuComponent implements OnInit, OnDestroy {
    @Input() areaButtonShown = ["ALL", "PRIMARY", "SECONDARY", "RADIUS"];
    @Input() competitorOptionsShown = ["ALL", "PUMP_IN", "IN_MARKET", "ONLY"];
    @Input() showSpotLight = true;
    @Input() showRadius = true;
    @Input() showAreaTitle = false;
    @Output() zipsUploaded: EventEmitter<ZipUploadContainer> = new EventEmitter();
    @Output() useCustomZips: EventEmitter<boolean> = new EventEmitter();
    dates: string[] = [];
    spotlightToggleDisabled = false;
    radiusSalesAreaDisabled = false;
    showRing = false;
    radiusDesc: string;

    customZipsString: string;
    customZips: string[];
    zipErrors: string[];
    showZipUploadModal = false;
    hasErrors = false;
    uploadFailure = false;
    zipUploadComplete = false;
    isCustomZipsEnabled = false;

    // Setting enum in class for template usage.
    FilterName = FilterName;
    SpotlightSalesAreas = SpotlightSalesAreas;
    SpotlightCompetitors = SpotlightCompetitors;

    competitors: SpotlightCompetitors = SpotlightCompetitors.All;
    salesArea: SpotlightSalesAreas = SpotlightSalesAreas.All;

    filterStateServiceSubscription: Subscription;
    dealerSetsSubscription: Subscription;

    constructor(
        protected filterStateService: FilterStateService,
        private spotlightService: SpotlightService,
        private dealerDataService: DealerDataService,
        protected featureFlagService: FeatureFlagService,
        protected uspsZipService: USPSZipService
    ) { }

    async ngOnInit(): Promise<any> {
        this.isCustomZipsEnabled = await this.featureFlagService.isFeatureEnabled(FeatureFlags.AA_Custom_Zips);
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.dealerSetsSubscription = this.dealerDataService.dealerDetails.subscribe(this.dealersUpdated.bind(this));
        this.filtersUpdated([FilterName.competitors_selection.toString(), FilterName.show_ring.toString()]);
        this.updateRadiusLabelText();
    }

    ngOnDestroy(): void {
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
        }
        if (this.dealerSetsSubscription) {
            this.dealerSetsSubscription.unsubscribe();
        }
        // clear zip data
        this.clearZips();
    }

    filtersUpdated(changes: string[]): void {
        if (changes.includes(FilterName.competitors_selection.toString()) || changes.includes(FilterName.sales_area_selection.toString())) {
            this.competitors = this.filterStateService.getFilterValue(FilterName.competitors_selection);
            this.salesArea = this.filterStateService.getFilterValue(FilterName.sales_area_selection);
            // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
            if (this.salesArea !== SpotlightSalesAreas.Custom) {
                this.clearZips();
            }
            this.spotlightToggleDisabled = (this.competitors === SpotlightCompetitors.PumpIn || this.competitors === SpotlightCompetitors.SpotlightOnly);
            this.radiusSalesAreaDisabled = this.competitors === SpotlightCompetitors.PumpIn || this.competitors === SpotlightCompetitors.InMarket;
            this.updateRadiusLabelText();
        }
        if (changes.includes(FilterName.show_ring.toString())) {
            this.showRing = this.filterStateService.getFilterValue<boolean>(FilterName.show_ring);
            if (!this.showRing && this.salesArea === SpotlightSalesAreas.Radius) {
                // Delay setting sales area to next event cycle for angular render cycle.
                window.setTimeout(() => {
                    this.spotlightService.selectSalesArea(SpotlightSalesAreas.All);
                }, 0);
            }
        }
    }

    dealersUpdated(): void {
        this.filterStateService.verifySpotlightDealerValidity()
            .subscribe((spotlightDealerCheck) => this.spotlightToggleDisabled = !spotlightDealerCheck);
    }

    selectSalesArea(area: SpotlightSalesAreas): void {
        this.spotlightService.selectSalesArea(area);
    }

    selectCompetitors(competitors: SpotlightCompetitors): void {
        this.spotlightService.selectCompetitors(competitors);
    }

    updateToggleValue(state: IFilterToggleState): void {
        if (!state) {
            return;
        }
        this.filterStateService.setFilterValue(state.name, state.value);
    }

    private updateRadiusLabelText(): void {
        const isRadius = this.filterStateService.getFilterValue<string>(FilterName.sales_area_selection) === SpotlightSalesAreas.Radius;
        this.radiusDesc = `${isRadius ? "Filters on" : "Displays"} a 1 to 50 mile radius ring around selected dealer`;
    }

    /**
     * Toggles the Zip Code Upload Modal to Show/Hide
     *
     * @returns void
     */
    toggleUploadModal(): void {
        this.showZipUploadModal = !this.showZipUploadModal;
    }

    /**
     * Zip Upload Handler, handles the actual upload and reading of the zips contained in the upload.
     * It stores them locally in this components memory since after they leave the page we want the
     * upload to be removed from memory otherwise we would store it in NGRX but there is no persistance.
     *
     * @param zipContainer ZipUploadContainer   an object containing an array of zips and a string that contains the uploaded filename
     * @returns void
     */
    zipsUploadedHandler(zipContainer: ZipUploadContainer): void {
        this.clearZips();
        if (zipContainer.success) {
            this.zipErrors = zipContainer.errors;
            this.customZips = zipContainer.zips;
            this.generateZipString(zipContainer.zips);
            this.showZipUploadModal = false;
            if (this.zipErrors.length >= 1) {
                this.hasErrors = true;
                if (parseInt(this.zipErrors[0], 10) === 1 && isNull(zipContainer.zips[0]) || zipContainer.zips[0] === "undefined") {
                    this.uploadFailure = true;
                }
            }
            this.zipUploadComplete = true;
            this.zipsUploaded.next({ zips: zipContainer.zips, fileName: zipContainer.fileName, errors: zipContainer.errors, success: !this.uploadFailure });
            this.useCustomZips.emit(true);
        } else {
            this.uploadFailure = true; // if there was a validation error fail the upload
            this.zipsUploaded.emit({ zips: [], fileName: "", errors: [], success: false });
            this.useCustomZips.emit(false);
        }
    }

    /**
     * When user presses "return" | "enter" key on zip upload input.
     *
     * @param event any     The keypress event
     */
    async onZipKeypress(event: any): Promise<void> {
        if (event.keyCode === 13 && event.code === "Enter" || event.code === "NumpadEnter") {
            this.clearZips();
            this.validateZips(event.target.value, true);
        }
    }

    async validateZips(zipString: string, manualEntry: boolean = false): Promise<void> {
        let zips = zipString.split(",").map((value) => {
            return value.trim();
        });

        let errors: string[] = [];
        let finalZips: string[] = [];
        let groupedZips = this.breakZipsIntoGroups(zips, 5);
        // loop the zips array
        for (let i = 0; i < groupedZips.length; i++) {
            let requestZips = groupedZips[i];
            await this.uspsZipService.validateZips(requestZips).then(response => {
                // parses the XML returned back from USPS, loops through the response array
                // and finds errors and records them.
                parse(response).root.children.forEach((value, index) => {
                    // USPS returns 3 children if the zip is valid and 1 child if it's invalid
                    // also it pulls the erroneous zip code out of the zips array
                    if (value.children.length === 1 && value.children[0].name === "Error") {
                        // fetch the index of original zips array (full set)
                        let zipIndex = zips.findIndex((element, idx) => {
                            // search for a duplicate erroneous zip code
                            if (errors.includes((idx + 1).toString())) {
                                return false;
                            }
                            if (element === requestZips[index]) {
                                return true;
                            }
                        });
                        // use the index from the original array + 1 to get the Excel row #
                        errors.push((zipIndex + 1).toString());
                    } else {
                        finalZips = [...finalZips, value.children[0].content];
                    }
                });
            }, (error) => {
                console.error(error);
                // we'll handle the error from USPS by just not capturing errors but still processing the zips.
                this.zipsUploaded.next({ zips: finalZips, fileName: "", errors, success: false });
            });
        } // loop end
        if (manualEntry) {
            this.generateZipString(finalZips);
        }
        // push out the data including which zips have errors
        this.zipsUploaded.next({ zips: finalZips, fileName: "", errors, success: true });
    }

    generateZipString(zips: string[]): void {
        let zipString = "";
        for (const zip of zips) {
            zipString = zipString + `${zip}, `;
        }
        zipString = zipString.substring(0, zipString.length - 2);
        this.customZipsString = zipString;
    }

    /**
     * Breaks an array of zips into groups of however many zips set in maxPerGroup
     *
     * @param data              string[]        your string array of data, in this case zips...
     * @param maxPerGroup       number          the arbitrary number you set to group the data
     * @returns                 string[][]      returns a multidimensional array of groups of zips
     */
    breakZipsIntoGroups(data: string[], maxPerGroup: number): string[][] {
        let groups = [];
        for (let index = 0; index < data.length; index += maxPerGroup) {
            groups.push(data.slice(index, index + maxPerGroup));
        }
        return groups;
    }

    /**
     * Cancel action located on the modal which will then toggleUploadModal again
     * effectively closing it and clearing any zips out that were stored in memory.
     *
     * @param   event   The DOM event.
     * @returns void
     */
    zipUploadCancelledHandler(event: any): void {
        this.clearZips();
        this.showZipUploadModal = false;
    }

    uploadFailureHandler(uploadFailure: boolean): void {
        this.uploadFailure = uploadFailure;
        this.zipUploadComplete = true;
    }

    /**
     * Clears zips, zipString, hasErrors, and zipErrors back to default
     *
     * @returns void
     */
    clearZips(): void {
        this.customZips = [];
        this.customZipsString = "";
        this.hasErrors = false;
        this.zipErrors = [];
        this.zipUploadComplete = false;
        this.showZipUploadModal = false;
        this.uploadFailure = false;
        this.zipUploadComplete = false;
        this.zipsUploaded.emit({ zips: [], fileName: "", errors: [], success: false });
        this.useCustomZips.emit(false);
    }
}
