<card cardTitle="Year Over Year Comparisons{{ dateTitle }}" [description]="description" [descriptionDisabled]="disableDescription" (open)="cardOpened()"
    (close)="cardClosed()" [disabled]="isDisabled" [isOpen]="isExpanded" [loading]="isLoading">
    <div class="section-header">
        Year Over Year Dealer Sales
        <span class="pipe">|</span>
        <div *ngIf="!useSalesData">
            <span [ngClass]="salesPattern(dealersPercentChange)">
                {{dealersPercentChange === 100 ? 100 : Math.abs(dealersPercentChange)?.toFixed(2)}}% <span class="arrow"></span>
            </span>
        </div>
    </div>
    <chart id="chart-yoy-dealer" [dataToRender]="dealersData" [labelsToRender]="labels" [colors]="dealersChartColor"
        [legends]="dealersLegendData" [percentLabel]="!showVolumeOnChart" (legendGroupClick)="onDealersGroupClick($event)"
        (chartLabelClick)="onDealersChartLabelClick($event)" (chartDatasetClick)="onDealersChartLabelClick($event)"></chart>
    <div class="section-header">
        Year Over Year Market Sales
        <span class="pipe">|</span>
        <span [ngClass]="salesPattern(marketPercentChange)">
            {{marketPercentChange === 100 ? 100 : Math.abs(marketPercentChange)?.toFixed(2)}}% <span class="arrow"></span>
        </span>
    </div>
    <chart id="chart-yoy-market" [dataToRender]="marketData" [labelsToRender]="labels" [colors]="marketChartColor"
        [hideLegend]="true" [percentLabel]="!showVolumeOnChart" (chartLabelClick)="onMarketChartLabelClick($event)"
        (chartDatasetClick)="onMarketChartLabelClick($event)"></chart>
    <!-- Custom footer that controls both charts -->
    <div class="chart-footer">
        <chart-legend class="chart-legend" [data]="dealersData" [selectedLegends]="selectedLegends" [colors]="dealersChartColor"
            (legendClick)="legendClick($event)"></chart-legend>
    </div>
</card>
