import { Component, Input } from "@angular/core";

import { ZipZoneVolumeComparison } from "../zip-zone-table/zip-zone-table.component";

@Component({
    selector: "sales-zip-table",
    templateUrl: "./sales-zip-table.component.html",
    styleUrls: ["./sales-zip-table.component.scss"]
})
export class SalesByZipTableComponent {
    @Input() title = "";
    @Input() salesByZip: ZipZoneVolumeComparison[] = [];
    @Input() showVolume = true;
    @Input() totalVolume = 0;
}
