export enum SpotlightSalesAreas {
    All = "ALL",
    Primary = "PRIMARY",
    Secondary = "SECONDARY",
    Radius = "RADIUS",
    Custom = "CUSTOM"
}

export enum SpotlightCompetitors {
    All = "ALL",
    PumpIn = "PUMP_IN",
    InMarket = "IN_MARKET",
    SpotlightOnly = "SPOTLIGHT_ONLY"
}
