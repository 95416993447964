import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { HeatmapScaleLevels } from "app/core/services/sales-data.service";

@Component({
    selector: "map-heatmap-legend",
    templateUrl: "map-heatmap-legend.component.html",
    styleUrls: ["map-heatmap-legend.component.scss"]
})
export class MapHeatmapLegendComponent implements OnChanges {
    @Input() leftPanelOpen;
    @Input() legendInfo: HeatmapScaleLevels[];

    expanded = true;
    smallDataSet = false;


    constructor(
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.legendInfo && this.legendInfo.length >= 1) {
            // if every legend item min === max this is a small data set.
            for (let i = 0; i < this.legendInfo.length; i++) {
                if (this.legendInfo[i].min === this.legendInfo[i].max) {
                    this.smallDataSet = true;
                }
            }
        }
    }

    expand(): void {
        if (!this.expanded) {
            this.expanded = true;
        }
    }

    minimize(event: MouseEvent): void {
        if (this.expanded) {
            this.expanded = false;
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
        }
    }
}
