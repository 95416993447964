<div class="filter-panel">
    <div class="filter-panel-scroll-container-outer box-shadow">
        <div class="filter-panel-scroll-container-inner">
            <div #filterPanelContent class="filter-panel-content">
                <filter-reset-button></filter-reset-button>
                <filter-spotlight-button></filter-spotlight-button>
                <filter-spotlight-menu *ngIf="spotlightDealerId"></filter-spotlight-menu>
                <filter-toggle-button label="Outside DMAs" optionOneLabel="Include" [optionOne]="[]"
                    optionTwoLabel="Exclude" [optionTwo]="currentDma" filterName="{{FilterName.buyer_dma_code}}"
                    [optionTwoSelected]="secondDmaValueSelected"
                    (toggle)="updateToggleValue($event)"></filter-toggle-button>
                <date-range-selector
                    (toggle)="toggleDateRange($event)"
                    [options]="[{ label: 'Rolling 12', value: DateRangeTypes.ROLLING12 }, { label: 'YTD', value: DateRangeTypes.YTD }, { label: 'Calendar', value: DateRangeTypes.YEARS }, { label: 'Custom', value: DateRangeTypes.MONTHS }]"
                    filterName="{{FilterName.dateRangeType}}"
                    radioGroupName="Pick a Date Range">
                </date-range-selector>
                <filter-toggle-button
                    label="Vehicles"
                    [disabled]="useSalesData"
                    optionOneLabel="New"
                    [optionOne]="0"
                    optionTwoLabel="Used"
                    [optionTwo]="1"
                    filterName="{{FilterName.new_used_flag}}"
                    (toggle)="updateToggleValue($event)">
                </filter-toggle-button>
                <div *ngIf="weeklyDataSelectorEnabled">
                    <data-source-selector
                        (toggle)="updateDataSource($event)"
                        [options]="[{ label: 'Registration Data', value: SalesDataTypes.REGISTRATION, hover: 'Registration data comes from the government record and is updated every 45-60 days. It includes data from 2 months prior to the current date.' }, { label: 'Sales Data', value: SalesDataTypes.SALES, hover: 'Sales data comes from a blend of several sources and only reflects new vehicle sales. It is updated every 7 days.' }]"
                        filterName="{{FilterName.use_sales_data}}"
                        radioGroupName="Pick a Data Source">
                    </data-source-selector>
                </div>
                <filter-expand-button label="Modify Sales Range" filterName="{{FilterName.sales_range}}">
                </filter-expand-button>

                <filter-expand-button [dTooltip]="spotlightonlytemplate" [showTooltip]="spotlightOnly" label="Dealers"
                    filterName="{{FilterName.dealers}}" [disabledState]="spotlightOnly"></filter-expand-button>

                <filter-expand-button *ngIf="ihsSegmentsEnabled" label="Segments"
                    filterName="{{FilterName.ihs_segments}}"></filter-expand-button>
                <filter-expand-button *ngIf="!ihsSegmentsEnabled" label="Segments" filterName="{{FilterName.segments}}">
                </filter-expand-button>
                <filter-expand-button label="Makes" filterName="{{FilterName.makes}}"></filter-expand-button>
                <filter-expand-button label="Models" filterName="{{FilterName.models}}"></filter-expand-button>
                <filter-expand-button label="Zip Codes" filterName="{{FilterName.zips}}"></filter-expand-button>
                <filter-expand-button label="Ad Zones" filterName="{{FilterName.zones}}"></filter-expand-button>
                <div class="last-item-border"></div>
<!--                <div class="data-details" *ngIf="dataLastUpdate?.length > 0">Sales data through {{dataLastUpdate}}</div>-->
            </div>
        </div>
    </div>
</div>

<ng-template #spotlightonlytemplate>
    <div style="max-width:220px">
        Dealers filters are unavailable when in Spotlight Only mode.
    </div>
</ng-template>
