import { Injectable } from "@angular/core";

import { ZipZoneSale } from "../models/zip-zone.model";
import { HeatmapScaleLevels, SalesDataService } from "./sales-data.service";

@Injectable()
export class TopZipsService {

    constructor(
        private salesDataService: SalesDataService
    ) {

    }

    public getScaledZips(sales: ZipZoneSale[], isCustom: boolean = false): { scaledZips: string[][]; scaledZipLevels: HeatmapScaleLevels[] } {
        const salesByZip = sales.sort((a, b) => b.sales - a.sales);

        let zipVolumes;
        if (!isCustom) {
            zipVolumes= salesByZip.filter(sale => sale.area !== null).slice(0, 25).map((sale) => ({ zip: sale.location, volume: sale.sales }));
        } else if(isCustom) {
            zipVolumes = salesByZip.slice(0, 25).map((sale) => ({ zip: sale.location, volume: sale.sales }));
        }

        // update the heatmap scalings with the zips this card cares about, not the entire market's zips
        // this updates the scaledZips behavior subject the heatmap legend subscribes to
        const scaledZips = this.salesDataService.getHeatmapScalings(zipVolumes, true);

        // get the scaledZips themselves now that they're updated
        return scaledZips;
    }

}
