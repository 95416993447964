<div class="results-expand-button">
    <div class="result-header" (click)="toggleShowHide()">
        <div class="button-header" [class.expand]="visible">
            <span class="index" *ngIf="index">
                {{index}}
            </span>
            <div class="result-title">
                {{title}}
            </div>
            <div class="icon">
                <span class="arrow-up" *ngIf="visible"></span>
                <span class="arrow-down" *ngIf="!visible"></span>
            </div>
        </div>
        <div class="header-description" [ngClass]="{open:visible&&opened}">
            <ng-container *ngTemplateOutlet="descriptionTemplate"></ng-container>
        </div>
    </div>
    <div class="result-values" [ngClass]="{open:visible&&opened}">
        <div class="result-values-internal" *ngIf="opened">
            <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
        </div>
    </div>
    <ng-container *ngIf="!visible">
        <ng-container *ngTemplateOutlet="closedTemplate"></ng-container>
    </ng-container>
</div>
