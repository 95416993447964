import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { OverlayService } from "app/core/services/overlay.service";
import { PopupService } from "app/core/services/popup.service";
import { Subject, Subscription } from "rxjs";

const PADDING = 20;

@Directive({
    selector: "[dPopup]"
})
export class PopupDirective implements OnInit, OnDestroy {
    dragging: boolean;
    overlayServiceSubscription: Subscription;
    visible = false;

    public readonly closed = new Subject();
    public readonly opened = new Subject();

    dragStartX: number;
    dragStartY: number;

    @Input() ignoreOverlay = false;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private overlayService: OverlayService,
        private popupService: PopupService
    ) { }

    ngOnInit(): void {
        this.renderer.setStyle(this.elementRef.nativeElement, "position", "fixed");
        this.renderer.setStyle(this.elementRef.nativeElement, "z-index", "1000");
        this.setVisible(false);
        if (!this.ignoreOverlay) {
            this.overlayServiceSubscription = this.overlayService.overlayState.subscribe(this.closePopup.bind(this));
        }
    }

    ngOnDestroy(): void {
        if (this.overlayServiceSubscription) {
            this.overlayServiceSubscription.unsubscribe();
        }
    }

    openPopup(): void {
        if (!this.visible && !this.popupService.checkBlock()) {
            this.setVisible(true);
            this.popupService.popupOpened(this);
            this.opened.next();
        }
    }

    closePopup(): void {
        if (this.visible) {
            this.setVisible(false);
            this.popupService.popupClosed();
            this.closed.next();
        }
    }

    @HostListener("document:keydown", ["$event"])
    processGlobalHotkeys(event: KeyboardEvent): void {
        if (event["_stopPropagation"]) {
            return;
        }
        if (event.key === "Escape" || event.code === "27") {
            if (this.visible) {
                this.closePopup();
                event["_stopPropagation"] = true;
                event.stopPropagation();
                event.stopImmediatePropagation();
                event.preventDefault();
            }
        }
    }

    @HostListener("mousedown", ["$event", "$event.target"])
    mouseDownEvent(event: MouseEvent, targetElement: EventTarget): void {
        if (targetElement["className"].includes("move-handle") || targetElement["parentElement"]["className"].includes("move-handle")) {
            this.dragging = true;
            const position = this.elementRef.nativeElement.getBoundingClientRect();
            this.dragStartX = event.x - position.left;
            this.dragStartY = event.y - position.top;
        }
    }

    @HostListener("window:mousemove", ["$event"])
    mouseMoveEvent(event: MouseEvent): void {
        if (this.dragging) {
            this.setPosition(event.x - this.dragStartX, event.y - this.dragStartY);
        }
    }

    @HostListener("mouseup", ["$event"])
    mouseUpEvent(event: MouseEvent): void {
        this.dragging = false;
    }

    @HostListener("touchstart", ["$event", "$event.target"])
    touchStartEvent(event: UIEvent, targetElement: EventTarget): void {
        if (event["changedTouches"] && event["changedTouches"].length && (targetElement["className"].includes("move-handle") || targetElement["parentElement"]["className"].includes("move-handle"))) {
            this.dragging = true;
            const touchobj = event["changedTouches"][0];
            const position = this.elementRef.nativeElement.getBoundingClientRect();
            this.dragStartX = touchobj.pageX - position.left;
            this.dragStartY = touchobj.pageY - position.top;
        }
    }

    @HostListener("touchmove", ["$event", "$event.target"])
    touchMoveEvent(event: UIEvent, targetElement: EventTarget): void {
        if (event["changedTouches"] && event["changedTouches"].length && this.dragging) {
            const touchobj = event["changedTouches"][0];
            this.setPosition(touchobj.pageX - this.dragStartX, touchobj.pageY - this.dragStartY);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    @HostListener("touchend", ["$event", "$event.target"])
    touchEndEvent(event: UIEvent, targetElement: EventTarget): void {
        this.dragging = false;
    }

    setVisible(visible: boolean): void {
        this.visible = visible;
        if (visible) {
            this.renderer.setStyle(this.elementRef.nativeElement, "display", "block");
        } else {
            this.renderer.setStyle(this.elementRef.nativeElement, "display", "none");
        }
    }

    setPosition(left: number, top: number): void {
        const width = this.elementRef.nativeElement.offsetWidth;
        const height = this.elementRef.nativeElement.offsetHeight;
        top = Math.max(66 + PADDING, top); // 66 for navbar
        left = Math.max(PADDING, left);
        top = Math.min(window.innerHeight - height - PADDING, top);
        left = Math.min(window.innerWidth - width - PADDING, left);

        this.renderer.setStyle(this.elementRef.nativeElement, "left", left + "px");
        this.renderer.setStyle(this.elementRef.nativeElement, "top", top + "px");
    }

    getPosition(): { left: number; top: number } {
        return {
            left: this.elementRef.nativeElement.offsetLeft,
            top: this.elementRef.nativeElement.offsetTop
        };
    }
}
