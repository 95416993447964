<card class="market-make-sales-card"
    cardTitle="Year Over Year Market Make {{showVolume ? 'Sales' : 'Shares'}} for {{dmaNames}}"
    (cardOpen)="updateOpen($event)" [isOpen]="cardOpenState" [loading]="loading" id="market-make-sales-card"
    [paddingless]="true">
        <date-shift (dateRange)="updateDate($event)" [reversed]="true" [limitToDisplay]="limitToDisplay"
            [stepInterval]="stepInterval" [rangeType]="dateRangeType">
        </date-shift>
    <ng-container *ngIf="!loading">
        <div class="header-content" [class.floating]="scrolled">
            <table class="sales-data-table">
                <thead>
                    <tr>
                        <th rowspan="2" class="width-definition row-header bottom-border padding-top">
                            <span class="row-sorter" (click)="updateSort('makes')">
                                Makes<span class="sort-icon-holder" *ngIf="sortKey === 'makes'">
                                    <span class="sort-icon {{sortDirection}}"></span>
                                </span>
                            </span>
                        </th>
                        <th *ngFor="let date of displayYears" colspan="2"
                            class="width-definition left-border padding-top">
                            <span class="row-sorter" (click)="updateSort(date)">
                                {{formatDate(date)}}<span class="sort-icon-holder" *ngIf="sortKey === date">
                                    <span class="sort-icon {{sortDirection}}"></span>
                                </span>
                            </span>
                        </th>
                    </tr>
                    <tr>
                        <ng-container *ngFor="let year of displayYears">
                            <th class="data bottom-border left-border" *ngIf="showVolume" >
                                Sales
                            </th>
                            <th class="data bottom-border" attr.colspan="{{columnSpan}}" [ngClass]="{
                            'left-border': !showVolume,
                            'center-cell': !showVolume}">
                                Share
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr class="total-row" *ngIf="showVolume">
                        <td class="row-header bottom-border">Total</td>
                        <ng-container *ngFor="let year of displayYears">
                            <td class="data left-border bottom-border">{{valueFor(salesData, year) | DisplayFig}}</td>
                            <td class="data bottom-border">100%</td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="scroll-content" (scroll)="dataTableScrolled($event)">
            <table class="sales-data-table">
                <thead>
                    <tr class="definition">
                        <th class="width-definition"></th>
                        <th class="width-definition left-border" colspan="2"></th>
                        <th class="width-definition left-border" colspan="2"></th>
                        <th class="width-definition left-border" colspan="2"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let make of salesData.makes">
                        <tr [class.expanded]="make.make_description === selectedMake" class="clickable"
                            (click)="toggleSelectedMake(make.make_description)">
                            <td class="row-header">
                                <span class="arrow"></span>
                                {{make.make_description}}
                            </td>
                            <ng-container *ngFor="let date of displayYears">
                                <td class="data left-border" *ngIf="showVolume">
                                    {{valueFor(make, date) | DisplayFig}}
                                </td>
                                <td class="data" attr.colspan="{{columnSpan}}" [ngClass]="{
                                'left-border': !showVolume,
                                'center-cell': !showVolume}">
                                    {{calculateShares(make, salesData, date) | SigFig | DisplayPercentage }}</td>
                            </ng-container>
                        </tr>
                        <tr *ngIf="make.make_description === selectedMake" class="sub-row clickable"
                            (click)="toggleSelectedMake(make.make_description)">
                            <td class="row-sub-header">Market Difference to Prior Year</td>
                            <ng-container *ngFor="let date of displayYears; let i = index">
                                <ng-container *ngIf="date > minStartDate">
                                    <td class="data difference-data left-border"
                                        [ngClass]="compareSalesToPrevious(make, displayYears, i)" *ngIf="showVolume">
                                        <span>{{calculateDifference(make, displayYears, i) | DisplayFig}}<span
                                                class="direction-icon-holder"><span
                                                    class="direction-icon"></span></span>
                                        </span>
                                    </td>
                                    <td class="data difference-data"
                                        [ngClass]="compareSharesToPrevious(make, salesData, displayYears, i)"
                                        attr.colspan="{{columnSpan}}">
                                        <span>
                                            {{calculateSharesDifference(make, salesData, displayYears, i) |
                                            SigFig}}%
                                            <span
                                                class="
                                        direction-icon-holder"><span class="direction-icon"></span></span>
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="date <= minStartDate">
                                    <td class="data difference-data left-border" *ngIf="showVolume">-</td>
                                    <td class="data difference-data" attr.colspan="{{columnSpan}}" [ngClass]="{
                                        'left-border': !showVolume,
                                        'center-cell': !showVolume}">-</td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr *ngIf="make.make_description === selectedMake" class="sub-row clickable"
                            (click)="toggleSelectedMake(make.make_description)">
                            <td class="row-sub-header" *ngIf="showVolume else justSales">National Sales and Share</td>
                            <ng-template #justSales>
                                <td class="row-sub-header" *ngIf="!showVolume">National Share</td>
                            </ng-template>
                            <ng-container *ngFor="let date of displayYears">
                                <td class="data left-border" *ngIf="showVolume">
                                    {{nationalValueFor(make, date) | DisplayFig}}
                                </td>
                                <td class="data" attr.colspan="{{columnSpan}}" [ngClass]="{
                                'left-border': !showVolume,
                                'center-cell': !showVolume}">
                                    {{calculateNationalShares(make, salesData, date) | SigFig }}%</td>
                            </ng-container>
                        </tr>
                        <tr *ngIf="make.make_description === selectedMake" class="sub-row clickable"
                            (click)="toggleSelectedMake(make.make_description)">
                            <td class="row-sub-header">National Difference to Prior Year</td>
                            <ng-container *ngFor="let date of displayYears; let i = index">
                                <ng-container *ngIf="date > minStartDate">
                                    <td class="data difference-data left-border"
                                        [ngClass]="compareNationalSalesToPrevious(make, displayYears, i)"
                                        *ngIf="showVolume">
                                        <span>{{calculateNationalDifference(make, displayYears, i) | DisplayFig}}<span
                                                class="direction-icon-holder"><span
                                                    class="direction-icon"></span></span>
                                        </span>
                                    </td>
                                    <td class="data difference-data"
                                        [ngClass]="compareNationalSharesToPrevious(make, salesData, displayYears, i)"
                                        attr.colspan="{{columnSpan}}">
                                        <span>{{calculateNationalSharesDifference(make, salesData, displayYears, i) |
                                            SigFig}}%<span
                                                class="
                                        direction-icon-holder"><span class="direction-icon"></span></span>
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="date <= minStartDate">
                                    <td class="data difference-data left-border" *ngIf="showVolume">-</td>
                                    <td class="data difference-data" attr.colspan="{{columnSpan}}" [ngClass]="{
                                        'left-border': !showVolume,
                                        'center-cell': !showVolume}">-</td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>
</card>
