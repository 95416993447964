import { TitleCasePipe } from "@angular/common";
import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, } from "@angular/core";
import { AbstractSalesDataBase } from "app/core/base/services/sales-data.abstract";
import { FilterName } from "app/core/models/filter-name.enum";
import { CognitoService } from "app/core/services/cognito.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { NextButtonService } from "app/core/services/next-button.service";
import { without } from "lodash";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { FilterBaseService } from "../../base/services/filter-base.service";

export class BaseChipValue {
    name: string;
    id?: number;
    isSelected: boolean;
    volume: number;
    index: number;
    dma?: number;
}

export class ChipCount {
    filterName: FilterName;
    chipCount: number;
}

export enum FilterSort {
    Alphabetical = "alphabetical",
    Sales = "sales"
}

export enum TopNContext {
    Market = "market",
    Spotlight = "spotlight"
}

@Component({
    selector: "selection-section",
    templateUrl: "./selection-section.component.html",
    styleUrls: ["../filter/filter-overlay-styles.scss", "./selection-section.component.scss"],
})
export class BaseSelectionSectionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() dataService: AbstractSalesDataBase;
    @Input() filterService: FilterBaseService;

    @Input() filterName: FilterName;

    @Input() titleTemplate: TemplateRef<any>;
    @Input() descriptionTemplate: TemplateRef<any>;
    @Input() footerTemplate: TemplateRef<any>;
    @Input() doneTemplate: TemplateRef<any>;
    @Input() noResultsTemplate: TemplateRef<any>;
    @Input() excludeSelections: (string | number)[] = [];
    @Input() excludedFilters: string[] = [];
    @Input() filterOverrides: { [key: string]: any } = {};
    @Input() showSortLabel = true;
    TopNContext = TopNContext;

    // Configurable states selection states.
    @Input() clearButtonDisabled = false;
    @Input() topXButtonDisabled = false;
    @Input() searchButtonDisabled = false;
    @Input() sortAreaDisabled = false;
    @Input() radiusRingButtonDisabled = false;
    @Input() selectionsLimit = 0; // limit number of selected selections, 0 = unlimited

    @Input() excludeSpotlightedDealer = true;

    prettyFilterName: string | FilterName = "";
    titleCasePipe = new TitleCasePipe();

    @Output() chipCountChanged = new EventEmitter<ChipCount>();
    FilterName = FilterName; // for proper template binding

    // All chip options.
    chipValues: BaseChipValue[] = [];
    filterState: any = []; // current filter, not current selections
    loadedOptions = false;

    spotlightDealerId: number | null;

    topNContext: TopNContext = TopNContext.Market;
    // All top X requests in the PPT flow are based on the spotlit dealer, not market
    topNForMarketDisabled = false;

    // A subset of chips to render, usually filtered by search.
    chipsToRender: BaseChipValue[] = [];

    // A list of checked chips to render at top of page
    selectedOptions: BaseChipValue[];
    selector: string;

    @Input() searchOpenState = false;
    searchTerm = "";

    sortOptionOne = FilterSort.Sales;
    sortOptionTwo = FilterSort.Alphabetical;
    sortSelection = FilterSort.Sales;

    showRing = false;

    selectedSelections = new BehaviorSubject([]);

    protected filterSearchSubscription: Subscription;
    protected filterServiceSubscription: Subscription;
    protected filterServiceUpdateSubscription: Subscription;

    constructor(
        protected dmaDataService: DmaDataService,
        public clickedButton: NextButtonService,
        public cognitoService: CognitoService,
    ) { }

    protected isDestroyed = false;

    toggleSearch(): void {
        this.searchOpenState = !this.searchOpenState;
        if (!this.searchOpenState) {
            this.searchTerm = "";
            this.chipsToRender = this.chipValues;
        }
    }

    toggleSort(value: boolean): void {
        //const isAlphabetical = FilterSort.Alphabetical === this.sortSelection;
        // Comment the above out because the event value is now sent from toggle.button.component
        if (!value) {
            this.sortSelection = FilterSort.Sales;

            // sort happens in place, so pass in a copy of the array in order to update the entire collection at once
            this.chipsToRender = this.sortByVolume([...this.chipsToRender]);
        } else {
            this.sortSelection = FilterSort.Alphabetical;

            this.chipsToRender = this.sortByName([...this.chipsToRender]);
        }
    }

    sortByVolume(chips: BaseChipValue[]): BaseChipValue[] {
        return chips.sort((a, b) => (a["volume"] > b["volume"] ? -1 : a["volume"] === b["volume"] ? 0 : 1));
    }
    sortByName(chips: BaseChipValue[]): BaseChipValue[] {
        return chips.sort((a, b) => (a["name"].toLowerCase() > b["name"].toLowerCase() ? 1 : a["name"].toLowerCase() === b["name"].toLowerCase() ? 0 : -1));
    }

    selectNone(): void {
        for (let i = 0; i < this.selectedOptions.length; i++) {
            this.selectedOptions[i].isSelected = false;
        }
        this.selectedOptions = [];
        this.setExportedSelections();
    }

    selectTopN(context: TopNContext, excludeBuyerDmaCode: boolean = false): void {
        this.topNContext = context;
        this.loadedOptions = false;
        let override = null;
        if (context === TopNContext.Spotlight) {
            override = this.spotlightOverride();
        } else if (this.filterName === FilterName.dealers) {
            override = { not_dealers: [this.spotlightDealerId] };
        }

        let exclusions = [];
        if (excludeBuyerDmaCode) {
            exclusions.push(FilterName.buyer_dma_code);
        }
        let zones: string[] = this.filterService.getFilterValue(FilterName.zones);
        if(zones?.length>0 ){
            override = { ...override, ...this.filterOverrides, zones };
        }else{
            override = { ...override, ...this.filterOverrides };
        }

        this.dataService.getTopOptions(this.filterName.toString(), null, override, exclusions)
            .subscribe((topNOptions: string[] | number[]) => {
                if (this.isDestroyed) {
                    return;
                }
                this.selectedOptions = this.setSelectedChips(topNOptions);
                this.loadedOptions = true;
                this.setExportedSelections();

                let selectedOptionsStringArray = this.selectedOptions.map(v => v);
                this.cognitoService.setHeapReportParams(this.filterName.toString(), selectedOptionsStringArray);
            });
    }

    spotlightOverride(): object {
        if (this.spotlightDealerId) {
            return { dealers: [this.spotlightDealerId] };
        }
        return null;
    }

    toggleRing(): void {
        this.showRing = !this.showRing;
        this.filterService.setFilterValue(FilterName.show_ring, this.showRing);
    }

    setSelectedChips(optionsToChange: any): BaseChipValue[] {
        const topNValues = [];
        const topNcount = optionsToChange.length;
        let found = 0;
        // Once all options are found, skip checking the rest.
        for (let i = 0; i < this.chipValues.length; i++) {
            const chip = this.chipValues[i];
            if (found < topNcount && optionsToChange.includes(chip[this.selector])) {
                chip.isSelected = true;
                topNValues.push(chip);
                found++;
            } else {
                chip.isSelected = false;
            }
        }
        return topNValues;
    }

    chipChanged(value: BaseChipValue): void {
        value.isSelected = !value.isSelected;
        this.clickedButton.sendSelectionStatus(value);
        if (value.isSelected && this.selectionsLimit !== 0 && this.selectedOptions.length >= this.selectionsLimit) {
            this.selectedOptions.pop().isSelected = false; // if selected options is greater than the limit, remove the last option and unselect
        }
        if (value.isSelected) {
            this.selectedOptions.push(value);
        } else {
            this.selectedOptions = this.selectedOptions.filter(e => e[this.selector] !== value[this.selector]);
        }
        let activeSelection: string = this.filterService.getFilterValue(FilterName.active_selection);
        this.cognitoService.setHeapReportParams(activeSelection, this.selectedOptions);
        this.setExportedSelections();
    }

    ngOnInit(): void {
        this.setupChips();
        this.filtersUpdated();
        this.filterServiceUpdateSubscription = this.filterService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.dmaDataService.loadAllDmas();
    }

    filtersUpdated(): void {
        this.spotlightDealerId = this.filterService.getFilterValue(FilterName.spotlight_dealer);
        this.showRing = this.filterService.getFilterValue<boolean>(FilterName.show_ring);
        if (this.filterName) {
            this.prettyFilterName = this.beautifyFilterName(this.filterName.toString());
        }
        // this.dmaNames = this.dmaDataService.getDmaNames(dmaVal).sort().join(", ");

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (("filterName") in changes && changes.filterName.currentValue) {
            this.setupChips();
        }
    }

    ngOnDestroy(): void {
        if (this.filterSearchSubscription) {
            this.filterSearchSubscription.unsubscribe();
            this.filterSearchSubscription = undefined;
        }
        if (this.filterServiceSubscription) {
            this.filterServiceSubscription.unsubscribe();
            this.filterServiceSubscription = undefined;
        }
        if (this.filterServiceUpdateSubscription) {
            this.filterServiceUpdateSubscription.unsubscribe();
            this.filterServiceUpdateSubscription = undefined;
        }
        this.isDestroyed = true;
    }

    setupChips(): void {
        if (this.filterName) {
            this.loadedOptions = false;
            if (this.filterServiceSubscription) {
                this.filterServiceSubscription.unsubscribe();
            }

            if (!this.excludeSpotlightedDealer) {
                // if excludeSpotlightedDealer is set to false, then spotlight dealer will not be removed from retrieved filter names
                this.excludedFilters.push("not_dealers");
            }
            if (!this.filterService.getFilterValue(FilterName.show_ring)) {
                this.excludedFilters.push("radius_ring");
            }
            this.selectedSelections.next(this.filterState);
            if (this.filterName === FilterName.dates) {
                this.loadedOptions = true;
                this.selectedSelections.next(this.filterState);
            } else if (this.filterName === FilterName.use_sales_data) {
                this.loadedOptions = true;
                this.selectedSelections.next(this.filterState);
            } else {
                this.filterServiceSubscription = this.filterService.getFilterOptions(this.filterName, [], this.excludedFilters)
                    .pipe(take(1)).subscribe((options: (string | object)[]) => {

                        this.selector = (options[0] && options[0]["id"]) ? "id" : "name";
                        if (this.excludeSelections.length) {
                            options = options.filter(option => !this.excludeSelections.includes(option[this.selector]));
                        }
                        this.chipCountChanged.emit({ filterName: this.filterName, chipCount: options.length });
                        if (Array.isArray(this.filterState)){
                            this.chipValues = this.createChipValues(options);
                        }
                        this.loadedOptions = true;
                        this.onSearchInputChanged(this.searchTerm);
                        this.setExportedSelections();
                    });
            }
        }
    }

    setupDates(): void {
        this.loadedOptions = true;
        this.selectedSelections.next(this.filterState);
    }

    protected createChipValues(rawChips: any[]): BaseChipValue[] {
        this.selectedOptions = [];
        const ret = [];

        for (let i = 0; i < rawChips.length; i++) {
            let c: BaseChipValue = null;
            if (rawChips[i]["id"]) {
                c = {
                    name: rawChips[i].name,
                    id: rawChips[i].id,
                    volume: rawChips[i].volume,
                    isSelected: this.filterState.includes(rawChips[i]["id"]),
                    dma: rawChips[i].dma_code,
                    index: i + 1
                };
            } else {
                const filterName = this.getKeyName(rawChips[i]);

                c = {
                    name: rawChips[i][filterName],
                    volume: rawChips[i].volume,
                    isSelected: this.filterState.includes(rawChips[i][filterName]),
                    index: i + 1
                };
            }
            if (c.isSelected) {
                this.selectedOptions.push(c);
            }
            ret.push(c);
        }
        return ret;
    }

    protected getKeyName(chip: BaseChipValue): string {
        const keys = Object.keys(chip);
        // gets the appropriate key for the filterName (i.e. "make" instead of "makes")
        const nonVolumeKeys = without(keys, "volume");
        const filterName = nonVolumeKeys[0];

        return filterName;
    }

    protected getSearchedChipValues(searchTerm: string): BaseChipValue[] {
        if (searchTerm && searchTerm.length) {
            const phrase = searchTerm.toLowerCase();
            const results = this.chipValues.filter(d => d.name.toLowerCase().match(phrase));
            return results;
        } else {
            return this.chipValues.slice();
        }
    }

    protected beautifyFilterName(filterName: string): string {
        let prettyName = "";

        if (filterName === FilterName.ihs_segments.toString()) {
            prettyName = filterName.replace("ihs_segments", "Segments");
        } else {
            prettyName = this.titleCasePipe.transform(filterName);
        }

        return prettyName;
    }
    protected setExportedSelections(): void {
        if (!this.loadedOptions || !this.selectedOptions) { // if selections panel is closed before selections are loaded, dont export
            return;
        }

        this.selectedSelections.next(this.selectedOptions.sort((a, b) => a.index - b.index).map(option => option[this.selector]));
    }

    onSearchInputChanged(input: string): void {
        // Prevent this function from executing if this component was destroyed prior to data return.
        this.searchTerm = input;
        if (this.isDestroyed) {
            return;
        }
        this.chipsToRender = this.getSearchedChipValues(input);
    }

    @HostListener("document:keyup", ["$event"])
    processGlobalHotkeys(event: KeyboardEvent): void {
        if (event.key === "Escape" || event.code === "27") {
            if (this.searchOpenState) {
                this.searchOpenState = false;
                this.onSearchInputChanged("");
            }
        }
    }
}
