<card
    id="dealer-opportunity-in-ad-zone"
    [cardTitle]="title"
    [description]="description"
    [disabled]="isDisabled"
    [isOpen]="isOpen"
    [loading]="isLoading"
    (open)="cardOpened()"
    (close)="cardClosed()">
    <dropdown
        [items]="dropdown"
        [selected]="defaultDropDown"
        (change)="dropdownChanged($event)"
        leadingText="Ad Zone Displayed:"></dropdown>
    <div class="card-subtitle">
        <h2 class="title">{{spotlightDealer}} Opportunity in the Top Ad Zone</h2>

        <ng-container [ngSwitch]="modelExplicitlySelected">
            <p class="hint">
                <ng-template [ngSwitchCase]="true">
                    Based on the models you selected, {{model}} is the top performing model for the
                    spotlighted dealer in the {{zoneName}} Ad Zone.
                </ng-template>
                <ng-template [ngSwitchCase]="false">
                    Based on the Ad Zone being displayed, {{model}} is the top performing model for the
                    spotlighted dealer.
                </ng-template>
            </p>
        </ng-container>
    </div>
    <div class="dealer-opportunity-charts">
        <div class="chart">
            <div class="chart-header">
                <p>
                    The dealer has a {{dealerShareOfModel}} share of {{model}} sales in this zone.
                </p>
                <h3>Dealer's Share of {{model}} sales</h3>
            </div>
            <chart
                [dataToRender]="dealerShareOfModelData"
                [labelsToRender]="[labels[0]]"
                [colors]="dealerShareOfModelColors"
                [chartType]="'bar'"
                [chartOptions]="chartOptions"
                [disableLabelInteractions]="true"
                [percentLabel]="true"
                [maxYScale]="true"></chart>
        </div>
        <div class="chart">
            <div class="chart-header">
                <p>
                    The {{model}} accounts for {{modelShareOfSegment}} of all {{segment}} sales in this zone.
                </p>
                <h3>{{model}} Share of {{segment}} sales</h3>
            </div>
            <chart
                [dataToRender]="modelShareOfSegmentData"
                [labelsToRender]="[labels[1]]"
                [colors]="modelShareOfSegmentColors"
                [chartType]="'bar'"
                [chartOptions]="chartOptions"
                [disableLabelInteractions]="true"
                [percentLabel]="true"
                [maxYScale]="true"></chart>
        </div>
        <div class="chart">
            <div class="chart-header">
                <p>
                    The dealer accounts for {{dealerShareOfSegment}} of all {{segment}} sales in this zone.
                </p>
                <h3>Dealer's Share of {{segment}} sales</h3>
            </div>
            <chart
                [dataToRender]="dealerShareOfSegmentData"
                [labelsToRender]="[labels[2]]"
                [colors]="dealerShareOfSegmentColors"
                [chartType]="'bar'"
                [chartOptions]="chartOptions"
                [disableLabelInteractions]="true"
                [percentLabel]="true"
                [maxYScale]="true"></chart>
        </div>
    </div>

</card>
