<div class="change-password">
    <div class="password-policy" id="{{policy.id}}-change-password" *ngFor="let policy of passwordPolicies; let first = first;"
        [ngClass]="checkPasswordPolicy(newPasswordValue, policy)" [class.first-item]="first">
        <div class="policy-title">{{policy.title}}</div>
        <div class="policy-icon"></div>
    </div>
    <div class="password-policy last-item" id="password-match-change-password" [ngClass]="checkPasswordMatch(newPasswordValue, confirmNewPasswordValue)">
        <div class="policy-title">Passwords Match</div>
        <div class="policy-icon"></div>
    </div>
    <div class="input-box">
        <input class="text-input" type="password" id="oldpassword" placeholder="Old Password" [(ngModel)]="oldPasswordValue"
            [class.error]="oldPasswordError.length" />
        <div class="error-message" id="oldpassworderror">{{oldPasswordError}}</div>
    </div>
    <div class="input-box">
        <input class="text-input" type="password" id="newpassword" placeholder="New Password" [(ngModel)]="newPasswordValue"
            [class.error]="newPasswordError.length" />
        <div class="error-message" id="newpassworderror">{{newPasswordError}}</div>
    </div>
    <div class="input-box">
        <input class="text-input" type="password" id="confirmnewpassword" placeholder="Confirm New Password"
            [(ngModel)]="confirmNewPasswordValue" [class.error]="confirmNewPasswordError.length" />
        <div class="error-message" id="confirmnewpassworderror">{{confirmNewPasswordError}}</div>
    </div>
    <button type="submit" id="submitnewpassword" class="submit-change-password-button" [class.disabled]="!verifyPasswordPolicies(newPasswordValue, confirmNewPasswordValue)"
        (click)="submit()">Submit</button>
    <button id="cancelnewpassword" class="cancel-change-password-button" (click)="cancel()">Cancel</button>
    <ng-container *ngIf="success">Password was successfully changed!</ng-container>
</div>
