import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@at/core";

import { AdminPageComponent } from "./pages/admin-page.component";

const routes: Routes = [
    { path: "", component: AdminPageComponent, canActivate: [AuthGuard] }];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
