import { Injectable, OnDestroy } from "@angular/core";
import { isEqual } from "lodash";
import { Observable, Subscription } from "rxjs";

import { FilterName } from "../models/filter-name.enum";
import { SalesRangeFilterModel } from "../models/sales-range-filter.model";
import { FilterStateService } from "./filter-state.service";
import { SalesDataService } from "./sales-data.service";
import { UserCookieService } from "./user-cookie.service";

@Injectable()
export class SalesRangeSaveService implements OnDestroy {
    private filterStateSubscription: Subscription;
    private salesRangeDealerSub: Subscription;
    constructor(
        private filterStateService: FilterStateService,
        private salesDataService: SalesDataService,
        private userCookieService: UserCookieService
    ) {
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
    }

    ngOnDestroy(): void {
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
        if (this.salesRangeDealerSub) {
            this.salesRangeDealerSub.unsubscribe();
        }
    }

    public save(min: any, max: any, filterName: FilterName): void {
        this.saveFilterValues(min, max, filterName);
    }

    private saveFilterValues(min: any, max: any, filterName: FilterName): void {
        const currentSalesRangeFilterValue = this.getCurrentSalesRangeFilterValue(filterName);
        const newSalesRangeFilterValue = this.buildFilterValue(min, max);
        if (isEqual(currentSalesRangeFilterValue, newSalesRangeFilterValue)) {
            return;
        }

        this.saveValue(newSalesRangeFilterValue, filterName);
    }

    private saveValue(newSalesRangeFilterValue: SalesRangeFilterModel, filterName: FilterName): void {
        const found = [];
        // If the user clears the values or enters 0, clear the filter from
        // the filter state service
        if (!newSalesRangeFilterValue || (!newSalesRangeFilterValue.min && !newSalesRangeFilterValue.max)) {
            this.clearSalesRangeDealers();
            this.filterStateService.setFilterValue(filterName, null);
        } else {
            // Otherwise, save the filter values..
            this.salesRangeDealerSub = this.updateSalesRangeDealers(newSalesRangeFilterValue)
                .subscribe(results => {
                    results.forEach(value => {
                        if (value.dealer_id && found.indexOf(value.dealer_id) === -1) {
                            found.push(value.dealer_id);
                        }
                    });

                    // Set the sales range filter dealers list before set the filter, since many things
                    // subscribe to the filter values changing
                    this.userCookieService.setSalesRangeFilteredDealers(found);
                    this.filterStateService.setFilterValue(filterName, JSON.stringify(newSalesRangeFilterValue));
                });
        }

    }

    private updateSalesRangeDealers(newSalesRangeFilterValue: SalesRangeFilterModel): Observable<any[]> {
        const filterOverrides = this.filterStateService.getCurrentApiFilters();
        const options = this.salesDataService.createDefaultSalesQueryOptions();
        options.group = ["dealer_id"];
        options.filterType = "filtered";

        // must pass in `volume` for getting sales so included it as an override here
        filterOverrides["volume"] = JSON.stringify(newSalesRangeFilterValue);
        return this.salesDataService.getSalesDetails(null, options, filterOverrides);
    }

    clearSalesRangeDealers(): void {
        this.userCookieService.setSalesRangeFilteredDealers(null);
    }

    private buildFilterValue(min: any, max: any): SalesRangeFilterModel {
        return {
            min,
            max
        };
    }

    private getCurrentSalesRangeFilterValue(filterName: FilterName): SalesRangeFilterModel {
        const json: string = this.filterStateService.getFilterValue(filterName);
        if (json) {
            return JSON.parse(json);
        }
        return { min: null, max: null };
    }

    private filtersUpdated(which: string[]): void {
        if (which.includes(FilterName.dateRangeType.toString()) ||
            which.includes(FilterName.new_used_flag.toString()) ||
            which.includes(FilterName.buyer_dma_code.toString())) {
            this.triggerSalesRangeDealersUpdate();
        }
    }

    /**
     * Using the current sales range volume filter, get a new list of dealers with
     * the other filters (i.e. date range type or new/used) which might trigger a new list to be generated.
     */
    triggerSalesRangeDealersUpdate(): void {
        const currentSalesRangeVolume = this.getCurrentSalesRangeFilterValue(FilterName.sales_range);

        this.saveValue(currentSalesRangeVolume, FilterName.sales_range);
    }
}
