import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { CognitoService } from "./cognito.service";

@Injectable()
export class UnAuthGuard implements CanActivate {

    constructor(
        private cognitoService: CognitoService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (this.cognitoService.isSignedIn()) {
            if (this.cognitoService.authenticated.value) {
                this.router.navigateByUrl("/");
                return Promise.resolve(false);
            } else {
                return Promise.resolve(true);
            }
        } else {
            return Promise.resolve(true);
        }
    }
}
