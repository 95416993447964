import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { GroupBySales } from "app/core/models/groupby-sales.model";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetadataService } from "app/core/services/metadata.service";
import { SalesDataService, SalesQueryOptions } from "app/core/services/sales-data.service";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { forkJoin, Observable, Subscription } from "rxjs";
import { skipWhile } from "rxjs/operators";
import { FeatureFlagService } from "@at/core";


@Component({
    selector: "dealers-sales-comparison",
    templateUrl: "./dealers-sales-comparison.component.html",
    styleUrls: ["./dealers-sales-comparison.component.scss"],
})
export class DealersSalesComparisonComponent implements OnInit, OnDestroy {
    @Input() dealers: Dealer[];

    dealersYearlySales: GroupBySales[] = [];
    dealersYearlySalesDisplay: GroupBySales[] = [];
    dealersToLoad = [];
    dates: string[] = [];
    showVolume = false;
    downloadable = false;
    maxDate: any;
    minDate: any;
    monthsFirstMinDate: any = undefined;
    useSalesData: boolean;
    dateRanges: string[];

    private filterStateSubscription: Subscription;
    private metadataSubscription: Subscription;

    keyBase: "sales" | "shares" = "sales";
    private dateRangeType = DateRangeTypes.YEARS;

    displayDates: string[];
    protected stream: Subscription;
    baseDealerIds: (string | number)[];
    private groupBySalesSubscription: Subscription;
    canViewDownloadDealerSalesComparison: boolean;


    constructor(
        private filterStateService: FilterStateService,
        private salesDataService: SalesDataService,
        private dealerDataService: DealerDataService,
        private metadataService: MetadataService,
        private featureFlagService: FeatureFlagService,
    ) {
        this.canViewDownload();
    }

    async canViewDownload(): Promise<void> {
        this.canViewDownloadDealerSalesComparison = await this.featureFlagService.viewDownloadDealerSalesComparison();
    }

    ngOnInit(): void {
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.updatedFilters.bind(this));
        this.updatedFilters([FilterName.show_volume.toString(), FilterName.dateRangeType.toString()]); // initializes showVolume & dateRangeType variables
        this.metadataSubscription = this.metadataService.metadata.pipe(skipWhile(i => !i)).subscribe(this.updateDate.bind(this));
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
    }

    ngOnDestroy(): void {
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
        if (this.metadataSubscription) {
            this.metadataSubscription.unsubscribe();
        }
        if (this.groupBySalesSubscription) {
            this.groupBySalesSubscription.unsubscribe();
        }
    }

    updateDate(): void {
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        const date = this.useSalesData ? this.metadataService.metadata.getValue().maxSalesDate : this.metadataService.metadata.getValue().maxDate;

        let format = "YYYYMM";
        if (!moment(date, format).isValid()) {
            format = "YYYYMMDD";
        }
        this.maxDate = moment(date, format).format("YYYYMM");
        // last year of data is not displayed, used for year over year comparison.
        if (this.useSalesData) {
            this.minDate = moment(date, format).subtract(3, "years").add(1, "month").format("YYYYMM");
        } else {
            this.minDate = moment(date, format).subtract(4, "years").format("YYYYMM");
        }
        this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
    }

    updatedFilters(changes: string[]): void {
        if (changes.includes(FilterName.show_volume.toString())) {
            this.showVolume = this.filterStateService.getFilterValue<boolean>(FilterName.show_volume);
            this.keyBase = this.showVolume ? "sales" : "shares";
        }
        if (changes.includes(FilterName.dateRangeType.toString())) {
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
            this.updateDate();
        }
        if (changes.includes(FilterName.use_sales_data.toString())) {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.updateDate();
        }
        // if the only filter that changed was show_volume, then don't empty out the cache
        if (!(changes.length === 1 && changes.includes(FilterName.show_volume.toString()))) {
            // reset values
            this.dealersYearlySales = [];
            this.dealersYearlySalesDisplay = [];
            this.loadDealersYearlySales();
        }
    }

    loadMoreDealersYearlySales(): void {
        // Here the this.dealersYearlySales has already been loaded, and we are adding the
        // next 5 rows for display.
        this.dealersYearlySalesDisplay = this.dealersYearlySalesDisplay.concat(this.dealersYearlySales.slice(this.dealersYearlySalesDisplay.length,this.dealersYearlySalesDisplay.length+(this.dealersYearlySales.length - this.dealersYearlySalesDisplay.length >= 5 ? 5 : this.dealersYearlySales.length - this.dealersYearlySalesDisplay.length)));
    }

    loadDealersYearlySales(): void {
        if (!this.dealers) {
            return;
        }
        this.dealersToLoad = new Array(this.dealers.length >= 5 ? 5 : this.dealers.length).fill(0); // array needed for loop in html
        const queryOptions: SalesQueryOptions = this.getSalesQueryOptions();

        // determine date groups
        const dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        const dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
        const dateGroupObservers: Observable<any>[] = [];

        if (dateRangeType === DateRangeTypes.MONTHS && dateRanges && dateRanges.length > 0) {
            this.maxDate = dateRanges[1];
            this.minDate = dateRanges[4];
            this.monthsFirstMinDate = dateRanges[0];
        } else if (dateRangeType === DateRangeTypes.MONTHS && (!dateRanges || (dateRanges && dateRanges.length === 0))) {
            return;
        }
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);

        const dateGroups = DateUtils.generateDateGroupings(dateRangeType, this.minDate, this.maxDate, 3, this.monthsFirstMinDate);
        this.displayDates = dateGroups.map(date => this.formatDate(`${date.start}-${date.end}`));

        if (this.dateRangeType === DateRangeTypes.YEARS) {
            queryOptions.sort[`t${this.maxDate.slice(0, 4)}_volume`] = "DESC";
        } else {
            queryOptions.sort[`t${this.maxDate.slice(0, 6)}_volume`] = "DESC";
        }

        // exclude spotlight dealer if 'Exclude Spotlight Dealer' toggle is enabled
        const spotlightDealer = this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer);
        const spotlightDealerIncluded = this.filterStateService.getFilterValue<string>(FilterName.include_spotlight);
        if (spotlightDealer && !spotlightDealerIncluded) {
            queryOptions.not_dealers = [spotlightDealer];
        }

        const dealersToSendToAPI = cloneDeep(<number[]>this.filterStateService.getFilterValue(FilterName.dealers));
        const dealerIds = this.dealers.map(d => d.dealer_id);
        // const salesReq = this.buildSalesRequest(queryOptions);
        const dealerReq = this.dealerDataService.getDealerDetails(dealerIds);
        // Only set as downloadable if there is:
        //    - At least 1 Dealers selected
        // OR - At least 1 Segments selected
        // OR - At least 1 Makes selected
        // OR - At least 1 Models selected
        const segments = this.filterStateService.getFilterValue<string>(FilterName.segments);
        const makes = this.filterStateService.getFilterValue<string>(FilterName.makes);
        const models = this.filterStateService.getFilterValue<string>(FilterName.models);
        const result_data_filtered = this.filterStateService.getFilterValue<string>(FilterName.result_data_filtered);

        if (result_data_filtered !== "all" && (!dealersToSendToAPI ? false : dealersToSendToAPI.length > 0 || segments.length > 0 || makes.length > 0 || models.length > 0)) {
            this.downloadable = true;
        } else {
            this.downloadable = false;
        }

        if (this.dateRangeType !== DateRangeTypes.MONTHS) {
            dateGroupObservers.push(this.salesDataService.getGroupBySalesDetails({ ...queryOptions }, dealerIds, FilterName.dealers));
            if (this.stream) {
                this.stream.unsubscribe();
                this.stream = undefined;
            }

            this.stream = forkJoin([...dateGroupObservers, dealerReq]).subscribe(responses => {
                let dealersResults;
                dealersResults = responses[0];

                this.dealersToLoad = [];
                this.dealersYearlySales = this.dealersYearlySales.concat(dealersResults);
                this.dealersYearlySalesDisplay = this.dealersYearlySales.slice(0,this.dealersYearlySales.length >= 5 ? 5 : this.dealersYearlySales.length);
                if (this.dealersYearlySales !== undefined && this.dealersYearlySales.length > 0) {
                    this.dates = Object.keys(this.dealersYearlySales[0].sales).reverse();
                }
            });
        } else {
            const baseGroup = dateGroups.shift();
            this.groupBySalesSubscription = this.salesDataService.getGroupBySalesDetails({ ...queryOptions, dateRangeStart: baseGroup.start, dateRangeEnd: baseGroup.end, dateRangeType: this.dateRangeType }, dealerIds, FilterName.dealers).subscribe(baseTimeGroup => {
                dealersToSendToAPI.push(...baseTimeGroup.map(dealers => dealers.groupById as number));
                if (this.stream) {
                    this.stream.unsubscribe();
                    this.stream = undefined;
                }
                dateGroups.forEach(group => {
                    queryOptions.sort = {};
                    queryOptions.sort[`t${group.end}_volume`] = "DESC";

                    dateGroupObservers.push(this.salesDataService.getGroupBySalesDetails({ ...queryOptions, offset: 0, dateRangeStart: group.start, dateRangeEnd: group.end, dateRangeType: this.dateRangeType }, dealerIds, FilterName.dealers));
                });
                this.stream = forkJoin([...dateGroupObservers, dealerReq]).subscribe(responses => {
                    let dealersResults;
                    if (this.dateRangeType !== DateRangeTypes.MONTHS) {
                        dealersResults = responses[0];
                    } else {
                        const source = responses.splice(0, 2);
                        source.unshift(baseTimeGroup);
                        const target = [];
                        const prop = "groupById";

                        // for each date group of data
                        source.forEach((dealers, index) => {
                            // array of dealers loop over them
                            dealers.forEach(dealerSale => {
                                const aggSales: any = { sales: {} };
                                const aggShares: any = { shares: {} };

                                const targetDealer = target.find(dealer => dealerSale[prop] === dealer[prop]);

                                const lastDate = Object.keys(dealerSale.sales).slice(-1)[0];

                                aggSales.sales[lastDate] = Object.values<number>(dealerSale.sales).reduce((acc, curr) => acc + curr);
                                aggShares.shares[lastDate] = Object.values<number>(dealerSale.shares).reduce((acc, curr) => acc + curr) / Object.keys(dealerSale.shares).length;


                                if (targetDealer) {
                                    targetDealer.sales = Object.assign(targetDealer.sales, aggSales.sales);
                                    targetDealer.shares = Object.assign(targetDealer.shares, aggShares.shares);
                                } else {
                                    dealerSale.sales = aggSales.sales;
                                    dealerSale.shares = aggShares.shares;
                                    target.push(dealerSale);
                                }
                            });

                        });
                        dealersResults = target.sort((a, b) => b.sales[this.maxDate] - a.sales[this.maxDate]);
                    }
                    this.dealersToLoad = [];
                    this.dealersYearlySales = this.dealersYearlySales.concat(dealersResults);

                    this.dealersYearlySalesDisplay = this.dealersYearlySales.slice(0,this.dealersYearlySales.length >= 5 ? 5 : this.dealersYearlySales.length);
                    if (this.dealersYearlySales.length > 0 && !this.dates.length) {
                        const values = this.dealersYearlySales[0][this.keyBase];
                        this.dates = [baseGroup.end, ...dateGroups.map(date => date.end)];
                    }
                });
            });
        }
    }


    getSalesQueryOptions(): SalesQueryOptions {
        const retVal = {
            sort: {},
            unknown: "false",
            dateRangeType: this.dateRangeType,
            group: ["dealer_id", "dma"]
        };

        return retVal;
    }

    allLoaded(): boolean {
        return this.dealersYearlySalesDisplay.length >= this.dealers.length;
    }

    protected valueFor(ds: GroupBySales, date: string): any {
        const selection = ds[this.keyBase][date];
        if (this.getType(selection) !== "number") {
            return selection;
        }
        if (Number.isNaN(selection)) {
            return 0;
        }
        if(this.keyBase==="sales"){
            return Number(selection);
        }else{
            return selection.toFixed(2);
        }
    }

    protected formatDate(date: string): string {
        // For Sales Data we use the regular formatDateRange function for DateRangeTypes.YEARS
        // which is the calendar selection because Dealer Sales Comparison shows YYYY for Calendar
        // when Registration data is selected instead of the 1/YY format used in the Unknown,
        // Market Compositionn and Dealers components.
        const tmpDateArray = date.split("-");
        if (this.useSalesData && this.dateRangeType !== DateRangeTypes.YEARS) {
            const minSalesDate = this.filterStateService.getFilterValue(FilterName.minSalesDate);
            const maxSalesDate = this.filterStateService.getFilterValue(FilterName.maxSalesDate);
            return DateUtils.popUpDateRange(this.useSalesData, this.dateRangeType !== DateRangeTypes.MONTHS ? tmpDateArray[1] : date, this.dateRangeType,
                moment(minSalesDate).toDate(), moment(maxSalesDate).toDate());
        }
        return DateUtils.formatDateRange(date, this.dateRangeType);
    }

    initiateExcelDownload(): void {
        if (this.downloadable) {
            this.dealerDataService.createDealerSalesComparisonWorkbook(this.dealersYearlySales, this.dates, this.showVolume, this.displayDates);
        }
    }

    protected getType(item: any): string {
        if (item === undefined) {
            return "null";
        } else if(Array.isArray(item)) {
            return "array";
        }
        return typeof item;
    }
}
