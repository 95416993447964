import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetadataService } from "app/core/services/metadata.service";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import {DetailsService} from "../../services/details.service";

export interface IDateRangeRadioButton {
    label: string;
    value: string;
}

export interface IDateRangeToggleState {
    name: FilterName;
    value: string;
}

export interface DropDownItem {
    value: any;
    label: string;
}

@Component({
    selector: "date-range-selector",
    templateUrl: "./date-range-selector.component.html",
    styleUrls: ["./date-range-selector.component.scss"]
}) export class DateRangeSelectorComponent implements OnInit,
    OnDestroy,
    OnChanges {
    @Input() filterName: FilterName;
    @Input() options: IDateRangeRadioButton[];
    @Input() radioGroupName: string;
    loading: boolean;
    DateRangeTypes = DateRangeTypes;

    defaultTextStart: DropDownItem = {
        value: null, label: "Select Date"
    };
    defaultTextEnd: DropDownItem = {
        value: null, label: ""
    };

    startDates: DropDownItem[] = [];
    endDates: DropDownItem[] = [];
    selectedOption: string;
    selectedDateRanges;
    selectedStartDate;
    selectedEndDate;
    firstDateAvailable;
    lastDateAvailable;
    firstSalesDateAvailable;
    lastSalesDateAvailable;
    monthGapCount;
    id = "";
    useSalesData: boolean;

    private filterStateServiceSubscription: Subscription;
    @Output() toggle: EventEmitter<IDateRangeToggleState> = new EventEmitter<IDateRangeToggleState>();

    constructor(
        private filterStateService: FilterStateService,
        private metadataService: MetadataService,
        private detailsService: DetailsService
    ) {
        this.selectedOption = this.filterStateService.getFilterValue(FilterName.dateRangeType);
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
    }

    ngOnInit(): void {
        if (this.radioGroupName) {
            this.id = this.radioGroupName.replace(/\s/g, "-").toLowerCase();
        }

        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.metadataService.metadata.pipe(skipWhile(i => !(i && i.maxDate && i.maxSalesDate)), take(1)).subscribe(meta => {
            this.firstSalesDateAvailable = moment(meta.maxSalesDate).subtract(6, "months");
            this.lastSalesDateAvailable = moment(meta.maxSalesDate);
            this.firstDateAvailable = moment(meta.minDate, "YYYYMM").add(2, "year");
            this.lastDateAvailable = moment(meta.maxDate, "YYYYMM");
            this.setUpOptions();
            this.filtersUpdated([this.filterName.toString()]);
        });
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.detailsService.getLoadingStatus().subscribe(loadingStatus => {
            this.loading = loadingStatus;
        });
    }

    setUpOptions() {
        const months = [];
        this.monthGapCount = this.useSalesData ? this.lastSalesDateAvailable.diff(this.firstSalesDateAvailable, "months")
            : this.lastDateAvailable.diff(this.firstDateAvailable, "months");

        // Get first calendar year of months via oldest data + 2 years.
        for (let i = 0; i <= this.monthGapCount; i++) {
            months.push(moment(this.useSalesData ? this.firstSalesDateAvailable : this.firstDateAvailable).add(i, "month"));
        }

        this.startDates = months.map((m) => ({
            value: m.format("YYYYMM"),
            label: m.format("MMMM, YYYY")
        }));
        this.startDates.unshift(this.defaultTextStart);
        this.determineSelectedDateRanges();
        this.selectedOption = this.filterStateService.getFilterValue(FilterName.dateRangeType);
        this.filterStateService.setFilterValue(FilterName.dates, {
            dateRangeType: this.selectedOption,
            dateRanges: this.selectedDateRanges,
            date_display: DateUtils.dateDisplay(
                [
                    this.selectedOption === DateRangeTypes.MONTHS ? this.selectedEndDate.value : this.startDates[this.startDates.length - 1].value,
                    this.selectedOption === DateRangeTypes.MONTHS ? this.selectedStartDate.value : this.startDates[this.startDates.length - 1].value
                ],
                this.selectedOption
            )
        });
    }

    ngOnDestroy(): void {
        this.filterStateServiceSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.filtersUpdated([this.filterName.toString()]);
    }

    startDropdownChanged(event: DropDownItem): void {
        // Update end months based on selected start date
        this.selectedStartDate = event;
        // if(event.value){
        if (!this.useSalesData) {
            const maxDate = moment(this.lastDateAvailable, "YYYYMM").subtract(6, "months");
            if (moment(this.selectedStartDate.value, "YYYYMM").isBefore(maxDate)) {
                this.filterStateService.setFilterValue(FilterName.disable_sales_data_menu, true);
            } else {
                this.filterStateService.setFilterValue(FilterName.disable_sales_data_menu, false);
            }
        }

        this.calculateEndDates(event.label);
        if (this.selectedEndDate.value) {
            // calculate range groups
            this.selectedEndDate = this.defaultTextStart;
        }
        this.endDates.unshift(this.defaultTextStart);
        // }else{
        // Registration Data Selected, Custom Selected, and the user selects option, "select a date"
        // this.filterStateService.setFilterValue(FilterName.disable_sales_data_menu, false);
        // this.filterStateService.setFilterValue(FilterName.dateRanges, []);
        // this.selectedEndDate = this.defaultTextEnd;
        // this.endDates = [];
        // }
    }

    determineSelectedDateRanges(): void {
        this.selectedDateRanges = this.filterStateService.getFilterValue(FilterName.dateRanges);
        if (this.selectedDateRanges.length <= 0) {
            this.selectedStartDate = this.defaultTextStart;
            this.selectedEndDate = this.defaultTextEnd;
            this.endDates = [];
        } else {
            this.selectedStartDate = {
                label: moment(this.selectedDateRanges[0]).format("MMMM, YYYY"),
                value: this.selectedDateRanges[0],
            };
            this.calculateEndDates(this.selectedStartDate.label);
            this.endDates.unshift(this.defaultTextStart);
            this.selectedEndDate = {
                label: moment(this.selectedDateRanges[1]).format("MMMM, YYYY"),
                value: this.selectedDateRanges[1],
            };
        }
    };

    calculateEndDates(label: string): void {
        const months = [];

        // calculate Months after first selection available within 1 year
        let remainGap = this.useSalesData ? this.lastSalesDateAvailable.diff(moment(this.selectedStartDate.value, "YYYYMM"), "months")
            : this.lastDateAvailable.diff(moment(this.selectedStartDate.value, "YYYYMM"), "months");
        remainGap = remainGap > 12 ? 12 : remainGap;

        for (let i = 0; i <= remainGap; i++) {
            months.push(moment(label, "MMMM, YYYY").add(i, "month").calendar());
        }

        this.endDates = months.map((m) => ({
            value: moment(m).format("YYYYMM"),
            label: moment(m).format("MMMM, YYYY")
        }));
    }

    endDropdownChanged(event: DropDownItem): void {
        this.selectedEndDate = event;
        const next: IDateRangeToggleState = {
            name: this.filterName, value: DateRangeTypes.MONTHS
        };

        // calculate range groups
        const year2start = moment(this.selectedStartDate.value, "YYYYMM").subtract(1, "year").format("YYYYMM");
        const year2end = moment(this.selectedEndDate.value, "YYYYMM").subtract(1, "year").format("YYYYMM");
        const year3start = moment(this.selectedStartDate.value, "YYYYMM").subtract(2, "year").format("YYYYMM");
        const year3end = moment(this.selectedEndDate.value, "YYYYMM").subtract(2, "year").format("YYYYMM");

        this.filterStateService.setFilterValue(FilterName.dateRanges, [this.selectedStartDate.value, this.selectedEndDate.value, year2start, year2end, year3start, year3end]);
        this.filterStateService.setFilterValue(FilterName.dateRangeType, DateRangeTypes.MONTHS);
        this.filterStateService.setFilterValue(FilterName.dates, {
            dateRangeType: DateRangeTypes.MONTHS,
            dateRanges: [this.selectedStartDate.value, this.selectedEndDate.value, year2start, year2end, year3start, year3end],
            date_display: DateUtils.dateDisplay(
                [this.selectedEndDate.value, this.selectedStartDate.value],
                this.filterStateService.getFilterValue(FilterName.dateRangeType)
            )
        });
        // if(!this.selectedEndDate.value){
        //     this.filterStateService.setFilterValue(FilterName.dateRanges, []);
        //     this.selectedEndDate = this.defaultTextEnd;
        // }
        this.toggle.emit(next);
    }

    selectOption(option: any): void {
        const next: IDateRangeToggleState = {
            name: this.filterName, value: option.value
        };
        if (next.value !== this.selectedOption) {
            this.selectedOption = option.value;
            if (option.value === DateRangeTypes.MONTHS) {
                this.determineSelectedDateRanges();
            } else {
                this.filterStateService.setFilterValue(FilterName.dateRanges, []);
                this.selectedDateRanges = [];
            }
            this.filterStateService.setFilterValue(FilterName.dateRangeType, option.value);
            this.filterStateService.setFilterValue(FilterName.dates, {
                dateRangeType: option.value,
                dateRanges: this.selectedDateRanges,
                date_display: DateUtils.dateDisplay(
                    [this.startDates[this.startDates.length - 1].value, this.startDates[this.startDates.length - 1].value],
                    this.filterStateService.getFilterValue(FilterName.dateRangeType))
            });
            if (option.value !== DateRangeTypes.MONTHS) {
                this.toggle.emit(next);
                if (this.filterStateService.getFilterValue(FilterName.new_used_flag) !== 1) {
                    this.filterStateService.setFilterValue(FilterName.disable_sales_data_menu, false);
                }
            }
        }
    }

    filtersUpdated(changes: string[]): void {
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        if (this.filterName && changes.includes(this.filterName.toString())) {
            const current = this.filterStateService.getFilterValue<string>(this.filterName);
            const dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            this.selectedOption = current;
        }
        if (this.filterName && changes.includes("use_sales_data") && !changes.includes("filters-setup")) {
            this.filterStateService.setFilterValue(FilterName.dateRanges, []);
            this.setUpOptions();
        }
    }
}
