<div id="filter-toggle-button-{{cssId}}" class="filter-toggle-button noselect">
    <div class="details">
        <div class="label">{{label}}</div>
    </div>
    <toggle-button
        [disabled]="disabled"
        [optionOne]=optionOne
        [optionOneLabel]=optionOneLabel
        [optionTwo]=optionTwo
        [optionTwoLabel]=optionTwoLabel
        [(optionTwoSelected)]=optionTwoSelected
    ></toggle-button>
</div>
