import { PresentationFilter } from "../models/filter.model";
import { SpotlightCompetitors, SpotlightSalesAreas } from "../models/spotlight.enum";

/*** This is our State object that is used for FilterState using NgRx,
     FilterStateFilters was the legacy state without NgRx.
     Here we are extending our legacy state object and then adding a necessary prop of "type" ***/
export interface FilterState extends PresentationFilter {
    type?: string;
    dealer_sales_data?: any[];
};

export interface FilterDefaultState extends PresentationFilter {
    type?: string;
    dealer_sales_data?: any[];
}

export const initialState: FilterState = {
    "type": "[FilterState]",
    "dateRangeType": "rolling",
    "dateRanges": [],
    "minDate": "",
    "maxDate": "",
    "minSalesDate": "",
    "maxSalesDate": "",
    "dma": [517],
    "dealers": [],
    "not_dealers": [],
    "makes": [],
    "dates": null,
    "models": [],
    "segments": [],
    "ihs_segments": [],
    "use_ihs_segments": false,
    "zips": [],
    "zones": [],
    "new_used_flag": 0,
    "buyer_dma_code": [517],
    "zip_zone_flag": "zip",
    "dealer_zip": [],
    "not_dealer_zip": [],
    "sales_area_zips": [],
    "spotlight_dealer": null,
    "competitors_selection": SpotlightCompetitors.All,
    "sales_area_selection": SpotlightSalesAreas.All,
    "include_spotlight": "include",
    "result_data_filtered": "filtered",
    "ring_radius": 5,
    "show_ring": false,
    "show_volume": false,
    "top_zones_names": [],
    "top_zones_sys_codes": [],
    "volume": null,
    "geosearch": false,
    "top_competitors": [],
    "top_zips": null,
    "top_zones": null,
    "selected_dealer": null,
    "active_selection": "",
    "report_building": false,
    "last_update": "",
    "selected_options": [],
    "dealer_sales_data": [],
    "use_sales_data": false,
    "enabled_features": [],
    "last_sales_update": ""
};

export const filterDefaultState: FilterDefaultState = {
    type: "[FilterStateDefaults]",
    dateRangeType: "rolling",
    dateRanges: [],
    dates: {},
    minDate: "",
    maxDate: "",
    minSalesDate: "",
    maxSalesDate: "",
    dma: [517],
    dealers: [],
    not_dealers: [],
    makes: [],
    models: [],
    segments: [],
    ihs_segments: [],
    use_ihs_segments: false,
    zips: [],
    zones: [],
    new_used_flag: 0,
    buyer_dma_code: [517],
    zip_zone_flag: "zip",
    dealer_zip: [],
    not_dealer_zip: [],
    sales_area_zips: [],
    spotlight_dealer: null,
    competitors_selection: SpotlightCompetitors.All,
    sales_area_selection: SpotlightSalesAreas.All,
    include_spotlight: "include",
    result_data_filtered: "filtered",
    ring_radius: 5,
    show_ring: false,
    show_volume: false,
    top_zones_names: [],
    top_zones_sys_codes: [],
    volume: null,
    geosearch: false,
    top_competitors: [],
    top_zips: { zips: [], totalSales: 0, topZips: [], topZipsSales: 0 },
    top_zones: null,
    selected_dealer: null,
    active_selection: "",
    report_building: false,
    last_update: "",
    selected_options: undefined,
    dealer_sales_data: [],
    use_sales_data: false,
    enabled_features: [],
    last_sales_update: ""
};
