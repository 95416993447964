import { Component, OnDestroy, OnInit } from "@angular/core";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { CognitoService } from "app/core/services/cognito.service";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { OverlayService, OverlayStatus } from "app/core/services/overlay.service";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
    selector: "filter-spotlight-button",
    templateUrl: "./filter-spotlight-button.component.html",
    styleUrls: ["./filter-spotlight-button.component.scss"]
})
export class FilterSpotlightButtonComponent implements OnInit, OnDestroy {
    dealer: Dealer;
    dealerId: number;
    selected = false;

    filterStateServiceSubscription: Subscription;
    dealerDataServiceSubscription: Subscription;
    overlayServiceSubscription: Subscription;

    constructor(
        private dealerDataService: DealerDataService,
        private filterStateService: FilterStateService,
        private overlayService: OverlayService,
        private cognitoService: CognitoService,
    ) { }

    ngOnInit(): void {
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.dealerDataServiceSubscription = this.dealerDataService.spotlightDealerDetails.subscribe(this.updateSpotlightDealer.bind(this));
        this.overlayServiceSubscription = this.overlayService.overlayState.subscribe(this.updateOverlayState.bind(this));
        this.filtersUpdated([FilterName.spotlight_dealer.toString()]); // initializing filter variable
    }

    ngOnDestroy(): void {
        this.filterStateServiceSubscription.unsubscribe();
        this.dealerDataServiceSubscription.unsubscribe();
        this.overlayServiceSubscription.unsubscribe();
    }

    filtersUpdated(changes: string[]): void {
        if (changes.includes(FilterName.spotlight_dealer.toString())) {
            this.dealerId = this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer);
        }
    }

    clickHandler(): void {
        this.overlayService.overlayState.pipe(take(1)).subscribe((value) => {
            if (value.spotlight) { // if spotlight selection panel is already open, then close it
                this.overlayService.hidePanel();
            } else {
                this.overlayService.showSpotlightSelectionPanel();
            }
        });
    }

    updateSpotlightDealer(dealer: Dealer): void {
        this.dealer = dealer;
    }

    updateOverlayState(overlayStatus: OverlayStatus): void {
        this.selected = !!overlayStatus.spotlight;
    }
}
