import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { ExportToastView } from "../components/export-toast/export-toast.component";

export interface ExportToastViewConfig {
    viewConfig: ExportToastView;
    reset: () => void;
    dismiss: () => void;
}

@Injectable()
export class ExportToastService {
    public readonly openSubject = new Subject<ExportToastViewConfig>();
    public readonly closeSubject = new Subject<void>();

    open(config: ExportToastViewConfig): void {
        this.openSubject.next(config);
    }

    close(): void {
        this.closeSubject.next();
    }
}
