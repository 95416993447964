import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { DealerSales, DmaSales, Sales } from "app/core/models/dealer-sales";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { UnknownSalesService } from "app/core/services/unknown-sales.service";
import * as moment from "moment/moment";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

@Component({
    selector: "dealer-results-details",
    templateUrl: "./dealer-results-details.component.html",
    styleUrls: ["./dealer-results-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerResultsDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() dealer: Dealer;
    @Input() total: boolean; // Market total does not contain shares %.
    @Input() scope: string;
    @Input() showVolume: boolean;

    dates: string[] = [];
    salesSource: any;
    sharesSource: any;
    dealerYearlySales: Sales = { loaded: new BehaviorSubject(false) };
    dmasYearlySales: Sales[];
    isMultiDma = false;
    protected dateGroupSales: DmaSales[] = [];
    protected dmaDateGroupSales: DmaSales[][] = [];
    protected dealerDateGroupSales: DealerSales[] = [];
    protected stream: Subscription;


    private dateRangeType = DateRangeTypes.YEARS;
    private salesSubscription: Subscription;
    private filterStateSubscription: Subscription;
    maxDate: string;
    minDate: string;
    useSalesData: boolean;

    get salesOwnerId(): number {
        return this.dealerYearlySales && (this.dealerYearlySales as DealerSales).dealer_id || (this.dealerYearlySales as DmaSales).dma_id;
    }

    constructor(
        private filterStateService: FilterStateService,
        private salesDataService: SalesDataService,
        private dmaDataService: DmaDataService,
        private changeDetectorRef: ChangeDetectorRef,
        private unknownSalesService: UnknownSalesService
    ) { }

    ngOnInit(): void {
        this.loadYearlySales();
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));

    }

    ngOnDestroy(): void {
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
        if (this.salesSubscription) {
            this.salesSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("dealer" in changes) {
            this.loadYearlySales();
        }
    }

    filtersUpdated(): void {
        this.loadYearlySales();
    }

    loadYearlySales(): void {
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate : FilterName.minDate);
        this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate);
        const dmas = this.filterStateService.getFilterValue(FilterName.dma) as number[];
        this.isMultiDma = dmas && dmas.length > 1;
        this.dates = [];
        this.salesSource = undefined;
        this.sharesSource = undefined;

        if (this.total && this.isMultiDma) {
            this.multiDMASales();
        } else {
            this.dealerSales();
        }

    }

    dealerSales(): void {
        this.dates = [];
        // determine date groups
        this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        const dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
        let dateGroups;

        if (this.dateRangeType === DateRangeTypes.MONTHS && dateRanges && dateRanges.length > 0) {
            this.maxDate = dateRanges[1];
            this.minDate = dateRanges[4];
            dateGroups = DateUtils.generateCustomDateGroupings(dateRanges);
        }

        if (this.scope && this.scope === "unknown" && this.dateRangeType !== DateRangeTypes.MONTHS) {
            this.dealerYearlySales = this.unknownSalesService.getUnknownSales(this.filterStateService.getFilterValue(FilterName.dma));
        } else if (this.dealer) {
            if (this.dateRangeType !== DateRangeTypes.MONTHS) {
                this.dealerYearlySales = this.salesDataService.getSalesForDealer(this.dealer, { volume: null });
            } else {
                if (this.dealerDateGroupSales.length) {
                    this.dealerDateGroupSales = [];
                }
                if (dateGroups) {
                    dateGroups.forEach(group => {
                        this.dealerDateGroupSales.push(this.salesDataService.getSalesForDealer(this.dealer, { dateRangeStart: group.start, dateRangeEnd: group.end, volume: null }));
                    });
                }
                this.dealerYearlySales = { makes: [], salesMonthly: {}, loaded: new BehaviorSubject(false), sharesMonthly: {} };
                // Clean out old data stream.
                if (this.stream) {
                    this.stream.unsubscribe();
                    this.stream = undefined;
                }

                this.stream = combineLatest(this.dealerDateGroupSales.map(sale => sale.loaded)).pipe(skipWhile(vals => vals.some(v => v === false))).subscribe(() => {
                    const target = [];
                    const prop = "make_description";
                    const modelProp = "model_description";

                    // sum all of the sales to groups
                    this.dealerDateGroupSales.forEach((dateGroup, dgInd) => {
                        const lastDate = Object.keys(dateGroup.salesMonthly).slice(-1)[0];
                        if (lastDate) {
                            this.dealerYearlySales.salesMonthly[lastDate] = Object.values<number>(dateGroup.salesMonthly).reduce((acc, curr) => acc + curr);
                            this.dealerYearlySales.sharesMonthly[lastDate] = (this.dealerYearlySales.salesMonthly[lastDate] / Object.values<number>(dateGroup["dmaSalesMonthly"]).reduce((acc, curr) => acc + curr)) * 100;

                            // lookup each make in agg and add date sales to each make
                            dateGroup.makes.forEach((make) => {
                                const aggSales: any = { salesMonthly: {} };
                                aggSales.salesMonthly[lastDate] = Object.values<number>(make.salesMonthly).reduce((acc, curr) => acc + curr);

                                const targetMake = target.find(_make => make[prop] === _make[prop]);
                                if (targetMake) {
                                    targetMake.salesMonthly = Object.assign(targetMake.salesMonthly, aggSales.salesMonthly);
                                } else {
                                    target.push({ ...make, models: [], salesMonthly: aggSales.salesMonthly });
                                }
                                make.models.forEach(model => {
                                    const targetMakeIndex = target.findIndex(_make => _make.make_description === make.make_description);
                                    const aggModelSales: any = { salesMonthly: {} };
                                    aggModelSales.salesMonthly[lastDate] = Object.values<number>(model.salesMonthly).reduce((acc, curr) => acc + curr);

                                    const targetModel = target[targetMakeIndex].models.find(_model => model[modelProp] === _model[modelProp]);
                                    if (targetModel) {
                                        targetModel.salesMonthly = Object.assign(targetModel.salesMonthly, aggModelSales.salesMonthly);
                                    } else {
                                        target[targetMakeIndex].models.push({ ...model, salesMonthly: aggModelSales.salesMonthly });
                                    }
                                });
                            });
                        } else {
                            this.dealerYearlySales.salesMonthly[dateGroups[dgInd].end] = 0;
                            this.dealerYearlySales.sharesMonthly[dateGroups[dgInd].end] = 0;
                        }
                    });
                    this.dealerYearlySales.makes = target;
                    this.dates = Object.keys(this.dealerYearlySales.salesMonthly).sort().reverse().slice(0, 3);
                    this.salesSource = this.dealerYearlySales.salesMonthly;
                    this.sharesSource = this.dealerYearlySales.sharesMonthly;
                    this.dealerYearlySales.loaded.next(true);
                    this.changeDetectorRef.detectChanges();
                });
            }
        } else if (this.total === true || this.total as any === "true") {
            if (this.dateRangeType !== DateRangeTypes.MONTHS) {
                if (this.scope && this.scope === "unknown") {
                    this.dealerYearlySales = this.unknownSalesService.getUnknownSales(this.filterStateService.getFilterValue(FilterName.dma));
                } else {
                    this.dealerYearlySales = this.salesDataService.getTotalSalesForDmas(this.filterStateService.getFilterValue(FilterName.dma), {}, false)[0];
                }
            } else {
                if (this.dateGroupSales.length) {
                    this.dateGroupSales = [];
                }
                if (dateGroups) {
                    dateGroups.forEach(group => {
                        if (this.scope && this.scope === "unknown") {
                            this.dateGroupSales.push(this.unknownSalesService.getUnknownSales(this.filterStateService.getFilterValue(FilterName.dma), { dateRangeStart: group.start, dateRangeEnd: group.end }));
                        } else {
                            this.dateGroupSales.push(this.salesDataService.getTotalSalesForDmas(this.filterStateService.getFilterValue(FilterName.dma), { dateRangeStart: group.start, dateRangeEnd: group.end }, false)[0]);
                        }
                    });
                }
                this.dealerYearlySales = { makes: [], salesMonthly: {}, loaded: new BehaviorSubject(false) };
                // Clean out old data stream.
                if (this.stream) {
                    this.stream.unsubscribe();
                    this.stream = undefined;
                }

                this.stream = combineLatest(this.dateGroupSales.map(sale => sale.loaded)).pipe(skipWhile(vals => vals.some(v => v === false)), take(1)).subscribe(() => {
                    const target = [];
                    const prop = "make_description";
                    const modelProp = "model_description";
                    const targetModels = [];

                    // sum all of the sales to groups
                    this.dateGroupSales.forEach((dateGroup, dgInd) => {
                        const lastDate = Object.keys(dateGroup.salesMonthly).slice(-1)[0];
                        if (lastDate) {
                            this.dealerYearlySales.salesMonthly[lastDate] = Object.values<number>(dateGroup.salesMonthly).reduce((acc, curr) => acc + curr);

                            // lookup each make in agg and add date sales to each make
                            dateGroup.makes.forEach((make) => {
                                const aggSales: any = { salesMonthly: {} };
                                aggSales.salesMonthly[lastDate] = Object.values<number>(make.salesMonthly).reduce((acc, curr) => acc + curr);

                                const targetMake = target.find(_make => make[prop] === _make[prop]);
                                if (targetMake) {
                                    targetMake.salesMonthly = Object.assign(targetMake.salesMonthly, aggSales.salesMonthly);
                                } else {
                                    target.push({ ...make, models: [], salesMonthly: aggSales.salesMonthly });
                                }
                                make.models.forEach(model => {
                                    const targetMakeIndex = target.findIndex(_make => _make.make_description === make.make_description);
                                    const aggModelSales: any = { salesMonthly: {} };
                                    aggModelSales.salesMonthly[lastDate] = Object.values<number>(model.salesMonthly).reduce((acc, curr) => acc + curr);

                                    const targetModel = target[targetMakeIndex].models.find(_model => model[modelProp] === _model[modelProp]);
                                    if (targetModel) {
                                        targetModel.salesMonthly = Object.assign(targetModel.salesMonthly, aggModelSales.salesMonthly);
                                    } else {
                                        target[targetMakeIndex].models.push({ ...model, salesMonthly: aggModelSales.salesMonthly });
                                    }
                                });
                            });
                        } else {
                            this.dealerYearlySales.salesMonthly[dateGroups[dgInd].end] = 0;
                        }
                    });
                    this.dealerYearlySales.makes = target;

                    this.dates = Object.keys(this.dealerYearlySales.salesMonthly).sort().reverse().slice(0, 3);
                    this.salesSource = this.dealerYearlySales.salesMonthly;
                    this.sharesSource = this.dealerYearlySales.sharesMonthly;
                    this.changeDetectorRef.detectChanges();
                });
            }
        }

        if (this.dateRangeType !== DateRangeTypes.MONTHS) {
            if (this.salesSubscription) {
                this.salesSubscription.unsubscribe();
            }
            this.changeDetectorRef.detectChanges();

            this.salesSubscription = this.dealerYearlySales.loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
                this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
                // Rolling is month specific and thus populates in the monthly property.
                const suffix = this.dateRangeType !== DateRangeTypes.YEARS ? "Monthly" : "";
                this.salesSource = this.dealerYearlySales["sales" + suffix];
                this.sharesSource = this.dealerYearlySales["shares" + suffix];
                this.dates = Object.keys(this.salesSource).sort().reverse().slice(0, 3);
                this.changeDetectorRef.detectChanges();
            });
        }



    }

    getType(item: any): string {
        if (item === null) {
            return "null";
        } else if (Array.isArray(item)) {
            return "array";
        }
        return typeof item;
    }

    multiDMASales(): void {
        this.dates = [];
        // determine date groups
        this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        const dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
        let dateGroups;

        if (this.dateRangeType === DateRangeTypes.MONTHS && dateRanges && dateRanges.length > 0) {
            this.maxDate = dateRanges[1];
            this.minDate = dateRanges[4];
            dateGroups = DateUtils.generateDateGroupings(this.dateRangeType, this.minDate, this.maxDate, 3, dateRanges[0]);
        }

        if (this.scope && this.scope === "unknown" && this.dateRangeType !== DateRangeTypes.MONTHS) {
            this.dmasYearlySales = this.unknownSalesService.getUnknownSalesMultiDMA(this.filterStateService.getFilterValue(FilterName.dma));
        } else if (this.total === true || this.total as any === "true") {
            if (this.dateRangeType !== DateRangeTypes.MONTHS) {
                if (this.scope && this.scope === "unknown") {
                    this.dmasYearlySales = this.unknownSalesService.getUnknownSalesMultiDMA(this.filterStateService.getFilterValue(FilterName.dma));
                } else {
                    this.dmasYearlySales = this.salesDataService.getTotalSalesForDmas(this.filterStateService.getFilterValue(FilterName.dma));
                }
            } else {
                dateGroups.forEach(group => {
                    if (this.scope && this.scope === "unknown") {
                        this.dmaDateGroupSales.push(this.unknownSalesService.getUnknownSalesMultiDMA(this.filterStateService.getFilterValue(FilterName.dma), { dateRangeStart: group.start, dateRangeEnd: group.end }));
                    } else {
                        this.dmaDateGroupSales.push(this.salesDataService.getTotalSalesForDmas(this.filterStateService.getFilterValue(FilterName.dma), { dateRangeStart: group.start, dateRangeEnd: group.end }, false));
                    }
                });
                this.dmasYearlySales = [];
                // Clean out old data stream.
                if (this.stream) {
                    this.stream.unsubscribe();
                    this.stream = undefined;
                }

                this.stream = combineLatest(this.dmaDateGroupSales.flat(20).map(sale => sale.loaded)).pipe(skipWhile(vals => vals.some(v => v === false)), take(1)).subscribe(() => {
                    const target = [];
                    const prop = "make_description";
                    const modelProp = "model_description";
                    this.dmasYearlySales = [];

                    // sum all of the sales to groups
                    this.dmaDateGroupSales.forEach(dateGroup => {
                        // for each dma
                        dateGroup.forEach((dma, dmaIndex) => {
                            const lastDate = Object.keys(dma.salesMonthly).slice(-1)[0];
                            if (!this.dmasYearlySales[dmaIndex]) {
                                this.dmasYearlySales.push({ dma_id: dma.dma_id, salesMonthly: { [lastDate]: Object.values<number>(dma.salesMonthly).reduce((acc, curr) => acc + curr) }, loaded: new BehaviorSubject(false) } as DmaSales);
                            } else {
                                this.dmasYearlySales[dmaIndex].salesMonthly[lastDate] = Object.values<number>(dma.salesMonthly).reduce((acc, curr) => acc + curr);
                            }
                            // lookup each make in agg and add date sales to each make
                            dma.makes.forEach((make) => {
                                const aggSales: any = { salesMonthly: {} };
                                aggSales.salesMonthly[lastDate] = Object.values<number>(make.salesMonthly).reduce((acc, curr) => acc + curr);

                                const targetMake = target.find(_make => make[prop] === _make[prop]);
                                if (targetMake) {
                                    targetMake.salesMonthly = Object.assign(targetMake.salesMonthly, aggSales.salesMonthly);
                                } else {
                                    target.push({ ...make, models: [], salesMonthly: aggSales.salesMonthly });
                                }
                                make.models.forEach(model => {
                                    const targetMakeIndex = target.findIndex(_make => _make.make_description === make.make_description);
                                    const aggModelSales: any = { salesMonthly: {} };
                                    aggModelSales.salesMonthly[lastDate] = Object.values<number>(model.salesMonthly).reduce((acc, curr) => acc + curr);

                                    const targetModel = target[targetMakeIndex].models.find(_model => model[modelProp] === _model[modelProp]);
                                    if (targetModel) {
                                        targetModel.salesMonthly = Object.assign(targetModel.salesMonthly, aggModelSales.salesMonthly);
                                    } else {
                                        target[targetMakeIndex].models.push({ ...model, salesMonthly: aggModelSales.salesMonthly });
                                    }
                                });
                            });
                            this.dmasYearlySales[dmaIndex].makes = target;
                        });
                    });


                    this.dates = Object.keys(this.dmasYearlySales[0].salesMonthly).sort().reverse().slice(0, 3);
                    this.salesSource = [{ dmaName: "Total Sales", sales: {}, id: 0 }];
                    const totals = this.salesSource[0].sales;
                    this.dmasYearlySales.forEach((s: DmaSales) => {
                        const sale = s.salesMonthly;
                        for (const date in sale) {
                            totals[date] = totals[date] ? totals[date] + sale[date] : sale[date];
                        }
                        this.salesSource.push({ dmaName: this.dmaDataService.getShortDmaName(s.dma_id), sales: s.salesMonthly, id: s.dma_id });
                    });
                    this.changeDetectorRef.detectChanges();
                });
            }
        }

        if (this.dateRangeType !== DateRangeTypes.MONTHS) {
            if (this.salesSubscription) {
                this.salesSubscription.unsubscribe();
            }
            this.changeDetectorRef.detectChanges();
            this.salesSubscription = combineLatest(this.dmasYearlySales.map(ds => ds.loaded))
                .pipe(skipWhile(list => !list.every(bool => bool)), take(1)).subscribe(() => {
                    this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
                    const property = this.dateRangeType === "rolling" || this.dateRangeType === "ytd" ? "salesMonthly" : "sales";
                    this.dates = Object.keys(this.dmasYearlySales[0][property]).sort().reverse().slice(0, 3);
                    this.salesSource = [{ dmaName: "Total Sales", sales: {}, id: 0 }];
                    const totals = this.salesSource[0].sales;
                    this.dmasYearlySales.forEach((s: DmaSales) => {
                        const sale = s[property];
                        for (const date in sale) {
                            totals[date] = totals[date] ? totals[date] + sale[date] : sale[date];
                        }
                        this.salesSource.push({ dmaName: this.dmaDataService.getShortDmaName(s.dma_id), sales: s[property], id: s.dma_id });
                    });
                    this.changeDetectorRef.detectChanges();
                });
        }
    }

    // Used in the template.
    protected formatDate(date: string): string {
        const min = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate : FilterName.minDate);
        const max = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate);
        let start = "";
        let end = "";

        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            const dateRanges: string[] = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            for (let i = 0; i < dateRanges.length; i++) {
                if (i % 2 === 0) {
                    continue;
                }
                if (dateRanges[i] === date) {
                    date = `${dateRanges[i - 1]}-${dateRanges[i]}`;
                    break;
                }
            }
        } else if (this.dateRangeType === DateRangeTypes.ROLLING12) {
            start = moment(date, "YYYYMM").subtract(1, "years").add(1, "months").format("YYYYMM");
            end = date;
            date = `${start}-${end}`;
        } else if (this.dateRangeType === DateRangeTypes.YEARS) {
            start = moment(date, "YYYYMM").month(0).format("YYYYMM");
            end = moment(date, "YYYYMM").month(0).format("YYYYMM");
            date = `${start}-${end}`;
        } else if (this.dateRangeType === DateRangeTypes.YTD) {
            start = moment(date, "YYYYMM").month(0).format("YYYYMM");
            end = moment(date, "YYYYMM").format("YYYYMM");
            date = `${start}-${end}`;
        }
        const tmpDateArray = date.split("-");

        return DateUtils.popUpDateRange(this.filterStateService.getFilterValue(FilterName.use_sales_data),
            this.dateRangeType !== DateRangeTypes.MONTHS ? tmpDateArray[1] : date,
            this.dateRangeType, moment(min).toDate(), moment(max).toDate());
    }

    protected formatData(item: any): any {
        if (Number.isNaN(item)) {
            item = "n/a";
        }
        return item;
    }
}
