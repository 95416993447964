<div class="toast box-shadow-bottom" [class.extend-left]="extendLeft" [class.visible]="!hidden">
    <div class="icon" [class.error]="error"></div>
    <div class="description">{{content}}</div>

    <label (click)="reset()">Start New Powerpoint</label>

    <button class="button dismiss" (click)="dismiss()">
        <div class="label">Dismiss</div>
    </button>
</div>
