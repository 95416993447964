import { uniq, without } from "lodash";
import { BehaviorSubject, Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { DealerSales, DmaSales, LineItemDetail, Sales } from "../models/dealer-sales";

export class SalesCache {
    private dealersCache: { [hash: string]: DealerSales } = {};
    private dmasCache: { [hash: string]: DmaSales } = {};
    // private dmasCache: { [dmaId: number]: DmaSales } = {};


    clear(): void {
        for (const id in this.dmasCache) {
            const dmaSale = this.dmasCache[id];
            dmaSale.loaded.complete();
            if (dmaSale.pending && dmaSale.pending.length) {
                dmaSale.pending.forEach(s => s.unsubscribe());
                dmaSale.pending = [];
            }
        }

        this.dealersCache = {};
        this.dmasCache = {};
    }

    createUncachedDealerSales(hash: string, dealer_id: number): DealerSales {
        return {
            hash,
            dealer_id,
            loaded: new BehaviorSubject<boolean>(false),
            pending: [],
            sales: {},
            salesMonthly: {},
            dmaSales: {},
            dmaSalesMonthly: {},
            shares: {},
            sharesMonthly: {},
            makes: []
        } as DealerSales;
    }

    createDealerSales(hash: string, dealer_id: number): DealerSales {
        if (this.dealersCache[hash]) {
            return;
        }

        this.dealersCache[hash] = this.createUncachedDealerSales(hash, dealer_id);
        return this.dealersCache[hash];
    }

    getDealerSales(hash: string): DealerSales {
        return this.dealersCache[hash];
    }

    // createUncachedDmaSales(dmaId: number): DmaSales {
    //     return {
    //         dma_id: dmaId,
    //         loaded: new BehaviorSubject<boolean>(false),
    //         pending: [],
    //         sales: {},
    //         salesMonthly: {},
    //         shares: {},
    //         sharesMonthly: {},
    //         makes: []
    //     } as DmaSales;
    // }

    // DMA id of 0 is a sales cumalative of all selected DMAs.
    // createDmaSales(dmaId: number): DmaSales {
    //     if (this.dmasCache[dmaId]) {
    //         return;
    //     }

    //     this.dmasCache[dmaId] = this.createUncachedDmaSales(dmaId);
    //     return this.dmasCache[dmaId];
    // }

    // getDmaSales(dmaId: number): DmaSales {
    //     return this.dmasCache[dmaId];
    // }

    createUncachedDmaSales(hash: string, dma_Id: number): DmaSales {
        return {
            hash,
            dma_id: dma_Id,
            loaded: new BehaviorSubject<boolean>(false),
            pending: [],
            sales: {},
            salesMonthly: {},
            shares: {},
            sharesMonthly: {},
            makes: []
        } as DmaSales;
    }

    // DMA id of 0 is a sales cumalative of all selected DMAs.
    createDmaSales(hash: string, dmaId: number): DmaSales {
        if (this.dmasCache[hash]) {
            return;
        }

        this.dmasCache[hash] = this.createUncachedDmaSales(hash, dmaId);
        return this.dmasCache[hash];
    }

    getDmaSales(hash: string): DmaSales {
        return this.dmasCache[hash];
    }

    addDmaSalesSubscription(salesObj: Sales, subscription: Subscription): void {
        subscription.add(() => salesObj.pending = without(salesObj.pending, subscription));
        salesObj.pending.push(subscription);
    }

    processSales(sales: Sales, salesData: any[] = [], dmaId: number, dmaHash?: string): void {
        if (!salesData.length) {
            sales.loaded.next(true);
            return;
        }

        // The first item is always the metadata header row and it is used to extract sales volume keys.
        const volumeKeys = this.extractVolumeKeys(salesData[0]);
        const salesDates = volumeKeys.map(vk => this.extractDate(vk));

        for (let i = 1; i < salesData.length; i++) {
            const data = salesData[i];
            let make = sales.makes.find((m) => m.make_description === data.make_description);
            if (!make && data["make_description"]) {
                make = {
                    make_description: data["make_description"],
                    sales: {},
                    salesMonthly: {},
                    dmaSales: {},
                    dmaSalesMonthly: {},
                    shares: {},
                    sharesMonthly: {},
                    models: []
                } as LineItemDetail;
                sales.makes.push(make);
            }

            let model;
            if (make) {
                model = make.models.find((m) => m.model_description === data.model_description);
                if (!model && data["model_description"]) {
                    model = {
                        model_description: data["model_description"],
                        sales: {},
                        salesMonthly: {},
                        dmaSales: {},
                        dmaSalesMonthly: {},
                        shares: {},
                        sharesMonthly: {}
                    };
                    make.models.push(model);
                }
            }

            for (let j = 0; j < volumeKeys.length; j++) {
                const volumeKey = volumeKeys[j];
                const date = this.extractDate(volumeKey);
                const dateType = date.length === 4 ? "" : "Monthly";
                if (make) {
                    if (!make["sales" + dateType][date]) {
                        make["sales" + dateType][date] = data[volumeKey] || 0;
                    } else if (data[volumeKey] && data[volumeKey] === "N/A") {
                        make["sales" + dateType][date] = data[volumeKey];
                    } else {
                        make["sales" + dateType][date] += data[volumeKey] || 0;
                    }
                    if (!make["dmaSales" + dateType][date]) {
                        make["dmaSales" + dateType][date] = data[volumeKey] || 0;
                    } else if (data[volumeKey] && data[volumeKey] === "N/A") {
                        make["dmaSales" + dateType][date] = data[volumeKey];
                    } else {
                        make["dmaSales" + dateType][date] += data[volumeKey] || 0;
                    }
                }

                if (model) {
                    if (!model["sales" + dateType][date]) {
                        model["sales" + dateType][date] = data[volumeKey] || 0;
                    }
                    if (!model["dmaSales" + dateType][date]) {
                        model["dmaSales" + dateType][date] = data[volumeKey] || 0;
                    }
                }

                if (!make && !model) {
                    sales["sales" + dateType][date] = data[volumeKey] || 0; // totals were returned
                    // sales["dmaSales" + dateType][date] = data[volumeKey] || 0; // totals were returned

                } else {
                    sales["sales" + dateType][date] = 0;
                    // sales["dmaSales" + dateType][date] = 0;
                }
            }
        }

        this.sortMakesModels(sales);
        this.processTotals(sales, salesDates);
        this.processTotals(sales, salesDates, "Monthly");
        this.processSharePercent(sales, dmaId, dmaHash);
    }

    private processTotals(sales: Sales, salesDates: string[], suffix: string = ""): void {
        let dates = Object.keys(sales["sales" + suffix]);
        if ((salesDates[0].length === 6 && suffix === "Monthly") ||
            (salesDates[0].length === 4 && suffix === "")) {
            dates = uniq(dates.concat(salesDates)).sort();
        }

        for (let i = 0; i < dates.length; i++) {
            const date = dates[i];
            for (let j = 0; j < sales.makes.length; j++) {
                const make = sales.makes[j];
                if (make.models.length) {
                    if (make["sales" + suffix][date] === "N/A") {
                        make["sales" + suffix][date] = "N/A";
                    } else {
                        make["sales" + suffix][date] = make.models.reduce((total, models) => total += (models["sales" + suffix][date] || 0), 0);
                    }
                    if (make["dmaSales" + suffix][date] === "N/A") {
                        make["dmaSales" + suffix] = { [date]: "N/A" };
                    } else {
                        make["dmaSales" + suffix] = { [date]: make.models.reduce((total, models) => total += (models["sales" + suffix][date] || 0), 0) };
                    }
                }
            }

            if (!!sales.makes.length) {
                if (sales.makes[0]["sales" + suffix][date] && sales.makes[0]["sales" + suffix][date] === "N/A") {
                    sales["sales" + suffix][date] = "N/A";
                    sales["dmaSales" + suffix] = { [date]: "N/A" };
                } else {
                    sales["sales" + suffix][date] = sales.makes.reduce((total, makes) => total += (makes["sales" + suffix][date] || 0), 0);
                    sales["dmaSales" + suffix] = { [date]: sales.makes.reduce((total, makes) => total += (makes["sales" + suffix][date] || 0), 0) };
                }
            }
        }
    }

    private processSharePercent(sales: Sales, dmaId: number, dmaHash?: string): void {
        // If sales is dmaSales, calculating it against itself will always results in 100%.
        // Set sales as loaded and exit.
        if ((sales as any).dma_id !== undefined) {
            if (sales.pending.length <= 1) {
                sales.loaded.next(true);
            }
            return;
        }

        // Shares percentage is calculated according to individual DMA.
        // If no dmaId is provided, shares percent cannot be calculated.
        // dmaId of 0 is share percentage across sumed selected dma.
        if (!dmaId || !dmaHash) {
            return;
        }

        // const dmaSalesCache = this.getDmaSales(dmaId) || this.createDmaSales(dmaId);

        const dmaSalesCache = this.getDmaSales(dmaHash) || this.createDmaSales(dmaHash, dmaId);
        const process = (suffix: string = "") => {
            const salesKey = "sales" + suffix;
            const sharesKey = "shares" + suffix;
            const dmaSaleKey = "dmaSales" + suffix;
            const dates = Object.keys(sales[salesKey]);

            // if (!dateRangeType || dateRangeType !== DateRangeTypes.MONTHS) {
            for (let i = 0; i < dates.length; i++) {
                const date = dates[i];
                // Ensure denominator exist prior to calculating shares volume.
                if (dmaSalesCache[salesKey][date]) {
                    sales[sharesKey][date] = ((sales[salesKey][date] / dmaSalesCache[salesKey][date]) * 100);
                    sales[dmaSaleKey][date] = dmaSalesCache[salesKey][date];
                } else if (dmaSalesCache[salesKey][date] === 0) {
                    // If all request completes and no sales were found for the given date,
                    // default to 0 to signify no sales rather than still loading.
                    sales[sharesKey][date] = 0;
                    sales[dmaSaleKey][date] = 0;
                }
                for (let j = 0; j < sales.makes.length; j++) {
                    const makeData = dmaSalesCache.makes.find((make) => make.make_description === sales.makes[j].make_description);
                    sales.makes[j][sharesKey][date] = 0;
                    if (makeData && makeData[salesKey][date]) {
                        sales.makes[j][sharesKey][date] = ((sales.makes[j][salesKey][date] / makeData[salesKey][date]) * 100) || 0;
                        sales.makes[j][dmaSaleKey][date] = ((makeData[salesKey][date])) || 0;

                    }

                    for (let k = 0; k < sales.makes[j].models.length; k++) {
                        sales.makes[j].models[k][sharesKey][date] = 0;
                        if (makeData) {
                            const modelData = makeData.models.find((model) => model.model_description === sales.makes[j].models[k].model_description);
                            if (modelData && modelData[salesKey][date]) {
                                sales.makes[j].models[k][sharesKey][date] = ((sales.makes[j].models[k][salesKey][date] / modelData[salesKey][date]) * 100) || 0;
                                sales.makes[j].models[k][dmaSaleKey][date] = ((modelData[salesKey][date])) || 0;

                            }
                        }
                    }
                }
            }
        };

        dmaSalesCache.loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
            process();
            process("Monthly");
            sales.loaded.next(true);
        });
    }

    private extractVolumeKeys(salesData: any): string[] {
        if (!salesData) {
            return [];
        }
        const volumeKeys: string[] = [];
        for (const key in salesData) {
            if (key && key.endsWith("_volume")) {
                volumeKeys.push(key);
            }
        }
        return volumeKeys;
    }

    private extractDate(salesVolumeKey: string): string {
        if (salesVolumeKey && salesVolumeKey.endsWith("_volume")) {
            return salesVolumeKey.match(/t(\d+)_volume/)[1];
        }
        return null;
    }

    private sortMakesModels(sales: Sales): void {
        sales.makes.sort((a, b) => a.make_description.localeCompare(b.make_description));
        for (let i = 0; i < sales.makes.length; i++) {
            sales.makes[i].models.sort((a, b) => a.model_description.localeCompare(b.model_description));
        }
    }
}

