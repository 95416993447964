import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DataSet } from "app/core/models/chart-data.model";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { FilterName } from "app/core/models/filter-name.enum";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { cloneDeep, filter } from "lodash";
import * as moment from "moment";
import { Subscription, forkJoin, fromEvent } from "rxjs";
import { debounceTime, skipWhile, take } from "rxjs/operators";

import { MetadataService } from "../../../services/metadata.service";
import { ChartColor } from "../../charts/chart/chart-color.utils";
import { MAX_BAR_THICKNESS, MIN_BAR_THICKNESS } from "../../charts/chart/chart-defaults.options";
import { ChartComponent } from "../../charts/chart/chart.component";
import { CardUtils } from "../card/card.utils";

@Component({
    selector: "comparison-by-zone-card",
    templateUrl: "./sales-comparison-by-zone-card.component.html",
    styleUrls: ["./sales-comparison-by-zone-card.component.scss"]
})
export class SalesComparisonByAdZoneCardComponent implements OnInit, OnDestroy {
    @ViewChild(ChartComponent) chart: ChartComponent;

    loadingState = true; // card data loading state
    cardDisabledState = true;
    cardOpenState = false;
    noResults = false;
    descriptionDisabledState = false;
    cardDescription = "";

    spotlightDealerId = 0;
    dealers: number[] = [];

    data: DataSet[] = []; // all loaded zone data
    chartData: DataSet[] = []; // all displayed zone data
    chartColors: string[] = [];
    labels: string[][]; // all loaded labels
    chartLabels: string[][]; // all displayed labels
    customTicks: number[][] = [];
    chartOptions: any = {
        scales: {
            xAxes: [{
                barThickness: MIN_BAR_THICKNESS,
                categoryPercentage: 1,
                barPercentage: 1.0,
                padding: -75,
            }]
        }
    }; // Chart options overrides.

    leftNavDisabled = true; // disabled state for the chart navigation arrows
    rightNavDisabled = true;
    showVolume = true; // default value as per business requirements

    protected chartSegmentWidth;
    protected minSegmentWidth = 130;
    protected labelFont = "14pt Open Sans";

    protected date = "2018";
    protected salesDate = "";
    protected dateRangeType = "calendar";
    private limitToDisplay = 5;
    private stepInterval = 1;
    private chartPosition = 0; // currently displayed Position
    private cachedCanvas;
    private cachedStringWidths = {};
    protected filterSubscription: Subscription;
    private resizeSub: Subscription;
    private use_sales_data: boolean;

    get title(): string {
        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        this.use_sales_data = this.filterStateService.getFilterValue(FilterName.use_sales_data);

        if (dateRanges && this.dateRangeType === "months" && dateRanges.length >= 2) {
            return `Dealer Sales Comparison by Ad Zone: ${DateUtils.dateDisplay([dateRanges[1], dateRanges[0]], this.dateRangeType)}`;
        } else if (this.dateRangeType === "months") {
            return "Dealer Sales Comparison by Ad Zone";
        } else {
            return `Dealer Sales Comparison by Ad Zone: ${DateUtils.displayDateRange(this.use_sales_data ? this.salesDate : this.date, this.dateRangeType, 1)}`;
        }
    }

    constructor(
        private salesDataService: SalesDataService,
        protected dealerDataService: DealerDataService,
        protected filterStateService: FilterStateService,
        protected dmaDataService: DmaDataService,
        protected metadataService: MetadataService
    ) {
        // Update this chart when the window is resized to recalculate the chart segmentation.
        this.resizeSub = fromEvent(window, "resize").pipe(
            debounceTime(500))
            .subscribe(this.updateChartSize.bind(this));
    }

    ngOnInit(): void {
        this.metadataService.metadata.pipe(skipWhile(i => !(i && i.maxDate && i.maxSalesDate)), take(1)).subscribe(meta => {
            this.date = meta.maxDate;
            this.salesDate = meta.maxSalesDate;
            this.filterSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
            this.use_sales_data = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.filtersUpdated([FilterName.spotlight_dealer.toString(), FilterName.dealers.toString(), FilterName.dateRangeType.toString(), FilterName.dateRanges.toString()]); // this initializes the spotlight, dateRangeType variables
        });
    }

    ngOnDestroy(): void {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
        if (this.resizeSub) {
            this.resizeSub.unsubscribe();
        }
    }

    filtersUpdated(changes: string[]): void {

        const dealers = this.filterStateService.getFilterValue<number[]>(FilterName.dealers);
        const conditions = {
            spotlit: {
                isValid: !!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer),
                text: "spotlight a dealer"
            },
            dealers: {
                isValid: dealers.length > 0 && dealers.length <= 6, // Spotlit + 5 dealers.
                text: "select 1-5 other dealers"
            },
            include_spotlit: {
                isValid: this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) === "include",
                text: "toggle 'Spotlight' to Include"
            }
        };

        this.cardDescription = CardUtils.getRequirementDescription(conditions);
        this.cardDisabledState = this.cardDescription.length > 0;

        if (changes.includes(FilterName.dateRangeType.toString()) || changes.includes(FilterName.dateRanges.toString())) {
            const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            // Dont update untill we have date ranges set.
            if (this.dateRangeType === DateRangeTypes.MONTHS) {
                if (!dateRanges || dateRanges.length === 0) {
                    return;
                }
            }
        }

        if (changes.includes("spotlight_dealer")) {
            this.spotlightDealerId = this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer);
        }

        if (changes.includes("dealers")) {
            this.updateDealers(this.filterStateService.getFilterValue<number[]>(FilterName.dealers).slice());
        }

        if (changes.includes(FilterName.use_sales_data.toString())) {
            this.use_sales_data = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
        }

        if (this.cardDisabledState) {
            this.resetProperties();
            this.cardOpenState = false;
            this.noResults = false;
            this.cardClosed();
        } else {
            this.updateCardData();
        }
    }

    updateDealers(dealers: number[]): void {
        this.dealers = dealers.filter((dealer) => dealer !== this.spotlightDealerId);
        if (this.spotlightDealerId) {
            this.dealers.unshift(this.spotlightDealerId);
        }
    }

    updateCardData(): void {
        // Don't update if this card is not open or enabled
        if (!this.cardOpenState || this.cardDisabledState) {
            return;
        }
        this.loadingState = true;
        const isMultiDma = this.filterStateService.isMultiDma();
        const zips = this.filterStateService.getFilterValue(FilterName.zips);
        const dmas = this.filterStateService.getFilterValue(FilterName.dma);
        const urlParams = this.filterStateService.getCurrentApiFilters();
        this.use_sales_data = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        const endDate = this.use_sales_data ? this.salesDate : this.date;
        const filterOverride = { dateRangeEnd: endDate, dateRangeType: this.dateRangeType, zips, dma_code: dmas };

        // Override for ppt custom date range
        if (
            urlParams !== undefined &&
            urlParams.hasOwnProperty("dateRanges") &&
            urlParams.hasOwnProperty("dateRangeType") &&
            urlParams["dateRanges"].length &&
            urlParams["dateRangeType"] === "months"
        ) {
            filterOverride["dateRangeStart"] = urlParams["dateRanges"][0];
            filterOverride["dateRangeEnd"] = urlParams["dateRanges"][1];
        }

        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);

        if (dateRanges && dateRanges.length >= 2 && this.filterStateService.getFilterValue(FilterName.dateRangeType) === "months") {
            filterOverride["dateRangeStart"] = dateRanges[0];
            filterOverride["dateRangeEnd"] = dateRanges[1];
        }

        const zoneTotalsReq = this.salesDataService.getBuyerSalesForZones(undefined, filterOverride, false, isMultiDma ? ["sys_code", "ncc_name"] : undefined);
        const dealersZoneSalesReq = this.salesDataService.getSalesForDealersByZipZone(this.dealers, "zones", filterOverride, false);
        const dealersLoadedReq = this.dealerDataService.getDealerDetails(this.dealers);

        forkJoin([zoneTotalsReq, dealersZoneSalesReq, dealersLoadedReq]).subscribe(values => {
            const zoneTotals = values[0].sort((z1, z2) => z2.sales - z1.sales);
            const dealersZoneSales = values[1];
            const dealerInfos = values[2];

            this.resetProperties();
            const spotlightDealer = dealersZoneSales.find(dealerSales => dealerSales.dealer_id === this.spotlightDealerId);
            if (!dealersZoneSales.length || !spotlightDealer || (spotlightDealer && !spotlightDealer.sales.length)) {
                this.noResults = true;
                this.loadingState = false;
                this.descriptionDisabledState = false;
                this.cardDisabledState = true;
                this.cardClosed();
                return;
            }

            // A map between selected zone name and zone info.
            const selectedZones = {};
            const dealerData = [];

            for (let i = 0; i < dealersZoneSales.length; i++) {
                const dealerResults = dealersZoneSales[i];

                for (let j = 0; j < zoneTotals.length; j++) {
                    const sys_code = zoneTotals[j].sys_code;
                    let foundData = dealerResults.sales.find(dealerResult => sys_code === "0" ?
                        dealerResult.sys_code === sys_code && dealerResult.dma === zoneTotals[j].dma :
                        dealerResult.sys_code === sys_code);

                    // If dealer does not sell to this location, add an entry and set sales to 0.
                    if (!foundData) {
                        foundData = cloneDeep(zoneTotals[j]);
                        foundData.sales = 0;
                    }
                    dealerData.push(foundData);

                    if (!selectedZones[foundData.location]) {
                        selectedZones[foundData.location] = foundData;
                    }
                }

                const isSpotlight = this.spotlightDealerId && dealerResults.dealer_id === this.spotlightDealerId;
                const chartSlice = {
                    id: dealerResults.dealer_id,
                    label: this.dealerDataService.getDealerName(dealerResults.dealer_id),
                    details: dealerInfos.find(dealer => dealer.dealer_id === dealerResults.dealer_id),
                    data: dealerData.map(sale => {
                        if (this.showVolume) {
                            const zone = zoneTotals.find(z => z.sys_code === sale.sys_code);
                            const percent = Number(sale.sales / zone.sales);
                            return sale.sales;
                        } else {
                            const zone = zoneTotals.find(z => z.sys_code === sale.sys_code);
                            const percent = Number(sale.sales / zone.sales);
                            return isNaN(percent) ? 0 : percent * 100;
                        }
                    }),
                    spotlight: isSpotlight
                };

                if (isSpotlight) {
                    this.data.unshift(chartSlice);
                } else {
                    this.data.push(chartSlice);
                }
            }

            let locations = [];
            dealerData.map((sale: any, idx: number) => {
                if (!locations.includes(sale.location)) {
                    locations.push(sale.location);
                }
                if (this.showVolume) {
                    let locationIdx = locations.indexOf(sale.location);
                    if ((locationIdx !== -1) && this.customTicks[locationIdx]) {
                        this.customTicks[locationIdx].push(sale.sales);
                    } else {
                        this.customTicks.push([sale.sales]);
                    }
                } else {
                    let locationIdx = locations.indexOf(sale.location);
                    if ((locationIdx !== -1) && this.customTicks[locationIdx]) {
                        const zone = zoneTotals.find(z => z.sys_code === sale.sys_code);
                        const percent = Number(sale.sales / zone.sales);
                        this.customTicks[locationIdx].push(isNaN(percent) ? 0 : percent * 100);
                    } else {
                        const zone = zoneTotals.find(z => z.sys_code === sale.sys_code);
                        const percent = Number(sale.sales / zone.sales);
                        this.customTicks.push([isNaN(percent) ? 0 : percent * 100]);
                    }
                }
            });

            this.updateChartSize();
            this.labels = Object.keys(selectedZones).map(zoneName => {
                const dmaName = selectedZones[zoneName].undefinedLocation || isMultiDma ? selectedZones[zoneName].dmaName : undefined;
                return this.wrapLabel(zoneName, this.chartSegmentWidth, [zoneName], dmaName);
            });
            this.setColors();
            this.setDisplayedData();
            this.loadingState = false; //
        });
    }

    resetProperties(): void {
        this.data = [];
        this.chartData = [];
        this.labels = [];
        this.chartLabels = [];
        this.chartPosition = 0;
    }

    setDisplayedData(): void {
        const isMultiDma = this.filterStateService.isMultiDma();
        // Change label positioning to account for third row if applicable.
        if (!this.chart.chartOptions.scales.xAxes[0].ticks) {
            this.chart.chartOptions.scales.xAxes[0].ticks = {};
        }
        this.chart.chartOptions.scales.xAxes[0].ticks.padding = isMultiDma ? -13 : -20;
        if (!this.chart.chartOptions.scales.xAxes[0].gridLines) {
            this.chart.chartOptions.scales.xAxes[0].gridLines = {};
        }
        this.chart.chartOptions.scales.xAxes[0].gridLines.tickMarkLength = isMultiDma ? 60 : 40;
        this.chartData = [];
        for (let i = 0; i < this.data.length; i++) {
            const dealer = cloneDeep(this.data[i]);
            // Here we need to only get the data for this dealer, the data length gives the number
            // of Ad Zones for this dealer
            dealer.data = dealer.data.slice(this.chartPosition + (i * this.data[0].data.length), this.chartPosition + (i * this.data[0].data.length) + this.limitToDisplay);
            if (isMultiDma) {
                const d = this.data.find(info => info.id === dealer.id)["details"];
                dealer.label += d ? ` (${this.dmaDataService.getShortDmaName(d.dealer_dma_code)})` : "";
            }
            this.chartData.push(dealer);
        }
        this.chartLabels = this.labels.slice(this.chartPosition, this.chartPosition + this.limitToDisplay);
    }

    navigateChart(previous: boolean = false): void {
        this.chartPosition = previous ? this.chartPosition - this.stepInterval : this.chartPosition + this.stepInterval;
        this.checkNavButtons();
        this.setDisplayedData();
    }

    setColors(): void {
        this.chartColors = ChartColor.getSpotlightPatterns(1).concat(ChartColor.getColorPalette(this.dealers.length - 1));
    }

    checkNavButtons(): void {
        this.leftNavDisabled = this.chartPosition === 0;
        this.rightNavDisabled = this.data.length && ((this.chartPosition + this.limitToDisplay) >= this.data[0].data.length);
    }

    cardOpened(): void {
        if (!this.cardDisabledState) {
            this.cardOpenState = true;
            this.updateCardData();
        }
    }

    cardClosed(): void {
        this.cardOpenState = false;
    }

    updateChartSize(): void {
        // Don't attempt to update if card is not opened.
        if (!this.cardOpenState) {
            return;
        }

        const chartWidth = this.chart.elementRef.nativeElement.offsetWidth;
        this.chartSegmentWidth = this.calculateSegmentWidth(this.dealers.length, MAX_BAR_THICKNESS);
        this.limitToDisplay = Math.floor((chartWidth - 100) / this.chartSegmentWidth);
        this.chart.chartOptions.scales.xAxes[0].maxBarThickness = MAX_BAR_THICKNESS;

        // If less then the minimum of 5 segment
        // recalculate using smaller bar thickness to increase segments size.
        if (this.limitToDisplay < 5) {
            this.chartSegmentWidth = this.calculateSegmentWidth(this.dealers.length, MIN_BAR_THICKNESS);
            this.limitToDisplay = Math.floor((chartWidth - 100) / this.chartSegmentWidth);
            this.chart.chartOptions.scales.xAxes[0].maxBarThickness = MIN_BAR_THICKNESS;
        }
        this.checkNavButtons();
        if (this.labels && this.labels.length > 0) {
            this.setDisplayedData();
        }
    }

    // Width in pixel for a segment: (totalGroupItems * barWidth) + (groupingOffset * groups)  + offset between intervals.
    protected calculateSegmentWidth(items: number, barThickness: number): number {
        const calcWidth = ((items * barThickness) + (2 * (items + 1)) + barThickness * 3);
        return calcWidth > this.minSegmentWidth ? calcWidth : this.minSegmentWidth; // sets a width for a chart segment, also gives enough room for the labels to not cover up the bars
    }

    protected wrapLabel(label: any, maxPixelWidth: number, wrappedLabel: string[] = [label], dmaName?: string): string[] {
        if (this.getStringWidths(label) > maxPixelWidth) { // check if the label needs wrapping
            const splitLabel = label.split(/(\w+)(?!.*\w+)/, 2); // get the last word in the remaining label
            label = splitLabel[0];
            // check if the current segment needs further wrapping
            if (this.getStringWidths(label) > maxPixelWidth) {
                return this.wrapLabel(label, maxPixelWidth, wrappedLabel, dmaName);
            }
            // catches edge case of a label segment being longer than the maxPixelWidth
            if (label.length === 0) {
                label = splitLabel[1];
            }
            // save completed word segment
            wrappedLabel.push(label);
            const remainingLabel = wrappedLabel[0].slice(wrappedLabel[0].indexOf(label) + label.length);
            return this.wrapLabel(remainingLabel, maxPixelWidth, wrappedLabel, dmaName);
        }
        wrappedLabel.push(label);

        // remove complete label reference
        wrappedLabel.shift();

        // If multiple DMAs are selected, append dma suffix.
        if (dmaName) {
            wrappedLabel.push(`(${dmaName})`);
        }

        // pad smaller array, chart has issues when label array has only 1 element
        if (wrappedLabel.length === 1) {
            wrappedLabel.push("");
        }
        return wrappedLabel; // exit
    }

    // Gets the width of each string as pixels
    // https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
    protected getStringWidths(string: string): number {
        let stringWidth = this.cachedStringWidths[string];
        if (!stringWidth) {
            // re-use canvas object for better performance
            if (!this.cachedCanvas) {
                this.cachedCanvas = document.createElement("canvas");
            }
            const context = this.cachedCanvas.getContext("2d");
            context.font = this.labelFont;
            stringWidth = this.cachedStringWidths[string] = Math.floor(context.measureText(string).width);
        }
        return stringWidth;
    }
}
