<card
    [cardTitle]="cardTitle"
    [description]="cardDescription"
    [descriptionDisabled]="cardDescriptionDisabled"
    (open)="cardOpened()"
    (close)="cardClosed()"
    [disabled]="cardDisabled"
    [isOpen]="cardOpen"
    [loading]="cardLoading"
    id="{{cardId}}-card"
>
    <chart
        id="{{cardId}}-chart"
        [chartType]="'groupedBars'"
        [dataToRender]="chartData"
        [labelsToRender]="chartLabels"
        [colors]="chartColors"
        [chartOptions]="chartOptions"
        [disableLabelInteractions]="true"
    ></chart>
</card>
