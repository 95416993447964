<div [class.menu-hidden]="!show" [class.menu-overlay]="show" [class.menu-closing]="closing" (click)="closeMenu()">
    <div class="menu-panel" [class.closing]="closing" (click)="$event.stopPropagation()">
        <div class="body-block">
            <button class="close-button" (click)="closeMenu()">
                <div class="label">Close</div>
                <div class="icon"></div>
            </button>
            <div class="user-details">
                <div class="user-icon"></div>
                <div class="user-label">{{ userEmail }}</div>
            </div>
            <ng-container *ngIf="!changePasswordSubmenu; else changePassword">
                <div class="menu-category">Application Settings</div>
                <div class="seperator">
                    <filter-toggle-button class="non-aligned" label="Sales Volume" optionOneLabel="Hide"
                        [optionOne]="false"
                        optionTwoLabel="Show" [optionTwo]="true" filterName="{{FilterName.show_volume}}"
                        (toggle)="updateToggleValue($event)"
                        [dTooltip]="hideSalesVolumeToolTip" placement="left" [tooltipZIndex]="1500">
                    </filter-toggle-button>

                    <filter-toggle-button class="non-aligned" label="Segments" optionOneLabel="Spectrum"
                        [optionOne]="false"
                        optionTwoLabel="IHS" [optionTwo]="true" filterName="{{FilterName.use_ihs_segments}}"
                        (toggle)="updateSegmentsValue($event)"
                        [dTooltip]="hideSalesVolumeToolTip" placement="left" [tooltipZIndex]="1500">
                    </filter-toggle-button>
                </div>
                <div *ngIf="isAdmin()" class="menu-category seperator">Application Admin</div>
                <div *ngIf="isAdmin()" class="menu-item seperator">
                    <a routerLinkActive="active" routerLink="admin">
                        <button class="label" id="user-menu-admin" (click)="closeMenu()">Manage Users</button>
                    </a>
                </div>
                <div class="menu-category seperator">Manage Account</div>
                <div class="menu-item seperator">
                    <button class="label" id="change-password-button"
                        (click)="changePasswordSubmenu = !changePasswordSubmenu"
                        [class.submenu-active]="changePasswordSubmenu">Change Password</button>
                </div>
                <div class="menu-item seperator">
                    <button class="label signout" (click)="signOut()">Log out</button>
                </div>
                <div class="menu-category seperator">Help</div>
                <div class="menu-item seperator">
                    <a class="label" href="mailto:{{supportEmail}}?subject=Auto%20Analyzer%20Support">Contact
                        Support</a>
                </div>
            </ng-container>
        </div>

        <div class="footer data-details">
            <div>Data source: IHS Markit</div>
            <div *ngIf="appLastUpdated !== ''">Application last updated: {{appLastUpdated}}</div>
        </div>
    </div>
</div>

<ng-template #changePassword>
    <div class="label submenu-active">Change Password</div>
    <user-change-password (closePasswordChange)="changePasswordSubmenu = false"></user-change-password>
</ng-template>

<ng-template #hideSalesVolumeToolTip>
    <div style="max-width:220px">
        This feature affects all data in the application including the PowerPoint output.
    </div>
</ng-template>
