import { AfterViewInit, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { AutoAnalyzerService } from "app/core/services/auto-analyzer.service";
import { PresentationFilterService } from "app/core/services/presentation-filter.service";
import { environment } from "environment";

import { ConfirmModalConfig, ConfirmModalService } from "../../services/confirm-modal.service";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";

export enum ModalIcon {
    STOP = "STOP",
    WARNING = "WARNING",
    STANDARD = "STANDARD"
}

export interface ConfirmModalView {
    id: string;
    icon: ModalIcon;
    header: string;
    message?: string;
    confirmLabelText?: string;
    contentTmplRef?: TemplateRef<any>;
    contentTmplData?: any;
    validDma?: boolean;
}

@Component({
    selector: "create-schedule-modal",
    templateUrl: "./create-schedule-modal.component.html",
    styleUrls: ["./create-schedule-modal.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CreateScheduleModalComponent extends ConfirmModalComponent implements OnInit, AfterViewInit {
    // For template usage.
    @ViewChild("createScheduleTemp", { read: TemplateRef }) createScheduleTemp: TemplateRef<any>;
    @ViewChild("invalidDma", { read: TemplateRef }) noDMATemp: TemplateRef<any>;

    @ViewChild("modalSelectionSection", { read: ViewContainerRef }) modalSelectionSection;
    @Output() cancelEvent = new EventEmitter();
    isDmaValid: boolean;
    dmaNames;
    showSelections = false;
    ref: any;
    newTabURL: string;


    constructor(
        public confirmModalService: ConfirmModalService,
        protected presentationFilterService: PresentationFilterService,
        protected autoAnalyzerService: AutoAnalyzerService
    ) {
        super(confirmModalService);
        this.openModal = this.openModal.bind(this);
    }

    ngAfterViewInit(): void {
        this.openModal();
    }

    public async openModal(): Promise<void> {
        this.showSelections = true;
        this.config = this.createConfirmModalConfig();
        this.confirmModalService.showModal(this.config);
    }

    private createConfirmModalConfig(): ConfirmModalConfig {
        this.isDmaValid = this.presentationFilterService.getFilterValue(FilterName.aa_is_Dma_valid);
        if (this.isDmaValid) {
            return {
                viewConfig: {
                    id: "create-tv-schedule",
                    header: "Create TV Schedule",
                    confirmLabelText: "Create TV Schedule",
                    contentTmplRef: this.createScheduleTemp,
                    validDma: this.isDmaValid
                } as ConfirmModalView,
                confirm: async (e) => {
                    this.presentationFilterService.handlePptClick(true);
                    const aaQueryVars: any[] = this.presentationFilterService.getFilterValue(FilterName.aa_query_vars);
                    const qs = Object.keys(aaQueryVars[0])
                        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(aaQueryVars[0][key])}`)
                        .join("&");
                    const newTabURL = environment.aaAPIUrl + environment.aaBaseAPIPath + "proposal?" + qs;
                    const authToken = await this.autoAnalyzerService.fetchAuthToken();
                    this.autoAnalyzerService.getUserWithToken(JSON.parse(authToken.data)).subscribe(
                        async response => {
                            if (response["isSuccess"]) {
                                window.open(newTabURL);
                                this.cancel(e);
                                return;
                            }
                        }, async error => {
                            console.error(error);
                            await this.autoAnalyzerService.log605AuthCall(error, "error");
                            this.cancel(e);
                        });
                },
                cancel: (e) => {
                    this.onCancelEvent(e);
                }
            };
        } else {
            return {
                viewConfig: {
                    id: "create-tv-schedule",
                    header: "Create TV Schedule",
                    confirmLabelText: "Create TV Schedule",
                    contentTmplRef: this.noDMATemp,
                    validDma: this.isDmaValid
                } as ConfirmModalView,
                confirm: (e) => {
                    // do nothing
                },
                cancel: (e) => {
                    this.onCancelEvent(e);
                }
            };
        }
    }


    onCancelEvent(event: any): void {
        this.cancelEvent.emit(event);
    }

}
