<div style="max-width: 450px; margin-top: 20px;">
    <div class="search">
        <kite-input
            [attr.type]="search"
            #filtersSearch
            class="searchInput"
            placeholder="Search..."
            (focus)="toggleFocus(true)"
            (blur)="toggleFocus(false)"
            [(value)]="searchTerm"
            (valueChange)="inputChanged()"
            [class.focus]="searchIsFocused">
        </kite-input>
    </div>
    <div class="icon">
        <span class="clear-icon" *ngIf="searchHasInput" (click)="clearSearch()"></span>
    </div>
</div>
