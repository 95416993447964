/* eslint-disable indent */
import { FilterName } from "@at/core";
import { createReducer, on } from "@ngrx/store";

import * as FilterStateActions from "../actions/filter-state-change.actions";
import { FilterState, initialState } from "../state";

/**
 * This is to allow us to update one particular filter property when the user selects a new filter
 * in the User Interface
 */
const _filterStateReducer = createReducer(
    initialState,
    on(FilterStateActions.setFilter, (state, {filterName, value}) => ({...state, [filterName]: value})),
    on(FilterStateActions.setFilterStateObject, (state, {filterState}) => (Object.assign({}, filterState))),
    on(FilterStateActions.appendToSalesDataFilter, (state, {value}) => ({...state, dealer_sales_data: [...state.dealer_sales_data, value]}))
);

export function filterStateReducer(state, action) {
    return _filterStateReducer(state, action);
}

