<div class="selection-section filter-sales-range-panel" id="{{filterName}}-selection-section">
    <div class="done-button">
        <div class="close-button" (click)="closePanel()">
            <div class="label">Done</div>
            <div class="icon"></div>
        </div>
    </div>

    <div class="selection-section-title">
        <label class="selection-title">
            Modify Sales Range
        </label>
        <label class="selection-subtitle">
            To modify the sales range, set the Minimum and Maximum values below.
            While this filter limits the dealers being viewed it does not affect share calculations.
            NOTE: This range is calculated based on sales for the last three years, if available.
        </label>
    </div>
    <ng-container *ngIf="loadedOptions; else loading">
        <div>
            <input
                [(ngModel)]="min"
                class="sales-range-input min"
                name="min"
                min="0"
                type="text"
                placeholder="Min"
                autocomplete="off"
                [class.invalid]="!validateMin()"
                (keydown)="handleKeyDown($event)">
            <span class="icon arrow"></span>
            <input
                [(ngModel)]="max"
                class="sales-range-input max"
                name="max"
                min="0"
                type="text"
                placeholder="Max"
                autocomplete="off"
                [class.invalid]="!validateMax()"
                (keydown)="handleKeyDown($event)">
        </div>

        <div class=" market-max">
            <label>Market Max = {{marketMax | number}} sales</label>
        </div>
        <br />
        <div class="error-state" *ngIf="!isValid()">
            <div>
                <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAWpJREFUKBV9kj1LA0EQhmf2zJeFlWh/e6QV9A+IBgQhHwYEe3s7EfIPxM5erEVI9AyBFIo/QAT7vbMQLSwtogfJjjPRC8vFuLDs7MzzsrvvLIIzekGwkBAdElENEH0pIdEzId4UEY+3jflIcZUGodYbibVPCLDsAbTm8/myTPK8FucWpSZMyo/XdhDsdrR+nSo4VCcI1ttav6QM9rVeGgAYPqVRi6K7lG37PkncjGM+8GeIGKw9Lyi1oj4BDpi4cEUpmF13jLnn9/a/iI6UJaoqxDALzdoropANqypxr5jLPcwCs/lcqfTIOX/iahaYtfesRb4uzQFRPBgO1xjsurBripsfs6zh52GIo1HDLUrM7enJzObZ1bpoVAHxhIubV+VyZQrKJKSHbEzlVwMgCe7b239iYdxPMmmuFEZEZ3zILSp1nTqdJMkqm1HnXu/xut805lIuMhHK5q9PzuZFbES3BHC6FUXvwsn4BgZflw+mgAYwAAAAAElFTkSuQmCC">
            </div>
            <div class="error-message">
                Please make sure that your minimum sales value does not exceed Maximum
            </div>
        </div>
    </ng-container>

</div>

<ng-template #loading>
    <div class="loader">
        <div class="loading-icon"></div>
    </div>
</ng-template>
