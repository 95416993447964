import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { Subscription } from "rxjs";

import { IFilterToggleState } from "../filter-toggle-button/filter-toggle-button.component";

export interface IFilterRadioButton {
    label: string;
    value: string;
}

@Component({
    selector: "filter-radio-button",
    templateUrl: "./filter-radio-button.component.html",
    styleUrls: ["./filter-radio-button.component.scss"]
})
export class FilterRadioButtonComponent implements OnInit, OnDestroy, OnChanges {
    @Input() filterName: FilterName;
    @Input() options: IFilterRadioButton[];
    @Input() radioGroupName: string;

    selectedOption: string;

    private filterStateServiceSubscription: Subscription;
    @Output() toggle: EventEmitter<IFilterToggleState> = new EventEmitter<IFilterToggleState>();

    constructor(
        private filterStateService: FilterStateService) { }

    ngOnInit(): void {
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.filtersUpdated([this.filterName.toString()]);
    }

    ngOnDestroy(): void {
        this.filterStateServiceSubscription.unsubscribe();
    }

    ngOnChanges(): void {
        this.filtersUpdated([this.filterName.toString()]);
    }

    selectOption(option: IFilterRadioButton): void {
        const next: IFilterToggleState = { name: this.filterName, value: option.value };

        if (next.value !== this.selectedOption) {
            this.selectedOption = option.value;
            this.toggle.emit(next);
        }
    }

    filtersUpdated(changes: string[]): void {
        if (this.filterName && changes.includes(this.filterName.toString())) {
            const current = this.filterStateService.getFilterValue<string>(this.filterName);
            this.selectedOption = current;
        }
    }
}
