import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[dClickOutside]"
})
export class ClickOutsideDirective {
    @Output() dClickOutside: EventEmitter<any> = new EventEmitter();

    constructor(private _elementRef: ElementRef) { }

    @HostListener("document:click", ["$event", "$event.target"])
    public onClick(event: MouseEvent, targetElement: EventTarget): void {
        const isClickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!isClickedInside) {
            this.dClickOutside.emit(event);
        }
    }
}
