import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { skipWhile, take } from "rxjs/operators";

import { CognitoService } from "./cognito.service";

@Injectable()
export class UserCookieService {

    protected emailPrefix = "";

    constructor(
        protected cookieService: CookieService,
        protected cognitoService: CognitoService
    ) {
        this.cognitoService.authenticated
            .pipe(skipWhile(i => !i)
                , take(1))
            .subscribe(this.setupPrefixes.bind(this));
    }

    public storedValueExists(item: string, prefix: string = ""): boolean {
        return this.cookieService.check(this.emailPrefix + prefix + "." + item);
    }

    public getStoredValue(item: string, arrayFilters: string[], prefix: string = ""): any {
        try {
            let value = JSON.parse(this.cookieService.get(this.emailPrefix + prefix + "." + item));
            if (arrayFilters.includes(item) && !Array.isArray(value)) {
                value = undefined;
            }
            return value;
        } catch (e) {
            this.cookieService.delete(this.emailPrefix + prefix + "." + item, "/");
            return undefined;
        }
    }

    public setStoredValue(item: string, value: any, prefix: string = ""): any {
        if (value !== null) {
            this.cookieService.set(this.emailPrefix + prefix + "." + item, JSON.stringify(value), 365, "/");
        } else {
            this.cookieService.delete(this.emailPrefix + prefix + "." + item, "/");
        }
    }

    public getSalesRangeFilteredDealers(): number[] {
        return this.getStoredValue("sales_range_dealers", []);
    }
    public clearCookies(): void{
        this.cookieService.deleteAll();
    }

    public setSalesRangeFilteredDealers(dealers: number[]): void {
        this.setStoredValue("sales_range_dealers", dealers);
    }

    private setupPrefixes(authenticated: boolean): void {
        if (authenticated) {
            this.emailPrefix = this.cognitoService.getUserEmail() + ".";
        }
    }

}
