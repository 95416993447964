import { createAction, props } from "@ngrx/store";
import { FilterDefaultState } from "../state";

/* Here we define each action type which is used when creating actions */
export const FILTER_STATE_DEFAULT_OBJECT_SET = "[FilterStateDefaults] Defaults Object Set";
export const FILTER_STATE_DEFAULTS_UPDATED = "[FilterStateDefaults] Default Value Updated";

export const setDefaultFilter = createAction(
    FILTER_STATE_DEFAULTS_UPDATED,
    props<{filterName: string, value: any}>()
);
/* Here we actually create the necessary actions needed to maintain our FilterState */
export const setFilterDefaultObject = createAction(
    FILTER_STATE_DEFAULT_OBJECT_SET,
    props<{filterDefaults: FilterDefaultState}>()
);
