<div class="chart-card" [ngClass]="{'chart-horizontal': isHorizontalBar}">
    <div class="chart" *ngIf="dataToRender && dataToRender.length" [ngStyle]="{'width': width }">
        <div class="chart-holder" (click)="closeModalForOutsideClick($event)">
            <canvas baseChart #ng2Chart="base-chart" [datasets]="dataToRender" [labels]="labelsToRender" [options]="chartOptions" [colors]="chartColors" [legend]="showLegend"
                [chartType]="chartType">
            </canvas>
        </div>
        <div class="chart-footer" *ngIf="!hideLegend">
            <chart-legend class="chart-legend" [data]="dataToRender" [legends]="legends" [selectedLegends]="selectedLegends" [selectedLegendsGroups]="selectedLegendsGroups"
                [colors]="colors" (legendClick)="legendClick($event)" (legendGroupClick)="onLegendGroupClick($event)"></chart-legend>
        </div>
    </div>
</div>
