import { HttpClient } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { PresentationTypes } from "app/core/models/presentation-type.enum";
import { CognitoService } from "app/core/services/cognito.service";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetadataService } from "app/core/services/metadata.service";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import { UserCookieService } from "app/core/services/user-cookie.service";
import { timingSafeEqual } from "crypto";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";

export abstract class AbstractPresentationFilterService extends FilterStateService {

    presentationFilters = new Array<string>();
    chipCountUpdated = new EventEmitter<string[]>();
    protected selectionPanelChipCount = {}; // this holds the chip counts for each selection panel displayed
    protected backSelectionSubscription: Subscription;

    constructor(
        protected cookieService: CookieService,
        protected http: HttpClient,
        protected cognitoService: CognitoService,
        protected dmaDataService: DmaDataService,
        protected metadataService: MetadataService,
        protected userCookieService: UserCookieService,
        protected dealerDataService: DealerDataService,
        protected ngrxFilterStateService: NgrxFilterStateService,
    ) {
        super(http, cognitoService, userCookieService, ngrxFilterStateService);
    }


    isPreviousConditionsMet(currentFilterName: FilterName): boolean {
        let allConditionsMet = true;
        const filterNameIndex = this.presentationFilters.findIndex(item => item === currentFilterName.toString());
        if (filterNameIndex === -1) {
            return false;
        }
        for (let i = 0; i < filterNameIndex; i++) {
            if (!this.areConditionsMet(FilterName[this.presentationFilters[i]])) {
                allConditionsMet = false;
            }
        }
        return allConditionsMet;
    }

    getUseSegmentsFlag(): boolean {
        // The `use_ihs_segments` filter is stored in the non-PPT flow, so we have to fetch it directly here instead of using `getFilterValue`
        return this.userCookieService.getStoredValue(FilterName.use_ihs_segments.toString(), [], this.cookiePrefix);
    }

    isVisited(filterName: FilterName): boolean {
        const activeSelectionIndex = this.presentationFilters.findIndex(item => item === this.getFilterValue(FilterName.active_selection));
        const filterNameIndex = this.presentationFilters.findIndex(item => item === filterName.toString());
        return (filterNameIndex !== -1 && filterNameIndex <= activeSelectionIndex) || this.getFilterValue<boolean>(FilterName.report_building);
    }

    getDmaName(dmaId: number): string {
        const res = this.dmaDataService.getDmaName(dmaId);
        return res === "Loading..." ? undefined : res;
    }

    getDmaNames(dmaIds: number[]): string[] {
        return dmaIds.map(dmaId => (this.getDmaName(dmaId)));
    }

    isLastSelection(): boolean {
        const activeSelection = this.getFilterValue(FilterName.active_selection);
        return activeSelection === this.presentationFilters[this.presentationFilters.length - 1];
    }

    chipCountChange(filterName: FilterName, chipCount: number): void {
        this.selectionPanelChipCount[filterName] = chipCount;
        this.chipCountUpdated.emit([filterName.toString()]);
    }

    nextActiveSelection(currentFilterName: FilterName): void {
        if (this.areConditionsMet(currentFilterName)) {
            const activeSelection = this.getFilterValue<string>(FilterName.active_selection);
            const nextIndex = this.presentationFilters.findIndex(filter => filter === activeSelection) + 1;
            if (nextIndex < this.presentationFilters.length) {
                this.setActiveSelection(FilterName[this.presentationFilters[nextIndex]]);
            }
        }
    }

    lastActiveSelection(currentFilterName: FilterName): boolean {
        const activeSelection = this.getFilterValue<string>(FilterName.active_selection);
        const lastIndex = this.presentationFilters.findIndex(filter => filter === activeSelection) - 1;
        if (lastIndex >= 0) {
            this.setActiveSelection(FilterName[this.presentationFilters[lastIndex]]);
            return false;
        } else {
            this.resetFilter(currentFilterName);
            return true;
        }
    }

    setActiveSelection(filterName: FilterName): void {
        if (this.backSelectionSubscription && !this.backSelectionSubscription.closed) {
            this.backSelectionSubscription.unsubscribe();
        }
        if (this.isVisited(filterName)) {
            this.backSelectionSubscription = this.filtersUpdated.subscribe(filtersUpdated => { // this will reset the future filters if the current selection panel is updated

                if (filtersUpdated.includes(filterName.toString())) {
                    this.clearNextFilters(filterName);
                    this.backSelectionSubscription.unsubscribe();
                }
            });
        }
        this.setFilterValue(FilterName.active_selection, filterName.toString());
    }

    clearNextFilters(name: FilterName): void {
        const nextFilterNames = this.getNextFilterNames(name);
        const futureFilters = [];
        for (let i = 0; i < nextFilterNames.length; i++) {
            const filterName = FilterName[nextFilterNames[i]];
            if (!!(this.getFilterValue(filterName) as any[]).length) {
                futureFilters.push(filterName);
            }
        }
        this.resetFilters(futureFilters);
    }

    getNextFilterNames(filterName: FilterName): string[] {
        const filterNameIndex = this.presentationFilters.findIndex(item => item === filterName.toString());
        return this.presentationFilters.slice(filterNameIndex + 1);
    }

    abstract createWorkbook(): void; // abstract
    abstract areConditionsMet(filterName: FilterName, conditionFlags?: {[key: string]: any}): boolean; // abstract
    abstract startPresentationBuild(presentationType: PresentationTypes): Promise<boolean>; // abstract
    abstract arePresentationFiltersReady(): boolean; // abstract
}
