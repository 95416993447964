import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { PanelState } from "app/core/models/panel-state.enum";
import { BehaviorSubject } from "rxjs";

import { SwipeDetectorDirective, SwipeDirection } from "../directives/swipe-detector.directive";

@Component({
    selector: "collapsible-panel",
    templateUrl: "./collapsible-panel.component.html",
    styleUrls: ["./collapsible-panel.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CollapsiblePanelComponent implements OnInit, OnDestroy {
    hovering: boolean;
    hoverStripTimeout: ReturnType<typeof setTimeout>;
    PanelState = PanelState;
    @Input() collapseLeft = false;
    @Input() collapseRight = false;
    @Input() overlayHide = false;
    animationTimeout = 600;

    @ViewChild(SwipeDetectorDirective, { static: true }) swipeDetector: SwipeDetectorDirective;

    public readonly panelState = new BehaviorSubject<PanelState>(PanelState.Open);

    ngOnInit(): void {
        this.swipeDetector.swiped.subscribe(this.swiped.bind(this));
    }

    ngOnDestroy(): void {
        this.swipeDetector.swiped.unsubscribe();
    }

    swiped(direction: SwipeDirection): void {
        switch (direction) {
            case SwipeDirection.Left:
                if (this.collapseLeft) {
                    this.togglePanel();
                }
                break;
            case SwipeDirection.Right:
                if (this.collapseRight) {
                    this.togglePanel();
                }
                break;
        }
    }

    toggleStripClick(): void {
        this.togglePanel();
    }

    showStrip(): void {
        this.hovering = true;
        clearTimeout(this.hoverStripTimeout);
    }

    hideStrip(): void {
        clearTimeout(this.hoverStripTimeout);
        this.hoverStripTimeout = setTimeout(() => {
            this.hovering = false;
        }, 250);
    }

    togglePanel(): void {
        this.hovering = false;
        switch (this.panelState.value) {
            case PanelState.Open:
                this.panelState.next(PanelState.Closing);
                setTimeout(() => {
                    this.panelState.next(PanelState.Closed);
                }, this.animationTimeout);
                break;
            case PanelState.Closed:
                this.panelState.next(PanelState.Opening);
                setTimeout(() => {
                    this.panelState.next(PanelState.Open);
                }, this.animationTimeout);
                break;
        }
    }
}
