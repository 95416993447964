import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Chart } from "chart.js";
import { cloneDeep, debounce, isEqual, merge, pullAllWith, remove } from "lodash";
import { BaseChartDirective, Color } from "ng2-charts";
import { Subscription } from "rxjs";

import { ChartDefaultOptions, LineChartDefaultOptions } from "../../../components/charts/chart/chart-defaults.options";
import { ChartGridLineLabelOverride } from "../../../components/charts/chart/chart-overrides.utils";
import { ChartUtils } from "../../../components/charts/chart/chart.utils";
import { Shares } from "../../../components/charts/chart/shares.plugin";
import { DisplayFig } from "../../../components/pipes/display-fig.pipe";
import { SigFig } from "../../../components/pipes/sigfig.pipe";
import { ChartDatasetElement, ChartLabelElement, DataSet, DataSetGroup } from "../../../models/chart-data.model";
import { ChartOptions } from "../../../models/chart-options.model";
import { ChartPopupService } from "../../../services/chart-popup.service";
import { OverlayService } from "../../../services/overlay.service";
import { HorizontalBarChartDefaultOptions } from "../../charts/chart/chart-defaults.options";
import { ChartColor } from "../chart/chart-color.utils";
import { BarChartDefaultOptions } from "../chart/chart-defaults.options";

@Component({
    selector: "chart",
    templateUrl: "./chart.component.html",
    styleUrls: ["./chart.component.scss"]
})
export class ChartComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild("ng2Chart") ng2Chart: BaseChartDirective;

    @Input() chartType = "line";
    @Input() percentLabel = false;
    @Input() maxYScale = false;
    @Input() chartOptions: ChartOptions;
    @Input() dataToRender: DataSet[] = [];      // displayed data
    @Input() labelsToRender: string[][] = [];   // rendered labels
    @Input() legends: DataSetGroup[];           // Legends data only used with "grouped" legends.
    @Input() hideLegend = false;                // Hide legends.
    @Input() disableLabelInteractions = false;       // For disable hover and click events.
    // All colors to be used in chart, in an array of hex values format;
    @Input() colors: string[] = ChartColor.getColorPalette();

    // Allows setting simple styles without breaking encapsulation
    @Input() chartWidth = "inherit";

    @Output() legendGroupClick: EventEmitter<DataSetGroup> = new EventEmitter<DataSetGroup>();
    @Output() chartLabelClick: EventEmitter<ChartLabelElement> = new EventEmitter<ChartLabelElement>();
    @Output() chartDatasetClick: EventEmitter<ChartDatasetElement> = new EventEmitter<ChartDatasetElement>();

    chartColors: Color[];
    showLegend = false; // disable chart.js default legend
    selectedLegendsGroups: DataSetGroup[] = [];
    selectedLegends: DataSet[] = [];

    isHorizontal = false;

    get isBar(): boolean {
        return this.chartType === "groupedBars" || this.chartType === "bar";
    }

    get isHorizontalBar(): boolean {
        return this.chartType === "horizontalBar" || this.chartType === "horizontalRoundedBar";
    }

    get width(): string {
        return this.chartWidth === "inherit" ? "inherit" : this.chartWidth;
    }

    // Chart element for selected data point e.g. clicking on a bar in a barchart.
    private selectedChartElement: any;
    private overlayServiceSubscription: Subscription;
    private popupCloseSubscription: Subscription;

    constructor(
        private popupService: ChartPopupService,
        private overlayService: OverlayService,
        public elementRef: ElementRef
    ) {
        // Data may change several times before settling. Wait 25 millisecond before updating chart.
        this.forceRedraw = debounce(this.forceRedraw.bind(this), 25);
    }

    ngOnInit(): void {
        // To juggle two context, this ChartComponent and the Chart bound as the context in the handlers.
        const __this = this;
        const defaults = cloneDeep(ChartDefaultOptions);
        // Add hover handler.
        defaults["onHover"] = function(): void {
            __this.onChartHover.apply(__this, [].concat([].slice.call(arguments), this));
        };
        // A click handler.
        defaults["onClick"] = function(): void {
            __this.onChartClick.apply(__this, [].concat([].slice.call(arguments), this));
        };

        // Override ng2-chart update function. Refresh chart if the data size changes.
        // This previously threw errors because it didn't validate the size of old and new data.
        BaseChartDirective.prototype["updateChartData"] = function(newDataValues: number[] | any[]): void {
            if (this.chart.data.datasets.length !== newDataValues.length) {
                this.refresh();
                return;
            }
            if (
                !this.chart.data.datasets.hasOwnProperty("barSpacing") ||
                !this.chart.data.datasets.hasOwnProperty("barPercentage") ||
                !this.chart.data.datasets.hasOwnProperty("maxBarThickness")
            ) {
                this.chart.data.datasets.barPercentage = 0.9;
                this.chart.data.datasets.maxBarThickness = 55;
                this.chart.data.datasets.barSpacing = 0.8;
            }
            if (Array.isArray(newDataValues[0].data)) {
                this.chart.data.datasets.forEach((dataset: any, i: number) => {
                    dataset.data = newDataValues[i].data;

                    if (newDataValues[i].label) {
                        dataset.label = newDataValues[i].label;
                    }
                });
            } else {
                this.chart.data.datasets[0].data = newDataValues;
            }
        };

        // Add percent symbol if neccessary.
        defaults.scales.yAxes[0].ticks.callback = (label) => `${this.percentLabel ? SigFig.staticTransform(label) + "%" : DisplayFig.staticTransform(label)}`;

        // Override styling for bar chart type.
        // Scales have to be registered seperately otherwise they interfere with eachother.
        if (this.chartType === "groupedBars") {
            this.chartType = "bar";
            merge(defaults, BarChartDefaultOptions);
        } else if (this.chartType === "bar") {
            merge(defaults, BarChartDefaultOptions);
        } else if (this.chartType === "line") {
            merge(defaults, LineChartDefaultOptions);
        } else if (this.isHorizontalBar) {
            this.isHorizontal = true;
            this.chartOptions = merge(Chart.defaults.horizontalBar, HorizontalBarChartDefaultOptions);
        }

        // Merge chart options defaults with passed chart options.
        // This allows for components using this component to pass in partial options.
        this.chartOptions = merge(defaults, this.chartOptions);

        // Set initial chart colors.
        this.chartColors = ChartColor.toChartJsColorArray(this.colors);

        // Opening overlay should close popup.
        this.overlayServiceSubscription = this.overlayService.overlayState.subscribe(state => {
            if (state.visible && this.popupService.isOpened) {
                this.popupService.hideModal();
            }
        });

        // Reset gridline colors when popup is closed.
        this.popupCloseSubscription = this.popupService.close.subscribe(() => {
            if (this.ng2Chart) {
                ChartUtils.updateLabelColors(this.ng2Chart.chart, undefined, "rgba(217, 217, 217, 1)", true);
            }
        });

        Chart.plugins.register(Shares as any);
    }

    ngOnChanges(changes: SimpleChanges): void {
        let update = false;
        if ("dataToRender" in changes && !changes.dataToRender.firstChange) {
            if (this.isHorizontal) {
                this.setXAxisScale();
            } else {
                this.setYAxisScale();
            }
            this.resetColors(false);
            update = true;

            if ( // only show vertical girdlines only if there is data to support it.  Only applicable on bar type charts
                this.isBar
                && changes.dataToRender.currentValue.length
                && changes.dataToRender.currentValue[0].data
                && changes.dataToRender.currentValue[0].data.length === 1
            ) {
                this.chartOptions.scales.xAxes[0].gridLines.display = false;
            } else {
                this.chartOptions.scales.xAxes[0].gridLines.display = true;
            }

            // If new value is empty, filters were changed and previous selection state should be resetted.
            if (changes.dataToRender.currentValue && changes.dataToRender.currentValue.length === 0) {
                this.resetState();
            }
        }
        // Some chart have dynamic color schemes depending on filter selections.
        if ("colors" in changes && !isEqual(changes.colors.currentValue, changes.colors.previousValue)) {
            this.chartColors = ChartColor.toChartJsColorArray(this.colors);
            update = true;
        }

        if (update) {
            this.forceRedraw();
        }
    }

    ngOnDestroy(): void {
        if (this.overlayServiceSubscription) {
            this.overlayServiceSubscription.unsubscribe();
        }
        if (this.popupCloseSubscription) {
            this.popupCloseSubscription.unsubscribe();
        }
    }

    resetColors(selected: boolean): void {
        // Selected items are not opague.
        this.chartColors = ChartColor.toChartJsColorArray(this.colors, selected);
    }

    legendClick(legend: DataSet): void {
        const isDataSetEqual = (ds: DataSet, other: DataSet): boolean => ds.id === other.id && ds.label === other.label;

        const wasSelected = this.selectedLegends.find(s => isDataSetEqual(s, legend));
        // If item was previously selected, remove item from the selected items list.
        if (wasSelected) {
            this.selectedLegends = this.selectedLegends.filter((sl: DataSet) => !isDataSetEqual(sl, wasSelected));
            // Check all selected groups to see if they still contain selected items.
            // If a group has no selected items, unselect the group.
            if (this.selectedLegendsGroups.length > 0) {
                this.selectedLegendsGroups = this.selectedLegendsGroups.filter(sg => {
                    let hasChild = false;
                    for (let i = 0; i < sg.dataset.length; i++) {
                        const item = sg.dataset[i];
                        if (!!this.selectedLegends.find(s => item.id === s.id && item.label === s.label)) {
                            hasChild = true;
                            break;
                        }
                    }
                    return hasChild;
                });
            }
        } else {
            this.selectedLegends.push(legend);
            // The legends property is only used with grouped legends.
            if (this.legends) {
                const groupOfSelectedItem = this.legends.find(g => g.id === legend.id);
                // If this selection results in all items in a group being selected,
                // add the group as a selected group.
                let allItemSelected = true;
                for (let i = 0; i < groupOfSelectedItem.dataset.length; i++) {
                    const groupItem = groupOfSelectedItem.dataset[i];
                    if (!!!this.selectedLegends.find(sl => sl.id === groupItem.id && sl.label === groupItem.label)) {
                        allItemSelected = false;
                        break;
                    }
                }
                // If all item is selected, add the group to selected groups.
                if (allItemSelected) {
                    this.selectedLegendsGroups.push(groupOfSelectedItem);
                }
            }
        }

        this.updateLegendSelection();
    }

    onLegendGroupClick(dsg: DataSetGroup): void {
        const wasSelected = this.selectedLegendsGroups.find(sg => sg.id === dsg.id && sg.label === dsg.label);
        if (wasSelected) {
            // Remove all group items from selected legends items.
            pullAllWith(this.selectedLegends, dsg.dataset,
                (a: DataSet, b: DataSet) => a.id === b.id && a.label === b.label);
            // Remove selected group from selected legends groups.
            remove(this.selectedLegendsGroups, (sg) => sg.id === wasSelected.id && sg.label === wasSelected.label);
            this.updateLegendSelection();
            this.forceRedraw();
        } else {
            this.setLegends(dsg.dataset);
            this.selectedLegendsGroups.push(dsg);
        }
        this.legendGroupClick.emit(dsg);
    }

    setLegends(legends: DataSet[], override: boolean = false): void {
        // Create a new array container to prevent modifying original.
        this.selectedLegends = override ? legends.slice() : this.selectedLegends.concat(legends.slice());
        this.updateLegendSelection();
        this.forceRedraw();
    }

    updateLegendSelection(): void {
        // Change chart colors / opacity depending on new selection state.
        if (this.selectedLegends.length) {
            this.resetColors(true);
            for (let i = 0; i < this.selectedLegends.length; i++) {
                const foundIndex = this.dataToRender.findIndex((item) => this.selectedLegends[i].id === item.id && this.selectedLegends[i].label === item.label);
                if (foundIndex === -1) {
                    continue;
                }
                this.chartColors[foundIndex] = ChartColor.toChartJsColor(this.colors[foundIndex]);
            }
        } else {
            this.resetColors(false);
        }
    }

    forceRedraw(): void {
        if (this.ng2Chart) {
            this.ng2Chart.labels = this.labelsToRender;
            // label updates are super buggy, this fixes it
            // https://github.com/valor-software/ng2-charts/issues/692
            this.ng2Chart.ngOnChanges({});
            if (this.selectedChartElement || this.selectedLegends.length) {
                ChartUtils.focusChartElement(this.ng2Chart.chart, this.selectedChartElement, this.selectedLegends);
            }
            if (this.chartType === "line") {
                this.updateLegendSelection();
            }
        }
    }

    forceResize(): void {
        if (this.ng2Chart) {
            this.ng2Chart.chart.resize();
        }
    }

    resetState(): void {
        this.selectedChartElement = undefined;
        this.selectedLegends = [];
        this.selectedLegendsGroups = [];
    }

    /**
     * Sets the Y Axis Scale based on the max value of the data set,
     * unless the maxYScale prop is true. If that is true and percentLabel
     * is true, the max Y scale will be 100%.
     * This is for scenarios like a percentage chart that should show 0-100% on the
     * y-axis when the max value does not go to 100%.
     */
    setYAxisScale(): void {
        const max = this.niceNum(this.getMaxValue());
        if (this.maxYScale && this.percentLabel) {
            this.chartOptions.scales.yAxes[0].ticks.max = 100;
            this.chartOptions.scales.yAxes[0].ticks.maxTicksLimit = 4;
            this.chartOptions.scales.yAxes[0].ticks.stepSize = 25;
        } else {
            this.chartOptions.scales.yAxes[0].ticks.max = max;
            this.chartOptions.scales.yAxes[0].ticks.stepSize = max / 5;
        }
    }

    setXAxisScale(): void {
        const max = this.niceNum(this.getMaxValue());
        this.chartOptions.scales.xAxes[0].ticks.max = max;
        this.chartOptions.scales.xAxes[0].ticks.stepSize = max / 5;
    }

    setXAxisScaleCustom(customTicks: number[][]): void {
        // const max = this.niceNum(this.getMaxValueCustom(customTicks));
        const max = customTicks[0].length * customTicks.length;
        this.chartOptions.scales.xAxes[1].ticks.min = max;
        this.chartOptions.scales.xAxes[1].ticks.max = max * customTicks.length;
        this.chartOptions.scales.xAxes[1].ticks.stepSize = max / customTicks[0].length;
        this.forceResize();
    }

    getMaxValueCustom(customTicks: number[][]): number {
        let high = 5;
        for (let i = 0; i < customTicks.length; i++) {
            const itemData = customTicks[i];
            for (let j = 0; j < itemData.length; j++) {
                if (high < itemData[j]) {
                    high = itemData[j];
                }
            }
        }
        if (this.percentLabel) {
            high = Math.min(high, 100);
            return high > .1 ? high : .1;
        }
        return high;
    }

    getMaxValue(): number {
        let high = 5;
        for (let i = 0; i < this.dataToRender.length; i++) {
            const itemData = this.dataToRender[i].data;
            for (let j = 0; j < itemData.length; j++) {
                if (high < itemData[j]) {
                    high = itemData[j];
                }
            }
        }

        if (this.percentLabel) {
            high = Math.min(high, 100);
            return high > .1 ? high : .1;
        }
        return high;
    }

    niceNum(range: number): number {
        let exponent: number; // exponent of range
        let fraction: number; // fractional part of range
        let niceFraction: number; // nice, rounded fraction

        exponent = Math.floor(Math.log10(range));
        fraction = range / Math.pow(10, exponent);

        // Want to round to two significant decimal places Math.ceil(x*10)/10 and
        //  we want to round to the nearest multiple of 5 Math.ceil(x/5)*5...
        // (all our graphs divided into fifths)
        niceFraction = Math.ceil(fraction * 2) / 2;

        return niceFraction * Math.pow(10, exponent);
    }

    onChartHover(event: MouseEvent, active: any[], chart: Chart): void {
        let element;

        // Hover over a chart element e.g. bar in barchart.
        if (this.isBar && active.length) {
            // Hovering over a selected chart element (bar);
            const sameElement = this.selectedChartElement &&
                active[0]._datasetIndex === this.selectedChartElement._datasetIndex &&
                active[0]._index === this.selectedChartElement._index;

            // Allow hover in following conditions:
            // 1) Hovering on a focused chart element.
            // 2) Hovering on a chart element with no focused chart element when no legend is selected.
            // 3) Hovering on a chart element with a focused chart element when no legend is selected.
            // 4) Hovering on a chart select that was selected in the legends.
            if ((!this.selectedLegends.length && sameElement) ||
                (!this.selectedLegends.length && !this.selectedChartElement) ||
                (!this.selectedLegends.length && this.selectedChartElement) ||
                this.isInSelectedLegends(active[0])) {
                element = active[0];
            }
            element = active[0];
        } else if (this.isHorizontalBar) {
            // No hover effect currently for bar charts
            return;
        } else {
            // Don't allow hover on label when chart element is selected.
            if (!this.disableLabelInteractions && !this.selectedChartElement) {
                // Look for a label near mouse cursor.
                element = this.isBar ?
                    ChartUtils.getNearbyBarLabel(event, chart) :
                    ChartUtils.getNearbyLineLabel(event, chart);

                // Change label colors to focus on hovered element or reset to not focused.
                ChartUtils.updateLabelColors(chart, element);
                chart.update();
            }
        }

        // Change cursor style base on hover.
        event.target["style"].cursor = element ? "pointer" : "default";
    }

    onChartClick(event: MouseEvent, active: any[], chart: Chart): void {
        let element;
        let isLabel;

        // Click on dataset e.g. a single bar.
        if (this.isBar && active.length) {
            // Verify if the selected chart element (bar) has been clicked previously.
            const sameElement = this.selectedChartElement &&
                active[0]._datasetIndex === this.selectedChartElement._datasetIndex &&
                active[0]._index === this.selectedChartElement._index;

            // Allow clicking on elements in following conditions:
            // 1) Clicking a focused chart element.
            // 2) Clicking a chart element with no focused chart element when no legend is selected.
            // 3) Clicking a chart element with a focused chart element when no legend is selected.
            // 4) Clicking a chart select that was selected in the legends.
            if ((!this.selectedLegends.length && sameElement) ||
                (!this.selectedLegends.length && !this.selectedChartElement) ||
                (!this.selectedLegends.length && this.selectedChartElement) ||
                this.isInSelectedLegends(active[0])) {
                // If same dataset was clicked, unhighlight dataset and close modal.
                if (sameElement) {
                    // Hiding modal will invoke subscription below to unset selectedChartElement and focus.
                    this.popupService.hideModal();
                    return;
                } else {
                    this.selectedChartElement = active[0];
                    element = active[0];
                    isLabel = false;
                    ChartUtils.focusChartElement(chart, element, this.selectedLegends);
                    // If modal closes, reset chart bar selection.
                    const sub = this.popupService.close.subscribe(() => {
                        this.selectedChartElement = undefined;
                        // If chart was cleared and modal was closed, don't attempt to reset a non-existing chart.
                        if (!!chart.canvas || !!chart.ctx) {
                            if (this.selectedLegends.length) {
                                ChartUtils.focusChartElement(chart, this.selectedChartElement, this.selectedLegends);
                            } else {
                                ChartUtils.focusReset(chart);
                            }
                        }
                        sub.unsubscribe();
                    });
                }
            }
        } else if (this.isHorizontalBar) {
            // No click effect currently for bar charts
            return;
        } else {
            // If no chart element is selected, allow clicking on chart label.
            if (!this.disableLabelInteractions && !this.selectedChartElement) {
                element = this.isBar ?
                    ChartUtils.getNearbyBarLabel(event, chart) :
                    ChartUtils.getNearbyLineLabel(event, chart);

                if (!element) {
                    return;
                }

                isLabel = true;
                ChartUtils.updateLabelColors(chart, element, "rgba(64, 64, 64, 1)");
                chart.update();
            }
            if (this.selectedChartElement && this.popupService.isOpened) {
                this.popupService.hideModal();
            }
        }

        // Only emit click if a valid click was made.
        if (element) {
            const screenPosition = ChartUtils.toScreenPosition({
                // Bar charts render labels and offsets differently.
                x: element._model.x - ((element._model.width + 90 || 0) / 2),
                y: isLabel ? 0 : element._model.y
            }, chart);
            const datasetMeta = this.dataToRender.map((d, i) => ({
                value: d.data[element._index],
                id: d.id,
                color: ChartColor.toRGBAString(this.colors[i])
            }));

            if (active.length) {
                const chartDatasetElement = {
                    position: screenPosition,
                    datasetIndex: element._datasetIndex, // Index of item in dataToRender[index].data[datasetIndex].
                    index: element._index, // Index of item in dataToRender.
                    label: this.labelsToRender[element._index],
                    model: {
                        datasetLabel: element._model.datasetLabel,
                        x: element._model.x,
                        y: element._model.y,
                        width: element._model.width,
                        backgroundColor: element._model.backgroundColor,
                        horizontal: element._model.horizontal
                    },
                    datasetMeta
                } as ChartDatasetElement;

                this.chartDatasetClick.emit(chartDatasetElement);
            } else {
                const chartLabelElement = {
                    position: screenPosition,
                    datasetIndex: element._datasetIndex, // Index of item in dataToRender[index].data[datasetIndex].
                    index: element._index, // Index of item in dataToRender.
                    label: this.labelsToRender[element._index],
                    datasetMeta
                } as ChartLabelElement;

                this.chartLabelClick.emit(chartLabelElement);
            }
        }
    }

    @HostListener("document:click", ["$event"])
    closeModalForOutsideClick(event: MouseEvent): void {
        if (this.popupService.isOpened) {
            // If clicked element is the chart canvas or the modal, do not attempt to close modal.
            const modal = document.getElementById("chart-popup");
            const isClickedInside = (event.target as any).tagName === "CANVAS" || modal.contains(event.target as any);
            if (!isClickedInside) {
                this.popupService.hideModal();
            }
        }
    }

    private isInSelectedLegends(element: any): boolean {
        return !!this.selectedLegends.find(l =>
            l.id === this.dataToRender[element._datasetIndex].id &&
            l.label === this.dataToRender[element._datasetIndex].label);
    }
}
