import { Component, Input } from "@angular/core";

@Component({
    selector: "zip-upload-status",
    templateUrl: "./zip-upload-status.component.html",
    styleUrls: ["./zip-upload-status.component.scss"]
})
export class ZipUploadStatusComponent {
    @Input() hasErrors: boolean;
    @Input() uploadFailure: boolean;
    successMsg = "Success! Custom zip codes applied.";
    partialSuccessMsg = "Some zip codes may have been excluded.";
    failureMsg = "Zip Code upload failed";
    constructor() {}
}
