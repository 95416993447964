import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environment";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/pipeable";
import * as R from "fp-ts/Record";
import * as S from "fp-ts/Semigroup";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subject } from "rxjs";

import { HttpServiceBase } from "../base/services/http-service-base.service";
import { DMAResponse } from "../models/dma-response";
import { MakesResponse } from "../models/makes-response";
import { SegmentsResponse } from "../models/segments-response";
import { UserResponse } from "../models/user-response";
import { CognitoService } from "./cognito.service";

// eslint-disable-next-line import/no-deprecated
const lastSemigroup = S.getLastSemigroup<string>();

type Params = Record<string, string | string[]>;

type Headers = Record<string, string>;

interface HttpOpts {
  observe: "body";
  responseType: "json";
  params: Params;
  headers: Headers;
  withCredentials: true;
}

interface HttpOptsCfg {
  hasJson: boolean;
  withCsrf: boolean;
  withBearer?: string;
  params: O.Option<Params>;
}

@Injectable()
export class AutoAnalyzerService extends HttpServiceBase {
    private csrf: O.Option<string> = O.none;
    protected path = "/autoanalyzer";
    protected emailPrefix: string;
    protected necessarySuffixes: string[] = ["getDMA", "getMakes", "getSegments"];
    private authToken: any;
    private isAuth: boolean;

    constructor(
            protected http: HttpClient,
            protected cookieService: CookieService,
            protected cognitoService: CognitoService
    ) {
        super(http);
        this.setupPrefixes();
        this.isAuth = undefined;
    }

    public getIsAuth(): boolean {
        return this.isAuth;
    }

    public setIsAuth(isAuth: boolean) {
        this.isAuth = isAuth;
    }

    private getHeaders({ hasJson, withCsrf, withBearer }: HttpOptsCfg): Headers {
        const headers: [string, O.Option<string>][] = [
            ["Accept", O.some("application/json")],
            ["Content-Type", hasJson ? O.some("application/json") : O.none],
            ["X-CSRF-Token", withCsrf ? this.csrf : O.none],
            ["Authorization", withBearer ? O.some(`Bearer ${withBearer}`) : O.none],
            ["X-Skip-Token-Interceptor", O.some("x-skip-token-interceptor")],
            ["X-Skip-Interceptor", O.some("x-skip-interceptor")]
        ];

        // eslint-disable-next-line import/no-deprecated
        return pipe(
            headers,
            A.filterMap(([name, maybeValue]) =>
            // eslint-disable-next-line import/no-deprecated
                pipe(
                    maybeValue,
                    O.map<string, [string, string]>(value => [name, value]),
                ),
            ),
            // eslint-disable-next-line import/no-deprecated
            R.fromFoldable(lastSemigroup, A.array),
        );
    }

    private getHttpOpts(cfg: HttpOptsCfg): HttpOpts {
        return {
            observe: "body",
            responseType: "json",
            // eslint-disable-next-line import/no-deprecated
            params: pipe(
                cfg.params,
                O.getOrElse(() => ({})),
            ),
            withCredentials: true,
            headers: this.getHeaders(cfg),
        };
    }

    getUserWithToken(token: string): Observable<Object> {
        const url = `${environment.aaAPIUrl}${environment.aaBaseAPIPath}user`;
        return this.http.get(
            url,
            this.getHttpOpts({ params: O.none, withCsrf: false, hasJson: false, withBearer: token }),
        );
    }

    checkCookieCache(cookieName: string): string | boolean {
        if (this.cookieService.check(cookieName)) {
            return this.cookieService.get(cookieName);
        }
        return false;
    }

    public fetchAuthToken(): Promise<any> {
        const url = `${this.rootUrl}${this.path}/auth`;
        const options = this.createRequestOptions({});
        return this.fetchInterfaceNoCache<any>(url, options).toPromise();
    }

    /**
     * This call is the only call that makes a direct call to AudienceApp for them to set a cookie in the browser
     *
     * @returns Promise<UserResponse>
     */
    public getUser(authToken: any): Observable<UserResponse> {
        const url = `${environment.aaAPIUrl}${environment.aaBaseAPIPath}user`;
        const headers = new HttpHeaders({
            Accept: "application/json",
            Authorization: `Bearer ${JSON.parse(authToken["data"])}`
        });
        const options = this.createRequestOptions({});
        return this.fetchInterfaceNoCache<UserResponse>(url, options, headers);
    }

    public async getDMA(dma: number): Promise<DMAResponse> {
        const url = `${this.rootUrl}${this.path}/dma`;
        const options = this.createRequestOptions({ dma });
        const cookieSuffix = "getDMA";
        if (this.cookieService.check(this.emailPrefix + cookieSuffix)) {
            const cachedDMA = JSON.parse(this.cookieService.get(this.emailPrefix + cookieSuffix));
            if (parseInt(cachedDMA.results["nielsen_dma_id"], 10) === dma[0]) {
                return JSON.parse(this.cookieService.get(this.emailPrefix + cookieSuffix));
            } else {
                this.clearNecessaryCache();
            }
        }
        const response = await this.fetchInterfaceNoCache<DMAResponse>(url, options).toPromise();
        if (response.isSuccess) {
            this.cookieService.set(this.emailPrefix + cookieSuffix, JSON.stringify(response), 1, "/");
        }
        return response;
    }

    public async getMakes(): Promise<MakesResponse> {
        const url = `${this.rootUrl}${this.path}/makes`;
        const options = this.createRequestOptions({});
        const cookieSuffix = "getMakes";
        if (this.cookieService.check(this.emailPrefix + cookieSuffix)) {
            return JSON.parse(this.cookieService.get(this.emailPrefix + cookieSuffix));
        }
        const response = await this.fetchInterfaceNoCache<MakesResponse>(url, options).toPromise();
        this.cookieService.set(this.emailPrefix + cookieSuffix, JSON.stringify(response), 7, "/");
        return response;
    }

    public async getSegments(): Promise<SegmentsResponse> {
        const url = `${this.rootUrl}${this.path}/segments`;
        const options = this.createRequestOptions({});
        const cookieSuffix = "getSegments";
        if (this.cookieService.check(this.emailPrefix + cookieSuffix)) {
            return JSON.parse(this.cookieService.get(this.emailPrefix + cookieSuffix));
        }
        const response = await this.fetchInterfaceNoCache<SegmentsResponse>(url, options).toPromise();
        this.cookieService.set(this.emailPrefix + cookieSuffix, JSON.stringify(response), 7, "/");
        return response;
    }

    public async log605AuthCall(logMessage: string, messageType: string): Promise<any> {
        const url = `${this.rootUrl}${this.path}/605log`;
        return this.postInterfaces<any>(url, { logMessage, messageType }).toPromise();
    }

    public async logMapboxCall(logMessage: string, messageType: string): Promise<any> {
        const url = `${this.rootUrl}${this.path}/logMapbox`;
        return this.postInterfaces<any>(url, { logMessage, messageType }).toPromise();
    }

    protected setupPrefixes(): void {
        this.emailPrefix = this.cognitoService.getUserEmail() + ".";
    }

    protected clearNecessaryCache(): void {
        this.necessarySuffixes.forEach((suffix, idx) => {
            this.cookieService.delete(this.emailPrefix + suffix);
        });
    }

}
