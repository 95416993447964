import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateRangeTypes, FeatureFlagService } from "@at/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { SalesDataTypes } from "app/core/models/sales-data";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetadataService } from "app/core/services/metadata.service";
import * as moment from "moment";
import { Subscription } from "rxjs";

export interface ISalesDataRadioButton {
    label: string;
    value: string;
    hover: string;
}

export interface ISalesDataToggleState {
    name: FilterName;
    value: boolean;
}

@Component({
    selector: "data-source-selector",
    templateUrl: "./data-source-selector.component.html",
    styleUrls: ["./data-source-selector.component.scss"]
}) export class SalesDataSelectorComponent implements OnInit,
    OnDestroy,
    OnChanges {
    @Input() filterName: FilterName;
    @Input() options: ISalesDataRadioButton[];
    @Input() radioGroupName: string;
    SalesDataTypes = SalesDataTypes;

    selectedOption: string;
    use_sales_data_flag: boolean;
    id = "";
    dataDate = "";
    disabled: boolean;
    salesDataEnabled: boolean;

    private filterStateServiceSubscription: Subscription;
    @Output() toggle: EventEmitter<ISalesDataToggleState> = new EventEmitter<ISalesDataToggleState>();

    constructor(
        private filterStateService: FilterStateService,
        private featureFlagService: FeatureFlagService,
        private metadataService: MetadataService
    ) {
        this.use_sales_data_flag = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.selectedOption = this.use_sales_data_flag ? SalesDataTypes.SALES : SalesDataTypes.REGISTRATION;
    }

    async ngOnInit(): Promise<void> {
        if (this.radioGroupName) {
            this.id = this.radioGroupName.replace(/\s/g, "-").toLowerCase();
        }
        this.salesDataEnabled = await this.featureFlagService.viewSalesToggle();
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        await this.getDataDate();
    }

    ngOnDestroy(): void {
        this.filterStateServiceSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.filtersUpdated([this.filterName.toString()]);
    }

    selectOption(option: any): void {
        if(!this.disabled){
            this.use_sales_data_flag = option.value === SalesDataTypes.SALES;

            this.filterStateService.setFilterValue(FilterName.use_sales_data, this.use_sales_data_flag);
            const next: ISalesDataToggleState = {
                name: this.filterName, value: this.use_sales_data_flag
            };
            this.filterStateService.setFilterValue(FilterName.dateRangeType, DateRangeTypes.ROLLING12);
            this.selectedOption = option.value;
            this.toggle.emit(next);
            this.getDataDate();
        }
    }

    filtersUpdated(changes: string[]): void {
        if (this.filterName && changes.includes(this.filterName.toString())) {
            const current = this.filterStateService.getFilterValue<string>(this.filterName);
            this.selectedOption = current ? SalesDataTypes.SALES : SalesDataTypes.REGISTRATION;
        }
        this.getDisabled();
    }

    async getDataDate(): Promise<void> {
        const date = await this.metadataService.getMaxDate(this.use_sales_data_flag);
        if(this.use_sales_data_flag){
            this.dataDate = moment(date, "YYYYMMDD").format("MMMM DD, YYYY");
        }else{
            this.dataDate =  moment(date, "YYYYMMDD").endOf("month").format("MMMM DD, YYYY");
        }
    }

    getDisabled() {
        this.disabled = this.filterStateService.getFilterValue(FilterName.disable_sales_data_menu);
    }
}
