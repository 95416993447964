<div class="header" (click)="toggleExpand()">
    <div class="icon" [class.expanded]="isExpanded">
        <div class="arrow"></div>
    </div>
    <div class="label">{{label}}</div>
</div>
<div class="body" [class.transitioning]="transitioning" [class.expanded]="isExpanded">
    <ng-container *ngIf="isExpanded || renderElWhenCollapsed">
        <ng-content></ng-content>
    </ng-container>
</div>
