<div class="curtain" [class.transitioning]="transitioning" [class.displayed]="displayCurtain"></div>
<div
    *ngIf="displayModal"
    class="confirm-modal"
    [attr.modal-id]="config.viewConfig.id"
    [class.stop]="config.viewConfig.icon === ModalIcon.STOP"
    [class.caution]="config.viewConfig.icon === ModalIcon.WARNING"
    [class.standard]="config.viewConfig.icon === ModalIcon.STANDARD">
    <div class="confirm-header">
        <div class="confirm-header-container">
            <div *ngIf="config.viewConfig.icon !== ModalIcon.STANDARD" class="icon"></div>
            <div class="text">{{config.viewConfig.header}}</div>
            <div class="close" (click)="cancel(undefined, true)"></div>
        </div>
    </div>

    <ng-container *ngIf="config.viewConfig.contentTmplRef; else defaultTmpl">
        <ng-container *ngTemplateOutlet="config.viewConfig.contentTmplRef; context: config.viewConfig.contentTmplData">
        </ng-container>
    </ng-container>

    <ng-template #defaultTmpl>
        <div class="confirm-content" [innerHTML]="config.viewConfig.message | BoldText"></div>
    </ng-template>

    <div class="confirm-buttons">
        <div class="confirm-buttons-container">
            <div class="confirm act-btn" [class.invalid-dma]="!config.viewConfig.validDma" (click)="confirm()">{{(config.viewConfig.confirmLabelText || 'CONFIRM')}}</div>
            <div [class]="config.viewConfig.icon !== ModalIcon.STANDARD ? 'cancel act-btn' : 'standard-action-two act-btn'" (click)="cancel(undefined, false)">{{config.viewConfig.cancelLabelText ?? 'Cancel'}}</div>
        </div>
    </div>
</div>
