import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CognitoService } from "app/core/services/cognito.service";
import { environment } from "environment";
import { isEqual } from "lodash";
import { Subscription } from "rxjs";

import { BaseChipValue, BaseSelectionSectionComponent, ChipCount, FilterSort, TopNContext } from "../../../components/selection-section/selection-section.component";
import { FilterName } from "../../../models/filter-name.enum";
import { AbstractPPTDataService } from "../../services/ppt-data.service.abstract";
import { AbstractPresentationFilterService } from "../../services/presentation-filter.service.abstract";
import { AbstractTitleBuilderService } from "../selection-panel/title-builder.service.abstract";

@Component({
    template: "./selection-panel.component.abstract.html",
})
export class AbstractSelectionPanelComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(BaseSelectionSectionComponent, { static: true }) selectionSection: BaseSelectionSectionComponent;

    title = "";
    subtitle = "";
    name = "";
    conditionsMet = false;

    // Bind as property to be used in the template.
    FilterName = FilterName;
    filterName: FilterName;
    dataFilterName: FilterName;
    spotlightedDealerId: number[] = [];
    // Top zones irrespective of what zone is selected.
    topZones: { id: string; value: string }[] = [];
    // Top zones based on what is selected.
    topSelectedZones: string[] = [];

    selectionsLimit = 0;
    topXButtonDisabled = false;
    clearButtonDisabled = false;
    searchButtonDisabled = false;
    sortAreaDisabled = false;
    radiusButtonDisabled = false;
    lastSelectionScreen = false;
    refreshingSelectionScreen = true;
    presentationBuilding = false;
    resetFirstSelection = false;
    selectedChips: BaseChipValue;
    canViewMultipleMakes = false;

    private presentationFilterServiceSubscription: Subscription;
    private selectionSelectionsSubscription: Subscription;
    private chipCountSubscription: Subscription;
    private NUMBER_OF_TOP_ZONES = 2;

    techEmail = environment.techEmail;

    allDmaLoadedSubscription;

    heapReportParams = {
        geography: null,
        primaryDealer: null,
        makes: [],
        segments: [],
        models: [],
        competitors: [],
        adZones: [],
        primaryDealerSelected: false,
        dma: []
    };

    constructor(
        public presentationFilterService: AbstractPresentationFilterService,
        public pptDataService: AbstractPPTDataService,
        public titleBuilderService: AbstractTitleBuilderService,
        public cognitoService: CognitoService,
    ) { }

    ngOnInit(): void {
        // this.selectionSection.loadedOptions = false;
        this.presentationFilterServiceSubscription = this.presentationFilterService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.chipCountSubscription = this.presentationFilterService.chipCountUpdated.subscribe(this.filtersUpdated.bind(this));
        this.filtersUpdated([FilterName.active_selection.toString(), FilterName.report_building.toString()]);

        if (this.filterName) {
            this.filtersUpdated([this.filterName.toString()]);
        }
    }

    ngAfterViewInit(): void {
        this.selectionSection.filterService = this.presentationFilterService;
        this.selectionSelectionsSubscription = this.selectionSection.selectedSelections.subscribe(this.updateSelections.bind(this));
    }

    ngOnDestroy(): void {
        // if (this.presentationFilterServiceSubscription) {
        //     this.presentationFilterServiceSubscription.unsubscribe();
        // }
        if (this.selectionSelectionsSubscription) {
            this.selectionSelectionsSubscription.unsubscribe();
        }
        if (this.chipCountSubscription) {
            this.chipCountSubscription.unsubscribe();
        }
    }

    updateSelections(values: any[]): void {
        if (!this.refreshingSelectionScreen) {
            if (!values || values.length === 0) {
                return;
            }
            if (this.filterName === FilterName.spotlight_dealer) {
                values = values[0];
            }
            if (isEqual(values, this.presentationFilterService.getFilterValue(this.filterName))) {
                return;
            }

            this.presentationFilterService.setFilterValue(this.filterName, values);
        }
    }

    nextFilter(): void {
        this.presentationFilterService.nextActiveSelection(this.filterName);
    }

    lastFilter(): void {
        this.resetFirstSelection = this.presentationFilterService.lastActiveSelection(this.filterName);
        if (this.resetFirstSelection === true) {

            for (let i = 0; i < this.selectionSection.chipValues.length; i++) {

                if (this.selectionSection.chipValues[i].isSelected === true) {
                    this.selectedChips = this.selectionSection.chipValues[i];
                }
            }
            if (this.selectedChips.isSelected === true) {
                this.selectionSection.chipChanged(this.selectedChips);
            }
        }
    }

    filtersUpdated(which: string[]): void {
        if (which.includes(FilterName.report_building.toString())) {
            this.presentationBuilding = this.presentationFilterService.getFilterValue<boolean>(FilterName.report_building);
        }

        if (this.filterName && which.includes(this.filterName.toString())) {
            this.conditionsMet = this.presentationFilterService.areConditionsMet(this.filterName);
        }

        this.updateActiveFilterAndDMAFilters(which);
    }

    private updateActiveFilterAndDMAFilters(which: string[]): void {
        if (this.isActiveSelectionOrDMAFilter(which)) {
            this.refreshingSelectionScreen = true;
            if (this.selectionSection) {
                this.resetSelectionPanel();
            }
            const newFilterName: FilterName = FilterName[this.presentationFilterService.getFilterValue<string>(FilterName.active_selection)];
            this.filterName = newFilterName;
            this.updateStepText();
            this.lastSelectionScreen = this.presentationFilterService.isLastSelection();
            this.conditionsMet = this.presentationFilterService.areConditionsMet(this.filterName);

            if (this.filterName === FilterName.dealers) { // this removes the spotlighted dealer from the displayed selections
                this.spotlightedDealerId = [this.presentationFilterService.getFilterValue(FilterName.spotlight_dealer)];
            } else {
                this.spotlightedDealerId = [];
            }

            this.setUpSelectionPanelVariables();
            if (this.selectionSection) {
                this.setDataFilterNameAndSelectionSection(newFilterName);
                this.selectionSection.setupChips();
            }
            this.refreshingSelectionScreen = false;
        }
    }

    private isActiveSelectionOrDMAFilter(which: string[]): boolean {
        return which.includes(FilterName.active_selection.toString())
            || which.includes(FilterName.dma.toString());
    }

    private setDataFilterNameAndSelectionSection(newFilterName: FilterName): void {
        if (this.filterName === FilterName.spotlight_dealer) {
            this.dataFilterName = FilterName.dealers;
            this.selectionSection.filterState = [this.presentationFilterService.getFilterValue<number>(newFilterName)] || [];
        } else {
            this.dataFilterName = this.filterName;
            this.selectionSection.filterState = this.presentationFilterService.getFilterValue(newFilterName) || [];
        }
    }

    protected setUpSelectionPanelVariables(): void {
        // For filters with a selection limit of 1
        if (this.filterName === FilterName.dma) {
            this.topXButtonDisabled = true;
            this.clearButtonDisabled = true;
            this.searchButtonDisabled = false;
            this.radiusButtonDisabled = true;
            this.sortAreaDisabled = true;
            this.selectionsLimit = 1;
            this.name = "Geography";
        }
        if (this.filterName === FilterName.spotlight_dealer) {
            this.topXButtonDisabled = true;
            this.clearButtonDisabled = true;
            this.searchButtonDisabled = false;
            this.radiusButtonDisabled = false;
            this.sortAreaDisabled = true;
            this.selectionsLimit = 1;
            this.name = "Primary Dealer";
        } else if (this.filterName === FilterName.dates) {
            this.topXButtonDisabled = true;
            this.clearButtonDisabled = true;
            this.searchButtonDisabled = true;
            this.radiusButtonDisabled = true;
            this.sortAreaDisabled = true;
            this.selectionsLimit = 10;
            this.name = "Dates";
        } else if (this.filterName === FilterName.makes) {
            this.topXButtonDisabled = true;
            this.clearButtonDisabled = true;
            this.searchButtonDisabled = true;
            if (this.canViewMultipleMakes) {
                this.selectionsLimit = 5;
                this.name = "Makes";
            } else {
                this.selectionsLimit = 1;
                this.name = "Make";
            }
        } else if (this.filterName === FilterName.models) {
            this.selectionsLimit = 5;
            this.name = "Models";
        } else if (this.filterName === FilterName.dealers) {
            // For filters with a selection limit of 5
            this.selectionsLimit = 5;
            this.name = "Competitors";
        } else if (this.filterName === FilterName.segments) {
            this.topXButtonDisabled = true;
            this.selectionsLimit = 3;
            this.name = "Segments";
        } else if (this.filterName === FilterName.ihs_segments) {
            this.topXButtonDisabled = true;
            this.selectionsLimit = 3;
            this.name = "Segments";
        } else if (this.filterName === FilterName.zones) {
            this.selectionsLimit = 10;
            this.topXButtonDisabled = true;
            this.name = "Zones";
            this.pptDataService.calculateTopZones([], this.NUMBER_OF_TOP_ZONES, {
                dealers: this.presentationFilterService.getFilterValue(FilterName.spotlight_dealer),
                makes: this.presentationFilterService.getFilterValue(FilterName.makes)
            }).subscribe(zones => this.topZones = zones);
        }
    }

    updateStepText(): void {
        this.titleBuilderService.buildTitle(this.filterName).then(title => this.title = title);
        this.titleBuilderService.buildSubTitle(this.filterName).then(subTitle => this.subtitle = subTitle);
    }

    resetSelectionPanel(): void {
        this.selectionSection.excludedFilters = ["models"];
        // Model, segment, and zone selections should not influence any other selection panels for top 5
        this.selectionSection.filterOverrides = { models: [], segments: [], zones: [] };

        // All top X requests in the PPT flow are based on the spotlit dealer, not market
        this.selectionSection.topNContext = TopNContext.Spotlight;
        this.selectionSection.topNForMarketDisabled = true;

        this.selectionSection.chipValues = [];

        this.selectionSection.searchTerm = "";
        this.selectionSection.loadedOptions = false;
        this.selectionSection.searchOpenState = false;
        this.selectionSection.selectedSelections.next([]);
        this.selectionSection.sortSelection = FilterSort.Sales;
        this.selectionsLimit = 0;
        this.clearButtonDisabled = false;
        this.searchButtonDisabled = false;
        this.topXButtonDisabled = false;
        this.sortAreaDisabled = false;
        this.radiusButtonDisabled = true;
    }

    chipCountChanged(chipCountChangeEvent: ChipCount): void {
        if (chipCountChangeEvent.filterName === this.filterName) {
            this.presentationFilterService.chipCountChange(this.filterName, chipCountChangeEvent.chipCount);
        }
    }

    initializeChipValue(): BaseChipValue {
        let c: BaseChipValue = null;
        c = {
            name: "dummy",
            index: 1,
            isSelected: false,
            volume: 0,
        };
        return c;
    }
}

