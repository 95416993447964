import { Component, Input, OnInit } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";

import { IFilterToggleState } from "../filter/filter-toggle-button/filter-toggle-button.component";

@Component({
    selector: "map-filter-controls",
    templateUrl: "map-filter-controls.component.html",
    styleUrls: ["map-filter-controls.component.scss"],
})
export class MapFilterControlsComponent implements OnInit {
    @Input() rightPanelOpen: boolean;
    @Input() isMvm: boolean;

    visibleState = false;
    FilterName = FilterName; // this is here for proper template binding
    zipZoneValue: string;

    constructor(private filterStateService: FilterStateService) { }

    ngOnInit(): void {
        this.zipZoneValue = this.filterStateService.getFilterValue(FilterName.zip_zone_flag);
    }

    closeControls(): void {
        this.visibleState = false;
    }

    openControls(): void {
        this.visibleState = true;
    }

    updateToggleValue(state: IFilterToggleState): void {
        if (!state) {
            return;
        }
        this.zipZoneValue = state.value;
        this.filterStateService.setFilterValue(state.name, state.value);
    }
}
