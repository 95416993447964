import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { ConfirmModalView } from "../components/confirm-modal/confirm-modal.component";

export interface ConfirmModalConfig {
    viewConfig: ConfirmModalView;
    confirm: (config: ConfirmModalView) => void;
    cancel: (e: any, iconFlag?: boolean) => void;
}

@Injectable()
export class ConfirmModalService {
    public readonly open = new Subject<ConfirmModalConfig>();
    public readonly close = new Subject<void>();

    async showModal(config: ConfirmModalConfig): Promise<void> {
        this.open.next(config);
    }

    closeModal(): void {
        this.close.next();
    }
}
