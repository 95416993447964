<div id="data-source-selector-{{ id }}" *ngIf="salesDataEnabled" class="data-source-selector">
    <div class="details">
        <div class="label">{{radioGroupName}}</div>
    </div>
    <kite-radio-group class="buttons">
        <kite-radio-button *ngFor="let option of options"
            (click)="selectOption(option)"
            [value]="option.value"
            [(ngModel)]="selectedOption"
            ngDefaultControl
            title={{option.hover}}
            [disabled]=disabled
            [checked]="selectedOption === option.value">
            {{option.label}}
        </kite-radio-button>
    </kite-radio-group>
    <span [dTooltip]="datasourceinfo" [showToolTip]="true">
        <p class="available-date">Data is available through {{dataDate}}</p>
        <p *ngIf="use_sales_data_flag" class="disclaimer"><b>*Sales Data within the last 2 weeks is subject to change.</b></p>
    </span>
</div>



<ng-template #datasourceinfo >
    <div style="max-width: 200px" *ngIf="!use_sales_data_flag">
        Registration Data comes from the government record and is updated every 45-60 days. It includes data from 2 months prior to the current date.
    </div>
    <div style="max-width: 200px" *ngIf="use_sales_data_flag">
        Sales data comes from a blend of several sources. It is updated every 7 days.
    </div>
</ng-template>
