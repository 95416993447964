import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { logger } from "@sentry/utils";
import { BehaviorSubject } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { HttpServiceBase } from "../base/services/http-service-base.service";
import { FilterName } from "../models/filter-name.enum";
import { ClientMetadata, ServerMetadata } from "../models/metadata.model";
import { CognitoService } from "./cognito.service";
import { FilterStateService } from "./filter-state.service";

@Injectable()
export class MetadataService extends HttpServiceBase {
    public readonly metadata = new BehaviorSubject<ClientMetadata>({ minDate: "", maxDate: "", lastRun: "", oldestDataDate: "", minSalesDate: "", maxSalesDate: "", lastSalesRun: "", oldestSalesDataDate: "" });

    constructor(
        protected http: HttpClient,
        private cognitoService: CognitoService,
        private filterStateService: FilterStateService,
    ) {
        super(http);
        this.cognitoService.authenticated.pipe(skipWhile(a => !a), take(1)).subscribe(() => this.fetchMetadata());
    }

    fetchMetadata(): void {
        const url = `${this.rootUrl}/info/update`;
        const useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        const options = this.createRequestOptions(useSalesData ? {
            "use_sales_data": "true"
        } : {});

        this.fetchInterfaceNoCache<ServerMetadata[]>(url, options).toPromise().then((metadata: ServerMetadata[]) => {
            // Convert DATEs to YYYYMM strings for the client to use
            // last_update is a Date string YYYY-DD-MM; add time so it is the first of the month and not the day before
            const lastUpdate = new Date(metadata[0].last_update + " 00:00:00");
            const lastRunDate = new Date(metadata[0].last_runtime + " 00:00:00");
            const lastRun = `${lastRunDate.getFullYear()}${String(lastRunDate.getMonth() + 1).padStart(2, "0")}`;
            const oldestDate = new Date(metadata[0].oldest_data_date);
            const oldestDataDateMonth = `${oldestDate.getFullYear()}${String(oldestDate.getMonth() + 1).padStart(2, "0")}`;
            const oldestDataDateDay = `${oldestDataDateMonth}${String(oldestDate.getDate()).padStart(2, "0")}`;
            const lastSalesUpdate = new Date(metadata[0].last_sales_update + " 00:00:00");
            const lastSalesRunDate = new Date(metadata[0].last_sales_runtime + " 00:00:00");
            const lastSalesRun = `${lastSalesRunDate.getFullYear()}${String(lastSalesRunDate.getMonth() + 1).padStart(2, "0")}`;
            const oldestSalesDate = new Date(metadata[0].oldest_sales_data_date);
            const oldestSalesDataDateMonth = `${oldestSalesDate.getFullYear()}${String(oldestSalesDate.getMonth() + 1).padStart(2, "0")}`;
            const oldestSalesDataDateDay = `${oldestSalesDataDateMonth}${String(oldestSalesDate.getDate()).padStart(2, "0")}`;

            // get the YYYYMM of the last_update and subtract 5 because our oldest date is always 5 years back
            const minSalesDate = `${oldestSalesDataDateMonth}01`;
            const minDate = `${lastUpdate.getFullYear() - 5}01`;
            // get the year and month portion and convert to YYYYMM
            // if using weekly sales data, the date is converted into YYYYMMDD
            const maxSalesDate = `${lastSalesUpdate.getFullYear()}${String(lastSalesUpdate.getMonth() + 1).padStart(2, "0")}${String(lastSalesUpdate.getDate()).padStart(2, "0")}`;
            const maxDate = `${lastUpdate.getFullYear()}${String(lastUpdate.getMonth() + 1).padStart(2, "0")}`;

            // set min & max date in state
            this.filterStateService.setFilterValue(FilterName.minDate, minDate);
            this.filterStateService.setFilterValue(FilterName.maxDate, maxDate);
            this.filterStateService.setFilterValue(FilterName.minSalesDate, minSalesDate);
            this.filterStateService.setFilterValue(FilterName.maxSalesDate, maxSalesDate);

            this.updateMetadata({ maxDate, minDate, lastRun, oldestDataDateDay, maxSalesDate, minSalesDate, lastSalesRun, oldestSalesDataDateDay });
        });
    }

    updateMetadata(update: any): void {
        this.metadata.next(update);
    }

    /**
     * Gets the current max date based on the last ingest.
     */
    async getMaxDate(useSalesData: boolean = false): Promise<string> {
        if (useSalesData) {
            return this.metadata.pipe(skipWhile(i => !(i && i.maxSalesDate)), take(1)).toPromise().then(meta => meta.maxSalesDate);
        } else {
            return this.metadata.pipe(skipWhile(i => !(i && i.maxDate)), take(1)).toPromise().then(meta => meta.maxDate);
        }
    }

    async getOldestDataDate(): Promise<string> {
        return this.metadata.pipe(skipWhile(i => !(i && i.oldestDataDate)), take(1)).toPromise().then(meta => meta.oldestDataDate);
    }
}
