/* eslint-disable indent */
import { createReducer, on } from "@ngrx/store";

import * as FilterStateDefaultsActions from "../actions/filter-state-defaults.actions";
import { filterDefaultState } from "../state";

/**
 * This is to allow us to update one particular filter property when the user selects a new filter
 * in the User Interface
 */
const _filterDefaultStateReducer = createReducer(
    filterDefaultState,
    on(FilterStateDefaultsActions.setDefaultFilter, (state, {filterName, value}) => ({...state, [filterName]: value})),
    on(FilterStateDefaultsActions.setFilterDefaultObject, (state, {filterDefaults}) => (Object.assign({}, state, filterDefaults)))
);

export function filterDefaultStateReducer(state, action) {
    return _filterDefaultStateReducer(state, action);
}

