import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { FilterName } from "../models/filter-name.enum";

export interface OverlayStatus {
    visible: boolean;
    filterName?: FilterName;
    spotlight?: boolean;
    sales_range?: boolean;
}

@Injectable()
export class OverlayService {

    private _overlayState = new BehaviorSubject<OverlayStatus>({ visible: false });
    public readonly overlayState = this._overlayState.asObservable();

    readonly _offlineState = new BehaviorSubject<number>(null);
    readonly offlineState = this._offlineState.asObservable();

    private _menuState = new BehaviorSubject<boolean>(false);
    readonly menuState = this._menuState.asObservable();

    showSelectionPanel(filterName: FilterName): void {
        this._overlayState.next({ visible: true, filterName });
    }

    showSpotlightSelectionPanel(): void {
        if (this._overlayState.getValue()["spotlight"]) {
            this.hidePanel();
            return;
        }
        this._overlayState.next({ visible: true, spotlight: true });
    }

    showSalesRangePanel(): void {
        this._overlayState.next({ visible: true, sales_range: true, filterName: FilterName.sales_range });
    }

    hidePanel(): void {
        this._overlayState.next({ visible: false });
    }

    showLoadingPanel(): void {
        if (!this._overlayState.value.visible && !this._overlayState.value.filterName) {
            this._overlayState.next({ visible: true });
        }
    }

    hideLoadingPanel(): void {
        if (this._overlayState.value.visible && !this._overlayState.value.filterName) {
            this.hidePanel();
        }
    }

    isShowHideOverlayPanel(): boolean {
        return this._overlayState.value.visible;
    }

    showOfflinePanel(errorCode: number): void {
        this._offlineState.next(errorCode);
    }

    hideOfflinePanel(): void {
        this._offlineState.next(null);
    }

    isShowHideOfflinePanel(): boolean {
        return (this._offlineState.value) ? true : false;
    }

    showMenuPanel(): void {
        this._menuState.next(true);
    }

    hideMenuPanel(): void {
        this._menuState.next(false);
    }

    applyNoScroll(show: boolean): void {
        const body = document.getElementsByTagName("body")[0];
        if (!show) {
            body.classList.remove("fullscreen-overlay-open");
        } else {
            body.classList.add("fullscreen-overlay-open");
        }
    }
}
