import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DateUtils } from "@at/utils";
import * as moment from "moment";
import { forkJoin, fromEvent, Subscription } from "rxjs";
import { debounceTime, skipWhile, take } from "rxjs/operators";
import { DataSet } from "../../../models/chart-data.model";
import { ChartOptions } from "../../../models/chart-options.model";
import { DateRangeTypes } from "../../../models/date-range.enum";
import { FilterName } from "../../../models/filter-name.enum";
import { GroupBySales } from "../../../models/groupby-sales.model";
import { UserPreferences } from "../../../models/user-preferences.enum";
import { FilterStateService } from "../../../services/filter-state.service";
import { MetadataService } from "../../../services/metadata.service";
import { SalesDataService, SalesQueryOptions } from "../../../services/sales-data.service";
import { ChartColor } from "../../charts/chart/chart-color.utils";
import { ChartComponent } from "../../charts/chart/chart.component";
import { ChartComponentUtils } from "../../charts/chart/chart.component.utils";
import { DropDownItem } from "../../dropdown/dropdown.component";
import { FeatureFlagService, PresentationFilterService } from "@at/core";


@Component({
    selector: "three-year-pivot-card",
    templateUrl: "./three-year-pivot-card.component.html",
    styleUrls: ["./three-year-pivot-card.component.scss"]
})
export class ThreeYearPivotCardComponent implements OnInit, OnDestroy {
    @ViewChild(ChartComponent) chart: ChartComponent;

    @Input() groupBySelection: FilterName = FilterName.models; // supported filters; models, makes, segments, zones
    @Input() weeklyDataSelectorEnabled = false;

    date: string;
    limitToDisplay = 5;
    cardDisabled = true;
    cardOpen = false;
    cardDescriptionDisabled = false;
    cardDescription = "";
    cardLoading = false;
    cardTitleBase = "Sales & Share Trends";
    cardTitle = "";
    elementId = `${this.groupBySelection.toString()}-three-year-pivot`;
    noResults = false;
    minDate;
    maxDate;
    oldestDate = "";

    dropdown: DropDownItem[] = [];
    defaultDropDown: DropDownItem;

    chartColors = ChartColor.getColorPalette(3); // a color for each year
    chartData: DataSet[] = [];
    chartLabels: string[][] = [];
    chartType = "barChartType";
    customTicks: number[][] = [];

    // Chart options overrides.
    chartOptions: ChartOptions = {
        borderRadius: .15,
        scales: {
            xAxes: [{
                gridLines: { display: true, offsetGridLines: true },
                ticks: {
                    autoSkip: false, // stops the chart from removing labels based on chart width
                    display: true
                }
            }, {
                type: "category",
                // in order to have scale labels on the bottom of the chart,
                // while having the original labels location on the top of the chart, a second axis is needed.
                id: "bottom-x-axis",
                gridLines: { display: false },
                offset: true,
                ticks: {
                    display: true,
                    autoSkip: false,
                    padding: 5,
                    maxRotation: 0,
                    beginAtZero: false,
                    callback: (value, idx, values) => {
                        if ((idx + 1) > this.customTicks.length) {
                            return;
                        }
                        let retVal = "";
                        this.customTicks[idx].forEach(tick => {
                            retVal += `  ${Number(tick).toFixed(2)}%  `;
                        });
                        return retVal;
                    }
                },
                afterBuildTicks: (chartObj) => {
                    if (chartObj.hasOwnProperty("ticks") && this.customTicks[0].length > 1) {
                        chartObj.ticks = chartObj.ticks.slice(0, (this.customTicks[0].length * 3) - 1);
                        // chartObj.ticks.stepSize = this.customTicks[0].length;
                        if (chartObj.hasOwnProperty("tickValues")) {
                            this.chart.setXAxisScaleCustom(chartObj.ticks.slice(0, (this.customTicks[0].length * 3) - 1));
                        }
                    }
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Volume",
                    fontStyle: 600,
                    fontFamily: "Open Sans",
                    fontColor: "#404040"
                }
            }]
        },
        customOptions: {
            groupedBars: {
                leftOffset: 0
            }
        },
        plugins: {
        }
    };

    labelsRef: string[] = [];
    protected resizeSubscription: Subscription;
    protected filterStateServiceSubscription: Subscription;
    groupSize: number;
    dateRangeType: DateRangeTypes;
    useSalesData: boolean;

    constructor(
        protected salesDataService: SalesDataService,
        protected filterStateService: FilterStateService,
        protected metadataService: MetadataService,
        protected featureFlagService: FeatureFlagService
    ) { }

    ngOnInit(): void {
        this.metadataService.metadata.pipe(skipWhile((i => !i || !i.maxDate)), take(1)).subscribe(metadata => {
            this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
            const dateRange = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);

            this.date = this.useSalesData ? metadata.maxSalesDate.slice(0, 6) : metadata.maxDate;
            this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate : FilterName.minDate);
            this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate);
            this.oldestDate = metadata.minSalesDate;

            if (this.dateRangeType === DateRangeTypes.MONTHS && dateRange && dateRange.length >= 5) {
                this.setupDateRanges();
            }

            this.filtersUpdated([FilterName.dateRangeType.toString()]);
            this.updateTitle();
        });

        this.groupBySelection = this.toEnum(this.filterStateService.getUserPreference(UserPreferences.threeYearTrendsCard));
        this.updateDropdown();

        // Update this chart when the window is resized to recalculate the chart segmentation.
        this.resizeSubscription = fromEvent(window, "resize").pipe(
            debounceTime(100))
            .subscribe(this.updateChartSize.bind(this));
    }

    ngOnDestroy(): void {
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
        }
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }

    filtersUpdated(filters: string[]): void {
        let updateRequired = false;
        const dateRange: [] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        if (!this.filterStateService.clientOnlyChanges(filters)) {
            updateRequired = true;
        }

        if (filters.includes(FilterName.use_sales_data.toString())) {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate : FilterName.minDate);
            this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate);
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);

            this.date = this.useSalesData ? this.maxDate.slice(0, 6) : this.maxDate;
            if (this.dateRangeType === DateRangeTypes.MONTHS && dateRange && dateRange.length >= 5) {
                this.setupDateRanges();
                updateRequired = true;
            } else if (this.dateRangeType === DateRangeTypes.MONTHS) {
                updateRequired = false;
            } else {
                updateRequired = true;
            }
            updateRequired = true;
        }

        if (filters.includes("use_ihs_segments")) {
            this.groupBySelection = this.toEnum(this.filterStateService.getUserPreference(UserPreferences.threeYearTrendsCard));

            this.updateDropdown();
            updateRequired = true;
        }

        if (filters.includes(FilterName.dateRangeType.toString()) || filters.includes(FilterName.dateRanges.toString())) {
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
            if (this.dateRangeType === DateRangeTypes.MONTHS && dateRange && dateRange.length >= 5) {
                this.setupDateRanges();
                updateRequired = true;
            } else if (this.dateRangeType === DateRangeTypes.MONTHS) {
                updateRequired = false;
            } else {
                updateRequired = true;
            }
        }

        if (updateRequired) {
            this.checkCardDisabled();
            this.updateSalesData();
            this.updateTitle();
        }
    }

    /**
     * Updates the accordion title with the appropriate date or date range utilizing the selected filtered values if they exist
     *
     * @returns void
     */
    updateTitle(): void {

        let min: string = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
        let max: string = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);
        let titleDate = "";

        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);

        // Added conditional in order to toggle the information populated in the card title
        if(!this.useSalesData){
            if (dateRanges && dateRanges.length >= 2 && this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.MONTHS ) {
                if (this.useSalesData) {
                    throw new Error("Sales data & Custom Date Range Not Allowed");
                }
                const orig = DateUtils.dateDisplay([dateRanges[1], dateRanges[0]], DateRangeTypes.MONTHS).split("-");
                const pretty = `${orig[0].slice(0, 3)} - ${orig[1]}`;
                titleDate = `${pretty}, ${DateUtils.getPreviousTwoYears(dateRanges[1])}`;
            }
            if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === "calendar") {
                let startDate = moment(new Date( moment(this.maxDate).toDate().getFullYear(),0,1));
                titleDate = moment(startDate).format("YYYY") + ", " +
                   (moment(this.maxDate).toDate().getFullYear() - 1) +", "+
                   (moment(this.maxDate).toDate().getFullYear() - 2);
            }
            if (this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.ROLLING12 ) {

                const start_date = DateUtils.monthYearAddMonths(this.date, -11);
                const end_date = DateUtils.prettyDate(this.date.slice());
                titleDate = `${this.useSalesData ? moment(start_date).format("MMM DD, YYYY"):
                    DateUtils.prettyDate(start_date)} - ${this.useSalesData ? moment(end_date).format("MMM DD, YYYY"):
                    (moment(end_date).format("MMM YYYY") + ",")} ${this.useSalesData ? "":
                    DateUtils.getPreviousTwoYears(this.date, this.useSalesData ? moment(this.oldestDate).format("YYYY") : undefined, this.useSalesData)}`;

            }
            if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.YTD){
                let startDate = moment(new Date( moment(this.maxDate).toDate().getFullYear(),0,1));
                titleDate =  moment(startDate).format("MMM  YYYY") + " - " +
                   moment(this.maxDate).format("MMM YYYY") + ", "+
                   (moment(this.maxDate).toDate().getFullYear() - 1) +", "+
                   (moment(this.maxDate).toDate().getFullYear() - 2);
            }
        } else if(this.useSalesData && this.weeklyDataSelectorEnabled){

            if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.ROLLING12){
                let momentMax = moment(moment(max).toDate().setDate(1));
                titleDate = `${moment(momentMax.toDate().setMonth(momentMax.toDate().getMonth() - 11)).format("MMM DD, YYYY")} - ${moment(max).format("MMM DD, YYYY")},
                ${moment(dateRanges[1]).toDate().getFullYear() - 1}, ${moment(dateRanges[1]).toDate().getFullYear() - 2}
                `;
            }

            if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.YTD){
                let fullWeekDateRange = DateUtils.popUpDateRange(this.useSalesData, max,  DateRangeTypes.YTD,
                    moment(min).toDate(),  moment(max).toDate());
                titleDate = moment(fullWeekDateRange.split("-")[0]).format("MMM DD, YYYY") + " - " +
                    moment(fullWeekDateRange.split("-")[1]).format("MMM DD, YYYY") + ", "+
                    (moment(fullWeekDateRange.split("-")[1]).toDate().getFullYear() - 1) +", "+
                    (moment(fullWeekDateRange.split("-")[1]).toDate().getFullYear() - 2);
            }
            if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === "calendar"){
                let firstOfYear = moment(max).toDate().getFullYear()+"0101";
                titleDate = ": " + moment(firstOfYear).format("YYYY") + ", " + (moment(firstOfYear).toDate().getFullYear() - 1) + ", "
                    +(moment(firstOfYear).toDate().getFullYear() -2);
            }
            if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.MONTHS){
                let stringDate = dateRanges[0] + " - " + dateRanges[1];
                let fullWeekDateRange = DateUtils.popUpDateRange(this.useSalesData, stringDate, this.filterStateService.getFilterValue(FilterName.dateRangeType),
                    moment(min).toDate(),  moment(max).toDate());
                titleDate = ": " + moment(fullWeekDateRange.split("-")[0]).format("MMM DD, YYYY") + " - " + moment(fullWeekDateRange.split("-")[1]).format("MMM DD, YYYY");
            }
        }
        this.cardTitle = `${this.cardTitleBase}: ${titleDate}`;
    }

    updateDropdown(): void {
        const segmentsValue = this.filterStateService.getFilterValue<boolean>(FilterName.use_ihs_segments) ? FilterName.ihs_segments : FilterName.segments;
        this.dropdown = [
            { label: "Models", value: FilterName.models },
            { label: "Makes", value: FilterName.makes },
            { label: "Segments", value: segmentsValue },
            { label: "Zones", value: FilterName.zones }
        ];

        this.defaultDropDown = this.dropdown.find(d => d.value === this.groupBySelection);
    }

    checkCardDisabled(): void {

        this.cardDisabled = false;

        if (this.cardDisabled) {
            this.cardDescriptionDisabled = false;
            this.cardDescription += `${this.cardDescription.length > 0 ? ". " : ""}`;
        } else {
            this.cardDescriptionDisabled = true;
            this.cardDescription = "";
        }

        if (this.cardDisabled) {
            this.cardClosed();
        }
    }

    cardOpened(): void {
        this.cardOpen = true;
        this.updateSalesData();
    }

    cardClosed(): void {
        this.cardOpen = false;
    }
    updateSalesData(hideTwoYearsAgo: boolean = false): void {
        if (this.cardDisabled || !this.cardOpen) {
            return;
        }
        this.cardLoading = true;
        this.customTicks = []; // reset custom ticks

        const queryOptions: SalesQueryOptions = this.getSalesQueryOptions();
        const dmaQueryOption = { sort: queryOptions.sort, dateRangeStart: queryOptions.dateRangeStart, dateRangeEnd: queryOptions.dateRangeEnd };
        const groupByfilterValues = this.filterStateService.getFilterValue<any[]>(this.groupBySelection);
        const filtersOverride = this.getFilterOverrides();
        const groupBySalesReq = this.salesDataService.getGroupBySalesDetails(queryOptions, groupByfilterValues, this.groupBySelection, filtersOverride);

        const dma = this.filterStateService.getFilterValue<number[]>(FilterName.dma);
        const dmaSalesReq = this.salesDataService.getGroupByDmaBuyerSales(dma, dmaQueryOption, {});

        forkJoin([groupBySalesReq, dmaSalesReq])
            .subscribe(datasets => {
                const filteredSales = datasets[0] as GroupBySales[];
                const marketSales = datasets[1][0] as GroupBySales;

                if (!filteredSales.length) {
                    this.noResults = true;
                    this.cardLoading = false;
                    this.cardClosed();
                    this.cardDescriptionDisabled = false;
                    this.cardDescription = "No results for current filters.";
                    return;
                } else {
                    this.noResults = false;
                    this.cardDescriptionDisabled = true;
                }

                // WEEKLY WORKING
                // if (this.useSalesData) {
                //     if (hideTwoYearsAgo) {
                //         for (let i in filteredSales) {
                //             const keys = Object.keys(filteredSales[i].sales);
                //             delete filteredSales[i].sales[keys[0]];
                //             if (this.dateRangeType === DateRangeTypes.ROLLING12) {
                //                 delete filteredSales[i].sales[keys[1]];
                //             }
                //         }
                //     } else {
                //         for (let i in filteredSales) {
                //             const keys = Object.keys(filteredSales[i].sales);
                //             filteredSales[i].sales[keys[0]] = 0;
                //             if (this.dateRangeType === DateRangeTypes.ROLLING12) {
                //                 filteredSales[i].sales[keys[1]] = 0;
                //             }
                //         }
                //     }
                // }
                this.adjustBarSpacing(filteredSales.length);
                this.chartData = [];
                this.labelsRef = [];
                /////////////
                let dateKeys;
                let currentDateKey;


                // Get volume keys by date type
                switch (this.dateRangeType) {
                    // if custom groups merge data and parse
                    // case DateRangeTypes.YTD:
                    case DateRangeTypes.MONTHS: {
                        dateKeys = this.groupDates(Object.keys(filteredSales[0].sales));
                        break;
                    } case DateRangeTypes.YEARS: {
                        dateKeys = Object.keys(filteredSales[0].sales);
                        break;
                    } default:
                        // Volume keys by date ASC.
                        dateKeys = Object.keys(filteredSales[0].sales).sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10));
                        currentDateKey = dateKeys[dateKeys.length - 1];
                }

                const groupByMapShareTotals = {};
                // Create a map to more quickly access sales values.
                const groupByMap = filteredSales.reduce((output, item) => {
                    output[item.groupByValue] = item.sales;

                    // Create an object representing shares for each date.
                    const shareTotals = {};
                    for (const k in item.sales) {
                        shareTotals[k] = marketSales.sales[k];
                    }
                    groupByMapShareTotals[item.groupByValue] = shareTotals;

                    // Add groupBy label to chart labels reference array.
                    this.labelsRef.push(item.groupByValue as string);
                    return output;
                }, {});
                // Sort labels by DESC order.
                this.labelsRef.sort((a, b) => (groupByMap[b][currentDateKey] / groupByMapShareTotals[b][currentDateKey]) - (groupByMap[a][currentDateKey] / groupByMapShareTotals[a][currentDateKey]));
                dateKeys.forEach(dateKey => {
                    const pivotDateData = {
                        id: dateKey,
                        label: this.formatDate(dateKey),
                        data: [],
                        shares: [],
                        xAxisId: "bottom-x-axis"
                    } as DataSet;
                    // Use the sorted labels to retrieve sales in order to populate chart data.
                    this.labelsRef.forEach(label => {
                        let data = 0;
                        let shareTotal = 0;
                        if (Array.isArray(dateKey)) {
                            dateKey.forEach(date => {
                                data = data + groupByMap[label][date] || 0;
                                shareTotal = shareTotal + groupByMapShareTotals[label][date];
                            });
                        } else {
                            data = groupByMap[label][dateKey] || 0;
                            shareTotal = groupByMapShareTotals[label][dateKey] || 0;
                        }
                        pivotDateData.data.push(data || 0);
                        const decimal = data / shareTotal;
                        pivotDateData.shares.push(isNaN(decimal) ? 0 : decimal * 100 || 0);
                    });

                    this.chartData.push(pivotDateData);
                });

                this.customTicks = [];
                for (let i = 0; i <= this.chartData[0].shares.length - 1; i++) {
                    // WEEKLY WORKING
                    // if (this.useSalesData) {
                    //     if (hideTwoYearsAgo) {
                    //         this.customTicks.push([this.chartData[0].shares[i], this.chartData[1].shares[i]]);
                    //     } else {
                    //         this.customTicks.push([this.chartData[1].shares[i], this.chartData[2].shares[i]]);
                    //     }
                    // } else {
                    this.customTicks.push([this.chartData[0].shares[i], this.chartData[1].shares[i], this.chartData[2].shares[i]]);
                    // }
                }
                setTimeout(this.updateChartSize.bind(this), 0); // allows the chart canvas to start to render to enable formating the label to the correct size
                this.updateChartSize();
                this.cardLoading = false;
            });
    }
    getKeysForYears(input: {}): string[] {
        const res = Object.keys(input).sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10)).map(item => item.substring(0, 7));
        return res;
    }

    formatDate(date: string | string[]): string {
        let formattedName;
        const realDate = moment(date, "YYYYMM");
        const diffYear = moment().diff(realDate, "years");
        const oldestRealDate = !Array.isArray(date) ? DateUtils.monthYearAddMonths(<string>date, -11) : "";
        const oldestMoment = moment(this.oldestDate, "YYYYMMDD");

        let min = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
        let max = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);

        switch (this.dateRangeType) {
            case DateRangeTypes.YTD: {
                formattedName = DateUtils.popUpDateRange(this.filterStateService.getFilterValue(FilterName.use_sales_data),
                    <string>date, this.dateRangeType,  moment(min).toDate(), moment(max).toDate());
                break;
            }
            case DateRangeTypes.MONTHS: {
                formattedName = DateUtils.popUpDatesRange((<string[]>date).reverse(), this.dateRangeType);
                break;
            }
            case DateRangeTypes.YEARS: {
                formattedName = DateUtils.popUpDateRange(this.filterStateService.getFilterValue(FilterName.use_sales_data),
                    <string>date, this.dateRangeType, moment(min).toDate(), moment(max).toDate());
                break;
            }
            default: {
                if (diffYear === 0) {
                    formattedName = "Current Year";
                } else {
                    formattedName = `${diffYear} year${diffYear > 1 ? "s" : ""} ago`;
                }
            }
        }

        let suffix = "";
        if (this.useSalesData) {
            if (oldestMoment.isSameOrAfter(oldestRealDate) && this.dateRangeType === DateRangeTypes.ROLLING12) {
                suffix = " - n/a";
            }
        }
        return formattedName + suffix;

    }

    setupDateRanges(): void {
        const dateRange = this.filterStateService.getFilterValue(FilterName.dateRanges);
        this.minDate = dateRange[4];
        this.maxDate = dateRange[1];
        // calculate grouping
        const dateEnd = moment(this.maxDate, "YYYYMM");
        const dateStart = moment(dateRange[0], "YYYYMM");

        this.groupSize = dateEnd.diff(dateStart, "months");
    }

    updateChartSize(): void {
        // Don't attempt to update if the card is not opened.
        if (!this.cardOpen) {
            return;
        }

        const chartWidth = this.chart.elementRef.nativeElement.offsetWidth;
        // Chart width - approximate layout offsets / # of labels (pivot sections)
        const chartSegmentWidth = (chartWidth - 300) / this.labelsRef.length;
        // Segment with / 3 bars.
        const barSize = (chartSegmentWidth - (this.chartData.length * this.chart.chartOptions.scales.xAxes[0].barSpacing)) / this.chartData.length;
        this.chart.chartOptions.scales.xAxes[0].maxBarThickness = barSize;

        // Format label for chart and resize chart offset to support label height.
        this.chartLabels = ChartComponentUtils.formatLabel(this.labelsRef, this.chart, this.labelsRef.length);
        const longestLabel = this.chartLabels.reduce((m, l) => m = l.length > m && l[l.length - 1] !== "" ? l.length : m, 1);
        this.chart.chartOptions.scales.xAxes[0].gridLines.tickMarkLength = longestLabel * 20;
        this.chart.forceRedraw();
    }

    getSalesQueryOptions(): SalesQueryOptions {
        const isMultiDma = this.filterStateService.isMultiDma();
        const retVal = {
            sort: {},
            limit: this.limitToDisplay,
            group: [this.groupBySelection.toString(), isMultiDma ? "" : FilterName.dma.toString()],
            dateRangeType: this.dateRangeType
        };

        switch (this.dateRangeType) {
            case DateRangeTypes.ROLLING12:
            case DateRangeTypes.YTD:
            case DateRangeTypes.YEARS:
                {
                    this.date = this.dateRangeType === DateRangeTypes.YEARS ? this.date.slice(0, 4) : this.date;
                    retVal.sort[`t${this.date.slice()}_volume`] = "DESC";
                }
                break;
            case DateRangeTypes.MONTHS:
                {
                    retVal.sort[`t${this.maxDate}_volume`] = "DESC";
                    retVal["dateRangeStart"] = this.minDate;
                    retVal["dateRangeEnd"] = this.maxDate;
                }
                break;
        }
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);

        return retVal;
    }

    getFilterOverrides(): { [key: string]: any } {
        return {};
    }

    adjustBarSpacing(numberOfBars: number): void {
        // Expand the space between bars as the shown results increase.
        this.chart.chartOptions.scales.xAxes[0].barSpacing = (this.limitToDisplay - numberOfBars) * 2 + 3;
    }

    dropdownChanged(selected: DropDownItem): void {
        this.groupBySelection = this.toEnum(selected.value);
        this.filterStateService.setUserPreference(UserPreferences.threeYearTrendsCard, this.groupBySelection);
        this.updateSalesData();
    }

    // Since typescript does not map strings back to enum.
    protected toEnum(enumValue: string): FilterName {
        switch (enumValue) {
            case "ihs_segments":
                return FilterName.ihs_segments;
            case "segments":
                return FilterName.segments;
            case "makes":
                return FilterName.makes;
            case "zones":
                return FilterName.zones;
            case "models":
            default:
                return FilterName.models;
        }
    }

    sumGroup(sales: any[]): any[] {
        const groupCount = this.groupSize + 1;
        const groupedSales = [];

        if (sales.length === 0) {
            return [0, 0, 0, 0];
        }

        if (isNaN(this.groupSize)) {
            return sales;
        }

        while (sales.length > 0) {
            groupedSales.push(sales.splice(0, groupCount));
            sales.splice(0, 12 - groupCount);
        }
        return groupedSales.map(
            group => group.reduce(
                (a, b) => a + b
            )
        );

    }
    averageGroup(shares: any[]): any[] {
        const groupCount = this.groupSize + 1;
        const groupedShares = [];

        if (shares.length === 0) {
            return [0, 0, 0, 0];
        }

        while (shares.length > 0) {
            groupedShares.push(shares.splice(0, groupCount));
            shares.splice(0, 12 - groupCount);
        }
        return groupedShares.map(
            group => group.reduce(
                (a, b) => a + b
            ) / groupCount
        );
    }
    groupDates(dates: string[]): string[] {
        const groupCount = this.groupSize + 1;
        const groupedDates = [];
        if (dates.length === 0) {
            return ["N/A", "N/A", "N/A"];
        }

        if (groupCount > 12) {
            // get group 1
            groupedDates.push(dates.filter((o, i) => i < groupCount && i >= 0));
            // get group 2
            groupedDates.push(dates.filter((o, i) => i < groupCount * 2 - 1 && i >= groupCount - 1));
            // get group 3
            groupedDates.push(dates.filter((o, i) => i <= dates.length - 1 && i > groupCount * 2 - 3));
        } else {
            while (dates.length > 0) {
                groupedDates.push(dates.splice(0, groupCount));
                dates.splice(0, 12 - groupCount);
            }
        }

        return groupedDates.map(
            group => group
        );
    }
    groupYtdDates(dates: string[]): string[] {
        const groupCount = this.groupSize + 1;
        const groupedDates = [];
        if (dates.length === 0) {
            return ["N/A", "N/A", "N/A"];
        }

        while (dates.length > 0) {
            groupedDates.push(dates.splice(0, groupCount));
            dates.splice(0, 12 - groupCount);
        }
        return groupedDates.map(
            group => group
        );
    }

    drawPercentValue = (rect: any, context: CanvasRenderingContext2D): void => {
        const vm = rect._view;
        const shareValues = rect._chart.data.datasets[rect._datasetIndex].shares;
        const value = `${shareValues && Number(shareValues[rect._index]).toFixed(2) || 100}%`;
        const textWidth = context.measureText(value).width;
        const x = vm.x - textWidth / 2;
        // Since there is not a quick and easy way to calculate canvas height, we'll approximate it 2/3 of the way for now.
        const y = vm.base - 12.5;
        context.fillText(value, x, y);
    };
}
