import { Injectable } from "@angular/core";

import { DmaSales } from "../models/dealer-sales";
import { FilterName } from "../models/filter-name.enum";
import { FilterStateService } from "./filter-state.service";
import { SalesDataService, SalesQueryOptions } from "./sales-data.service";

@Injectable()
export class UnknownSalesService {

    constructor(
        private salesDataService: SalesDataService,
        private filterStateService: FilterStateService
    ) { }

    public getUnknownSales(dmaId: number = null, salesQueryOptions?: SalesQueryOptions): DmaSales {

        const filterOverride: SalesQueryOptions = { ...salesQueryOptions, unknown: "true" };

        if (dmaId) {
            return this.salesDataService
                .getTotalSalesForDmas([dmaId], filterOverride, true)[0];
        } else {
            return this.salesDataService
                .getTotalSalesForDmas(this.filterStateService.getFilterValue(FilterName.dma), filterOverride, true)[0];
        }
    }

    public getUnknownSalesMultiDMA(dmaIds: number[], salesQueryOptions?: SalesQueryOptions): DmaSales[] {

        const filterOverride: SalesQueryOptions = { ...salesQueryOptions, unknown: "true" };

        return this.salesDataService
            .getTotalSalesForDmas(dmaIds, filterOverride, true);
    }

}
