import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environment";
import { EMPTY, Observable, throwError as observableThrowError } from "rxjs";
import { catchError } from "rxjs/operators";

import { CognitoService } from "../services/cognito.service";
export const InterceptorSkipTokenHeader = "X-Skip-Token-Interceptor";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private auth: CognitoService, private router: Router) { }

    // applies authorization header to a request
    addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `${this.auth.getToken()}`,
                "Content-type": "application/json"
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has(InterceptorSkipTokenHeader)) {
            const headers = request.headers.delete(InterceptorSkipTokenHeader);
            const clone = request.clone({ headers });
            // bypass adding our auth header to certain 3rd party requests
            return next.handle(clone);
        }
        request = this.addAuthHeader(request);
        // send request with amended auth header
        return next.handle(request).pipe(catchError((err: HttpErrorResponse, retry: Observable<HttpEvent<any>>): Observable<any> => {
            if (err.status === 401) { // if id token is rejected, get a new token
                this.auth.refreshToken().then(
                    () => next.handle(this.addAuthHeader(request)),
                    () => {
                        throw new Error("Unable to refresh authentication token");
                    }
                ).catch(error => {
                    if (!environment.production) {
                        // console.log(error);
                    }
                    this.auth.signOut();
                    return EMPTY;
                });
            }
            return observableThrowError(err);
        }));
    }
}
