import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ZipZoneSale } from "app/core/models/zip-zone.model";
import { TopZipsHeaderService } from "app/core/services/top-zips-header.service";

import { ChartColor } from "../charts/chart/chart-color.utils";
import { DropDownItem } from "../dropdown/dropdown.component";
import { ZipZoneAreaComparison } from "../top-zips-header-table/zip-zone-comparison.interface";

@Component({
    selector: "primary-and-secondary-sales-zip-table",
    templateUrl: "./primary-and-secondary-sales-zip-table.component.html",
    styleUrls: ["./primary-and-secondary-sales-zip-table.component.scss"]
})
export class PrimaryAndSecondarySalesZipTableComponent implements OnChanges {
    @Input() type: "zips" | "zones";
    @Input() zipZoneSales: ZipZoneSale[] = [];
    @Input() showVolume = true;
    @Input() isMultiDma = false;
    @Input() limit: DropDownItem;
    @Input() showTop25Zips = false;

    topZipZoneVolumes: ZipZoneAreaComparison[] = [];
    primarySalesByZip: ZipZoneAreaComparison[] = [];
    top25SalesByZip: ZipZoneAreaComparison[] = [];
    secondarySalesByZip: ZipZoneAreaComparison[] = [];
    topZipZoneColors: object = {};
    totalVolume = 0;
    secondarySalesCount = 0;
    primarySalesCount = 0;

    constructor(
        private topZipsHeaderService: TopZipsHeaderService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if ("zipZoneSales" in changes) {
            this.updateZipVolume();
        }

        if ("colors" in changes) {
            const newColors = changes.colors.currentValue;
            this.updateZipColors(newColors);
        }
    }

    updateZipColors(newColors: object): void {
        const colors = {};
        for (const zip in newColors) {
            colors[zip] = ChartColor.toChartJsColor(newColors[zip]);
        }
        this.topZipZoneColors = colors;
    }

    updateZipVolume(): void {
        // The selected dealer does not yet have any sales for the current year / rolling 12.
        if (!this.zipZoneSales.length) {
            this.clearVolumes();
        } else {
            this.setVolumes();
        }
    }

    private setVolumes(): void {
        // Sort the Sales
        this.zipZoneSales = this.zipZoneSales.sort((a, b) => b.sales - a.sales);
        // Get the total sales for the dealer
        this.totalVolume = this.topZipsHeaderService.calculateTotalVolume(this.zipZoneSales);

        this.primarySalesCount = this.zipZoneSales.filter(z => z.area === "psa").length;
        this.secondarySalesCount = this.zipZoneSales.filter(z => z.area === "ssa").length;

        const filteredTopZips = this.topZipsHeaderService.applyColorsToZips(this.zipZoneSales
            .filter(sale => sale.area !== null)
            .slice(0, this.limit.value)
            .map(sale => this.topZipsHeaderService.toZipZoneComparison(sale, this.isMultiDma))
        );
        // Get the primary zips for the dealer
        this.primarySalesByZip = filteredTopZips.filter(z => z.area === "psa");
        // Get the secondary zips for the dealer
        this.secondarySalesByZip = filteredTopZips.filter(z => z.area === "ssa");
        // Get the top 25 zips
        this.top25SalesByZip = filteredTopZips.slice(0, 25);
    }

    private clearVolumes(): void {
        this.totalVolume = 0;
        this.topZipZoneVolumes = [];
        this.primarySalesByZip = [];
        this.secondarySalesByZip = [];
        this.top25SalesByZip = [];
    }
}
