<div class="filter-spotlight-button" (click)="clickHandler()" [class.selected]="selected">
    <div class="spotlight-menu-button" [dTooltip]="spotlightInstructions" [shiftTop]="20"
        [showTooltip]="!selected && !dealerId">
        <div class="text-group">
            <div class="link" [class.small]="dealer">{{dealerId ? 'Dealer Spotlight' : 'Spotlight'}}</div>
            <div class="dealer-name" *ngIf="dealer">{{dealerId ? dealer.dealer_name : ""}}</div>
        </div>
        <div class="icon-group">
            <div class="icon spotlight-icon" data-cy="spotlight" [class.active]="dealerId"></div>
        </div>
    </div>
</div>

<ng-template #spotlightInstructions>
    <div style="max-width:220px">
        To spotlight a dealer, open dealer pins on the map, or open dealer details on the right, then select "Turn on
        Spotlight".
    </div>
</ng-template>
