import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { ZipUploadContainer } from "../../drag-and-drop-file-upload/drag-and-drop-file-upload.component";

@Component({
    selector: "zip-upload-modal",
    templateUrl: "./zip-upload-modal.component.html",
    styleUrls: ["./zip-upload-modal.component.scss"]
})
export class ZipUploadModalComponent implements OnInit {

  @Input() transitioning = false;
  @Input() displayCurtain = false;
  hasErrors = false;
  zipsContainer: ZipUploadContainer;
  zips: string[] = [];
  filename: string;
  errors: string;


  @Output() zipsUploaded: EventEmitter<ZipUploadContainer> = new EventEmitter();
  @Output() zipUploadCancelled: EventEmitter<any> = new EventEmitter();
  @Output() uploadFailure: EventEmitter<boolean> = new EventEmitter();
  uploadFail = false;

  constructor() { }

  ngOnInit(): void {
  }

  zipsUploadedHandler(zipContainer: ZipUploadContainer) {
      if (zipContainer.success) {
          this.uploadFailure.next(false);
          this.uploadFail = false;
          if (zipContainer.errors.length >= 1) {
              this.hasErrors = true;
              this.errors = zipContainer.errors.toString();
          }
          this.zipsContainer = zipContainer;
          this.zips = zipContainer.zips;
          this.filename = zipContainer.fileName;
      } else {
          this.uploadFailure.emit(true);
          this.uploadFail = true;
          this.hasErrors = true;
          this.zipsContainer = zipContainer;
          this.errors = zipContainer.errors.toString();
      }
  }

  clickZipUpload(event: any) {
      if(this.zipsContainer) {
          this.zipsUploaded.next(this.zipsContainer);
      }
  }

  failUpload(event) {
      this.zipsContainer.success = false;
      this.zipsUploaded.next(this.zipsContainer);
      this.uploadFailure.emit(true);
      this.uploadFail = true;
  }

  clearZips() {
      this.zipsContainer = { zips: [], fileName: "", errors: [], success: false };
      this.hasErrors = false;
      this.uploadFailure.emit(false);
      this.uploadFail = false;
      this.zips = [];
      this.errors = "";
      this.filename = "";
      this.zipsUploaded.emit(this.zipsContainer);
  }

  cancelUpload(event: any) {
      this.zipUploadCancelled.next(event);
  }

}
