import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import { cloneDeep, map, omit, uniq } from "lodash";
import * as moment from "moment";
import { AsyncSubject, BehaviorSubject, EMPTY, Observable, Subscription, concat, of, timer } from "rxjs";
import { debounce, flatMap } from "rxjs/operators";

import { HttpServiceBase } from "../base/services/http-service-base.service";
import { DealerSalesRangeFilterHelper } from "../helpers/dealer-sales-range-filter.helper";
import { DealerMapping } from "../models/dealer-mapping";
import { Dealer } from "../models/dealers.model";
import { FilterName } from "../models/filter-name.enum";
import { GroupBySales } from "../models/groupby-sales.model";
import { SpotlightCompetitors } from "../models/spotlight.enum";
import { DmaDataService } from "../services/dma-data.service";
import { FilterStateService } from "./filter-state.service";
import { MetricsService } from "./metrics.service";
import { SalesDataService } from "./sales-data.service";
import { UserCookieService } from "./user-cookie.service";

@Injectable()
export class DealerDataService extends HttpServiceBase implements OnDestroy {
    private currentDetailsQuery: Subscription;
    private filterStateSubscription: Subscription;
    private fetchFilteredDealersSubscription: Subscription;
    private loadingDealerDetailsSubject = new BehaviorSubject<boolean>(false);
    public readonly dealerDetails = new BehaviorSubject<Dealer[]>([]);
    public readonly spotlightDealerDetails = new BehaviorSubject<Dealer>(null);
    public readonly loadingDealerDetails: Observable<boolean> = this.loadingDealerDetailsSubject.asObservable().pipe(
        debounce(loading => loading ? EMPTY : timer(30)));

    spotlightDealerId: number;

    dealersCache = {};
    private dealerDetailsSub: Subscription;

    constructor(
        protected http: HttpClient,
        protected filterStateService: FilterStateService,
        protected salesDataService: SalesDataService,
        protected userCookieService: UserCookieService,
        protected dmaDataService: DmaDataService,
        protected metricsService: MetricsService
    ) {
        super(http);
    }

    ngOnDestroy(): void {
        this.stopMonitoringDealers();
    }

    startMonitoringDealers(): void {
        this.salesDataService.startMonitoringSales();
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe((changed: string[]) => {
            if (this.filterStateService.clientOnlyChanges(changed) && !changed.includes("spotlight_dealer") && !changed.includes("include_spotlight")) {
                return;
            }

            if (changed.includes("dma") || changed.includes("use_sales_data")) {
                this.resetDealers();
            }
            if (changed.includes("spotlight_dealer")) {
                this.updateSpotlightDealer();
            }
            this.updateFilteredDealerSet();
        });
        this.updateFilteredDealerSet();
        this.updateSpotlightDealer();
    }

    resetDealers(): void {
        this.dealersCache = {};
    }

    stopMonitoringDealers(): void {
        this.salesDataService.stopMonitoringSales();
        // if (this.fetchFilteredDealersSubscription) {
        //     this.fetchFilteredDealersSubscription.unsubscribe();
        // }
        // if (this.dealerDetailsSub) {
        //     this.dealerDetailsSub.unsubscribe();
        // }
        // if (this.filterStateSubscription) {
        //     this.filterStateSubscription.unsubscribe();
        //     this.filterStateSubscription = null;
        // }
    }

    private getNotCachedDealerIds(dealerIds: number[]): number[] {
        const notFound: number[] = [];
        for (let i = 0; i < dealerIds.length; i++) {
            if (!this.dealersCache[dealerIds[i]]) {
                notFound.push(dealerIds[i]);
            }
        }
        return notFound;
    }

    private getCachedDealers(dealerIds: number[]): Dealer[] {
        const found: Dealer[] = [];
        for (let i = 0; i < dealerIds.length; i++) {
            if (this.dealersCache[dealerIds[i]]) {
                found.push(this.dealersCache[dealerIds[i]]);
            }
        }
        return found;
    }

    getFilteredDealerDetails(): Observable<Dealer[]> {
        const filters = cloneDeep(this.filterStateService.getCurrentApiFilters()); // deep copy
        filters["filtername"] = "dealer";
        if ("dealers" in filters
            && filters["dealers"].length
            && this.spotlightDealerId
            && this.filterStateService.getFilterValue(FilterName.include_spotlight) === "include") {

            filters["dealers"].push(this.spotlightDealerId);
            filters["dealers"] = uniq(filters["dealers"]);
        }

        return DealerSalesRangeFilterHelper.handleSalesRangeFilters<Dealer>(filters,
            () => this.fetchSalesRangeFilteredDealers(filters),
            (f) => this.fetchFilteredDealers(f));
    }

    private fetchSalesRangeFilteredDealers(filters: object): Observable<Dealer[]> {
        // Remove the dealers and fetch a new set of dealers based on sales range
        delete filters[FilterName.dealers.toString()];
        const salesRangeOnlyFilters = omit<any>(filters, [FilterName.makes.toString(), FilterName.segments.toString(), FilterName.models.toString(), FilterName.zips.toString(), FilterName.zones.toString()]);
        return this.filterStateService.fetchFilterNames(FilterName.dealers, salesRangeOnlyFilters)
            .pipe(flatMap(dealers => {
                if (dealers.length > 0) {
                    delete filters[FilterName.sales_range.toString()];
                    filters[FilterName.dealers.toString()] = dealers.map((dealer: { id: any }) => dealer.id);
                    return this.fetchFilteredDealers(filters);
                } else {
                    return of([]);
                }
            }));
    }

    public fetchFilteredDealers(filters: object): AsyncSubject<Dealer[]> {
        const url = `${this.rootUrl}/filter/names`;
        if (this.filterStateService.getFilterValue(FilterName.use_sales_data)) {
            filters["use_sales_data"] = "true";
        }
        const options = this.createRequestOptions(filters);
        const dealerSubject = new AsyncSubject<Dealer[]>();
        this.fetchFilteredDealersSubscription = this.fetchInterfaces<{ name: string; id: number }>(url, options).subscribe((dealerTuples) => {
            this.dealerDetailsSub = this.getDealerDetails(map(dealerTuples, "dealer_id")).subscribe((newDealers) => {
                dealerSubject.next(newDealers);
                dealerSubject.complete();
            });
        });
        return dealerSubject;
    }

    getDealerDetails(dealerIds: number[]): Observable<Dealer[]> {
        const dealerSubject = new AsyncSubject<Dealer[]>();
        const foundDealers = this.getCachedDealers(dealerIds);
        dealerIds = this.getNotCachedDealerIds(dealerIds);
        if (dealerIds.length === 0) {
            dealerSubject.next(foundDealers);
            dealerSubject.complete();
        } else {
            const filters = [];
            filters["dealer_id"] = dealerIds;
            // setTimeout(() => {
            //     dealerSubject.next([]);
            //     dealerSubject.complete();
            // }, 500);

            const url = `${this.rootUrl}/dealers/details`;
            if (this.filterStateService.getFilterValue(FilterName.use_sales_data)) {
                filters["use_sales_data"] = "true";
            }

            const options = this.createRequestOptions(filters);
            this.dealerDetailsSub = this.fetchInterfaces<Dealer>(url, options).subscribe((dealers) => {
                const cleanedDealers = dealers.map((dealer) => this.cleanKeys(dealer, DealerMapping));
                cleanedDealers.forEach((dealer) => {
                    this.dealersCache[dealer.dealer_id] = dealer;
                });
                const requestedDealers = cleanedDealers.concat(foundDealers);
                dealerSubject.next(requestedDealers);
                dealerSubject.complete();
            });
        }
        return dealerSubject;
    }

    cancelPreviousDealerDetails(): void {
        if (this.currentDetailsQuery) {
            this.currentDetailsQuery.unsubscribe();
            this.currentDetailsQuery = null;
        }
    }

    updateSpotlightDealer(): void {
        this.spotlightDealerId = this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer);
        if (this.spotlightDealerDetails.getValue() && this.spotlightDealerDetails.getValue().dealer_id === this.spotlightDealerId) {
            return;
        }
        if (this.spotlightDealerId) {
            this.dealerDetailsSub = this.getDealerDetails([this.spotlightDealerId]).subscribe((spotlightDealer) => {
                this.spotlightDealerDetails.next(spotlightDealer[0]);
            });
        } else {
            this.spotlightDealerDetails.next(null);
        }
    }

    updateFilteredDealerSet(): void {
        this.cancelPreviousDealerDetails();
        this.loadingDealerDetailsSubject.next(true);
        this.dealerDetails.next([]);
        this.currentDetailsQuery = this.getFilteredDealerDetails().subscribe((dealers) => {
            if (this.filterStateService.getFilterValue(FilterName.include_spotlight) === "exclude" || this.filterStateService.getFilterValue(FilterName.competitors_selection) === SpotlightCompetitors.PumpIn) {
                const spotlightDealer = this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer);
                dealers = dealers.filter((dealer) => dealer.dealer_id !== spotlightDealer);
            }
            this.dealerDetails.next(dealers);
            this.loadingDealerDetailsSubject.next(false);
        });
    }

    getDealerName(dealerId: number | string): string {
        dealerId = Number(dealerId);
        if (!this.dealersCache[dealerId]) {
            return "Loading...";
        }
        return this.dealersCache[dealerId].dealer_name;
    }

    /**
     * Creates and Saves an Excel Workbook based on the current filtered data
     * for the dealer-sales-comparison component.
     *
     * @returns void
     */
    createDealerSalesComparisonWorkbook(dealersYearlySales: GroupBySales[], displayDates: string[], showVolume: boolean, excelDate: string[]): void {
        const startTime = Date.now();
        const blobType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const params: any = this.filterStateService.getCurrentApiFilters();
        const useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        // Here we get the DMA names to be used later in the file name
        let dmaNames = "";
        params.dma.forEach(dmacode => {
            dmaNames = dmaNames.concat("_", this.getDmaName(dmacode));
        });

        const workbook = new Excel.Workbook();
        const dlrSalesCompSheet = workbook.addWorksheet("Dealer Sales Comparison");
        // Initial column setup, other fields assigned later on purpose
        dlrSalesCompSheet.columns = [
            { key: "1", width: 36.33 },
            { key: "2", },
            { key: "3", },
            { key: "4", },
            { key: "5", },
            { key: "6", },
            { key: "7", }
        ];
        dlrSalesCompSheet.columns[0].values = ["Dealer Name"];
        dlrSalesCompSheet.columns[0].font = { bold: true, underline: true, size: 12 };
        // The following logic is used because if the user selects to Show Sales Volume, then the
        // Excel file will have columns for Unit Sales AND Share %.
        // Also note that the first loop is just for the Header row
        // If the user selects Hide Sales Volume, there will only be columns for Share %
        // realIdx is the column number in the spreadsheet
        let realIdx = 1;
        excelDate.forEach(date => {
            if (showVolume) {
                dlrSalesCompSheet.columns[realIdx].values = [date + " Unit Sales"];
                dlrSalesCompSheet.columns[realIdx].width = useSalesData ? 27.5 : 21.5;
                dlrSalesCompSheet.columns[realIdx].font = { bold: true, underline: true, size: 12 };
                dlrSalesCompSheet.columns[realIdx].alignment = { vertical: "middle", horizontal: "right" };
                realIdx++;
            }
            dlrSalesCompSheet.columns[realIdx].values = [date + " Share %"];
            dlrSalesCompSheet.columns[realIdx].width = useSalesData ? 27.5 : 21.5;
            dlrSalesCompSheet.columns[realIdx].font = { bold: true, underline: true, size: 12 };
            dlrSalesCompSheet.columns[realIdx].alignment = { vertical: "middle", horizontal: "right" };
            realIdx++;
        });
        // The following loop follows similar logic as above, however it is for each row of Dealer data
        for (let i = 0; i < dealersYearlySales.length; i++) {
            const dealer = dealersYearlySales[i];
            let curRow = [dealer.groupByValue];
            displayDates.forEach(date => {
                if (showVolume) {
                    curRow.push(dealer.sales[date] ? dealer.sales[date].toString() : "0");
                }
                curRow.push(dealer.shares[date] && typeof dealer.shares[date] === "number" && !Number.isNaN(dealer.shares[date]) ? (Math.round((Math.round(dealer.shares[date] * 1000) / 1000) * 100) / 100).toFixed(2).toString() + "%" : "n/a");
            });
            dlrSalesCompSheet.addRow(curRow).font = ({ bold: false, underline: false, size: 12 });
        }
        const filename = `DealerSales${dmaNames}_` + moment(new Date()).format("YYYYMMDD") + ".xlsx";

        workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: blobType });
            FileSaver.saveAs(blob, filename);
            this.metricsService.sendMetric({
                metricName: "excel_duration",
                metricValue: (Date.now() - startTime) / 1000.0,
                unit: "Seconds",
                dimensions: [
                    {
                        Name: "Excel Name",
                        Value: "Dealer Sales Comparison"
                    }
                ]
            });
        });
    }

    /**
     * Fetches the DMA Name by DMA ID via the DMADataService
     *
     * @param   dmaId   number  DMA unique identifier
     * @returns string  The name of the DMA for the ID you passed.
     */
    getDmaName(dmaId: number): string {
        const res = this.dmaDataService.getDmaName(dmaId);
        return res === "Loading..." ? undefined : res;
    }
}
