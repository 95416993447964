import { HttpClient } from "@angular/common/http";
import { Directive, EventEmitter, Inject, Injectable } from "@angular/core";
import { FilterDefaultState } from "@at/core";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import * as _ from "lodash";
import { omit } from "lodash";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, forkJoin, Observable, Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { DateUtils } from "../../utils/date-utils";
import { GLOBAL_STATE } from "../base/components/tokens/tokens";
import { AbstractPresentationFilterService } from "../base/services/presentation-filter.service.abstract";
import { FilterName } from "../models/filter-name.enum";
import { PresentationTypes } from "../models/presentation-type.enum";
import { CognitoService } from "../services/cognito.service";
import { DealerDataService } from "../services/dealer-data.service";
import { DmaDataService } from "../services/dma-data.service";
import { FilterStateService } from "../services/filter-state.service";
import { MetadataService } from "../services/metadata.service";
import { FeatureFlags } from "./feature-flag.service";
import { NgrxFilterStateService } from "./ngrx-filter-state.service";
import { PresentationCookieService } from "./presentation-cookie.service";
import { UserCookieService } from "./user-cookie.service";
import { MetricsService } from "./metrics.service";
import * as moment from "moment";

/**
 * PresentationFilterService
 *
 * This connects the FilterStateService mixed with some custom functionality specific to our
 * Preset Views outside of the Custom View.  The Views that utilize this service
 * are /make-vs-make/, /make-vs-segment/, and /marketshare/ all located in /src/app/ in
 * their respective directories.  The injects/params are injected in each of the components module files
 * and are variable based on the view.
 *
 * @param   GLOBAL_STATE        any                 The Global State of the Application in all cases for this service
 *                                                  we are passing the FILTER_STATE which is the Global Filter State
 *                                                  for the Application
 * @param   filterDefaults      PresentationFilter  These are the default filters you can pass for your particular View
 * @param   resettableFilters   string[]            This is a String Array of Filters that can be cleared or reset
 * @param   clientOnlyFilters   string[]            This is a String Array of Filters for a specific client - ?? I think
 * @param   arrayFilters        string[]            This is a String Array of Filters - not sure the use here???
 * @param   presentationType    string              This is a String that's passed from the PresentationTypes enum
 *                                                  and is the main determinator of conditional logic
 */
@Directive()
@Injectable({
    providedIn: "root"
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class PresentationFilterService extends AbstractPresentationFilterService {
    private dealerPinListener = new BehaviorSubject("default message");
    dealerIds = this.dealerPinListener.asObservable();

    private accordionClickListener = new BehaviorSubject("default message");
    accordionIds = this.accordionClickListener.asObservable();

    private pptClickListener = new BehaviorSubject(false);
    pptClickListenerBool = this.pptClickListener.asObservable();

    private zipZoneListener = new BehaviorSubject([]);
    zone = this.zipZoneListener.asObservable();
    requestError = false;
    processingRequest = true;
    globalStateSubscription: Subscription;
    protected backSelectionSubscription: Subscription; // A subscription that listens for the Filters Updating - the naming on this is odd for what it does
    cookiePrefix = "presentation"; // our prefix from all cookies from this service - we could make this an inject and make it dynamic
    chipCountUpdated = new EventEmitter<string[]>(); // This emits an event when the chip count increments or decrements to update the view
    protected selectionPanelChipCount = {}; // this holds the chip counts for each selection panel displayed
    canViewMultipleMakes: boolean;
    oldestDate: string;
    useSalesData: boolean;

    constructor(
        protected cookieService: CookieService,
        protected http: HttpClient,
        protected cognitoService: CognitoService,
        protected dmaDataService: DmaDataService,
        protected metadataService: MetadataService,
        protected metricsService: MetricsService,
        protected userCookieService: UserCookieService,
        protected dealerDataService: DealerDataService,
        protected ngrxFilterStateService: NgrxFilterStateService,
        @Inject(GLOBAL_STATE) public globalFilterState: FilterStateService,
        @Inject("filterDefaults") public filterDefaults: FilterDefaultState,
        @Inject("resettableFilters") public resettableFilters: string[],
        @Inject("clientOnlyFilters") public clientOnlyFilters: string[],
        @Inject("arrayFilters") public arrayFilters: string[],
        @Inject("presentationFilters") public presentationFilters: string[],
        @Inject("presentationType") public presentationType: string
    ) {
        super(
            cookieService,
            http,
            cognitoService,
            dmaDataService,
            metadataService,
            userCookieService,
            dealerDataService,
            ngrxFilterStateService
        );
        this.serviceInit();
    }

    /**
     * Initialization based on Presentation Type.
     */
    serviceInit(): void {
        // set the filter defaults
        this.ngrxFilterStateService.setDefaultFilterState(this.filterDefaults);
        let initialStateDefaults = _.cloneDeep(this.filterDefaults);
        initialStateDefaults.type = "[FilterState]";
        // set the current filters with current defaults
        this.ngrxFilterStateService.setCurrentFilterState(initialStateDefaults);
        // set resettableFilters
        this.ngrxFilterStateService.setResettableFilters(this.resettableFilters);


        switch (this.presentationType) {
            case PresentationTypes.COMPOSITE:
                this.globalStateSubscription =
                    this.globalFilterState.filtersUpdated.subscribe(
                        this.updatedFilterState.bind(this)
                    );
                this.cognitoService.authenticated
                    .pipe(
                        skipWhile((i) => !i),
                        take(1)
                    )
                    .subscribe(this.setupCurrentGlobalFilters.bind(this));
                this.filtersUpdated.subscribe(this.updatedFilterState.bind(this));
                this.metadataService.metadata
                    .pipe(
                        skipWhile((i) => !(i && i.maxDate && i.maxSalesDate)), take(1)
                    )
                    .subscribe((meta) => {
                        this.setFilterValue(FilterName.last_update, meta.maxDate);
                        this.setFilterValue(FilterName.last_sales_update, meta.maxSalesDate);
                        this.oldestDate = moment(meta.maxDate).subtract(2, "years").add(1, "month").format("YYYYMMDD");
                    });
                this.updatedFilterState([FilterName.dma.toString()]); // initializes the dma filter
                this.updateSegmentFilterName();
                break;
            case PresentationTypes.MAKE_VS_MAKE:
                this.cognitoService.authenticated
                    .pipe(
                        skipWhile((i) => !i),
                        take(1)
                    )
                    .subscribe(this.setupCurrentFilters.bind(this));
                this.metadataService.metadata
                    .pipe(
                        skipWhile((i) => !(i && i.maxDate && i.maxSalesDate)), take(1)
                    )
                    .subscribe((meta) => {
                        this.setFilterValue(FilterName.last_update, meta.maxDate);
                        this.setFilterValue(FilterName.last_sales_update, meta.maxSalesDate);
                        this.oldestDate = moment(meta.maxDate).subtract(2, "years").add(1, "month").format("YYYYMMDD");
                    });
                this.getStates();
                break;
            case PresentationTypes.MAKE_VS_SEGMENT:
                this.globalFilterState.filtersUpdated.subscribe(
                    this.updatedFilterState.bind(this)
                );
                this.filtersUpdated.subscribe(this.updatedFilterState.bind(this));
                this.cognitoService.authenticated
                    .pipe(
                        skipWhile((i) => !i),
                        take(1)
                    )
                    .subscribe(this.setupCurrentGlobalFilters.bind(this));
                this.metadataService.metadata
                    .pipe(
                        skipWhile((i) => !(i && i.maxDate && i.maxSalesDate)), take(1)
                    )
                    .subscribe((meta) => {
                        this.setFilterValue(FilterName.last_update, meta.maxDate);
                        this.setFilterValue(FilterName.last_sales_update, meta.maxSalesDate);
                        this.oldestDate = moment(meta.maxDate).subtract(2, "years").add(1, "month").format("YYYYMMDD");
                    });
                this.updateSegmentFilterName();
                this.getStates();
                break;
            case PresentationTypes.MAKE_VS_MARKET:
                this.cognitoService.authenticated
                    .pipe(
                        skipWhile((i) => !i),
                        take(1)
                    )
                    .subscribe(this.setupCurrentFilters.bind(this));
                this.metadataService.metadata
                    .pipe(
                        skipWhile((i) => !(i && i.maxDate && i.maxSalesDate)), take(1)
                    )
                    .subscribe((meta) => {
                        this.setFilterValue(FilterName.last_update, meta.maxDate);
                        this.setFilterValue(FilterName.last_sales_update, meta.maxSalesDate);
                        this.oldestDate = moment(meta.maxDate).subtract(2, "years").add(1, "month").format("YYYYMMDD");
                    });
                this.getStates();
                break;
            default: // if you don't pass a presentation type it won't init anything
                break;
        }
    }

    serviceShutDown() {
        this.globalStateSubscription.unsubscribe();
    }

    getDataFilterNames(): string[] {
        return this.presentationFilters;
    }

    /**
     * This is the filter options override method for the MVM, MVS, and MS PresentationTypes, Composite uses the base class method
     * It will either call the getFilterOptionsOverride method or it will call the method from the base class dependant on the
     * PresentationType that is currently being used.
     *
     * @param filterName        FilterName             The filter name to get options for
     * @param keys              string[] | number[]    An array of filter array keys
     * @param excludedFilters   string[]               An array of filters to exclude
     * @returns Observable<any[]>
     */
    getFilterOptions(
        filterName: FilterName,
        keys: string[] | number[] = [],
        excludedFilters: string[] = []
    ): Observable<any[]> {
        excludedFilters.push("buyer_dma_code");
        switch (this.presentationType) {
            case PresentationTypes.MAKE_VS_MAKE:
            case PresentationTypes.MAKE_VS_MARKET:
            case PresentationTypes.MAKE_VS_SEGMENT:
                return this.getFilterOptionsOverride(filterName, keys, excludedFilters);
            default:
                if (filterName === FilterName.makes) {
                    return this.getFilterOptionsOverride(
                        filterName,
                        keys,
                        excludedFilters
                    );
                }
                if (filterName === FilterName.dates) {
                    return this.getFilterOptionsOverride(
                        filterName,
                        keys,
                        excludedFilters
                    );
                }
                if (filterName === FilterName.models) {
                    return this.getFilterOptionsOverride(filterName, keys, excludedFilters);
                }
                return super.getFilterOptions(filterName, keys, excludedFilters);
        }
    }

    /**
     * This is the filter options override method for the MVM, MVS, and MS PresentationTypes, Composite uses the base class method
     *
     * @param filterName        FilterName             The filter name to get options for
     * @param keys              string[] | number[]    An array of filter array keys
     * @param excludedFilters   string[]               An array of filters to exclude
     * @returns Observable<any[]>
     */
    getFilterOptionsOverride(
        filterName: FilterName,
        keys: string[] | number[] = [],
        excludedFilters: string[] = []
    ): Observable<any[]> {
        const currentActiveSelection =
            FilterName[
                this.ngrxFilterStateService.getFilter(FilterName.active_selection)
            ];
        const nextFilters = this.getNextFilterNames(
            FilterName[currentActiveSelection]
        ).concat(filterName.toString());
        const filters = this.getCurrentApiFilters(
            nextFilters.concat(excludedFilters)
        ); // exclude current filters, next filters, and those passed in
        if (!["spotlight_dealer", "dealers"].includes(filterName.toString())) {
            filters["dealers"] = [
                this.ngrxFilterStateService.getFilter(FilterName.spotlight_dealer)
            ];
        }
        if (filterName === FilterName.dates) {
            return new Observable<[]>();
        }
        filters["filtername"] = filterName.toString();
        return this.fetchFilterNames(filterName, filters);
    }

    /**
     * This is a strange method.  It says it's to setup the current global filters, although it is also setting up the filterDefaults
     * the reason it's not really global is it's route specific.  We only call this on Composite (Custom) and Make Vs Segment.
     * It's not application wide and therefore, not global.
     *
     * @param authenticated boolean     is the user authenticated?
     */
    setupCurrentGlobalFilters(authenticated: boolean): void {
        // wait to setup the filters until the user is authenticated
        if (authenticated) {
            for (const name in this.filterDefaults) {
                // unrelated to this method... buyer_dma_code is not a global filter.
                if (name === FilterName.buyer_dma_code.toString()) {
                    this.setDefaultFilterValue(
                        FilterName.buyer_dma_code,
                        this.ngrxFilterStateService.getFilter(FilterName.dma)
                    ); // sets buyer_dma_code to default exclude
                }
                // here it appears we are hydrating the cookie values with our state.
                // if the name is a global filter and has a cookie set,
                // we hydrate it back to our local application state
                if (this.isGlobalFilterCookieSet(name)) {
                    this.setFilterValue(
                        FilterName[name],
                        (<PresentationCookieService>this.userCookieService).getCookieValue(
                            name,
                            this.arrayFilters,
                            "filter"
                        )
                    );
                    // otherwise we just set the state of the filter to the local app state.
                } else {
                    this.setFilterValue(FilterName[name], this.filterDefaults[name]);
                }
                // adds the name of the filter we added to the local app state to an array of filters that changed value.
                this.filtersChanged.push(name);
            }
            // if filtersChanged array length is > 0 push a string called "filters-setup"
            // this really makes no sense because of course they changed, theres no logic in here to prevent use from changing them.
            if (this.filtersChanged.length) {
                this.filtersChanged.push("filters-setup"); // this notifies any subscribed element that the emitted filters where emitted from this function
            }
            // now we trigger the event emitter
            this.emitChange();
        }
    }

    setupCurrentFilters(authenticated: boolean): void {
        // wait to setup the filters until the user is authenticated
        if (authenticated) {
            for (const name in this.filterDefaults) {
                if (name === FilterName.buyer_dma_code.toString()) {
                    this.setDefaultFilterValue(
                        FilterName.buyer_dma_code,
                        this.ngrxFilterStateService.getFilter(FilterName.dma)
                    ); // sets buyer_dma_code to default exclude
                }

                this.filtersChanged.push(name);
            }
            if (this.filtersChanged.length) {
                this.filtersChanged.push("filters-setup"); // this notifies any subscribed element that the emitted filters where emitted from this function
            }
            this.emitChange();
        }
    }

    isGlobalFilterCookieSet(name: string): boolean {
        const res =
            name === FilterName.use_ihs_segments.toString() ||
            name === FilterName.show_volume.toString() ||
            name === FilterName.dma.toString();
        return (
            res &&
            (<PresentationCookieService>this.userCookieService).cookieValueExists(
                name,
                "filter"
            )
        );
    }

    /**
     * Calls the Base Class Method if we using composite, otherwise calls custom function
     *
     * @param exclude (string | string[])   A string or a string[] of filters to exclude
     * @param rest    string[]              The rest of the params
     * @returns object                      Returns obj of current api filters
     */
    getCurrentApiFilters(
        exclude: string | string[] = null,
        ...rest: string[]
    ): object {
        switch (this.presentationType) {
            case PresentationTypes.COMPOSITE:
                return super.getCurrentApiFilters(exclude, ...rest);
            case PresentationTypes.MAKE_VS_MAKE:
            case PresentationTypes.MAKE_VS_MARKET:
            case PresentationTypes.MAKE_VS_SEGMENT:
            default:
                const ex = []
                    .concat(exclude, rest)
                    .concat(this.clientOnlyFilters.slice());
                const filters = omit<any>(
                    this.ngrxFilterStateService.getCurrentFilterState(),
                    ex
                );
                const spotlightDealerId = this.ngrxFilterStateService.getFilter(
                    FilterName.spotlight_dealer
                );
                if (spotlightDealerId && filters["dealers"]) {
                    if (filters["dealers"]) {
                        const dealers = (filters.dealers as number[]).slice();
                        dealers.push(spotlightDealerId);
                        filters["dealers"] = dealers;
                    } else {
                        filters["dealers"] = [spotlightDealerId];
                    }
                }
                return filters;
        }
    }

    /**
     * A Promise based Http call that returns a list of states
     * a specific dealer has locations for
     *
     * @returns Promise<any>
     */
    getStates(): Promise<any> {
        const url = `${this.rootUrl}/dealers/details?getStates&used=0`;
        const useSalesData = this.ngrxFilterStateService.getFilter(FilterName.use_sales_data);
        const options = this.createRequestOptions(useSalesData ? {
            "use_sales_data": "true"
        } : {});

        return this.fetchInterfaces(url, options).toPromise();
    }

    /**
     * A Promise based Http call that returns a list of states
     * a specific dealer has locations for
     *
     * @returns Promise<any>
     */
    getCities(selectedState: string[]): Promise<any> {
        const url = `${this.rootUrl}/dealers/details?getCities&state=${selectedState}&used=0`;
        const useSalesData = this.ngrxFilterStateService.getFilter(FilterName.use_sales_data);
        const options = this.createRequestOptions(useSalesData ? {
            "use_sales_data": "true"
        } : {});

        return this.fetchInterfaces(url, options).toPromise();
    }

    updatedFilterState(changes: string[]): void {
        if (
            changes.includes(FilterName.use_ihs_segments.toString()) &&
            !changes.includes("filters-setup")
        ) {
            this.updateSegmentFilterName();
            this.setFilterValue(
                FilterName.use_ihs_segments,
                this.globalFilterState.currentFilters[
                    FilterName.use_ihs_segments.toString()
                ]
            );
            this.resetResettableFilters();
            return; // bail this is for Make vs Segment but because of scope can't use this.presentationType
        }
        if (
            this.presentationType &&
            this.presentationType === PresentationTypes.COMPOSITE &&
            !changes.includes("filters-setup")
        ) {
            // Composite block
            if (changes.includes(FilterName.dma.toString())) {
                this.resetResettableFilters();
                // get filter state from global
                const currentDma = this.globalFilterState.getFilterValue<number[]>(
                    FilterName.dma
                );
                this.setFilterValue(FilterName.dma, currentDma);
                this.setFilterValue(FilterName.buyer_dma_code, currentDma); // buyer_dma_code is set to the current dmas as this filters the buyers to only inside the selected dmas
            }
            // Composite Block - we avoid this on make vs segment by using the return above
            if (changes.includes(FilterName.use_ihs_segments.toString())) {
                this.updateSegmentFilterName();
                this.setFilterValue(
                    FilterName.use_ihs_segments,
                    this.globalFilterState.currentFilters[
                        FilterName.use_ihs_segments.toString()
                    ]
                );
                this.resetResettableFilters();
            }
        }
    }

    updateSegmentFilterName(): void {
        const useIhsSegments = this.globalFilterState.getFilterValue<boolean>(
            FilterName.use_ihs_segments
        );
        // If using IHS segments, we need the segments index for replacing it and vice versa
        const previousFilterName = useIhsSegments
            ? FilterName.segments
            : FilterName.ihs_segments;
        const index = this.presentationFilters.findIndex(
            (item) => item === previousFilterName.toString()
        );
        if (index > -1) {
            this.presentationFilters[index] = useIhsSegments
                ? FilterName.ihs_segments.toString()
                : FilterName.segments.toString();
        }
    }

    /**
     * Checks if the previous conditions of the selected filter were met
     *
     * @param   currentFilterName     FilterName  The enum of all FilterNames
     * @returns true | false          boolean     returns true or false
     */
    isPreviousConditionsMet(currentFilterName: FilterName): boolean {
        let allConditionsMet = true;
        const filterNameIndex = this.presentationFilters.findIndex(
            (item) => item === currentFilterName.toString()
        );
        if (filterNameIndex === -1) {
            return false;
        }
        for (let i = 0; i < filterNameIndex; i++) {
            if (!this.areConditionsMet(FilterName[this.presentationFilters[i]])) {
                allConditionsMet = false;
            }
        }
        return allConditionsMet;
    }

    /**
     * A special getter for the `use_ihs_segments` filter that gets the value from StateService
     *
     * @returns boolean     use_ihs_segments    Returns the filter value - true | false
     */
    getUseSegmentsFlag(): boolean {
        // if we are using the powerpoint flow...
        if (this.presentationType === PresentationTypes.COMPOSITE) {
            return this.ngrxFilterStateService.getFilter(FilterName.use_ihs_segments);
        }
        const useIHSSegments: boolean =
            this.globalFilterState.getFilterValue<boolean>(
                FilterName.use_ihs_segments
            );
        // The `use_ihs_segments` filter is stored in the non-PPT flow, so we have to fetch it directly here instead of using `getFilterValue`
        return useIHSSegments ? useIHSSegments : false;
    }

    /**
     * Checks if a Filter has been visited by a User.    Similar to a ":visited" state on a link
     *
     * @param filterName    FilterName  The FilterName enum
     * @returns boolean     true | false
     */
    isVisited(filterName: FilterName): boolean {
        const activeSelectionIndex = this.presentationFilters.findIndex(
            (item) =>
                item ===
                this.ngrxFilterStateService.getFilter(FilterName.active_selection)
        );
        const filterNameIndex = this.presentationFilters.findIndex(
            (item) => item === filterName.toString()
        );
        return (
            (filterNameIndex !== -1 && filterNameIndex <= activeSelectionIndex) ||
            this.ngrxFilterStateService.getFilter(FilterName.report_building)
        );
    }

    /**
     * Fetches the DMA Name by DMA ID via the DMADataService
     *
     * @param   dmaId   number  DMA unique identifier
     * @returns string  The name of the DMA for the ID you passed.
     */
    getDmaName(dmaId: number): string {
        const res = this.dmaDataService.getDmaName(dmaId);
        return res === "Loading..." ? undefined : res;
    }

    /**
     * Fetches an array of DMA Names based on an array of DMA IDs
     *
     * @param   dmaIds  number[]    An array of DMA IDs
     *
     * @returns string[]    returns a string array of DMAs
     */
    getDmaNames(dmaIds: number[]): string[] {
        return dmaIds.map((dmaId) => this.getDmaName(dmaId));
    }

    /**
     * Checks if the last selection was active
     *
     * @returns boolean     true | false
     */
    isLastSelection(): boolean {
        const activeSelection = this.ngrxFilterStateService.getFilter(
            FilterName.active_selection
        );
        return (
            activeSelection ===
            this.presentationFilters[this.presentationFilters.length - 1]
        );
    }

    /**
     * Changes the chip count (selected filter count) for a given FilterName
     * and then triggers the Event Emitter to update the view.
     *
     * @param filterName    FilterName   the supplied FilterName enum
     *                                   string ie. FilterName.dateRanges = "dateRanges"
     * @param chipCount     number       The current chip count
     */
    chipCountChange(filterName: FilterName, chipCount: number): void {
        this.selectionPanelChipCount[filterName] = chipCount;
        this.chipCountUpdated.emit([filterName.toString()]);
    }

    /**
     * Takes the current filter name, checks if the conditions are met, then finds the next filter in the list,
     * and then sets it to active.
     *
     * @param currentFilterName string  The currently active filter name
     * @returns void
     */
    nextActiveSelection(currentFilterName: FilterName): void {
        if (this.areConditionsMet(currentFilterName)) {
            const activeSelection = this.ngrxFilterStateService.getFilter(
                FilterName.active_selection
            );
            const nextIndex =
                this.presentationFilters.findIndex(
                    (filter) => filter === activeSelection
                ) + 1;
            if (nextIndex < this.presentationFilters.length) {
                this.setActiveSelection(
                    FilterName[this.presentationFilters[nextIndex]]
                );
            }
        }
    }

    /**
     * Unsubscribes from previous backSelectionSubscription, checks if the
     * passed filterName has been visited by the user and then updates the filters
     * and updates the filter value and resubscribes to backSelectionSubscription
     *
     * @param filterName    string  The name of the filter you want to set as the active selection
     * @returns void
     */
    setActiveSelection(filterName: FilterName): void {
        // if the back selection subscription is still open, unsubscribe
        if (
            this.backSelectionSubscription &&
            !this.backSelectionSubscription.closed
        ) {
            this.backSelectionSubscription.unsubscribe();
        }
        if (this.isVisited(filterName)) {
            this.backSelectionSubscription = this.filtersUpdated.subscribe(
                (filtersUpdated) => {
                    // this will reset the future filters if the current selection panel is updated

                    if (filtersUpdated.includes(filterName.toString())) {
                        this.clearNextFilters(filterName);
                        this.backSelectionSubscription.unsubscribe();
                    }
                }
            );
        }
        this.setFilterValue(FilterName.active_selection, filterName.toString());
    }

    /**
     * Clears the next filter values that come next after the filterName passed
     *
     * @param name FilterName   The FilterName you want to stop at
     *                          before clearing any future filter values
     * @returns void
     */
    clearNextFilters(name: FilterName): void {
        const nextFilterNames = this.getNextFilterNames(name);
        const futureFilters = [];
        for (let i = 0; i < nextFilterNames.length; i++) {
            const filterName = FilterName[nextFilterNames[i]];
            if (
                typeof (this.ngrxFilterStateService.getFilter(filterName) as any[]) !==
                "undefined" &&
                !!(this.ngrxFilterStateService.getFilter(filterName) as any[]).length
            ) {
                futureFilters.push(filterName);
            }
        }
        this.resetFilters(futureFilters);
    }

    /**
     * Gets an array of filter names that are next up after the filterName you pass
     *
     * @param filterName string     The FilterName to start pulling names after
     * @returns string[]            A String Array of FilterNames that are up next
     */
    getNextFilterNames(filterName: FilterName): string[] {
        const filterNameIndex = this.presentationFilters.findIndex(
            (item) => item === filterName.toString()
        );
        return this.presentationFilters.slice(filterNameIndex + 1);
    }

    /**
     * Creates and Saves an Excel Workbook based on the current filtered data
     *
     * @returns void
     */
    createWorkbook(): void {
        const useSalesData = this.ngrxFilterStateService.getFilter(
            FilterName.use_sales_data
        );

        const dateRangeType = this.ngrxFilterStateService.getFilter(FilterName.dateRangeType);

        const dataSource = useSalesData ? "Sales Data" : "Registration Data";

        const startTime = Date.now();
        // if we are using the ppt flow we don't create excel sheets
        if (this.presentationType === PresentationTypes.COMPOSITE) {
            throw new Error("Method not implemented.");
        }
        const blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const dealerName =
            this.ngrxFilterStateService.getFilter("selected_dealer").dealer_name;
        const dealerId =
            this.ngrxFilterStateService.getFilter("selected_dealer").dealer_id;
        const params: any = omit<any>(
            this.ngrxFilterStateService.getCurrentFilterState(),
            this.clientOnlyFilters.slice()
        );

        const displayDates: string[] = this.ngrxFilterStateService.getFilter(FilterName.displayDateRanges);

        const startDate =
            params["dateRangeType"] === DateRangeTypes.YEARS
                ? displayDates[0]
                : displayDates[0].split("-")[0];
        const endDate =
            params["dateRangeType"] === DateRangeTypes.YEARS ||
                !displayDates[2].includes("-")
                ? displayDates[2]
                : displayDates[2].split("-")[1];

        const lastUpdate = this.getFilterValue(useSalesData ? FilterName.last_sales_update : FilterName.last_update);

        const latestDate = moment(lastUpdate).format("MMMM DD, YYYY");

        const commonHeader = useSalesData ? [
            startDate,
            endDate,
            latestDate,
            dataSource,
            "Markets",
            this.getDmaName(params.dma[0]),
            dealerName
        ] : [
            startDate,
            endDate,
            dataSource,
            "Markets",
            this.getDmaName(params.dma[0]),
            dealerName
        ];
        const workbook = new Excel.Workbook();
        const t5sheet = workbook.addWorksheet("Top 5 Competitors");
        t5sheet.columns = [
            { key: "1", width: 18.67 },
            { key: "2", width: 36.33 },
            { key: "3", width: 21.5 },
            { key: "4", width: 21.5 },
            { key: "5", width: 21.5 },
            { key: "6", width: 21.5 }
        ];

        t5sheet.columns[0].values = useSalesData
            ? [
                "Start Date",
                "End Date",
                "Data Available Through",
                "Data Source",
                ,
                "Geography",
                "Spotlight Dealer"
            ] : [
                "Start Date",
                "End Date",
                "Data Source",
                ,
                "Geography",
                "Spotlight Dealer"
            ];
        t5sheet.columns[0].font = { bold: true };
        t5sheet.columns[1].font = { bold: true };
        t5sheet.columns[1].values = commonHeader;
        const competitors: any[] = this.ngrxFilterStateService
            .getFilter(FilterName.top_competitors)
            .filter((item) => item["dealer_id"] !== dealerId);


        const spotlightDealer: any = this.ngrxFilterStateService
            .getFilter(FilterName.top_competitors)
            .filter((item) => item["dealer_id"] === dealerId)[0];

        const dealer_sales_data: any[] = _.cloneDeep(
            this.ngrxFilterStateService.getFilter(FilterName.dealer_sales_data)
        );

        if (spotlightDealer.shares) {
            const addressRow = [
                "",
                `${spotlightDealer.dealer_address}, ${spotlightDealer.dealer_city}, ${spotlightDealer.dealer_state}, ${spotlightDealer.dealer_zip}`,
                ""
            ];
            const salesRow = ["", "Sales", ...spotlightDealer.sales];

            const sharesRow = [
                "",
                "Total Share",
                ...spotlightDealer.shares.map((share) => typeof share === "number" && !Number.isNaN(share) ? (share / 100) : "n/a")
            ];

            t5sheet.addRow(addressRow).font = { bold: false };
            t5sheet.addRow(["", "", ...displayDates]).font = { bold: true };
            t5sheet.addRow(salesRow);
            t5sheet.addRow(sharesRow).numFmt = "0.00%";
            t5sheet.addRow("");
        } else {
            let spotlight_sales_data = dealer_sales_data.find(
                (sales_data) => sales_data.dealer === dealerId
            );
            if (!spotlight_sales_data) {
                spotlight_sales_data = dealer_sales_data.find(
                    (sales_data) => sales_data.dealer_id === dealerId
                );
            }
            const addressRow = [
                "",
                `${spotlightDealer.dealer_address}, ${spotlightDealer.dealer_city}, ${spotlightDealer.dealer_state}, ${spotlightDealer.dealer_zip}`,
                ""
            ];
            const salesRow = ["", "Sales", ...spotlight_sales_data.sales];
            const sharesRow = [
                "",
                "Total Share",
                ...spotlight_sales_data.shares.map((share) => typeof share === "number" && !Number.isNaN(share) ? (share / 100) : "n/a")
            ];

            t5sheet.addRow(addressRow).font = { bold: false };
            t5sheet.addRow(["", "", ...displayDates]).font = { bold: true };
            t5sheet.addRow(salesRow);
            t5sheet.addRow(sharesRow).numFmt = "0.00%";
            t5sheet.addRow("");
        }
        t5sheet.getCell("A13").value = "Competitors & Rank";
        t5sheet.getCell("B13").value = "Competitors Name & Address";

        for (let i = 0; i < competitors.length; i++) {
            const competitor = competitors[i];
            if (competitor.shares) {
                const competitorRow = [i + 1, competitor.dealer_name];
                const addressRow = [
                    "",
                    `${competitor.dealer_address}, ${competitor.dealer_city}, ${competitor.dealer_state}, ${competitor.dealer_zip}`,
                    ""
                ];
                const salesRow = ["", "Sales", ...competitor.sales];
                const sharesRow = [
                    "",
                    "Total Share",
                    ...competitor.shares.map((share) => typeof share === "number" && !Number.isNaN(share) ? share / 100 : "n/a")
                ];

                t5sheet.addRow(competitorRow).font = { bold: false };
                t5sheet.addRow(addressRow).font = { bold: false };
                t5sheet.addRow(["", "", ...displayDates]).font = { bold: true };
                t5sheet.addRow(salesRow);
                t5sheet.addRow(sharesRow).numFmt = "0.00%";
                t5sheet.addRow("");
            } else {
                let competitor_sales_data = dealer_sales_data.find(
                    (sales_data) => sales_data.dealer === competitor.dealer_id
                );
                if (!competitor_sales_data) {
                    competitor_sales_data = dealer_sales_data.find(
                        (sales_data) => sales_data.dealer_id === competitor.dealer_id
                    );
                }
                const competitorRow = [i + 1, competitor.dealer_name];
                const addressRow = [
                    "",
                    `${competitor.dealer_address}, ${competitor.dealer_city}, ${competitor.dealer_state}, ${competitor.dealer_zip}`,
                    ""
                ];
                const salesRow = ["", "Sales", ...competitor_sales_data.sales];
                const sharesRow = [
                    "",
                    "Total Share",
                    ...competitor_sales_data.shares.map((share) => typeof share === "number" && !Number.isNaN(share) ? share / 100 : "n/a")
                ];

                t5sheet.addRow(competitorRow).font = { bold: false };
                t5sheet.addRow(addressRow).font = { bold: false };
                t5sheet.addRow(["", "", ...displayDates]).font = { bold: true };
                t5sheet.addRow(salesRow);
                t5sheet.addRow(sharesRow).numFmt = "0.00%";
                t5sheet.addRow("");
            }
        }

        if (useSalesData) {
            t5sheet.getCell("B8").font = { bold: true };
            t5sheet.getCell("B4").font = { underline: true };
        }else {
            t5sheet.getCell("B7").font = { bold: true };
            t5sheet.getCell("B3").font = { underline: true };
        }


        // -----------------------------------Sales By Zip code-------------------------------------------
        const zipsheet = workbook.addWorksheet("Sales By Zip code");
        // const zipData: any = this.ngrxFilterStateService.getFilter(FilterName.top_zips);

        zipsheet.columns = [
            { key: "1", width: 15.67 },
            { key: "2", width: 26.33 },
            { key: "3", width: 28 },
            { key: "4", width: 28 },
            { key: "5", width: 28, outlineLevel: 1, hidden: true },
            { key: "6", width: 28, outlineLevel: 1, hidden: true },
            { key: "7", width: 28, outlineLevel: 1, hidden: true },
            { key: "8", width: 28 },
            { key: "9", width: 28 },
            { key: "10", width: 28, outlineLevel: 1, hidden: true },
            { key: "11", width: 28, outlineLevel: 1, hidden: true },
            { key: "12", width: 28, outlineLevel: 1, hidden: true },
            { key: "13", width: 28 },
            { key: "14", width: 28 },
            { key: "15", width: 28, outlineLevel: 1, hidden: true },
            { key: "16", width: 28, outlineLevel: 1, hidden: true },
            { key: "17", width: 28, outlineLevel: 1, hidden: true },
            { key: "18", width: 28 },
            { key: "19", width: 28 },
            { key: "20", width: 28, outlineLevel: 1, hidden: true },
            { key: "21", width: 28, outlineLevel: 1, hidden: true },
            { key: "22", width: 28, outlineLevel: 1, hidden: true },
            { key: "23", width: 28 },
            { key: "24", width: 28 },
            { key: "25", width: 28, outlineLevel: 1, hidden: true },
            { key: "26", width: 28, outlineLevel: 1, hidden: true },
            { key: "27", width: 28, outlineLevel: 1, hidden: true },
            { key: "28", width: 28 },
            { key: "29", width: 28 },
            { key: "30", width: 28, outlineLevel: 1, hidden: true },
            { key: "31", width: 28, outlineLevel: 1, hidden: true },
            { key: "32", width: 28, outlineLevel: 1, hidden: true }
        ];

        const zipKeys = Object.keys(spotlightDealer).filter((key) =>
            key.match(/\d{6}_\d{6}/)
        );

        zipsheet.columns[0].values = useSalesData ?
            [
                "Start Date",
                "End Date",
                "Data Available Through",
                "Data Source",
                "Geography",
                ,
                "Dealer",
                ,
                ,
                "Top 25 ZIPs Total",
                "All Zip Codes"
            ] : [
                "Start Date",
                "End Date",
                "Data Source",
                "Geography",
                ,
                "Dealer",
                ,
                ,
                "Top 25 ZIPs Total",
                "All Zip Codes"
            ];
        zipsheet.columns[0].font = { bold: true };

        zipsheet.columns[1].values = commonHeader.concat([
            "",
            "",
            "",
            ...spotlightDealer[zipKeys[0]].zips.map((z) => Number(z.value))
        ]);

        // ---------------------------Build Dealer Results-----------------------------------------

        let colStart = 2;
        let alphaSet = [3, 6];
        const dealers = [spotlightDealer, ...competitors];

        dealers.forEach((dealer) => {
            zipsheet.columns[colStart].values =  useSalesData ? [
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                dealer.dealer_name
            ].concat([`${displayDates[0]} Sales`,
                dealer[zipKeys[0]].zips
                    .map((z) => z.sales.group)
                    .reduce((acc, val) => acc + val, 0),
                ...dealer[zipKeys[0]].zips.map((z) => {
                    if (z.sales.group as number > 0){
                        return z.sales.group;
                    } else{
                        return "N/A";
                    }
                })
            ]):
                [
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    dealer.dealer_name
                ].concat([
                    // calc sum of top 25 zip codes
                    `${displayDates[0]} Sales`,
                    dealer[zipKeys[0]].zips
                        .map((z) => z.sales.group)
                        .reduce((acc, val) => acc + val, 0),
                    ...dealer[zipKeys[0]].zips.map((z) => z.sales.group)
                ]);

            zipsheet.columns[colStart + 1].values =
                useSalesData ?
                    ["", "", "", "", "", "", "", ""].concat([
                        `${displayDates[0]} % of Sales`,
                        "",
                        ...dealer[zipKeys[0]].zips.map((z) => {
                            if (z.sales.group as number > 0){
                                return z.shares.group / 100;
                            } else{
                                return "N/A";
                            }
                        })
                    ]):
                    ["", "", "", "", "", "", ""].concat([
                        `${displayDates[0]} % of Sales`,
                        "",
                        ...dealer[zipKeys[0]].zips.map((z) => z.shares.group / 100)
                    ]);
            const top25Sales = dealer[zipKeys[1]].zips
                .map((z) => z.sales.group)
                .reduce((acc, val) => acc + val, 0);
            const top25SalesResults = top25Sales ? top25Sales !== 0: "N/A";

            zipsheet.columns[colStart + 3].values =
                useSalesData ?
                    ["", "", "", "", "", "", "", ""].concat([
                        `${displayDates[1]} Sales`,
                        top25SalesResults,
                        ...dealer[zipKeys[1]].zips.map((z) => {
                            if (z.sales.group as number > 0){
                                return z.sales.group;
                            } else {
                                return "N/A";
                            }
                        })
                    ]):
                    ["", "", "", "", "", "", ""].concat([
                        `${displayDates[1]} Sales`,
                        dealer[zipKeys[1]].zips
                            .map((z) => z.sales.group)
                            .reduce((acc, val) => acc + val, 0),
                        ...dealer[zipKeys[1]].zips.map((z) => z.sales.group)
                    ]);

            zipsheet.columns[colStart + 4].values =
                useSalesData ?
                    ["", "", "", "", "", "", "", ""].concat([
                        `${displayDates[1]} % of Sales`,
                        "",
                        ...dealer[zipKeys[1]].zips.map((z) => {
                            if (z.sales.group as number > 0){
                                return z.shares.group / 100;
                            } else{
                                return "N/A";
                            }
                        })
                    ]):
                    ["", "", "", "", "", "", ""].concat([
                        `${displayDates[1]} % of Sales`,
                        "",
                        ...dealer[zipKeys[1]].zips.map((z) => z.shares.group / 100)
                    ]);

            // We are grabbing the values directly from the excel sheet,
            // Wait till the other columns are populated and fill this column last
            const priorYear = [];
            let idx;
            if(useSalesData){
                idx = 10;
            } else{
                idx = 9;
            }
            let i = idx;
            while (zipsheet.getCell(i, alphaSet[1]).value !== null) {
                if (useSalesData && (typeof zipsheet.getCell(i, alphaSet[1]).value !== "number" || zipsheet.getCell(i, alphaSet[1]).value === 0)) {
                    priorYear.push("N/A");
                } else {
                    priorYear.push(
                        zipsheet.getCell(i, alphaSet[0]).value -
                        zipsheet.getCell(i, alphaSet[1]).value
                    );
                }
                i++;
            }
            zipsheet.columns[colStart + 2].values =
                useSalesData ?
                    ["", "", "", "", "", "", "", ""].concat([
                        "Prior year difference Sales",
                        ...priorYear
                    ]):

                    ["", "", "", "", "", "", ""].concat([
                        "Prior year difference Sales",
                        ...priorYear
                    ]);
            colStart = colStart + 5;
            alphaSet[0] = alphaSet[0] + 5;
            alphaSet[1] = alphaSet[1] + 5;
        });

        zipsheet.columns[3].numFmt = "0.00%";
        zipsheet.columns[6].numFmt = "0.00%";
        zipsheet.columns[8].numFmt = "0.00%";
        zipsheet.columns[11].numFmt = "0.00%";
        zipsheet.columns[13].numFmt = "0.00%";
        zipsheet.columns[16].numFmt = "0.00%";
        zipsheet.columns[18].numFmt = "0.00%";
        zipsheet.columns[21].numFmt = "0.00%";
        zipsheet.columns[23].numFmt = "0.00%";
        zipsheet.columns[26].numFmt = "0.00%";
        zipsheet.columns[28].numFmt = "0.00%";
        zipsheet.columns[31].numFmt = "0.00%";



        if(useSalesData){
            zipsheet.getCell("B4").font = { underline: true };
            zipsheet.getCell("C9").font = { bold: true };
            zipsheet.getCell("D9").font = { bold: true };
        }else{
            zipsheet.getCell("B3").font = { underline: true };
            zipsheet.getCell("C7").font = { bold: true };
            zipsheet.getCell("D7").font = { bold: true };
        }
        zipsheet.getCell("B7").font = { bold: true };

        // add styling
        const cellColor1 = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "DCE6F1" }
        };
        const cellColor2 = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "B8CCE4" }
        };

        if(useSalesData){
            zipsheet.mergeCells("C8:G8");
            zipsheet.getCell("C8").alignment = { horizontal: "center" };
            zipsheet.getCell("C8").fill = cellColor1;
            zipsheet.mergeCells("H8:L8");
            zipsheet.getCell("H8").alignment = { horizontal: "center" };
            zipsheet.getCell("H8").fill = cellColor2;
            zipsheet.mergeCells("M8:Q8");
            zipsheet.getCell("M8").alignment = { horizontal: "center" };
            zipsheet.getCell("M8").fill = cellColor1;
            zipsheet.mergeCells("R8:V8");
            zipsheet.getCell("R8").alignment = { horizontal: "center" };
            zipsheet.getCell("R8").fill = cellColor2;
            zipsheet.mergeCells("W8:AA8");
            zipsheet.getCell("W8").alignment = { horizontal: "center" };
            zipsheet.getCell("W8").fill = cellColor1;
            zipsheet.mergeCells("AB8:AF8");
            zipsheet.getCell("AB8").alignment = { horizontal: "center" };
            zipsheet.getCell("AB8").fill = cellColor2;
        } else{
            zipsheet.mergeCells("C7:G7");
            zipsheet.getCell("C7").alignment = { horizontal: "center" };
            zipsheet.getCell("C7").fill = cellColor1;
            zipsheet.mergeCells("H7:L7");
            zipsheet.getCell("H7").alignment = { horizontal: "center" };
            zipsheet.getCell("H7").fill = cellColor2;
            zipsheet.mergeCells("M7:Q7");
            zipsheet.getCell("M7").alignment = { horizontal: "center" };
            zipsheet.getCell("M7").fill = cellColor1;
            zipsheet.mergeCells("R7:V7");
            zipsheet.getCell("R7").alignment = { horizontal: "center" };
            zipsheet.getCell("R7").fill = cellColor2;
            zipsheet.mergeCells("W7:AA7");
            zipsheet.getCell("W7").alignment = { horizontal: "center" };
            zipsheet.getCell("W7").fill = cellColor1;
            zipsheet.mergeCells("AB7:AF7");
            zipsheet.getCell("AB7").alignment = { horizontal: "center" };
            zipsheet.getCell("AB7").fill = cellColor2;
        }


        const color1Cells = [
            "C",
            "D",
            "E",
            "F",
            "G",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "W",
            "X",
            "Y",
            "Z",
            "AA"
        ];
        const color2Cells = [
            "H",
            "I",
            "J",
            "K",
            "L",
            "R",
            "S",
            "T",
            "U",
            "V",
            "AB",
            "AC",
            "AD",
            "AE",
            "AF"
        ];

        color1Cells.map((key) => {
            if(useSalesData){
                zipsheet.getCell(`${key}9`).fill = cellColor1;
            } else{
                zipsheet.getCell(`${key}8`).fill = cellColor1;
            }
        });

        color2Cells.map((key) => {
            if(useSalesData){
                zipsheet.getCell(`${key}9`).fill = cellColor2;
            } else{
                zipsheet.getCell(`${key}8`).fill = cellColor2;
            }
        });

        if(useSalesData){
            zipsheet.getRow("8").font = { bold: true };
            zipsheet.getRow("9").font = { bold: true };
        } else{
            zipsheet.getRow("7").font = { bold: true };
            zipsheet.getRow("8").font = { bold: true };
        }
        // ------------------------------------Sales by Ad Zone--------------------------------------------

        const zonesheet = workbook.addWorksheet("Sales by Ad Zone");
        const zoneData: any = this.ngrxFilterStateService.getFilter(
            FilterName.top_zones
        );

        zonesheet.columns = [
            { key: "1", width: 15.67 },
            { key: "2", width: 26.33 },
            { key: "3", width: 28 },
            { key: "4", width: 28 },
            { key: "5", width: 28, outlineLevel: 1, hidden: true },
            { key: "6", width: 28, outlineLevel: 1, hidden: true },
            { key: "7", width: 28, outlineLevel: 1, hidden: true },
            { key: "8", width: 28 },
            { key: "9", width: 28 },
            { key: "10", width: 28, outlineLevel: 1, hidden: true },
            { key: "11", width: 28, outlineLevel: 1, hidden: true },
            { key: "12", width: 28, outlineLevel: 1, hidden: true },
            { key: "13", width: 28 },
            { key: "14", width: 28 },
            { key: "15", width: 28, outlineLevel: 1, hidden: true },
            { key: "16", width: 28, outlineLevel: 1, hidden: true },
            { key: "17", width: 28, outlineLevel: 1, hidden: true },
            { key: "18", width: 28 },
            { key: "19", width: 28 },
            { key: "20", width: 28, outlineLevel: 1, hidden: true },
            { key: "21", width: 28, outlineLevel: 1, hidden: true },
            { key: "22", width: 28, outlineLevel: 1, hidden: true },
            { key: "23", width: 28 },
            { key: "24", width: 28 },
            { key: "25", width: 28, outlineLevel: 1, hidden: true },
            { key: "26", width: 28, outlineLevel: 1, hidden: true },
            { key: "27", width: 28, outlineLevel: 1, hidden: true },
            { key: "28", width: 28 },
            { key: "29", width: 28 },
            { key: "30", width: 28, outlineLevel: 1, hidden: true },
            { key: "31", width: 28, outlineLevel: 1, hidden: true },
            { key: "32", width: 28, outlineLevel: 1, hidden: true }
        ];
        zonesheet.columns[0].values = useSalesData
            ? [
                "Start Date",
                "End Date",
                "Data Available Through",
                "Data Source",
                "Geography",
                ,
                "Dealer",
                ,
                "Rank",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                ""
            ] : [
                "Start Date",
                "End Date",
                "Data Source",
                "Geography",
                ,
                "Dealer",
                ,
                "Rank",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                ""
            ];
        zonesheet.columns[0].font = { bold: true };
        zonesheet.columns[1].values = commonHeader.concat([
            // Slice to only show top 5 ad zones
            "",
            "",
            ...spotlightDealer[zipKeys[0]].zones.map((z) => z.value).slice(0, 5)
        ]);

        zonesheet.columns[2].values =
            useSalesData ?
                [
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    spotlightDealer["dealer_name"]
                ].concat([
                    `${displayDates[0]} Sales`,
                    ...spotlightDealer[zipKeys[0]].zones.map((z) => z.sales.group).slice(0, 5)
                ]):
                [
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    spotlightDealer["dealer_name"]
                ].concat([
                    `${displayDates[0]} Sales`,
                    ...spotlightDealer[zipKeys[0]].zones.map((z) => z.sales.group).slice(0, 5)
                    // , "All Zips Total", spotlightDealer[zipKeys[0]].zips.length
                ]);



        zonesheet.getCell("C13").value = "All Zips Total";
        zonesheet.getCell("C14").value = spotlightDealer[zipKeys[0]].zips.length;

        colStart = 2;
        alphaSet = [3, 6];

        dealers.forEach((dealer) => {
            zonesheet.columns[colStart].values = useSalesData ?
                ["", "", "", "", "", "", "", dealer.dealer_name].concat([
                    `${displayDates[0]} Sales`,
                    ...dealer[zipKeys[0]].zones.map((z) => z.sales.group).slice(0, 5)
                ]):
                ["", "", "", "", "", "", dealer.dealer_name].concat([
                    `${displayDates[0]} Sales`,
                    ...dealer[zipKeys[0]].zones.map((z) => z.sales.group).slice(0, 5)
                ]);
            zonesheet.columns[colStart + 1].values = useSalesData ? ["", "", "", "", "", "", "", ""].concat([
                `${displayDates[0]} % of Sales`,
                ...dealer[zipKeys[0]].zones.map((z) => z.shares.group / 100).slice(0, 5)
            ]):
                ["", "", "", "", "", "", ""].concat([
                    `${displayDates[0]} % of Sales`,
                    ...dealer[zipKeys[0]].zones.map((z) => z.shares.group / 100).slice(0, 5)
                ]);


            zonesheet.columns[colStart + 3].values = useSalesData ? ["", "", "", "", "", "", "", ""].concat([
                `${displayDates[1]} Sales`,
                ...dealer[zipKeys[1]].zones.map((z) => z.sales.group).slice(0, 5)
            ]):
                ["", "", "", "", "", ""].concat([
                    `${displayDates[1]} Sales`,
                    ...dealer[zipKeys[1]].zones.map((z) => z.sales.group).slice(0, 5)
                ]);

            zonesheet.columns[colStart + 4].values = useSalesData ? ["", "", "", "", "", "", "", ""].concat([
                `${displayDates[1]} % of Sales`,
                ...dealer[zipKeys[1]].zones.map((z) => z.shares.group / 100).slice(0, 5)
            ]):
                ["", "", "", "", "", "", ""].concat([
                    `${displayDates[1]} % of Sales`,
                    ...dealer[zipKeys[1]].zones.map((z) => z.shares.group / 100).slice(0, 5)
                ]);

            const priorYearZone = [];
            let idx;
            if(useSalesData){
                idx = 10;
            } else{
                idx = 9;
            }
            for (let i = idx; i < idx + dealer[zipKeys[0]].zones.length; i++) {
                if(useSalesData && zonesheet.getCell(i, alphaSet[1]).value === 0){
                    priorYearZone.push("N/A");
                } else {
                    priorYearZone.push(
                        zonesheet.getCell(i, alphaSet[0]).value - zonesheet.getCell(i, alphaSet[1]).value
                    );
                }
            }
            zonesheet.columns[colStart + 2].values = useSalesData ? ["", "", "", "", "", "", "", ""].concat([
                "Prior year difference Sales",
                ...priorYearZone.slice(0, 5)
            ]):
                ["", "", "", "", "", "", ""].concat([
                    "Prior year difference Sales",
                    ...priorYearZone.slice(0, 5)
                ]);

            colStart = colStart + 5;
            alphaSet[0] = alphaSet[0] + 5;
            alphaSet[1] = alphaSet[1] + 5;
        });

        zonesheet.columns[3].numFmt = "0.00%";
        zonesheet.columns[6].numFmt = "0.00%";
        zonesheet.columns[8].numFmt = "0.00%";
        zonesheet.columns[11].numFmt = "0.00%";
        zonesheet.columns[13].numFmt = "0.00%";
        zonesheet.columns[16].numFmt = "0.00%";
        zonesheet.columns[18].numFmt = "0.00%";
        zonesheet.columns[21].numFmt = "0.00%";
        zonesheet.columns[23].numFmt = "0.00%";
        zonesheet.columns[26].numFmt = "0.00%";
        zonesheet.columns[28].numFmt = "0.00%";
        zonesheet.columns[31].numFmt = "0.00%";

        // zonesheet.columns[4].numFmt = "0.00%";
        // zonesheet.columns[5].values = ["", "", "", "", "", "All Zones Total"].concat([totalZoneSales]);


        if(useSalesData){
            zonesheet.getCell("B4").font = { underline: true };
            zonesheet.getCell("C8").font = { bold: true };
            zonesheet.getCell("D8").font = { bold: true };
            zonesheet.getCell("E8").font = { bold: true };
            zonesheet.getCell("F8").font = { bold: true };
        } else{
            zonesheet.getCell("B3").font = { underline: true };
            zonesheet.getCell("C6").font = { bold: true };
            zonesheet.getCell("D6").font = { bold: true };
            zonesheet.getCell("E6").font = { bold: true };
            zonesheet.getCell("F6").font = { bold: true };
        }

        // add styling
        if(useSalesData){
            zonesheet.mergeCells("C8:G8");
            zonesheet.getCell("C8").alignment = { horizontal: "center" };
            zonesheet.getCell("C8").fill = cellColor1;
            zonesheet.mergeCells("H8:L8");
            zonesheet.getCell("H8").alignment = { horizontal: "center" };
            zonesheet.getCell("H8").fill = cellColor2;
            zonesheet.mergeCells("M8:Q8");
            zonesheet.getCell("M8").alignment = { horizontal: "center" };
            zonesheet.getCell("M8").fill = cellColor1;
            zonesheet.mergeCells("R8:V8");
            zonesheet.getCell("R8").alignment = { horizontal: "center" };
            zonesheet.getCell("R8").fill = cellColor2;
            zonesheet.mergeCells("W8:AA8");
            zonesheet.getCell("W8").alignment = { horizontal: "center" };
            zonesheet.getCell("W8").fill = cellColor1;
            zonesheet.mergeCells("AB8:AF8");
            zonesheet.getCell("AB8").alignment = { horizontal: "center" };
            zonesheet.getCell("AB8").fill = cellColor2;
        } else{
            zonesheet.mergeCells("C7:G7");
            zonesheet.getCell("C7").alignment = { horizontal: "center" };
            zonesheet.getCell("C7").fill = cellColor1;
            zonesheet.mergeCells("H7:L7");
            zonesheet.getCell("H7").alignment = { horizontal: "center" };
            zonesheet.getCell("H7").fill = cellColor2;
            zonesheet.mergeCells("M7:Q7");
            zonesheet.getCell("M7").alignment = { horizontal: "center" };
            zonesheet.getCell("M7").fill = cellColor1;
            zonesheet.mergeCells("R7:V7");
            zonesheet.getCell("R7").alignment = { horizontal: "center" };
            zonesheet.getCell("R7").fill = cellColor2;
            zonesheet.mergeCells("W7:AA7");
            zonesheet.getCell("W7").alignment = { horizontal: "center" };
            zonesheet.getCell("W7").fill = cellColor1;
            zonesheet.mergeCells("AB7:AF7");
            zonesheet.getCell("AB7").alignment = { horizontal: "center" };
            zonesheet.getCell("AB7").fill = cellColor2;
        }

        zonesheet.getCell("C13").font = { bold: true };
        zonesheet.getCell("C13").fill = cellColor2;

        color1Cells.map((key) => {
            if(useSalesData){
                zonesheet.getCell(`${key}9`).fill = cellColor1;
            }else{
                zonesheet.getCell(`${key}8`).fill = cellColor1;
            }
        });

        color2Cells.map((key) => {
            if(useSalesData){
                zonesheet.getCell(`${key}9`).fill = cellColor2;
            }else{
                zonesheet.getCell(`${key}8`).fill = cellColor2;
            }
        });


        if(useSalesData){
            zonesheet.getRow("8").font = { bold: true };
            zonesheet.getRow("9").font = { bold: true };
        } else{
            zonesheet.getRow("7").font = { bold: true };
            zonesheet.getRow("8").font = { bold: true };
        }


        // create file and sheets

        const filename = `${spotlightDealer.dealer_name}_${DateUtils.yyyy_mm_dd(
            new Date().toString()
        )}.xlsx`;

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: blobType });
            FileSaver.saveAs(blob, filename);
            this.metricsService.sendMetric({
                metricName: "excel_duration",
                metricValue: (Date.now() - startTime) / 1000.0,
                unit: "Seconds",
                dimensions: [
                    {
                        Name: "Excel Name",
                        Value: `${this.presentationType}`
                    }
                ]
            });
        });
    }

    /**
     * Checks if the conditions are met for a specified FilterName and returns True | False
     *
     * @param filterName FilterName
     * @returns boolean True | False
     */
    areConditionsMet(
        filterName: FilterName,
        conditionFlags?: { [key: string]: any }
    ): boolean {
        let conditionsMet = false;
        if (filterName) {
            const enabled_features = this.ngrxFilterStateService.getFilter(
                FilterName.enabled_features
            ) as string[];
            const filterValue = this.ngrxFilterStateService.getFilter(filterName);
            const filterValueLength: number = filterValue
                ? (filterValue as any[]).length
                : null;

            if (
                this.presentationType === PresentationTypes.MAKE_VS_MAKE ||
                this.presentationType === PresentationTypes.MAKE_VS_MARKET ||
                this.presentationType === PresentationTypes.MAKE_VS_SEGMENT
            ) {
                switch (filterName) {
                    case FilterName.spotlight_dealer:
                        conditionsMet = !!filterValue;
                        break;
                    case FilterName.segments:
                        conditionsMet = filterValueLength >= 1;
                        break;
                    case FilterName.dma:
                        conditionsMet = filterValueLength === 1;
                        break;
                    case FilterName.makes:
                        conditionsMet = filterValueLength >= 1;
                        break;
                    case FilterName.ihs_segments:
                        conditionsMet = filterValueLength >= 1;
                        break;
                    default:
                        conditionsMet = true;
                }
            } else if (this.presentationType === PresentationTypes.COMPOSITE) {
                switch (filterName) {
                    case FilterName.spotlight_dealer:
                        conditionsMet = !!filterValue;
                        break;
                    case FilterName.makes:
                        if (
                            enabled_features &&
                            enabled_features.includes(FeatureFlags.AA_Multiple_Makes_PPT)
                        ) {
                            conditionsMet = filterValueLength >= 1;
                        } else {
                            conditionsMet = filterValueLength === 1;
                        }
                        break;
                    case FilterName.models:
                        // The user may select up to 5 models, but
                        // a selection is not required.
                        conditionsMet = filterValueLength < 6;
                        break;
                    case FilterName.segments:
                    case FilterName.ihs_segments:
                        // The user may select up to 3 segments, but
                        // a selection is not required.
                        conditionsMet = filterValueLength < 4;
                        break;
                    case FilterName.dealers:
                        conditionsMet =
                            (filterValueLength <= 5 && filterValueLength >= 1) ||
                            this.fetchChipCount(FilterName.dealers) === 0;
                        break;
                    case FilterName.zones:
                        conditionsMet = filterValueLength > 0 && filterValueLength <= 10;
                        break;
                    default:
                        conditionsMet = true;
                }
            }
        }
        return conditionsMet;
    }

    fetchChipCount(filterName: FilterName): number {
        if (this.selectionPanelChipCount.hasOwnProperty(filterName)) {
            return this.selectionPanelChipCount[filterName];
        }
    }

    /**
     * Starts building the powerpoint including any filtered data
     *
     * @returns Promise<boolean>    A boolean promise that tells if the build was successful
     */
    startPresentationBuild(
        presentationType?: PresentationTypes
    ): Promise<boolean> {
        if (this.presentationType === PresentationTypes.COMPOSITE) {
            return this.buildCustomPresentation();
        }
        const url = `${this.rootUrl}/export/powerpoint`;
        const dma = this.ngrxFilterStateService.getFilter("dealer_dma_code")
            ? [this.ngrxFilterStateService.getFilter("dealer_dma_code")]
            : this.ngrxFilterStateService.getFilter("dma");
        this.setFilterValue(FilterName.dma, dma);
        this.setFilterValue(
            FilterName.dealers,
            this.ngrxFilterStateService.getFilter("dealers").slice(0, 5)
        );
        let currentFilterState =
            this.ngrxFilterStateService.getCurrentFilterState();
        let body = omit<any>(
            this.ngrxFilterStateService.getCurrentFilterState(),
            this.clientOnlyFilters.slice()
        );
        body.report_type = presentationType || this.presentationType;
        body.date_display = this.ngrxFilterStateService.getFilter(
            FilterName.date_display
        );
        const asArray = Object.entries(body);
        // FYI these are the only props necessary for a PPT
        const propFilterArr = [
            "dma",
            "dma_names",
            "dateRangeType",
            "dateRanges",
            "new_used_flag",
            "buyer_dma_code",
            "spotlight_dealer",
            "spotlight_dealer_name",
            "makes",
            "models",
            "segments",
            "ihs_segments",
            "use_ihs_segments",
            "dealers",
            "dealers_names",
            "zones",
            "zones_names",
            "userToken",
            "last_update",
            "show_volume",
            "ring_radius",
            "show_ring",
            "top_zones_names",
            "top_zones_sys_codes",
            "api",
            "report_type",
            "date_display",
            "displayDateRanges",
            "ring_radius",
            "show_ring",
            "top_ad_zone_models",
            "zips",
            "sales_area_zips",
            "use_sales_data"
        ];
        const filtered = asArray.filter(([key, value]) =>
            propFilterArr.includes(key)
        );
        body = Object.fromEntries(filtered);
        const displayDateRanges = [];
        body.displayDateRanges.forEach((element) => {
            displayDateRanges.push(element.replace("\n", ""));
        });
        body.displayDateRanges = displayDateRanges;
        const runID = this.ngrxFilterStateService.getFilter(FilterName.run_id);
        if (runID !== null) {
            body.run_id = runID;
        }
        this.setFilterValue(FilterName.report_building, true);

        const dealerReq = this.getFilterNamesFromIds(
            (body.dealers as number[]).concat([body.spotlight_dealer as number]),
            FilterName.dealers
        );
        const zoneReq = this.getFilterNamesFromIds(
            body.zones as string[],
            FilterName.zones
        );
        const segmentsFlag = this.getUseSegmentsFlag();
        const useSalesData = this.ngrxFilterStateService.getFilter(FilterName.use_sales_data);
        if (useSalesData) {
            body.last_update = this.ngrxFilterStateService.getFilter(FilterName.last_sales_update) + "";
        }
        return forkJoin(dealerReq, zoneReq)
            .toPromise()
            .then((meta) => {
                const dealerNames = meta[0];
                const zoneNames = meta[1];

                Object.assign(body, {
                    userToken: this.cognitoService.getUserAccessTokens(),
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    dma_names: (body.dma as number[]).map((dma) =>
                        this.dmaDataService.getDmaName(dma)
                    ),
                    spotlight_dealer_name: dealerNames[body.spotlight_dealer as number],
                    dealers_names: (body.dealers as number[]).map(
                        (dealer) => dealerNames[dealer]
                    ),
                    zones_names: (body.zones as number[]).map((zone) => zoneNames[zone]),
                    use_ihs_segments: segmentsFlag,
                    use_sales_data: useSalesData
                });

                return this.postInterfaces<any>(url, body).toPromise();
            })
            .then(() => {
                this.setFilterValue(FilterName.run_id, null);
                return true;
            })
            .catch((err) => {
                this.setFilterValue(FilterName.report_building, false);
                throw err;
            });
    }

    /**
     * Builds the presentation for the Custom PPT Workflow
     *
     * @returns Promise<boolean>
     */
    buildCustomPresentation(): Promise<boolean> {
        const url = `${this.rootUrl}/export/powerpoint`;
        let body = omit<any>(
            this.ngrxFilterStateService.getCurrentFilterState(),
            this.clientOnlyFilters.slice()
        );
        const asArray = Object.entries(body);
        // FYI these are the only props necessary for a PPT
        const propFilterArr = [
            "dma",
            "dma_names",
            "dateRangeType",
            "dateRanges",
            "new_used_flag",
            "buyer_dma_code",
            "spotlight_dealer",
            "spotlight_dealer_name",
            "makes",
            "models",
            "segments",
            "ihs_segments",
            "use_ihs_segments",
            "dealers",
            "dealers_names",
            "zones",
            "zones_names",
            "userToken",
            "last_update",
            "show_volume",
            "ring_radius",
            "show_ring",
            "top_zones_names",
            "top_zones_sys_codes",
            "api",
            "report_type",
            "date_display",
            "displayDateRanges",
            "ring_radius",
            "show_ring",
            "top_ad_zone_models",
            "zips",
            "sales_area_zips",
            "use_sales_data"
        ];
        const filtered = asArray.filter(([key, value]) =>
            propFilterArr.includes(key)
        );
        body = Object.fromEntries(filtered);
        const dealerReq = this.getFilterNamesFromIds(
            (body.dealers as number[]).concat([body.spotlight_dealer as number]),
            FilterName.dealers
        );
        const zoneReq = this.getFilterNamesFromIds(
            body.zones as string[],
            FilterName.zones
        );
        const segmentsFlag = this.getUseSegmentsFlag();
        const useSalesData = this.ngrxFilterStateService.getFilter(FilterName.use_sales_data);
        const last_sales_update = this.ngrxFilterStateService.getFilter(FilterName.last_sales_update);
        const dates = this.ngrxFilterStateService.getFilter(FilterName.dates);
        if (useSalesData && last_sales_update) {
            body.last_update = last_sales_update;
        }

        return forkJoin([dealerReq, zoneReq])
            .toPromise()
            .then((meta) => {
                const dealerNames = meta[0];
                const zoneNames = meta[1];

                Object.assign(body, {
                    // "rolling" is the only supported dateRangeType for PPT, so override current filter value with the default
                    //dateRangeType: this.filterDefaults[FilterName.dateRangeType],
                    dateRangeType: body.dateRangeType,
                    date_display: dates.date_display,
                    userToken: this.cognitoService.getUserAccessTokens(),
                    dma_names: (body.dma as number[]).map((dma) =>
                        this.dmaDataService.getDmaName(dma)
                    ),
                    spotlight_dealer_name: dealerNames[body.spotlight_dealer as number],
                    dealers_names: (body.dealers as number[]).map(
                        (dealer) => dealerNames[dealer]
                    ),
                    zones_names: (body.zones as number[]).map((zone) => zoneNames[zone]),
                    use_ihs_segments: segmentsFlag,
                    use_sales_data: useSalesData
                });

                return this.postInterfaces<any>(url, body).toPromise();
            })
            .then(() => {
                this.setFilterValue(FilterName.report_building, true);
                return true;
            })
            .catch((err) => {
                this.setFilterValue(FilterName.report_building, false);
                throw err;
            });
    }

    /**
     * Checks if the conditions are met before creating a powerpoint for the various
     * presentation types
     *
     * @returns boolean     true | false
     */
    arePresentationFiltersReady(): boolean {
        switch (this.presentationType) {
            case PresentationTypes.MAKE_VS_MAKE:
            case PresentationTypes.MAKE_VS_SEGMENT:
                return (
                    this.areConditionsMet(FilterName.spotlight_dealer) &&
                    this.areConditionsMet(FilterName.dealers) &&
                    this.areConditionsMet(FilterName.makes)
                );
            case PresentationTypes.MAKE_VS_MARKET:
                return (
                    this.areConditionsMet(FilterName.spotlight_dealer) &&
                    this.areConditionsMet(FilterName.dealers)
                );
            case PresentationTypes.COMPOSITE:
                return (
                    this.areConditionsMet(FilterName.spotlight_dealer) &&
                    this.areConditionsMet(FilterName.dealers) &&
                    this.areConditionsMet(FilterName.makes) &&
                    this.areConditionsMet(FilterName.zones)
                );
            default:
                return false;
        }
    }

    handleDealerPinClick(message: any): void {
        this.dealerPinListener.next(message);
    }

    handleAccordionClick(message: any): void {
        this.accordionClickListener.next(message);
    }

    handlePptClick(message: any): void {
        this.pptClickListener.next(message);
    }

    handleZipZoneClick(message: any[]): void {
        this.zipZoneListener.next(message);
    }

    getDealersByGeography(state: string[], city: string[]): Promise<any> {
        const url = `${this.rootUrl}/dealers/details?city=${city.toString()}&state=${state.toString()}&used=0&volume=${encodeURIComponent("{\"min\":1}")}`;
        const useSalesData = this.ngrxFilterStateService.getFilter(FilterName.use_sales_data);
        const options = this.createRequestOptions(useSalesData ? {
            "use_sales_data": "true"
        } : {});

        return this.fetchInterfaces(url, options).toPromise();
    }

    getFilterByDealerId(
        dealerId: number,
        activeFilter?: FilterName
    ): Promise<any> {
        let url = `${this.rootUrl}/filter/names?new_used_flag=0&dealers=${dealerId}&filtername=makes`;
        if (activeFilter) {
            url = `${this.rootUrl}/filter/names?new_used_flag=0&dealers=${dealerId}&filtername=${activeFilter}`;
        }
        const useSalesData = this.ngrxFilterStateService.getFilter(FilterName.use_sales_data);
        const options = this.createRequestOptions(useSalesData ? {
            "use_sales_data": "true"
        } : {});

        return this.fetchInterfaces(url, options).toPromise();
    }
}
