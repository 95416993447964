<div class="split-button" [class.active]="showDropdown">
  <button class="button {{actionButtonClass}}" [disabled]="isDisabled" (click)="activateMode()">
    {{buttonText}}
    <span class="button-icon icon-{{selectedItem}}"></span>
  </button>
  <button class="button select" aria-label="Select top 5 category"
    (click)="toggleDropdown()">
    <span class="button-icon"></span>
  </button>
  <ul class="items" *ngIf="showDropdown">
    <li class="item item-{{optionOneValue}}" [class.active]="selectedItem === optionOneValue">
      <button (click)="changeMode(optionOneValue)" [disabled]="optionOneDisabled">
        {{optionOneLabel}} <span class="icon icon-{{optionOneValue}}"></span>
      </button>
    </li>
    <li class="item item-{{optionTwoValue}}" [class.active]="selectedItem === optionTwoValue">
      <button (click)="changeMode(optionTwoValue)" [disabled]="optionTwoDisabled">
        {{optionTwoLabel}} <span class="icon icon-{{optionTwoValue}}"></span>
      </button>
    </li>
  </ul>
</div>
