import { Component, OnDestroy, OnInit } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { SpotlightCompetitors } from "app/core/models/spotlight.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { Subscription } from "rxjs";

@Component({
    selector: "filter-reset-button",
    templateUrl: "./filter-reset-button.component.html",
    styleUrls: ["./filter-reset-button.component.scss"]
})
export class FilterResetButtonComponent implements OnInit, OnDestroy {
    filterStateServiceSubscription: Subscription;
    canReset = false;

    constructor(private filterStateService: FilterStateService) { }

    ngOnInit(): void {
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.filtersUpdated([]);
    }

    ngOnDestroy(): void {
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
        }
    }

    filtersUpdated(changes: string[]): void {
        let excludeFilters = [];
        if (this.filterStateService.getFilterValue(FilterName.competitors_selection) === SpotlightCompetitors.SpotlightOnly) {
            excludeFilters.push(FilterName.dealers.toString());
        }
        this.canReset = this.filterStateService.canResetFilters([...excludeFilters, FilterName.dates.toString()]);
    }

    click(): void {
        if (this.canReset) {
            if (this.filterStateService.getFilterValue(FilterName.competitors_selection) === SpotlightCompetitors.SpotlightOnly) {
                this.filterStateService.resetResettableFilters([FilterName.dealers.toString()]);
            } else {
                this.filterStateService.resetResettableFilters();
            }
        }
    }
}
