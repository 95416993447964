import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DateUtils } from "@at/utils";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { ZipZoneSales } from "app/core/models/zip-zone.model";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { MetadataService } from "../../../services/metadata.service";
import { ChartColor } from "../../charts/chart/chart-color.utils";
import { MiniMapComponent } from "../../mini-map/mini-map.component";
import { CardUtils } from "../card/card.utils";

@Component({
    selector: "sales-by-zip-card",
    templateUrl: "./sales-by-zip-card.component.html",
    styleUrls: ["./sales-by-zip-card.component.scss"]
})
export class SalesByZipCardComponent implements OnInit, OnDestroy {
    @ViewChild(MiniMapComponent) miniMapComponent: MiniMapComponent;

    showVolume = false;
    animateMap = true;

    isDisabled = true;
    isExpanded = false;
    isLoading = true;
    noResults = false;

    colors: string[] = [];
    spotlitDealer: Dealer = null;
    dateRangeType = "calendar";
    displayDates = "Loading...";
    endDate: string;
    endSalesDate: string;
    description = "";

    salesByZip: ZipZoneSales[] = [];
    topZips: string[] = [];
    renderZips: string[][] = [];
    ringRadius = 0;

    private topLimit = 10;
    protected spotlightDealerDetailsSubscription: Subscription;
    protected filterStateSubscription: Subscription;

    constructor(
        private salesDataService: SalesDataService,
        protected dealerDataService: DealerDataService,
        private metadataService: MetadataService,
        protected filterStateService: FilterStateService
    ) { }

    ngOnInit(): void {
        if (this.filterStateService.getFilterValue<boolean>(FilterName.show_ring)) {
            this.ringRadius = this.filterStateService.getFilterValue(FilterName.ring_radius);
        }

        this.metadataService.metadata.pipe(skipWhile(i => !(i && i.maxDate)), take(1)).subscribe(meta => {
            this.salesDataService.updateZipVolume();

            this.endDate = meta.maxDate;
            this.endSalesDate = meta.maxSalesDate;
            this.updateDisplayDate();

            this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
            this.filtersUpdated([FilterName.dateRangeType.toString(), FilterName.show_volume.toString()]); // this is to initialize the dateRangeType & showVolume variables

            this.spotlightDealerDetailsSubscription = this.dealerDataService.spotlightDealerDetails.subscribe((dealer: Dealer) => {
                this.spotlitDealer = dealer;
                if (!dealer) {
                    this.isExpanded = false;
                    this.isDisabled = true;
                } else {
                    this.isDisabled = false;
                    this.updateZipVolume();
                }
            });
        });
    }

    filtersUpdated(changes: string[]): void {
        // Changing dealers, zips, or zones filters does not affect this card.
        // This is to isolate the filters update only when they are change manually
        // e.g. by hand from the filter stack. In comparison to a bulk update programmatically.
        if (changes.length === 1 &&
            (changes[0] === FilterName.dealers.toString() ||
                changes[0] === FilterName.zips.toString() ||
                changes[0] === FilterName.zones.toString())) {
            return;
        }

        const conditions = {
            spotlit: {
                isValid: !!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer),
                text: "spotlight a dealer"
            },
            include_spotlit: {
                isValid: this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) === "include",
                text: "toggle 'Spotlight' to Include"
            }
        };

        this.description = CardUtils.getRequirementDescription(conditions);
        this.isDisabled = this.description.length > 0;

        if (changes.includes(FilterName.dateRangeType.toString())) {
            this.dateRangeType = this.filterStateService.getFilterValue<string>(FilterName.dateRangeType);
            if (this.endDate) {
                this.updateDisplayDate();
            }
        }

        if (changes.includes(FilterName.show_volume.toString())) {
            this.showVolume = this.filterStateService.getFilterValue<boolean>(FilterName.show_volume);
        }

        if (this.isDisabled) {
            this.isExpanded = false;
        } else {
            this.updateZipVolume();
        }
    }

    ngOnDestroy(): void {
        if (this.spotlightDealerDetailsSubscription) {
            this.spotlightDealerDetailsSubscription.unsubscribe();
        }
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
    }

    cardOpened(): void {
        this.isExpanded = true;
        this.updateZipVolume();
    }

    cardClosed(): void {
        this.isExpanded = false;
    }

    resizeMap(): void {
        if (this.miniMapComponent) {
            this.miniMapComponent.resizeMap();
        }
    }

    updateDisplayDate(): void {
        const useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.displayDates = DateUtils.displayDateRange(useSalesData ? this.endSalesDate : this.endDate, this.dateRangeType);
    }

    updateZipVolume(): void {
        if (!this.spotlitDealer || !this.isExpanded) {
            return;
        }

        this.salesDataService.getSalesForDealersByZipZone([this.spotlitDealer.dealer_id], "zips", {
            zips: [],
            zones: [],
            dateRangeType: this.dateRangeType
        }).pipe(take(1)).subscribe((sales: ZipZoneSales[]) => {
            this.salesByZip = sales;

            // Spotlit dealer has no sales for any zips within the current year  or rolling 12.
            if (!sales[0]) {
                this.noResults = true;
                this.topZips = [];
                this.renderZips = [];
                this.isLoading = false;
                return;
            }

            this.noResults = false;
            this.topZips = [];
            this.renderZips = [];
            let count = 0;
            const uniq = {};
            const isMultiDma = this.filterStateService.isMultiDma();

            // Top 10 zip codes for the dealer.
            for (let i = 0; i < sales[0].sales.length && i < this.topLimit; i++) {
                let s = sales[0].sales[i];
                if (s.sales > 0 && !uniq[s.location]) {
                    this.renderZips.push([s.location]);
                    if (isMultiDma) {
                        sales[0].sales[i] = Object.assign({}, sales[0].sales[i], { location: s.locationWithDma });
                        s = sales[0].sales[i];
                    }
                    uniq[s.location] = true;
                    this.topZips.push(s.location);
                    count++;
                }
            }

            this.colors = ChartColor.getColorPalette(count);
            this.isLoading = false;
            this.resizeMap();
        });
    }
}
