import {AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from "@angular/core";
import { DateUtils } from "@at/utils";
import { ChartLabelElement, DataSet, DataSetGroup } from "app/core/models/chart-data.model";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { DealerSales, Sales } from "app/core/models/dealer-sales";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { ChartPopupService } from "app/core/services/chart-popup.service";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { merge, sortBy } from "lodash";
import * as moment from "moment";
import { combineLatest, Observable, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MetadataService } from "../../../services/metadata.service";
import { ChartLegendComponent } from "../../charts/chart-legend/chart-legend.component";
import { ChartColor } from "../../charts/chart/chart-color.utils";
import { ChartComponent } from "../../charts/chart/chart.component";
import { CardUtils } from "../card/card.utils";


@Component({
    selector: "year-over-year-card",
    templateUrl: "./year-over-year-card.component.html",
    styleUrls: ["./year-over-year-card.component.scss"]
})
export class YearOverYearCardComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChildren(ChartComponent) charts: QueryList<ChartComponent>;
    @ViewChild(ChartLegendComponent) legendComponent: ChartLegendComponent;
    @Input() weeklyDataSelectorEnabled = false;

    Math = Math; // For using Math class in template.
    isDisabled = true;
    isExpanded = false;
    dealerDetailsAreLoading = false;
    dealersAreLoading = false;
    marketIsLoading = false;
    showVolumeOnChart = true; // as per business req, this card will show sales volume
    showVolumeOnModal = false; // Sales on modal will be suppress by default.
    description = "Filter to five or fewer dealers to view year over year sales comparisons.";

    dealers: Dealer[] = [];
    focusedDealer: Dealer;
    labels: string[][] = [];
    spotlightDealerId: number;
    stream: Subscription;

    dealersData: DataSet[];
    dealersChartColor: string[] = ChartColor.getColorPalette(2);
    dealersLegendData: DataSetGroup[] = [];
    dealersPercentChange: number;
    private salesChart: ChartComponent;

    marketData: DataSet[];
    marketChartColor: string[] = ChartColor.getColorPalette(2);
    marketPercentChange: number;
    private marketChart: ChartComponent;

    // Custom controls to control both graphs.
    selectedLegends: DataSet[] = [];

    private loadingDealerDetailsSubscription: Subscription;
    private filterSubscription: Subscription;
    private metadataServiceSubscription: Subscription;
    minDate: any;
    maxDate: any;
    groupSize: number;
    dateRangeType: any;
    defaultMaxDate: string;
    defaultMaxSalesDate: string;
    defaultMinDate: string;
    defaultMinSalesDate: string;
    oldestDate: any;
    useSalesData: boolean;
    dateTitle = "";

    get isLoading(): boolean {
        return this.dealersAreLoading || this.marketIsLoading || this.dealerDetailsAreLoading;
    }

    get disableDescription(): boolean {
        const max = (this.spotlightDealerId && this.filterStateService.getFilterValue(FilterName.include_spotlight) === "include") ? 6 : 5;
        return this.dealers.length > 0 && this.dealers.length <= max;
    }

    constructor(
        private salesDataService: SalesDataService,
        protected dealerDataService: DealerDataService,
        protected filterStateService: FilterStateService,
        private dmaDataService: DmaDataService,
        private metadataService: MetadataService,
        private chartPopupService: ChartPopupService
    ) { }

    ngOnInit(): void {
        this.metadataServiceSubscription = this.metadataService.metadata.subscribe(metaData => {
            this.loadingDealerDetailsSubscription = this.dealerDataService.loadingDealerDetails.subscribe(this.loadingDealers.bind(this));
            this.filterSubscription = this.filterStateService.filtersUpdated.pipe(debounceTime(30)).subscribe(this.update.bind(this));
            this.spotlightDealerId = this.filterStateService.getFilterValue(FilterName.spotlight_dealer);
            this.showVolumeOnModal = this.filterStateService.getFilterValue(FilterName.show_volume);
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.defaultMaxDate = metaData.maxDate;
            this.defaultMaxSalesDate = metaData.maxSalesDate;
            this.defaultMinDate = metaData.minDate;
            this.defaultMinSalesDate = metaData.minSalesDate;
            this.minDate = this.useSalesData ? this.defaultMinSalesDate : this.defaultMinDate;
            this.maxDate = this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate;
            this.oldestDate = moment(metaData.maxSalesDate).subtract(2, "years").format("YYYYMMDD");
            this.setupDateRanges(this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate);
            this.dateTitle = this.setupDateTitle();
            this.updateLabels();

        });
    }

    ngAfterViewInit(): void {
        const charts = this.charts.toArray();
        this.salesChart = charts[0];
        this.marketChart = charts[1];

        // Override the legends group click handler as this card has functionality differences
        // compare to normal legend functionality.
        // Disable linting rule to allow function to retain the chart context.
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions,
        this.salesChart.onLegendGroupClick = function(dsg: DataSetGroup) {
            this.selectedLegendsGroups[0] = dsg;
            this.legendGroupClick.emit(dsg);
        };

        // Disable linting rule to allow function to retain the chart context.
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        this.legendComponent.legendIsSelected = function(item: DataSet): boolean {
            return !!this.selectedLegends.find(ds => ds.label === item.label);
        };
    }

    ngOnDestroy(): void {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
        if (this.metadataServiceSubscription) {
            this.metadataServiceSubscription.unsubscribe();
        }
        if (this.loadingDealerDetailsSubscription) {
            this.loadingDealerDetailsSubscription.unsubscribe();
        }
    }

    cardOpened(): void {
        this.isExpanded = true;
        this.update();
    }

    cardClosed(): void {
        this.chartPopupService.hideModal();
        this.isExpanded = false;
    }

    updateLabels(): void {
        this.labels = [];
        for (let i = this.groupSize; i >= 0; i--) {
            const m = moment(DateUtils.monthYearAddMonths(this.maxDate, -i), "YYYYMM");
            this.labels.push([m.format("MMM"), ""]);
        }

        if (this.groupSize === 0) {
            const m = moment(DateUtils.monthYearAddMonths(this.maxDate, 1), "YYYYMM");
            this.labels.push([m.format("MMM"), ""]);
        }
    }

    loadingDealers(loading: boolean): void {
        this.dealerDetailsAreLoading = loading;
        if (!loading) {
            this.dealersUpdated();
        }
    }

    dealersUpdated(): void {
        let dealers = this.dealerDataService.dealerDetails.value.slice();
        const foundSpotlightDealer = dealers.find((dealer) => dealer.dealer_id === this.spotlightDealerId);
        const max = foundSpotlightDealer ? 6 : 5;
        const min = foundSpotlightDealer ? 1 : 0;
        if (dealers.length < min || dealers.length > max) {
            this.dealers = [];
            this.isExpanded = false;
            this.isDisabled = true;
        } else {
            // If multiple dma are selected, add dma suffix.
            if (this.filterStateService.isMultiDma()) {
                dealers = CardUtils.appendDmaSuffix(dealers, this.dmaDataService);
            }
            // Sort dealer array to ensure dealers are always shown in alphabetical order.
            dealers = sortBy(dealers, "dealer_name");

            if (foundSpotlightDealer) {
                const index = dealers.findIndex(d => d.dealer_id === this.spotlightDealerId);
                this.dealers = dealers.splice(index, 1).concat(dealers);
            } else {
                this.dealers = dealers;
            }
            this.isDisabled = false;
        }

        if (this.dealers.length) {
            // Set default focused dealer if one is not set or focused dealer was unselected.
            if (!this.focusedDealer || !this.dealers.find(d => d.dealer_id === this.focusedDealer.dealer_id)) {
                this.focusedDealer = this.dealers[0];
            }
            this.updateLegendGroups();
        }
    }

    update(filters?: string[]): void {
        if (filters && filters.length) {
            // If the dealers selection was changed, ignore this event from FilterStateService.
            // The DealerDataService will send a separate more accurate event.
            if (filters.length === 1 && filters[0] === "dealers") {
                return;
            }

            if (filters.includes(FilterName.spotlight_dealer.toString()) || filters.includes(FilterName.include_spotlight.toString())) {
                this.spotlightDealerId = this.filterStateService.getFilterValue(FilterName.spotlight_dealer);
            }

            if (filters.includes(FilterName.dateRangeType.toString())) {
                this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
                const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
                if (this.dateRangeType === DateRangeTypes.MONTHS && !dateRanges) {
                    return;
                }
            }

            if (filters.includes(FilterName.show_volume.toString())) {
                this.showVolumeOnModal = this.filterStateService.getFilterValue(FilterName.show_volume);
            }

            if (filters.includes(FilterName.use_sales_data.toString())) {
                // Update filters according to sales data
                // If the max date is prior to the oldest date, we need to submit "N/A" for the data
                this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
                this.minDate = this.useSalesData ? this.defaultMinSalesDate : this.defaultMinDate;
                this.maxDate = this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate;
            }
            this.updateDateTitle();
        }

        // Don't update if this card is not expanded / open.
        if (!this.isExpanded) {
            return;
        }
        // Filters were reset while card is opened.
        if (this.isDisabled && this.isExpanded) {
            this.isExpanded = false;
            return;
        }
        this.setupDateRanges(this.maxDate);
        this.updateDealersChart();
        this.updateMarketChart();
        this.updateLabels();
    }

    updateDateTitle(){
        this.dateTitle = this.setupDateTitle();
    }

    private emitWhenLoad(ds: Sales, verify: number): Observable<any> {
        return new Observable(observer => {
            ds.loaded.subscribe(() => {
                // Year over year requires two years of data to render the chart.
                // This verify that all data have been fetched before rendering to
                // prevent glitchy multiple re-render upon data fetch completes.

                if (Object.keys(ds.salesMonthly).length !== verify) {
                    return;
                }

                observer.next(ds);
                observer.complete();
            });
        });
    }

    updateDealersChart(): void {
        this.dealersAreLoading = true;
        this.dealersData = [];
        const request = [];
        let verifyDates;

        for (let i = 0; i < 2; i++) {
            let minDate = this.minDate;
            const params = {
                dateRangeType: DateRangeTypes.MONTHS,
            };
            if (this.dateRangeType === DateRangeTypes.MONTHS) {
                if (this.groupSize === 0) {
                    minDate = moment(minDate, "YYYYMM").subtract(1, "month").format("YYYYMM");
                    verifyDates = this.groupSize + 2;
                } else {
                    verifyDates = this.groupSize + 1;
                }
                params["dateRangeStart"] = DateUtils.monthYearAddMonths(minDate, (12 * (2 - i)));
                params["dateRangeEnd"] = DateUtils.monthYearAddMonths(this.maxDate, (12 * -i));
            } else if (this.dateRangeType === DateRangeTypes.YTD || this.dateRangeType === DateRangeTypes.YEARS) {
                params["dateRangeStart"] = moment(this.maxDate, "YYYY01").add(12 * -i, "month").format("YYYYMM");
                params["dateRangeEnd"] = moment(this.maxDate, "YYYYMM").add(12 * -i, "month").format("YYYYMM");
                verifyDates = this.groupSize + 1;
            } else {
                params["dateRangeEnd"] = DateUtils.monthYearAddMonths(this.maxDate, (12 * -i));
                verifyDates = DateUtils.previousYearInMonths(params["dateRangeEnd"]).length + 1;
            }
            request.push(this.emitWhenLoad(this.salesDataService.getSalesForDealer(this.focusedDealer, params), verifyDates));
        }

        // Clean out old data stream.
        if (this.stream) {
            this.stream.unsubscribe();
            this.stream = undefined;
        }

        this.stream = combineLatest(request).subscribe((sales: DealerSales[]) => {
            if (sales.length) {
                const combinedMonthly = sales.reduce((m, s) => Object.assign(m, s.salesMonthly), {});

                this.dealersPercentChange = this.calculateCurrentPriorPercentChange(combinedMonthly, this.maxDate);
                this.dealersData = this.separateByYears(combinedMonthly);
                this.updateLegendGroups();
                this.dealersAreLoading = false;
            }
        });
    }

    onDealersGroupClick(dsg: DataSetGroup): void {
        this.displaySalesForDealer(dsg.id);
    }

    updateMarketChart(): void {
        this.marketIsLoading = true;
        this.marketData = [];
        const request = [];

        for (let i = 0; i < 2; i++) {
            let verifyDates;
            let minDate = this.minDate;
            const params = {
                dateRangeType: DateRangeTypes.MONTHS,
            };
            if (this.dateRangeType === DateRangeTypes.MONTHS) {
                if (this.groupSize === 0) {
                    minDate = moment(minDate, "YYYYMM").subtract(1, "month").format("YYYYMM");
                    verifyDates = this.groupSize + 2;
                } else {
                    verifyDates = this.groupSize + 1;
                }
                params["dateRangeStart"] = DateUtils.monthYearAddMonths(minDate, (12 * (2 - i)));
                params["dateRangeEnd"] = DateUtils.monthYearAddMonths(this.maxDate, (12 * -i));
            } else if (this.dateRangeType === DateRangeTypes.YTD || this.dateRangeType === DateRangeTypes.YEARS) {
                params["dateRangeStart"] = moment(this.maxDate, "YYYY01").add(12 * -i, "month").format("YYYYMM");
                params["dateRangeEnd"] = moment(this.maxDate, "YYYYMM").add(12 * -i, "month").format("YYYYMM");
                verifyDates = this.groupSize + 1;
            } else {
                params["dateRangeEnd"] = DateUtils.monthYearAddMonths(this.maxDate, (12 * -i));
                verifyDates = DateUtils.previousYearInMonths(params["dateRangeEnd"]).length + 1;
            }

            if (this.filterStateService.isMultiDma()) {
                request.push(this.emitWhenLoad(this.salesDataService.getTotalSalesForDmas([this.focusedDealer.dealer_dma_code], params)[0], verifyDates));
            } else {
                request.push(this.emitWhenLoad(this.salesDataService.getTotalSales(params), verifyDates));
            }
        }

        combineLatest(request).subscribe((sales: DealerSales[]) => {
            const combinedMonthly = sales.reduce((m, s) => Object.assign(m, s.salesMonthly), {});

            this.marketPercentChange = this.calculateCurrentPriorPercentChange(combinedMonthly, this.maxDate);
            this.marketData = this.separateByYears(combinedMonthly);
            this.marketIsLoading = false;
        });
    }

    updateLegendGroups(): void {
        this.dealersLegendData = this.dealers.map(d => ({
            label: d.dealer_name,
            id: d.dealer_id,
            dataset: [],
            hideSubCategory: true,
            spotlight: d.dealer_id === this.spotlightDealerId
        }));
        // Set first group as default group if no group was selected or selected group was removed in the filter.
        if (!this.salesChart.selectedLegendsGroups.length || !this.dealersLegendData.find(g => g.id === this.salesChart.selectedLegendsGroups[0].id)) {
            this.salesChart.selectedLegendsGroups[0] = this.dealersLegendData.find(g => g.id === this.focusedDealer.dealer_id);
            this.displaySalesForDealer(this.focusedDealer.dealer_id, true);
        }
    }

    onDealersChartLabelClick(segment: ChartLabelElement): void {
        // [MMM, YYYY] => "YYYYMMM"
        const latestDate = this.latestDataForSelectedDate(segment.label[1] + segment.label[0]);
        const fetchObj = CardUtils.buildChartModalData(this.salesDataService, this.dealers, latestDate);

        if (!this.showVolumeOnModal) {
            const dmaRequest = CardUtils.buildChartModalData(this.salesDataService, this.dealers, latestDate, "dmas");
            fetchObj.requests = fetchObj.requests.concat(dmaRequest.requests);
        }

        // Create a datastream for which will populate and update data for the modal.
        const stream = new Subscription();

        const processData = (dealerSales: DealerSales[]) => {
            // Chart popup data format. Columns property represent column headers and the values for
            // each header is within values data map within the dealers array e.g. dealers[0].values[column[0]].
            const data = {
                position: segment.position,
                title: `${moment(segment.label[0], "MMM").format("MMMM")} Sales`,
                columns: fetchObj.yearLabels.map(l => l.slice(0, 4)),
                display: this.showVolumeOnModal ? "sales" : "shares",
                dealers: (() => this.dealers.map((d, i) => {
                    const sales = dealerSales.filter(ds => ds.dealer_id === d.dealer_id) || ({} as any);
                    const flatSales = sales.length > 0 ? sales.reduce((acc, build) => merge(acc, build), {}) : {};
                    const monthly = flatSales.salesMonthly;
                    if (this.showVolumeOnModal) {
                        return {
                            name: d.dealer_name,
                            id: d.dealer_id,
                            values: fetchObj.yearLabels.reduce((m, l) => {
                                const y = l.slice(0, 4);
                                m[y] = monthly ? monthly[l] : undefined;
                                return m;
                            }, {}),
                            spotlight: d.dealer_id === this.spotlightDealerId
                        };
                    } else {
                        const dmaSales = dealerSales.filter((ds: any) => ds.dma_id === d.dealer_dma_code) || ({} as any);
                        const flatDmaSales = dmaSales.length > 0 ? dmaSales.reduce((acc, build) => merge(acc, build), {}) : {};
                        const dmaMonthly = flatDmaSales.salesMonthly;
                        return {
                            name: d.dealer_name,
                            id: d.dealer_id,
                            values: fetchObj.yearLabels.reduce((m, l) => {
                                const y = l.slice(0, 4);
                                m[y] = monthly ? (monthly[l] / dmaMonthly[l]) * 100 || 0 : undefined;
                                return m;
                            }, {}),
                            spotlight: d.dealer_id === this.spotlightDealerId
                        };
                    }
                }))(),
                datastream: stream
            };

            this.chartPopupService.showModal(data);
        };

        stream.add(new Observable(o => {
            o.next([]);
            combineLatest(fetchObj.requests).subscribe(data => o.next(data));
        }).subscribe(processData));
    }

    onMarketChartLabelClick(segment: ChartLabelElement): void {
        // [MMM, YYYY] => "YYYYMMM"
        const latestDate = this.latestDataForSelectedDate(segment.label[1] + segment.label[0]);
        const fetchObj = CardUtils.buildChartModalData(this.salesDataService, this.dealers, latestDate, "dmas");

        // Create a datastream for which will populate and update data for the modal.
        const stream = new Subscription();

        const processData = (dealerSales: DealerSales[]) => {
            // Chart popup data format. Columns property represent column headers and the values for
            // each header is within values data map within the dealers array e.g. dealers[0].values[column[0]].
            const data = {
                position: segment.position,
                title: `${moment(segment.label[0], "MMM").format("MMMM")} Sales`,
                columns: fetchObj.yearLabels.map(l => l.slice(0, 4)),
                display: "sales",
                dealers: (() => {
                    // All sales here are identical.
                    const sales = dealerSales || ({} as any);
                    const flatSales = sales.length > 0 ? sales.reduce((acc, build) => merge(acc, build), {}) : {};
                    const yearData = {
                        name: "Market Sales",
                        id: 0,
                        values: fetchObj.yearLabels.reduce((m, l) => {
                            const y = l.slice(0, 4);
                            m[y] = flatSales.salesMonthly ? flatSales.salesMonthly[l] : undefined;
                            return m;
                        }, {})
                    };
                    return [yearData];
                })(),
                datastream: stream
            };

            this.chartPopupService.showModal(data);
        };

        stream.add(new Observable(o => {
            o.next([]);
            combineLatest(fetchObj.requests).subscribe(data => o.next(data));
        }).subscribe(processData));
    }

    legendClick(legend: DataSet): void {
        // Add or remove clicked legend.
        const found = this.selectedLegends.find(s => legend.id === s.id && legend.label === s.label);
        if (!!found) {
            // Keying off label to remove both sales and market legend item.
            this.selectedLegends = this.selectedLegends.filter((sl: DataSet) => sl.label !== found.label);
        } else {
            this.selectedLegends.push(legend);
            this.selectedLegends.push(this.marketData.find(ds => ds.label === legend.label));
        }

        this.salesChart.setLegends(this.selectedLegends, true);
        this.salesChart.updateLegendSelection();
        this.salesChart.forceRedraw();
        this.marketChart.setLegends(this.selectedLegends, true);
        this.marketChart.updateLegendSelection();
        this.marketChart.forceRedraw();
    }

    separateByYears(sales: { [month: string]: number }): DataSet[] {
        const currentDate = moment(this.maxDate, "YYYYMM");
        const data: { [key: string]: number[] } = {};
        const current = data[currentDate.format("YYYY")] = [];       // Current year.
        const previous = data[moment(currentDate).subtract(1, "years").format("YYYY")] = [];   // Previous year.
        let currentTotal = 0;
        let previousTotal = 0;
        const iteratorCount = this.groupSize || 1;
        for (let i = iteratorCount; i >= 0; i--) {
            const yearMonth = moment(currentDate).subtract(i, "months").format("YYYYMM");
            const prevYearMonth = moment(currentDate).subtract(1, "years").subtract(i, "months").format("YYYYMM");
            current.push(sales[yearMonth]);
            currentTotal += sales[yearMonth] || 0;
            previous.push(sales[prevYearMonth]);
            previousTotal += sales[prevYearMonth] || 0;
        }

        if (!this.showVolumeOnChart) {
            for (let i = 0; i < current.length; i++) {
                current[i] = (current[i] / currentTotal) * 100;
                previous[i] = (previous[i] / previousTotal) * 100;
            }
        }

        const graphData = [];
        // The keys are year number and will be in ascending order.
        const years = Object.keys(data).sort().reverse();
        for (let i = 0; i < years.length; i++) {
            const year = years[i];
            graphData.push({
                data: data[year],
                id: `${this.focusedDealer.dealer_id}-${year}`,
                label: i === 0 ? "Current Year" : "Prior Year",
                fill: false
            });
        }

        return graphData;
    }

    displaySalesForDealer(id: number, force: boolean = false): void {
        const newFocus = this.dealers.find(d => d.dealer_id === id);

        // Do not change anything if the focus dealer was reselected.
        if (!force && newFocus && newFocus.dealer_id === this.focusedDealer.dealer_id) {
            return;
        }

        this.focusedDealer = newFocus;
        this.updateDealersChart();

        // Should reflect the corresponding DMA market sales for the selected dealer.
        if (this.filterStateService.isMultiDma()) {
            this.updateMarketChart();
        }
    }

    salesPattern(change: number): { [key: string]: boolean } {
        if (change > 0) {
            return { decrease: false, increase: true };
        } else if (change < 0) {
            return { decrease: true, increase: false };
        } else {
            return { decrease: false, increase: false };
        }
    }

    private total(salesMonths: { [key: string]: any }, endDate: string): number {
        let months;
        if (this.dateRangeType === DateRangeTypes.MONTHS || this.dateRangeType === DateRangeTypes.YTD || this.dateRangeType === DateRangeTypes.YEARS) {
            months = DateUtils.monthYearInMonths(endDate, this.groupSize || 1);
        } else {
            months = DateUtils.previousYearInMonths(endDate);
        }
        return months.reduce((sum, m) => sum += (typeof salesMonths[m] === "number" ? salesMonths[m] : 0), 0);
    }

    private calculateCurrentPriorPercentChange(salesMonths: { [key: string]: number }, endDate: string): number {
        const currentTotal = this.total(salesMonths, endDate);
        const priorTotal = this.total(salesMonths, DateUtils.monthYearAddMonths(endDate, -12));
        // Handle the condition where a dealer does not have sales previous year and
        // has at least one sale the current year (X/0 === Infinity)
        return priorTotal === 0 ? 100 : ((currentTotal - priorTotal) / priorTotal) * 100;
    }

    /**
     * Accepts a selected date and return a date for the last available data for that selected month.
     *
     * @param selected YYYYMMM e.g. 2018Jan
     * Returns a string representing the date for last available data for the selected month.
     */
    private latestDataForSelectedDate(selected: string): string {
        const selectedDate = moment(selected, "YYYYMMM");
        return selectedDate.isAfter(moment(this.maxDate, "YYYYMM")) ?
            selectedDate.subtract(1, "year").format("YYYYMM") :
            selectedDate.format("YYYYMM");
    }

    getKeysForYears(input: {}): string[] {
        const res = Object.keys(input).sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10)).map(item => item.substring(0, 7));
        return res;
    }

    formatDate(date: string | string[]): string {

        let formattedName;
        const realDate = moment(date, "YYYYMM");
        const diffYear = moment().diff(realDate, "years");
        let min = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
        let max = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);


        switch (this.dateRangeType) {
            case DateRangeTypes.YTD: {
                formattedName = DateUtils.popUpDateRange(this.filterStateService.getFilterValue(FilterName.use_sales_data),
                    <string>date, this.dateRangeType,  moment(min).toDate(), moment(max).toDate());
                break;
            }
            case DateRangeTypes.MONTHS: {
                formattedName = DateUtils.popUpDatesRange((<string[]>date).reverse(), this.dateRangeType);
                break;
            }
            case DateRangeTypes.YEARS: {
                formattedName = DateUtils.popUpDateRange(this.filterStateService.getFilterValue(FilterName.use_sales_data),
                    <string>date, this.dateRangeType,  moment(min).toDate(), moment(max).toDate());
                break;
            }
            default: {
                if (diffYear === 0) {
                    formattedName = "Current Year";
                } else {
                    formattedName = `${diffYear} year${diffYear > 1 ? "s" : ""} ago`;
                }
            }
        }

        return formattedName;
    }

    setupDateRanges(maxDate: string): void {
        const dateRange: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        if (this.dateRangeType === DateRangeTypes.MONTHS && dateRange && dateRange.length > 3) {
            this.minDate = dateRange[4];
            this.maxDate = dateRange[1];
            // calculate grouping
            const dateEnd = moment(this.maxDate, "YYYYMM");
            const dateStart = moment(dateRange[0], "YYYYMM");

            this.groupSize = dateEnd.diff(dateStart, "months");
        } else if (this.dateRangeType === DateRangeTypes.YTD || this.dateRangeType === DateRangeTypes.YEARS) {
            this.maxDate = this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate;
            const dateEnd = moment(this.maxDate, "YYYYMM");
            const dateStart = moment(this.maxDate, "YYYY01");
            this.groupSize = dateEnd.diff(dateStart, "months");
        } else {
            this.groupSize = 11;
            this.maxDate = this.useSalesData ? this.defaultMaxSalesDate : this.defaultMaxDate;
        }
    }

    setupDateTitle() {
        let tmpMaxDate: string = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);
        let tmpMinDate: string = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
        if(this.weeklyDataSelectorEnabled){
            if (this.useSalesData) {
                let titleDate = "";
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.ROLLING12){
                    let momentMax = moment(moment(tmpMaxDate).toDate().setDate(1));
                    titleDate = `${moment(momentMax.toDate().setMonth(momentMax.toDate().getMonth() - 11)).format("MMM DD, YYYY")} - ${moment(tmpMaxDate).format("MMM DD, YYYY")},
                    ${moment(tmpMaxDate).toDate().getFullYear() - 1}, ${moment(tmpMaxDate).toDate().getFullYear() - 2}
                    `;
                }

                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.YTD){
                    let fullWeekDateRange = DateUtils.popUpDateRange(this.useSalesData, tmpMaxDate,  DateRangeTypes.YTD,
                        moment(tmpMinDate).toDate(), moment(tmpMaxDate).toDate());
                    titleDate = moment(fullWeekDateRange.split("-")[0]).format("MMM DD, YYYY") + " - " +
                        moment(fullWeekDateRange.split("-")[1]).format("MMM DD, YYYY") + ", "+
                        (moment(fullWeekDateRange.split("-")[1]).toDate().getFullYear() - 1) +", "+
                        (moment(fullWeekDateRange.split("-")[1]).toDate().getFullYear() - 2);
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === "calendar"){
                    let firstOfYear = moment(tmpMaxDate).toDate().getFullYear()+"0101";
                    titleDate = moment(firstOfYear).format("YYYY") + ", " + (moment(firstOfYear).toDate().getFullYear() - 1) + ", "
                        +(moment(firstOfYear).toDate().getFullYear() -2);

                } if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.MONTHS){
                    let dateRanges: any[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
                    if(dateRanges.length>0){
                        let stringDate = dateRanges[0] + " - " + dateRanges[1];
                        let fullWeekDateRange = DateUtils.popUpDateRange(this.useSalesData, stringDate, this.filterStateService.getFilterValue(FilterName.dateRangeType),
                            moment(tmpMinDate).toDate(),  moment(tmpMaxDate).toDate());
                        titleDate = ": " + moment(fullWeekDateRange.split("-")[0]).format("MMM DD, YYYY") + " - " + moment(fullWeekDateRange.split("-")[1]).format("MMM DD, YYYY");
                    }
                }
                return ": "+titleDate;
            } else {
                let suffix = "";
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.ROLLING12){
                    const start_date = DateUtils.monthYearAddMonths(tmpMaxDate, -11);
                    const end_date = DateUtils.prettyDate(tmpMaxDate.slice());
                    suffix = `${this.useSalesData ? moment(start_date).format("MMM DD, YYYY") :
                        DateUtils.prettyDate(start_date)} - ${this.useSalesData ? moment(end_date).format("MMM DD, YYYY") :
                        (moment(end_date).format("MMM YYYY") + ",")} ${this.useSalesData ? "" :
                        DateUtils.getPreviousTwoYears(tmpMaxDate, this.useSalesData ? moment(this.oldestDate).format("YYYY") : undefined, this.useSalesData)}`;
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.YTD){
                    let startDate = moment(new Date( moment(tmpMaxDate).toDate().getFullYear(),0,1));
                    suffix = moment(startDate).format("MMM  YYYY") + " - " +
                        moment(tmpMaxDate).format("MMM YYYY") + ", "
                        + (moment(tmpMaxDate).toDate().getFullYear() - 1) +", "+ (moment(tmpMaxDate).toDate().getFullYear() - 2);
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === "calendar"){
                    let startDate = moment(new Date( moment(tmpMaxDate).toDate().getFullYear(),0,1));
                    suffix =
                        moment(startDate).format("YYYY") + ", " +
                        (moment(tmpMaxDate).toDate().getFullYear() - 1) +", "+ (moment(tmpMaxDate).toDate().getFullYear() - 2);
                }
                if(this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.MONTHS){
                    const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
                    if(dateRanges.length>0){
                        const orig = DateUtils.dateDisplay([dateRanges[1], dateRanges[0]], DateRangeTypes.MONTHS).split("-");
                        const pretty = `${orig[0].slice(0, 3)} - ${orig[1]}`;
                        suffix = ` ${pretty}, ${DateUtils.getPreviousTwoYears(dateRanges[1])}`;
                    }
                }
                return ": "+suffix;
            }
        }
    }
}
