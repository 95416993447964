import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SpotlightService } from "app/core/services/spotlight.service";
import { Subscription } from "rxjs";

@Component({
    selector: "spotlight-toggle",
    templateUrl: "./spotlight-toggle.component.html",
    styleUrls: ["./spotlight-toggle.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpotlightToggleComponent implements OnChanges {
    spotlightDataDetailsSubscription: Subscription;
    @Input() dealer: Dealer;
    spotlighted = false;

    constructor(private spotlightService: SpotlightService, private changeDetectorRef: ChangeDetectorRef, private filterStateService: FilterStateService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if ("dealer" in changes) {
            this.updateSpotlightedDealer();
        }
    }

    updateSpotlightedDealer(): void {
        const spotlightDealer = this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer);
        if (spotlightDealer && this.dealer) {
            this.spotlighted = (spotlightDealer === this.dealer.dealer_id);
        } else {
            this.spotlighted = false;
        }
        this.changeDetectorRef.detectChanges();
    }

    toggleSpotlight(): void {
        if (this.spotlighted) {
            this.spotlightService.clearSpotlight();
        } else {
            this.spotlightService.setSpotlight(this.dealer.dealer_id);
        }
    }
}
