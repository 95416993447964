import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class DetailsService {
  private loadingStatus: Subject<boolean>;
  constructor() {
      this.loadingStatus = new Subject<boolean>();
  }

  setLoadingStatus(loadingStatus: boolean) {
      this.loadingStatus.next(loadingStatus);
  }

  getLoadingStatus(): Observable<boolean> {
      return this.loadingStatus;
  }
}
