import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PresentationFilterService } from "../../services/presentation-filter.service";

import { MetadataService } from "../../services/metadata.service";
import { UserCookieService } from "../../services/user-cookie.service";
import { AbstractSalesDataBase } from "../services/sales-data.abstract";

@Injectable()
export class AbstractPPTDataService extends AbstractSalesDataBase {

    constructor(
        protected filterBaseService: PresentationFilterService,
        protected http: HttpClient,
        protected metaDataService: MetadataService,
        protected userCookieService: UserCookieService
    ) {
        super(filterBaseService, http, metaDataService, userCookieService);
    }
}
