import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/browser";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { environment } from "environment";
import { array } from "fp-ts";
import jwtDecode from "jwt-decode";
import { BehaviorSubject } from "rxjs";

import { HeapReport } from "../models/heap-report.model";
import { PasswordPolicy } from "../models/password-policy.model";

@Injectable()
export class CognitoService {
    private cognitoUser: any;
    private userPool: any;
    private authenticationDetails: any;
    private redirectUrl: string;

    private passwordPolicy: PasswordPolicy[] = [
        { title: "At least eight characters", id: "eight-characters", pattern: ".{8,}" },
        { title: "At least one uppercase letter", id: "one-uppercase-letter", pattern: "\w*[A-Z]" },
        { title: "At least one lowercase letter", id: "one-lowercase-letter", pattern: "(\w*[a-z])" },
        { title: "At least one number", id: "one-number", pattern: "[0-9]" },
        { title: "No spaces allowed", id: "no-spaces-allowed", pattern: "^[^\\s]*$" }
    ];

    private devBypass = environment.loginBypass;
    private poolData = {
        UserPoolId: environment.aws.userPoolId, // CognitoUserPool
        ClientId: environment.aws.clientId, // CognitoUserPoolClient
        Paranoia: 7
    };

    private identityPoolId: string = environment.aws.identityPoolId; // CognitoIdentityPool
    private region: string = environment.aws.region; // Region Matching CognitoUserPool region
    private token: string;

    private sessionTokenId = "id_token";

    public readonly authenticated = new BehaviorSubject<boolean>(false);

    public heapReportParams = {
        dma: null,
        geography: null,
        spotlight_dealer: null,
        dates: null,
        makes: null,
        models: null,
        segments: null,
        dealers: null,
        zones: null,
        spotlightDealerSelected: false,
        dataSource: null,
        dataSourceDateType: null,
        dataSourceDateRange: null,
    };

    constructor(private router: Router) {
        this.userPool = new CognitoUserPool(this.poolData);
        this.cognitoUser = this.userPool.getCurrentUser();
        this.token = localStorage.getItem(this.sessionTokenId);
        if (this.token) {
            this.appcuesIdentify();
            this.sendHeapIdentify();
            this.setSentryUser();
        } else {
            if (!this.devBypass) {
                this.signOut();
            }
        }
    }

    authenticateUserPool(): Promise<string> {
        return new Promise((resolve, reject) =>
            this.cognitoUser.authenticateUser(this.authenticationDetails, {
                onSuccess: result => {
                    this.saveToken(result);
                    this.sendHeapIdentify();
                    this.setSentryUser();
                    this.appcuesIdentify();
                    this.authenticated.next(true);
                    resolve(this.redirectUrl);
                },
                onFailure: (err) => {
                    this.authenticated.next(false);
                    reject(err.message);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    reject("new password needed");
                }
            })
        );
    }

    protected appcuesIdentify() {
        console.log("user email: ", this.getUserEmail());
        if (this.getUserEmail() !== "e2eautotestuser@charter.com" && this.getUserEmail() !== "id_token") {
            console.log("identifying: ", this.getUserEmail());
            (window as any).Appcues.identify(this.getUserEmail(), {});
        }

    }

    protected sendHeapIdentify(): void {
        try {
            if (environment.heapAnalytics) {
                window["heap"].identify(this.getUserEmail());
            }
        } catch (e) {
            console.log("catching");
            // if this fails, we don't want to prevent the user from logging in.
        }
    }

    protected setSentryUser(): void {
        Sentry.configureScope(scope => {
            scope.setUser({ email: this.getUserEmail() });
        });
    }

    protected setUserCreds(user: string, password: string): void {
        // All cognito access will be done via a all lower case user name.
        const username = user.toLowerCase();
        const authenticationData = {
            Username: username,
            Password: password,
        };
        this.authenticationDetails = new AuthenticationDetails(authenticationData);
        const userData = {
            Username: username,
            Pool: this.userPool
        };
        this.cognitoUser = new CognitoUser(userData);
    }

    refreshToken(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.cognitoUser) {
                this.signOut();
                reject();
            } else {
                this.cognitoUser.getSession((err, session) => { // this will get a new token if the current token is expired
                    if (err || !session.isValid()) {
                        this.signOut();
                        reject();
                    } else {
                        this.saveToken(session);
                        this.authenticated.next(true);
                        resolve();
                    }
                });
            }
        });
    }

    protected saveToken(session: any): void {
        const newToken = session.getIdToken().getJwtToken();
        localStorage.setItem(this.sessionTokenId, newToken);
        const logins = {};
        logins[`cognito-idp.${this.region}.amazonaws.com/${environment.aws.userPoolId}`] = newToken;
        // If something needs access to an aws service use this to provide the user access to that service using a V3 sdk
        // config.credentials = new CognitoIdentityCredentials({
        //     IdentityPoolId: this.identityPoolId,
        //     Logins: logins
        // });
        this.token = newToken;
    }

    setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }

    signIn(user: string, password: string): Promise<string> {
        this.setUserCreds(user, password);
        return this.authenticateUserPool();
    }

    updatePassword(nPassword: string): Promise<string> {
        return new Promise((resolve, reject) =>
            this.cognitoUser.completeNewPasswordChallenge(nPassword, null, {
                onSuccess: result => {
                    this.saveToken(result);
                    this.authenticated.next(true);
                    resolve(this.redirectUrl);
                },
                onFailure: (err) => {
                    this.authenticated.next(false);
                    reject(err.message);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    reject("new password needed");
                }
            })
        );
    }

    // this does not invalidate the id token, https://github.com/aws/aws-sdk-js/issues/1687
    signOut(): void {
        if (this.cognitoUser) {
            this.cognitoUser.signOut();
            this.cognitoUser = null;
        }
        Sentry.configureScope(scope => {
            scope.setUser(null);
        });
        this.token = undefined;
        this.authenticated.next(false);
        localStorage.clear();
        this.router.navigateByUrl("/signin");
    }

    decodeToken(token: any): any {
        return jwtDecode(token);
    }

    getToken(): string {
        if (this.devBypass) {
            return "bypasstoken";
        }
        return this.token;
    }

    isSignedIn(): boolean {
        if (this.devBypass) {
            return true;
        }
        try {
            const decodedToken = this.decodeToken(this.token);
            if (!decodedToken.hasOwnProperty("exp")) {
                this.authenticated.next(false);
                return false;
            }
            const date = new Date(0);
            date.setUTCSeconds(decodedToken.exp);
            const sessionValid = date.valueOf() > new Date().valueOf();
            this.authenticated.next(sessionValid);
            return sessionValid;
        } catch (e) {
            if (e.message === "Invalid token specified") {
                this.authenticated.next(false);
                return false;
            } else {
                throw e;
            }
        }
    }

    getUserEmail(): string {
        const encodedToken = this.getToken();
        if (encodedToken === "bypasstoken") {
            return encodedToken;
        }
        const decodedToken = this.decodeToken(encodedToken);
        if (!decodedToken.hasOwnProperty("email")) {
            return null;
        }
        return decodedToken["email"];
    }

    requestPasswordResetCode(username: string): Promise<{}> {
        return new Promise((resolve, reject) => {
            if (!this.cognitoUser || this.cognitoUser.username !== username.toLowerCase()) {
                this.setUserCreds(username.toLowerCase(), undefined);
            }
            this.cognitoUser.forgotPassword({
                onSuccess: (data) => resolve(data),
                onFailure: (err) => reject(err)
            });
        });
    }

    confirmPasswordReset(code: number, password: string): Promise<{}> {
        return new Promise((resolve, reject) => {
            this.cognitoUser.confirmPassword(code, password, {
                onSuccess: () => resolve({}),
                onFailure: (err) => reject(err)
            });
        });
    }

    changePassword(oldPassword: string, newPassword: string): Promise<{}> {
        return new Promise((resolve, reject) => {
            this.cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
                if (err) {
                    reject(err);
                    return;
                }
                resolve(result);
            });
        });
    }

    getUserGroups(): string[] {
        const encodedToken = this.getToken();
        if (encodedToken === "bypasstoken") {
            return ["Admins"];
        }
        const decodedToken = this.decodeToken(encodedToken);
        return (decodedToken["cognito:groups"] || []);
    }

    getPasswordPolicies(): PasswordPolicy[] {
        return this.passwordPolicy;
    }

    checkPasswordPolicy(password: string, policy: object): object {
        if (!password) {
            return { "policy-passed": false, "policy-failed": false };
        }
        const regEx = RegExp(policy["pattern"]);
        return regEx.test(password) ? { "policy-passed": true, "policy-failed": false } : { "policy-passed": false, "policy-failed": true };
    }

    checkPasswordMatch(password: string, confirmPassword: string): object {
        if (!password && !confirmPassword) {
            return { "policy-passed": false, "policy-failed": false };
        }
        const match = password === confirmPassword;
        return { "policy-passed": match, "policy-failed": !match };
    }

    verifyPasswordPolicies(password: string, confirmPassword: string): boolean {
        let passingCheck = false;
        if (!!password && !!password.length && !!confirmPassword && !!confirmPassword.length && password === confirmPassword) {
            passingCheck = this.passwordPolicy.every(policy => RegExp(policy.pattern).test(password));
        }
        return passingCheck;
    }

    getUserAccessTokens(): { [key: string]: string } {
        return this.cognitoUser.getSession((err, session) => { // this will get a new token if the current token is expired
            if (err) {
                // console.log("Failed to retrieve user auth from session");
                return;
            }
            return {
                username: this.getUserEmail(),
                idToken: session.idToken.getJwtToken(),
                accessToken: session.accessToken.getJwtToken(),
                refreshToken: session.refreshToken.getToken()
            };
        });
    }

    testHook(): any {
        // Only used for unit tests
        if (!environment.production) {
            return {
                __setUserCreds: (username: string, password: string) => this.setUserCreds(username, password)
            };
        }
    }

    heapTrack(eventName, eventProps): void {
        if (window["heap"] && window["heap"]["track"]) {
            window["heap"].track(eventName, eventProps);
        }
    }

    setHeapReportParams(key: string, value: any): void {
        this.heapReportParams[key] = value;
        if (this.heapReportParams.spotlight_dealer) {
            this.heapReportParams.spotlightDealerSelected = true;
        } else {
            this.heapReportParams.spotlightDealerSelected = false;
        }
    }

    generateHeapReport(): HeapReport {
        const stringParams: HeapReport = new HeapReport();

        if (this.heapReportParams.geography) {
            stringParams.geography = typeof this.heapReportParams.geography[0] === "number" ? "dma" : "city/state";
        }
        if (this.heapReportParams.spotlight_dealer) {
            stringParams.primaryDealer = this.heapReportParams.spotlight_dealer[0]["name"];
        }
        if (this.heapReportParams.spotlightDealerSelected) {
            stringParams.primaryDealerSelected = this.heapReportParams.spotlight_dealer.length > 0 ? true : false;
        }
        if (this.heapReportParams.dma) {
            // array
            if (typeof this.heapReportParams.dma[0] === "number") {
                stringParams.dma = this.arrayToString(this.heapReportParams.dma);
            } else {
                let arrayOfStrings = this.arrayOfObjectsToArrayOfNameStrings(this.heapReportParams.dma);
                stringParams.dma = this.arrayToString(arrayOfStrings);
            }
        }
        if (this.heapReportParams.makes) {
            // array
            let arrayOfStrings = this.arrayOfObjectsToArrayOfNameStrings(this.heapReportParams.makes);
            stringParams.makes = this.arrayToString(arrayOfStrings);
        }
        if (this.heapReportParams.segments) {
            // array
            let arrayOfStrings = this.arrayOfObjectsToArrayOfNameStrings(this.heapReportParams.segments);
            stringParams.segments = this.arrayToString(arrayOfStrings);
        }
        if (this.heapReportParams.models) {
            // array
            let arrayOfStrings = this.arrayOfObjectsToArrayOfNameStrings(this.heapReportParams.models);
            stringParams.models = this.arrayToString(arrayOfStrings);
        }
        if (this.heapReportParams.dealers) {
            // array
            let arrayOfStrings = this.arrayOfObjectsToArrayOfNameStrings(this.heapReportParams.dealers);
            stringParams.competitors = this.arrayToString(arrayOfStrings);
        }
        if (this.heapReportParams.zones) {
            // array
            let arrayOfStrings = this.arrayOfObjectsToArrayOfNameStrings(this.heapReportParams.zones);
            stringParams.adZones = this.arrayToString(arrayOfStrings);
        }
        if (this.heapReportParams.dataSource) {
            stringParams.dataSource = this.heapReportParams.dataSource;
        }
        if (this.heapReportParams.dataSourceDateType) {
            if (this.heapReportParams.dataSourceDateType === "months") {
                stringParams.dataSourceDateType = "custom";
            } else {
                stringParams.dataSourceDateType = this.heapReportParams.dataSourceDateType;
            }
        }
        if (this.heapReportParams.dataSourceDateRange) {
            stringParams.dataSourceDateRange = this.heapReportParams.dataSourceDateRange;
            stringParams.dataSourceDateRange = stringParams.dataSourceDateRange.replace("\n", "");

            // We need to set this back to null
            this.heapReportParams.dataSourceDateRange = null;
        }

        return stringParams;
    }

    arrayToString(arrayValues: string[]): string {
        return arrayValues.join(", ");
    }

    arrayOfObjectsToArrayOfNameStrings(arrayValues: [any]): string[] {
        return arrayValues.map(v => v.name);
    }

    resetHeapReportParams(): void {
        this.heapReportParams = {
            dma: null,
            geography: null,
            spotlight_dealer: null,
            dates: null,
            makes: null,
            models: null,
            segments: null,
            dealers: null,
            zones: null,
            spotlightDealerSelected: false,
            dataSource: null,
            dataSourceDateType: null,
            dataSourceDateRange: null,
        };
    }
}
