import { DecimalPipe } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import { OverlayService, OverlayStatus } from "app/core/services/overlay.service";
import { UserCookieService } from "app/core/services/user-cookie.service";
import { without } from "lodash";
import { Subscription } from "rxjs";
import { takeLast } from "rxjs/operators";

import { OpenPanelService } from "./open-panel.service";

const MAX_TOOLTIP_ITEMS = 20;

@Component({
    selector: "filter-expand-button",
    encapsulation: ViewEncapsulation.None,
    templateUrl: "./filter-expand-button.component.html"
})
export class FilterExpandButtonComponent implements OnInit, OnDestroy {
    @Input() label;
    @Input() filterName: FilterName;
    @Input() disabledState = false;

    filterValues: string[];
    overlayServiceSubscription: Subscription;
    filterStateServiceSubscription: Subscription;
    dealerDetailsSubscription: Subscription;
    invalidFilters = false;
    FilterName = FilterName;
    decimalPipe = new DecimalPipe("en");

    selected = false;
    selectionsCount = "";

    constructor(
        protected overlayService: OverlayService,
        protected filterStateService: FilterStateService,
        protected dealerDataService: DealerDataService,
        private openPanelService: OpenPanelService,
        private userCookieService: UserCookieService,
        private ngrxFilterStateService: NgrxFilterStateService
    ) { }

    ngOnInit(): void {
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.overlayServiceSubscription = this.overlayService.overlayState.subscribe(this.updateOverlayState.bind(this));
        this.dealerDetailsSubscription = this.dealerDataService.loadingDealerDetails.subscribe(() => this.filtersUpdated(["dealers"]));
        this.filtersUpdated([this.filterName.toString()]);
    }

    ngOnDestroy(): void {
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
        }
        this.overlayService.hidePanel();
        this.overlayServiceSubscription.unsubscribe();
        this.dealerDetailsSubscription.unsubscribe();
    }

    openPanel(): void {
        this.openPanelService.openPanel(this.disabledState, this.filterName, this.selected);
    }

    updateOverlayState(overlayStatus: OverlayStatus): void {
        if (!overlayStatus.visible) {
            this.selected = false;
        } else {
            this.selected = (this.filterName === overlayStatus.filterName);
        }
    }

    filtersUpdated(which: string[]): void {
        this.invalidFilters = false;
        const selectedDealers = (this.filterStateService.getFilterValue<string[]>(this.filterName) || []);
        let tempSelectionsCount = (this.filterStateService.getFilterValue<string[]>(this.filterName) || []).length;
        if (this.filterName === FilterName.dealers) {
            const spotlightedDealer = this.ngrxFilterStateService.getFilter(FilterName.spotlight_dealer);
            if (selectedDealers.find(dealer => dealer["dealer_id"] === spotlightedDealer)) {
                tempSelectionsCount = tempSelectionsCount - 1;
            }
        }
        this.updateSelectionText(tempSelectionsCount);

        if (tempSelectionsCount === 0) {
            this.filterValues = [];
            return;
        }

        this.validateFilterSelections();
    }

    private validateFilterSelections(): void {
        if (this.filterName === FilterName.sales_range) {
            this.invalidFilters = false;
            this.filterValues = [this.buildSalesRangeSelectionText()];
            // We don't need to validate the sales range at all
            return;
        }


        this.filterValues = ["Loading..."];
        const selected = (this.filterStateService.getFilterValue<string[]>(this.filterName) || []).slice();
        let dealers = [];
        if (this.filterName !== FilterName.dealers) {
            dealers = this.userCookieService.getSalesRangeFilteredDealers();
        }
        this.filterStateService.getFilterOptions(this.filterName, selected, [])
            .pipe(takeLast(1))
            .subscribe((returnedNames: string[]) => {
                this.filterOptionsSubscription(returnedNames, selected);
            });
    }

    private filterOptionsSubscription(returnedNames: string[], selected: any): void {
        if (returnedNames.length === 0) {
            this.filterValues = ["Invalid Filters Selected"];
            this.invalidFilters = true;
            return;
        }

        this.buildTooltip(returnedNames, selected);
    }

    private buildTooltip(returnedNames: string[], selected: any): void {
        if (returnedNames[0]["id"]) {
            returnedNames = returnedNames.filter((unfiltered) => selected.includes(unfiltered["id"]))
                .map((filtered) => filtered["name"]);
        } else {
            const keys = Object.keys(returnedNames[0]);
            const filterName = without(keys, "volume")[0];

            returnedNames = returnedNames.map((unfiltered) => unfiltered[filterName]).filter((unfiltered) => selected.includes(unfiltered));
        }

        this.filterValues = returnedNames.slice(0, MAX_TOOLTIP_ITEMS);
        if (returnedNames.length > MAX_TOOLTIP_ITEMS) {
            this.filterValues.push("+" + (returnedNames.length - MAX_TOOLTIP_ITEMS) + " More");
        }

        if (returnedNames.length !== selected.length) {
            const total = selected.length - returnedNames.length;
            this.filterValues.unshift("");
            if (total === 1) {
                this.filterValues.unshift("1 Invalid Filter Selected");
            } else {
                this.filterValues.unshift(`${total} Invalid Filters Selected`);
            }
            this.invalidFilters = true;
        }
    }

    private updateSelectionText(tempSelectionsCount: number): void {
        if (this.filterName === FilterName.sales_range) {
            this.selectionsCount = this.buildSalesRangeSelectionText();
        } else {
            this.selectionsCount = this.buildSelectionSelectionText(tempSelectionsCount);
        }

    }

    private buildSalesRangeSelectionText(): string {
        const model = this.filterStateService.getSalesRangeValue();

        if (!model.min && !model.max) {
            return "";
        }

        return `${this.decimalPipe.transform(model.min) || 0} to ${this.decimalPipe.transform(model.max) || "Market Max"}`;
    }

    private buildSelectionSelectionText(tempSelectionsCount: number): string {
        if (tempSelectionsCount === 1000) {
            return "1K";
        } else if (tempSelectionsCount > 1000) {
            return "1K+";
        } else {
            return tempSelectionsCount.toString();
        }
    }
}
