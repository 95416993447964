import { Chart, helpers } from "chart.js";
import { isNumber } from "util";
import { ChartUtils } from "./chart.utils";

const getLineValue = (scale, index, offsetGridLines) => {
    let lineValue = scale.getPixelForTick(index);
    if (offsetGridLines) {
        if (index === 0) {
            lineValue -= (scale.getPixelForTick(1) - lineValue) / 2;
        } else {
            lineValue -= (lineValue - scale.getPixelForTick(index - 1)) / 2;
        }
    }
    return lineValue;
};

const drawArea = (chartEl: any, chart: Chart, pluginOptions: SharesPluginOption): void => {
    const chartArea = chart.chartArea;
    const options = chartEl.options;
    const chartLayoutPadding = chart["controller"].config.options.layout.padding;
    const context = chartEl.ctx;
    const globalDefaults = Chart.defaults.global;
    const gridLines = options.gridLines;

    const tl = gridLines.drawTicks ? gridLines.tickMarkLength : 0;
    const ticks = chartEl.getTicks();
    const optionTicks = (typeof options.ticks.minor !== "undefined") ? options.ticks.minor : { padding: { top: 0, bottom: 0, left: 0, right: 0 } };
    const tickWidth = chartEl.getPixelForTick(1) - chartEl.getPixelForTick(0) || 0;
    const startY = chartArea.bottom;
    const endY = chartArea.bottom + pluginOptions.container.height;

    const drawQueue = [];

    for (let i = 0; i < ticks.length; i++) {
        const tick = ticks[i];
        const label = tick.label;

        let lineWidth; let lineColor; let borderDash; let borderDashOffset;
        if (i === chartEl.zeroLineIndex && options.offset === gridLines.offsetGridLines) {
            lineWidth = gridLines.zeroLineWidth;
            lineColor = gridLines.zeroLineColor;
            borderDash = gridLines.zeroLineBorderDash;
            borderDashOffset = gridLines.zeroLineBorderDashOffset;
        } else {
            lineWidth = helpers.valueAtIndexOrDefault(gridLines.lineWidth, i);
            lineColor = helpers.valueAtIndexOrDefault(gridLines.color, i);
            borderDash = helpers.valueOrDefault(gridLines.borderDash, [10,10]);
            borderDashOffset = helpers.valueOrDefault(gridLines.borderDashOffset, 0.5);
        }

        const textAlign = "bottom";
        const textBaseline = "left";
        const tickPadding = optionTicks.padding;
        const labelYOffset = tl + tickPadding.top;
        const labelY = chartEl.bottom + labelYOffset * 3;

        let xLineValue = getLineValue(chartEl, i, gridLines.offsetGridLines && ticks.length > 1);
        xLineValue += ChartUtils.aliasPixel(lineWidth);

        const labelX = chartEl.getPixelForTick(i) - (tickWidth / 2) + optionTicks.labelOffset; // x values for optionTicks (need to consider offsetLabel option)
        drawQueue.push({
            tx1: xLineValue + chartLayoutPadding.left,
            ty1: startY,
            tx2: xLineValue + chartLayoutPadding.right,
            ty2: endY,
            x1: xLineValue + chartLayoutPadding.left,
            y1: chartArea.top + chartLayoutPadding.top,
            x2: xLineValue + chartLayoutPadding.right,
            y2: chartArea.bottom + chartLayoutPadding.bottom,
            labelX,
            labelY,
            glWidth: lineWidth - chartLayoutPadding.left - chartLayoutPadding.right,
            glColor: lineColor,
            glBorderDash: borderDash,
            glBorderDashOffset: borderDashOffset,
            rotation: 0,
            label,
            major: tick.major,
            textBaseline,
            textAlign
        });
    }

    for (let i = 0; i < drawQueue.length; i++) {
        const item = drawQueue[i];
        if (gridLines.display) {
            context.save();
            context.lineWidth = item.glWidth;
            context.strokeStyle = item.glColor;

            if (context.setLineDash) {
                context.setLineDash(item.glBorderDash);
                context.lineDashOffset = item.glBorderDashOffset;
            }

            context.beginPath();

            // Draw tick extention for percentage area divider.
            if (gridLines.drawTicks) {
                context.moveTo(item.tx1, item.ty1);
                context.lineTo(item.tx2, item.ty2);
            }

            // Draw filled in area underneath y axis.
            if (i < drawQueue.length) {
                const nextTick = drawQueue[i + 1] || { tx1: chartArea.right };
                const width = nextTick.tx1 - item.tx1;
                const height = item.ty2 - item.ty1;
                context.fillStyle = "#" + pluginOptions.container.bgColor;
                context.fillRect(item.tx1, item.ty1 + 1, width, height);
            }

            context.stroke();
            context.restore();
        }
    }
};

const drawPercentage = (chart: Chart, pluginOptions: SharesPluginOption): void => {
    const context = chart.ctx;
    for (let i = 0; i < (chart.data.datasets || []).length; i++) {
        if (chart.isDatasetVisible(i)) {
            const meta = chart.getDatasetMeta(i);
            const controller = meta.controller;
            const scale = controller._getValueScale();
            const rects = controller.getMeta().data;
            const dataset = controller.getDataset();
            // const shareCount = (dataset.hasOwnProperty("shares") && dataset.shares.length >= 1) ? dataset.shares.length : 0;

            for (let j = 0; j < rects.length; ++j) {
                if (!isNaN(scale.getValueForPixel(dataset.data[j]))) {
                    // hacky as hell to push the offset.
                    if (rects[j]._model.width === 45) {
                        rects[j]._model.width = 44;
                        rects[j]._model.x = rects[j]._model.x;
                    }
                    drawPercentValue(rects[j], context, pluginOptions);
                }
            }
        }
    }
};

const drawPercentValue = (rect: any, context: CanvasRenderingContext2D, pluginOptions: SharesPluginOption): void => {
    const vm = rect._view;
    const shareValues = rect._chart.data.datasets[rect._datasetIndex].shares;
    const value = `${shareValues && Number(shareValues[rect._index]).toFixed(2) || 100}%`;
    const textWidth = context.measureText(value).width;
    const x = vm.x - textWidth / 2;
    // Since there is not a quick and easy way to calculate canvas height, we'll approximate it 2/3 of the way for now.
    const y = vm.base - 12.5;
    context.fillText(value, x, y);
};

const drawScaleLabel = (chartEl: any, chart: Chart, pluginOptions: SharesPluginOption): void => {
    if (!chartEl) {
        return;
    }
    const scaleLabel = chartEl.options.scaleLabel;
    const globalDefaults = Chart.defaults.global;
    const context = chartEl.ctx;
    const chartHeight = chart.chartArea.bottom + pluginOptions.container.height;

    if (scaleLabel.displayLabel) {
        // Draw the scale label for xaxis
        const fontSize = helpers.valueOrDefault(scaleLabel.fontSize, globalDefaults.defaultFontSize);
        const fontStyle = helpers.valueOrDefault(scaleLabel.fontStyle, globalDefaults.defaultFontStyle);
        const fontFamily = helpers.valueOrDefault(scaleLabel.fontFamily, globalDefaults.defaultFontFamily);
        const lineHeight = helpers.valueOrDefault(scaleLabel.lineHeight, helpers.options._parseFont(chartEl.options).defaultLineHeight);
        const scaleLabelFont = {
            lineHeight: helpers.options.toLineHeight(lineHeight, fontSize),
            string: (fontStyle ? fontStyle + " " : "") + fontSize + "px " + fontFamily
        };

        const scaleLabelFontColor = helpers.valueOrDefault(scaleLabel.fontColor, globalDefaults.defaultFontColor);

        let scaleLabelX;
        let scaleLabelY;
        const rotation = 0;
        const halfLineHeight = scaleLabelFont.lineHeight / 2;

        scaleLabelX = chartEl.left + ((chartEl.right - chartEl.left) / 2); // midpoint of the width
        scaleLabelY = chartHeight + halfLineHeight;

        context.save();
        context.translate(scaleLabelX, scaleLabelY);
        context.rotate(rotation);
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = scaleLabelFontColor; // render in correct colour
        context.font = scaleLabelFont.string;
        context.fillText(scaleLabel.labelString, 0, 0);
        context.restore();
    }
};

const getScaleLabelHeight = (chart: Chart): number => {
    const scaleAxisOptions = chart.options.scales.xAxes.find(b => b.id === "x-axis-scale-label");
    const globalDefaults = Chart.defaults.global;

    if (!(scaleAxisOptions && (scaleAxisOptions.scaleLabel as any).displayLabel)) { // only reduce height if a scale label is added
        return 0;
    }

    const fontSize = helpers.valueOrDefault(scaleAxisOptions.scaleLabel.fontSize, globalDefaults.defaultFontSize);
    const lineHeight = helpers.valueOrDefault(scaleAxisOptions.scaleLabel.lineHeight, 1);
    return isNumber(lineHeight) ? lineHeight : parseFloat(lineHeight);
};

// A plugin to display shares percent below each individual bars on a vertical bar chart.
export const Shares = {
    id: "SharesPercent",
    afterLayout: (chart: Chart, options: SharesPluginOption) => {
        if (!isNaN(chart.height)) {
            const containerHeight = options.container.height || 25;
            const scaleLabelHeight = getScaleLabelHeight(chart);
            // Decrease graph height to allow for percent section & scale label.
            (Chart as any).layoutService.update(chart, chart.width, chart.height - containerHeight - scaleLabelHeight - 10);
        }
    },
    afterDraw: (chart: any, easingValue: number, options: SharesPluginOption) => {
        const xAxisEl = chart.boxes.find(b => b.id === "x-axis-0");
        const xAxisScaleLabel = chart.boxes.find(b => b.id === "x-axis-scale-label");
        drawArea(xAxisEl, chart, options);
        drawScaleLabel(xAxisScaleLabel, chart, options);
        drawPercentage(chart, options);
    }
};

export interface SharesPluginOption {
    container: {
        height: number;
        bgColor: string;
    };
}
