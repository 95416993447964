import { Injectable } from "@angular/core";

import { ChartColor } from "../components/charts/chart/chart-color.utils";
import { ZipZoneAreaComparison } from "../components/top-zips-header-table/zip-zone-comparison.interface";
import { ZipZoneSale } from "../models/zip-zone.model";
import { SalesDataService } from "./sales-data.service";

@Injectable()
export class TopZipsHeaderService {

    constructor(
        protected salesDataService: SalesDataService) { }

    /**
     * Get the total sales of the top x zips for the dealer
     *
     * @param zipZoneSales
     * @param limit
     * @param isMultiDma
     */
    public calculateTopZipZoneVolume(zipZoneSales: ZipZoneSale[], limit: number, isMultiDma: boolean): number {
        if (limit === NaN || !limit) {
            limit = 9999;
        }
        const ordered = zipZoneSales.filter(sale => sale.area !== null).sort((a, b) => b.sales - a.sales);
        const topzips = ordered.slice(0, limit);
        return topzips.reduce((sum, zip) => sum + zip.sales, 0);
    }

    public toZipZoneComparison(sale: ZipZoneSale, isMultiDma: boolean): ZipZoneAreaComparison {
        return {
            zip: isMultiDma || sale.undefinedLocation ? sale.locationWithDma : sale.location,
            volume: sale.sales,
            area: sale.area
        };
    }

    public calculateTotalVolume(zipZoneSales: ZipZoneSale[]): number {
        return zipZoneSales.reduce((sum, zip) => sum + zip.sales, 0);
    }

    public calculatePrimarySalesAreaTotal(zipZoneSales: ZipZoneSale[]): number {
        return zipZoneSales.filter(z => z.area === "psa").reduce((sum, zip) => sum + zip.sales, 0);
    }

    public calculateSecondarySalesAreaTotal(zipZoneSales: ZipZoneSale[]): number {
        return zipZoneSales.filter(z => z.area === "ssa").reduce((sum, zip) => sum + zip.sales, 0);
    }

    public calculateTop25ZipZoneVolume(zipZoneSales: ZipZoneSale[]): number {
        return zipZoneSales.slice(0, 25).reduce((sum, zip) => sum + zip.sales, 0);
    }

    public applyColorsToZips(sales: ZipZoneAreaComparison[], limit: number = 25): ZipZoneAreaComparison[] {
        const scalings = this.salesDataService.getHeatmapScalings(sales.slice(0, limit), true);

        const colors = ChartColor.toChartJsColorArray(ChartColor.getHeatmapPalette());

        scalings.scaledZips.map((zips, levelIndex) => {
            zips.map((zip) => {
                sales.find(value => value.zip === zip).color = colors[levelIndex];
            });
        });

        return sales;
    }
}
