<div [class.offline-hidden]="!show" [class.offline-overlay]="show">
    <div class="offline-message-box">
        <ng-container *ngTemplateOutlet="maintenance ? maintenanceTemplate : offlineTemplate"></ng-container>
    </div>
</div>

<ng-template #offlineTemplate>
    <div class="offline-header">Connection Failed</div>
    <div class="offline-message">
        This may be due to no internet connection or a problem with the server. Please check your internet connection and then try
        refreshing your browser.
    </div>
    <div class="offline-message">
        If the issue persists, you may contact technical support at
        <a class="email-address" href="mailto:{{techEmail}}?subject=Automotive%20Tool%20Support">{{techEmail}}</a>
    </div>
    <div class="icon">
        <div class="offline" (click)="refresh()"></div>
    </div>
</ng-template>

<ng-template #maintenanceTemplate>
    <div class="offline-header">Down for Maintenance</div>
    <div class="offline-message">Administrators are currently performing scheduled maintenance.</div>
    <div class="offline-message">If you have any questions, you may contact technical support at
        <a class="email-address" href="mailto:{{techEmail}}?subject=Automotive%20Tool%20Support">{{techEmail}}</a>
    </div>
    <div class="icon">
        <div class="maintenance" (click)="refresh()">
            <div class="hazards front"></div>
            <div class="hazards back"></div>
        </div>
    </div>
</ng-template>
