export class HeapReport {
    geography?: string;
    primaryDealer?: string;
    makes?: string;
    segments?: string;
    models?: string;
    competitors?: string;
    adZones?: string;
    primaryDealerSelected?: boolean;
    dma?: string;
    dataSource?: string;
    dataSourceDateType?: string;
    dataSourceDateRange?: string;
}
