<div class="toast box-shadow-bottom" [class.extend-left]="extendLeft" [class.visible]="!hidden">
    <div class="icon"></div>
    <div class="description">{{content}}</div>

    <div class="checkbox" [class.checked]="checkboxChecked" (click)="toggleChecked()"></div>
    <label (click)="toggleChecked()">Don't show this message again this session.</label>

    <button class="button dismiss" (click)="dismiss()">
        <div class="label">Dismiss</div>
    </button>
</div>
