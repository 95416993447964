import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { isEqual } from "lodash";

import { DataSet, DataSetGroup } from "../../../models/chart-data.model";
import { ChartColor } from "../chart/chart-color.utils";

@Component({
    selector: "chart-legend",
    templateUrl: "./chart-legend.component.html",
    styleUrls: ["./chart-legend.component.scss"],
})

export class ChartLegendComponent implements OnChanges {
    @Input() data: DataSet[];
    @Input() legends: DataSetGroup[];
    @Input() selectedLegends: DataSet[] = [];
    @Input() selectedLegendsGroups: DataSetGroup[] = [];
    // Support both rgba strings and CanvasPattern objects.
    @Input() colors: any[];

    @Output() legendClick: EventEmitter<DataSet> = new EventEmitter<DataSet>();
    @Output() legendGroupClick: EventEmitter<DataSetGroup> = new EventEmitter<DataSetGroup>();

    private colorMap = {};

    constructor(private sanitization: DomSanitizer) { }

    ngOnChanges(changes: SimpleChanges): void {
        // There are two legend types:
        // 1) Legend items: An array of legend items generated using the given data e.g. [item1, item2]
        // 2) Grouped legend items: An array of grouped legend items passed from the card using the chart component.
        // e.g. [{ label: "legend 1", dataset: [item1] }, { label: "legend 2", dataset: [item1, item2] }]

        if (changes.colors && changes.colors.currentValue && !isEqual(changes.colors.currentValue, changes.colors.previousValue)) {
            this.colorMap = this.colors.reduce((m, c, i) => {
                m[i] = typeof c === "object" ? ChartColor.toRGBAString(c.hex) : ChartColor.toRGBAString(c);
                return m;
            }, {});
        }

        if (changes.legends && changes.legends.currentValue) {
            let count = 0;
            for (let g = 0; g < changes.legends.currentValue.length; g++) {
                const group = this.legends[g];
                for (let l = 0; l < group.dataset.length; l++) {
                    this.colorMap[this.getLegendKey(group.dataset[l])] = typeof this.colors[count] === "object" ?
                        ChartColor.toRGBAString(this.colors[count++].hex) :
                        ChartColor.toRGBAString(this.colors[count++]);
                }
            }
        }
    }

    getLegendEntryText(item: DataSet): string {
        return item.spotlight ? ("Spotlighted Dealer: " + item.label) : item.label;
    }

    groupIsFocused(group: DataSetGroup): boolean {
        return !!this.selectedLegendsGroups.find(sg => group.id === sg.id);
    }

    getColor(index: number): SafeStyle {
        const color = this.colorMap[index];
        return this.data[index].spotlight ?
            this.sanitization.bypassSecurityTrustStyle(`repeating-linear-gradient(-45deg, transparent, transparent 1px, ${color}, ${color} 4px)`) :
            color;
    }

    calculateColor(legend: DataSet): SafeStyle {
        const color = this.colorMap[this.getLegendKey(legend)];
        return legend.spotlight ?
            this.sanitization.bypassSecurityTrustStyle(`repeating-linear-gradient(-45deg, transparent, transparent 1px, ${color}, ${color} 4px)`) :
            color;
    }

    legendIsSelected(item: DataSet): boolean {
        return !!this.selectedLegends.find(ds => ds.id === item.id && ds.label === item.label);
    }

    legendClicked(label: DataSet): void {
        if (this.data.length === 1) {
            return;
        }
        this.legendClick.emit(label);
    }

    groupClicked(group: DataSetGroup): void {
        this.legendGroupClick.emit(group);
    }

    private getLegendKey(legend: DataSet): string {
        return `${legend.id}-${legend.label}`;
    }
}
