import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { DealerSales } from "app/core/models/dealer-sales";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import * as moment from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { PopupDirective } from "../../directives/popup.directive";

@Component({
    selector: "dealer-share-popup",
    templateUrl: "./dealer-share-popup.component.html",
    styleUrls: ["./dealer-share-popup.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class DealerSharePopupComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(PopupDirective) popup: PopupDirective;
    @Input() dealer: Dealer;

    source: any;
    dateGroups: any[];
    dates: string[] = [];
    displayDates: string[] = [];
    useCustomDates = false;
    customDateDataLoaded = false;
    minDate = "";
    maxDate = "";
    shares: any[] = [];
    dealerYearlySales: DealerSales = {
        dealer_id: null,
        hash: null,
        loaded: new BehaviorSubject(false)
    };
    protected dateGroupSales: DealerSales[] = [];

    private dateRangeType = "calendar";
    private filterStateSubscription: Subscription;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private salesDataService: SalesDataService,
        private filterStateService: FilterStateService
    ) { }

    ngOnInit(): void {
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
        this.minDate = this.filterStateService.getFilterValue(this.filterStateService.getFilterValue(FilterName.use_sales_data) ? FilterName.minSalesDate: FilterName.minDate);
        this.maxDate = this.filterStateService.getFilterValue(this.filterStateService.getFilterValue(FilterName.use_sales_data) ? FilterName.maxSalesDate: FilterName.maxDate);
        this.initData();
    }

    ngOnDestroy(): void {
        this.filterStateSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("dealer" in changes) {
            this.initData();
        }
    }

    initData(): void {
        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        if (dateRanges && this.dateRangeType === DateRangeTypes.MONTHS) {
            this.loadDealerYearlySalesCustomDates(dateRanges);
        } else {
            this.loadDealerYearlySales();
        }
    }

    filtersUpdated(changed: FilterName[]): void {

        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);

        if (changed.includes(FilterName.dateRangeType)) {
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            if (this.dateRangeType !== DateRangeTypes.MONTHS) {
                this.useCustomDates = false;
                this.customDateDataLoaded = false;
            }
        }

        if (changed.includes(FilterName.dateRanges) && this.dateRangeType === DateRangeTypes.MONTHS) {
            this.loadDealerYearlySalesCustomDates(dateRanges);
        } else {
            this.loadDealerYearlySales();
        }

        if (!this.popup || !this.popup.visible) {
            return;
        }
        if (!changed.includes(FilterName.result_data_filtered)) {
            this.popup.closePopup();
        }
    }

    loadDealerYearlySales(): void {
        this.dates = [];
        if (this.dealer) {
            this.dealerYearlySales = this.salesDataService.getSalesForDealer(this.dealer, { volume: null });
        }
        this.dealerYearlySales.loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            this.source = this.dateRangeType === "rolling" || this.dateRangeType === "ytd" || this.dateRangeType === "months" ?
                this.dealerYearlySales.sharesMonthly :
                this.dealerYearlySales.shares;
            this.dates = Object.keys(this.source).sort().reverse().slice(0, 3);
            this.changeDetectorRef.detectChanges();
        });
    }

    loadDealerYearlySalesCustomDates(dateRanges?: string[]): void {
        this.dates = [];
        this.source = {};
        this.useCustomDates = true;
        this.customDateDataLoaded = false;

        if (this.dateRangeType === DateRangeTypes.MONTHS && dateRanges && dateRanges.length > 0) {
            this.maxDate = dateRanges[1];
            this.minDate = dateRanges[4];
            this.dateGroups = DateUtils.generateCustomDateGroupings(dateRanges);
        }
        if (this.dateGroupSales.length) {
            this.dateGroupSales = [];
        }
        if (this.dealer) {
            this.dateGroups.forEach((group, index) => {
                this.dates.push(group.start); // add the start date to this.dates
                // gets sales for each custom date range group
                const response = this.salesDataService.getSalesForDealer(this.dealer, { volume: null, dateRangeStart: group.start, dateRangeEnd: group.end });
                response.loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
                    this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
                    const sharesMonthlyKeys = Object.keys(response.sharesMonthly).sort().reverse();
                    let totalRangeShares = 0;
                    if (sharesMonthlyKeys.length !== 0) {
                        sharesMonthlyKeys.forEach((v, i) => {
                            totalRangeShares = totalRangeShares + response.sharesMonthly[v];
                        });
                    }
                    let avgShares = (totalRangeShares / sharesMonthlyKeys.length); // actually averaging out the shares
                    avgShares = (Number.isNaN(avgShares)) ? 0 : avgShares; // accounting for NaN as a value response
                    this.source = { ...this.source, ...{ [group.start]: { value: avgShares, loaded: new BehaviorSubject(true), dealer_id: response.dealer_id } } }; // append the monthly shares to this.source.
                    // Ensure all three yearly date groups are set in this.source before triggering a re-render
                    if (
                        typeof this.source[this.dateGroups[0].start] !== "undefined" &&
                        typeof this.source[this.dateGroups[1].start] !== "undefined" &&
                        typeof this.source[this.dateGroups[2].start] !== "undefined"
                    ) {
                        if (
                            typeof this.source[this.dateGroups[0].start].value !== "undefined" &&
                            typeof this.source[this.dateGroups[1].start].value !== "undefined" &&
                            typeof this.source[this.dateGroups[2].start].value !== "undefined"
                        ) {
                            this.customDateDataLoaded = true;
                            this.changeDetectorRef.detectChanges();
                        }
                    }
                });
                this.dealerYearlySales = response;
                this.dateGroupSales.push(response);
            });
        }
    }

    setupDateRanges(date: string): string {
        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            const dateRanges: string[] = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            for (let i = 0; i < dateRanges.length; i++) {
                if (i !== 0 && i % 2 !== 0) {
                    continue;
                }
                if (dateRanges[i] === date) {
                    return `${dateRanges[i - 1]}-${dateRanges[i]}`;
                }
            }

        }
    }
    getType(item: any): string {
        if (item === null) {
            return "null";
        } else if(isNaN(item)) {
            return "N/A";
        } else if (Array.isArray(item)) {
            return "array";
        }
        return typeof item;
    }

    // Used in the template.
    private formatDate(date: string): string {
        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            date = this.setupDateRanges(date);
        }
        return DateUtils.popUpDateRange(this.filterStateService.getFilterValue(FilterName.use_sales_data), date,
            this.dateRangeType, moment(this.minDate).toDate(), moment(this.maxDate).toDate());
    }
}
