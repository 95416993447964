<div id="date-range-selector-{{ id }}" class="date-range-selector">
    <div class="details">
        <div class="label">{{radioGroupName}}</div>
    </div>
    <kite-radio-group class="buttons" [disabled]="loading">
<!--        [disabled] = "option.value === 'months' && useSalesData" -->
        <kite-radio-button *ngFor="let option of options"

            (click)="selectOption(option)"
            [value]="option.value"
            [(ngModel)]="selectedOption"
            ngDefaultControl
            [checked]="selectedOption === option.value">
            {{option.label}}
        </kite-radio-button>
    </kite-radio-group>
    <div class="container" *ngIf="selectedOption === DateRangeTypes.MONTHS">
        <div id="start-select" class="dd-lable start-select">Select Start Date</div>

        <div class="dropdown-container">
            <dropdown
                (change)="startDropdownChanged($event)"
                [items]="startDates"
                [selected]="selectedStartDate"
                [selectedToTop]="false"
                leadingText="">
            </dropdown>

        </div>

        <div *ngIf="selectedOption === DateRangeTypes.MONTHS && endDates && endDates.length>0"
            class="dd-lable end-select">
            Select End Date
        </div>
        <div class="dropdown-container">
            <dropdown
                *ngIf="selectedOption === DateRangeTypes.MONTHS && endDates && endDates.length>0"
                [items]="endDates"
                [selected]="selectedEndDate"
                [selectedToTop]="false"
                (change)="endDropdownChanged($event)"
                leadingText="">
            </dropdown>
        </div>
    </div>
</div>
