import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";

export interface DropDownItem {
    value: any;
    label: string;
}

@Component({
    selector: "dropdown",
    templateUrl: "./dropdown.component.html",
    encapsulation: ViewEncapsulation.None,
    // styleUrls: ["./dropdown.component.scss"]
})
export class DropDownComponent implements OnInit, OnChanges {

    @Input() leadingText = "Displaying:";
    @Input() items: DropDownItem[] = [];
    @Input() selected: DropDownItem;
    @Input() selectedToTop = true;

    @Output() change: EventEmitter<DropDownItem> = new EventEmitter<DropDownItem>();

    public select: FormControl = this.formBuilder.control({
        value: null,
        label: ""
    }, [Validators.required]);

    isOpen = false;

    constructor(private elementRef: ElementRef, protected formBuilder: FormBuilder) { }

    ngOnInit(): void {
        if (this.selectedToTop) {
            this.shiftSelectedToFront();
        }
        if (this.selected) {
            const itemValue = this.items.find(i => i.value === this.selected.value);
            if (itemValue) {
                this.select.setValue(itemValue);
            } else {
                this.select.setValue(this.selected);
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selected) {
            this.select.setValue(this.selected);
        }
    }

    selectItem(event: MouseEvent): void {
        event.stopImmediatePropagation();
        //this.updateDropDown();
        //}

        //updateDropDown() {
        this.isOpen = false;
        if (this.selected.value !== this.select.value.value) {
            this.selected = { value: this.select.value.value, label: this.select.value.label };
            if (this.selectedToTop) {
                this.shiftSelectedToFront();
            }
            this.change.next(this.select.value);
        }
    }

    @HostListener("document:click", ["$event"])
    closeOnOutsideClick(event: KeyboardEvent): void {
        if (event["_stopPropagation"]) {
            return;
        }

        if (!this.isOpen) {
            return;
        }

        // Close drop down if clicking outside of dropdown list.
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isOpen = false;
        }
    }

    private shiftSelectedToFront(): void {
        if (this.selected) {
            const index = this.items.findIndex(i => i === this.selected);
            if (index > -1 && this.selectedToTop) {
                this.items.unshift(this.items.splice(index, 1)[0]);
            }
        }
    }
}
