export const DmaNames = {
    500: {
        name: "Portland-Auburn",
        shortName: "Portland ME"
    },
    501: {
        name: "New York",
        shortName: "New York"
    },
    502: {
        name: "Binghamton",
        shortName: "Binghamton"
    },
    503: {
        name: "Macon",
        shortName: "Macon"
    },
    504: {
        name: "Philadelphia",
        shortName: "Philadelphia"
    },
    505: {
        name: "Detroit",
        shortName: "Detroit"
    },
    506: {
        name: "Boston (Manchester)",
        shortName: "Boston"
    },
    507: {
        name: "Savannah",
        shortName: "Savannah"
    },
    508: {
        name: "Pittsburgh",
        shortName: "Pittsburgh"
    },
    509: {
        name: "Ft. Wayne",
        shortName: "Ft. Wayne"
    },
    510: {
        name: "Cleveland-Akron",
        shortName: "Cleveland"
    },
    511: {
        name: "Washington DC (Hagrstwn)",
        shortName: "DC"
    },
    512: {
        name: "Baltimore",
        shortName: "Baltimore"
    },
    513: {
        name: "Flint-Saginaw-Bay City",
        shortName: "Flint"
    },
    514: {
        name: "Buffalo",
        shortName: "Buffalo"
    },
    515: {
        name: "Cincinnati",
        shortName: "Cincinnati"
    },
    516: {
        name: "Erie",
        shortName: "Erie"
    },
    517: {
        name: "Charlotte",
        shortName: "Charlotte"
    },
    518: {
        name: "Greensboro-H.Point-W.Salem",
        shortName: "Greensboro"
    },
    519: {
        name: "Charleston, SC",
        shortName: "Chrlstn SC"
    },
    520: {
        name: "Augusta-Aiken",
        shortName: "Augusta"
    },
    521: {
        name: "Providence-New Bedford",
        shortName: "Providence"
    },
    522: {
        name: "Columbus, GA (Opelika, AL)",
        shortName: "Columbus GA"
    },
    523: {
        name: "Burlington-Plattsburgh",
        shortName: "Burlington"
    },
    524: {
        name: "Atlanta",
        shortName: "Atlanta"
    },
    525: {
        name: "Albany, GA",
        shortName: "Albany GA"
    },
    526: {
        name: "Utica",
        shortName: "Utica"
    },
    527: {
        name: "Indianapolis",
        shortName: "Indianapolis"
    },
    528: {
        name: "Miami-Ft. Lauderdale",
        shortName: "Miami"
    },
    529: {
        name: "Louisville",
        shortName: "Louisville"
    },
    530: {
        name: "Tallahassee-Thomasville",
        shortName: "Tallahassee"
    },
    531: {
        name: "Tri-Cities, TN-VA",
        shortName: "Tri Cities"
    },
    532: {
        name: "Albany-Schenectady-Troy",
        shortName: "Albany NY"
    },
    533: {
        name: "Hartford & New Haven",
        shortName: "Hartford"
    },
    534: {
        name: "Orlando-Daytona Bch-Melbrn",
        shortName: "Orlando"
    },
    535: {
        name: "Columbus, OH",
        shortName: "Columbus OH"
    },
    536: {
        name: "Youngstown",
        shortName: "Youngstown"
    },
    537: {
        name: "Bangor",
        shortName: "Bangor"
    },
    538: {
        name: "Rochester, NY",
        shortName: "Rochester NY"
    },
    539: {
        name: "Tampa-St. Petersburg (Sarasota)",
        shortName: "Tampa"
    },
    540: {
        name: "Traverse City-Cadillac",
        shortName: "Traverse City"
    },
    541: {
        name: "Lexington",
        shortName: "Lexington"
    },
    542: {
        name: "Dayton",
        shortName: "Dayton"
    },
    543: {
        name: "Springfield-Holyoke",
        shortName: "Springfld ME"
    },
    544: {
        name: "Norfolk-Portsmth-Newpt Nws",
        shortName: "Norfolk"
    },
    545: {
        name: "Greenville-N.Bern-Washgtn",
        shortName: "GNBW"
    },
    546: {
        name: "Columbia, SC",
        shortName: "Columbia SC"
    },
    547: {
        name: "Toledo",
        shortName: "Toledo"
    },
    548: {
        name: "West Palm Beach-Ft. Pierce",
        shortName: "WPalm Beach"
    },
    549: {
        name: "Watertown",
        shortName: "Watertown"
    },
    550: {
        name: "Wilmington",
        shortName: "Wilmington"
    },
    551: {
        name: "Lansing",
        shortName: "Lansing"
    },
    552: {
        name: "Presque Isle",
        shortName: "Presque Isle"
    },
    553: {
        name: "Marquette",
        shortName: "Marquette"
    },
    554: {
        name: "Wheeling-Steubenville",
        shortName: "Wheeling"
    },
    555: {
        name: "Syracuse",
        shortName: "Syracuse"
    },
    556: {
        name: "Richmond-Petersburg",
        shortName: "Richmond"
    },
    557: {
        name: "Knoxville",
        shortName: "Knoxville"
    },
    558: {
        name: "Lima",
        shortName: "Lima"
    },
    559: {
        name: "Bluefield-Beckley-Oak Hill",
        shortName: "Bluefield"
    },
    560: {
        name: "Raleigh-Durham (Fayetteville)",
        shortName: "Raleigh"
    },
    561: {
        name: "Jacksonville",
        shortName: "Jacksonville"
    },
    563: {
        name: "Grand Rapids-Kalmzoo-B. Crk",
        shortName: "Gr Rapids"
    },
    564: {
        name: "Charleston-Huntington",
        shortName: "Chrlstn WV"
    },
    565: {
        name: "Elmira (Corning)",
        shortName: "Elmira"
    },
    566: {
        name: "Harrisburg-Lncstr-Leb-York",
        shortName: "Harrisburg"
    },
    567: {
        name: "Greenvll-Spart-Ashevll-And",
        shortName: "GSAA"
    },
    569: {
        name: "Harrisonburg",
        shortName: "Harrisonburg"
    },
    570: {
        name: "Myrtle Beach-Florence",
        shortName: "Myrtle Beach"
    },
    571: {
        name: "Fort Myers-Naples",
        shortName: "Ft Myers"
    },
    573: {
        name: "Roanoke-Lynchburg",
        shortName: "Roanoke"
    },
    574: {
        name: "Johnstown-Altoona-St Colge",
        shortName: "Johnstown"
    },
    575: {
        name: "Chattanooga",
        shortName: "Chattanooga"
    },
    576: {
        name: "Salisbury",
        shortName: "Salisbury"
    },
    577: {
        name: "Wilkes Barre-Scranton-Hztn",
        shortName: "Wilkes Barre"
    },
    581: {
        name: "Terre Haute",
        shortName: "Terre Haute"
    },
    582: {
        name: "Lafayette, IN",
        shortName: "Lafayette IN"
    },
    583: {
        name: "Alpena",
        shortName: "Alpena"
    },
    584: {
        name: "Charlottesville",
        shortName: "Chrlttsvlle"
    },
    588: {
        name: "South Bend-Elkhart",
        shortName: "South Bend"
    },
    592: {
        name: "Gainesville",
        shortName: "Gainesville"
    },
    596: {
        name: "Zanesville",
        shortName: "Zanesville"
    },
    597: {
        name: "Parkersburg",
        shortName: "Parkersburg"
    },
    598: {
        name: "Clarksburg-Weston",
        shortName: "Clarksburg"
    },
    600: {
        name: "Corpus Christi",
        shortName: "CCTX"
    },
    602: {
        name: "Chicago",
        shortName: "Chicago"
    },
    603: {
        name: "Joplin-Pittsburg",
        shortName: "Joplin"
    },
    604: {
        name: "Columbia-Jefferson City",
        shortName: "Col-Jeff Cty"
    },
    605: {
        name: "Topeka",
        shortName: "Topeka"
    },
    606: {
        name: "Dothan",
        shortName: "Dothan"
    },
    609: {
        name: "St. Louis",
        shortName: "St Louis"
    },
    610: {
        name: "Rockford",
        shortName: "Rockford"
    },
    611: {
        name: "Rochester-Mason City-Austin",
        shortName: "Rochester MN"
    },
    612: {
        name: "Shreveport",
        shortName: "Shreveport"
    },
    613: {
        name: "Minneapolis-St. Paul",
        shortName: "Minneapolis"
    },
    616: {
        name: "Kansas City",
        shortName: "KC"
    },
    617: {
        name: "Milwaukee",
        shortName: "Milwaukee"
    },
    618: {
        name: "Houston",
        shortName: "Houston"
    },
    619: {
        name: "Springfield, MO",
        shortName: "Springfld MO"
    },
    622: {
        name: "New Orleans",
        shortName: "New Orleans"
    },
    623: {
        name: "Dallas-Ft. Worth",
        shortName: "Dallas"
    },
    624: {
        name: "Sioux City",
        shortName: "Sioux City"
    },
    625: {
        name: "Waco-Temple-Bryan",
        shortName: "Waco"
    },
    626: {
        name: "Victoria",
        shortName: "Victoria"
    },
    627: {
        name: "Wichita Falls & Lawton",
        shortName: "Wichita Fls"
    },
    628: {
        name: "Monroe-El Dorado",
        shortName: "Monroe"
    },
    630: {
        name: "Birmingham (Ann and Tusc)",
        shortName: "Birmingham"
    },
    631: {
        name: "Ottumwa-Kirksville",
        shortName: "Ottumwa"
    },
    632: {
        name: "Paducah-Cape Girard-Harsbg",
        shortName: "Paducah"
    },
    633: {
        name: "Odessa-Midland",
        shortName: "Odessa"
    },
    634: {
        name: "Amarillo",
        shortName: "Amarillo"
    },
    635: {
        name: "Austin",
        shortName: "Austin"
    },
    636: {
        name: "Harlingen-Wslco-Brnsvl-McA",
        shortName: "Harlingen"
    },
    637: {
        name: "Cedar Rapids-Wtrlo-IWC&Dub",
        shortName: "Cdr Rapids"
    },
    638: {
        name: "St. Joseph",
        shortName: "St Joseph"
    },
    639: {
        name: "Jackson, TN",
        shortName: "Jackson TN"
    },
    640: {
        name: "Memphis",
        shortName: "Memphis"
    },
    641: {
        name: "San Antonio",
        shortName: "San Antonio"
    },
    642: {
        name: "Lafayette, LA",
        shortName: "Lafayette LA"
    },
    643: {
        name: "Lake Charles",
        shortName: "Lake Charles"
    },
    644: {
        name: "Alexandria, LA",
        shortName: "Alexandria"
    },
    647: {
        name: "Greenwood-Greenville",
        shortName: "Greenwood"
    },
    648: {
        name: "Champaign&Sprngfld-Decatur",
        shortName: "Champaign"
    },
    649: {
        name: "Evansville",
        shortName: "Evansville"
    },
    650: {
        name: "Oklahoma City",
        shortName: "OK City"
    },
    651: {
        name: "Lubbock",
        shortName: "Lubbock"
    },
    652: {
        name: "Omaha",
        shortName: "Omaha"
    },
    656: {
        name: "Panama City",
        shortName: "Panama City"
    },
    657: {
        name: "Sherman-Ada",
        shortName: "Sherman"
    },
    658: {
        name: "Green Bay-Appleton",
        shortName: "Green Bay"
    },
    659: {
        name: "Nashville",
        shortName: "Nashville"
    },
    661: {
        name: "San Angelo",
        shortName: "San Angelo"
    },
    662: {
        name: "Abilene-Sweetwater",
        shortName: "Abilene"
    },
    669: {
        name: "Madison",
        shortName: "Madison"
    },
    670: {
        name: "Ft. Smith-Fay-Sprngdl-Rgrs",
        shortName: "Ft Smith"
    },
    671: {
        name: "Tulsa",
        shortName: "Tulsa"
    },
    673: {
        name: "Columbus-Tupelo-W Pnt-Hstn",
        shortName: "Col-Tup-WP"
    },
    675: {
        name: "Peoria-Bloomington",
        shortName: "Peoria"
    },
    676: {
        name: "Duluth-Superior",
        shortName: "Duluth"
    },
    678: {
        name: "Wichita-Hutchinson Plus",
        shortName: "Wichita"
    },
    679: {
        name: "Des Moines-Ames",
        shortName: "Des Moines"
    },
    682: {
        name: "Davenport-R. Island-Moline",
        shortName: "Davenport"
    },
    686: {
        name: "Mobile-Pensacola (Ft. Walt)",
        shortName: "Mobile"
    },
    687: {
        name: "Minot-Bsmrck-Dcknsn(Wlstn)",
        shortName: "Minot"
    },
    691: {
        name: "Huntsville-Decatur (Flor)",
        shortName: "Huntsville"
    },
    692: {
        name: "Beaumont-Port Arthur",
        shortName: "Beaumont"
    },
    693: {
        name: "Little Rock-Pine Bluff",
        shortName: "Little Rock"
    },
    698: {
        name: "Montgomery-Selma",
        shortName: "Montgomery"
    },
    702: {
        name: "La Crosse-Eau Claire",
        shortName: "La Crosse"
    },
    705: {
        name: "Wausau-Rhinelander",
        shortName: "Wausau"
    },
    709: {
        name: "Tyler-Longview(Lfkn&Ncgd)",
        shortName: "Tyler"
    },
    710: {
        name: "Hattiesburg-Laurel",
        shortName: "Hattiesburg"
    },
    711: {
        name: "Meridian",
        shortName: "Meridian"
    },
    716: {
        name: "Baton Rouge",
        shortName: "Baton Rouge"
    },
    717: {
        name: "Quincy-Hannibal-Keokuk",
        shortName: "Quincy"
    },
    718: {
        name: "Jackson, MS",
        shortName: "Jackson MS"
    },
    722: {
        name: "Lincoln & Hastings-Krny",
        shortName: "Lincoln"
    },
    724: {
        name: "Fargo",
        shortName: "Fargo"
    },
    725: {
        name: "Sioux Falls(Mitchell)",
        shortName: "Sioux Fls"
    },
    734: {
        name: "Jonesboro",
        shortName: "Jonesboro"
    },
    736: {
        name: "Bowling Green",
        shortName: "Bwling Green"
    },
    737: {
        name: "Mankato",
        shortName: "Mankato"
    },
    740: {
        name: "North Platte",
        shortName: "North Platte"
    },
    743: {
        name: "Anchorage",
        shortName: "Anchorage"
    },
    744: {
        name: "Honolulu",
        shortName: "Honolulu"
    },
    745: {
        name: "Fairbanks",
        shortName: "Fairbanks"
    },
    746: {
        name: "Biloxi-Gulfport",
        shortName: "Biloxi"
    },
    747: {
        name: "Juneau",
        shortName: "Juneau"
    },
    749: {
        name: "Laredo",
        shortName: "Laredo"
    },
    751: {
        name: "Denver",
        shortName: "Denver"
    },
    752: {
        name: "Colorado Springs-Pueblo",
        shortName: "Colo Springs"
    },
    753: {
        name: "Phoenix",
        shortName: "Phoenix"
    },
    754: {
        name: "Butte-Bozeman",
        shortName: "Butte"
    },
    755: {
        name: "Great Falls",
        shortName: "Great Falls"
    },
    756: {
        name: "Billings",
        shortName: "Billings"
    },
    757: {
        name: "Boise",
        shortName: "Boise"
    },
    758: {
        name: "Idaho Fals-Pocatllo(Jcksn)",
        shortName: "Idaho Fls"
    },
    759: {
        name: "Cheyenne-Scottsbluff",
        shortName: "Cheyenne"
    },
    760: {
        name: "Twin Falls",
        shortName: "Twin Falls"
    },
    762: {
        name: "Missoula",
        shortName: "Missoula"
    },
    764: {
        name: "Rapid City",
        shortName: "Rapid City"
    },
    765: {
        name: "El Paso (Las Cruces)",
        shortName: "El Paso"
    },
    766: {
        name: "Helena",
        shortName: "Helena"
    },
    767: {
        name: "Casper-Riverton",
        shortName: "Casper"
    },
    770: {
        name: "Salt Lake City",
        shortName: "Salt Lake"
    },
    771: {
        name: "Yuma-El Centro",
        shortName: "Yuma"
    },
    773: {
        name: "Grand Junction-Montrose",
        shortName: "Gr Junction"
    },
    789: {
        name: "Tucson (Sierra Vista)",
        shortName: "Tucson"
    },
    790: {
        name: "Albuquerque-Santa Fe",
        shortName: "Albuquerque"
    },
    798: {
        name: "Glendive",
        shortName: "Glendive"
    },
    800: {
        name: "Bakersfield",
        shortName: "Bakersfield"
    },
    801: {
        name: "Eugene",
        shortName: "Eugene"
    },
    802: {
        name: "Eureka",
        shortName: "Eureka"
    },
    803: {
        name: "Los Angeles",
        shortName: "LA"
    },
    804: {
        name: "Palm Springs",
        shortName: "Palm Springs"
    },
    807: {
        name: "San Francisco-Oak-San Jose",
        shortName: "SF"
    },
    810: {
        name: "Yakima-Pasco-Rchlnd-Knnwck",
        shortName: "Yakima"
    },
    811: {
        name: "Reno",
        shortName: "Reno"
    },
    813: {
        name: "Medford-Klamath Falls",
        shortName: "Medford"
    },
    819: {
        name: "Seattle-Tacoma",
        shortName: "Seattle"
    },
    820: {
        name: "Portland, OR",
        shortName: "Portland OR"
    },
    821: {
        name: "Bend, OR",
        shortName: "Bend"
    },
    825: {
        name: "San Diego",
        shortName: "San Diego"
    },
    828: {
        name: "Monterey - Salinas",
        shortName: "Monterey"
    },
    839: {
        name: "Las Vegas",
        shortName: "Las Vegas"
    },
    855: {
        name: "SantaBarbara-SanMar-SanLuOb",
        shortName: "Sta Barbara"
    },
    862: {
        name: "Sacramento-Stkton-Modesto",
        shortName: "Sacramento"
    },
    866: {
        name: "Fresno-Visalia",
        shortName: "Fresno"
    },
    868: {
        name: "Chico-Redding",
        shortName: "Chico"
    },
    881: {
        name: "Spokane",
        shortName: "Spokane"
    },
    900: {
        name: "Puerto Rico",
        shortName: "Puerto Rico"
    }
};
