<zip-upload-modal
    *ngIf="showZipUploadModal"
    [hasErrors]="hasErrors"
    (uploadFailure)="uploadFailureHandler($event)"
    (zipsUploaded)="zipsUploadedHandler($event)"
    (zipUploadCancelled)="zipUploadCancelledHandler($event)">
</zip-upload-modal>
<div class="filter-spotlight-menu">
    <zip-upload-status *ngIf="zipUploadComplete" [hasErrors]="hasErrors" [uploadFailure]="uploadFailure">
    </zip-upload-status>

    <!-- competitors_selection -->
    <div class="icon-radio-buttons competitors-options"
        *ngIf="competitorOptionsShown.length >0">
        <div *ngIf="competitorOptionsShown.includes('ALL')" class="icon-radio-button competitors-option all-option"
            [class.selected]="competitors === SpotlightCompetitors.All"
            (click)="selectCompetitors(SpotlightCompetitors.All)">
            <div class="icon"></div>
            <div>All Dealers</div>
        </div>
        <div *ngIf="competitorOptionsShown.includes('PUMP_IN')" [placement]="'bottom'" [dTooltip]="pumpin"
            class="icon-radio-button competitors-option pump-in-option"
            [class.selected]="competitors === SpotlightCompetitors.PumpIn"
            (click)="selectCompetitors(SpotlightCompetitors.PumpIn)">
            <div class="icon"></div>
            <div>Pump In</div>
        </div>
        <div *ngIf="competitorOptionsShown.includes('IN_MARKET')" [placement]="'bottom'" [dTooltip]="inmarket"
            class="icon-radio-button competitors-option in-market-option"
            [class.selected]="competitors === SpotlightCompetitors.InMarket"
            (click)="selectCompetitors(SpotlightCompetitors.InMarket)">
            <div class="icon"></div>
            <div>In Market</div>
        </div>
        <div *ngIf="competitorOptionsShown.includes('ONLY')" [placement]="'bottom'" [dTooltip]="spotlightonly"
            class="icon-radio-button competitors-option spotlight-only-option"
            [class.selected]="competitors === SpotlightCompetitors.SpotlightOnly"
            (click)="selectCompetitors(SpotlightCompetitors.SpotlightOnly)">
            <div class="icon"></div>
            <div>Spotlight Only</div>
        </div>
    </div>

    <!-- sales_area_selection -->

    <div class="icon-radio-buttons area-options" *ngIf="areaButtonShown.length>0">
        <label *ngIf="showAreaTitle">Sales Area</label>
        <ng-container
            *ngIf="areaButtonShown.includes('ALL') && (competitors === SpotlightCompetitors.All || competitors === SpotlightCompetitors.SpotlightOnly)">
            <div class="icon-radio-button area-option all-option"
                [class.selected]="salesArea === SpotlightSalesAreas.All"
                (click)="selectSalesArea(SpotlightSalesAreas.All)">
                <div class="icon"></div>
                <div>All Areas</div>
            </div>
        </ng-container>
        <ng-container
            *ngIf="areaButtonShown.includes('ALL') && (competitors !== SpotlightCompetitors.All && competitors !== SpotlightCompetitors.SpotlightOnly)">
            <div [placement]="'bottom'" dTooltip="All Areas are not available for Pump In and In Market."
                class="icon-radio-button area-option all-option disabled">
                <div class="icon"></div>
                <div>All Areas</div>
            </div>
        </ng-container>

        <div *ngIf="areaButtonShown.includes('PRIMARY')" [placement]="'bottom'" [dTooltip]="psa"
            class="icon-radio-button area-option primary-option"
            [class.selected]="salesArea === SpotlightSalesAreas.Primary"
            (click)="selectSalesArea(SpotlightSalesAreas.Primary)">
            <div class="icon"></div>
            <div>Primary</div>
        </div>
        <div *ngIf="areaButtonShown.includes('SECONDARY')" [placement]="'bottom'" [dTooltip]="ssa"
            class="icon-radio-button area-option secondary-option"
            [class.selected]="salesArea === SpotlightSalesAreas.Secondary"
            (click)="selectSalesArea(SpotlightSalesAreas.Secondary)">
            <div class="icon"></div>
            <div>Secondary</div>
        </div>
        <div
            *ngIf="areaButtonShown.includes('RADIUS')"
            [placement]="'bottom'"
            [dTooltip]="radius"
            class="icon-radio-button area-option radius-option"
            [class.selected]="salesArea === SpotlightSalesAreas.Radius"
            [class.disabled]="radiusSalesAreaDisabled"
            (click)="!radiusSalesAreaDisabled && selectSalesArea(SpotlightSalesAreas.Radius)">
            <div class="icon"></div>
            <div>Radius</div>
        </div>
        <div
            *ngIf="areaButtonShown.includes('CUSTOM') && isCustomZipsEnabled"
            [placement]="'bottom'"
            [dTooltip]="custom"
            class="icon-radio-button area-option custom-option"
            [class.selected]="salesArea === SpotlightSalesAreas.Custom"
            (click)="selectSalesArea(SpotlightSalesAreas.Custom)">
            <div class="icon">
            </div>
            <div>Custom</div>
        </div>
        <div class="zip-upload" *ngIf="this.salesArea === SpotlightSalesAreas.Custom">
            <p class="message">Enter zip codes</p>
            <input [(ngModel)]="customZipsString" placeholder="00000, 00000, 00000"
                (keypress)="onZipKeypress($event)" />
            <kite-button buttonSize="sm" (click)="toggleUploadModal()">Upload Zip Codes</kite-button>
            <p class="clear-zips" (click)="clearZips()">Clear zip codes</p>
        </div>
    </div>

    <!-- include_spotlight -->
    <filter-toggle-button
        *ngIf="showSpotLight"
        label="Spotlight"
        optionOneLabel="Include"
        optionOne="include"
        optionTwoLabel="Exclude"
        optionTwo="exclude"
        [filterName]="FilterName.include_spotlight"
        (toggle)="updateToggleValue($event)"
        [disabled]="spotlightToggleDisabled || competitors === SpotlightCompetitors.PumpIn || competitors === SpotlightCompetitors.SpotlightOnly"
        [dTooltip]="toggledisabled"
        [placement]="'bottom'"
        [showTooltip]="spotlightToggleDisabled || competitors === SpotlightCompetitors.PumpIn || competitors === SpotlightCompetitors.SpotlightOnly">
    </filter-toggle-button>

    <!-- ring_radius -->
    <filter-toggle-button
        *ngIf="showRadius"
        label="Spotlight Radius"
        optionOneLabel="On"
        [optionOne]="true"
        optionTwoLabel="Off"
        [optionTwo]="false"
        [filterName]="FilterName.show_ring"
        (toggle)="updateToggleValue($event)">
    </filter-toggle-button>
    <filter-counter-button
        *ngIf="showRing && areaButtonShown.includes('RADIUS')"
        [label]="radiusDesc"
        [filterName]="FilterName.ring_radius"
        [filterService]="filterStateService"
        min="1"
        max="50"
        labelPos="left"></filter-counter-button>
</div>

<ng-template #pumpin>
    <div style="max-width: 200px">
        View sales from dealerships located outside and selling into the Spotlight Dealer's Primary sales area or
        Secondary sales
        area
    </div>
</ng-template>

<ng-template #inmarket>
    <div style="max-width: 200px">
        View sales from competitive dealers located and selling within the Spotlight Dealer's Primary sales area or
        Secondary sales
        area
    </div>
</ng-template>

<ng-template #spotlightonly>
    <div style="max-width: 200px">
        View sales from the Spotlight dealer only.
    </div>
</ng-template>

<ng-template #psa>
    <div style="max-width: 200px">
        The highest 75% of sales concentration by zip code
    </div>
</ng-template>

<ng-template #ssa>
    <div style="max-width: 200px">
        The next 15% of sales concentration outside of the primary sales area
    </div>
</ng-template>

<ng-template #custom>
    <div style="max-width: 200px">
        Custom zip upload
    </div>
</ng-template>

<ng-template #radius>
    <div style="max-width: 200px">
        <ng-container
            *ngIf="competitors === SpotlightCompetitors.PumpIn || competitors === SpotlightCompetitors.InMarket; else radiusEnabled">
            Radius is not available for Pump In and In Market.
        </ng-container>
        <ng-template #radiusEnabled>
            Filters data for only zip codes or zones located, even partially, within the user specified radius ring
        </ng-template>
    </div>
</ng-template>

<ng-template #toggledisabled>
    <div style="max-width: 220px">
        The spotlight toggle is unavailable due to currently selected filters
    </div>
</ng-template>
