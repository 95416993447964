import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { DropDownItem } from "app/core/components/dropdown/dropdown.component";
import { ZipZoneSale } from "app/core/models/zip-zone.model";
import { TopZipsHeaderService } from "app/core/services/top-zips-header.service";
import { FeatureFlagService } from "@at/core";

@Component({
    selector: "top-zips-header-table",
    templateUrl: "./top-zips-header-table.component.html",
    styleUrls: ["./top-zips-header-table.component.scss"]
})
export class TopZipsHeaderTableComponent implements OnInit, OnChanges {

    @Input() showVolume = true;
    @Input() limit: DropDownItem;
    @Input() zipZoneSales: ZipZoneSale[] = [];
    @Input() isMultiDma: boolean;
    @Input() hidePrimaryAndSecondary = false;
    @Input() smallerCells = false;
    @Input() showTop25Zips = false;

    topZipZoneTotalVolume = 0;
    top25ZipZoneTotalVolume = 0;
    totalVolume = 0;
    primarySalesTotal = 0;
    secondarySalesTotal = 0;

    constructor(
        private topZipsHeaderService: TopZipsHeaderService
    ) { }

    ngOnInit(): void {
        this.updateTotals();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateTotals();
    }

    private updateTotals(): void {
        this.topZipZoneTotalVolume = this.topZipsHeaderService.calculateTopZipZoneVolume(this.zipZoneSales, this.limit.value, this.isMultiDma);
        this.top25ZipZoneTotalVolume = this.topZipsHeaderService.calculateTop25ZipZoneVolume(this.zipZoneSales);
        this.totalVolume = this.topZipsHeaderService.calculateTotalVolume(this.zipZoneSales);
        this.primarySalesTotal = this.topZipsHeaderService.calculatePrimarySalesAreaTotal(this.zipZoneSales);
        this.secondarySalesTotal = this.topZipsHeaderService.calculateSecondarySalesAreaTotal(this.zipZoneSales);
    }
}
