import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { USPSZipService } from "app/core/services/usps.zip.service";
import * as Excel from "exceljs/dist/exceljs.min.js";
import { parse } from "fsp-xml-parser";

export interface ZipUploadContainer {
  zips: string[];
  fileName: string;
  errors: string[];
  success: boolean;
}

@Component({
    selector: "drag-and-drop-file-upload",
    templateUrl: "./drag-and-drop-file-upload.component.html",
    styleUrls: ["./drag-and-drop-file-upload.component.scss"]
})
export class DragAndDropFileUploadComponent implements OnDestroy {

    errors: string[] = [];
    finalZips: string[] = [];

    @Output() zipsUploaded: EventEmitter<ZipUploadContainer> = new EventEmitter();

    constructor(private uspsZipService: USPSZipService) {}

    ngOnDestroy(): void {
        this.errors = [];
        this.finalZips = [];
    }

    async fileDropped(file: File) {
        this.errors = [];
        this.finalZips = [];
        const zips = [];
        const workbook = new Excel.Workbook();
        const fileBuffer = await file.arrayBuffer();
        await workbook.xlsx.load(fileBuffer);
        const worksheet = workbook.worksheets[0];
        let j = 1;
        while (true) {
            const cellValue = worksheet.getCell(`A${j}`).value;
            if (cellValue == null || typeof cellValue === "undefined") {
                if (j === 1) {
                    this.errors.push(j.toString());
                    this.zipsUploaded.next({ zips: [], fileName: file.name, errors: this.errors, success: false });
                    return;
                }
                break;
            } else {
                zips.push(cellValue);
            }
            j++;
        }
        let zipGroups = this.breakZipsIntoGroups(zips, 5);
        // loop the zips array
        for ( let i = 0; i < zipGroups.length; i++) {
            let requestZips = zipGroups[i];
            await this.uspsZipService.validateZips(requestZips).then(response => {
                // parses the XML returned back from USPS, loops through the response array
                // and finds errors and records them.
                parse(response).root.children.forEach((value, index) => {
                    // USPS returns 3 children if the zip is valid and 1 child if it's invalid
                    // also it pulls the erroneous zip code out of the zips array
                    if (value.children.length === 1 && value.children[0].name === "Error") {
                        // fetch the index of original zips array (full set)
                        let zipIndex = zips.findIndex((element, idx) => {
                            // search for a duplicate erroneous zip code
                            if (this.errors.includes((idx + 1).toString())) {
                                return false;
                            }
                            if (element === requestZips[index]) {
                                return true;
                            }
                        });
                        // use the index from the original array + 1 to get the Excel row #
                        this.errors.push((zipIndex + 1).toString());
                    } else {
                        this.finalZips = [...this.finalZips, value.children[0].content];
                    }
                });
                // return new Promise(resolve =>
                //     setTimeout(() => resolve(true), 100)
                // );
            }, (error) => {
                console.error(error);
                // return new Promise(resolve =>
                //     setTimeout(() => resolve(true), 100)
                // );
            });
        }
        // push out the data including which zips have errors
        this.zipsUploaded.next({ zips: this.finalZips, fileName: file.name, errors: this.errors, success: true });
    }

    /**
     * Breaks an array of zips into groups of however many zips set in maxPerGroup
     *
     * @param data              string[]        your string array of data, in this case zips...
     * @param maxPerGroup       number          the arbitrary number you set to group the data
     * @returns                 string[][]      returns a multidimensional array of groups of zips
     */
    breakZipsIntoGroups(data: string[], maxPerGroup: number): string[][] {
        let groups = [];
        for (let index = 0; index < data.length; index += maxPerGroup) {
            groups.push(data.slice(index, index + maxPerGroup));
        }
        return groups;
    }

}
