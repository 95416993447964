import { UserCookieService } from "./user-cookie.service";
import { Injectable } from "@angular/core";

@Injectable()
export class PresentationCookieService extends UserCookieService {

    filters = {};

    getStoredValue(item: string, arrayFilters: string[], prefix: string = "ppt_"): any {

        return this.filters[item];
    }

    setStoredValue(item: string, value: any, prefix: string = "ppt_"): any {
        this.filters[item] = value;
    }

    public getSalesRangeFilteredDealers(): number[] {
        return this.getStoredValue("sales_range_dealers", []);
    }

    public setSalesRangeFilteredDealers(dealers: number[]): void {
        this.setStoredValue("sales_range_dealers", dealers);
    }

    storedValueExists(item: string, prefix: string = "ppt_"): boolean {
        return this.filters[item] !== undefined;
    }

    public cookieValueExists(item: string, prefix: string = ""): boolean {
        return this.cookieService.check(this.emailPrefix + prefix + "." + item);
    }

    public getCookieValue(item: string, arrayFilters: string[], prefix: string = ""): any {
        try {
            let value = JSON.parse(this.cookieService.get(this.emailPrefix + prefix + "." + item));
            if (arrayFilters.includes(item) && !Array.isArray(value)) {
                value = undefined;
            }
            return value;
        } catch (e) {
            this.cookieService.delete(this.emailPrefix + prefix + "." + item, "/");
            return undefined;
        }
    }

}
