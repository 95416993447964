import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEmpty } from "lodash";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";
import { InterceptorSkipHeader } from "../../interceptors/client-cache.interceptor";
import { InterceptorSkipTokenHeader } from "../../interceptors/token.interceptor";

@Injectable()
export class HttpServiceBase {
    protected headers = new HttpHeaders({ "Content-type": "application/json" });
    protected rootUrl: string;

    constructor(protected http: HttpClient) {
        // Check for the 'api' query string parameter to override api calls
        const matchResults = new RegExp("[?&]api(=([^&#]*)|&|#|$)").exec(window.location.href);
        const subdomainResults = new RegExp(/\w+/).exec(window.location.host);
        const subdomain = subdomainResults[0];
        const apiQueryString = matchResults && matchResults[2];

        if (apiQueryString === "dev" && !environment.production) {
            // If "api=dev" is in the queryString of the client URL, then use the feature branch api
            this.rootUrl = `https://${matchResults}.services.spectrumreach.io/automotive`;
        } else if (environment.pullRequest && !environment.production) {
            // PR branches use their back-end by default, but the service URL includes automotive-<pr name>
            this.rootUrl = `https://pr.services.spectrumreach.io/automotive-${subdomain}`;
        } else {
            this.rootUrl = environment.apiBaseUrl;
        }
    }

    fetchStrings(url: string, params: HttpParams): Observable<string[]> {
        return this.http.get<string[]>(url, { params });
    }

    fetchInterfaces<T>(url: string, params: HttpParams): Observable<T[]> {
        return this.http.get<T[]>(url, { params });
    }

    fetchInterfaceNoCache<T>(url: string, params: HttpParams, headers?: HttpHeaders): Observable<T> {
        if (typeof headers === "undefined") {
            headers = new HttpHeaders().set(InterceptorSkipHeader, "");
        } else {
            headers = headers.set(InterceptorSkipHeader, "");
            headers = headers.set(InterceptorSkipTokenHeader, "");
        }
        return this.http.get<T>(url, { headers, params });
    }

    postInterfaceNoCache<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
        if (typeof headers === "undefined") {
            headers = new HttpHeaders().set(InterceptorSkipHeader, "");
        } else {
            headers = headers.set(InterceptorSkipHeader, "");
            headers = headers.set(InterceptorSkipTokenHeader, "");
        }
        return this.http.post<T>(url, body, { headers });
    }

    postInterfaces<T>(url: string, json: any): Observable<T[]> {
        return this.http.post<T[]>(url, json, { headers: this.headers });
    }

    postInterface<T>(url: string, body: any): Observable<T> {
        return this.http.post<T>(url, body, { headers: this.headers });
    }

    cleanKeys(obj: object, mapping: object): any {
        const updatedMapping = {};
        Object.keys(obj).forEach((key) => {
            const newKey = mapping[key] || key;
            updatedMapping[newKey] = obj[key];
        });
        return updatedMapping;
    }

    createRequestOptions(filters: object = null): HttpParams {
        let params = new HttpParams();
        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                if ((value && value.length > 0)
                    || (typeof value === "number" && !isNaN(value))
                    || (typeof value === "object" && !Array.isArray(value) && !isEmpty(value) && value)) {
                    if (typeof value === "string" || typeof value === "number") {
                        params = params.set(key, value as any);
                    } else {
                        params = params.set(key, JSON.stringify(value));
                    }
                }
            }
        }
        return params;
    }
}
