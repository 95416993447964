<form>
    <kite-label>{{leadingText}}</kite-label>
    <div class="dd-selector" [class.open]="isOpen" (click)="isOpen = true">
        <div class="dd-selected">
            <!-- {{selected?.label}} -->
            <div class="icon">
                <span class="arrow" [class.expanded]="isOpen"></span>
            </div>
        </div>
        <select [formControl]="select" kiteSelect (change)="selectItem($event)">
            <option *ngFor="let item of items" [ngValue]="item">{{item.label}}</option>
        </select>
    </div>
</form>
