import { Component, Input, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { ExportToastService, ExportToastViewConfig } from "app/core/services/export-toast.service";

export interface ExportToastView {
    id: string;
    icon: ExportIcon;
    header: string;
    message?: string;
    confirmLabelText?: string;
    contentTmplRef?: TemplateRef<any>;
    contentTmplData?: any;
}

export enum ExportIcon {
    PROMPT = "PROMPT",
    ERROR = "ERROR"
}

@Component({
    selector: "export-toast",
    templateUrl: "./export-toast.component.html",
    styleUrls: ["./export-toast.component.scss"]
})
export class ExportToastComponent implements OnInit, OnDestroy {
    // @Input() show = false;
    @Input() extendLeft = false;
    @Input() content = "";

    hidden = true;
    ExportIcon = ExportIcon;
    config: ExportToastViewConfig;
    resetFunction: () => void;
    error: boolean;

    constructor(private exportToastService: ExportToastService) {
    }

    ngOnInit(): void {
        this.exportToastService.openSubject.subscribe(this.show.bind(this));
        this.exportToastService.closeSubject.subscribe(this.dismiss.bind(this));
    }

    ngOnDestroy(): void {
        // this.exportToastService.openSubject.unsubscribe();
        // this.exportToastService.closeSubject.unsubscribe();
    }

    show(config: ExportToastViewConfig): void {
        this.content = config.viewConfig.message;
        this.error = config.viewConfig.icon === ExportIcon.ERROR;
        this.hidden = false;
        this.resetFunction = config.reset;
    }
    reset(): void {
        this.resetFunction();
        this.hidden = true;
    }

    dismiss(): void {
        this.hidden = true;
    }
}
