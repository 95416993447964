import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DateUtils } from "@at/utils";
import { ChartOptions } from "app/core/models/chart-options.model";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { Subscription, forkJoin, fromEvent } from "rxjs";
import { debounceTime, skipWhile, take } from "rxjs/operators";

import { DataSet } from "../../../models/chart-data.model";
import { FilterName } from "../../../models/filter-name.enum";
import { ZipZoneSale } from "../../../models/zip-zone.model";
import { FilterStateService } from "../../../services/filter-state.service";
import { MetadataService } from "../../../services/metadata.service";
import { SalesDataService } from "../../../services/sales-data.service";
import { ChartColor } from "../../charts/chart/chart-color.utils";
import { MAX_BAR_THICKNESS, MIN_BAR_THICKNESS } from "../../charts/chart/chart-defaults.options";
import { ChartComponent } from "../../charts/chart/chart.component";
import { ChartComponentUtils } from "../../charts/chart/chart.component.utils";
import { CardUtils } from "../card/card.utils";

@Component({
    selector: "dealer-make-by-ad-zone-card",
    templateUrl: "./dealer-make-by-ad-zone-card.component.html"
})
export class DealerMakeByAdZoneCardComponent implements OnInit, OnDestroy {
    @ViewChild(ChartComponent) chart: ChartComponent;

    title = "Zone Make Sales vs. Spotlight Dealer Make Sales";
    description = "";
    disableDescription = "";
    isDisabled = true;
    isOpen = false;
    isLoading = false;
    noResults = false;
    dateRangeType = "rolling";
    dateRange = "2018";
    salesDateRange = "";

    data: DataSet[] = [];
    labels: string[][] = [];
    colors: string[] = [];
    customTicks: number[][] = [];
    // Chart options overrides.
    chartOptions: ChartOptions = {
        borderRadius: .15,
        scales: {
            xAxes: [{
                gridLines: { display: true, offsetGridLines: true },
                ticks: {
                    autoSkip: false, // stops the chart from removing labels based on chart width
                    display: true
                }
            }, {
                type: "category",
                // in order to have scale labels on the bottom of the chart,
                // while having the original labels location on the top of the chart, a second axis is needed.
                id: "bottom-x-axis",
                gridLines: { display: false },
                offset: true,
                ticks: {
                    display: true,
                    autoSkip: false,
                    padding: 20,
                    maxRotation: 0,
                    beginAtZero: false,
                    callback: (value, idx, values) => {
                        if ((idx + 1) > this.customTicks.length) {
                            return;
                        }
                        let retVal = "";
                        this.customTicks[idx].forEach((tick, index) => {
                            retVal += `  ${Number(tick).toFixed(2)}%  `;
                        });
                        return retVal;
                    }
                },
                afterBuildTicks: (chartObj) => {
                    if (chartObj.hasOwnProperty("ticks") && this.customTicks[0].length > 1) {
                        chartObj.ticks = this.customTicks;
                        // chartObj.ticks.stepSize = this.customTicks[0].length;
                        if (chartObj.hasOwnProperty("tickValues")) {
                            this.chart.setXAxisScaleCustom(this.customTicks);
                        }
                    }
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Volume",
                    fontStyle: 600,
                    fontFamily: "Open Sans",
                    fontColor: "#404040"
                }
            }]
        },
        customOptions: {
            groupedBars: {
                leftOffset: 0
            }
        },
        plugins: {
        }
    };

    private MAX_AD_ZONE = 5;
    // The master list of adzones before adjusting for chart display.
    private labelsRef: string[] = [];
    private filterStateSubscription: Subscription;
    private resizeSubscription: Subscription;
    maxDate: any;
    minDate: any;

    constructor(
        protected filterStateService: FilterStateService,
        protected salesDataService: SalesDataService,
        private metadataService: MetadataService,
    ) {
        // Update this chart when the window is resized to recalculate the chart segmentation.
        this.resizeSubscription = fromEvent(window, "resize").pipe(
            debounceTime(100))
            .subscribe(() => {
                if (this.isOpen) {
                    this.updateChartSize.call(this);
                }
            });
    }

    ngOnInit(): void {
        this.colors = ChartColor.getColorPalette(2);
        this.metadataService.metadata.pipe(skipWhile(i => !i || !i.maxDate || !i.maxSalesDate), take(1)).subscribe(meta => {
            const dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            const dateRangeState: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
            this.dateRange = (dateRangeState !== undefined && dateRangeState.length >= 1 && dateRangeType === DateRangeTypes.MONTHS) ? dateRangeState[1] : meta.maxDate;
            this.salesDateRange = (dateRangeState !== undefined && dateRangeState.length >= 1 && dateRangeType === DateRangeTypes.MONTHS) ? dateRangeState[1] : meta.maxSalesDate;
            this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
            this.filtersUpdated([
                FilterName.spotlight_dealer.toString(),
                FilterName.makes.toString(),
                FilterName.dateRangeType.toString(),
                FilterName.dateRanges.toString()
            ]);
        });
    }

    ngOnDestroy(): void {
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }

    cardOpened(): void {
        this.isOpen = true;
        this.updateSalesData();
    }

    cardClosed(): void {
        this.isOpen = false;
    }

    filtersUpdated(filters: string[]): void {
        let requiresUpdate = false;

        const conditions = {
            spotlit: {
                isValid: !!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer),
                text: "spotlight a dealer"
            },
            // make: {
            //     isValid: this.filterStateService.getFilterValue<number[]>(FilterName.makes).length === 1,
            //     text: "select exactly 1 make"
            // },
            include_spotlit: {
                isValid: this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) === "include",
                text: "toggle 'Spotlight' to Include"
            }
        };

        this.description = CardUtils.getRequirementDescription(conditions);
        this.isDisabled = this.description.length > 0;


        if (
            filters.includes(FilterName.spotlight_dealer.toString()) ||
            filters.includes(FilterName.makes.toString()) ||
            filters.includes(FilterName.zones.toString()) ||
            filters.includes(FilterName.segments.toString()) ||
            filters.includes(FilterName.models.toString()) ||
            filters.includes(FilterName.new_used_flag.toString()) ||
            filters.includes(FilterName.zips.toString()) ||
            filters.includes(FilterName.use_sales_data.toString())
        ) {
            requiresUpdate = true;
        }
        const includesDateRangeType = filters.includes(FilterName.dateRangeType.toString());
        const includesDateRange = filters.includes(FilterName.dateRanges.toString());

        if (includesDateRangeType || includesDateRange) {
            const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            // if this is a custom date range, the update does not include new date ranges, and has no old date ranges
            if (this.dateRangeType === DateRangeTypes.MONTHS && !includesDateRange && (!dateRanges || dateRanges.length === 0)) {
                requiresUpdate = false;
            } else {
                this.updateDates();
                requiresUpdate = true;
            }
        }

        // A disabled card should be closed and not update.
        if (this.isDisabled) {
            this.cardClosed();
        } else if (requiresUpdate) {
            this.updateSalesData();
            this.updateDates();
            requiresUpdate = false;
        }
    }

    async updateSalesData(): Promise<void> {
        this.isLoading = true;
        const getHashKey = (adzone: ZipZoneSale): string =>
            // DMA code as part of hashkey because a zone can be in multiple DMA
            // e.g. "RCS-Rwn-Cabrs-Stly, NC" is mapped to 517 (Charlotte) and 518 (Greensboro).
            `${adzone.dma}-${adzone.sys_code}`
        ;
        const zones = this.filterStateService.getFilterValue<string[]>(FilterName.zones);
        const zips = this.filterStateService.getFilterValue<string[]>(FilterName.zips);

        const make = this.filterStateService.getFilterValue<string[]>(FilterName.makes)[0] ? this.filterStateService.getFilterValue<string[]>(FilterName.makes)[0] : "all";
        const isMultiDma = this.filterStateService.isMultiDma();
        const useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        // Don't update if this card is not open or enabled
        // if (!this.cardOpenState || this.cardDisabledState) {
        //     return;
        // }
        const dmas = this.filterStateService.getFilterValue(FilterName.dma);
        const urlParams = this.filterStateService.getCurrentApiFilters();
        const endDateRange = useSalesData ? this.salesDateRange : this.dateRange;
        const filterOverride = { dateRangeEnd: endDateRange, dateRangeType: this.dateRangeType, zips, dma_code: dmas };

        // Override for ppt custom date range
        if (urlParams["dateRanges"] && urlParams["dateRangeType"] === "months") {
            filterOverride["dateRangeStart"] = urlParams["dateRanges"][0];
            filterOverride["dateRangeEnd"] = urlParams["dateRanges"][1];
        }

        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);

        if (dateRanges !== undefined && dateRanges.length >= 2 && this.filterStateService.getFilterValue(FilterName.dateRangeType) === "months") {
            filterOverride["dateRangeStart"] = dateRanges[0];
            filterOverride["dateRangeEnd"] = dateRanges[1];
        }

        // Does not apply zips filter.
        const buyerZoneTotalsReq = this.salesDataService.getBuyerSalesForZones(zones, { zips });
        const dealersZoneSalesReq = this.salesDataService.getSalesForDealersByZipZone(
            [this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer)],
            "zones",
            { zips },
            false
        );

        return forkJoin([buyerZoneTotalsReq, dealersZoneSalesReq]).toPromise().then(values => {
            const zoneBuyerTotals = values[0];
            const dealerZonesSales = values[1];

            // If dealer has no sales in any adzones for the selected dma(s),
            // close and disable the card with "No results" messaging.
            if (dealerZonesSales.length === 0) {
                this.isOpen = false;
                this.isDisabled = true;
                this.noResults = true;
                this.description = "No results for current filters.";
                return;
            }

            this.data = [];
            const topZoneForDealer = dealerZonesSales[0].sales.sort((a, b) => b.sales - a.sales).slice(0, this.MAX_AD_ZONE);
            // If the spotlit dealer does not sell into some selected adzones,
            // the server will not return a sale record for those adzones,
            // resulting in less than 5 zones. Fill in adzones by alphabetical order with 0 sales
            // until max adzone is reached to populate data for chart.
            while (topZoneForDealer.length < this.MAX_AD_ZONE && topZoneForDealer.length < zoneBuyerTotals.length) {
                const missingAdzone = zoneBuyerTotals.find(topAdzone => !topZoneForDealer.find(dealerAdzone => dealerAdzone.sys_code === topAdzone.sys_code));
                topZoneForDealer.push(Object.assign({}, missingAdzone, { sales: 0 }));
            }

            const adzoneHash = zoneBuyerTotals.reduce((memo, adzone) => {
                memo[getHashKey(adzone)] = adzone;
                return memo;
            }, {});

            this.data.push({
                id: `zone-${make}`,
                label: `Zone ${make} Sales`,
                data: topZoneForDealer.map(z => adzoneHash[getHashKey(z)].sales || 0)
            } as DataSet);

            this.data.push({
                id: `dealer-${make}`,
                shares: topZoneForDealer.map((z: any, i: number) => {
                    const zone = adzoneHash[getHashKey(topZoneForDealer[i])];
                    const decimal = (z.sales / (zone.sales || 0));
                    return isNaN(decimal) ? 0 : decimal * 100;
                }),
                label: `Dealer ${make} Sales`,
                data: topZoneForDealer.map(z => z.sales)
            } as DataSet);

            this.labelsRef = topZoneForDealer.map(z => isMultiDma ? z.locationWithDma : z.location);

            this.customTicks = [];
            // creates the custom ticks for a third axis
            topZoneForDealer.forEach((z: any, i: number) => {
                const zone = adzoneHash[getHashKey(topZoneForDealer[i])];
                const decimal = (z.sales / (zone.sales || 0));
                const dealerSalesShare =  isNaN(decimal) ? 0 : decimal * 100;
                this.customTicks.push([100, dealerSalesShare]);
            });

            // Wait for angular to render chart before the chart size
            // can be updated.
            setTimeout(() => this.updateChartSize.call(this), 25);
            this.isLoading = false;
        });
    }

    updateChartSize(): void {
        if (!this.isOpen) {
            return;
        }

        // Don't attempt to update if the card is not opened.
        const chartWidth = this.chart.elementRef.nativeElement.offsetWidth;
        // Chart width - approximate layout offsets / 5 segments.
        const chartSegmentWidth = (chartWidth - 300) / this.MAX_AD_ZONE;
        // Segment width / 2 bars.
        const barSize = (chartSegmentWidth - 2 * 15) / 2;
        this.chart.chartOptions.scales.xAxes[0].maxBarThickness = barSize;

        // Format label for chart and resize chart offset to support label height.
        this.labels = ChartComponentUtils.formatLabel(this.labelsRef, this.chart, this.MAX_AD_ZONE);
        const longestLabel = this.labels.reduce((m, l) => m = l.length > m && l[l.length - 1] !== "" ? l.length : m, 1);
        this.chart.chartOptions.scales.xAxes[0].gridLines.tickMarkLength = longestLabel * 20;
        this.chart.forceRedraw();
    }

    private updateDates(): void {
        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        const useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);

        if (dateRanges !== undefined && this.dateRangeType === "months" && dateRanges.length >= 2) {
            this.title = `Zone Make Sales vs. Spotlight Dealer Make Sales: ${DateUtils.dateDisplay([dateRanges[1], dateRanges[0]], this.dateRangeType)}`;
        } else {
            this.title = `Zone Make Sales vs. Spotlight Dealer Make Sales: ${DateUtils.displayDateRange(useSalesData ? this.salesDateRange : this.dateRange, this.dateRangeType, 1)}`;
        }
    }
}
