import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

import { FilterName } from "../models/filter-name.enum";
import { SpotlightCompetitors, SpotlightSalesAreas } from "../models/spotlight.enum";
import { DealerDataService } from "./dealer-data.service";
import { DealerSalesAreaService, SalesArea } from "./dealer-sales-area.service";
import { FilterStateService } from "./filter-state.service";

@Injectable()
export class SpotlightService {

    getSalesAreaSubscription: Subscription;
    filterStateServiceSubscription: Subscription;

    constructor(
        protected dealerDataService: DealerDataService,
        protected filterStateService: FilterStateService,
        protected dealerSalesAreaService: DealerSalesAreaService
    ) { }

    public setSpotlight(spotlightId: number, resetOptions: boolean = true): void {
        if (resetOptions) {
            this.resetOptions();
        }
        this.filterStateService.setFilterValue(FilterName.spotlight_dealer, spotlightId);
        const dealers = this.filterStateService.getFilterValue<number[]>(FilterName.dealers).filter((dealerId) => dealerId !== spotlightId);
        this.filterStateService.setFilterValue(FilterName.dealers, dealers);
    }

    public clearSpotlight(): void {
        this.resetOptions();
        this.filterStateService.setFilterValue(FilterName.spotlight_dealer, null);
    }

    public selectCompetitors(competitors: SpotlightCompetitors): void {
        const currentCompetitors_selection = this.filterStateService.getFilterValue<SpotlightCompetitors>(FilterName.competitors_selection);
        if (competitors === currentCompetitors_selection) {
            return;
        }
        if (!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer)) {
            competitors = SpotlightCompetitors.All;
        }

        // If sales area is currently set to "All Areas" or "Radius"
        // And newly set competitor is not "All Dealers" or "Spotlight Only"
        if ([SpotlightSalesAreas.All, SpotlightSalesAreas.Radius].includes(this.filterStateService.getFilterValue(FilterName.sales_area_selection))
            && ![SpotlightCompetitors.All, SpotlightCompetitors.SpotlightOnly].includes(competitors)
        ) {
            this.selectSalesArea(SpotlightSalesAreas.Primary);
        }
        if (this.filterStateService.getFilterValue<number[]>(FilterName.dealers).length) { // if a dealer filter has been applied, reset dealer filter
            this.filterStateService.resetFilters([FilterName.dealers]);
        }
        this.filterStateService.setFilterValue(FilterName.competitors_selection, competitors);
        this.updateFiltersForSpotlightSelections();
    }

    public selectSalesArea(area: SpotlightSalesAreas, customZips?: SalesArea[]): void {
        if (area === this.filterStateService.getFilterValue<SpotlightSalesAreas>(FilterName.sales_area_selection)) {
            return;
        }
        if (!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer)) {
            area = SpotlightSalesAreas.All;
        }
        if (this.filterStateService.getFilterValue<number[]>(FilterName.zips).length
            || this.filterStateService.getFilterValue<number[]>(FilterName.zones).length) { // if a zipcode/adzone filter has been applied, reset zips & zones & dealer filter
            this.filterStateService.resetFilters([FilterName.zips, FilterName.zones]);
        }
        this.filterStateService.setFilterValue(FilterName.sales_area_selection, area);
        if (customZips?.length >= 1) {
            this.updateFiltersForSpotlightSelections(customZips);
        } else {
            this.updateFiltersForSpotlightSelections();
        }
    }

    public resetOptions(): void {
        this.selectCompetitors(SpotlightCompetitors.All);
        this.selectSalesArea(SpotlightSalesAreas.All);
        this.filterStateService.resetFilters([FilterName.include_spotlight, FilterName.show_ring, FilterName.ring_radius]);
    }

    updateFiltersForSpotlightSelections(salesArea?: SalesArea[]): void {
        const competitors = this.filterStateService.getFilterValue<SpotlightCompetitors>(FilterName.competitors_selection);
        const area = this.filterStateService.getFilterValue<SpotlightSalesAreas>(FilterName.sales_area_selection);
        const dealer = this.dealerDataService.spotlightDealerDetails.getValue();

        if (this.getSalesAreaSubscription) {
            this.getSalesAreaSubscription.unsubscribe();
            this.getSalesAreaSubscription = null;
        }

        if (!dealer) {
            this.filterStateService.resetFilters([FilterName.sales_area_zips, FilterName.dealer_zip, FilterName.not_dealer_zip]);
            return;
        }

        switch (area) {
            case SpotlightSalesAreas.All:
                this.filterStateService.resetFilters([FilterName.dealers, FilterName.sales_area_zips]);
                this.filterStateService.emitChange("zips"); // forces zip code invalid check refresh
                break;

            case SpotlightSalesAreas.Primary:
            case SpotlightSalesAreas.Secondary:
                const spotlightType = (area === SpotlightSalesAreas.Primary ? "PSA" : "SSA");
                this.filterStateService.resetFilters([FilterName.dealers, FilterName.sales_area_zips]);
                const salesType = this.filterStateService.getFilterValue<number>(FilterName.new_used_flag);
                const useSalesData = this.filterStateService.getFilterValue<boolean>(FilterName.use_sales_data);
                this.getSalesAreaSubscription =
                    this.dealerSalesAreaService.getSalesAreas(dealer.dealer_id, spotlightType, salesType, useSalesData).subscribe((salesAreas: SalesArea[]) => {
                        if (salesAreas.length) {
                            this.filterStateService.setFilterValue(FilterName.sales_area_zips, salesAreas.map(r => r.buyer_zip));
                        } else {
                            // this isn't a zip code, we want to exclude all results so it shows as empty in the results
                            this.filterStateService.setFilterValue(FilterName.sales_area_zips, ["No Secondary Sales Area"]);
                        }
                        this.getSalesAreaSubscription.unsubscribe();
                        this.getSalesAreaSubscription = null;
                        this.filterStateService.emitChange("zips"); // forces zip code invalid check refresh
                    });
                break;

            case SpotlightSalesAreas.Radius:
                if (!this.filterStateService.getFilterValue(FilterName.show_ring)) {
                    this.filterStateService.setFilterValue(FilterName.show_ring, true);
                }
                break;
            case SpotlightSalesAreas.Custom:
                if (typeof salesArea !== "undefined" && salesArea.length >= 1) {
                    this.filterStateService.resetFilters([FilterName.dealers, FilterName.sales_area_zips]);
                    this.filterStateService.setFilterValue(FilterName.sales_area_zips, salesArea.map(r => r.buyer_zip));
                    this.filterStateService.emitChange("zips"); // forces zip code invalid check refresh
                }
                break;
        }

        let setMarketZips: Function;
        switch (competitors) {
            case SpotlightCompetitors.All:
                this.filterStateService.resetFilters([FilterName.dealers, FilterName.dealer_zip, FilterName.not_dealer_zip]);
                return;
            case SpotlightCompetitors.SpotlightOnly:
                this.filterStateService.resetFilters([FilterName.dealer_zip, FilterName.not_dealer_zip]);
                this.filterStateService.setFilterValue(FilterName.dealers, [dealer.dealer_id]);
                this.filterStateService.setFilterValue(FilterName.include_spotlight, "include");
                return;
            case SpotlightCompetitors.PumpIn:
                setMarketZips = () => { // dealer zips != sales area zips
                    const marketZips = this.filterStateService.getFilterValue<string[]>(FilterName.sales_area_zips);
                    this.filterStateService.setFilterValue(FilterName.not_dealer_zip, marketZips);
                    this.filterStateService.resetFilters([FilterName.dealers, FilterName.dealer_zip]);
                };
                break;
            case SpotlightCompetitors.InMarket:
                setMarketZips = () => { // dealer zips = sales area zips
                    const marketZips = this.filterStateService.getFilterValue<string[]>(FilterName.sales_area_zips);
                    this.filterStateService.setFilterValue(FilterName.dealer_zip, marketZips);
                    this.filterStateService.resetFilters([FilterName.dealers, FilterName.not_dealer_zip]);
                };
                break;
        }

        if (this.getSalesAreaSubscription) { // if requesting new sales area, wait for the new data
            this.getSalesAreaSubscription.add(setMarketZips);
        } else {
            setMarketZips();
        }
    }
}
