import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { DealerSales, DmaSales, LineItemDetail, ModelItemDetail, Sales } from "app/core/models/dealer-sales";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { UnknownSalesService } from "app/core/services/unknown-sales.service";
import * as moment from "moment";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { SalesAccordionHeaderRollup, SalesAccordionSalesItem } from "../sales-accordion/sales-accordion.component";

export interface MakeModelSales {
    label: string;
    rollups: SalesAccordionHeaderRollup;
    items: SalesAccordionSalesItem[];
}

@Component({
    selector: "dealer-makes-models-details",
    templateUrl: "dealer-makes-models-details.component.html",
    styleUrls: ["./dealer-makes-models-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerMakesModelsDetailsComponent implements OnInit, OnDestroy {
    @Input() dealer: Dealer;
    // Determine if DMA sales are shown instead of dealer sales. dealer property should not be set.
    @Input() total = false;
    @Input() dmaId: number;
    @Input() type: string;
    @Input() title: string;
    @Input() scope = "dealer";
    @Input() showVolume: boolean;

    minDate: string;
    maxDate: string;
    dates: string[] = [];
    dealerYearlySales: Sales = { loaded: new BehaviorSubject(false) };
    sales: MakeModelSales[] = [];
    salesDone: Boolean = false;
    protected stream: Subscription;

    filterOverrides: { [key: string]: any } = {};

    protected dealerYearlySalesSubscription: Subscription;
    dateRangeType = "calendar";
    private filterStateSubscription: Subscription;
    protected dateGroupSales: (DmaSales | DealerSales)[] = [];
    useSalesData: boolean;

    get salesOwnerId(): number {
        return this.dealerYearlySales && (this.dealerYearlySales as DealerSales).dealer_id || (this.dealerYearlySales as DmaSales).dma_id;
    }

    constructor(
        protected salesDataService: SalesDataService,
        protected filterStateService: FilterStateService,
        protected changeDetectorRef: ChangeDetectorRef,
        private unknownSalesService: UnknownSalesService
    ) { }

    ngOnInit(): void {
        this.loadDealerYearlySales();
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.loadDealerYearlySales.bind(this));
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
        this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);
    }

    ngOnDestroy(): void {
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
        if (this.dealerYearlySalesSubscription) {
            this.dealerYearlySalesSubscription.unsubscribe();
        }
    }

    loadDealerYearlySales(): void {
        this.dates = [];
        this.salesDone = false;

        // determine date groups
        this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        const dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
        let dateGroups;

        if (this.dateRangeType === DateRangeTypes.MONTHS && dateRanges && dateRanges.length > 0) {
            this.maxDate = dateRanges[1];
            this.minDate = dateRanges[4];
            dateGroups = DateUtils.generateCustomDateGroupings(dateRanges);
        }


        if (this.dateRangeType !== DateRangeTypes.MONTHS) {
            if (this.scope && this.scope === "unknown") {
                this.dealerYearlySales = this.unknownSalesService.getUnknownSales(this.dmaId || this.filterStateService.getFilterValue(FilterName.dma));
            } else if (this.total === true || this.total as any === "true") {
                this.dealerYearlySales = this.salesDataService.getTotalSalesForDmas([this.dmaId || this.filterStateService.getFilterValue(FilterName.dma)], {}, false, this.filterOverrides)[0];
            } else if (this.dealer) {
                this.dealerYearlySales = this.salesDataService.getSalesForDealer(this.dealer, { volume: null });
            }
        } else {
            if (this.dateGroupSales.length) {
                this.dateGroupSales = [];
            }
            if(dateGroups){
                dateGroups.forEach((group) => {
                    if (this.scope && this.scope === "unknown") {
                        this.dateGroupSales.push(this.unknownSalesService.getUnknownSales(this.dmaId || this.filterStateService.getFilterValue(FilterName.dma), { dateRangeStart: group.start, dateRangeEnd: group.end }));
                    } else if (this.total === true || this.total as any === "true") {
                        this.dateGroupSales.push(this.salesDataService.getTotalSalesForDmas([this.dmaId || this.filterStateService.getFilterValue(FilterName.dma)], { dateRangeStart: group.start, dateRangeEnd: group.end }, false, this.filterOverrides)[0]);
                    } else if (this.dealer) {
                        this.dateGroupSales.push(this.salesDataService.getSalesForDealer(this.dealer, { volume: null, dateRangeStart: group.start, dateRangeEnd: group.end }));
                    }
                });
            }
            this.dealerYearlySales = { makes: [], salesMonthly: {}, sharesMonthly: {}, loaded: new BehaviorSubject(false) };

            // Clean out old data stream.
            if (this.stream) {
                this.stream.unsubscribe();
                this.stream = undefined;
            }

            this.stream = combineLatest(this.dateGroupSales.map(sale => sale.loaded)).pipe(skipWhile(vals => vals.some(v => v === false)), take(1)).subscribe(() => {
                const target = [];
                const prop = "make_description";
                const modelProp = "model_description";

                // sum all of the sales to groups
                this.dateGroupSales.forEach((dateGroup, dgInd) => {
                    const lastDate = Object.keys(dateGroup.salesMonthly).slice(-1)[0];
                    if (Object.values<number>(dateGroup.salesMonthly).length > 0) {
                        this.dealerYearlySales.salesMonthly[lastDate] = Object.values<number>(dateGroup.salesMonthly).reduce((acc, curr) => acc + curr);
                        this.dealerYearlySales.sharesMonthly[lastDate] = (this.dealerYearlySales.salesMonthly[lastDate] / Object.values<number>(dateGroup["dmaSalesMonthly"]).reduce((acc, curr) => acc + curr)) * 100;
                    } else {
                        this.dealerYearlySales.salesMonthly[dateGroups[dgInd].end] = 0;
                        this.dealerYearlySales.sharesMonthly[dateGroups[dgInd].end] = 0;
                    }

                    dateGroup.makes.forEach((make) => {
                        const aggSales: any = { salesMonthly: {}, sharesMonthly: {} };

                        aggSales.salesMonthly[lastDate] = Object.values<number>(make.salesMonthly).reduce((acc, curr) => acc + curr);
                        aggSales.sharesMonthly[lastDate] = (aggSales.salesMonthly[lastDate] / Object.values<number>(make["dmaSalesMonthly"]).reduce((acc, curr) => acc + curr)) * 100;

                        const targetMake = target.find(_make => make[prop] === _make[prop]);
                        if (targetMake) {
                            targetMake.salesMonthly = Object.assign(targetMake.salesMonthly, aggSales.salesMonthly);
                            targetMake.sharesMonthly = Object.assign(targetMake.sharesMonthly, aggSales.sharesMonthly);
                        } else {
                            target.push({ ...make, models: [], salesMonthly: aggSales.salesMonthly, sharesMonthly: aggSales.sharesMonthly });
                        }
                        make.models.forEach(model => {
                            const aggModelSales: any = { salesMonthly: {}, sharesMonthly: {} };
                            const targetMakeIndex = target.findIndex(_make => _make.make_description === make.make_description);
                            aggModelSales.salesMonthly[lastDate] = Object.values<number>(model.salesMonthly).reduce((acc, curr) => acc + curr);
                            aggModelSales.sharesMonthly[lastDate] = (aggModelSales.salesMonthly[lastDate] / Object.values<number>(model["dmaSalesMonthly"]).reduce((acc, curr) => acc + curr)) * 100;

                            const targetModel = target[targetMakeIndex].models.find(_model => model[modelProp] === _model[modelProp]);
                            if (targetModel) {
                                targetModel.salesMonthly = Object.assign(targetModel.salesMonthly, aggModelSales.salesMonthly);
                                targetModel.sharesMonthly = Object.assign(targetModel.sharesMonthly, aggModelSales.sharesMonthly);

                            } else {
                                target[targetMakeIndex].models.push({ ...model, salesMonthly: aggModelSales.salesMonthly, sharesMonthly: aggModelSales.sharesMonthly });
                            }
                        });
                    });
                });
                this.dealerYearlySales.makes = target;
                this.dealerYearlySales.makes.forEach(make => make.models.sort());

                this.dates = dateGroups.map(date => date.end);

                this.sales = this.toAccordionSales(this.dealerYearlySales, this.dates);
                this.salesDone = true;
                this.changeDetectorRef.detectChanges();
            });
        }

        if (this.dateRangeType !== DateRangeTypes.MONTHS) {
            if (this.dealerYearlySalesSubscription) {
                this.dealerYearlySalesSubscription.unsubscribe();
            }
            this.changeDetectorRef.detectChanges();
            this.salesDone = false;

            this.dealerYearlySalesSubscription = this.dealerYearlySales.loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
                this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
                const dateRef = this.dateRangeType === "rolling" || this.dateRangeType === "ytd" ?
                    this.dealerYearlySales.salesMonthly :
                    this.dealerYearlySales.sales;
                this.dates = Object.keys(dateRef).sort().reverse().slice(0, 3);
                this.sales = this.toAccordionSales(this.dealerYearlySales, this.dates);

                this.salesDone = true;
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    protected toAccordionSales(s: Sales, dates: string[]): MakeModelSales[] {
        return s.makes.map(make => this.toMakeModelSale(make, s, dates)) as MakeModelSales[];
    }

    protected toMakeModelSale(make: LineItemDetail, sales: Sales, dates: string[]): MakeModelSales {
        let dealerSales = {};
        if (Object.keys(make.salesMonthly).length > 0) {
            dealerSales = make.salesMonthly;
        } else {
            dealerSales = make.sales;
        }
        // Including this but adding (or isNaN(Number(key)). NaN was getting passed as zero when we didn't have data (which would show 0%).
        for (const key in dealerSales) {
            if (typeof dealerSales[key] === "string" || isNaN(Number(key))) {
                make.salesMonthly[key] = "n/a";
                make.sharesMonthly[key] = "n/a";
                make.sales[key] = "n/a";
                make.shares[key] = "n/a";
                for (const model in make.models) {
                    make.models[model].salesMonthly[key] = "n/a";
                    make.models[model].sharesMonthly[key] = "n/a";
                    make.models[model].sales[key] = "n/a";
                    make.models[model].shares[key] = "n/a";
                }
            }
        }
        return {
            label: make.make_description,
            rollups: dates.reduce((memo, d) => {
                memo[d] = {} as { shares?: any; sales?: any };
                if (this.type === "shares") {
                    memo[d].shares = (this.dateRangeType !== DateRangeTypes.YEARS ? make.sharesMonthly[d] : make.shares[d]) || 0;
                } else {
                    memo[d].sales = (this.dateRangeType !== DateRangeTypes.YEARS ? make.salesMonthly[d] : make.sales[d]) || 0;
                }
                return memo;
            }, {}) as SalesAccordionHeaderRollup,
            items: make.models.map(model => this.toAccordionSalesItem(model, make, dates)) as SalesAccordionSalesItem[]
        };
    }

    protected toAccordionSalesItem(model: ModelItemDetail, make: LineItemDetail, dates: string[]): SalesAccordionSalesItem {
        return {
            label: model.model_description,
            sales: dates.reduce((memo, date) => {
                memo[date] = {};
                if (this.type === "shares") {
                    memo[date].shares = (this.dateRangeType !== DateRangeTypes.YEARS ? model.sharesMonthly[date] : model.shares[date]) || 0;
                } else {
                    memo[date].sales = (this.dateRangeType !== DateRangeTypes.YEARS ? model.salesMonthly[date] : model.sales[date]) || 0;
                }
                return memo;
            }, {})
        } as SalesAccordionSalesItem;
    }

    protected formatDate(date: string): string {
        const min = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate : FilterName.minDate);
        const max = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate);
        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            const dateRanges: string[] = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            for (let i = 0; i < dateRanges.length; i++) {
                if (i % 2 === 0) {
                    continue;
                }
                if (dateRanges[i] === date) {
                    date = `${dateRanges[i - 1]}-${dateRanges[i]}`;
                    break;
                }
            }
        }
        return DateUtils.popUpDateRange(this.filterStateService.getFilterValue(FilterName.use_sales_data), date,
            this.dateRangeType, moment(min).toDate(), moment(max).toDate());
    }
}
