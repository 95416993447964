import { Component, OnDestroy, OnInit } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MarketMaxService } from "app/core/services/market-max.service";
import { OverlayService } from "app/core/services/overlay.service";
import { SalesRangeSaveService } from "app/core/services/sales-range-save.service";
import { SalesRangeValidationService } from "app/core/services/sales-range-validation.service";
import { Subscription } from "rxjs";

@Component({
    selector: "filter-sales-range-panel",
    templateUrl: "./filter-sales-range-panel.component.html",
    styleUrls: ["../filter-overlay-styles.scss", "./filter-sales-range-panel.component.scss"],
})
export class FilterSalesRangePanelComponent implements OnInit, OnDestroy {

    public loadedOptions = false;
    public marketMax = 1200;
    public filterName = FilterName.sales_range;
    public min: number;
    public max: number;
    filterStateSubscription: Subscription;

    constructor(
        private overlayService: OverlayService,
        private marketMaxService: MarketMaxService,
        private filterStateService: FilterStateService,
        private salesRangeValidationService: SalesRangeValidationService,
        private salesRangeSaveService: SalesRangeSaveService
    ) { }

    ngOnInit(): void {
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));

        this.loadMarketMax();

        const model = this.filterStateService.getSalesRangeValue();
        this.min = model.min;
        this.max = model.max;
    }

    ngOnDestroy(): void {
        if (this.isValid()) {
            this.salesRangeSaveService.save(this.min, this.max, this.filterName);
        }
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
    }

    handleKeyDown(event: KeyboardEvent): boolean {
        const validKeys = ["Tab", "Enter", "Home", "End", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const key = event.key;

        if (!validKeys.includes(key)) {
            return false;
        }

        // When the enter key is pressed,
        // close the panel. Save is triggered
        // on ngOnDestroy event, which is fired
        // when the panel is closed via
        // the overlay service.
        if (event.code === "13") {
            this.closePanel();
        }

        return true;
    }

    validateMin(): boolean {
        return this.salesRangeValidationService.validateMin(this.min, this.max);
    }

    validateMax(): boolean {
        return this.salesRangeValidationService.validateMax(this.min, this.max);
    }

    isValid(): boolean {
        return this.salesRangeValidationService.isValid(this.min, this.max);
    }

    private loadMarketMax(): void {
        this.loadedOptions = false;
        this.marketMaxService.getMarketMax()
            .then(marketMax => {
                this.marketMax = marketMax;
                this.loadedOptions = true;
            });
    }

    private filtersUpdated(which: string[]): void {
        if (which.includes(FilterName.dma.toString())) {
            this.closePanel();
            this.clearFilter();
        }

        if (this.shouldReload(which)) {
            this.loadMarketMax();
        }
    }

    private clearFilter(): void {
        this.min = null;
        this.max = null;
    }

    private shouldReload(which: string[]): boolean {
        return which.includes(FilterName.new_used_flag.toString())
            || which.includes(FilterName.dateRangeType.toString())
            || which.includes(FilterName.buyer_dma_code.toString());
    }

    public closePanel(): void {
        this.overlayService.hidePanel();
    }

}
