import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { DateUtils } from "@at/utils";
import { maxBy, memoize, omit, overEvery, remove, uniq, uniqBy } from "lodash";
import * as moment from "moment";
import * as hasher from "object-hash";
import { AsyncSubject, BehaviorSubject, EMPTY, Observable, Subscription, forkJoin, throwError as observableThrowError, of } from "rxjs";
import { flatMap, map } from "rxjs/operators";

import { AbstractSalesDataBase } from "../base/services/sales-data.abstract";
import { DealerSalesRangeFilterHelper } from "../helpers/dealer-sales-range-filter.helper";
import { DateRangeTypes } from "../models/date-range.enum";
import { DealerSales, DmaSales, Sales } from "../models/dealer-sales";
import { Dealer } from "../models/dealers.model";
import { FilterName } from "../models/filter-name.enum";
import { GroupBySales } from "../models/groupby-sales.model";
import { SalesMapping } from "../models/sales-mapping";
import { ZipVolume } from "../models/zip-volume.model";
import { ZipZone, ZipZoneSale, ZipZoneSales } from "../models/zip-zone.model";
import { DmaDataService } from "./dma-data.service";
import { FilterStateService } from "./filter-state.service";
import { MetadataService } from "./metadata.service";
import { UserCookieService } from "./user-cookie.service";
import { ZipZoneService } from "./zip-zone.service";

export interface SalesQueryOptions {
    dateRangeStart?: string; // YYYY or YYYYMM
    dateRangeEnd?: string;
    dateRangeType?: string;
    dateRanges?: any[];
    group?: string[];
    sort?: { [column: string]: string }; // sorting is accomplished by sending a object, with the key being the column to sort by, and the value being ASC or DESC. i.e {t201811_volume: DESC}
    limit?: number;
    offset?: number;
    optimizeDates?: boolean;
    filterType?: "filtered" | "all" | "exclude_dealers";
    not_dealers?: number[];
    unknown?: string;
    volume?: string;
}

export interface HeatmapScaleLevels {
    min: number;
    max: number;
}

@Injectable()
export class SalesDataService extends AbstractSalesDataBase implements OnDestroy {
    public readonly zipVolume = new BehaviorSubject<ZipVolume[]>([]);
    public readonly scaledZips = new BehaviorSubject<string[][]>([]);
    public readonly scaledZipLevels = new BehaviorSubject<HeatmapScaleLevels[]>([]);
    public heatmapLayerCount = 7;
    public zipZoneMode = false; // false = zip mode, true = zone mode
    volumeDetailKey = "volume";
    groupSize;
    protected dateGroupSales: Observable<any>[] = [];



    private filterStateSubscription: Subscription;
    private zipSalesDetailsSubscription: Subscription;
    private zipZoneSubscription: Subscription;
    private stream: Subscription;
    private salesDetailsSubscription: Subscription;
    private filteredZipVolumeSub: Subscription;

    constructor(
        protected http: HttpClient,
        protected filterStateService: FilterStateService,
        protected zipZoneService: ZipZoneService,
        protected dmaDataService: DmaDataService,
        protected metadataService: MetadataService,
        protected userCookieService: UserCookieService
    ) {
        super(filterStateService, http, metadataService, userCookieService);
    }

    ngOnDestroy(): void {
        if (this.zipSalesDetailsSubscription) {
            this.zipSalesDetailsSubscription.unsubscribe();
        }
        if (this.zipZoneSubscription) {
            this.zipZoneSubscription.unsubscribe();
        }
        if (this.salesDetailsSubscription) {
            this.salesDetailsSubscription.unsubscribe();
        }
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
        if (this.stream) {
            this.stream.unsubscribe();
        }
        if (this.salesDetailsSubscription) {
            this.salesDetailsSubscription.unsubscribe();
        }
        if (this.filteredZipVolumeSub) {
            this.filteredZipVolumeSub.unsubscribe();
        }
    }

    getRandomString(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    startMonitoringSales(): void {
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe((changed) => {
            this.salesCache.clear();
            if (!this.filterStateService.clientOnlyChanges(changed) || changed.includes("zip_zone_flag") || changed.includes("sales_area_zips") || changed.includes("include_spotlight") || changed.includes("use_sales_data")) {
                this.updateZipVolume();
            }
        });
        this.salesCache.clear();
        this.updateZipVolume();
    }

    stopMonitoringSales(): void {
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
            this.filterStateSubscription = null;
        }
    }

    async getZipZoneForDealersSales(
        dealers: number[],
        type: "zones" | "zips" = "zones",
        salesArea: string[] = [],
        filterOverrides: { [key: string]: any } = {},
        filterOutZeroSales: boolean = true
    ): Promise<ZipZoneSales[]> {
        return this.getSalesForDealersByZipZone(dealers, type, Object.assign({ area: salesArea }, filterOverrides), filterOutZeroSales, ["area"]).toPromise();
    }

    async getZipZoneSales(
        type: "zones" | "zips" = "zones",
        salesArea: string[] = [],
        filterOverrides: { [key: string]: any } = {},
        filterOutZeroSales: boolean = true
    ): Promise<ZipZoneSale[]> {
        return this.getSalesGroupByZip(type, Object.assign({ area: salesArea }, filterOverrides), filterOutZeroSales, ["area"]).toPromise();
    }

    // Any passed filter will override the global filter.
    // To remove a global filter pass null or an empty array as appropriate.
    getFilteredZipVolume(filterOverrides: { [key: string]: any } = {}): Observable<ZipVolume[]> {
        const filters: any = this.filterStateService.getCurrentApiFilters();

        if (!("dma" in filters && filters.dma !== "" && filters.dma !== undefined && filters.dma.length > 0)) {
            return EMPTY;
        }

        Object.assign(filters, filterOverrides);

        if (this.shouldFilter(filters)) {
            filters[FilterName.dealers.toString()] = this.userCookieService.getSalesRangeFilteredDealers();
        }

        delete filters[FilterName.sales_range.toString()];

        const url = `${this.rootUrl}/sales/zipvolume`;
        if (this.filterStateService.getFilterValue(FilterName.use_sales_data)) {
            filters["use_sales_data"] = "true";
        }

        const options = this.createRequestOptions(filters);
        return this.fetchInterfaces<ZipVolume>(url, options);
    }

    private shouldFilter(filters: Object): boolean {
        return (!filters[FilterName.dealers.toString()] || filters[FilterName.dealers.toString()].length < 1)
            && filters[FilterName.sales_range.toString()];
    }

    getZipSalesDetails(zips: string[], excludedFilters?: string[], dateStart?: string, dateEnd?: string): Observable<any[]> {
        let filters;
        const dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
        if (this.filterStateService.getFilterValue(FilterName.result_data_filtered) === "filtered") {
            filters = this.filterStateService.getCurrentApiFilters("zips");

            if (DealerSalesRangeFilterHelper.shouldApplySalesRangeDealers(filters)) {
                filters[FilterName.dealers.toString()] = this.userCookieService.getSalesRangeFilteredDealers();
            }
        } else if (this.filterStateService.getFilterValue(FilterName.result_data_filtered) === "exclude_dealers") {
            filters = this.filterStateService.getCurrentApiFilters("zips", "dealers");
        } else {
            filters = this.filterStateService.getCurrentApiFilters("dealers", "segments", "makes", "models", "zips");
        }

        if (dateStart && dateStart.length > 0 && dateEnd && dateEnd.length) {
            filters.dateRangeStart = dateStart;
            filters.dateRangeEnd = dateEnd;
        }

        filters["zips"] = zips.slice();
        filters["group"] = "dealer_id";

        if (excludedFilters) {
            filters = omit<any>(filters, excludedFilters);
        }

        if (!("dma" in filters && filters["dma"] !== "" && filters["dma"] !== undefined)) {
            return observableThrowError("no mandatory filter of dma supplied");
        }
        const url = `${this.rootUrl}/sales/volume`;
        if (this.filterStateService.getFilterValue(FilterName.use_sales_data)) {
            filters["use_sales_data"] = "true";
        }

        const options = this.createRequestOptions(filters);
        return this.fetchInterfaces<Dealer>(url, options);
    }

    updateZipVolume(): void {
        this.zipVolume.next([]); // empty it out, mapcomponent will render empty heatmap, and then update later
        const dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        const dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
        const dateGroupObservers: Observable<any>[] = [];


        if (dateRangeType === DateRangeTypes.MONTHS) {
            if (dateRanges && dateRanges.length >= 5) {
                const dateGroups = DateUtils.generateCustomDateGroupings(dateRanges);
                dateGroups.forEach(group => {
                    dateGroupObservers.push(this.getFilteredZipVolume({ dateRangeStart: group.start, dateRangeEnd: group.end }));

                });
                // Clean out old data stream.
                if (this.stream) {
                    this.stream.unsubscribe();
                    this.stream = undefined;
                }
                this.stream = forkJoin(dateGroupObservers).subscribe(dateResults => {
                    const zipResults = [];
                    // for each dategroup merge zip data
                    dateResults.forEach(dateGroup => {
                        dateGroup.forEach(zipData => {
                            const targetZip = zipResults.find(zip => zip.zip === zipData.zip);

                            if (targetZip) {
                                targetZip.volume += zipData.volume;
                            } else {
                                zipResults.push(Object.assign({}, zipData));
                            }
                        });


                    });
                    if (this.filterStateService.getFilterValue(FilterName.zip_zone_flag) === "zone") {
                        this.calculateZoneVolumes(zipResults);
                    } else {
                        this.volumeDetailKey = "volume";
                        this.zipZoneMode = false;
                        this.zipVolume.next(zipResults);
                        this.updateHeatmapScalings(zipResults);
                    }
                });
            }
        } else {
            this.filteredZipVolumeSub = this.getFilteredZipVolume().subscribe((volumes) => {
                if (this.filterStateService.getFilterValue(FilterName.zip_zone_flag) === "zone") {
                    this.calculateZoneVolumes(volumes);
                } else {
                    this.volumeDetailKey = "volume";
                    this.zipZoneMode = false;
                    this.zipVolume.next(volumes);
                    this.updateHeatmapScalings(volumes);
                }
            });
        }
    }

    getZoneVolumes(volumes: ZipVolume[]): Observable<ZipVolume[]> {
        const containedZips = volumes.map((zipVolume) => zipVolume.zip);

        return this.zipZoneService.getZoneDetailsByZips(containedZips)
            .pipe(map((zipZoneMappings: ZipZone[]): ZipVolume[] => {
                if (zipZoneMappings.length) {
                    const groupedZipsByZones = this.zipZoneService.getGroupedZipsByZones(containedZips);
                    groupedZipsByZones.forEach((groupedZipsByZone) => { // iterate through each zone
                        let zoneVolume = 0;
                        groupedZipsByZone.forEach((zip) => { // iterate through each zip of a zone & determine the zone volume
                            zoneVolume += volumes.find((zv) => zv.zip === zip).volume;
                        });
                        groupedZipsByZone.forEach((zip) => { // iterate through each zip of a zone & update volume with zone volume
                            volumes.find((zv) => zv.zip === zip).adZoneVolume = zoneVolume;
                        });
                    });
                }

                return volumes;
            }));
    }

    calculateZoneVolumes(volumes: ZipVolume[]): void {
        this.volumeDetailKey = "adZoneVolume";
        this.zipZoneMode = true;

        this.zipZoneSubscription = this.getZoneVolumes(volumes).subscribe((volumesWithZone) => {
            this.zipVolume.next(volumesWithZone);
            this.updateHeatmapScalings(volumesWithZone);
        });
    }

    updateHeatmapScalings(volumes: ZipVolume[]): void {
        const zips = this.volumeDetailKey === "volume";
        const result = this.getHeatmapScalings(volumes, zips);
        this.scaledZips.next(result.scaledZips);
        this.scaledZipLevels.next(result.scaledZipLevels);
    }

    getHeatmapScalings(volumes: ZipVolume[], zips: boolean = true): { scaledZips: string[][]; scaledZipLevels: HeatmapScaleLevels[] } {
        const volumeDetailKey = zips ? "volume" : "adZoneVolume";

        if (!(volumes && volumes.length)) {
            return { scaledZips: new Array(this.heatmapLayerCount).fill([]), scaledZipLevels: [] };
        }

        const maxVolume = (maxBy(volumes, volumeDetailKey))[volumeDetailKey];
        const scaledZipLevels: HeatmapScaleLevels[] = [];
        let smallDataSet = false;
        if (maxVolume < 7) {
            smallDataSet = true;
            this.heatmapLayerCount = maxVolume;
            scaledZipLevels.push({ min: 0, max: 0 });
        } else {
            smallDataSet = false;
            this.heatmapLayerCount = 7;
        }
        for (let j = 1; j < this.heatmapLayerCount; j++) {
            // equally sized interval buckets
            let threshold = (j * maxVolume) / (this.heatmapLayerCount - 1);
            if (j !== (this.heatmapLayerCount - 1)) {
                threshold = Math.floor(threshold);
            } else {
                threshold = Math.ceil(threshold);
            }
            let minScale = j === 1 ? 1 : (scaledZipLevels[scaledZipLevels.length - 1].max + 1); // previous max + 1
            scaledZipLevels.push({ min: minScale, max: threshold });
        }

        this.scaledZipLevels.next(scaledZipLevels);

        const scaledZipSet: string[][] = []; // array index = heatmap level
        if (!smallDataSet) {
            scaledZipSet.push(volumes.filter((volume) => volume[volumeDetailKey] === 0).map(volume => volume.zip)); // set all 0 sales to the lowest heatmap level
            for (let i = 0; i < this.heatmapLayerCount - 1; i++) {
                const scaledZipsLevel = volumes.filter((volume) => (volume[volumeDetailKey] >= scaledZipLevels[i].min && volume[volumeDetailKey] <= scaledZipLevels[i].max));
                scaledZipSet.push(scaledZipsLevel.map((scaledZip) => scaledZip.zip));
            }
        } else {
            scaledZipSet.push(volumes.filter((volume) => volume[volumeDetailKey] === 0).map(volume => volume.zip)); // set all 0 sales to the lowest heatmap level
            for (let i = 0; i < this.heatmapLayerCount; i++) {
                const scaledZipsLevel = volumes.filter((volume) => (volume[volumeDetailKey] === scaledZipLevels[i].min && volume[volumeDetailKey] === scaledZipLevels[i].max ||
                    volume[volumeDetailKey] >= scaledZipLevels[i].min) && volume[volumeDetailKey] <= scaledZipLevels[i].max);
                scaledZipSet.push(scaledZipsLevel.map((scaledZip) => scaledZip.zip));
            }
            for (let i = 1; i < (7 - this.heatmapLayerCount); i++) {
                scaledZipSet.push([]);
            }
        }
        return { scaledZips: scaledZipSet, scaledZipLevels };
    }

    getHeatmapScalingsForDealer(volumes: ZipZoneSale[], zips: boolean = true): { scaledZips: string[][]; scaledZipLevels: HeatmapScaleLevels[] } {

        if (!(volumes && volumes.length)) {
            return { scaledZips: new Array(this.heatmapLayerCount).fill([]), scaledZipLevels: [] };
        }

        const maxVolume = (maxBy(volumes, "sales"))["sales"];
        const scaledZipLevels: HeatmapScaleLevels[] = [];
        let smallDataSet = false;

        if (maxVolume < 7) {
            smallDataSet = true;
            this.heatmapLayerCount = maxVolume;
            scaledZipLevels.push({ min: 0, max: 0 });
        } else {
            smallDataSet = false;
            this.heatmapLayerCount = 7;
        }

        for (let j = 1; j < this.heatmapLayerCount; j++) {
            // equally sized interval buckets
            let threshold = (j * maxVolume) / (this.heatmapLayerCount - 1);
            if (j !== (this.heatmapLayerCount - 1)) {
                threshold = Math.floor(threshold);
            } else {
                threshold = Math.ceil(threshold);
            }
            let minScale = j === 1 ? 1 : (scaledZipLevels[scaledZipLevels.length - 1].max + 1); // previous max + 1
            scaledZipLevels.push({ min: minScale, max: threshold });
        }

        this.scaledZipLevels.next(scaledZipLevels);

        const scaledZipSet: string[][] = []; // array index = heatmap level
        if (!smallDataSet) {
            scaledZipSet.push(volumes.filter((volume) => volume["sales"] === 0).map(volume => volume.location)); // set all 0 sales to the lowest heatmap level
            for (let i = 0; i < this.heatmapLayerCount - 1; i++) {
                const scaledZipsLevel = volumes.filter((volume) => (volume["sales"] >= scaledZipLevels[i].min && volume["sales"] <= scaledZipLevels[i].max));
                scaledZipSet.push(scaledZipsLevel.map((scaledZip) => scaledZip.location));
            }
        } else {
            scaledZipSet.push(volumes.filter((volume) => volume["sales"] === 0).map(volume => volume.location)); // set all 0 sales to the lowest heatmap level
            for (let i = 0; i < this.heatmapLayerCount; i++) {
                const scaledZipsLevel = volumes.filter((volume) => (volume["sales"] === scaledZipLevels[i].min && volume["sales"] === scaledZipLevels[i].max));
                scaledZipSet.push(scaledZipsLevel.map((scaledZip) => scaledZip.location));
            }
            for (let i = 1; i < (7 - this.heatmapLayerCount); i++) {
                scaledZipSet.push([]);
            }
        }

        return { scaledZips: scaledZipSet, scaledZipLevels };
    }

    // Return all sales for all selected dma.
    getTotalSales(salesQueryOptions: SalesQueryOptions = {}, uncached: boolean = false): DmaSales {
        salesQueryOptions = this.createDefaultSalesQueryOptions(salesQueryOptions);
        salesQueryOptions.group = ["make", "model"];

        // const dmaSales = uncached ? this.salesCache.createUncachedDmaSales(0) : this.salesCache.getDmaSales(0) || this.salesCache.createDmaSales(0);
        if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
            salesQueryOptions["use_sales_data"] = "true";
        }
        const hash = hasher({ dma_id: 0, query: salesQueryOptions });
        const dmaSales = uncached ? this.salesCache.createUncachedDmaSales(hash, 0) : this.salesCache.getDmaSales(hash) || this.salesCache.createDmaSales(hash, 0);
        if (salesQueryOptions.optimizeDates) {
            this.optimizeDates([dmaSales], salesQueryOptions);
        }

        if (!(salesQueryOptions.dateRangeStart && salesQueryOptions.dateRangeEnd)) {
            dmaSales.loaded.next(false);
        } else if (!((salesQueryOptions.dateRangeStart in dmaSales.sales || salesQueryOptions.dateRangeStart in dmaSales.salesMonthly) &&
            (salesQueryOptions.dateRangeEnd in dmaSales.sales || salesQueryOptions.dateRangeEnd in dmaSales.salesMonthly))) {
            dmaSales.loaded.next(false);
        }

        if (!dmaSales.loaded.value) {
            this.salesCache.addDmaSalesSubscription(dmaSales, this.getSalesDetails([], salesQueryOptions, { dealers: [] }).subscribe((results) => {
                const cleanedResults = results.map((result) => this.cleanKeys(result, SalesMapping));
                this.salesCache.processSales(dmaSales, cleanedResults, dmaSales.dma_id);
            }));
        }
        return dmaSales;
    }

    getNationalTotalSales(salesQueryOptions: SalesQueryOptions = {}, uncached: boolean = false): DmaSales {
        salesQueryOptions = this.createDefaultSalesQueryOptions(salesQueryOptions);
        salesQueryOptions.group = ["make"];
        // hash all dmas for key
        // const dmaSales = uncached ? this.salesCache.createUncachedDmaSales(0) : this.salesCache.getDmaSales(0) || this.salesCache.createDmaSales(0);

        if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
            salesQueryOptions["use_sales_data"] = "true";
        }
        const hash = hasher({ dma_id: 0, query: salesQueryOptions });
        const dmaSales = uncached ? this.salesCache.createUncachedDmaSales(hash, 0) : this.salesCache.getDmaSales(hash) || this.salesCache.createDmaSales(hash, 0);
        if (salesQueryOptions.optimizeDates) {
            this.optimizeDates([dmaSales], salesQueryOptions);
        }

        if (!(salesQueryOptions.dateRangeStart && salesQueryOptions.dateRangeEnd)) {
            dmaSales.loaded.next(false);
        } else if (!((salesQueryOptions.dateRangeStart in dmaSales.sales || salesQueryOptions.dateRangeStart in dmaSales.salesMonthly) &&
            (salesQueryOptions.dateRangeEnd in dmaSales.sales || salesQueryOptions.dateRangeEnd in dmaSales.salesMonthly))) {
            dmaSales.loaded.next(false);
        }

        if (!dmaSales.loaded.value) {
            this.salesCache.addDmaSalesSubscription(dmaSales, this.getNationalSalesDetails(salesQueryOptions).subscribe((results) => {
                const cleanedResults = results.map((result) => this.cleanKeys(result, SalesMapping));
                this.salesCache.processSales(dmaSales, cleanedResults, dmaSales.dma_id);
            }));
        }
        return dmaSales;
    }

    // Returns aggregate sales for selected dma based on sales from buyers within selected dma.
    getGroupByDmaBuyerSales(
        filterValues: any[] = [],
        salesQueryOptions: SalesQueryOptions,
        filterOverrides: { [key: string]: any } = {}
    ): Observable<GroupBySales[]> {
        // The total for the dma is all of the sales bought by people in that dma.
        if (!salesQueryOptions.group) {
            salesQueryOptions.group = [FilterName.buyer_dma_code.toString()];
        } else if (!salesQueryOptions.group.find(g => g === FilterName.buyer_dma_code.toString())) {
            salesQueryOptions.group.push(FilterName.buyer_dma_code.toString());
        }
        return this.getSalesDetails(filterValues, salesQueryOptions, filterOverrides, FilterName.buyer_dma_code).pipe(flatMap((results) => {
            const groupBySalesData = this.processGroupBySalesByDate(results, FilterName.buyer_dma_code);
            return of(groupBySalesData);
        }));
    }

    getSalesForDealer(dealer: Dealer, salesQueryOptions: SalesQueryOptions = {}): DealerSales {
        return this.getSalesForDealers([dealer], salesQueryOptions)[0];
    }

    getSalesForDealers(dealers: Dealer[], salesQueryOptions: SalesQueryOptions = {}): DealerSales[] {
        const dealerSales = [];
        const dealerVolumeRequestQueue: number[] = [];
        const dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        if (this.filterBaseService.getFilterValue(FilterName.use_sales_data)) {
            salesQueryOptions["use_sales_data"] = "true";
        }
        let needData = false;
        for (let i = 0; i < dealers.length; i++) {
            const queryHash = hasher({ dealer_id: dealers[i].dealer_id, query: salesQueryOptions });
            const found = this.getDealerSalesFromCache(dealers[i].dealer_id, queryHash, salesQueryOptions);
            dealerSales.push(found);
            if (!found.loaded.value) {
                dealerVolumeRequestQueue.push(found.dealer_id);
                needData = true;
            }
        }
        if (needData) {
            if (salesQueryOptions.optimizeDates) {
                this.optimizeDates(dealerSales, salesQueryOptions);
            }
            // Get unique dmaIds to fetch total sales for.
            const dmaIds = uniq(dealers.map(d => d.dealer_dma_code));
            const dmasSales = this.getTotalSalesForDmas(dmaIds, salesQueryOptions);
            dmaIds.forEach(dmaId => {
                const dealerRequestForDma = dealerVolumeRequestQueue.filter(id => {
                    const dealer = dealers.find(d => d.dealer_id === id);
                    return dealer.dealer_dma_code === dmaId;
                });
                const dmaHash = dmasSales.find(x => x.dma_id === dmaId).hash;
                // SRAUTO-2452 Sales data will be compared to it's respective DMA.
                // Narrow down DMA to limit results to just the dealer's sales.
                this.salesDetailsSubscription = this.getSalesDetails(dealerRequestForDma, salesQueryOptions, { dma: [dmaId] }).subscribe((results) => {
                    const resultsByDealer = {};
                    for (let i = 0; i < dealerRequestForDma.length; i++) {
                        resultsByDealer[dealerRequestForDma[i]] = [];
                    }
                    let headerRow;
                    results.forEach((result: any) => {
                        const cleanedResult = this.cleanKeys(result, SalesMapping);
                        if (cleanedResult.dealer_id === null) {
                            headerRow = cleanedResult;
                        } else if (resultsByDealer[cleanedResult.dealer_id]) {
                            resultsByDealer[cleanedResult.dealer_id].push(cleanedResult);
                        } else {
                            resultsByDealer[cleanedResult.dealer_id] = [cleanedResult];
                        }
                    });
                    for (let i = 0; i < dealers.length; i++) {
                        const dealerResults = resultsByDealer[dealers[i].dealer_id] as Sales[];
                        // If dealer data was previously loaded, it won't be in the dealerResults map
                        // and does not need to be processed.
                        if (!dealerResults) {
                            continue;
                        }
                        // Add header row as the first item within the sales data.
                        dealerResults.unshift(headerRow);
                        const queryHash = hasher({ dealer_id: dealers[i].dealer_id, query: salesQueryOptions });

                        const dys = this.getDealerSalesFromCache(dealers[i].dealer_id, queryHash, salesQueryOptions);
                        this.salesCache.processSales(dys, dealerResults, dmaId, dmaHash);
                    }
                });

            });
        }
        return dealerSales;
    }

    getSalesForDealersByZipZone(
        dealers: number[],
        type: "zones" | "zips" = "zones",
        filterOverrides: { [key: string]: any } = {},
        filterOutZeroSales: boolean = true,
        selects: string[] = [],
        yoy: boolean = false,
    ): Observable<ZipZoneSales[]> {

        const params = {
            group: ["dealer_id", "buyer_dma_code"],
            filterType: "filtered",
            select: selects
        } as SalesQueryOptions;

        let selector: string;
        if (type.toString() === "zones") {
            selector = "ncc_name"; // ad zone name
            // Adding sys_code to have the value returned in the data set.
            // sys_code and ncc_name are equivalent and therefore should not change the results set.
            params.group.push("sys_code");
        } else {
            selector = "buyer_zip"; // zip code name
        }

        params.group.unshift(selector);
        return this.getSalesDetails(dealers, params, filterOverrides).pipe(map((results) => {
            results = results.filter(result => result.dealer_id !== null); // remove header row
            const latestKey = this.getLatestVolumeKey(results);
            const dateRangeType = filterOverrides.dateRangeType || this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
            const dealerSales = filterOutZeroSales && dateRangeType !== DateRangeTypes.MONTHS ?
                results.filter((item) => !!(item[latestKey] && item[latestKey] !== "0")) : // remove items with no related sales
                results;

            // If no sales remain, return a empty dataset.
            if (!dealerSales.length) {
                return [];
            }

            const locationTotals = {};
            const formattedResults = [];
            // list of zips
            const uniqueLocations = uniqBy(dealerSales, selector).map(result => result[selector]);

            for (let i = 0; i < dealers.length; i++) {
                const dealer = dealers[i];
                const formatted = {
                    dealer_id: dealer,
                    // for each zip
                    sales: uniqueLocations.reduce((memo: any[], location: string) => {
                        const entries = remove(dealerSales, ds => ds[selector] === location && ds.dealer_id === dealer);
                        let dealerLocationTotal = 0;
                        const formattedData = [];
                        for (let j = 0; j < entries.length; j++) {
                            const cleanedLocation = type === "zones" ? location.replace("Spectrum/", "") : location;
                            let undefinedLocation = false;
                            const entry = entries[j];
                            let key = cleanedLocation;
                            const dmaName = this.dmaDataService.getShortDmaName(entry.buyer_dma_code);
                            const locationWithDmaSuffix = `${cleanedLocation} (${dmaName})`;
                            if (cleanedLocation === "Undefined") {
                                key = locationWithDmaSuffix;
                                undefinedLocation = true;
                            }
                            let delta;
                            if (dateRangeType === DateRangeTypes.MONTHS) {
                                this.setupCustomDateRanges(yoy);
                                if (this.groupSize) {
                                    // if custom groups merge data and parse
                                    delta = this.sumGroup(Object.values<number>(Object.keys(entry).filter(prop => prop.endsWith("volume")).sort((a, b) => parseInt(a.substring(1, 7), 10) - parseInt(b.substring(1, 7), 10)).map(prop => entry[prop])).reverse());
                                } else {
                                    delta = entry[latestKey];
                                }
                                if (!filterOutZeroSales || delta !== 0) {
                                    dealerLocationTotal += delta;
                                    locationTotals[key] = locationTotals[key] ? locationTotals[key] + delta : delta;
                                }
                            } else {
                                delta = entry[latestKey];
                                dealerLocationTotal += delta;
                                locationTotals[key] = locationTotals[key] ? locationTotals[key] + delta : delta;
                            }

                            const locationData = {
                                location: cleanedLocation,
                                locationWithDma: locationWithDmaSuffix,
                                dma: entry.buyer_dma_code,
                                dmaName,
                                undefinedLocation,
                                sales: delta,
                                area: entry.area
                            } as ZipZoneSale;

                            if (type.toString() === "zones") {
                                locationData.sys_code = entry.sys_code;
                            }

                            formattedData.push(locationData);
                        }
                        if (formattedData[0]) {
                            if (formattedData[0].undefinedLocation) {
                                memo = memo.concat(formattedData); // add full list of undefined location sales
                            } else {
                                formattedData[0].sales = dealerLocationTotal;
                                memo.push(formattedData[0]);
                            }
                        }
                        return memo;
                    }, [])
                };
                formattedResults.push(formatted);
            }
            const locationOrder = Object.keys(locationTotals).sort((a, b) =>
                // Order the locations from highest to lowest in terms of current year sales volumes.
                // If volumes are the same, sort by location name alphabetically.
                locationTotals[b] === locationTotals[a] ?
                    a.localeCompare(b) :
                    locationTotals[b] - locationTotals[a]
            );
            formattedResults.forEach(fs => fs.sales.sort((a, b) => {
                const locationA = a.undefinedLocation ? a.locationWithDma : a.location;
                const locationB = b.undefinedLocation ? b.locationWithDma : b.location;
                return locationOrder.indexOf(locationA) - locationOrder.indexOf(locationB);
            })); // sort dealer sales

            // DATA checker const summed = formattedResults[0].sales.reduce((a, b) => a + b.sales, 0);
            return formattedResults;
        }));
    }

    getSalesGroupByZip(
        type: "zones" | "zips" = "zones",
        filterOverrides: { [key: string]: any } = {},
        filterOutZeroSales: boolean = true,
        selects: string[] = [],
        yoy: boolean = false,
    ): Observable<ZipZoneSale[]> {
        const params = {
            group: ["dealer_id", "buyer_dma_code"],
            filterType: "filtered",
            select: selects
        } as SalesQueryOptions;

        let selector: string;
        if (type.toString() === "zones") {
            selector = "ncc_name"; // ad zone name
            // Adding sys_code to have the value returned in the data set.
            // sys_code and ncc_name are equivalent and therefore should not change the results set.
            params.group.push("sys_code");
        } else {
            selector = "buyer_zip"; // zip code name
        }

        params.group.unshift(selector);
        return this.getSalesDetails(undefined, params, filterOverrides).pipe(map((results) => {
            results = results.filter(result => result.dealer_id !== null); // remove header row
            const latestKey = this.getLatestVolumeKey(results);
            const dateRangeType = filterOverrides.dateRangeType || this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
            const dealerSales = filterOutZeroSales && dateRangeType !== DateRangeTypes.MONTHS ?
                results.filter((item) => !!(item[latestKey] && item[latestKey] !== "0")) : // remove items with no related sales
                results;

            // If no sales remain, return a empty dataset.
            if (!dealerSales.length) {
                return [];
            }

            const locationTotals = {};
            const formattedResults = [];
            // list of zips
            const uniqueLocations = uniqBy(dealerSales, selector).map(result => result[selector]);

            dealerSales.forEach((dealer) => {
                let dealerLocationTotal = 0;
                let delta;
                let undefinedLocation = false;
                let location: string = dealer.buyer_zip;
                const dmaName = this.dmaDataService.getShortDmaName(dealer.buyer_dma_code);
                const cleanedLocation = type === "zones" ? location.replace("Spectrum/", "") : location;
                let key = cleanedLocation;
                const locationWithDmaSuffix = `${cleanedLocation} (${dmaName})`;
                key = locationWithDmaSuffix;

                if (dateRangeType === DateRangeTypes.MONTHS) {
                    this.setupCustomDateRanges(yoy);
                    if (this.groupSize) {
                        // if custom groups merge data and parse
                        delta = this.sumGroup(Object.values<number>(Object.keys(dealer).filter(prop => prop.endsWith("volume")).sort((a, b) => parseInt(a.substring(1, 7), 10) - parseInt(b.substring(1, 7), 10)).map(prop => dealer[prop])).reverse());
                        dealerLocationTotal += delta;
                        locationTotals[key] = locationTotals[key] ? locationTotals[key] + delta : delta;
                    } else {
                        delta = dealer[latestKey];
                        dealerLocationTotal += dealer[latestKey];
                        locationTotals[key] = locationTotals[key] ? locationTotals[key] + dealer[latestKey] : dealer[latestKey];
                    }
                    if (!filterOutZeroSales || delta !== 0) {
                        dealerLocationTotal += delta;
                        locationTotals[key] = locationTotals[key] ? locationTotals[key] + delta : delta;
                    }
                } else {
                    delta = dealer[latestKey];
                    dealerLocationTotal += delta;
                    locationTotals[key] = locationTotals[key] ? locationTotals[key] + delta : delta;
                }

                const formatted = {
                    location: dealer.buyer_zip,
                    locationWithDma: dealer.buyer_zip + " (" + dmaName + ")",
                    dma: dealer.buyer_dma_code,
                    dmaName,
                    undefinedLocation,
                    sales: delta,
                    area: dealer.area
                };

                const existingDealer = formattedResults.find((entry) => entry.location === formatted.location);

                if (existingDealer) {
                    existingDealer.sales += formatted.sales;
                } else {
                    formattedResults.push(formatted);
                }
            });

            formattedResults.sort((a, b) => a.sales > b.sales ? -1 : 1);

            return formattedResults;
        }));
    }

    setupCustomDateRanges(yoy: boolean): void {
        const dateRange = this.filterStateService.getFilterValue(FilterName.dateRanges);
        const minDate = yoy ? dateRange[4] : dateRange[0];
        const maxDate = dateRange[1];
        // calculate grouping
        const dateEnd = moment(maxDate, "YYYYMM");
        const dateStart = moment(minDate, "YYYYMM");

        this.groupSize = dateEnd.diff(dateStart, "months");
    }

    sumGroup(sales: any[]): any {
        const groupCount = this.groupSize + 1;
        const groupedSales = [];

        if (sales.length === 0) {
            return 0;
        }

        while (sales.length > 0) {
            groupedSales.push(sales.splice(0, groupCount));
            sales.splice(0, 12 - groupCount);
        }
        return groupedSales.map(
            group => group.reduce(
                (a, b) => a + b
            )
        )[0];


        // let groupCount = 0;

        // if (sales.length === 0) {
        //     return 0;
        // }

        // sales.forEach(sale => {
        //     groupCount += sale;
        // });
        // return groupCount;

    }

    getSalesTotalsZones(zones?: string[], filterOverrides: { [key: string]: any } = {}, groupByOverride?: string[]): Observable<ZipZoneSale[]> {
        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        const params = {
            group: groupByOverride ? groupByOverride : ["sys_code", "ncc_name", "dma"],
            filterType: "filtered",
            dateRangeType: this.filterStateService.getFilterValue(FilterName.dateRangeType),
        } as SalesQueryOptions;

        const overrides = Object.assign({}, filterOverrides);
        if (zones) {
            overrides.zones = zones;
        }

        const salesDetails = this.getSalesDetails([], params, overrides).pipe(map(sales => {
            // Remove the first entry which is a header metadata row (all values are null).
            sales.splice(0, 1);
            const latestKey = this.getLatestVolumeKey(sales);
            return sales.map(s => {
                // Slice off "Spectrum/" suffix for ncc_name.
                const zoneName = s.ncc_name.replace("Spectrum/", "") || "Audience Extension";
                const dmaName = this.dmaDataService.getShortDmaName(s.dealer_dma_code);

                const formattedData = {
                    sys_code: s.sys_code,
                    sales: s[latestKey] || 0,
                    dma: s.dealer_dma_code,
                    dmaName,
                    location: zoneName,
                    locationWithDma: `${zoneName} (${dmaName})`,
                    undefinedLocation: zoneName === "Audience Extension"
                };

                return formattedData;
            }) as ZipZoneSale[];
        }));

        return salesDetails;
    }

    // A clone of getSalesTotalsZones with grouping by sales by the buyer_dma_code.
    // This aggregates a more correct number of sales for the dma.
    getBuyerSalesForZones(zones: string[], filterOverrides: { [key: string]: any } = {}, yoy: boolean = false, groupByOverride?: string[]): Observable<ZipZoneSale[]> {
        const apiFilters = this.filterStateService.getCurrentApiFilters();
        const dateRangeType = apiFilters["dateRangeType"] ? apiFilters["dateRangeType"] : this.filterStateService.getFilterValue(FilterName.dateRangeType);
        const dateRangeStart = dateRangeType === "months" && apiFilters["dateRanges"] ? apiFilters["dateRanges"][0] : "";
        const dateRangeEnd = dateRangeType === "months" && apiFilters["dateRanges"] ? apiFilters["dateRanges"][1] : "";

        const params = {
            group: groupByOverride ?? ["sys_code", "ncc_name", "buyer_dma_code"],
            filterType: "filtered",
            dateRangeType,
            dateRangeStart,
            dateRangeEnd
        } as SalesQueryOptions;

        const overrides = Object.assign({}, filterOverrides);
        if (zones) {
            overrides.zones = zones;
        }

        return this.getSalesDetails([], params, overrides).pipe(map(sales => {
            // Remove the first entry which is a header metadata row (all values are null).
            sales.splice(0, 1);
            const latestKey = this.getLatestVolumeKey(sales);

            return sales.map((s, i) => {
                // Slice off "Spectrum/" suffix for ncc_name.
                const zoneName = s.ncc_name.replace("Spectrum/", "") || "Audience Extension";
                const dmaName = this.dmaDataService.getShortDmaName(s.buyer_dma_code);
                let salesGroupSum = sales[i][latestKey];

                if (dateRangeType === DateRangeTypes.MONTHS) {
                    this.setupCustomDateRanges(yoy);
                    if (this.groupSize) {
                        // if custom groups merge data and parse
                        salesGroupSum = this.sumGroup(Object.values<number>(Object.keys(sales[i]).filter(prop => prop.endsWith("volume")).sort((a, b) => parseInt(a.substring(1, 7), 10) - parseInt(b.substring(1, 7), 10)).map(prop => sales[i][prop])).reverse());
                    }
                }
                return {
                    sys_code: s.sys_code,
                    sales: salesGroupSum || 0,
                    dma: s.buyer_dma_code,
                    dmaName,
                    location: zoneName,
                    locationWithDma: `${zoneName} (${dmaName})`,
                    undefinedLocation: zoneName === "Audience Extension"
                };
            }) as ZipZoneSale[];
        }));
    }

    getDetailsForZips(zips: string[], dateStart?: string, dateEnd?: string): Observable<DealerSales[]> {
        const zipSalesDetails = new AsyncSubject<DealerSales[]>();
        let locationSalesTotalSub;
        let zipSalesSub;

        // volume min/max should never apply to the zip/zone popup, so exclude them from these calls
        if (dateStart && dateStart.length > 0 && dateEnd && dateEnd.length > 0) {
            locationSalesTotalSub = this.getZipSalesDetails(zips, ["group", "dealer", "volume"], dateStart, dateEnd); // zip/zone sales total, used for zip/zone share percentage calculation
            zipSalesSub = this.getZipSalesDetails(zips, ["volume"], dateStart, dateEnd); // zip/zone dealer sales
        } else {
            locationSalesTotalSub = this.getZipSalesDetails(zips, ["group", "dealer", "volume", "buyer_dma_code"]); // zip/zone sales total, used for zip/zone share percentage calculation
            zipSalesSub = this.getZipSalesDetails(zips, ["volume", "buyer_dma_code"]); // zip/zone dealer sales
        }
        forkJoin([zipSalesSub, locationSalesTotalSub]).subscribe((results) => {
            const zipZoneDealersSales: any = results[0];
            const zipZoneSalesTotal = results[1];
            const volumeKeys = this.extractVolumeKeys(zipZoneDealersSales);
            const dealerSalesList = [];
            const type = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            zipZoneDealersSales.forEach((result) => {
                if (!result.dealer_id) {
                    return; // skip the header row
                }
                const dealerSales = this.salesCache.createUncachedDealerSales(this.getRandomString(), result.dealer_id);
                // Rolling daterangetype uses a yearmonth format and thus uses the precedent of saleMonthly.
                const salesContainer = type === "rolling" || type === "ytd" || type === "months" ? dealerSales.salesMonthly : dealerSales.sales;
                const sharesContainer = type === "rolling" || type === "ytd" || type === "months" ? dealerSales.sharesMonthly : dealerSales.shares;
                for (let i = 0; i < volumeKeys.length; i++) {
                    const volumeKey = volumeKeys[i];
                    const date = this.extractDate(volumeKey);
                    salesContainer[date] = result[volumeKey];
                    sharesContainer[date] = ((result[volumeKey] / zipZoneSalesTotal[1][volumeKey]) * 100) || 0; // skipping zipZoneSalesTotal[0] as it is a header row
                }
                dealerSales.loaded.next(true);
                dealerSalesList.push(dealerSales);
            });
            this.sortDealersByVolume(dealerSalesList);
            zipSalesDetails.next(dealerSalesList);
            zipSalesDetails.complete();
        });
        return zipSalesDetails;
    }

    protected getLatestVolumeKey(salesData: any[]): string {
        const volumeKeys = this.extractVolumeKeys(salesData);
        const latestDate = Math.max(...volumeKeys.map((key) => parseInt(this.extractDate(key), 10)));
        return volumeKeys.find((key) => key.includes(latestDate.toString()));
    }

    protected sortDealersByVolume(dealersSales: DealerSales[]): void {

        const getTotal = memoize((d: DealerSales): number => {
            const sales = Object.values(d.sales) as number[];
            return sales.reduce((total, value) => total + value, 0);
        });

        dealersSales.sort((a, b) => {
            const value = getTotal(b) - getTotal(a);
            if (value < 0) {
                return -1;
            }
            if (value > 0) {
                return 1;
            }
            return 0;
        });
    }

    protected getDealerSalesFromCache(dealer_id: number, hash: string, salesQueryOptions: SalesQueryOptions): DealerSales {
        let dealerSales = this.salesCache.getDealerSales(hash);
        if (!dealerSales) {
            dealerSales = this.salesCache.createDealerSales(hash, dealer_id);
        }

        if (!(salesQueryOptions.dateRangeStart && salesQueryOptions.dateRangeEnd)) {
            dealerSales.loaded.next(false);
        } else if (!((salesQueryOptions.dateRangeStart in dealerSales.sales || salesQueryOptions.dateRangeStart in dealerSales.salesMonthly) &&
            (salesQueryOptions.dateRangeEnd in dealerSales.sales || salesQueryOptions.dateRangeEnd in dealerSales.salesMonthly))) {
            dealerSales.loaded.next(false);
        }

        return dealerSales;
    }
}
