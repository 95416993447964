<div class="curtain"></div>
<div class="zip-upload-modal" *ngIf="!hasErrors">
    <div class="confirm-header">
        <div class="confirm-header-container">
            <div class="text">Custom Zip Codes Upload</div>
            <div class="close" (click)="cancelUpload($event)"></div>
        </div>
    </div>
    <drag-and-drop-file-upload (zipsUploaded)="zipsUploadedHandler($event)"></drag-and-drop-file-upload>
    <div class="file-uploaded" *ngIf="zipsContainer">
        <div class="custom-zip-file">Custom Zip File:</div>
        <div class="file-name">{{filename}}</div>
        <div class="icon" (click)="clearUpload()"></div>
    </div>
    <div class="footer">
        <div class="preformatted-download"><a class="download" href="assets/files/Pre_Defined_PMA_File.xlsx"
                download="Pre_Defined_PMA_File.xlsx">Download</a> a preformated file to avoid errors.</div>
        <div class="buttons">
            <div class="cancel" (click)="cancelUpload($event)">Cancel</div>
            <kite-button buttonSize="lg" (click)="clickZipUpload($event)">Upload</kite-button>
        </div>
    </div>
</div>
<div class="zip-upload-error-modal" *ngIf="hasErrors">
    <div class="confirm-header">
        <div class="confirm-header-container">
            <div class="icon"></div>
            <div class="text">Upload Formatting Error</div>
            <div class="close" (click)="cancelUpload($event)"></div>
        </div>
    </div>
    <div class="modal-body">
        <div class="modal-body-header">Please see your excel file for formatting errors at the following rows:</div>
        <div class="modal-body-error-rows">{{errors}}</div>
        <div class="modal-body-footer">You may <b>upload as is</b> without fixing the errors or choose to <b>cancel this
                upload</b>, fix the errors and reupload the file.</div>
    </div>
    <div class="footer">
        <div class="buttons">
            <div class="cancel" (click)="cancelUpload($event)">Cancel This Upload</div>
            <kite-button buttonSize="lg" (click)="clickZipUpload()">Upload As Is</kite-button>
        </div>
    </div>
</div>
<div class="zip-upload-error-modal" *ngIf="hasErrors && uploadFail">
    <div class="confirm-header">
        <div class="confirm-header-container">
            <div class="icon"></div>
            <div class="text">Upload Formatting Error</div>
            <div class="close" (click)="failUpload($event)"></div>
        </div>
    </div>
    <div class="modal-body">
        <div class="modal-body-header">Please see your excel file for formatting errors at the following rows:</div>
        <div class="modal-body-error-rows">{{errors}}</div>
        <div class="modal-body-footer">Your first row and column of your excel file of custom zips must not be empty.
            Please adjust your zips file, and try again later.</div>
    </div>
    <div class="footer">
        <div class="buttons">
            <kite-button buttonSize="lg" (click)="failUpload($event)">Close</kite-button>
        </div>
    </div>
</div>
