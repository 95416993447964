import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { PopupDirective } from "../components/directives/popup.directive";

@Injectable()
export class PopupService {
    currentPopup: PopupDirective;
    public readonly opened = new Subject<PopupDirective>();
    public readonly closed = new Subject<void>();
    block = false;

    popupOpened(popup: PopupDirective): void {
        if (this.currentPopup !== popup && !this.block) {
            if (this.currentPopup) {
                this.currentPopup.closePopup();
            }
            this.currentPopup = popup;
            this.opened.next(popup);
        }
    }

    popupClosed(): void {
        this.currentPopup = null;
        this.closed.next();
    }

    getOpenPopup(): PopupDirective {
        return this.currentPopup;
    }

    closePopup(): void {
        if (this.currentPopup) {
            this.currentPopup.closePopup();
        }
    }

    blockPopup(): void {
        this.closePopup();
        this.block = true;
    }

    unblockPopup(): void {
        this.block = false;
    }

    checkBlock(): boolean {
        return this.block;
    }
}
