import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "DisplayPercentage" })
export class DisplayPercentagePipe implements PipeTransform {
    static staticTransform(value: string): string {
        // If value is not a number, it is a placeholder value e.g. dash for non-existing value.
        if (isNaN(Number(value))) {
            return "n/a" ;
        }
        if(typeof Number(value) ==="number"){
            return value+"%";
        }
    }

    transform(value: string): string {
        return DisplayPercentagePipe.staticTransform(value);
    }
}
