<nav class="navbar box-shadow fixed-top">
  <a class="navbar-item navbar-brand" routerLink="/home">
    <div class="image"></div>
  </a>
  <ul class="navbar-item navbar-nav">
    <li class="nav-item" *ngIf="showNavItems">
      <a routerLinkActive="active" class="nav-link map" routerLink="/map">Map View</a>
    </li>

    <li class="nav-item" *ngIf="showNavItems">
      <a routerLinkActive="active" class="nav-link trends" routerLink="/trends">Trends</a>
    </li>
    <li class="nav-item" *ngIf="showNavItems">
      <a routerLinkActive="active" class="nav-link insights" routerLink="/insights">Geo Insights</a>
    </li>
    <li class="nav-item" *ngIf="showNavItems && canViewComposite">
      <a routerLinkActive="active" class="nav-button presentation" routerLink="/presentation/composite">Create PowerPoint</a>
    </li>
  </ul>
  <div class="navbar-item navbar-right">
    <dma-selection *ngIf="showNavItems"></dma-selection>
  
    <div id="appcues-launchpad"></div>

    <button class="settings-menu" (click)="openMenu()">
      <img class="icon" src="/assets/icons/sprocket.png"/>
    </button>
  </div>
</nav>
