import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { AsyncSubject, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { HttpServiceBase } from "../base/services/http-service-base.service";
import { FilterName } from "../models/filter-name.enum";
import { ZipZone } from "../models/zip-zone.model";
import { DmaDataService } from "./dma-data.service";
import { NgrxFilterStateService } from "./ngrx-filter-state.service";

@Injectable()
export class ZipZoneService extends HttpServiceBase implements OnDestroy {
    private zipZonesCache = {};
    private zoneDetailsSub: Subscription;

    constructor(protected http: HttpClient,
        protected dmaDataService: DmaDataService,
        protected ngrxFilterStateService: NgrxFilterStateService,) {
        super(http);
    }

    ngOnDestroy(): void {
        if (this.zoneDetailsSub) {
            this.zoneDetailsSub.unsubscribe();
        }
    }

    getZoneDetails(locations: string[], zone: boolean = false): Observable<ZipZone[]> {
        const filters = {};
        if (zone) {
            filters["ncc_name"] = locations.map(location => "Spectrum/" + location);
        } else {
            filters["zip"] = locations;
        }
        if (this.ngrxFilterStateService.getFilter(FilterName.use_sales_data)) {
            filters["use_sales_data"] = "true";
        }

        const url = `${this.rootUrl}/zones/details`;
        const options = this.createRequestOptions(filters);
        return this.fetchInterfaces<ZipZone>(url, options);
    }

    getZoneDetailsByZips(zips: string[]): Observable<ZipZone[]> {
        const result = new AsyncSubject<ZipZone[]>();

        const loadedZones: ZipZone[] = [];
        const notloadedZones: string[] = [];

        zips.forEach((zip) => {
            if (this.zipZonesCache[zip]) {
                loadedZones.push(this.zipZonesCache[zip]);
                return;
            }
            notloadedZones.push(zip);
        });

        if (notloadedZones.length === 0) {
            setTimeout(() => {
                result.next(loadedZones);
                result.complete();
            }, 0);
        } else {
            this.zoneDetailsSub = this.getZoneDetails(notloadedZones).subscribe((results) => {
                const processedResults = loadedZones.concat(this.processData(results));
                result.next(processedResults);
                result.complete();
            });
        }
        return result;
    }

    processData(results: any[]): ZipZone[] {
        const loadedZones = [];
        results.forEach((returnedResult) => {
            const newZipZone = this.createZipZone();
            newZipZone.zip = returnedResult["zip"];
            newZipZone.dmas.push(returnedResult["dma_code"]);
            if (returnedResult["ncc_name"]) {
                newZipZone.ad_zone = returnedResult["ncc_name"].replace("Spectrum/", "");
            } else {
                newZipZone.ad_zone = "Audience Extension in " + this.dmaDataService.getDmaNames(Array.from(newZipZone.dmas, dma => Number(dma))).join(", ") + " DMA";
            }
            loadedZones.push(newZipZone);
            this.zipZonesCache[newZipZone.zip] = newZipZone;
        });
        return loadedZones;
    }

    createZipZone(): ZipZone {
        return {
            zip: "",
            dmas: [],
            ad_zone: ""
        } as ZipZone;
    }

    // Takes an array of zips and return a 2d array of grouped zips
    // e.g ["64563", "12345", "3463"] => [["64563", "12345"], ["3463"]].
    getGroupedZipsByZones(zips: string[]): string[][] {
        const groupedZips = {};
        zips.forEach((zip) => {
            const currentZone = this.zipZonesCache[zip].ad_zone;
            if (groupedZips[currentZone]) {
                groupedZips[currentZone].push(zip);
                return;
            }
            groupedZips[currentZone] = [zip];
        });
        return Object.values(groupedZips);
    }

    // Takes a zip and return all zips for the zone in which the given zip belongs to
    // e.g. "12345" => ["12345", "12346","12347"].
    getAllZipsInZoneByZip(zip: string): string[] {
        const allZoneZips = [];
        const adZone = this.zipZonesCache[zip].ad_zone;
        Object.keys(this.zipZonesCache).forEach((key) => {
            if (this.zipZonesCache[key].ad_zone === adZone) {
                allZoneZips.push(this.zipZonesCache[key].zip);
            }
        });
        return allZoneZips;
    }

    // Takes a array of zones and returns all zips for the given zones
    // e.g. ["zone-1", "zone-2"] => [["12345", "12346", "12347"], ["54321", "54322"]]
    getZonesZips(zones: string[]): Observable<string[][]> {
        return this.getZoneDetails(zones, true).pipe(map((results) => {
            const returnedResults = [];
            const returnedZipZoneResults = this.processData(results);
            zones.forEach(zone => {
                returnedResults.push(returnedZipZoneResults
                    .filter(zipZoneResult => zipZoneResult.ad_zone === zone)
                    .map(ZoneZips => ZoneZips.zip)
                );
            });
            return returnedResults;
        }));
    }

    async getZipsForZonesByZips(zips: string[]): Promise<string[][]> {
        const zoneZips = {};
        const fetch = [];
        for (let i = 0; i < zips.length; i++) {
            if (this.zipZonesCache[zips[i]]) {
                zoneZips[this.zipZonesCache[zips[i]].ad_zone] = [];
            } else {
                fetch.push(zips[i]);
            }
        }

        const promise = fetch.length ? this.getZoneDetailsByZips(fetch).toPromise() : Promise.resolve();
        return (promise as Promise<void | ZipZone[]>).then(() => {
            // All of the requested zips should have be populated in the cache.
            for (let i = 0; i < fetch.length; i++) {
                if (!zoneZips[this.zipZonesCache[fetch[i]].ad_zone]) {
                    zoneZips[this.zipZonesCache[fetch[i]].ad_zone] = [];
                }
            }

            const allZips = Object.values(this.zipZonesCache) as ZipZone[];
            for (let i = 0; i < allZips.length; i++) {
                const zipZone = allZips[i];
                if (zoneZips[zipZone.ad_zone]) {
                    zoneZips[zipZone.ad_zone].push(zipZone.zip);
                }
            }
            return Object.values(zoneZips) as string[][];
        });
    }
}
