import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { PasswordPolicy } from "app/core/models/password-policy.model";
import { CognitoService } from "app/core/services/cognito.service";

@Component({
    selector: "user-change-password",
    templateUrl: "./user-change-password.html",
    styleUrls: ["./user-change-password.component.scss"]
})
export class UserChangePasswordComponent implements OnInit {
    @Output() closePasswordChange = new EventEmitter();

    oldPasswordValue: string;
    newPasswordValue: string;
    confirmNewPasswordValue: string;

    oldPasswordError = "";
    newPasswordError = "";
    confirmNewPasswordError = "";

    success = false;

    passwordPolicies: PasswordPolicy[]; // this is used in the html

    constructor(private cognitoService: CognitoService) { }

    ngOnInit(): void {
        this.passwordPolicies = this.cognitoService.getPasswordPolicies();
    }

    checkPasswordPolicy(password: string, policy: PasswordPolicy): object {
        return this.cognitoService.checkPasswordPolicy(password, policy);
    }

    checkPasswordMatch(password: string, confirmPassword: string): object {
        return this.cognitoService.checkPasswordMatch(password, confirmPassword);
    }

    verifyPasswordPolicies(password: string, confirmPassword: string = ""): boolean {
        return this.cognitoService.verifyPasswordPolicies(password, confirmPassword);
    }

    validateInputs(): boolean {
        if (!this.oldPasswordValue) {
            this.oldPasswordError = "Enter the current password.";
        } else {
            if (this.oldPasswordError) {
                this.oldPasswordError = "";
            }
        }
        if (!this.newPasswordValue) {
            this.newPasswordError = "Enter a new password.";
        } else {
            if (this.newPasswordError) {
                this.newPasswordError = "";
            }
        }
        if (!this.confirmNewPasswordValue) {
            this.confirmNewPasswordError = "Re-enter the new password.";
        } else if (this.newPasswordValue !== this.confirmNewPasswordValue) {
            this.confirmNewPasswordError = "Passwords must match";
        } else {
            if (this.confirmNewPasswordError) {
                this.confirmNewPasswordError = "";
            }

            for (let i = 0; i < this.passwordPolicies.length; i++) {
                const checkedPolicy = this.checkPasswordPolicy(this.newPasswordValue, this.passwordPolicies[i]);
                if (!checkedPolicy["policy-passed"]) {
                    this.newPasswordError = "Invalid password";
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    cancel(): void {
        this.closePasswordChange.emit();
    }

    submit(): void {
        const isValid = this.validateInputs();
        if (!isValid) {
            return;
        }
        this.cognitoService.changePassword(this.oldPasswordValue, this.newPasswordValue)
            .then(result => {
                this.success = true;
                setTimeout(() => {
                    this.closePasswordChange.emit();
                }, 2000);
            })
            .catch(err => {
                // try limit reached
                if (err.code === "LimitExceededException") {
                    this.newPasswordError = "Limit reached, Please try again later";
                } else if (err.code === "NotAuthorizedException") {
                    // Incorrect old password.
                    this.oldPasswordError = "Invalid password";
                } else if (err.code === "InvalidParameterException") {
                    const errMessages = err.message.split(":");
                    // First item is a general validation error count.
                    for (let i = 1; i < errMessages.length; i++) {
                        if (errMessages[i].indexOf("previousPassword") !== -1 && errMessages[i + 1] !== undefined) {
                            this.oldPasswordError = "Invalid password";
                        }
                        if (errMessages[i].indexOf("proposedPassword") !== -1 && errMessages[i + 1] !== undefined) {
                            this.newPasswordError = "Invalid password";
                        }
                    }
                }
            });
    }
}
