import { Observable } from "rxjs";

import { FilterName } from "../models/filter-name.enum";

export class DealerSalesRangeFilterHelper {
    // We need to determine which filters to use.
    // If we have a sales range and no dealers use the sales range to limit filter options.
    // otherwise ignore the sales range filters.
    public static handleSalesRangeFilters<T>(filters: object, useSalesRange: () => Observable<T[]>, ignoreSalesRange: (filters: object) => Observable<T[]>): Observable<T[]> {
        if (this.shouldApplySalesRangeDealers(filters)) {
            return useSalesRange();
        } else {
            delete filters[FilterName.sales_range.toString()];
            return ignoreSalesRange(filters);
        }
    }

    public static shouldApplySalesRangeDealers(filters: object): boolean {
        return !!filters[FilterName.sales_range.toString()]
            && !this.hasDealerFilters(filters);
    }

    private static hasDealerFilters(filters: any): boolean {
        return !!filters[FilterName.dealers.toString()] && filters[FilterName.dealers.toString()].length > 0;
    }
}
