import { HttpClient } from "@angular/common/http";
import { Directive, Injectable } from "@angular/core";
import { Dictionary, cloneDeep, compact, flattenDeep, omit, uniq } from "lodash";
import { Observable, of } from "rxjs";
import { map, skipWhile, take } from "rxjs/operators";

import { FilterBaseService } from "../base/services/filter-base.service";
import { FilterName } from "../models/filter-name.enum";
import { FilterDefaults } from "../models/filter.model";
import { SpotlightCompetitors, SpotlightSalesAreas } from "../models/spotlight.enum";
import { CognitoService } from "./cognito.service";
import { NgrxFilterStateService } from "./ngrx-filter-state.service";
import { UserCookieService } from "./user-cookie.service";

export interface FilterStateFilters extends FilterDefaults {
    not_dealers: number[];
    dates: {};
    minDate: string;
    maxDate: string;
    maxSalesDate: string;
    minSalesDate: string;
    models: number[];
    zips: string[];
    zip_zone_flag: string;
    dealer_zip: string[];
    not_dealer_zip: string[];
    sales_area_zips: string[];
    competitors_selection: string;
    sales_area_selection: string;
    spotlight_dealer: number;
    include_spotlight: string;
    result_data_filtered: string;
    ring_radius: number;
    show_ring: boolean;
    show_volume: boolean;
    use_sales_data: boolean;
}

@Directive()
@Injectable()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FilterStateService extends FilterBaseService {
    filterDefaults: FilterStateFilters = {
        dateRangeType: "rolling",
        dateRanges: [],
        minDate: "201509",
        maxDate: "201709",
        maxSalesDate: "",
        minSalesDate: "",
        dma: [517], // dma id for Charlotte
        dealers: [],
        not_dealers: [],
        makes: [],
        dates: {},
        models: [],
        segments: [],
        ihs_segments: [],
        use_ihs_segments: false,
        use_sales_data: false,
        zips: [],
        zones: [],
        new_used_flag: 0, // 0 = new
        buyer_dma_code: [517], // exclude outside dma by default
        zip_zone_flag: "zip",
        dealer_zip: [],
        not_dealer_zip: [],
        sales_area_zips: [],
        spotlight_dealer: null,
        competitors_selection: SpotlightCompetitors.All,
        sales_area_selection: SpotlightSalesAreas.All,
        include_spotlight: "include",
        result_data_filtered: "filtered",
        ring_radius: 5,
        show_ring: false,
        show_volume: false,
        top_zones_names: [],
        top_zones_sys_codes: [],
        volume: null,
        geosearch: false,
        top_competitors: [],
        top_zips: null,
        top_zones: null,
        selected_dealer: null,
    };

    resettableFilters = ["dateRangeType", "new_used_flag", "dealers", "makes", "dates", "models", "segments", "ihs_segments", "zips", "zones", "radius_ring", "volume", "spotlight_dealer", "minDate", "maxDate",];
    clientOnlyFilters = ["type", "result_data_filtered", "zip_zone_flag", "include_spotlight", "sales_area_zips", "use_ihs_segments", "spotlight_dealer", "competitors_selection", "sales_area_selection", "ring_radius", "show_ring", "show_volume", "display_range", "minDate", "maxDate"];
    arrayFilters = ["dma", "dealers", "not_dealers", "makes", "dates", "models", "segments", "zips", "zones", "dealer_zip", "not_dealer_zip", "sales_area_zips"];

    cookiePrefix = "filter";

    constructor(
        protected http: HttpClient,
        protected cognitoService: CognitoService,
        protected userCookieService: UserCookieService,
        protected ngrxFilterStateService: NgrxFilterStateService
    ) {
        super(http, userCookieService, ngrxFilterStateService);
        this.cognitoService.authenticated.pipe(skipWhile(i => !i), take(1)).subscribe(this.setupCurrentFilters.bind(this));
    }

    getRecentDma(): number[] {
        const recent = (this.userCookieService.getStoredValue("dma", this.arrayFilters, "recent") || [this.filterDefaults.dma]) as any[];
        return compact(flattenDeep(recent));
    }

    setRecentDma(dmaList: number[]): void {
        this.userCookieService.setStoredValue("dma", dmaList, "recent");
    }

    getCurrentApiFilters(exclude: (string | string[]) = null, ...rest: string[]): object {
        let filters = this.applySpotlightDealerFilters(this.ngrxFilterStateService.getCurrentFilterState());
        const excludedFilters = [].concat(exclude, rest).concat(this.clientOnlyFilters.slice());
        filters = omit<any>(filters, excludedFilters);
        filters = this.adjustForSalesAreaFilters(filters);
        return filters;
    }

    // This functions very similarly to 'getFilterOptions', but it does not call 'getCurrentApiFilters'
    // which calls 'adjustForSalesAreaFilters'. Calling 'getFilterOptions' with FilterName.zips would filter
    // the returned full list of zips to the set 'sales_area_zips' which is undesirable when trying to get
    // the full list of zip options.
    getFullFilterOptions(filterName: FilterName, excludedFilters: string[] = []): Observable<any[]> {
        let filters = this.applySpotlightDealerFilters(this.ngrxFilterStateService.getCurrentFilterState());
        const excludes = excludedFilters.concat(this.clientOnlyFilters.slice());
        excludes.push(filterName.toString());
        filters = omit<any>(filters, excludedFilters);
        filters["filtername"] = filterName.toString();
        return this.fetchFilterNames(filterName, filters);
    }

    getFilterDma(): number[] {
        return uniq(this.ngrxFilterStateService.getFilter(FilterName.dma));
    }

    adjustForSalesAreaFilters(filters: Dictionary<{}>): Dictionary<{}> {
        const salesAreaZips = this.ngrxFilterStateService.getFilter(FilterName.sales_area_zips);
        if (salesAreaZips && salesAreaZips.length) {
            if (filters.zips && (filters["zips"] as string[]).length) { // if zip codes filters are present, then remove any that are not in the current sales area
                filters.zips = (filters["zips"] as string[]).filter((zip) => salesAreaZips.includes(zip));
            }
            if (!(filters.zips && (filters["zips"] as string[]).length)) { // if there are no zip codes present, then fill with the current sales area
                filters.zips = salesAreaZips.slice();
            }
        }

        return filters;
    }

    isMultiDma(): boolean {
        return this.ngrxFilterStateService.getFilter(FilterName.dma).length > 1;
    }

    verifySpotlightDealerValidity(): Observable<boolean> {
        const spotlightDealerId = this.ngrxFilterStateService.getFilter(FilterName.spotlight_dealer);
        if (!!!spotlightDealerId) { // if there is no spotlighted dealer Id then it is definitely not validated
            return of(false);
        }

        return this.getFilterOptions(FilterName.dealers, [spotlightDealerId], [FilterName.not_dealers.toString()]).pipe(
            map(spotlightData => !!spotlightData.length));
    }

    private applySpotlightDealerFilters(filters: any): any {
        const spotlit = this.ngrxFilterStateService.getFilter(FilterName.spotlight_dealer);
        if (spotlit) {
            filters = cloneDeep(filters); // deep copy
            if (this.getFilterValue(FilterName.include_spotlight) === "exclude") {
                filters["not_dealers"] = [spotlit];
                filters["dealers"] = uniq(filters["dealers"]);
            } else {
                if ("dealers" in filters && filters["dealers"].length > 0) {
                    filters["dealers"].push(spotlit);
                    filters["dealers"] = uniq(filters["dealers"]);
                }
            }
        }
        return filters;
    }
}
