import { Chart } from "chart.js";

import { ChartComponent } from "./chart.component";

function formatLabel(labels: string[], chart: ChartComponent, segmentCount: number): string[][] {
    if (!chart) {
        return [];
    }

    // Chart width - approximate layout offsets / # of segments.
    const chartSegmentWidth = ((chart.elementRef.nativeElement.offsetWidth || 1200) - 300) / segmentCount;

    let context: CanvasRenderingContext2D;
    if (chart.ng2Chart) {
        context = chart.ng2Chart.chart.canvas.getContext("2d");
    } else {
        // If ng2Chart is not loaded yet, use a generic canvas.
        context = document.createElement("canvas").getContext("2d");
        context.font = `${Chart.defaults.global.defaultFontSize}px Arial`;
    }

    return labels.reduce((m, l) => {
        const stringWidth = Math.floor(context.measureText(l).width);
        const lines = [];
        let line = "";

        if (stringWidth < chartSegmentWidth) {
            line = l;
        } else {
            const split = l.split(/([a-zA-Z,()]+)/);
            let currentWidth = 0;
            for (let i = 0; i < split.length; i++) {
                const current = split[i];
                // Only process non-empty strings.
                if (current.length) {
                    const subStrWidth = Math.floor(context.measureText(current).width);
                    if (currentWidth + subStrWidth < chartSegmentWidth) {
                        line += current;
                        currentWidth += subStrWidth;
                    } else {
                        lines.push(line);
                        line = current;
                        currentWidth = subStrWidth;
                    }
                }
            }
        }

        // Push final line.
        lines.push(line);

        // There must be at least two array items.
        if (lines.length < 2) {
            lines.push("");
        }

        m.push(lines);
        return m;
    }, []);
}

export const ChartComponentUtils = { formatLabel };
