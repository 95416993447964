import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FeatureFlagService, MetadataService } from "@at/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { DealerSales, LineItemDetail, Sales } from "app/core/models/dealer-sales";
import { FilterName } from "app/core/models/filter-name.enum";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService, SalesQueryOptions } from "app/core/services/sales-data.service";
import { assign, debounce } from "lodash";
import * as moment from "moment";
import { Subscription, forkJoin } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { DateRange, DateShiftComponent } from "../../charts/date-shift/date-shift.component";

@Component({
    selector: "market-make-sales-card",
    templateUrl: "./market-make-sales-card.component.html",
    styleUrls: ["./market-make-sales-card.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class MarketMakeSalesCardComponent implements OnInit, OnDestroy {
    @ViewChild(DateShiftComponent, { static: true }) dateShiftComponent: DateShiftComponent;
    @Input() cardOpenState = true;

    scrolled = false;
    filterStateServiceSubscription: Subscription;
    dmaSubscription: Subscription;
    salesData: DealerSales;
    nationalSalesData: Sales;
    dateRange: DateRange;
    limitToDisplay = 2;
    stepInterval = 1;
    displayYears: string[];
    minStartDate: string;
    dmaNames: string;
    maxPastYear = 2015;
    useSalesData: boolean;

    showVolume = false;
    columnSpan = 1; // for reduced columns in the table

    sortKey = "";
    sortDirection: "asc" | "desc" = "desc";
    selectedMake = "";

    loading = true;

    public dateRangeType = DateRangeTypes.ROLLING12;

    constructor(
        private salesDataService: SalesDataService,
        private filterStateService: FilterStateService,
        private dmaDataService: DmaDataService,
        private metadataService: MetadataService
    ) {
        this.updateCardData = debounce(this.updateCardData.bind(this), 40);
    }

    ngOnInit(): void {
        this.metadataService.metadata.pipe(skipWhile((i => !i || !i.maxDate)), take(1)).subscribe(metadata => {
            this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.updateSettings.bind(this));
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.minStartDate = this.useSalesData ? metadata.minSalesDate : metadata.minDate;
            this.dateRange = {
                start: this.minStartDate,
                end: this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate),
            };
        });

        this.updateSettings([FilterName.dma.toString(), FilterName.dateRangeType.toString(), FilterName.show_volume.toString(), FilterName.minDate.toString(), FilterName.maxDate.toString()]); // initialize filter variables

        this.dmaSubscription = this.dmaDataService.allDmasLoaded.subscribe({
            complete: () => {
                this.updateDmaNames();
            }
        });
        this.dmaDataService.loadAllDmas();
    }

    ngOnDestroy(): void {
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
        }
        if (this.dmaSubscription) {
            this.dmaSubscription.unsubscribe();
        }
    }

    updateSettings(changes: string[]): void {
        let update = false;

        if (changes.includes("dma") || changes.includes("new_used_flag") || changes.includes("buyer_dma_code")) {
            update = true;
        }

        if (changes.includes("dateRangeType") || changes.includes("dateRanges") || changes.includes("use_sales_data")) {
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
            let rangeDifference: number;
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            let maxDate = "";
            maxDate = this.useSalesData
                ? this.filterStateService.getFilterValue(FilterName.maxSalesDate)
                : this.filterStateService.getFilterValue(FilterName.maxDate);
            if (maxDate === "") {
                return;
            }

            if (this.dateRangeType === "rolling" || this.dateRangeType === "ytd") {
                this.stepInterval = 12;
                this.limitToDisplay = 24;
                const formatStr = this.useSalesData ? "YYYYMMDD" : "YYYYMM";
                this.dateRange = {
                    start: moment(maxDate).subtract(this.limitToDisplay, "months").format(formatStr),
                    end: maxDate,
                };
            } else if (this.dateRangeType === "months") {
                const dateRanges: string[] = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
                if (dateRanges !== undefined && dateRanges.length >= 1) {
                    rangeDifference = parseInt(dateRanges[1].substr(4, 2), 10);
                    const selectedYear = parseInt(dateRanges[1].substr(0, 4), 10);
                    const yearDiff = selectedYear - this.maxPastYear;

                    this.dateRange = {
                        start: DateUtils.addYear(dateRanges[0], -(yearDiff)),
                        end: dateRanges[1],
                    };
                    this.stepInterval = rangeDifference;
                    this.limitToDisplay = 24;
                } else {
                    this.stepInterval = 12;
                    this.limitToDisplay = 24;
                }
            } else if (this.dateRangeType === "calendar") {
                this.stepInterval = 1;
                this.limitToDisplay = 2;
                this.dateRange = {
                    start: moment(maxDate).subtract(this.limitToDisplay, "years").year().toString(),
                    end: moment(maxDate).year().toString(),
                };
            } else {
                this.stepInterval = 1;
                this.limitToDisplay = 2;
            }
            // Since updateCardData is debounced, show loading ahead of time to cover up
            // empty data set during the debounced period.
            if ((changes.includes("use_sales_data")) || (changes.includes("dateRangeType") && changes.includes("dateRanges"))) {
                this.loading = true;
                update = true;
            } else if (changes.includes("dateRangeType")) {
                this.loading = true;
                update = false;
            } else {
                this.loading = true;
                update = true;
            }
        }

        if (changes.includes(FilterName.show_volume.toString())) {
            this.showVolume = this.filterStateService.getFilterValue<boolean>(FilterName.show_volume);
            this.columnSpan = this.showVolume ? 1 : 2;
        }

        if (update) {
            this.updateCardData();
        }
    }

    // captures data update requests from date-shift
    updateDate(dateRange: DateRange): void {
        this.dateRange = { start: dateRange.start, end: dateRange.end };
        this.updateCardData();
    }

    updateOpen(event: Event): void {
        this.cardOpenState = event !== undefined;
        this.updateCardData();
    }

    updateCardData(): void {
        if (!this.cardOpenState) {
            return;
        }

        if (!this.dateRange?.start || !this.dateRange?.end || this.dateRange.start === "" || this.dateRange.end === "") {
            return;
        }

        this.updateDmaNames();

        this.loading = true;
        this.displayYears = this.getDisplayYears();
        this.sortKey = this.displayYears[0].toString();

        this.minStartDate = this.dateShiftComponent.minDate;

        const params = {
            dateRangeEnd: this.dateRange.end,
            dateRangeStart: this.getStartDate(this.dateRange.end),
            dateRangeType: this.dateRangeType,
            optimizeDates: false,
            filterType: "all"
        } as SalesQueryOptions;

        this.loadSalesData(params);
    }

    private loadSalesData(params: SalesQueryOptions): void {
        this.salesData = this.salesDataService.getTotalSales(params, true) as unknown as DealerSales;
        this.nationalSalesData = this.salesDataService.getNationalTotalSales(params, true);
        forkJoin([this.salesData.loaded, this.nationalSalesData.loaded]).subscribe(_ => {
            this.mergeNationalAndDMASales();
            this.loading = false;
        });
        this.nationalSalesData.loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
            this.nationalSalesData.loaded.complete();
        });
        this.salesData.loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
            this.salesData.makes = this.getSortedMakes();
            this.salesData.loaded.complete();
        });
    }

    private mergeNationalAndDMASales(): void {
        this.salesData?.makes.forEach((dmaSalesMake) => {
            const nationalMakeSales = this.nationalSalesData.makes.find((nationalSalesMake) => nationalSalesMake.make_description.toLowerCase() === dmaSalesMake.make_description.toLowerCase());
            dmaSalesMake.nationalSales = nationalMakeSales?.sales;
            dmaSalesMake.nationalSalesMonthly = nationalMakeSales?.salesMonthly;
            this.aggregateNationalMonthlySales(nationalMakeSales);
            this.aggregateNationalSales(nationalMakeSales);
        });
    }

    private aggregateNationalSales(nationalMakeSales: LineItemDetail): void {
        for (const key in nationalMakeSales?.sales) {
            if (!this.salesData.nationalSales) {
                this.salesData.nationalSales = {};
            }
            if (!this.salesData.nationalSales[key]) {
                this.salesData.nationalSales[key] = nationalMakeSales.sales[key];
            } else {
                this.salesData.nationalSales[key] += nationalMakeSales.sales[key];
            }
        }
    }

    private aggregateNationalMonthlySales(nationalMakeSales: LineItemDetail): void {
        for (const key in nationalMakeSales?.salesMonthly) {
            if (!this.salesData.nationalSalesMonthly) {
                this.salesData.nationalSalesMonthly = {};
            }
            if (!this.salesData.nationalSalesMonthly[key]) {
                this.salesData.nationalSalesMonthly[key] = nationalMakeSales.salesMonthly[key];
            } else {
                this.salesData.nationalSalesMonthly[key] += nationalMakeSales.salesMonthly[key];
            }
        }
    }

    updateDmaNames(): void {
        const dmaIds = this.filterStateService.getFilterDma();
        const dmaNames = [];
        dmaIds.forEach((dmaId) => {
            dmaNames.push(this.dmaDataService.getDmaName(Number(dmaId)));
        });
        this.dmaNames = dmaNames.join(", ");
    }

    protected getDisplayYears(): string[] {
        const dates = [];
        let ptr = this.dateRange.end;
        // if not a custom date range just push the first date and
        // run the while loop to build the date labels
        if (this.dateRangeType !== "months") {
            dates.push(ptr);
            while (ptr > (ptr.length >= 8 ? this.dateRange.start + "01" : this.dateRange.start)) {
                if (this.dateRangeType === "rolling" || this.dateRangeType === "ytd") {
                    ptr = DateUtils.monthYearAddMonths(ptr, -this.stepInterval);
                    if (this.useSalesData) {
                        ptr = DateUtils.appendLastDayOfMonth(ptr);
                    }
                } else if (this.dateRangeType === "calendar") {
                    ptr = DateUtils.addYear(ptr, -1);
                } else {
                    ptr = DateUtils.addYear(ptr, -this.stepInterval);
                }
                dates.push(ptr);
            }
            // If a custom date range is supplied, fetch the date ranges
            // and build the date labels from the filter values
        } else if (this.dateRangeType === "months") {
            const dateRanges: string[] = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            if (dateRanges) {
                for (let i = 0; i < dateRanges.length; i++) {
                    // all odd indices will return 0
                    // we start building the date range with the odds
                    if (i % 2 === 0) {
                        ptr = dateRanges[i];
                        // all even indices will return 1
                        // we finish building a date range string with the evens
                    } else if (i % 2 === 1) {
                        ptr = ptr + " - " + dateRanges[i];
                        dates.push(ptr);
                    }
                }
            }
        }
        return dates;
    }

    dataTableScrolled(event: Event): void {
        const scrollTop = (event.target as HTMLElement).scrollTop;
        this.scrolled = (scrollTop > 0);
    }

    updateSort(key: string): void {
        if (this.sortKey === key) {
            this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
        } else {
            this.sortKey = key;
            if (this.sortKey === "makes") {
                this.sortDirection = "asc";
            } else {
                this.sortDirection = "desc";
            }
        }
        this.salesData.makes = this.getSortedMakes();
    }

    getSortedMakes(): LineItemDetail[] {
        let makes = this.salesData.makes.slice();

        makes = makes.sort((m1: LineItemDetail, m2: LineItemDetail) => {
            if (this.sortDirection === "desc") {
                const m3 = m2;
                m2 = m1;
                m1 = m3;
            }

            if (this.sortKey === "makes") {
                return m1.make_description.localeCompare(m2.make_description);
            }

            const v1 = this.valueFor(m1, this.sortKey);
            const v2 = this.valueFor(m2, this.sortKey);
            if (v1 < v2) {
                return -1;
            }
            if (v1 > v2) {
                return 1;
            }
            return 0; // if they are the same
        });

        return makes;
    }

    toggleSelectedMake(make: string): void {
        if (this.selectedMake !== make) {
            this.selectedMake = make;
        } else {
            this.selectedMake = "";
        }
    }

    getStartDate(date: string): string {
        // Get 4 years worth of data, 3 for display and the last to compare against the last shown date.
        // Rolling requires complete coverage of all months in interval e.g. 201802,201702 => 201602->201802
        let startDate = "";
        if (this.dateRangeType === "rolling" || this.dateRangeType === "ytd") {
            startDate = DateUtils.addYear(date, -4);
        } else if (this.dateRangeType === "months") {
            startDate = DateUtils.addYear(date, -4);
        } else {
            startDate = DateUtils.addYear(date, -3);
        }
        return startDate;
    }

    valueFor(salesObj: any, date: string): any {
        if (this.dateRangeType === "rolling" || this.dateRangeType === "ytd") {
            if (this.useSalesData && date.length > 6) {
                date = date.substring(0, date.length - 2);
            }
            if (salesObj.salesMonthly[moment(date).format("YYYYMM").toString()]) {
                return salesObj.salesMonthly[moment(date).format("YYYYMM").toString()];
            }
            if (salesObj?.salesMonthly[date]) {
                return salesObj.salesMonthly[date];
            }
            return 0;
        } else if (this.dateRangeType === "months") {
            if (date.indexOf(" - ") !== -1) {
                const dateSplit: string[] = date.split(" - ");
                const startMonth: number = parseInt(dateSplit[0].substring(4, 6), 10);
                const startYear: string = dateSplit[0].substring(0, 4);
                const endYear: string = dateSplit[1].substring(0, 4);
                let endMonth: number = parseInt(dateSplit[1].substring(4, 6), 10);
                if (startYear !== endYear) {
                    endMonth += 12;
                }

                let customSalesRangeData = 0;
                for (let i = startMonth; i <= endMonth; i++) {
                    const curMonth = ((i - 1) % 12) + 1;
                    let nextMonth: string;
                    if (curMonth.toString().length === 2) {
                        nextMonth = curMonth.toString();
                    } else {
                        nextMonth = "0" + curMonth.toString();
                    }
                    const dateString = (curMonth < startMonth ? endYear : startYear) + nextMonth;
                    customSalesRangeData = customSalesRangeData + salesObj.salesMonthly[dateString];
                }
                return customSalesRangeData;
            }
            return salesObj.sales[date];
        }
        return salesObj.sales[date];
    }

    calculateShares(make: LineItemDetail, ds: DealerSales, date: string): number {
        if (this.valueFor(make, date) === "N/A" || this.valueFor(ds, date) === "N/A") {
            return 0;
        }
        return (this.valueFor(make, date) / this.valueFor(ds, date)) * 100 || 0;
    }

    calculateSharesDifference(make: LineItemDetail, ds: DealerSales, dates: string[], i: number): number {
        return this.calculateShares(make, ds, dates[i]) - this.calculateShares(make, ds, this.previousYear(dates, i));
    }

    calculateDifference(make: LineItemDetail, dates: string[], i: number): number {
        return this.valueFor(make, dates[i]) - this.valueFor(make, this.previousYear(dates, i)) || 0;
    }

    // Previous is the next value in the array but, represents the previous year.
    compareSalesToPrevious(make: LineItemDetail, dates: string[], i: number): { decreasing: boolean; equal: boolean; increasing: boolean } {
        const current = this.valueFor(make, dates[i]);
        const previous = this.valueFor(make, this.previousYear(dates, i));
        return this.compareNumbers(current, previous);
    }

    // Previous is the next value in the array but, represents the previous year.
    compareSharesToPrevious(make: LineItemDetail, ds: DealerSales, dates: string[], i: number): { decreasing: boolean; equal: boolean; increasing: boolean; "center-cell": boolean; "left-border": boolean } {
        const current = this.calculateShares(make, ds, dates[i]);
        const previous = this.calculateShares(make, ds, this.previousYear(dates, i));
        return assign(this.compareNumbers(current, previous), { "center-cell": !this.showVolume, "left-border": !this.showVolume });
    }

    nationalValueFor(salesObj: any, date: string): number {
        if (this.dateRangeType === "rolling" || this.dateRangeType === "ytd") {
            return salesObj.nationalSalesMonthly[date];
        } else if (this.dateRangeType === "months") {
            if (date.indexOf(" - ") !== -1) {
                const dateSplit: string[] = date.split(" - ");
                const startMonth: number = parseInt(dateSplit[0].substr(4, 2), 10);
                const endMonth: number = parseInt(dateSplit[1].substr(4, 2), 10);
                let customSalesRangeData = 0;
                for (let i = startMonth; i <= endMonth; i++) {
                    let nextMonth: string;
                    if (i.toString().length === 2) {
                        nextMonth = i.toString();
                    } else {
                        nextMonth = "0" + i.toString();
                    }
                    const dateString = dateSplit[0].substr(0, 4) + nextMonth;
                    customSalesRangeData = customSalesRangeData + salesObj.nationalSalesMonthly[dateString];
                }
                return customSalesRangeData;
            }
            return salesObj.nationalSalesMonthly[date];
        }
        return salesObj.nationalSales[date];
    }

    calculateNationalShares(make: LineItemDetail, ds: DealerSales, date: string): number {
        return (this.nationalValueFor(make, date) / this.nationalValueFor(ds, date)) * 100 || 0;
    }

    calculateNationalSharesDifference(make: LineItemDetail, ds: DealerSales, dates: string[], i: number): number {
        return this.calculateNationalShares(make, ds, dates[i]) - this.calculateNationalShares(make, ds, this.previousYear(dates, i));
    }

    calculateNationalDifference(make: LineItemDetail, dates: string[], i: number): number {
        return this.nationalValueFor(make, dates[i]) - this.nationalValueFor(make, this.previousYear(dates, i)) || 0;
    }

    // Previous is the next value in the array but, represents the previous year.
    compareNationalSalesToPrevious(make: LineItemDetail, dates: string[], i: number): { decreasing: boolean; equal: boolean; increasing: boolean } {
        const current = this.nationalValueFor(make, dates[i]);
        const previous = this.nationalValueFor(make, this.previousYear(dates, i));
        return this.compareNumbers(current, previous);
    }

    // Previous is the next value in the array but, represents the previous year.
    compareNationalSharesToPrevious(make: LineItemDetail, ds: DealerSales, dates: string[], i: number): { decreasing: boolean; equal: boolean; increasing: boolean; "center-cell": boolean; "left-border": boolean } {
        const current = this.calculateNationalShares(make, ds, dates[i]);
        const previous = this.calculateNationalShares(make, ds, this.previousYear(dates, i));
        return assign(this.compareNumbers(current, previous), { "center-cell": !this.showVolume, "left-border": !this.showVolume });
    }

    // Previous is the next value in the array but, represents the previous year.
    private compareNumbers(current: number, previous: number): { decreasing: boolean; equal: boolean; increasing: boolean } {
        if (!current && !previous) {
            return { decreasing: false, equal: true, increasing: false };
        } else if (previous === undefined) {
            return { decreasing: false, equal: true, increasing: false };
        } else if (current < previous) {
            return { decreasing: true, equal: false, increasing: false };
        } else if (current === previous) {
            return { decreasing: false, equal: true, increasing: false };
        } else {
            // current > previous
            return { decreasing: false, equal: false, increasing: true };
        }
    }

    private previousYear(dates: string[], i: number): string {
        return dates[i + 1] || DateUtils.addYear(dates[i], -1);
    }

    formatDate(date: string): string {
        let dateString: string;
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        const dateFmtOne = this.useSalesData ? "YYYYMMDD" : "YYYYMM";
        const dateFmtTwo = this.useSalesData ? "MM/DD/YY" : "MM/YY";
        const maxSalesDate = this.filterStateService.getFilterValue<string>(FilterName.maxSalesDate);

        switch (this.dateRangeType) {
            case "rolling":
                dateString = this.shiftDateRange(date, 11);
                break;
            case "ytd":
                const month: number = (moment(date, "YYYYMM").format("MM")) as any;
                dateString = this.shiftDateRange(date, month - 1);
                break;
            case "months":
                const dateRangeSplit = date.split(" - ");
                let drMonthStart: number;
                let drMonthEnd: number;

                drMonthStart = (moment(dateRangeSplit[0], dateFmtOne).format(dateFmtTwo)) as any;
                if (this.useSalesData) {
                    if (dateRangeSplit[1].substring(0, 6) === maxSalesDate.substring(0, 6)) {
                        dateRangeSplit[1] = maxSalesDate;
                    } else {
                        dateRangeSplit[1] = DateUtils.appendLastDayOfMonth(dateRangeSplit[1]);
                    }
                }
                drMonthEnd = (moment(dateRangeSplit[1], dateFmtOne).format(dateFmtTwo)) as any;
                dateString = drMonthStart + " - " + drMonthEnd;
                break;
            default:
                if (this.useSalesData && moment(this.minStartDate, "YYYYMMDD").isAfter(moment(date, "YYYY").startOf("year"))) {
                    dateString = "N/A";
                } else {
                    dateString = date;
                }

        }
        return dateString;
    }

    private shiftDateRange(date: string, months: number): string {
        const dateFmtOne = this.useSalesData ? "YYYYMMDD" : "YYYYMM";
        const dateFmtTwo = this.useSalesData ? "MM/DD/YY" : "MM/YY";
        const end = moment(date, dateFmtOne);
        const dateFirstOfMonth = moment(this.useSalesData ? date.slice(0, 6) + "01" : date, dateFmtOne);

        const begin = moment(dateFirstOfMonth).subtract(months, "months");
        const minActual = moment(this.dateShiftComponent.getDefaultMin(this.useSalesData), dateFmtOne);

        return `${begin.isAfter(minActual) ? begin.format(dateFmtTwo) : minActual.format(dateFmtTwo)} - ${end.format(dateFmtTwo)}`;
    }
}
