import { Injectable } from "@angular/core";
import { HttpServiceBase } from "../base/services/http-service-base.service";
import { HttpClient } from "@angular/common/http";
import { Metric } from "../models/metric.model";

@Injectable()
export class MetricsService extends HttpServiceBase {
    private metricUrl;
    constructor(
        protected http: HttpClient
    ) {
        super(http);
        this.metricUrl = "/metrics";
    }

    public sendMetric(metric: Metric) {
        this.postInterfaceNoCache(`${this.rootUrl}${this.metricUrl}`, metric).toPromise().then((res) => {}, err => {
            console.error(err);
        });
    }
}
