import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormControl, ValidatorFn, Validators } from "@angular/forms";
import { StringInputComponent } from "@kite/angular-kite";
@Component({
    selector: "search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    // encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements AfterViewInit {
    @ViewChild(StringInputComponent, { static: false }) searchInput: StringInputComponent;
    @ViewChild("filtersSearch") searchEl: ElementRef<StringInputComponent>;
    @Input() searchTerm = "";
    @Input() placeholder = "Search...";
    @Input() allowedCharacterRegex = /[^A-Za-z0-9 '\-_]/g; // Only emit letters, numbers, space, apostrophe, underscore, dash.
    @Output() searchUpdated = new EventEmitter();

    public validator: ValidatorFn = Validators.nullValidator;

    public search: FormControl = new FormControl(
        {
            value: "",
            disabled: false,
        },
        [
            this.validator
        ]
    );

    searchIsFocused = false;

    get searchHasInput(): boolean {
        return this.searchTerm && this.searchTerm.length > 0;
    }

    ngAfterViewInit(): void {
        setTimeout(() => { // timeout prevents ExpressionChangedAfterItHasBeenCheckedError
            this.focusSearch();
        }, 0);
    }

    clearSearch(): void {
        this.searchIsFocused = true;
        this.searchTerm = "";
        this.searchUpdated.emit(this.searchTerm);
    }

    inputChanged(): void {
        this.searchIsFocused = !this.searchHasInput;
        this.searchUpdated.emit(this.searchTerm.replace(this.allowedCharacterRegex, ""));
    }

    toggleFocus(focused: boolean): void {
        this.searchIsFocused = focused;
    }

    focusSearch(): void {
        if (this.searchInput) {
            const val = this.searchTerm;
            this.searchInput.inFocus.next(true);
            setTimeout(() => {
                // this moves cursor to end of line, silly but apparently the only method.
                this.searchInput.value = "";
                this.searchInput.value = val;
            }, 0);
        }
    }

    @HostListener("document:keyup", ["$event"])
    processGlobalHotkeys(event: KeyboardEvent): void {
        if (event["_stopPropagation"]) {
            return;
        }

        if (event.key === "Escape" || event.code === "27") {
            if (this.searchHasInput) {
                this.clearSearch();
            }
        }
    }
}
