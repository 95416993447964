<table class="sbz-content">
    <caption>{{title}}</caption>
    <thead>
        <tr class="overview">
            <th [attr.column-name]="'type'">ZIP Codes</th>
            <th *ngIf="showVolume" [attr.column-name]="'sales'">Sales</th>
            <th [attr.column-name]="'shares'">% of Sales</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="salesByZip.length === 0; then outOfZips"></tr>
        <tr *ngFor="let zip of salesByZip; let i = index" [attr.row-index]="i + 1">
            <td>
                <span class="badge" [style.background]="zip?.color?.backgroundColor"></span>{{zip.zip}}</td>
            <td *ngIf="showVolume" [attr.column-name]="'sales'">{{zip.volume}}</td>
            <td [attr.column-name]="'shares'">{{((zip.volume / this.totalVolume) * 100).toFixed(2)}}%</td>
        </tr>
    </tbody>
</table>

<ng-template #outOfZips>
    <td colspan="3">
        <div class="zip-code-table-message">
            Please change ZIP Code display limit or applied filters to display additional ZIPs.
        </div>
    </td>
</ng-template>
