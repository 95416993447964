import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { PresentationFilterService } from "app/core/services/presentation-filter.service";

import { AutoAnalyzerService } from "../../services/auto-analyzer.service";
import { TooltipComponent } from "../tooltip/tooltip.component";
import { DmaDataService } from "@at/core";
import { Subscription } from "rxjs";

@Component({
    selector: "create-schedule-button",
    templateUrl: "./create-schedule-button.component.html",
    styleUrls: ["./create-schedule-button.component.scss"],
})

export class CreateScheduleButtonComponent implements AfterViewInit {

    public isAudienceAppAuth = false;
    public createTVSchedule: any = "";
    public showDisableError: any = "";
    @Output() createTVScheduleEvent = new EventEmitter();
    @ViewChild(TooltipComponent, { static: true }) tooltip: TooltipComponent;

    constructor(
            private autoAnalyzerService: AutoAnalyzerService,
            private presentationFilterService: PresentationFilterService,
            protected dmaDataService: DmaDataService
    ) { }

    ngAfterViewInit(): void {
        this.isAudienceAppAuth = this.autoAnalyzerService.getIsAuth();
        if (this.isAudienceAppAuth === undefined) {
            this.checkUserAuth();
        } else {
            this.checkDMAValid();
        }
    }

    private async checkUserAuth(): Promise<void> {
        const authToken = await this.autoAnalyzerService.fetchAuthToken();
        this.autoAnalyzerService.getUserWithToken(JSON.parse(authToken.data)).subscribe(response => {
            this.isAudienceAppAuth = (response["isSuccess"]) ? true : false;
            if (!this.isAudienceAppAuth) {
                return;
            }
            // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
            this.autoAnalyzerService.setIsAuth(this.isAudienceAppAuth);
            this.checkDMAValid();
        }, error => {
            this.isAudienceAppAuth = false;
            console.error(error);
            return;
        });
    }

    onCreateTVScheduleClicked(event: any): void {
        this.createTVScheduleEvent.emit(event);
    }

    private checkDMAValid() {
        let dma = this.presentationFilterService.getFilterValue(FilterName.dma);
        if(typeof dma[0] === "string") {
            dma = this.dmaDataService.getDmaId(dma[0].split(", ")[0]);
            this.presentationFilterService.setFilterValue(FilterName.dma, [dma]);
        }
        this.checkAudienceAppDMA(dma);
    }

    private checkAudienceAppDMA(code: any): Promise<any> {
        return this.autoAnalyzerService.getDMA(code).then(response => {
            if (response.isSuccess) {
                this.presentationFilterService.setFilterValue(FilterName.aa_is_Dma_valid, true);
            } else {
                console.error("DMA not found");
                this.presentationFilterService.setFilterValue(FilterName.aa_is_Dma_valid, false);
                return;
            }
        }).catch((error) => {
            console.error(error);
            return;
        });
    }
}
