<div class="map-heatmap-legend" *ngIf="legendInfo && legendInfo.length > 0" [class.left-panel-open]="leftPanelOpen"
    [class.expanded]="expanded" (click)="expand()">
    <div class="header">
        <div class="title">
            AUTO SALES
        </div>
        <div class="minimize" (click)="minimize($event)">
            <div></div>
        </div>
    </div>
    <div class="legend-list">
        <div class="legend-label" *ngFor="let q of legendInfo;let i = index">
            <div class="label-color heatmap{{i}}"></div>
            <div class="label-text" *ngIf="!smallDataSet || smallDataSet && q.min !== q.max">{{q.min | DisplayFig }}-{{q.max | DisplayFig}}</div>
            <div class="label-text" *ngIf="smallDataSet && q.min === q.max">{{q.max | DisplayFig}}</div>
        </div>
    </div>
</div>
