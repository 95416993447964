import { Component } from "@angular/core";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { LineItemDetail, ModelItemDetail, Sales } from "app/core/models/dealer-sales";

import { DealerMakesModelsDetailsComponent, MakeModelSales } from "../dealer-makes-models-details/dealer-makes-models-details.component";
import { SalesAccordionHeaderRollup, SalesAccordionSalesItem } from "../sales-accordion/sales-accordion.component";
import { FilterName } from "@at/core";

@Component({
    selector: "make-model-composition",
    templateUrl: "../dealer-makes-models-details/dealer-makes-models-details.component.html",
    styleUrls: ["../dealer-makes-models-details/dealer-makes-models-details.component.scss"]
})
export class MakeModelCompositionComponent extends DealerMakesModelsDetailsComponent {
    filterOverrides = { volume: null, dealers: [] };

    protected toMakeModelSale(make: LineItemDetail, sales: Sales, dates: string[]): MakeModelSales {
        const total = this.dateRangeType !== DateRangeTypes.YEARS ? sales.salesMonthly : sales.sales;
        const makeObject = this.dateRangeType !== DateRangeTypes.YEARS ? make.salesMonthly : make.sales;
        // const keys = Object.keys(total);
        // let key = keys[0];
        // if (typeof total[key] === "string") {
        //     total[key] = "n/a";
        //     makeObject[key] = "n/a";
        //     const dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
        //     if (dateRangeType === DateRangeTypes.MONTHS) {
        //         key = keys[1];
        //         total[key] = "n/a";
        //         makeObject[key] = "n/a";
        //     }
        // }
        return {
            label: make.make_description,
            rollups: dates.reduce((memo, d) => {
                memo[d] = {
                    shares: (makeObject[d] / total[d]) * 100 || 0
                } as { shares: any; sales?: any };
                memo[d].sales = makeObject[d] || 0;
                return memo;
            }, {}) as SalesAccordionHeaderRollup,
            items: make.models.map(model => this.toAccordionSalesItem(model, make, dates)) as SalesAccordionSalesItem[]
        };
    }

    protected toAccordionSalesItem(model: ModelItemDetail, make: LineItemDetail, dates: string[]): SalesAccordionSalesItem {
        const modelValues = this.dateRangeType !== DateRangeTypes.YEARS ? model.salesMonthly : model.sales;
        const makeValues = this.dateRangeType !== DateRangeTypes.YEARS ? make.salesMonthly : make.sales;
        const keys = Object.keys(modelValues);
        let key = keys[0];
        if (typeof modelValues[key] === "string") {
            modelValues[key] = "n/a";
            makeValues[key] = "n/a";
            const dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            if (dateRangeType === DateRangeTypes.MONTHS) {
                key = keys[1];
                modelValues[key] = "n/a";
                makeValues[key] = "n/a";
            }
        }
        return {
            label: model.model_description,
            sales: dates.reduce((memo, date) => {
                memo[date] = {
                    shares: (modelValues[date] / makeValues[date]) * 100 || 0
                };
                memo[date].sales = modelValues[date] || 0;
                return memo;
            }, {})
        } as SalesAccordionSalesItem;
    }
}
