<div class="selection-section" id="{{filterName}}-selection-section">
    <div class="done-button">
        <ng-container *ngTemplateOutlet="doneTemplate"></ng-container>
    </div>

    <div class="selection-section-title" *ngIf="chipValues.length || !noResultsTemplate">
        <label class="selection-title">
            <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
        </label>
        <label class="selection-subtitle">
            <ng-container *ngTemplateOutlet="descriptionTemplate"></ng-container>
        </label>
    </div>

    <ng-container *ngIf="loadedOptions; else loading">
        <ng-container *ngIf="chipValues.length || !noResultsTemplate; else noResultsTemplate">
            <div class="button-block">
                <button class="button clear" (click)="selectNone()" [disabled]="selectedOptions.length === 0"
                    *ngIf="!clearButtonDisabled">
                    Clear All
                    <div class="button-icon clear"></div>
                </button>
                <div *ngIf="filterName === FilterName.dealers; else top5MarketOrSpotlight">
                    <button class="button top5" [disabled]="searchTerm.length > 0"
                        (click)="selectTopN(TopNContext.Market)"
                        *ngIf="!topXButtonDisabled">
                        Top 5
                        <div class="button-icon top5"></div>
                    </button>
                </div>
                <ng-template #top5MarketOrSpotlight>
                    <split-button *ngIf="!topXButtonDisabled" actionButtonClass="top5"
                        [optionOneDisabled]="topNForMarketDisabled" [optionTwoDisabled]="spotlightDealerId === null"
                        [selectedItem]="topNContext" (itemChanges)="selectTopN($event)">
                    </split-button>
                </ng-template>
                <button class="button search" (click)="toggleSearch()" *ngIf="!searchButtonDisabled">
                    <div class="button-icon search"></div>
                </button>
                <button class="button radius-ring" (click)="toggleRing()" *ngIf="!radiusRingButtonDisabled">
                    Show Radius
                </button>
                <filter-counter-button *ngIf="showRing && !radiusRingButtonDisabled"
                    label="Displays a 1 to 50 mile radius ring around selected dealer"
                    [filterName]="FilterName.ring_radius" [filterService]="filterService" min="1" max="50">
                </filter-counter-button>
            </div>
            <search *ngIf="searchOpenState" [searchTerm]="searchTerm" (searchUpdated)="onSearchInputChanged($event)">
            </search>
            <div class="selected-options">
                <label class="chip-label selections" id="chip-label-selections-{{value[selector]}}"
                    *ngFor="let value of selectedOptions">
                    <div class="chip selected selections" (click)="chipChanged(value)">
                        <span class="icon"></span>
                        <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                        <span class="name">{{value.name}}</span>
                    </div>
                </label>
            </div>
            <div class="separator clearfix"></div>
            <div class="chips-area">
                <div class="sort-area" *ngIf="!sortAreaDisabled">
                    <span class="sort-label">Sort by:</span>
                    <div class="sort-toggle">
                        <toggle-button *ngIf="filterName !== FilterName.dma"
                            [optionOne]="sortOptionOne" optionOneLabel="Market Sales" [optionTwo]="sortOptionTwo"
                            optionTwoLabel="Alphabetical" [optionTwoSelected]="sortSelection === sortOptionTwo"
                            (optionTwoSelectedChange)="toggleSort($event)">
                        </toggle-button>
                    </div>
                </div>
                <div class="scrollable">

                    <div class="chips">
                        <label class="chip-label" id="chip-label-{{value[selector]}}"
                            *ngFor="let value of chipsToRender">
                            <div class="chip" [class.selected]="value.isSelected" (click)="chipChanged(value)">
                                <span class="icon"></span>
                                <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                                <div class="name" title="{{value.name}}" *ngIf="!value.isSelected">
                                    <highlighted-text class="name" [needle]="searchTerm" [haystack]="value.name">
                                    </highlighted-text>
                                </div>
                                <span class="name" title="{{value.name}}" *ngIf="value.isSelected">{{value.name}}</span>
                            </div>
                        </label>
                    </div>
                    <div class="footer">
                        <ng-container *ngTemplateOutlet="footerTemplate" class="footer"></ng-container>
                    </div>
                    <div class="spaceAfter clearfix"></div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #loading>
    <div class="loader">
        <div class="loading-icon"></div>
    </div>
</ng-template>
