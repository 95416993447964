<div class="selection-section"
    id="{{filterName}}-selection-section">
    <div class="done-button">
        <ng-container *ngTemplateOutlet="doneTemplate"></ng-container>
    </div>
    <div class="selection-section-title" *ngIf="chipValues.length || !noResultsTemplate">
        <label class="selection-title">
            Select Zones
        </label>
        <label class="selection-subtitle">
            Select zones to include in your proposal.
        </label>
    </div>
    <ng-container *ngIf="loadedOptions; else loading">
        <ng-container *ngIf="chipValues.length || !noResultsTemplate; else noResultsTemplate">
            <div class="button-block">
                <!-- <button class="button search" (click)="toggleSearch()" *ngIf="!searchButtonDisabled">
                    <div class="button-icon search"></div>
                </button> -->
            </div>
            <search [searchTerm]="searchTerm" (searchUpdated)="onSearchInputChanged($event)">
            </search>
            <!-- <div class="selected-options">
                <label class="chip-label selections" id="chip-label-selections-{{value[selector]}}"
                    *ngFor="let value of selectedOptions">
                    <div class="chip selected selections" (click)="chipChanged(value)">
                        <span class="icon"></span>
                        <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                        <span class="name">{{value.name}}</span>
                    </div>
                </label>
            </div> -->
            <div class="separator clearfix"></div>
            <div class="chips-area">
                <div class="sort-area" *ngIf="!sortAreaDisabled">
                    <span *ngIf="showSortLabel" class="sort-label">Sort by:</span>
                    <div class="sort-toggle">
                        <toggle-button *ngIf="filterName !== FilterName.dma"
                            [optionOne]="sortOptionOne" optionOneLabel="Market Sales" [optionTwo]="sortOptionTwo"
                            optionTwoLabel="Alphabetical" [optionTwoSelected]="sortSelection === sortOptionTwo"
                            (optionTwoSelectedChange)="toggleSort($event)">
                        </toggle-button>
                    </div>
                    <p class="sort-explainer" *ngIf="showSortLabel && sortSelection === sortOptionOne">
                        {{prettyFilterName}} ranked by sales in: <strong>{{dmaNames}}</strong>
                    </p>
                </div>

                <!-- --------------zone chips---------- -->
                <div class="scrollable">
                    <div *ngIf="zones" class="chips">
                        <div>Select Zones</div>
                        <label class="chip-label" id="chip-label-{{value[selector]}}"
                            *ngFor="let value of zoneChipsToRender;trackBy: trackById">
                            <div class="chip" *ngIf="value.isValid" [class.selected]="value.isSelected"
                                (click)="chipChanged(value)">
                                <span class="icon"></span>
                                <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                                <div class="name" title="{{value.name}}" *ngIf="!value.isSelected">
                                    <highlighted-text class="name" [needle]="searchTerm" [haystack]="value.name">
                                    </highlighted-text>
                                </div>
                                <span class="name" title="{{value.name}}" *ngIf="value.isSelected">{{value.name}}</span>
                            </div>
                            <div class="disabled-chip" *ngIf="!value.isValid" #tooltip [dTooltip]="showZoneDisableError"
                                [showTooltip]="!value.isValid" [placement]="'bottom'" [tooltipZIndex]="2003">
                                <span class="icon"></span>
                                <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                                <div class="name" title="{{value.name}}" *ngIf="!value.isSelected">
                                    <highlighted-text class="name" [needle]="searchTerm" [haystack]="value.name">
                                    </highlighted-text>
                                </div>
                                <span class="name" title="{{value.name}}" *ngIf="value.isSelected">{{value.name}}</span>
                            </div>
                        </label>
                    </div>
                    <!-- --------------zone chips---------- -->

                    <!-- --------------makes chips---------- -->

                    <div *ngIf="makeChipsToRender.length > 0" class="chips border">
                        <div class="section-lable">Select Makes</div>

                        <label class="chip-label" id="chip-label-{{value[selector]}}"
                            *ngFor="let value of makeChipsToRender;trackBy: trackById">
                            <div class="chip" *ngIf="value.isValid" [class.selected]="value.isSelected"
                                (click)="chipChanged(value)">
                                <span class="icon"></span>
                                <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                                <div class="name" title="{{value.name}}" *ngIf="!value.isSelected">
                                    <highlighted-text class="name" [needle]="searchTerm" [haystack]="value.name">
                                    </highlighted-text>
                                </div>
                                <span class="name" title="{{value.name}}" *ngIf="value.isSelected">{{value.name}}</span>
                            </div>
                            <div class="disabled-chip" *ngIf="!value.isValid" #tooltip
                                [dTooltip]="showMakesDisableError" [showTooltip]="!value.isValid" [placement]="'bottom'"
                                [tooltipZIndex]="2003">
                                <span class="icon"></span>
                                <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                                <div class="name" title="{{value.name}}" *ngIf="!value.isSelected">
                                    <highlighted-text class="name" [needle]="searchTerm" [haystack]="value.name">
                                    </highlighted-text>
                                </div>
                                <span class="name" title="{{value.name}}" *ngIf="value.isSelected">{{value.name}}</span>
                            </div>
                        </label>
                    </div>

                    <!-- --------------makes chips---------- -->


                    <!-- -------------- segments chips---------- -->
                    <div *ngIf="segmentChipsToRender.length > 0" class="chips border">
                        <div class="section-lable">Select Segments</div>

                        <label class="chip-label" id="chip-label-{{value[selector]}}"
                            *ngFor="let value of segmentChipsToRender;trackBy: trackById">
                            <div class="chip" *ngIf="value.isValid" [class.selected]="value.isSelected"
                                (click)="chipChanged(value)">
                                <span class="icon"></span>
                                <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                                <div class="name" title="{{value.name}}" *ngIf="!value.isSelected">
                                    <highlighted-text class="name" [needle]="searchTerm" [haystack]="value.name">
                                    </highlighted-text>
                                </div>
                                <span class="name" title="{{value.name}}" *ngIf="value.isSelected">{{value.name}}</span>
                            </div>
                            <div class="disabled-chip" *ngIf="!value.isValid" #tooltip
                                [dTooltip]="showSegmentsDisableError" [showTooltip]="!value.isValid"
                                [placement]="'bottom'" [tooltipZIndex]="2003">
                                <span class="icon"></span>
                                <span *ngIf="(sortSelection === 'sales')" class="index">{{value.index}}.</span>
                                <div class="name" title="{{value.name}}" *ngIf="!value.isSelected">
                                    <highlighted-text class="name" [needle]="searchTerm" [haystack]="value.name">
                                    </highlighted-text>
                                </div>
                                <span class="name" title="{{value.name}}" *ngIf="value.isSelected">{{value.name}}</span>
                            </div>
                        </label>
                    </div>
                    <div class="footer">
                        <ng-container *ngTemplateOutlet="footerTemplate" class="footer"></ng-container>
                    </div>
                    <div class="spaceAfter clearfix"></div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>


<ng-template #loading>
    <div class="loader">
        <div class="loading-icon"></div>
    </div>
</ng-template>

<ng-template #showZoneDisableError>
    <div style="max-width: 200px;">
        This Ad Zone can not be imported into AudienceApp
    </div>
</ng-template>

<ng-template #showMakesDisableError>
    <div style="max-width: 200px;">
        This Make can not be imported into AudienceApp
    </div>
</ng-template>

<ng-template #showSegmentsDisableError>
    <div style="max-width: 200px;">
        This Segment can not be imported into AudienceApp
    </div>
</ng-template>
