import { Injectable } from "@angular/core";

@Injectable()
export class SalesRangeValidationService {

    public validateMin(inputMin: any, inputMax: any): boolean {
        const min = +inputMin;
        const max = +inputMax;

        // Blanks is valid.
        if (this.isNullOrWhitespace(inputMin)) {
            return true;
        }

        return min >= 0 && (min <= max || this.isNullOrWhitespace(inputMax));
    }

    public validateMax(inputMin: any, inputMax: any): boolean {
        const min = +inputMin;
        const max = +inputMax;

        // Blanks is valid.
        if (this.isNullOrWhitespace(inputMax)) {
            return true;
        }

        return (max >= min || this.isNullOrWhitespace(inputMin));
    }

    public isValid(inputMin: any, inputMax: any): boolean {
        return this.validateMin(inputMin, inputMax)
            && this.validateMax(inputMin, inputMax);
    }

    private isNullOrWhitespace(value: any): boolean {
        return value === null || value === undefined || value === "";
    }

}
