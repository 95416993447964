import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { environment } from "environment";

import { CognitoService } from "./cognito.service";
import { FeatureFlagService, FeatureFlags, FeatureFlagsVariables } from "./feature-flag.service";

@Injectable()
export class AuthGuard implements CanActivate {

    // if a page is not added to one the below arrays, it will be accessable by standard users
    // if a page needs to be accessable from different groups, it needs to be added to each applicable array
    adminPages = ["/admin"]; // add admin only paths here
    featureGuardPaths = ["/presentation/composite", "/presentation/make-vs-make", "/presentation/make-vs-segment", "/presentation/marketshare", "/map", "/trends", "/insights"
    ]; // add feature only paths here

    constructor(
        private cognitoService: CognitoService,
        private featureFlagService: FeatureFlagService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            if (!this.cognitoService.isSignedIn()) {
                return this.cognitoService.refreshToken()
                    .then(
                        () => this.sessionIsValid(state),
                        () => this.redirectToSignIn(state)
                    );
            } else {
                return this.sessionIsValid(state);
            }
        } catch (error) {
            if (!environment.production) {
                // console.log(error);
            }
            return this.redirectToSignIn(state);
        }
    }

    private async sessionIsValid(state: RouterStateSnapshot): Promise<boolean> {
        const baseRoute = state.url.split("?")[0];
        let validationPassed = true;

        if (!this.cognitoService.authenticated.value) { // if not authorized, return user to signin for proper authorization
            return this.redirectToSignIn(state);
        } else if (this.adminPages.includes(baseRoute)) { // if page is a admin page, check if user can view it
            validationPassed = this.featureFlagService.viewAdminFeature();
        } else if (this.featureGuardPaths.includes(baseRoute)) {
            switch (baseRoute) {
                case "/presentation/composite":
                    validationPassed = await this.featureFlagService.isFeatureEnabled(FeatureFlags.AA_Composite);
                    break;
                case "/presentation/make-vs-make":
                    validationPassed = await this.featureFlagService.isFeatureEnabled(FeatureFlags.AA_MakeVsMake);
                    break;
                case "/presentation/make-vs-segment":
                    validationPassed = await this.featureFlagService.isFeatureEnabled(FeatureFlags.AA_MakeVsSegment);
                    break;
                case "/presentation/marketshare":
                    validationPassed = await this.featureFlagService.isFeatureEnabled(FeatureFlags.AA_MarketShare);
                    break;
                case "/map":
                case "/trends":
                case "/insights":
                    validationPassed = await this.featureFlagService.isFeatureEnabled(FeatureFlags.AA_MarketAnalysis);
                    break;
                default:
                    break;
            }
        }

        if (!validationPassed) {
            this.router.navigateByUrl("/not-found");
        }
        return Promise.resolve(validationPassed);
    }

    private redirectToSignIn(state: RouterStateSnapshot): Promise<boolean> {
        this.cognitoService.setRedirectUrl(state.url);
        this.router.navigate(["/signin"], {
            queryParams: {
                return: state.url
            }
        });
        return Promise.resolve(false);
    }
}
