<div *ngIf="legends; else ungrouped" class="legend-group-headers">
    <div id="legend-group-header-{{i}}" *ngFor="let g of legends; let i = index" class="label-text" [class.selected]="groupIsFocused(g)" (click)="groupClicked(g)">
        <pre>{{getLegendEntryText(g)}}</pre>
    </div>
</div>
<ng-container *ngFor="let g of legends">
    <div class="legend-group-legends" *ngIf="!g.hideSubCategory">
        <div *ngFor="let item of g.dataset" class="legend-label" id="legend-label-{{item.id}}" (click)="legendClicked(item, g)">
            <div class="label-badge" [style.background]="calculateColor(item)"></div>
            <span class="label-text" [class.selected]="legendIsSelected(item)">{{item.label}}</span>
        </div>
    </div>
</ng-container>

<ng-template #ungrouped>
    <div class="legend-ungrouped">
        <div class="legend-label" ngClass="" id="legend-label-{{item.id}}" *ngFor="let item of data; let index = index" (click)="legendClicked(item)">
            <div class="label-badge" [style.background]="getColor(index)"></div>
            <div class="label-text" [class.selected]="legendIsSelected(item)">
                <pre>{{getLegendEntryText(item)}}</pre>
            </div>
        </div>
    </div>
</ng-template>
