import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DateUtils } from "@at/utils";
import { DataSet } from "app/core/models/chart-data.model";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { ZipZoneSale, ZipZoneSales } from "app/core/models/zip-zone.model";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { ZipZoneService } from "app/core/services/zip-zone.service";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { MetadataService } from "../../../services/metadata.service";
import { ChartColor } from "../../charts/chart/chart-color.utils";
import { MAX_BAR_THICKNESS } from "../../charts/chart/chart-defaults.options";
import { ChartComponent } from "../../charts/chart/chart.component";
import { MiniMapComponent } from "../../mini-map/mini-map.component";
import { CardUtils } from "../card/card.utils";

@Component({
    selector: "sales-by-ad-zone-card",
    templateUrl: "./sales-by-ad-zone-card.component.html",
    styleUrls: ["./sales-by-ad-zone-card.component.scss"]
})
export class SalesByAdZoneCardComponent implements OnInit, OnDestroy {
    @ViewChild(MiniMapComponent) miniMapComponent: MiniMapComponent;
    @ViewChild(ChartComponent) chart: ChartComponent;

    description = "";
    isLoading = true;
    isOpen = false;
    isDisabled = true;
    isMultiDma = false;
    visualization: "chart" | "table" = "chart";

    date = "2018";
    salesDate = "2024";
    cardTitle: string;
    showVolume = false;
    animateMap = false;

    zonesByZips: string[][] = [];
    dataLastUpdate: string;
    barChartData: DataSet[] | null = null;
    chartColors = ChartColor.getColorPalette(10);

    topZones: string[] = [];
    selectedZones: string[] = [];
    salesByZones: ZipZoneSales[] = [];
    spotlightedDealer: Dealer | null = null;
    ringRadius = 0;
    minDate;
    maxDate;
    groupSize: number;
    dateRangeType: DateRangeTypes;
    customTicks: number[][] = [];

    private spotlightDealerDetailsSubscription: Subscription;
    private filterStateSubscription: Subscription;

    constructor(
        protected salesDataService: SalesDataService,
        protected dealerDataService: DealerDataService,
        protected metadataService: MetadataService,
        protected filterStateService: FilterStateService,
        protected zipZoneService: ZipZoneService
    ) { }

    // Returning promise to chain unit test evaluation.
    updateBarChartData(): Promise<void> {
        return this.fetchZoneSales()
            .then((result: ZipZoneSales[][]) => {
                const allZones = result && result[0][0];
                const filteredZones = result && result[1][0];

                if (allZones && allZones.sales && allZones.sales.length > 0 &&
                    filteredZones && filteredZones.sales && filteredZones.sales.length > 0) {
                    const totalSales = allZones.sales.reduce((acc, item) => acc + item.sales, 0);
                    const zones = [];

                    return this.fetchMissingZones(filteredZones)
                        .then(missingZones => {
                            missingZones.forEach((zone: any) => {
                                if (!this.hasZone(filteredZones, zone)) {
                                    const missingZone = cloneDeep(zone);
                                    missingZone.sales = 0;
                                    allZones.sales.push(missingZone);
                                    filteredZones.sales.push(missingZone);
                                }
                            });

                            this.barChartData = filteredZones.sales
                                .slice(0, 10)
                                .map((item, i) => {
                                    zones.push(item.location);
                                    return {
                                        id: i,
                                        data: [(item.sales / totalSales) * 100],
                                        label: this.isMultiDma || item.undefinedLocation ? item.locationWithDma : item.location
                                    };
                                })
                                .sort((s1, s2) => s1.data[0] === s2.data[0] ?
                                    s1.label.localeCompare(s2.label) :
                                    s2.data[0] - s1.data[0]);
                            this.zipZoneService.getZonesZips(zones).pipe(take(1)).subscribe(returnedZones => this.zonesByZips = returnedZones);
                            this.chartColors = ChartColor.getColorPalette(this.barChartData.length);
                        });
                } else {
                    this.barChartData = [];
                }
            })
            .then(() => {
                this.barChartData.forEach((v: any, i: number) => {
                    if (!this.customTicks.includes([v.data[0]])) {
                        this.customTicks.push([v.data[0]]);
                    }
                });
                this.isLoading = false;
                if (!this.barChartData || !this.barChartData.length) {
                    this.isDisabled = true;
                    this.description = "No results for current filters";
                }
            });
    }

    // Returning promise to chain unit test evaluation.
    updateZonesTable(): Promise<void> {
        return this.fetchZoneSales()
            .then((result: ZipZoneSales[][]) => {
                const allZones = result && result[0][0];
                const filteredZones = result && result[1][0];

                this.topZones = [];
                this.selectedZones = [];
                const zoneNames = [];
                if (allZones && allZones.sales && allZones.sales.length > 0 &&
                    filteredZones && filteredZones.sales && filteredZones.sales.length > 0) {
                    filteredZones.sales = filteredZones.sales.slice(0, 10);

                    return this.fetchMissingZones(filteredZones)
                        .then(zones => {
                            zones.forEach((zone: any) => {
                                if (!this.hasZone(filteredZones, zone)) {
                                    const missingZone = cloneDeep(zone);
                                    missingZone.sales = 0;
                                    allZones.sales.push(missingZone);
                                    filteredZones.sales.push(missingZone);
                                }
                            });

                            filteredZones.sales.forEach((item: ZipZoneSale, i: number) => {
                                const name = this.isMultiDma || item.undefinedLocation ? item.locationWithDma : item.location;
                                zoneNames.push(item.location);
                                this.selectedZones.push(name);

                                // Keep a list of the top 10 zones names for shares calculation.
                                if (i < 10) {
                                    this.topZones.push(this.isMultiDma || item.undefinedLocation ? item.locationWithDma : item.location);
                                }
                            });

                            allZones.sales = allZones.sales.sort((s1, s2) => s1.sales === s2.sales ?
                                s1.locationWithDma.localeCompare(s2.locationWithDma) :
                                s2.sales - s1.sales);

                            this.salesByZones = result[0];
                            this.chartColors = ChartColor.getColorPalette(this.selectedZones.length);
                            this.zipZoneService.getZonesZips(zoneNames).pipe(take(1)).subscribe(returnedZones => this.zonesByZips = returnedZones);
                        });
                } else {
                    this.salesByZones = [];
                }
            })
            .then(() => {
                this.isLoading = false;
                if (!this.salesByZones || !this.salesByZones.length) {
                    this.isDisabled = true;
                    this.description = "No results for current filters";
                }
            });
    }

    ngOnInit(): void {
        if (this.filterStateService.getFilterValue<boolean>(FilterName.show_ring)) {
            this.ringRadius = this.filterStateService.getFilterValue(FilterName.ring_radius);
        }

        this.metadataService.metadata.pipe(skipWhile(i => !(i && i.maxDate && i.maxSalesDate)), take(1)).subscribe(meta => {
            this.spotlightDealerDetailsSubscription = this.dealerDataService.spotlightDealerDetails.subscribe(this.spotlitDealerUpdated.bind(this));

            this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
            this.filtersUpdated([, FilterName.dateRangeType.toString(), FilterName.dateRanges.toString(), FilterName.show_volume.toString(), FilterName.dma.toString()]); // this initializes the dateRangeType & show_volume variables
            const dateRange = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
            this.date = this.dateRangeType === DateRangeTypes.YEARS ? meta.maxDate.slice(0, 4) : meta.maxDate;
            this.salesDate = this.dateRangeType === DateRangeTypes.YEARS ? meta.maxSalesDate.slice(0, 4) : meta.maxSalesDate;
            this.updateTitle();

            if (this.dateRangeType === DateRangeTypes.MONTHS && dateRange && dateRange.length >= 2) {
                this.setupDateRanges();
            }
        });
    }

    filtersUpdated(changes: string[]): void {
        let updateRequired = true;
        const conditions = {
            spotlit: {
                isValid: !!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer),
                text: "spotlight a dealer"
            },
            include_spotlit: {
                isValid: this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) === "include",
                text: "toggle 'Spotlight' to Include"
            }
        };

        this.description = CardUtils.getRequirementDescription(conditions);
        this.isDisabled = this.description.length > 0;

        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);

        if (
            changes.includes(FilterName.dateRangeType.toString()) ||
            changes.includes(FilterName.dateRanges.toString())
        ) {
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
            if (
                (dateRanges &&
                dateRanges.length >= 2 &&
                this.dateRangeType === DateRangeTypes.MONTHS) ||
                this.dateRangeType !== DateRangeTypes.MONTHS
            ) {
                this.updateTitle();
            } else {
                updateRequired = false;
            }
        }

        if (changes.includes(FilterName.show_volume.toString())) {
            this.showVolume = this.filterStateService.getFilterValue<boolean>(FilterName.show_volume);
        }
        if (changes.includes(FilterName.dma.toString())) {
            this.isMultiDma = this.filterStateService.isMultiDma();
        }

        if (changes.includes(FilterName.show_ring.toString()) || changes.includes(FilterName.ring_radius.toString())) {
            this.ringRadius = this.filterStateService.getFilterValue(FilterName.ring_radius);
        }

        if (this.isDisabled) {
            this.isOpen = false;
        } else if (updateRequired) {
            this.updateVis();
        }
    }

    updateTitle(): void {
        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);
        const useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);

        if (dateRanges && dateRanges.length >= 2 && this.filterStateService.getFilterValue(FilterName.dateRangeType) === DateRangeTypes.MONTHS) {
            this.cardTitle = `Spotlight Dealer Sales Distribution by Ad Zone: ${DateUtils.dateDisplay([dateRanges[1], dateRanges[0]], DateRangeTypes.MONTHS)}`;
        } else {
            this.cardTitle = `Spotlight Dealer Sales Distribution by Ad Zone: ${DateUtils.displayDateRange(useSalesData ? this.salesDate : this.date, this.dateRangeType, 1)}`;
        }
    }

    spotlitDealerUpdated(dealer: Dealer): void {
        if (dealer) {
            this.spotlightedDealer = dealer;
            if (this.miniMapComponent) {
                this.miniMapComponent.resizeMap();
            }
            this.updateVis();
        } else {
            this.spotlightedDealer = null;
            this.description = "Spotlight a dealer to view";
        }
    }

    resizeMap(): void {
        if (this.miniMapComponent) {
            this.miniMapComponent.resizeMap();
        }
    }

    ngOnDestroy(): void {
        if (this.spotlightDealerDetailsSubscription) {
            this.spotlightDealerDetailsSubscription.unsubscribe();
        }
        if (this.filterStateSubscription) {
            this.filterStateSubscription.unsubscribe();
        }
    }

    cardOpened(): void {
        this.isOpen = true;
        this.updateVis();
    }

    cardClosed(): void {
        this.isOpen = false;
    }

    changeVis(): void {
        this.visualization = this.visualization === "chart" ? "table" : "chart";
        this.updateVis();
    }

    fetchZoneSales(): Promise<ZipZoneSales[][]> {
        if (this.spotlightedDealer) {
            const filters: any = this.filterStateService.getCurrentApiFilters();
            const dateParams = {};
            const zips = this.filterStateService.getFilterValue(FilterName.zips);
            const dmas = this.filterStateService.getFilterValue(FilterName.dma);

            const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);

            if (dateRanges && filters[FilterName.dateRangeType] === DateRangeTypes.MONTHS && dateRanges.length >= 2) {
                this.setupDateRanges();
                dateParams["dateRangeStart"] = this.minDate;
                dateParams["dateRangeEnd"] = this.maxDate;
            }
            const filterOverride = { zips, dma_code: dmas, ...dateParams };

            const totals = this.salesDataService.getSalesForDealersByZipZone(
                [this.spotlightedDealer.dealer_id],
                "zones",
                { ...filterOverride, zones: [] },
                true
            ).toPromise();

            const filtered = this.salesDataService.getSalesForDealersByZipZone(
                [this.spotlightedDealer.dealer_id],
                "zones",
                filterOverride,
                true
            ).toPromise();

            return Promise.all([totals, filtered]);
        }

        return Promise.resolve([[], []]);
    }

    private updateVis(): void {
        if (!this.isOpen || !this.spotlightedDealer) {
            return;
        }

        if (this.visualization === "chart") {
            this.updateBarChartData().then(() => {
                if (typeof this.chart !== "undefined") {
                    this.chart.forceRedraw();
                }
            });
        } else {
            this.updateZonesTable();
        }
    }

    private fetchMissingZones(currentZones: ZipZoneSales): Promise<ZipZoneSale[]> {
        return new Promise((resolve) => {
            if (currentZones.sales.length >= 10) {
                return resolve([]);
            }

            const zonesFromFilter = this.filterStateService.getFilterValue(FilterName.zones) as string[];
            // If zones were selected from the filter, verify selected zones
            // are included in the sales data even if dealer does not sale to those zones.
            const missingZonesIds: string[] = zonesFromFilter.reduce((m, sys_code) => {
                if (!currentZones.sales.find(fz => fz.sys_code === sys_code)) {
                    m.push(sys_code);
                }
                return m;
            }, []);

            if (missingZonesIds.length === 0) {
                return resolve([]);
            }

            const filterOverride = {};
            if (this.dateRangeType === DateRangeTypes.MONTHS) {
                filterOverride["dateRangeStart"] = this.minDate;
                filterOverride["dateRangeEnd"] = this.maxDate;
            }

            return this.salesDataService.getSalesTotalsZones(missingZonesIds, filterOverride)
                .toPromise()
                .then((zones: ZipZoneSale[]) => resolve(zones));
        });
    }

    private hasZone(zonesRef: ZipZoneSales, zone: ZipZoneSale): boolean {
        return !!zonesRef.sales.find(s => zone.sys_code === "0" ?
            zone.sys_code === s.sys_code && zone.dma === s.dma :
            s.sys_code === zone.sys_code);
    }

    sumGroup(sales: any[]): any[] {
        const groupCount = this.groupSize + 1;
        const groupedSales = [];

        if (sales.length === 0) {
            return [0, 0, 0, 0];
        }

        if (isNaN(this.groupSize)) {
            return sales;
        }

        while (sales.length > 0) {
            groupedSales.push(sales.splice(0, groupCount));
            sales.splice(0, 12 - groupCount);
        }
        return groupedSales.map(
            group => group.reduce(
                (a, b) => a + b
            )
        );

    }
    averageGroup(shares: any[]): any[] {
        const groupCount = this.groupSize + 1;
        const groupedShares = [];

        if (shares.length === 0) {
            return [0, 0, 0, 0];
        }

        while (shares.length > 0) {
            groupedShares.push(shares.splice(0, groupCount));
            shares.splice(0, 12 - groupCount);
        }
        return groupedShares.map(
            group => group.reduce(
                (a, b) => a + b
            ) / groupCount
        );
    }
    groupDates(dates: string[]): string[] {
        const groupCount = this.groupSize + 1;
        const groupedDates = [];
        if (dates.length === 0) {
            return ["N/A", "N/A", "N/A"];
        }

        while (dates.length > 0) {
            groupedDates.push(dates.splice(0, groupCount));
            dates.splice(0, 12 - groupCount);
        }
        return groupedDates.map(
            group => group
        );
    }

    setupDateRanges(): void {
        const dateRange = this.filterStateService.getFilterValue(FilterName.dateRanges);
        this.minDate = dateRange[0];
        this.maxDate = dateRange[1];
        // calculate grouping
        const dateEnd = moment(this.maxDate, "YYYYMM");
        const dateStart = moment(dateRange[0], "YYYYMM");

        this.groupSize = dateEnd.diff(dateStart, "months");
    }
}
