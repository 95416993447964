import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: "BoldText"
})
export class BoldTextPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: string): any {
        const regex = /[\*][\w\W]*[\*]/gmi;
        return this.sanitize(this.replace(value, regex));
    }

    replace(str, regex) {
        let matched = str.match(regex);
        if (matched) {
            matched.forEach(foundString => {
                foundString = foundString.substring(1, foundString.length - 1);
                str = str.replace(regex, `<b>${foundString}</b>`);
            });
        }
        return str;
    }

    sanitize(value: string) {
        return this.sanitizer.sanitize(SecurityContext.HTML, value);
    }
}
