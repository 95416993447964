import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard, UnAuthGuard } from "app/core/internal";

const appRoutes: Routes = [
    { path: "", redirectTo: "/home", pathMatch: "full" },
    { path: "home", loadChildren: () => import("@at/home/module").then(m => m.HomeModule), canActivate: [AuthGuard] },
    { path: "dealers", redirectTo: "/map", pathMatch: "full" },
    { path: "mapview", redirectTo: "/map", pathMatch: "full" },
    { path: "map-view", redirectTo: "/map", pathMatch: "full" },
    { path: "signin", loadChildren: () => import("@at/signin/module").then(m => m.SigninModule), canActivate: [UnAuthGuard] },
    { path: "map", loadChildren: () => import("@at/geoanalytics/module").then(m => m.GeoAnalyticsModule), canActivate: [AuthGuard] },
    { path: "trends", loadChildren: () => import("./trends/trends.module").then(m => m.TrendsModule), canActivate: [AuthGuard] },
    { path: "insights", loadChildren: () => import("./insights/insights.module").then(m => m.InsightsModule), canActivate: [AuthGuard] },
    { path: "presentation/composite", loadChildren: () => import("./composite/composite.module").then(m => m.CompositeModule), canActivate: [AuthGuard] },
    { path: "presentation/make-vs-make", loadChildren: () => import("./make-vs-make/make-vs-make.module").then(m => m.MakeVsMakeModule), canActivate: [AuthGuard] },
    { path: "presentation/make-vs-segment", loadChildren: () => import("./make-vs-segment/make-vs-segment.module").then(m => m.MakeVsSegmentModule), canActivate: [AuthGuard] },
    { path: "presentation/market-share", loadChildren: () => import("./marketshare/marketshare.module").then(m => m.MarketShareModule), canActivate: [AuthGuard] },
    { path: "admin", loadChildren: () => import("@at/admin/module").then(m => m.AdminModule), canActivate: [AuthGuard] },
    { path: "not-found", loadChildren: () => import("@at/notfound/module").then(m => m.NotFoundModule) },
    { path: "**", redirectTo: "/not-found" }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
