import { Injectable } from "@angular/core";

import { FilterName } from "../models/filter-name.enum";
import { FilterStateService } from "./filter-state.service";
import { MetadataService } from "./metadata.service";
import { SalesDataService } from "./sales-data.service";

@Injectable()
export class MarketMaxService {

    constructor(
        private salesDataService: SalesDataService,
        private metadataService: MetadataService,
        private filterStateService: FilterStateService
    ) { }

    public async getMarketMax(): Promise<number> {

        const salesQueryOptions = this.salesDataService.createDefaultSalesQueryOptions();
        salesQueryOptions.group = [
            "dealer_id",
            "dma"
        ];

        salesQueryOptions.unknown = "false";

        // Add filter overrides
        const filterOverrides = this.getFilterOverrides();
        const sales: object[] = await this.salesDataService.getSalesDetails(null, salesQueryOptions, filterOverrides).toPromise();

        if (!sales || sales.length < 2) {
            return null;
        }

        // Skip the first result as it is always the null header
        let marketMax = 0;
        sales.forEach(dealerSale => {
            let dealerMax = 0;
            for (const key in dealerSale) {
                if (key.indexOf("_volume") > -1) {
                    dealerMax += +dealerSale[key];
                }
            }
            if (dealerMax > marketMax) {
                marketMax = dealerMax;
            }
        });

        return marketMax;
    }


    private getFilterOverrides(): object {
        return {
            dealer_zip: [],
            makes: [],
            models: [],
            not_dealer_zip: [],
            not_dealers: [],
            segments: [],
            zips: [],
            zones: [],
            volume: {},
            [FilterName.dealers.toString()]: []
        };
    }
}
