import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { Dealer } from "app/core/models/dealers.model";
import { HeatmapScaleLevels } from "app/core/services/sales-data.service";
import { environment } from "environment";
import { Feature, FeatureCollection } from "geojson";
import { debounce, flatten } from "lodash";
import { GeoJSONGeometry, LngLatBoundsLike } from "mapbox-gl";
import { Subscription, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { ChartColor } from "../charts/chart/chart-color.utils";
import { MapUtils, MapUtilsResponse } from "../map/map-utils";
import { MetricsService } from "app/core/services/metrics.service";

@Component({
    selector: "mini-map",
    templateUrl: "./mini-map.component.html",
    styleUrls: ["./mini-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniMapComponent implements OnDestroy, OnChanges, AfterViewInit {
    @Input() spotlightedDealer: Dealer;
    @Input() renderedZips: string[][] = [];
    @Input() zoneZips: [][] = [];
    @Input() dealers: Dealer[];
    @Input() animateHighlight = false;
    @Input() radiusRingSize = 0; // 0 => radius ring is turned off
    @Input() showHeatmapLegend = false;
    @Input() useHeatmapColors = false;
    @Input() disclaimer = "";
    @Input() heatmapScales: HeatmapScaleLevels[];
    @Input() zipzoneflag: String = "zip";
    @Input() useNumberPins = false;

    renderedZones: any[] = [];
    map: mapboxgl.Map;
    mapUtils: MapUtils = new MapUtils();

    radiusRingBoundingBox: LngLatBoundsLike;

    protected allColorLayers = ChartColor.getColorPalette(15);
    protected mapInitialized = false;
    ringText = "";

    protected zipCodeLayerKey = "ZCTA5CE10"; // magic key from us census shape file data
    protected mapConfig: mapboxgl.MapboxOptions = {
        style: "mapbox://styles/mediasolutions/ck3egoxxl0a8e1dqfr2wsyddf", // Auto Tool 3.4 Prod - Owner: Tim Case (migrated to newer Mapbox Studio)
        zoom: 3,
        minZoom: 2,
        center: [-98.35, 39.50], // [lng,lat] Geographic center of continental usa
        attributionControl: false,
        interactive: true
    };

    requireLayersForDealer = false;
    protected spotlightedDealerLayerId = "dealerPinsSpotlight";
    protected highlightSourceId = "dealerRadiusSource";
    protected highlightLayerId = "dealerHighlightLayer";

    protected animationTimer: ReturnType<typeof setInterval | typeof clearTimeout>;
    protected resizeSubscription: Subscription;
    styleSourceKeys: { sourceId: any; sourceLayerId: any };
    protected zonePinLayerLoaded = false;
    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected metricsService: MetricsService,
        protected elementRef: ElementRef
    ) {
        (mapboxgl as any).accessToken = environment.mapbox.accessToken;

        this.resizeSubscription = fromEvent(window, "resize")
            .pipe(debounceTime(100))
            .subscribe(() => {
                if (this.mapInitialized) {
                    this.fitMapToZips();
                }
            });
    }

    initializeMap(): void {
        const startTime = Date.now();
        // Selected zipcodes do not exist in the minimap since they are not interactive
        this.map.setFilter("zipcodes-select", ["==", this.zipCodeLayerKey, ""]);
        this.map.setFilter("zipcodes-select-border", ["==", this.zipCodeLayerKey, ""]);

        const saveStyleSourceId = (sourceData) => {
            if (sourceData.isSourceLoaded && sourceData.source.type === "vector") {
                this.map.off("sourcedata", saveStyleSourceId);
                this.styleSourceKeys = {
                    sourceId: sourceData.sourceId,
                    sourceLayerId: sourceData.style.stylesheet.layers.find(l => l.id.includes("zipcodes-heatmap"))["source-layer"]
                };
            }
        };
        this.map.on("sourcedata", saveStyleSourceId);

        this.loadPins();

        // Add source for radius highlight circles.
        this.mapUtils.addPinHighlightLayer(this.map, this.highlightLayerId, this.highlightSourceId);

        if (this.dealers) {
            if (this.dealers.length) {
                this.addLayersForDealers();
            } else {
                this.requireLayersForDealer = true;
            }
        }

        this.mapUtils.addEmptyIconLayer(this.map, this.spotlightedDealerLayerId, "dealerPinSpotlightIcon", ["==", "dealer_id", ""]);
        this.addZoneBorderLayer(this.map);

        this.mapInitialized = true;
        this.updateRenderedPins();
        this.updatePinLayerFilters();
        this.updateRenderedZips();

        this.map.on("moveend", (data) => {
            this.updateZoneFeatures();
        });
        this.map.on("zoomend", (data) => {
            this.updateZoneFeatures();
        });
        this.map.once("idle", () => {
            this.updateZoneFeatures();
            debounce(this.fitMapToZips.bind(this), 25)();
        }
        );
        this.metricsService.sendMetric({
            metricName: "initialize_map",
            metricValue: (Date.now() - startTime) / 1000.0,
            unit: "Seconds",
            dimensions: [
                {
                    Name: "Map Type",
                    Value: "Mini Map"
                }
            ]
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const startTime = Date.now();
        if (this.mapInitialized) {
            this.updateRenderedPins();
            this.updatePinLayerFilters();
            this.updateRenderedZips();
            if(!this.spotlightedDealer){
                this.radiusRingSize = 0;
            }
            this.ringText = this.radiusRingSize ? `  Showing a ${this.radiusRingSize} mile ring around the primary dealer.` : "";

        }
        this.metricsService.sendMetric({
            metricName: "change_map",
            metricValue: (Date.now() - startTime) / 1000.0,
            unit: "Seconds",
            dimensions: [
                {
                    Name: "Map Type",
                    Value: "Mini Map"
                }
            ]
        });
    }

    public fitMapToZips(): void {
        let updateZoom = false;

        let combinedCoordinates = this.mapUtils.determineCoordinatesForDealers(this.spotlightedDealer, this.dealers);

        const longLatBounds = new mapboxgl.LngLatBounds(combinedCoordinates.sw, combinedCoordinates.ne);

        // Fit to selected dealers.
        if (this.spotlightedDealer || (this.dealers && this.dealers.length)) {
            updateZoom = true;
        }

        const features = this.getRenderedFeatures();

        if (features.length > 0 && this.renderedZips) {
            // Fit to selected zips.
            for (let i = 0; i < features.length; i++) {
                const geometry = features[i].geometry;
                const polygonBorders = geometry["coordinates"];

                for (let j = 0; j < polygonBorders.length; j++) {
                    const polygonBorder = polygonBorders[j];
                    for (let z = 0; z < polygonBorder.length; z++) {
                        const coordinates = polygonBorder[z];
                        if (geometry.type === "MultiPolygon") {
                            for (let a = 0; a < coordinates[a].length; a++) {
                                const multiPolygonCoordinates = coordinates[a];
                                const polygonLongLat = new mapboxgl.LngLat(multiPolygonCoordinates[0], multiPolygonCoordinates[1]);
                                longLatBounds.extend(polygonLongLat);
                            }
                        } else {
                            const polygonLongLat = new mapboxgl.LngLat(coordinates[0], coordinates[1]);
                            longLatBounds.extend(polygonLongLat);
                        }
                    }
                }
            }
            updateZoom = true;
        }

        // Fit to radius ring
        if (this.radiusRingSize && this.radiusRingBoundingBox) {
            const radiusRingBoundingBox = flatten(this.radiusRingBoundingBox as number[][]);
            const radiusRingLongLat = new mapboxgl.LngLat(radiusRingBoundingBox[0], radiusRingBoundingBox[1]);

            longLatBounds.extend(radiusRingLongLat);
            updateZoom = true;
        } else if(this.spotlightedDealer && !this.dealers) {
            const options = {
                center: [this.spotlightedDealer.dealer_longitude, this.spotlightedDealer.dealer_latitude],
                radiusInMiles: 50
            };
            this.radiusRingBoundingBox = this.mapUtils.expandBoundingBox(this.map, options).bbox;
            const radiusRingBoundingBox = flatten(this.radiusRingBoundingBox as number[][]);
            const radiusRingLongLat = new mapboxgl.LngLat(radiusRingBoundingBox[0], radiusRingBoundingBox[1]);

            longLatBounds.extend(radiusRingLongLat);
            updateZoom = true;
        }

        if (updateZoom) {
            // padding is large enough to account for the spotlight dealer's highlighted pin to be at the end of the map and not cut off
            this.map.fitBounds(longLatBounds, { padding: 30, animate: this.animateHighlight });
        }
    }

    addZoneBorderLayer(map: mapboxgl.Map, layerId: string = "zone-border", sourceId: string = "zone-border-source", fillColor?: string): MapUtilsResponse {
        map.addSource(sourceId, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: []
            }
        });
        map.addLayer({
            id: layerId,
            type: "line",
            layout: {
                visibility: "visible",
                "line-cap": "round",
                "line-join": "round",
            },
            paint: {
                "line-color": ["get", "color"],
                "line-width": 2
            },
            source: sourceId
        });
        if (fillColor) {
            map.addLayer({
                id: `${layerId}-paint`,
                type: "fill",
                layout: {
                    visibility: "visible"
                },
                paint: {
                    "fill-color": fillColor,
                    "fill-opacity": 0.3
                },
                source: sourceId
            });
        }
        // eslint-disable-next-line object-shorthand
        return { layerId: layerId, sourceId: sourceId };
    }

    public updateRenderedZips(): void {
        if (this.renderedZips.length < 1) {
            return;
        }

        if (this.useHeatmapColors) {
            this.updateHeatmapZips();
        } else {
            this.updateColoredZips();
        }
    }

    public updateHeatmapZips(): void {
        const zipcodesHeatmapLayers = [];

        for (let i = this.renderedZips.length - 1; i > 0; i--) {
            const zips = this.renderedZips[i];
            const filter = ["in", this.zipCodeLayerKey, ...zips];

            if (typeof this.map.getLayer("zipcodes-heatmap-" + i) !== "undefined") {
                this.map.setFilter("zipcodes-heatmap-" + i, filter);
                zipcodesHeatmapLayers.push("zipcodes-heatmap-" + i);
            }
        }
        if (this.zipzoneflag === "zip") {
            this.map.setFilter("zipcodes-names", ["in", this.zipCodeLayerKey].concat(flatten(this.renderedZips)));
            this.toggleLayerVisibility(["zipcodes-names"], "visible");
        }
        this.toggleLayerVisibility(zipcodesHeatmapLayers, "visible");
    }

    public updateZoneFeatures(): void {
        const startTime = Date.now();
        if (this.styleSourceKeys) {
            this.renderedZones.length = 0;

            const colors = ChartColor.getColorPalette(0);

            for (let i = 0; i < this.zoneZips.length; i++) {
                const zips = this.zoneZips[i];

                if (zips && zips.length > 0) {
                    const feature = this.mapUtils.reduceZipsToZones(this.map, zips, this.styleSourceKeys, `#${colors[i]}`);
                    if (feature) {
                        this.renderedZones.push(feature);
                    }
                }
            }
            const zonepins = this.mapUtils.getCentroids(this.map, this.renderedZones);
            if (!this.zonePinLayerLoaded) {
                this.addLayersForZonePins();
            }

            if (this.renderedZones) {
                this.renderedZones.forEach((z, i) => {
                    const zoneLayerId = `zone-pin-${z.properties.id}`;
                    const data: FeatureCollection = {
                        type: "FeatureCollection",
                        features: zonepins
                    };
                    (this.map.getSource(zoneLayerId) as mapboxgl.GeoJSONSource).setData(data.features[i]);
                });
            }

            (this.map.getSource(
                "zone-border-source"
            ) as mapboxgl.GeoJSONSource).setData({ type: "FeatureCollection", features: this.renderedZones });
        }
        this.metricsService.sendMetric({
            metricName: "update_zone_features_map",
            metricValue: (Date.now() - startTime) / 1000.0,
            unit: "Seconds",
            dimensions: [
                {
                    Name: "Map Type",
                    Value: "Mini Map"
                }
            ]
        });
    }

    public updateColoredZips(): void {
        const mapColors = ChartColor.getColorPalette(this.renderedZips.length);
        const unusedColors = this.allColorLayers.filter(color => !mapColors.includes(color));
        const mapColorsLayers = [];
        const unusedColorsLayers = [];

        for (let i = 0; i < unusedColors.length; i++) {
            this.map.setFilter("zipcodes-colors-" + unusedColors[i], ["==", this.zipCodeLayerKey, ""]);
            unusedColorsLayers.push("zipcodes-colors-" + unusedColors[i]);
        }

        for (let i = 0; i < mapColors.length; i++) {
            this.map.setFilter("zipcodes-colors-" + mapColors[i], ["in", this.zipCodeLayerKey].concat(this.renderedZips[i]));
            mapColorsLayers.push("zipcodes-colors-" + mapColors[i]);

        }

        this.toggleLayerVisibility(unusedColorsLayers, "none");
        this.toggleLayerVisibility(mapColorsLayers, "visible");
    }

    toggleLayerVisibility(layers: string[], visibility: string): void {
        for (let i = 0; i < layers.length; i++) {
            this.map.setLayoutProperty(layers[i], "visibility", visibility);
        }
    }

    getRenderedFeatures(): Feature<GeoJSONGeometry>[] {
        let features;

        if (this.useHeatmapColors) {
            // for heatmap version find all heatmap layers except the base 0 layer
            features = this.map.queryRenderedFeatures().filter(feature => feature["layer"]["id"].indexOf("zipcodes-heatmap-") >= 0);
            features = features.filter(feature => feature["layer"]["id"].indexOf("zipcodes-heatmap-0") < 0);
        } else {
            // find all the colored layers
            features = this.map.queryRenderedFeatures().filter(feature => feature["layer"]["id"].indexOf("zipcodes-colors-") >= 0);
        }

        return features;
    }

    public updateRenderedPins(): void {
        const onLoad = (loaded) => {
            if (loaded.dataType === "source" && loaded.isSourceLoaded) {
                this.map.off("data", onLoad);
                this.updatePinLayerFilters();

                debounce(this.fitMapToZips.bind(this), 25)();
            }
        };
        this.map.on("data", onLoad);
    }

    updatePinLayerFilters(): void {
        const features = [];

        if (this.requireLayersForDealer && this.dealers.length) {
            this.addLayersForDealers();
            this.map.moveLayer(this.spotlightedDealerLayerId);
            this.requireLayersForDealer = false;
        }

        if (typeof this.dealers !== "undefined" && this.dealers.length >= 1 && this.spotlightedDealer) {
            const filteredDealers = this.dealers.filter(dealer => dealer.dealer_id !== this.spotlightedDealer.dealer_id);

            filteredDealers.forEach((d, i) => {
                const dealerLayerId = this.getDealerLayerId(d.dealer_id);
                const data = this.mapUtils.createDataSource([d]);
                features.push.apply(features, data.features);
                (this.map.getSource(dealerLayerId) as mapboxgl.GeoJSONSource).setData(data);
                this.map.setFilter(dealerLayerId, ["==", "dealer_id", d.dealer_id]);

            });
        }

        let spotlightPinFilter = 0;
        if (this.spotlightedDealer) {
            spotlightPinFilter = this.spotlightedDealer.dealer_id;
            const spotlightData = this.mapUtils.createDataSource([this.spotlightedDealer]);
            features.push.apply(features, spotlightData.features);
            (this.map.getSource(this.spotlightedDealerLayerId) as mapboxgl.GeoJSONSource).setData(spotlightData);
            this.map.setCenter([this.spotlightedDealer.dealer_longitude, this.spotlightedDealer.dealer_latitude]);
            this.map.setZoom(5);
            this.resizeMap();
        }

        // Add radius ring, if the "radiusRingSize" is greater than 0
        if (this.spotlightedDealer && this.radiusRingSize) {
            this.mapUtils.removeLayer(this.map, "radius-ring"); // remove ring layer
            this.mapUtils.removeSource(this.map, "radius-ring"); // remove ring Source
            const options = {
                center: [this.spotlightedDealer.dealer_longitude, this.spotlightedDealer.dealer_latitude],
                radiusInMiles: this.radiusRingSize
            };
            this.radiusRingBoundingBox = this.mapUtils.addRadiusRingLayer(this.map, options).bbox; // add new ring
        }

        this.map.setFilter(this.spotlightedDealerLayerId, ["==", "dealer_id", spotlightPinFilter]);
        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit(): void {
        this.mapConfig.container = this.elementRef.nativeElement.querySelector(".minimap");
        this.map = new mapboxgl.Map(this.mapConfig).addControl(new mapboxgl.AttributionControl({ compact: true }));
        this.map.once("load", this.initializeMap.bind(this));
        if(!this.spotlightedDealer){
            this.radiusRingSize = 0;
        }
        this.ringText = this.radiusRingSize ? `  Showing a ${this.radiusRingSize} mile ring around the primary dealer.` : "";
    }

    ngOnDestroy(): void {
        clearTimeout(this.animationTimer as any);
        this.map.remove();
        this.resizeSubscription.unsubscribe();
    }

    resizeMap(): void {
        setTimeout(() => {
            if (this.map) {
                this.map.resize();
            }
        }, 0);
    }

    protected getPinColorKey(hex: string): string {
        return `dealer-pin-${hex}`;
    }

    protected getDealerLayerId(dealerId: number): string {
        return `dealer_layer_${dealerId}`;
    }

    protected loadPins(): void {
        // Spotlight pins.
        const pinSpotlight = document.getElementById("dealer-pin-spotlight") as HTMLImageElement;
        this.map.addImage("dealerPinSpotlightIcon", pinSpotlight);

        // Competitor Pins.
        if (this.useNumberPins) {
            for (let i = 1; i < 6; i++) {
                const key = `dealer-pin-${i}`;
                const pin = document.getElementById(key) as HTMLImageElement;
                if (pin) {
                    this.map.addImage(key, pin);
                } else {
                    console.warn(`Failed to find pin number for  ${i}`);
                }
            }
        } else {
            ChartColor.getColorPalette(5).forEach((hex: string) => {
                const key = this.getPinColorKey(hex);
                const pin = document.getElementById(key) as HTMLImageElement;
                if (pin) {
                    this.map.addImage(key, pin);
                } else {
                    console.warn(`Failed to find pin for color ${hex}`);
                }
            });
        }
    }

    protected addLayersForDealers(): void {
        if (this.useNumberPins) {
            const filteredDealers = this.dealers.filter(dealer => dealer.dealer_id !== this.spotlightedDealer.dealer_id);

            for (let i = 0; i < filteredDealers.length; i++) {
                const dealer = filteredDealers[i];
                const pinKey = `dealer-pin-${i + 1}`;
                this.mapUtils.addEmptyIconLayer(this.map, this.getDealerLayerId(dealer.dealer_id), pinKey, ["==", "dealer_id", ""]);

            }
        } else {
            ChartColor.getColorPalette(this.dealers.length).forEach((hex: string, i: number) => {
                const dealer = this.dealers[i];
                const pinKey = this.getPinColorKey(hex);
                this.mapUtils.addEmptyIconLayer(this.map, this.getDealerLayerId(dealer.dealer_id), pinKey, ["==", "dealer_id", ""]);
            });
        }
    }

    protected addLayersForZonePins(): void {
        for (let i = 0; i < this.renderedZones.length; i++) {
            const zone = this.renderedZones[i];
            const pinKey = `dealer-pin-${i + 1}`;
            this.mapUtils.addEmptyIconLayer(this.map, `zone-pin-${zone.properties.id}`, pinKey);
        }
        if (this.renderedZones.length > 0) {
            this.zonePinLayerLoaded = true;
        }
    }
}
