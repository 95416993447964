import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { isEqual } from "lodash";
import { Subscription } from "rxjs";

export interface IFilterToggleState {
    name: FilterName;
    value: string;
}

@Component({
    selector: "filter-toggle-button",
    templateUrl: "./filter-toggle-button.component.html",
    styleUrls: ["./filter-toggle-button.component.scss"]
})
export class FilterToggleButtonComponent implements OnInit, OnDestroy, OnChanges {
    @Input() filterName: FilterName;
    @Input() label: string;
    @Input() optionOne;
    @Input() optionOneLabel;
    @Input() optionTwo;
    @Input() optionTwoLabel;
    @Input() set optionTwoSelected(value: boolean) {
        this._optionTwoSelected = value;
        this.pushValue();
    };
    get optionTwoSelected(): boolean {
        return this._optionTwoSelected;
    };
    @Input() disabled = false;
    private _optionTwoSelected = true;

    currentValue: any;

    cssId = "";

    private filterStateServiceSubscription: Subscription;
    @Output() toggle: EventEmitter<IFilterToggleState> = new EventEmitter<IFilterToggleState>();

    constructor(
        private filterStateService: FilterStateService) {
    }

    ngOnInit(): void {
        // Angular interpolates the object null as an empty string, which creates confusion when the
        // default value needs to be an empty string.
        this.optionOne = this.optionOne === "null" ? null : this.optionOne;
        this.optionTwo = this.optionTwo === "null" ? null : this.optionTwo;

        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.filtersUpdated([this.filterName.toString()]);
        this.optionOneLabel = this.optionOneLabel || this.optionOne;
        this.optionTwoLabel = this.optionTwoLabel || this.optionTwo;
        this.cssId = this.label.toLowerCase().replace(" ", "-");
    }

    ngOnDestroy(): void {
        this.filterStateServiceSubscription.unsubscribe();
    }

    ngOnChanges(): void {
        this.filtersUpdated([this.filterName.toString()]);
    }

    filtersUpdated(changes: string[]): void {
        if (this.filterName && changes.includes(this.filterName.toString())) {
            const current = this.filterStateService.getFilterValue(this.filterName);
            this.optionTwoSelected = isEqual(current, this.optionTwo);
            this.currentValue = { name: this.filterName, value: current };
        }
    }

    click(): void {
        if (this.disabled) {
            return;
        }
        this.optionTwoSelected = !this.optionTwoSelected;
    }

    pushValue(): void {
        const next = { name: this.filterName, value: this.optionTwoSelected ? this.optionTwo : this.optionOne };
        if (!this.currentValue || (next.name !== this.currentValue.name || next.value !== this.currentValue.value)) {
            this.currentValue = next;
            this.toggle.emit(this.currentValue);
        }
    }
}
