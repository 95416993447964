<div (click)="openPanel()" id="filter-expand-button-{{filterName}}" class="filter-expand-button noselect"
    [class.selected]="selected"
    [class.disabled]="disabledState">
    <div class="details">
        <div class="label">{{label}}</div>
        <ng-template #selectedFilters>
            <div *ngFor="let value of filterValues">
                {{value}}<br />
            </div>
        </ng-template>
        <div class="selections-count" [class.salesRange]="filterName === FilterName.sales_range"
            [class.invalid]="invalidFilters"
            *ngIf="!disabledState && selectionsCount !== '' && selectionsCount !== '0' && !selected"
            [placement]="'right'" [dTooltip]="selectedFilters">
            {{selectionsCount}}
        </div>
    </div>
    <div class="icon" *ngIf="!selected">
        <span class="arrow"></span>
    </div>
</div>
