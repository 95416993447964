import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[dClickBlur]"
})
export class ClickBlurDirective {
    constructor(private _elementRef: ElementRef) {
    }

    @HostListener("click")
    public onClick(): void {
        this._elementRef.nativeElement.blur();
    }
}
