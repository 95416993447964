// import { MonthMap } from "@at/core";
import { MonthMap } from "app/core/models/month-mapping";
import * as moment from "moment";

export class DateUtils {

    static getPreviousTwoYears(date: string, oldestDateYear?: string, use_sales_data: boolean = false): string {
        const year = parseInt(date.substring(0, 4), 10);
        const year1 = oldestDateYear ? (year - 1) >= parseInt(oldestDateYear, 10) ? year - 1 : undefined : year - 1;
        const year2 = oldestDateYear ? (year - 2) >= parseInt(oldestDateYear, 10) ? year - 2 : undefined : year - 2;
        let yearReturn = "";
        if (year1) {
            yearReturn += oldestDateYear && !use_sales_data ? `${moment(date).endOf("month").format("MMM DD")}, ${year1}` : `${year1}`;
            if (year2) {
                yearReturn += oldestDateYear && !use_sales_data ? `; ${moment(date).endOf("month").format("MMM DD")}, ${year2}` : `, ${year2}`;
            }
        }
        return yearReturn;
    }

    static addYear(date: string, count: number): string {
        let year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(4, 6), 10);
        year += count;
        return month ? String(year) + String(month).padStart(2, "00") : "" + year;
    }

    static appendLastDayOfMonth(date: string): string {
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(4, 6), 10);
        let day = 0;
        if (month === 2) {
            day = year % 4 === 0 ? 29 : 28;
        } else if (month === 4 || month === 6 || month === 9 || month === 11) {
            day = 30;
        } else {
            day = 31;
        }

        return date + String(day).padStart(2, "00");
    }

    static monthYearAddMonths(date: string, count: number): string {
        let tempDate = date;
        for (let i = 0; i < Math.abs(count); i++) { // including original date
            tempDate = this.monthYearNavigate(tempDate, count < 0);
        }
        return tempDate;
    }

    static monthYearNavigate(date: string, previous: boolean = true): string {
        if (!date) {
            return;
        }
        let year = parseInt(date.substring(0, 4), 10);
        let month = parseInt(date.substring(4, 6), 10);
        if (previous) {
            month--;
            if (month === 0) {
                month = 12;
                year--;
            }
        } else {
            month++;
            if (month === 13) {
                month = 1;
                year++;
            }
        }
        const temp = String(year) + String(month).padStart(2, "00");
        return temp;
    }

    static previousYearInMonths(date: string): string[] {
        const yearMonths = [];
        for (let i = 0; i < 12; i++) {
            yearMonths.push(this.monthYearAddMonths(date, -i));
        }
        return yearMonths;
    }

    static monthYearInMonths(date: string, months: number): string[] {
        const yearMonths = [];
        for (let i = 0; i < months; i++) {
            yearMonths.push(this.monthYearAddMonths(date, -i));
        }
        return yearMonths;
    }

    static yearInMonths(date: string, months: number): string[] {
        const yearMonths = [];
        for (let i = 0; i < months; i++) {
            yearMonths.push(this.monthYearAddMonths(date, i));
        }
        return yearMonths;
    }



    static prettyDate(date: string): string {
        if (date.length === 6) { // if date has months
            const year = date.slice(0, 4);
            const month = date.slice(4, 6);
            date = MonthMap[month].slice(0, 3) + " " + year;
        } else if (date.length === 8) { // Sales Date has Day
            const year = date.slice(0, 4);
            const month = date.slice(4, 6);
            const day = date.slice(6, 8);
            date = MonthMap[month].slice(0, 3) + " " + day + " " + year;
        }
        return date;
    }

    static shortMonth(date: string): string {
        return MonthMap[moment(date, "YYYYMM").format("MM")].slice(0, 3);
    }

    static ytdLongDate(date: string, years: number = 3, oldestDate?: string): string {
        const momentDate = moment(date, "YYYYMM");
        const janDate = momentDate.clone().startOf("year");
        const endDate = momentDate.clone().endOf("month");
        let oldestMoment;
        if (oldestDate) {
            oldestMoment = moment(oldestDate);
        }
        const addYears = x => {
            let yearString = "";
            let dateString = "";
            for (let i = x; i > 1; i--) {
                if (oldestDate !== undefined || oldestMoment < janDate) {
                    dateString += "; " + endDate.subtract(1, "year").format("ll");
                } else {
                    yearString += ", " + momentDate.subtract(1, "year").format("YYYY");
                }
            }
            return dateString + yearString;
        };
        return this.ytdMonthRange(date) + " for " + momentDate.format("YYYY") + addYears(years);
    }

    static ytdMonthRange(date: string): string {
        return date.slice(4, 6) === "01" ? MonthMap["01"] : MonthMap["01"].slice(0, 3) + " - " + DateUtils.shortMonth(date);
    }

    static displayDateRange(date: string, dateRangeType: string, yearsBack: number = 3, dateRanges?: string[]): string {
        let titleDate = "";

        if (dateRangeType === "rolling" && (date.length === 6 || date.length === 8)) {
            let minDate = `${DateUtils.monthYearAddMonths(date, -((yearsBack * 12) - 1))}`;
            if (date.length === 8) {
                minDate += "01";
            }
            titleDate = `${DateUtils.prettyDate(minDate)} - ${DateUtils.prettyDate(date.slice())}`;
        } else if (dateRangeType === "ytd" && (date.length === 6 || date.length === 8)) {
            titleDate = DateUtils.ytdLongDate(date, yearsBack);
        } else {
            titleDate = date.slice(0, 4);
        }

        return titleDate;
    }

    static yyyy_mm_dd(date: string): string {
        return moment(date).format("YYYY-MM-DD");
    }
    //formatting for weekly sales data in simplified flow
    static mm_dd_yy(date: string, dateRangeType: string, maxDate: string): string[] {
        const maxMoment = moment(maxDate, "YYYYMMDD");

        if (dateRangeType === "rolling") {
            const end = moment(date, "YYYYMMDD").endOf("month");
            const start = moment(end).subtract(11, "months").format("M/1/YY");

            return [start, (maxMoment.year() === end.year() && maxMoment.isBefore(end) ? maxMoment : end).format("M/D/YY")];
        }   else if (dateRangeType === "ytd") {
            const start = moment(date).startOf("year").format("M/D/YY");
            const end = moment(date, "YYYYMMDD").endOf("month");
            return [start, (maxMoment.year() === end.year() && maxMoment.isBefore(end) ? maxMoment : end).format("M/D/YY")];
        }   else if (dateRangeType === "calendar") {
            const end = moment(date, "YYYYMMDD").format("YYYY");
            return [end];
        }   else {
            return [date];
        }
    }

    static popUpDateRange(useSalesData: boolean, date: string, dateRangeType: string, firstUpdate?: Date, lastUpdate?: Date, unwrap?: boolean): string {
        let dateString = "";
        if (dateRangeType === "rolling") {
            const end = moment(date, "YYYYMM");
            // This line break and tabbing format might look weird but it creates the right
            // spacing and line break to be used for the template.
            dateString = useSalesData ? `${moment(end).subtract(11, "months").format("M/D/YY")}${unwrap ? "-" : "-\n"}${end.format("M/D/YY")}` : `${moment(end).subtract(11, "months").format("M/YY")}${unwrap ? "-" : "-\n"}${end.format("M/YY")}`;
        } else if (dateRangeType === "ytd") {
            const end = moment(date, "YYYYMM");
            // eslint-disable-next-line quotes
            dateString = useSalesData ? `${moment(end).startOf("month").format("1/1/YY")}${unwrap ? `-` : `-\n`}${end.endOf("month").format("M/D/YY")}` : `${moment(end).format("1/YY")}${unwrap ? `-` : `-\n`}${end.format("M/YY")}`;
        } else if (dateRangeType === "months") {
            const tmpDateArray = date.split("-");
            if (tmpDateArray.length > 1) {
                const end = moment(tmpDateArray[1], "YYYYMM");
                // eslint-disable-next-line quotes
                dateString = useSalesData ? `${moment(tmpDateArray[tmpDateArray.length - 2], "YYYYMM").startOf("month").format("M/D/YY")}${!unwrap ? `-\n` : `-`}${end.endOf("month").format("M/D/YY")}` : `${moment(tmpDateArray[tmpDateArray.length - 2], "YYYYMM").format("M/YY")}${!unwrap ? `-\n` : `-`}${end.format("M/YY")}`;
            } else {
                const end = moment(date, "YYYYMM");
                // eslint-disable-next-line quotes
                dateString = useSalesData ? `${moment(end).subtract(11, "months").startOf("month").format("M/D/YY")}${unwrap ? `-` : `-\n`}${end.endOf("month").format("M/D/YY")}` : `${moment(end).subtract(11, "months").format("M/YY")}${unwrap ? `-` : `-\n`}${end.format("M/YY")}`;
            }
        } else {
            dateString = moment(date, "YYYYMM").format("M/YY");
        }
        const dateArray = dateString.split("-");
        if (dateArray.length > 1 && useSalesData) {
            return this.formatForSalesData(dateArray[dateArray.length - 1].trim(), dateArray[0].trim(), firstUpdate , lastUpdate );
        }
        return dateString;
    }

    static formatDateRange(date: string, dateRangeType: string, unwrap?: boolean): string {
        const dates = date.split("-");

        if (dateRangeType === "rolling") {
            const end = moment(dates[1], "YYYYMM");
            // This line break and tabbing format might look weird but it creates the right
            // spacing and line break to be used for the template.
            return `${moment(end).subtract(11, "months").format("M/YY")}${unwrap ? "-" : "-\n"}${end.format("M/YY")}`;
        } else if (dateRangeType === "calendar") {
            const index = dates.length > 1 ? 1 : 0;
            const end = moment(dates[index], "YYYYMM");
            return `${moment(end).format("YYYY")}`;
        } else if (dateRangeType === "ytd") {
            const end = moment(dates[0], "YYYYMM").format("M/YY");
            const start = moment(dates[0], "YYYYMM").format("1/YY");
            // This line break and tabbing format might look weird but it creates the right
            // spacing and line break to be used for the template.
            if (end !== start) {
                return `${start}${unwrap ? "-" : "-\n"}${end}`;
            } else {
                return `${start}`;
            }
        } else if (dateRangeType === "months") {
            const end = moment(dates[1], "YYYYMM").format("M/YY");
            const start = moment(dates[0], "YYYYMM").format("M/YY");
            if (start !== end) {
                return `${start}${!unwrap ? "-\n" : "-"}${end}`;
            } else {
                return `${start}`;
            }
        } else {
            return date;
        }
    }

    static popUpDatesRange(dates: string[], dateRangeType: string, wrap?: boolean): string {
        if (!dates || !dates.length || isNaN(parseInt(dates[0], 10))) {
            return "N/A";
        }

        if (dateRangeType === "rolling") {
            const end = moment(dates[0], "YYYYMM");
            return `${moment(end).subtract(11, "months").format("M/YY")}${wrap ? "-\n" : "-"}${end.format("M/YY")}`;
        } else if (dateRangeType === "calendar") {
            const end = moment(dates[0], "YYYYMM");
            return `${moment(end).format("YYYY")}`;
        } else if (dateRangeType === "ytd") {
            const end = moment(dates[0], "YYYYMM");
            const startYear = end.year();
            return `${moment(`01/01/${startYear}`).format("M/YY")}${wrap ? "-\n" : "-"}${moment(end).format("M/YY")}`;
        } else if (dateRangeType === "months" && dates.length > 1) {
            const dateArray = Array.isArray(dates[0]) ? dates[0] : dates;
            const end = moment(dateArray[0], "YYYYMM");
            return `${moment(dateArray[dateArray.length - 1], "YYYYMM").format("M/YY")}${wrap ? "-\n" : "-"}${end.format("M/YY")}`;
        } else {
            return moment(dates[0], "YYYYMM").format("M/YY");
        }
    }

    static dateDisplay(dates: string[], dateRangeType: string): string {
        if (!dates || !dates.length || isNaN(parseInt(dates[0], 10))) {
            return "N/A";
        }

        if (dateRangeType === "rolling") {
            const end = moment(dates[0], "YYYYMM");
            return `${moment(end).subtract(11, "months").format("MMM YYYY")} - ${end.format("MMM YYYY")}`;
        } else if (dateRangeType === "calendar") {
            const end = moment(dates[0], "YYYYMM");
            return `${moment(end).format("YYYY")}`;
        } else if (dateRangeType === "ytd") {
            const end = moment(dates[0], "YYYYMM");
            const startYear = end.year();
            return `${moment(`01/01/${startYear}`).format("MMM YYYY")} - ${moment(end).format("MMM YYYY")}`;
        } else if (dateRangeType === "months" && dates.length > 1) {
            const dateArray = Array.isArray(dates[0]) ? dates[0] : dates;
            const end = moment(dateArray[0], "YYYYMM");
            return `${moment(dateArray[dateArray.length - 1], "YYYYMM").format("MMM YYYY")} - ${end.format("MMM YYYY")}`;
        } else {
            return moment(dates[0], "YYYYMM").format("MMM YYYY");
        }
    }

    static generateDateGroupings(dateRangeType: string, minDate: string, maxDate: string, yearsBack: number, monthsFirstMinDate?: string): { start: string; end: string }[] {
        const dateGroups = [];
        let start;
        let end;
        for (let i = 0; i < yearsBack; i++) {
            if (dateRangeType === "rolling") {
                start = moment(maxDate, "YYYYMM").subtract(i + 1, "years").add(1, "months").format("YYYYMM");
                end = moment(maxDate, "YYYYMM").subtract(i, "years").format("YYYYMM");
            } else if (dateRangeType === "calendar") {
                start = moment(maxDate, "YYYYMM").month(0).subtract(i, "years").format("YYYYMM");
                end = moment(maxDate, "YYYYMM").month(11).subtract(i, "years").format("YYYYMM");
            } else if (dateRangeType === "ytd") {
                start = moment(maxDate, "YYYYMM").month(0).subtract(i, "years").format("YYYYMM");
                end = moment(maxDate, "YYYYMM").subtract(i, "years").format("YYYYMM");
            } else if (dateRangeType === "months" && minDate.length > 0 && monthsFirstMinDate) {
                end = moment(maxDate, "YYYYMM").subtract(i, "years").format("YYYYMM");
                start = moment(monthsFirstMinDate, "YYYYMM").subtract(i, "years").format("YYYYMM");
            }

            dateGroups.push({ start, end });
        }

        return dateGroups;
    }

    static generateCustomDateGroupings(dateRanges: string[]): { start: string; end: string }[] {
        const dateGroups = [];
        for (let i = 0; i < dateRanges.length; i++) {
            if (i % 2 !== 0) {
                continue;
            }

            dateGroups.push({ start: dateRanges[i], end: dateRanges[(i + 1)] });
        }
        return dateGroups;
    }

    static formatForSalesData(endDate: string, startDate: string, firstUpdate: Date, lastUpdate: Date){
        let returnedStartDate;
        let returnedEndDate;
        let tmpStartDate;
        let tmpEndDate;

        if (startDate.length <= 5) {
            tmpStartDate= moment(startDate, "M/YY").startOf("month").toDate();
        } else {
            tmpStartDate= moment(startDate).toDate();
        }
        if (endDate.length <= 5) {
            tmpEndDate = moment(endDate, "M/YY").endOf("month").toDate();
        } else {
            tmpEndDate = moment(endDate).toDate();
        }

        if(lastUpdate && firstUpdate){
            if (tmpStartDate.getMonth() === firstUpdate.getMonth() && tmpStartDate.getFullYear() === firstUpdate.getFullYear()) {
                returnedStartDate = `${firstUpdate.getMonth() + 1}/${firstUpdate.getDate()}/${firstUpdate.getFullYear().toString().slice(2)}`;
            } else {
                returnedStartDate = `${tmpStartDate.getMonth() + 1}/${tmpStartDate.getDate()}/${tmpStartDate.getFullYear().toString().slice(2)}`;
            }
            if (tmpEndDate.getMonth() === lastUpdate.getMonth() && tmpEndDate.getFullYear() === lastUpdate.getFullYear()) {
                returnedEndDate = `${lastUpdate.getMonth() + 1}/${lastUpdate.getDate()}/${lastUpdate.getFullYear().toString().slice(2)}`;
            } else {
                let end = new Date(tmpEndDate.getFullYear(), tmpEndDate.getMonth() + 1, 0);
                returnedEndDate = `${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear().toString().slice(2)}`;
            }
        } else {
            returnedStartDate = `${tmpStartDate.getMonth() + 1}/${tmpStartDate.getDate()}/${tmpStartDate.getFullYear().toString().slice(2)}`;
            let end = new Date(tmpEndDate.getFullYear(), tmpEndDate.getMonth() + 1, 0);
            returnedEndDate = `${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear().toString().slice(2)}`;
        }
        return `${returnedStartDate}-\n${returnedEndDate}`;
    }
}
