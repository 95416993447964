import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";

const DISMISS_FILTER_OVERRIDE_WARNING_KEY = "dismissOverrideWarning";

@Component({
    selector: "toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"]
})
export class ToastComponent implements OnChanges, OnDestroy, OnInit {
    @Input() show = false;
    @Input() extendLeft = false;
    @Input() content = "";
    @Input() dismissKeySuffix = "";

    hiddenPermanently: boolean;

    hidden = true;

    checkboxChecked = false;

    constructor() {
    }

    ngOnInit(): void {
        this.hiddenPermanently = sessionStorage.getItem(DISMISS_FILTER_OVERRIDE_WARNING_KEY + this.dismissKeySuffix) === "true";

        if (this.hiddenPermanently) {
            this.hidden = true;
        }
    }

    ngOnChanges(): void {
        this.hidden = !this.show || this.hiddenPermanently;
    }

    dismiss(): void {
        if (this.checkboxChecked) {
            sessionStorage.setItem(DISMISS_FILTER_OVERRIDE_WARNING_KEY + this.dismissKeySuffix, "true");
            this.hiddenPermanently = true;
        }

        this.hidden = true;
    }


    toggleChecked(): void {
        this.checkboxChecked = !this.checkboxChecked;
    }

    ngOnDestroy(): void {
    }
}
