<div class="results-panel" id="results-panel">
    <filter-toggle-button [dTooltip]="resultsdatatoggle" [placement]="'bottom'" class="result-data-filter-toggle"
        label="Result Data" optionOneLabel="Filtered" optionOne="filtered" [class.floating]="scrolled"
        optionTwoLabel="DMA" optionTwo="all" (toggle)="updateToggleValue($event)"
        filterName="{{FilterName.result_data_filtered}}"></filter-toggle-button>

    <div class="results-scroller" (scroll)="resultsPanelScrolled($event)">
        <results-expand-button id="dealer-sales-comparison-btn" [title]="'Dealer Sales Comparison'" [dealers]="dealers"
            *ngIf="dealers?.length > 1">
            <ng-template #main>
                <dealers-sales-comparison [dealers]="dealers"></dealers-sales-comparison>
            </ng-template>
        </results-expand-button>

        <results-expand-button id="unknown-dealer-btn" [defaultOpen]="false" [title]="'Unknown Dealer Sales'"
            *ngIf="hasUnknown">
            <ng-template #main>
                <dealer-results-details [total]="true" scope="unknown" *ngIf="showVolume" [showVolume]="showVolume">
                </dealer-results-details>
                <make-model-composition *ngIf="!isMultiDma; else multiDmaTotals" total="true"
                    [title]="'Composition by Make &amp; Model'" [showVolume]="showVolume" scope="unknown">
                </make-model-composition>
                <ng-template #multiDmaTotals>
                    <make-model-composition *ngFor="let dma of dmas" total="true" [dmaId]="dma.id" [type]="'sales'"
                        title="Composition by Make &amp; Model: {{dma.name}}" [showVolume]="showVolume" scope="unknown">
                    </make-model-composition>
                </ng-template>
            </ng-template>
        </results-expand-button>

        <results-expand-button id="0-results-expandable-btn" [defaultOpen]="false" [title]="'Market Composition'"
            *ngIf="dealers?.length > 1">
            <ng-template #main>
                <dealer-results-details [total]="true" *ngIf="showVolume" [showVolume]="showVolume">
                </dealer-results-details>
                <make-model-composition *ngIf="!isMultiDma; else multiDmaTotals" total="true"
                    [title]="'Composition by Make &amp; Model'" [showVolume]="showVolume">
                </make-model-composition>
                <ng-template #multiDmaTotals>
                    <make-model-composition *ngFor="let dma of dmas" total="true" [dmaId]="dma.id" [type]="'sales'"
                        title="Composition by Make &amp; Model: {{dma.name}}" [showVolume]="showVolume">
                    </make-model-composition>
                </ng-template>
            </ng-template>
        </results-expand-button>

        <div class="element-item" *ngFor="let dealer of dealers">
            <results-expand-button #dealerResults id="{{dealer.dealer_id}}-results-expandable-btn"
                [defaultOpen]="isDealerOpen(dealer)" [title]="dealer.dealer_name">
                <ng-template #description>
                    <div class="expand-button-description">{{dealer.dealer_address}}</div>
                    <div class="expand-button-description">{{dealer.dealer_city}} {{dealer.dealer_state}},
                        {{dealer.dealer_zip}}</div>
                </ng-template>
                <ng-template #main>
                    <spotlight-toggle [dealer]="dealer"></spotlight-toggle>
                    <dealer-results-details [total]="false" [dealer]="dealer" [showVolume]="showVolume">
                    </dealer-results-details>
                    <dealer-makes-models-details [attr.data-dealer-id]="dealer.dealer_id" [dealer]="dealer"
                        [type]="'shares'" [title]="'Shares by Make &amp; Model'">
                    </dealer-makes-models-details>
                    <make-model-composition [attr.data-dealer-id]="dealer.dealer_id" [dealer]="dealer"
                        [title]="'Composition by Make &amp; Model'" [showVolume]="showVolume">
                    </make-model-composition>
                </ng-template>
                <ng-template #closed>
                    <div class="spotlight-hint"
                        *ngIf="dealer && spotlightedDealer && dealer.dealer_id === spotlightedDealer.dealer_id"></div>
                </ng-template>
            </results-expand-button>
        </div>
    </div>
    <div class="no-sales" *ngIf="(dealers?.length === 0) && !loading">
        <div>No dealers found with the selected filters</div>
        <div class="icon"></div>
    </div>
</div>

<ng-template #resultsdatatoggle>
    <div style="max-width: 220px">
        Filtered data will be inclusive of the filter selections, whereas DMA will exclude the selected filters.
    </div>
</ng-template>
