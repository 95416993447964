import { Component, Input } from "@angular/core";

@Component({
    selector: "accordion",
    templateUrl: "./accordion.component.html",
    styleUrls: ["./accordion.component.scss"]
})
export class AccordionComponent {
    @Input() label: string;
    @Input() renderElWhenCollapsed = false; // Only render dom elements body when expanded.

    isExpanded = false;
    transitioning = false;
    transitioningTimeout: number;

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
        this.transitioning = true;
        clearTimeout(this.transitioningTimeout);
        this.transitioningTimeout = window.setTimeout(() => {
            this.transitioning = false;
        }, 300);
    }
}
