import { ChartOptions } from "app/core/models/chart-options.model";

export const MAX_BAR_THICKNESS = 55;
export const MIN_BAR_THICKNESS = 15;
export const SPACE_BETWEEN_GROUP = 15;

export const ChartDefaultOptions: ChartOptions = { // default options
    responsive: true,
    maintainAspectRatio: false,
    // Animation callback is used by the powerpoint
    // generation project.
    animation: {
        onComplete: () => {
            // Because this is stored on the global window object,
            // there could be collisions if a page renders
            // multiple chart objects. For the current use
            // case, this isn't likely to be a problem, but
            // just noting here in case you begin to see
            // additional issues with charts not fully rendering
            // during the powerpoint process.
            window["chartAnimationComplete"] = true;
        }
    },
    elements: {
        line: {
            tension: 0,  // straight lines, not curved
            borderWidth: 2 // line width (px)
        },
        point: {
            radius: 0, // points not visible
            hitRadius: 5, // allow clicking on point
            hoverRadius: 5 // allow hovering on point
        }
    },
    layout: {
        padding: { // padding for sides of chart
            right: 64,
            left: 25,
            top: -22
        }
    },
    scales: {
        xAxes: [{
            position: "top", // move x axis labels to top of chart
            gridLines: {
                color: "rgb(217, 217, 217)",
                drawBorder: false, // dont draw border around chart
                tickMarkLength: 40 // extend axis grid into label area (px)
            },
            ticks: {
                maxRotation: 0,
                labelOffset: 10, // shift x axis labels right (px)
                fontColor: "#0077bc",
                fontFamily: "'Open Sans'",
                padding: -15 // sets vertical padding for labels
            }
        }],
        yAxes: [{
            gridLines: {
                color: "rgba(0,0,0,0)", // make all yaxes gridlines transparent
                zeroLineColor: "rgba(217, 217, 217, 1)", // make the first (bottom) gridline grey and fully opaque
            },
            ticks: {
                padding: 5,
                beginAtZero: true, // start chart at yaxis = 0
                max: 100,
                suggestedMax: 100,
                fontColor: "#404040",
                fontFamily: "'Open Sans'",
                callback: (label) => label
            }
        }]
    },
    tooltips: {
        enabled: false
    },
    // Chartjs plugins options is kind of janky. Cannot set default and turn the plugin
    // off at the same time.
    plugins: {
        SharesPercent: false
    }
};

export const BarChartDefaultOptions: ChartOptions = { // default options
    borderRadius: .15,
    scales: {
        xAxes: [{
            position: "top", // move x axis labels to top of chart
            offset: true,
            gridLines: {
                zeroLineColor: "rgba(217, 217, 217, 1)",
                offsetGridLines: true
            },
            ticks: {
                display: true, // stops the chart from removing labels based on chart width
                maxRotation: 0,
                labelOffset: 0, // shift x axis labels right (px)
                fontColor: "#0077bc",
                fontFamily: "'Open Sans'",
                lineHeight: 1.7,
                padding: 0,
                minor: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                }
            },
            categoryPercentage: 1.0,
            barPercentage: 0.9,
            barThickness: "flex",
            maxBarThickness: MAX_BAR_THICKNESS,
            clampThickness: false, // A custom option added in to force bar size.
            barSpacing: 20
        }],
        yAxes: [{
            gridLines: {
                color: "rgba(242, 242, 242, 1)",
                drawTicks: true,
                drawOnChartArea: true,
                lineWidth: 1
            }
        }]
    },
    layout: {
        padding: {
            top: 25,
            bottom: 25,
            left: 30,
            right: 0,
        }
    },
    hover: {
        mode: "point",
        intersect: true
    },
    animation: {
        duration: 300
    }
};

export const LineChartDefaultOptions: ChartOptions = {
    scales: {
        xAxes: [{
            ticks: {
                display: true, // stops the chart from removing labels based on chart width
                maxRotation: 0,
                labelOffset: 20, // shift x axis labels right (px)
                fontColor: "#0077bc",
                fontFamily: "'Open Sans'",
                lineHeight: 1.2,
                padding: -25 // sets vertical padding for labels
            },
        }],
    },
    layout: {
        padding: {
            top: 20
        }
    }
};

export const HorizontalBarChartDefaultOptions: ChartOptions = { // default options
    borderRadius: .75,
    scales: {
        yAxes: [{
            position: "left", // move y axes labels to the right of the chart
            offset: true,
            gridLines: {
                zeroLineColor: "rgba(217, 217, 217, 1)",
                offsetGridLines: true,
                tickMarkLength: 0
            },
            ticks: {
                labelOffset: 0,
                padding: 0
            },
            categoryPercentage: 1.0,
            barPercentage: 1.0,
            barThickness: MIN_BAR_THICKNESS,
            maxBarThickness: MAX_BAR_THICKNESS,
            clampThickness: true // A custom option added in to force bar size.
        }],
        xAxes: [{
            position: "bottom", // move x axis labels to bottom of chart
            gridLines: {
                tickMarkLength: 10
            },
            ticks: {
                beginAtZero: true,
                labelOffset: 0,
                callback: (value) => value + "%",
                padding: 0
            }
        }]
    },
    hover: {
        mode: "point",
        intersect: true
    },
    animation: {
        duration: 300
    }
};

export const DottedLine: number[] = [10, 5];
