import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MetadataService } from "@at/core";
import { DateUtils } from "@at/utils";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { DealerSales } from "app/core/models/dealer-sales";
import { FilterName } from "app/core/models/filter-name.enum";
import { ZipZone } from "app/core/models/zip-zone.model";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { ZipZoneService } from "app/core/services/zip-zone.service";
import * as moment from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { PopupDirective } from "../../directives/popup.directive";

export interface DateGroupSale {
    dealer_id: number;
    salesMonthly: any;
    sharesMonthly: any;
    loaded: any;
}
@Component({
    selector: "zip-zone-sales-popup",
    templateUrl: "./zip-zone-sales-popup.component.html",
    styleUrls: ["./zip-zone-sales-popup.component.scss"]
})
export class ZipZoneSalesPopupComponent implements OnInit, OnDestroy, OnChanges {
    @Input() zips: string[];
    @Input() zipZoneMode: boolean; // zone if true, zip if false

    filterStateSubscription: Subscription;
    zipZoneDetails: ZipZone;
    dealerSales: DealerSales[] = [];
    dates: string[] = [];
    showVolume = false;
    dateRanges: string[] = [];
    useCustomDates = false;
    dateGroup: DateGroupSale = {
        dealer_id: null,
        salesMonthly: null,
        sharesMonthly: null,
        loaded: new BehaviorSubject(false)
    };
    dateGroups: any[];
    useSalesData: boolean;
    maxDate;
    minDate;

    protected dateGroupSales: DateGroupSale[] = []; // for date range groups
    private salesKeyBase: "sales" | "shares" = "sales"; // this is the type of sales data to display
    private salesKeyEnd: "" | "Monthly" = ""; // this is the date type of the sales data to display

    @ViewChild(PopupDirective, { static: true }) popup: PopupDirective;

    private dateRangeType = "calendar";

    dealerSubscription: Subscription;
    salesDataSubscription: Subscription;


    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private salesDataService: SalesDataService,
        private dealerDataService: DealerDataService,
        private zipZoneService: ZipZoneService,
        private dmaDataService: DmaDataService,
        private filterStateService: FilterStateService
    ) { }

    ngOnInit(): void {
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersUpdated.bind(this));
        this.filtersUpdated([FilterName.dateRangeType.toString(), FilterName.show_volume.toString()]); // initializes showVolume & dateRangeType variables
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
        this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);
        this.loadZipYearlySales();
        this.loadZipZone();
    }

    ngOnDestroy(): void {
        this.filterStateSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dateRanges = this.filterStateService.getFilterValue(FilterName.dateRanges);
        this.dateRangeType = this.filterStateService.getFilterValue(FilterName.dateRangeType);
        if ("zips" in changes) {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
            this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);
            if (this.dateRanges && this.dateRangeType === DateRangeTypes.MONTHS) {
                this.loadCustomDateSales();
                this.loadZipZone();
            } else {
                this.loadZipYearlySales();
                this.loadZipZone();
            }
        }
    }

    filtersUpdated(changed: string[]): void {
        // close the popup on filter change unless its the results data toggle or the show volume toggle
        if (!(changed.includes(FilterName.result_data_filtered.toString()) || changed.includes(FilterName.show_volume.toString())) && changed.length === 1) {
            this.popup.closePopup();
        }
        if (changed.includes(FilterName.dateRangeType.toString()) || changed.includes(FilterName.use_sales_data.toString())) {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate: FilterName.minDate);
            this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate: FilterName.maxDate);
            this.dateRangeType = this.filterStateService.getFilterValue<string>(FilterName.dateRangeType);
            this.dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            this.salesKeyEnd = this.dateRangeType === "calendar" ? "" : "Monthly";
            if (this.salesDataSubscription) {
                this.salesDataSubscription.unsubscribe();
                this.salesDataSubscription = undefined;
            }
            if (this.dealerSubscription) {
                this.dealerSubscription.unsubscribe();
                this.dealerSubscription = undefined;
            }
            this.dateGroupSales = [];
            this.dealerSales = [];
            this.useCustomDates = false;
        }
        if (changed.includes(FilterName.dateRanges.toString())) {
            this.dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
        }
        if (changed.includes(FilterName.show_volume.toString())) {
            this.showVolume = this.filterStateService.getFilterValue<boolean>(FilterName.show_volume);
            this.salesKeyBase = this.showVolume ? "sales" : "shares";
        }
        if (this.popup.visible) {
            if (this.dateRanges && this.dateRangeType === DateRangeTypes.MONTHS) {
                this.loadCustomDateSales();
            } else {
                this.loadZipYearlySales();
            }
            if (changed.includes(FilterName.use_sales_data.toString())) {
                this.loadZipZone();
            }
        }
    }

    loadCustomDateSales(): void { //For custom date range
        this.dates = [];
        this.dealerSales = [];
        if (this.zips && this.zips.length) {
            if (this.dateRangeType === DateRangeTypes.MONTHS && this.dateRanges && this.dateRanges.length > 0) {
                this.dateGroups = DateUtils.generateCustomDateGroupings(this.dateRanges);
            }
            if (this.dateGroupSales.length) {
                this.dateGroupSales = [];
            }
            this.dateGroups = this.dateGroups.sort((a: any, b: any) => (a.start < b.start) ? 1 : -1);
            let monthCount = 0;
            this.dates = [this.dateGroups[0].start, this.dateGroups[1].start, this.dateGroups[2].start];
            this.dateGroups.forEach((group, index) => {
                this.salesDataSubscription = this.salesDataService.getDetailsForZips(this.zips, group.start, group.end).subscribe((data: DealerSales[]) => {
                    const dealerIds = data.map((entry: DealerSales) => entry.dealer_id);
                    this.dealerSubscription = this.dealerDataService.getDealerDetails(dealerIds).subscribe(() => {
                        if (data.length > 0) {
                            data[0].loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
                                const salesKeys = Object.keys(data[0]["sales" + this.salesKeyEnd]);
                                monthCount = salesKeys.length;
                                let dateGroupSalesIndex;
                                // sort the data
                                data = data.sort((a: DealerSales, b: DealerSales) => this.sum(b["sales" + this.salesKeyEnd], this.dates) - this.sum(a["sales" + this.salesKeyEnd], this.dates));
                                // mapping the data we need and actually adding the values together for the custom date range (should be done serverside)
                                data.forEach((value) => {
                                    dateGroupSalesIndex = this.dateGroupSales.findIndex(element => element.dealer_id === value.dealer_id);
                                    if (dateGroupSalesIndex !== -1) {
                                        this.dateGroupSales[dateGroupSalesIndex]["sales" + this.salesKeyEnd][group.start] = this.sum(value["sales" + this.salesKeyEnd], salesKeys);
                                        this.dateGroupSales[dateGroupSalesIndex]["shares" + this.salesKeyEnd][group.start] = this.avg(value["shares" + this.salesKeyEnd], salesKeys);
                                    } else {
                                        this.dateGroupSales.push({
                                            dealer_id: value.dealer_id,
                                            salesMonthly: { [group.start]: this.sum(value["sales" + this.salesKeyEnd], salesKeys) },
                                            sharesMonthly: { [group.start]: this.avg(value["shares" + this.salesKeyEnd], salesKeys) },
                                            loaded: value.loaded
                                        });
                                    }
                                });
                                // adds dates and zeros out anything we didn't have data for - some dealers are missing data.
                                this.dateGroupSales.forEach((dateGroup, i) => {
                                    if (!this.dateGroupSales[i]["sales" + this.salesKeyEnd].hasOwnProperty(group.start)) {
                                        this.dateGroupSales[i]["sales" + this.salesKeyEnd][group.start] = "0";
                                    }
                                    if (!this.dateGroupSales[i]["shares" + this.salesKeyEnd].hasOwnProperty(group.start)) {
                                        this.dateGroupSales[i]["shares" + this.salesKeyEnd][group.start] = "0";
                                    }
                                });
                                if (this.useSalesData) {
                                    this.dateGroupSales.forEach((dateGroup) => {
                                        let sales = dateGroup.salesMonthly;
                                        for (let val in sales) {
                                            if (isNaN(dateGroup.salesMonthly[val])) {
                                                dateGroup.salesMonthly[val] = "n/a";
                                                dateGroup.sharesMonthly[val] = "n/a";
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                });
            });
            this.useCustomDates = true;
            setTimeout(() => {
                const position = this.popup.getPosition();
                this.popup.setPosition(position.left, position.top); // moves away from edge if size increased
            }, 0);
            this.changeDetectorRef.detectChanges();
        }
    }

    loadZipYearlySales(): void {
        this.dates = [];
        this.dealerSales = [];
        if (this.zips && this.zips.length) {
            if (this.salesDataSubscription) {
                this.salesDataSubscription.unsubscribe();
                this.salesDataSubscription = undefined;
            }
            this.salesDataSubscription = this.salesDataService.getDetailsForZips(this.zips).subscribe((data: DealerSales[]) => {
                const dealerIds = data.map((entry: DealerSales) => entry.dealer_id);
                if (this.dealerSubscription) {
                    this.dealerSubscription.unsubscribe();
                    this.dealerSubscription = undefined;
                }
                this.dealerSubscription = this.dealerDataService.getDealerDetails(dealerIds).subscribe(() => {
                    if (data.length > 0) {
                        data[0].loaded.pipe(skipWhile(i => !i), take(1)).subscribe(() => {
                            const source = data[0]["sales" + this.salesKeyEnd];
                            this.dates = Object.keys(source).sort().reverse().slice(0, 3);
                            this.dealerSales = data.sort((a: DealerSales, b: DealerSales) => this.sum(b["sales" + this.salesKeyEnd], this.dates) - this.sum(a["sales" + this.salesKeyEnd], this.dates));
                            if (this.useSalesData) {
                                for (const dealer in this.dealerSales) {
                                    let sales = {};
                                    let shares = {};
                                    if (Object.keys(this.dealerSales[dealer].salesMonthly).length > 0) {
                                        sales = this.dealerSales[dealer].salesMonthly;
                                        shares = {...this.dealerSales[dealer].sharesMonthly};
                                        // this logic will format the shares based on what comes back from the sales of the same year and dealer
                                        for (let key in sales) {
                                            if (sales[key] === "N/A") {
                                                shares[key] = sales[key];
                                            }
                                        }
                                        this.dealerSales[dealer].sharesMonthly = shares;
                                    } else {
                                        sales = this.dealerSales[dealer].sales;
                                        shares = this.dealerSales[dealer].shares;
                                    }
                                }
                            }
                        });
                    }
                    setTimeout(() => {
                        const position = this.popup.getPosition();
                        this.popup.setPosition(position.left, position.top); // moves away from edge if size increased
                    }, 0);
                    this.changeDetectorRef.detectChanges();
                });
            });
        }
    }

    loadZipZone(): void {
        if (this.zips && this.zips.length && this.zips[0]) {
            this.zipZoneDetails = undefined;
            this.zipZoneService.getZoneDetailsByZips(this.zips).pipe(take(1)).subscribe(zoneData => {
                this.zipZoneDetails = zoneData[0];
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    getDealerName(dealerId: number): string {
        return this.dealerDataService.getDealerName(dealerId);
    }

    getDmaNames(dmaIds: string[]): string[] {
        const dmaNames = [];
        dmaIds.forEach((dmaId) => {
            dmaNames.push(this.dmaDataService.getDmaName(Number(dmaId)));
        });
        return dmaNames;
    }

    valueFor(ds: DealerSales, date: string): number {
        return ds[this.salesKeyBase + this.salesKeyEnd][date];
    }

    valueForCustom(ds: any, date: string): number {
        return ds[this.salesKeyBase + this.salesKeyEnd][date];
    }

    setupDateRanges(date: string): string {//custom
        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            const dateRanges: string[] = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            for (let i = 0; i < dateRanges.length; i++) {
                if (i !== 0 && i % 2 !== 0) {
                    continue;
                }
                if (dateRanges[i] === date) {
                    return `${dateRanges[i + 1]}-${dateRanges[i]}`;
                }
            }

        }
    }

    private formatDate(date: string): string {
        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            date = this.setupDateRanges(date);
        }
        return DateUtils.popUpDateRange(this.filterStateService.getFilterValue(FilterName.use_sales_data), date,
            this.dateRangeType, moment(this.minDate).toDate(), moment(this.maxDate).toDate());
    }

    private avg(salesObj: any, dates: string[], useSalesData?: boolean): number {
        let sum = 0;
        for (let i = 0; i < dates.length; i++) {
            sum += salesObj[dates[i]] || 0;
        }
        return sum / dates.length;
    }

    private sum(salesObj: any, dates: string[], useSalesData?: boolean): number {
        let sum = 0;
        for (let i = 0; i < dates.length; i++) {
            sum += salesObj[dates[i]] || 0;
        }
        return sum;
    }
}
