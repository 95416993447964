import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { DateUtils } from "@at/utils";
import { logger } from "@sentry/utils";
import { AbstractPPTDataService } from "app/core/base/services/ppt-data.service.abstract";
import { DropDownComponent } from "app/core/components/dropdown/dropdown.component";
import { ExportIcon, ExportToastView } from "app/core/components/export-toast/export-toast.component";
import { AudienceAppQueryVars } from "app/core/models/aa-query-vars.model";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { FilterName } from "app/core/models/filter-name.enum";
import { PresentationFunc, PresentationTypes } from "app/core/models/presentation-type.enum";
import { CognitoService } from "app/core/services/cognito.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { ExportToastService } from "app/core/services/export-toast.service";
import { MetadataService } from "app/core/services/metadata.service";
import { PresentationFilterService } from "app/core/services/presentation-filter.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { v4 as uuidv4 } from "uuid";
export interface DropDownItem {
    value: any;
    label: string;
}

@Component({ template: "" })
export abstract class AbstractExportDropdownComponent extends DropDownComponent implements OnInit {
    @Input() radioGroupName: string;
    items: DropDownItem[] = [];
    selected: DropDownItem = { value: null, label: "Export" };
    selectedOption: string;
    private runId: string;
    private audienceAppQueryVars: AudienceAppQueryVars;
    pptClickListenerSub: Subscription;

    toastConfig = {
        viewConfig: {
            id: "export-success",
            icon: ExportIcon.PROMPT,
            header: "Report Complete",
            message: `Your Powerpoint report will be emailed to ${this.cognitoService.getUserEmail()}.
It will take approximately 5 minutes to run this report.`
        } as ExportToastView,
        reset: () => {
            this.presentationFilterService.resetResettableFilters();
            this.exportToastService.close();
        },
        dismiss: () => {
            this.exportToastService.close();
        }
    };

    errorConfig = {
        viewConfig: {
            id: "export-error",
            icon: ExportIcon.ERROR,
            header: "An error occurred.",
            message: `An error has occured while attempting to generate a powerpoint report.
Please try again.`
        } as ExportToastView,
        reset: () => {
            this.presentationFilterService.resetResettableFilters();
        },
        dismiss: () => {
            this.exportToastService.close();
        }
    };
    maxDate: any;
    minDate: any;
    monthsFirstMinDate: any = undefined;
    dateRangeType: any;

    constructor(elementRef: ElementRef,
        protected presentationFilterService: PresentationFilterService,
        protected pptDataService: AbstractPPTDataService,
        protected salesDataService: SalesDataService,
        protected exportToastService: ExportToastService,
        protected cognitoService: CognitoService,
        protected metadataService: MetadataService,
        protected dmaDataService: DmaDataService,
        protected formBuilder: FormBuilder,


    ) {
        super(elementRef, formBuilder);
    }

    ngOnInit(): void {
        const useSalesData = this.presentationFilterService.getFilterValue(FilterName.use_sales_data) === true;
        this.metadataService.getMaxDate(useSalesData).then(date => {
            this.maxDate = moment(date, "YYYYMM").format("YYYYMM");
            // last year of data is not displayed, used for year over year comparison.
            this.minDate = moment(date, "YYYYMM").subtract(4, "years").format("YYYYMM");
            this.dateRangeType = this.presentationFilterService.getFilterValue(FilterName.dateRangeType);

            this.presentationFilterService.pptClickListenerBool.subscribe((bool) => {
                if (bool === true) {
                    this.runId = uuidv4();
                    // sets the runId of the current operation
                    this.presentationFilterService.setFilterValue(FilterName.run_id, this.runId);
                    const selectedSysCodes = [];
                    const selectedMakes = [];
                    const selectedSegments = [];
                    // get pertinent info on dealer
                    const dealerInfo: any = this.presentationFilterService.getFilterValue(FilterName.selected_dealer);
                    const modalSelectedOptions: any[] = this.presentationFilterService.getFilterValue(FilterName.selected_options);

                    if (typeof modalSelectedOptions !== "undefined" && modalSelectedOptions) {
                        // pull out the syscodes to a new array of soley syscodes without the fluff
                        modalSelectedOptions.forEach(element => {
                            if (element.filterName === "zones" && element.syscode) {
                                selectedSysCodes.push(element.syscode);
                            } else if (element.filterName === "makes" && element.name !== "") {
                                selectedMakes.push(element.nickname);
                            } else if (element.filterName === "segments" && element.name !== "") {
                                selectedSegments.push(element.nickname);
                            }

                        });
                    }
                    const dmaCode: number[] = this.presentationFilterService.getFilterValue(FilterName.dma);
                    // const dmaName: any = this.dmaDataService.getDmaName(this.presentationFilterService.getFilterValue(FilterName.dealer_dma_code));
                    if (dealerInfo && dmaCode[0] && selectedSysCodes && this.runId) {
                        this.audienceAppQueryVars = {
                            dealerZip: dealerInfo.dealer_zip,
                            dealerNm: dealerInfo.dealer_name,
                            dmaCode: dmaCode[0].toString(),
                            sysCodes: selectedSysCodes,
                            runId: this.runId
                        };
                    }
                    if (selectedMakes.length >= 1) {
                        this.audienceAppQueryVars.makes = selectedMakes;
                    }
                    if (selectedSegments.length >= 1) {
                        this.audienceAppQueryVars.segments = selectedSegments;
                    }

                    // sets the filter value to the query vars needed
                    this.presentationFilterService.setFilterValue(FilterName.aa_query_vars, [this.audienceAppQueryVars]);
                    this.buildPresentation(PresentationTypes.QUICK_VIEW);
                }
            });
        });
    }


    selectItem(event: MouseEvent, item?: DropDownItem): void {
        event.stopImmediatePropagation();
        this.isOpen = false;
        if (this.selected.value !== item.value) {
            switch (item.value) {
                case PresentationFunc.EXCEL:
                    this.buildDataForExcel().then(() => this.presentationFilterService.createWorkbook());

                    break;
                default:
                    this.buildPresentation(item.value);
                    break;
            }
            this.change.next(item);
        }
    }

    getTopModel(selectedZoneId: string): Observable<string[]> {
        const filterOverrides = {
            zones: [selectedZoneId],
            dealers: [this.presentationFilterService.getFilterValue<number>(FilterName.spotlight_dealer)],
            makes: this.presentationFilterService.getFilterValue(FilterName.makes),
            models: [],
            zips: [],
            segments: [],
            ihs_segments: []
        };

        // Get top model for the selected ad zone
        const model = this.salesDataService.getTopOptions("models", 1, filterOverrides);
        return model;
    }

    buildDataForExcel(): Promise<void> {

        const dealer: number = this.presentationFilterService.getFilterValue(FilterName.spotlight_dealer);
        const selected_dealer = this.presentationFilterService.getFilterValue(FilterName.selected_dealer);
        const dealer_dma = selected_dealer ? selected_dealer["dealer_dma_code"] : "Not Set";

        // determine date groups
        const dateRangeType = this.presentationFilterService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
        const dateRanges = this.presentationFilterService.getFilterValue<string[]>(FilterName.dateRanges);
        const dateGroupPromises: Promise<any>[] = [];
        let dateGroups;

        if (dateRanges && dateRanges.length > 0) {
            this.maxDate = dateRanges[1];
            this.minDate = dateRanges[4];
            this.monthsFirstMinDate = dateRanges[0];
        }

        if (dateRangeType === DateRangeTypes.MONTHS) {
            dateGroups = DateUtils.generateCustomDateGroupings(dateRanges);
        } else {
            dateGroups = DateUtils.generateDateGroupings(dateRangeType, this.minDate, this.maxDate, 3, this.monthsFirstMinDate);
        }

        const dealerTopZones = this.presentationFilterService.getFilterValue<any[]>(FilterName.top_zones).map(zone => zone.sys_code);
        const dealerTopZips = this.presentationFilterService.getFilterValue<any>(FilterName.top_zips).topZips.map(zip => zip.location);
        const top5Competitors = cloneDeep(this.presentationFilterService.getFilterValue<any>(FilterName.top_competitors));

        // move spotlight to top for calculations
        const spotIndex = top5Competitors.findIndex(_dealer => _dealer.dealer_id === dealer);
        top5Competitors.push(...top5Competitors.splice(0, spotIndex));

        // clean out prior sales keys
        top5Competitors.forEach((comp, it) => {
            const keys2clean = Object.keys(comp).filter(key => key.match(/\d{6}_\d{6}/));
            keys2clean.forEach(key => {
                delete comp[key];
            }
            );

        });

        const filterOverride = {
            dealers: dealer,
            new_used_flag: 0,
            dma: [dealer_dma],
            dealer_dma_code: [dealer_dma],
            buyer_dma_code: [dealer_dma],
            dateRangeType: DateRangeTypes.MONTHS
        };

        // applies filters based on presentation type values for makes and segments if exporting from these workflows
        const presentationType = this.presentationFilterService.presentationType;
        if (presentationType === PresentationTypes.MAKE_VS_MAKE) {
            filterOverride["makes"] = this.presentationFilterService.getFilterValue(FilterName.makes);
        }

        if (presentationType === PresentationTypes.MAKE_VS_SEGMENT) {
            const segments: string | any[][] = this.presentationFilterService.getFilterValue(FilterName.segments);
            const ihs_segments: string | any[][] = this.presentationFilterService.getFilterValue(FilterName.ihs_segments);
            if (segments.length > 0) {
                filterOverride["segments"] = segments;
            }

            if (ihs_segments.length > 0) {
                filterOverride["ish_segments"] = ihs_segments;
            }
        }

        for (let i = 0; i < top5Competitors.length; i++) {
            filterOverride.dealers = top5Competitors[i].dealer_id;
            dateGroups.forEach(group => {
                dateGroupPromises.push(this.pptDataService.getZipZoneSalesForDateRange(group.start, group.end, { ...filterOverride, zones: dealerTopZones }, "zones").toPromise());
                dateGroupPromises.push(this.pptDataService.getZipZoneSalesForDateRange(group.start, group.end, { ...filterOverride, zips: dealerTopZips }, "zips").toPromise());
            });
        }

        return Promise.all(dateGroupPromises)
            .then(salesResults => {
                let offset = 0;
                top5Competitors.forEach((competitor, index) => {
                    let dateOffset = 0;
                    offset = index * 6;

                    dateGroups.forEach((group, j) => {
                        const key = `${group.start}_${group.end}`;
                        competitor[key] = { zones: [], zips: [] };
                        competitor[key].zones = salesResults[j + offset + dateOffset];
                        competitor[key].zips = salesResults[j + 1 + offset + dateOffset];
                        dateOffset++;
                    });
                });
                top5Competitors.splice(spotIndex + 1, 0, top5Competitors[0]);
                top5Competitors.shift();
                this.presentationFilterService.setFilterValue(FilterName.top_competitors, top5Competitors);

            }).catch(err => {
                logger.log(err);
            });
    }

    buildPresentation(pType: PresentationTypes): void {
        const dealer: number = this.presentationFilterService.getFilterValue<number>(FilterName.spotlight_dealer);
        const selected_dealer = this.presentationFilterService.getFilterValue(FilterName.selected_dealer);
        const dealer_dma = selected_dealer ? selected_dealer["dealer_dma_code"] : "Not Set";
        const dateRangeType = this.presentationFilterService.getFilterValue<string>(FilterName.dateRangeType);

        this.pptDataService.calculateTopZones(this.presentationFilterService.getFilterValue(FilterName.zones), 2, {
            dealers: dealer,
            new_used_flag: 0,
            dma: [dealer_dma],
            dealer_dma_code: [dealer_dma],
            buyer_dma_code: [dealer_dma]
        })
            .toPromise()
            .then(topZones => {
                if (topZones && topZones.length > 0) {
                    this.presentationFilterService.setFilterValue(FilterName.top_zones_names, topZones.map(z => z.value));
                    this.presentationFilterService.setFilterValue(FilterName.top_zones_sys_codes, topZones.map(z => z.id as string));
                    const topAdZoneModels: string[] = [];
                    return this.getTopModel(topZones[0].id).toPromise().then(topModel1 => {
                        topAdZoneModels.push(topModel1[0]);
                    }).then(() => {
                        if (topZones[1]) {
                            return this.getTopModel(topZones[1].id).toPromise().then(topModel2 => {
                                topAdZoneModels.push(topModel2[0]);
                            }).then(() => {
                                this.presentationFilterService.setFilterValue(FilterName.top_ad_zone_models, topAdZoneModels);
                            });
                        } else {
                            this.presentationFilterService.setFilterValue(FilterName.top_ad_zone_models, topAdZoneModels);
                            return;
                        }
                    })
                        .then(() => {
                            const makes: [] = this.presentationFilterService.getFilterValue(FilterName.makes);
                            if (makes.length > 1) {
                                const zipPerModel = {};
                                for (let i = 0; i < makes.length; i++) {
                                    this.salesDataService.getZipZoneForDealersSales([dealer], "zips", [], {
                                        zips: [],
                                        zones: [],
                                        makes,
                                        dateRangeType
                                    });
                                }
                                return;

                            } else {
                                return this.salesDataService.getZipZoneForDealersSales([dealer], "zips", [], {
                                    zips: [],
                                    zones: [],
                                    makes,
                                    dateRangeType
                                });
                            }
                        }).then(dealerSales => {
                            if (dealerSales && dealerSales.length > 0) {
                                const sales = dealerSales.find(sale => sale.dealer_id === dealer);
                                const zips = sales.sales.map(sale => sale.location);
                                this.presentationFilterService.setFilterValue(FilterName.zips, zips);
                            }
                            this.exportToastService.open(this.toastConfig);
                        })
                        .then(() => {
                            this.presentationFilterService.startPresentationBuild.bind(this.presentationFilterService)(pType);
                        })
                        .then(
                            () => {
                                this.presentationFilterService.setFilterValue(FilterName.report_building, false);
                            },
                            (error) => {
                                console.error("PPT Build Error", error);
                                this.presentationFilterService.setFilterValue(FilterName.report_building, false);
                                this.exportToastService.open(this.errorConfig);
                            }
                        );
                }
            });
    }

    public getRunId(): string {
        return this.runId;
    }
}
