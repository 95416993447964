export class DomUtils {
    // Many of these functions based on bootstrap positioning file
    // Including the entire bootstrap library would be a bit overkill
    // https://github.com/ng-bootstrap/ng-bootstrap/blob/master/src/util/positioning.ts

    public static getComputedZIndex(element: HTMLElement): number | "auto" {
        let highest = -1;
        while (element) {
            const index = this.getAllStyles(element).zIndex;
            if (index !== "auto") {
                highest = Math.max(Number(index) || 0, highest);
            }
            element = element.parentElement;
        }
        if (highest > -1) {
            return highest;
        }
        return "auto";
    }

    public static getAllStyles(element: HTMLElement): CSSStyleDeclaration {
        return window.getComputedStyle(element);
    }

    private static isStaticPositioned(element: HTMLElement): boolean {
        return (this.getStyle(element, "position") || "static") === "static";
    }

    public static offsetParent(element: HTMLElement): HTMLElement {
        let offsetParentEl = <HTMLElement>element.offsetParent || document.documentElement;

        while (offsetParentEl && offsetParentEl !== document.documentElement && this.isStaticPositioned(offsetParentEl)) {
            offsetParentEl = <HTMLElement>offsetParentEl.offsetParent;
        }

        return offsetParentEl || document.documentElement;
    }

    public static getStyle(element: HTMLElement, prop: string): string {
        return this.getAllStyles(element)[prop];
    }

    public static position(element: HTMLElement, round: boolean = true): any {
        let elPosition: any;
        let parentOffset: any = { width: 0, height: 0, top: 0, bottom: 0, left: 0, right: 0 };

        if (this.getStyle(element, "position") === "fixed") {
            elPosition = element.getBoundingClientRect();
        } else {
            const offsetParentEl = this.offsetParent(element);

            elPosition = this.offset(element, false);

            if (offsetParentEl !== document.documentElement) {
                parentOffset = this.offset(offsetParentEl, false);
            }

            parentOffset.top += offsetParentEl.clientTop;
            parentOffset.left += offsetParentEl.clientLeft;
        }

        elPosition.top -= parentOffset.top;
        elPosition.bottom -= parentOffset.top;
        elPosition.left -= parentOffset.left;
        elPosition.right -= parentOffset.left;

        if (round) {
            elPosition.top = Math.round(elPosition.top);
            elPosition.bottom = Math.round(elPosition.bottom);
            elPosition.left = Math.round(elPosition.left);
            elPosition.right = Math.round(elPosition.right);
        }

        return elPosition;
    }

    static offset(element: HTMLElement, round: boolean = true): any {
        const elBcr = element.getBoundingClientRect();
        const viewportOffset = {
            top: window.pageYOffset - document.documentElement.clientTop,
            left: window.pageXOffset - document.documentElement.clientLeft
        };

        const elOffset = {
            height: elBcr.height || element.offsetHeight,
            width: elBcr.width || element.offsetWidth,
            top: elBcr.top + viewportOffset.top,
            bottom: elBcr.bottom + viewportOffset.top,
            left: elBcr.left + viewportOffset.left,
            right: elBcr.right + viewportOffset.left
        };

        if (round) {
            elOffset.height = Math.round(elOffset.height);
            elOffset.width = Math.round(elOffset.width);
            elOffset.top = Math.round(elOffset.top);
            elOffset.bottom = Math.round(elOffset.bottom);
            elOffset.left = Math.round(elOffset.left);
            elOffset.right = Math.round(elOffset.right);
        }

        return elOffset;
    }

    static stopEventPropagation(event: Event): void {
        if (!event) {
            return;
        }
        event["_stopPropagation"] = true;
        /*
            These methods of swallowing the event don't work with other components, but do work with the page:
            event.stopPropagation();
            event.stopImmediatePropagation();
            event.preventDefault();
            https://github.com/angular/angular/issues/9587
        */
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
    }
}
