import { Directive, HostListener, Input } from "@angular/core";
import { Subject } from "rxjs";

const PADDING = 20;
const NAVBAR_HEIGHT = 66;

export enum SwipeDirection {
    Up,
    Down,
    Left,
    Right
}

@Directive({
    selector: "[dSwipeDetector]"
})
export class SwipeDetectorDirective {
    public readonly swiped = new Subject<SwipeDirection>();

    @Input() swipeThresholdDistance = 150;
    @Input() swipeRestraintDistance = 100;
    @Input() swipeAllowedMilliseconds = 300;

    swiping: boolean;
    startX: number;
    startY: number;
    startTime: number;

    @HostListener("touchstart", ["$event", "$event.target"])
    touchStartEvent(event: UIEvent, targetElement: EventTarget): void {
        if (event["changedTouches"] && event["changedTouches"].length > 0) {
            const touchobj = event["changedTouches"][0];
            this.swiping = true;
            this.startX = touchobj.pageX;
            this.startY = touchobj.pageY;
            this.startTime = new Date().getTime();
        }
    }

    @HostListener("touchend", ["$event"])
    touchEndEvent(event: UIEvent): void {
        if (this.swiping && event["changedTouches"] && event["changedTouches"].length) {
            this.swiping = false;
            const touchobj = event["changedTouches"][0];
            const distX = touchobj.pageX - this.startX;
            const distY = touchobj.pageY - this.startY;
            const elapsedTime = new Date().getTime() - this.startTime;
            let swipeDirection;

            if (elapsedTime <= this.swipeAllowedMilliseconds) {
                if (Math.abs(distX) >= this.swipeThresholdDistance && Math.abs(distY) <= this.swipeRestraintDistance) {
                    swipeDirection = (distX < 0) ? SwipeDirection.Left : SwipeDirection.Right; // if dist traveled is negative, it indicates left swipe
                } else if (Math.abs(distY) >= this.swipeThresholdDistance && Math.abs(distX) <= this.swipeRestraintDistance) {
                    swipeDirection = (distY < 0) ? SwipeDirection.Up : SwipeDirection.Down; // if dist traveled is negative, it indicates up swipe
                }
                if (swipeDirection !== undefined) {
                    event.preventDefault();
                    this.swiped.next(swipeDirection);
                }
            }
        }
    }

}
