import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { debounce } from "lodash";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class RequestMonitoringService {
    private _busy = new BehaviorSubject<boolean>(false);
    public readonly busy = this._busy.asObservable();

    private queue: HttpRequest<any>[] = [];

    constructor() {
        this.emitNotBusy = debounce(this.emitNotBusy.bind(this), 1000);
    }

    addToQueue(request: HttpRequest<any>): void {
        // Cancel debounced not busy event because something was added into the queue.
        (this.emitNotBusy as any).cancel();
        this.queue.push(request);
        if (!this._busy.value) {
            this._busy.next(true);
        }
    }

    removeFromQueue(request: HttpRequest<any>): void {
        const req = this.queue.findIndex(r => r === request);
        this.queue.splice(req, 1);
        if (!this.queue.length) {
            this.emitNotBusy();
        }
    }

    private emitNotBusy(): void {
        this._busy.next(false);
    }
}
