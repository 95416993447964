<ng-template #createScheduleTemp #modalSelectionSection>
    <div>
        <modal-selection-section></modal-selection-section>
    </div>
</ng-template>

<ng-template #invalidDma>
    <div class="no-dma-container">
        <div class="no-dma">
            <img src="../../../../assets/icons/warningo.png" alt="">
            <h2>The selected DMA is not available in Audience App</h2>
        </div>
        <p>Please make a new geography selection</p>
        <hr class="no-dma-hr">
    </div>
</ng-template>
