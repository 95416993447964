import { Chart, helpers } from "chart.js";
import { pullAt } from "lodash";
import { DataSet } from "../../../models/chart-data.model";
import { ChartColor } from "../chart/chart-color.utils";

const aliasPixel = (pixelWidth: number): number => (pixelWidth % 2 === 0) ? 0 : 0.5;

// Returns an x-axis label (gridline) if the mouse is in proximity. Otherwise returns undefined.
const getNearbyLineLabel = (event: MouseEvent, chart: any): any => {
    const pos = helpers.getRelativePosition(event, chart);
    if (chart.data.datasets) {
        // Check for X-axis grid in range.
        for (let i = 0; i < chart.data.datasets.length; i++) {
            const meta = chart.getDatasetMeta(i);
            if (chart.isDatasetVisible(i)) {
                for (let j = 0; j < meta.data.length; j++) {
                    if ((meta.data[j] as any).inLabelRange(pos.x, pos.y)) {
                        return meta.data[j];
                    }
                }
            }
        }

        // Check for labels in range.
        const xAxis = chart.scales["x-axis-0"];
        if (xAxis) {
            const closestTick = xAxis.getValueForPixel(pos.x);
            // longestLabelWidth doesn't seem exact.
            const labelWidth = xAxis.longestLabelWidth + 5;
            // Roughly the hight of two lines.
            const labelHeight = (typeof xAxis.options.fontSize !== "undefined") ? helpers.valueOrDefault(xAxis.options.fontSize, chart.options.defaultFontSize) * 2 + 5 : chart.options.defaultFontSize * 2 + 5;
            const tickX = xAxis.getPixelForTick(closestTick);
            // Check to see if mouse position is within label box.
            if (pos.x > tickX && pos.x < tickX + labelWidth && pos.y > 0 && pos.y < 0 + labelHeight) {
                return chart.getDatasetMeta(0).data[closestTick];
            }
        }
    }
};

// Returns an x-axis label (gridline) for bar chart if the mouse is in proximity. Otherwise returns undefined.
const getNearbyBarLabel = (event: MouseEvent, chart: any): any => {
    const pos = helpers.getRelativePosition(event, chart);
    if (chart.data.datasets) {
        // Check for gridline in range.
        const xAxis = chart.scales["x-axis-0"];
        if (xAxis) {
            // width between each tick
            const tickWidth = xAxis.width / xAxis.ticks.length;
            // longestLabelWidth doesn't seem exact.
            const labelWidth = xAxis.longestLabelWidth + 5;
            const halfTick = tickWidth / 2;
            const halfLabelWidth = labelWidth / 2;
            // Roughly the hight of two lines.
            const labelHeight = (typeof xAxis.options.fontSize !== "undefined") ? helpers.valueOrDefault(xAxis.options.fontSize, chart.options.defaultFontSize) * 2 + 5 : chart.options.defaultFontSize * 2 + 5;
            for (let i = 0; i < xAxis.ticks.length; i++) {
                const axisPos = xAxis.getPixelForTick(i) - tickWidth / 2;
                // Check to see if the mouse is near the gridline or on the label.
                if ((pos.x >= axisPos - 10 && pos.x <= axisPos + 10) ||
                    (pos.x >= axisPos + 10 && pos.x <= axisPos + 10 + labelWidth && pos.y > 0 && pos.y < 0 + labelHeight)) {
                    return chart.getDatasetMeta(0).data[i];
                }
            }
        }
    }
};

// Mutates chart object and update x-axis grid line colors. Chart update has to be called seperately.
// focusedLabel is of type "ChartElement" which is a generic contains all property object from Chartjs.
const updateLabelColors = (chart: Chart, focusedLabel?: any, highlightColor: string = "rgba(0, 119, 188, 1)", clear: boolean = false): void => {
    // Currently, charts.js does not allow axis labels to have individual changes.
    // https://github.com/chartjs/Chart.js/issues/2442

    let labelColors = [];
    if (!(chart.options.scales.xAxes[0].gridLines.color instanceof Array) || clear) {
        for (let i = 0; i < chart.data.labels.length; i++) {
            labelColors.push("rgba(217, 217, 217, 1)");
        }
        chart.options.scales.xAxes[0].gridLines.color = labelColors;
        labelColors = [];
    }
    for (let i = 0; i < chart.options.scales.xAxes[0].gridLines.color.length; i++) {
        const gridLineColor = chart.options.scales.xAxes[0].gridLines.color[i];
        labelColors.push(gridLineColor === highlightColor ? "rgba(217, 217, 217, 1)" : gridLineColor);
    }
    if (focusedLabel && (labelColors[focusedLabel._index] === "rgba(217, 217, 217, 1)" || labelColors[focusedLabel._index] === "rgba(0, 119, 188, 1)")) {
        labelColors[focusedLabel._index] = highlightColor;
    }
    chart.options.scales.xAxes[0].gridLines.color = labelColors;
};

// Focus selected dataset, emphasize other possible selections, and make all other elements opaque.
const focusChartElement = (chart: Chart, focused: any, others: DataSet[]): void => {
    const dataset = (chart.data.datasets as any);
    for (let i = 0; i < dataset.length; i++) {
        const meta = chart.getDatasetMeta(i);
        const barColors = [];
        for (let j = 0; j < meta.data.length; j++) {
            const model = (meta.data[j]._model as any);
            if (focused && (i === focused._datasetIndex && j === focused._index)) {
                // Selected element.
                barColors.push(ChartColor.toggleColorOpacity(model.backgroundColor, false));
            } else if (others.find(o => o.id === dataset[i].id && o.label === model.datasetLabel)) {
                // Other elements that are possible valid selections from previous filters.
                barColors.push(ChartColor.toggleColorOpacity(model.backgroundColor, false));
            } else {
                // All others elements.
                barColors.push(ChartColor.toggleColorOpacity(model.backgroundColor, true));
            }
        }
        dataset[i].backgroundColor = barColors;
    }
    chart.update();
};

const focusReset = (chart: Chart, exceptions: DataSet[] = []): void => {
    const dataset = chart.data.datasets;
    if (exceptions.length) {
        const copy = exceptions.slice();
        for (let i = 0; i < dataset.length; i++) {
            const bgColor = Array.isArray(dataset[i].backgroundColor) ? dataset[i].backgroundColor[0] : dataset[i].backgroundColor;
            let isException = false;
            for (let j = 0; j < copy.length; j++) {
                if ((dataset[i] as any).id === copy[j].id && dataset[i].label === copy[j].label) {
                    dataset[i].backgroundColor = ChartColor.toggleColorOpacity(bgColor);
                    isException = true;
                    pullAt(copy, j);
                    break;
                }
            }
            if (!isException) {
                dataset[i].backgroundColor = ChartColor.toggleColorOpacity(bgColor, true);
            }
        }
    } else {
        for (let i = 0; i < dataset.length; i++) {
            dataset[i].backgroundColor = ChartColor.toggleColorOpacity(Array.isArray(dataset[i].backgroundColor) ? dataset[i].backgroundColor[0] : dataset[i].backgroundColor);
        }
    }
    chart.update();
};

// Convert chart coordinates to screen coordinates.
const toScreenPosition = (relative: { x: number; y: number }, chart: any): { x: number; y: number } => {
    const canvas = chart.ctx.canvas;
    const boundingRect = canvas.getBoundingClientRect();
    let mouseX = relative.x;
    let mouseY = relative.y;

    const paddingLeft = parseFloat(helpers.getStyle(canvas, "padding-left"));
    const paddingTop = parseFloat(helpers.getStyle(canvas, "padding-top"));
    const paddingRight = parseFloat(helpers.getStyle(canvas, "padding-right"));
    const paddingBottom = parseFloat(helpers.getStyle(canvas, "padding-bottom"));
    const width = boundingRect.right - boundingRect.left - paddingLeft - paddingRight;
    const height = boundingRect.bottom - boundingRect.top - paddingTop - paddingBottom;

    // Divide by the current device pixel ratio to account for scaling according to devices.
    mouseX = Math.round((mouseX + boundingRect.left + paddingLeft) / (width) * canvas.width / chart.currentDevicePixelRatio);
    mouseY = Math.round((mouseY + boundingRect.top + paddingTop) / (height) * canvas.height / chart.currentDevicePixelRatio);

    return {
        x: mouseX,
        y: mouseY
    };
};

export const ChartUtils = {
    aliasPixel,
    getNearbyLineLabel,
    getNearbyBarLabel,
    updateLabelColors,
    focusChartElement,
    focusReset,
    toScreenPosition
};
