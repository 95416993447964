export enum PresentationTypes {
    COMPOSITE = "composite",
    MAKE_VS_MAKE = "make_vs_make",
    MAKE_VS_SEGMENT = "make_vs_segment",
    MAKE_VS_MARKET = "make_vs_market",
    QUICK_VIEW = "quick_view",
}
// split these out as presentation functions since they do not relate to an actual angular view/presentation.
export enum PresentationFunc {
    QUICK_VIEW = "quick_view",
    EXCEL = "EXCEL",
    COMPOSITE = "composite",
    MAKE_VS_MAKE = "make_vs_make",
    MAKE_VS_SEGMENT = "make_vs_segment",
    MAKE_VS_MARKET = "make_vs_market",
}
