import { Location } from "@angular/common";
import { Component, HostListener } from "@angular/core";
import { CognitoService } from "app/core/services/cognito.service";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import { PresentationFilterService } from "app/core/services/presentation-filter.service";

import { FilterName } from "../../../models/filter-name.enum";

@Component({ template: "" })
export class AbstractPageComponent {
    @HostListener("window:popstate", ["$event"])
    onPopState(e) {
        this.activeFilter = "";
        if (this.flowType === "composite") {
            this.location.replaceState("/map");
        } else {
            this.location.replaceState("/home");
        }
    }

    FilterName = FilterName;
    flowType = "";
    ihsSegmentEnabled = false;
    activeFilter = "";
    makesEnabled = false;
    datesEnabled = false;
    modelsEnabled = false;
    segmentsEnabled = false;
    spotlightDealerEnabled = false;
    ihsSegmentsEnabled = false;
    dealersEnabled = false;
    zonesEnabled = false;
    dmaEnabled = true;
    resultsEnabled = false;
    showResultsView = false;
    extendMapLeft = false;
    extendMapRight = false;
    showFilterPanel = false;
    showResultsPanel = false;
    toastComponent = null;
    presentationBuilding = false;
    dataSelectorEnabled = true;

    constructor(
        public presentationFilterService: PresentationFilterService,
        public ngrxFilterStateService: NgrxFilterStateService,
        public location: Location,
        public cognitoService: CognitoService,
    ) { }

    filtersUpdated(changes: string[]): void {
        if (changes.includes(FilterName.use_ihs_segments.toString()) || changes.includes("Filters-Reset")) {
            // Filters get reset by filter-panel component, so listen for either the IHS Segments toggle OR the filters to be reset
            this.ihsSegmentEnabled = this.presentationFilterService.getUseSegmentsFlag();
        }
        this.activeFilter = this.presentationFilterService.getFilterValue(FilterName.active_selection);
        this.presentationBuilding = this.presentationFilterService.getFilterValue(FilterName.report_building);

        this.makesEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.makes) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.makes.toString();
        this.datesEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.dates) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.dates.toString();
        this.modelsEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.models) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.models.toString();
        this.segmentsEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.segments) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.segments.toString();
        this.ihsSegmentsEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.ihs_segments) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.ihs_segments.toString();
        this.spotlightDealerEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.spotlight_dealer) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.spotlight_dealer.toString();
        this.zonesEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.zones) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.zones.toString();
        this.dealersEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.dealers) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.dealers.toString();
        this.resultsEnabled = this.presentationFilterService.isPreviousConditionsMet(FilterName.results) || this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.results.toString();
        this.showResultsView = this.presentationFilterService.getFilterValue<string>(FilterName.active_selection) === FilterName.results.toString();

        if (changes.includes("Filters-Reset")) {
            this.activeFilter = "";
        } else {
            if (changes.includes(FilterName.active_selection.toString())) {
                let baseRoute = `/presentation/${this.flowType}`;

                if (this.flowType === "composite") {
                    switch (this.activeFilter) {
                        case "use_sales_data":
                            this.location.replaceState(`${baseRoute}/data`);
                            break;
                        case "spotlight_dealer":
                            this.location.replaceState(`${baseRoute}/dealer`);
                            break;
                        case "dates":
                            this.location.replaceState(`${baseRoute}/dates`);
                            break;
                        case "makes":
                            this.location.replaceState(`${baseRoute}/makes`);
                            break;
                        case "models":
                            this.location.replaceState(`${baseRoute}/models`);
                            break;
                        case "segments":
                        case "ihs_segments":
                            this.location.replaceState(`${baseRoute}/segments`);
                            break;
                        case "dealers":
                            this.location.replaceState(`${baseRoute}/competitors`);
                            break;
                        case "zones":
                            this.location.replaceState(`${baseRoute}/zones`);
                            break;
                    }
                } else {
                    switch (this.activeFilter) {
                        case "use_sales_data":
                            this.location.replaceState(`${baseRoute}/data`);
                            break;
                        case "dma":
                            this.location.replaceState(`${baseRoute}/geo`);
                            break;
                        case "spotlight_dealer":
                            this.location.replaceState(`${baseRoute}/dealer`);
                            break;
                        case "makes":
                            this.location.replaceState(`${baseRoute}/makes`);
                            break;
                        case "segments":
                            this.location.replaceState(`${baseRoute}/segments`);
                            break;
                        case "results":
                            this.location.replaceState(`${baseRoute}/results`);
                            break;
                    }
                }
            }
        }
    }

    navClicked(filterName: string): void {
        this.filtersUpdated([FilterName.active_selection.toString(), this.ngrxFilterStateService.getFilter(FilterName.active_selection), FilterName.report_building.toString()]);
        this.presentationFilterService.setActiveSelection(FilterName[filterName]);
    }

}

