import { createReducer, on } from "@ngrx/store";

import { setResettableFilters } from "../actions/resettable-filters.actions";

const initialState: string[] = [];

const _resettableFilterReducer = createReducer(
    initialState,
    on(setResettableFilters, (state, {resettableFilters}) => ([...resettableFilters]))
);

export function resettableFilterReducer(state, action) {
    return _resettableFilterReducer(state, action);
};
