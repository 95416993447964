import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "DisplayFig" })
export class DisplayFig implements PipeTransform {
    static staticTransform(value: number): string {
        // If value is not a number, it is a placeholder value e.g. dash for non-existing value.
        if (isNaN(Number(value))) {
            return "" + value;
        }
        return new DecimalPipe("en-US").transform(value, "1.0-0");
    }

    transform(value: number): string {
        return DisplayFig.staticTransform(value);
    }
}
