import { DateUtils } from "@at/utils";
import { cloneDeep, uniq } from "lodash";
import * as moment from "moment";
import { Observable } from "rxjs";

import { DealerSales, Sales } from "../../../models/dealer-sales";
import { Dealer } from "../../../models/dealers.model";
import { DmaDataService } from "../../../services/dma-data.service";
import { SalesDataService } from "../../../services/sales-data.service";

export interface ChartDataRequest {
    yearLabels: string[];
    requests: Observable<DealerSales>[];
}

export interface CardRequirements { [key: string]: { isValid: boolean; text: string } }

function buildChartModalData(service: SalesDataService, dealers: Dealer[], startDate: string, totalsType: string = "dealers"): ChartDataRequest {
    const yearLabels = [];
    const requests = [];

    for (let i = 0; i < 3; i++) {
        // Append date range ending year with selected month e.g. Jan and convert to numerical year/month.
        const yearMonth = moment(startDate, "YYYYMM").subtract(i, "years").format("YYYYMM");
        yearLabels.push(yearMonth);

        const params = {
            dateRangeType: "month",
            dateRangeStart: yearMonth,
            dateRangeEnd: yearMonth
        };

        const verify = DateUtils.previousYearInMonths(yearMonth);

        let dsForYearMonth;
        switch (totalsType) {
        // Get sales for given dealers.
            case "dealers":
                dsForYearMonth = service.getSalesForDealers(dealers, params)
                    .map(ds => broadcastWhenLoaded(ds, verify, true));
                requests.push.apply(requests, dsForYearMonth);
                break;

            // Map dealers to their respective dma and get sales for each of those dmas.
            case "dmas":
                dsForYearMonth = service.getTotalSalesForDmas(uniq(dealers.map(d => d.dealer_dma_code)), params)
                    .map(ds => broadcastWhenLoaded(ds, verify, true));
                requests.push.apply(requests, dsForYearMonth);
                break;

            // Get a culmalative sales total for all selected dmas e.g. adding sales between dmas together.
            case "totals":
                dsForYearMonth = broadcastWhenLoaded(service.getTotalSales(params), verify, true);
                requests.push(dsForYearMonth);
                break;
        }
    }

    return { yearLabels, requests };
}

function broadcastWhenLoaded(ds: Sales, dates: string[] = [], immediateEmit: boolean = true): Observable<DealerSales>[] {
    return new Observable(observer => {
        ds.loaded.subscribe(loaded => {
            if (loaded) {
                // Immediately send out loaded date, possibly only partial data.
                // Ideal for a more progressive load UI.
                if (immediateEmit) {
                    observer.next(ds);
                }
                for (let i = 0; i < dates.length; i++) {
                    if (ds.salesMonthly[dates[i]] === undefined) {
                        return;
                    }
                }
                if (!immediateEmit) {
                    observer.next(ds);
                }
                observer.complete();
            }
        });
    }) as unknown as Observable<DealerSales>[];
}

function appendDmaSuffix(dealers: Dealer[], dmaDataService: DmaDataService): Dealer[] {
    return dealers.map(d => {
        const copy = cloneDeep(d);
        // This odd newline and spacing is required for proper formatting of the text string.
        copy.dealer_name = `${d.dealer_name}
(${dmaDataService.getShortDmaName(d.dealer_dma_code)})`;
        return copy;
    });
}

function getRequirementDescription(conditions: CardRequirements = {}): string {
    const stringParts = [];
    Object.keys(conditions).forEach(c => {
        const condition = conditions[c];
        if (!condition.isValid) {
            stringParts.push(condition.text);
        }
    });

    if (stringParts.length > 0) {
        // Capitalize first letter on first word.
        stringParts[0] = stringParts[0].replace(/^\w/, c => c.toUpperCase());
        // Add and to the last condition.
        if (stringParts.length > 1) {
            stringParts[stringParts.length - 1] = "and " + stringParts[stringParts.length - 1];
        }

    }

    return stringParts.length > 2 ? stringParts.join(", ") : stringParts.join(" ");
}

export const CardUtils = { buildChartModalData, broadcastWhenLoaded, appendDmaSuffix, getRequirementDescription };
