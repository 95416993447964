import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AbstractMapComponent, MapMouseFeaturesEvent } from "app/core/base/components/map/map.component.abstract";
import { FilterName } from "app/core/models/filter-name.enum";
import { AutoAnalyzerService } from "app/core/services/auto-analyzer.service";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { MetricsService } from "app/core/services/metrics.service";
import { NgrxFilterStateService } from "app/core/services/ngrx-filter-state.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { ZipZoneService } from "app/core/services/zip-zone.service";
import { FeatureCollection, GeometryObject } from "geojson";
import { GeoJSONGeometry } from "mapbox-gl";
import { take } from "rxjs/operators";

@Component({
    selector: "map",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent extends AbstractMapComponent implements OnInit {

    constructor(
        protected salesDataService: SalesDataService,
        protected dealerDataService: DealerDataService,
        protected filterStateService: FilterStateService,
        protected zipZoneService: ZipZoneService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected autoAnalyzerService: AutoAnalyzerService,
        protected ngrxFilterStateService: NgrxFilterStateService,
        protected metricsService: MetricsService
    ) {
        super(salesDataService, dealerDataService, filterStateService, zipZoneService, changeDetectorRef, ngrxFilterStateService, metricsService, "Primary Map");
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.map.on("error", (error) => {
            this.autoAnalyzerService.logMapboxCall(error, "error");
        });
    }

    dealerPinClick(e: MapMouseFeaturesEvent): void {
        const features = this.map.queryRenderedFeatures(e.point);
        if (!(features && features.length)) {
            return;
        }
        if (this.recentClick) {
            return;
        }

        const f = features[0];
        const dealer_id = f.properties["dealer_id"];
        const foundDealer = this.dealerDataService.dealerDetails.value.find((d) => d.dealer_id === dealer_id);
        if (this.spotlightedDealer
            && foundDealer.dealer_id === this.spotlightedDealer.dealer_id
            && this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) !== "include") {
            return;
        }

        this.selectedDealer = foundDealer;
        this.setRecentClick();

        const position = this.getPopupStartPosition(280);

        if (this.mapFilterControlsComponent.visibleState) {
            this.mapFilterControlsComponent.closeControls();
        }

        if (this.zipZoneSalesPopupComponent.popup.visible) {
            this.zipZoneSalesPopupComponent.popup.closePopup();
        }

        this.dealerSharePopupComponent.popup.openPopup();

        this.dealerSharePopupComponent.popup.setPosition(position.left, position.top);
        this.dealerSharePopupComponent.popup.closed.pipe(take(1)).subscribe(() => {
            if (this.selectedDealer && this.selectedDealer.dealer_id === dealer_id) {
                this.selectedDealer = null;
            }
            this.updatePinLayerFilters();
            this.updatePinHighlight();
        });
        this.updatePinLayerFilters();
        this.updatePinHighlight();
    }

    updatePinLayerFilters(): void {
        let selectedDealerId = 0; let spotlightId = 0;
        const pinFilter = [];

        if (this.selectedDealer) {
            selectedDealerId = this.selectedDealer.dealer_id;
            pinFilter.push(this.selectedDealer.dealer_id);
            this.setPinFocus("dealerPinsFocused", selectedDealerId);
        }

        if (this.spotlightedDealer) {
            if (this.selectedDealer && this.spotlightedDealer.dealer_id === this.selectedDealer.dealer_id) {
                selectedDealerId = 0;
            }
            spotlightId = this.spotlightedDealer.dealer_id;
            pinFilter.push(this.spotlightedDealer.dealer_id);
            this.setPinFocus("dealerPinsSpotlight", spotlightId);
        }

        this.map.setFilter("dealerPins", ["!in", "dealer_id"].concat(pinFilter));
        this.map.setFilter("dealerPinsFocused", ["==", ["get", "dealer_id"], selectedDealerId]);
        this.map.setFilter("dealerPinsSpotlight", ["==", ["get", "dealer_id"], spotlightId]);
        this.updateZipClicks();
        if (!this.changeDetectorRef["destroyed"]) {
            this.changeDetectorRef.detectChanges();
        }
    }

    updatePinHighlight(): void {
        const source = (this.map.getSource("dealerPins") as any).serialize() as { data: FeatureCollection<GeometryObject> };
        const features = [this.selectedDealer, this.spotlightedDealer].reduce((m, d) => {
            if (d) {
                const feature = source.data.features.find(f => f.properties.dealer_id === d.dealer_id);
                if (feature) {
                    m.push(feature);
                }
            }
            return m;
        }, []);

        // Highlight dealer and animate if they exist.
        const fc = { type: "FeatureCollection", features } as FeatureCollection<GeoJSONGeometry>;
        (this.map.getSource("dealer-highlight-source") as mapboxgl.GeoJSONSource).setData(fc);
        if (features.length) {
            this.animationTimer = this.mapUtils.animatePinHighlight(this.map, this.animationTimer, "dealer-highlight");
        }
    }

    zipCodeClick(e: MapMouseFeaturesEvent): void {
        const features = this.map.queryRenderedFeatures(e.point, { layers: this.zipcodeHeatMapLevels });
        if (!(features && features.length)) {
            return;
        }
        if (this.recentClick) {
            return;
        }
        this.setRecentClick();
        const f = features[0];
        let zips = [f.properties[this.zipCodeLayerKey]];
        if (this.salesDataService.volumeDetailKey === "adZoneVolume") {
            zips = this.zipZoneService.getAllZipsInZoneByZip(zips[0]);
        }
        this.selectedZips = zips;

        this.resetZipCodeExclusion();
        this.excludeZipCodeFromHeatmap(zips);

        this.map.setFilter("zipcodes-select", ["in", this.zipCodeLayerKey].concat(zips));
        this.map.setFilter("zipcodes-select-border", ["in", this.zipCodeLayerKey].concat(zips));

        const position = this.getPopupStartPosition(320);

        if (this.mapFilterControlsComponent.visibleState) {
            this.mapFilterControlsComponent.closeControls();
        }

        if (this.dealerSharePopupComponent.popup.visible) {
            this.dealerSharePopupComponent.popup.closePopup();
        }

        this.zipZoneSalesPopupComponent.popup.openPopup();
        this.zipZoneSalesPopupComponent.popup.setPosition(position.left, position.top);

        this.zipZoneSalesPopupComponent.popup.closed.pipe(take(1)).subscribe(() => {
            if (zips.includes(this.selectedZips[0])) {
                this.selectedZips = [];
                this.map.setFilter("zipcodes-select", ["==", this.zipCodeLayerKey, ""]);
                this.map.setFilter("zipcodes-select-border", ["==", this.zipCodeLayerKey, ""]);
                this.resetZipCodeExclusion();
                if (!this.changeDetectorRef["destroyed"]) {
                    this.changeDetectorRef.detectChanges();
                }
            }
        });
        this.changeDetectorRef.detectChanges();
    }
}
