import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"]
})
export class CardComponent {
    transitioningTimeout: ReturnType<typeof setTimeout>;
    transitioning: boolean;
    @Input() cardTitle: string;
    @Input() description: string;
    @Input() descriptionDisabled = false;
    @Input() disabled = false;
    @Input() isOpen = false;
    @Input() loading = false;
    @Input() paddingless = false;
    @Output() open: EventEmitter<void> = new EventEmitter<void>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    toggleOpenClosed(): void {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
            this.open.emit();
        } else {
            this.close.emit();
        }
        this.transitioning = true;
        clearTimeout(this.transitioningTimeout);
        this.transitioningTimeout = setTimeout(() => {
            this.transitioning = false;
        }, 700);
    }
}
