import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BaseSelectionSectionComponent } from "app/core/components/selection-section/selection-section.component";
import { FilterName } from "app/core/models/filter-name.enum";
import { FilterStateService } from "app/core/services/filter-state.service";
import { OverlayService } from "app/core/services/overlay.service";
import { PopupService } from "app/core/services/popup.service";
import { SalesDataService } from "app/core/services/sales-data.service";
import { isEqual } from "lodash";
import { Subscription } from "rxjs";
import { concatMap } from "rxjs/operators";

@Component({
    selector: "filter-selection-panel",
    templateUrl: "./filter-selection-panel.component.html",
    styleUrls: ["./filter-selection-panel.component.scss", "../filter-overlay-styles.scss"],
})
export class FilterSelectionPanelComponent implements OnInit, OnDestroy {
    title = "You may add filters here to limit your results.";

    filterName: FilterName;

    FilterName = FilterName; // this is here for proper template binding for HTML

    spotlightedDealerId: number[] = [];

    // Top zones irrespective of what zone is selected.
    topZones: string[] = [];

    private filterStateServiceSubscription: Subscription;
    private selectionSelectionsSubscription: Subscription;
    private topZonesSubscription: Subscription;

    radiusRingButtonDisabled = true;

    @ViewChild(BaseSelectionSectionComponent, { static: true }) selectionSection: BaseSelectionSectionComponent;

    constructor(
        public filterStateService: FilterStateService,
        private overlayService: OverlayService,
        private popupService: PopupService,
        public salesDataService: SalesDataService
    ) { }

    ngOnInit(): void {
        this.popupService.blockPopup();
        this.filterStateServiceSubscription = this.filterStateService.filtersUpdated.subscribe(this.onFiltersUpdated.bind(this));
        this.selectionSection.filterService = this.filterStateService;
        this.selectionSelectionsSubscription = this.selectionSection.selectedSelections.subscribe(this.getSelectionText.bind(this));
        this.onFiltersUpdated([FilterName.new_used_flag.toString(), FilterName.spotlight_dealer.toString()]);
    }

    ngOnDestroy(): void {
        this.popupService.unblockPopup();
        if (this.filterStateServiceSubscription) {
            this.filterStateServiceSubscription.unsubscribe();
            this.filterStateServiceSubscription = undefined;
        }
        if (this.selectionSelectionsSubscription) {
            this.selectionSelectionsSubscription.unsubscribe();
            this.selectionSelectionsSubscription = undefined;
        }
        if (this.topZonesSubscription) {
            this.topZonesSubscription.unsubscribe();
            this.topZonesSubscription = undefined;
        }
        this.saveSelections();
    }

    saveSelections(): void {
        if (isEqual(this.selectionSection.selectedSelections.getValue(), this.filterStateService.getFilterValue(this.filterName))) {
            return;
        }
        this.filterStateService.setFilterValue(this.filterName, this.selectionSection.selectedSelections.getValue());
    }

    closePanel(): void {
        this.overlayService.hidePanel();
    }

    onFiltersUpdated(which: string[]): void {
        if (which.includes(FilterName.dma.toString())) {
            this.closePanel();
        }

        if (which.includes(FilterName.new_used_flag.toString()) || which.includes(FilterName.buyer_dma_code.toString())) {
            this.selectionSection.filterState = this.filterStateService.getFilterValue(this.filterName) || [];
            this.selectionSection.setupChips();
        }

        if (which.includes(FilterName.spotlight_dealer.toString())) {
            if (this.filterName === FilterName.dealers) {
                this.spotlightedDealerId = [this.filterStateService.getFilterValue(FilterName.spotlight_dealer)];
            } else {
                this.spotlightedDealerId = [];
            }
        }

        if (this.filterName === FilterName.zones) {
            this.salesDataService.getTopOptions(FilterName.zones.toString(), 2)
                .pipe(concatMap(zoneIds => this.filterStateService.getFilterNamesFromIds(zoneIds, FilterName.zones))).subscribe(topZones => {
                    this.topZones = Object.values(topZones);
                });
        }
    }

    getSelectionText(values: string[] | number[]): void {
        const name = this.filterName.toString().replace("ihs_segments", "Segments");
        if (values.length > 0) {
            this.title = `${values.length} ${name.charAt(0).toUpperCase() + (values.length > 1 ? name.slice(1) : name.slice(1, -1))} selected`;
        } else {
            this.title = "You may add filters here to limit your results.";
        }
    }
}
