<div dPopup id="{{dealerYearlySales.dealer_id}}-dealershare-popup" class="dealer-share-popup map-popup">
    <ng-container *ngIf="dealer">
        <div class="result-title noselect move-handle">
            <div class="dealer-share-popup-close close" (click)="popup.closePopup()">
                <div class="icon"></div>
            </div>
            Share for
            <span class="dealerName">{{dealer.dealer_name}}</span>
            <div class="approx" *ngIf="dealer.dealer_misplaced">Approx. Pin Location</div>
        </div>
        <spotlight-toggle [dealer]="dealer"></spotlight-toggle>
        <div id="{{dealerYearlySales.dealer_id}}-dsp-address" class="dealer-address">
            {{dealer.dealer_address}}
            <br/> {{dealer.dealer_city}}, {{dealer.dealer_state}} {{dealer.dealer_zip}}
        </div>
        <div>
            <table class="results-data-table">
                <thead>
                    <tr *ngIf="dealerYearlySales.loaded.value || customDateDataLoaded; else loadingHeader">
                        <th *ngFor="let date of dates">
                            <pre>{{formatDate(date)}}</pre>
                        </th>
                    </tr>
                </thead>
                <ng-template #loadingHeader>
                    <tr>
                        <th *ngFor="let x of [0,0,0]">
                            <div class="loading"></div>
                        </th>
                    </tr>
                </ng-template>
                <tbody *ngIf="!useCustomDates">
                    <tr *ngIf="dealerYearlySales.loaded.value; else loading">
                        <td id="{{dealerYearlySales.dealer_id}}-{{dates[0]}}-dsp-entry" [class.increasing]="source[dates[0]] >= source[dates[1]]"
                            [class.decreasing]="source[dates[0]] < source[dates[1]]">
                            {{source[dates[0]]  | SigFig | DisplayPercentage}}
                            <span class="arrow"></span>
                        </td>
                        <td id="{{dealerYearlySales.dealer_id}}-{{dates[1]}}-dsp-entry" [class.increasing]="source[dates[1]] >= source[dates[2]]"
                            [class.decreasing]="source[dates[1]] < source[dates[2]]">
                            {{source[dates[1]]  | SigFig | DisplayPercentage}}
                            <span class="arrow"></span>
                        </td>
                        <td id="{{dealerYearlySales.dealer_id}}-{{dates[2]}}-dsp-entry">
                            {{source[dates[2]] | SigFig | DisplayPercentage}}
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="useCustomDates">
                    <tr *ngIf="customDateDataLoaded; else loading">
                        <td id="{{this.source[dateGroups[0].start].dealer_id}}-{{dateGroups[0].start}}-dsp-entry" [class.increasing]="source[dateGroups[0].start].value >= source[dateGroups[1].start].value"
                            [class.decreasing]="source[dateGroups[0].start].value < source[dateGroups[1].start].value">
                            {{source[dateGroups[0].start].value | SigFig}}{{getType(source[dateGroups[0].start].value) === 'number' ? '%': ''}}
                            <span class="arrow"></span>
                        </td>
                        <td id="{{this.source[dateGroups[1].start].dealer_id}}-{{dateGroups[1].start}}-dsp-entry" [class.increasing]="source[dateGroups[1].start].value >= source[dateGroups[2].start].value"
                            [class.decreasing]="source[dateGroups[1].start].value < source[dateGroups[2].start].value">
                            {{source[dateGroups[1].start].value | SigFig}}{{getType(source[dateGroups[1].start].value) === 'number' ? '%': ''}}
                            <span class="arrow"></span>
                        </td>
                        <td id="{{this.source[dateGroups[2].start].dealer_id}}-{{dateGroups[2].start}}-dsp-entry">
                            {{source[dateGroups[2].start].value | SigFig}}{{getType(source[dateGroups[2].start].value) === 'number' ? '%': ''}}
                        </td>
                    </tr>
                </tbody>
                <ng-template #loading>
                    <tr>
                        <td *ngFor="let x of [0,0,0]">
                            <div class="loading"></div>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </div>
    </ng-container>
</div>
