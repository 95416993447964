<div class="date-shift-controls">
    <ng-container *ngIf="!reversed">
        <button class="left" [disabled]="pastDisabled" (click)="dateNavigate(true)"></button>
        <button class="right" [disabled]="futureDisabled" (click)="dateNavigate(false)"></button>
    </ng-container>
    <ng-container *ngIf="reversed">
        <button class="left" [disabled]="futureDisabled" (click)="dateNavigate(false)"></button>
        <button class="right" [disabled]="pastDisabled" (click)="dateNavigate(true)"></button>
    </ng-container>
</div>
