import { Component, Input, OnInit } from "@angular/core";
import { DateUtils } from "@at/utils";
import { ChartOptions } from "app/core/models/chart-options.model";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { SalesQueryOptions } from "app/core/services/sales-data.service";
import * as moment from "moment";
import { forkJoin } from "rxjs";

import { DataSet } from "../../../models/chart-data.model";
import { FilterName } from "../../../models/filter-name.enum";
import { ChartColor } from "../../charts/chart/chart-color.utils";
import { ChartComponentUtils } from "../../charts/chart/chart.component.utils";
import { DropDownItem } from "../../dropdown/dropdown.component";
import { CardUtils } from "../card/card.utils";
import { ThreeYearPivotCardComponent } from "../three-year-pivot-card/three-year-pivot-card.component";

export const MAX_BAR_THICKNESS = 55;
export const MIN_BAR_THICKNESS = 15;
export const SPACE_BETWEEN_GROUP = 15;

@Component({
    selector: "dealer-make-segment-in-ad-zone-card",
    templateUrl: "./dealer-make-segment-in-ad-zone.component.html",
    styleUrls: ["./dealer-make-segment-in-ad-zone.component.scss"]
})
export class DealerMakeSegmentInAdZoneCardComponent extends ThreeYearPivotCardComponent implements OnInit {
    @Input() weeklyDataSelectorEnabled = false;
    cardTitleBase = "Dealer Make/Segment Share in Ad Zone";
    cardTitle = "Dealer Make/Segment Share in Ad Zone";
    cardDisabled = true;
    cardId = "dealer-make-segment-in-zone";

    chartColors = ChartColor.getColorPalette(2);

    groupBySelection = FilterName.dealers;

    dropdown: DropDownItem[] = [];
    defaultDropDown: DropDownItem;
    minDate;
    maxDate;
    useSalesData: boolean;

    private selectedZone: DropDownItem;

    // Chart options overrides.
    chartOptions: ChartOptions = {
        borderRadius: .15,
        scales: {
            xAxes: [{
                gridLines: { display: true, offsetGridLines: true },
                ticks: {
                    autoSkip: false, // stops the chart from removing labels based on chart width
                    display: true
                }
            }, {
                type: "category",
                // in order to have scale labels on the bottom of the chart,
                // while having the original labels location on the top of the chart, a second axis is needed.
                id: "bottom-x-axis",
                gridLines: { display: false },
                offset: true,
                ticks: {
                    display: true,
                    autoSkip: false,
                    padding: 20,
                    maxRotation: 0,
                    beginAtZero: false,
                    callback: (value, idx, values) => {
                        if ((idx + 1) > this.customTicks.length) {
                            return;
                        }
                        let retVal = "";
                        this.customTicks[idx].forEach((tick, index) => {
                            retVal += `  ${Number(tick).toFixed(2)}%  `;
                        });
                        return retVal;
                    }
                },
                afterBuildTicks: (chartObj) => {
                    if (chartObj.hasOwnProperty("ticks") && this.customTicks[0].length > 1) {
                        chartObj.ticks = this.customTicks;
                        // chartObj.ticks.stepSize = this.customTicks[0].length;
                        if (chartObj.hasOwnProperty("tickValues")) {
                            this.chart.setXAxisScaleCustom(this.customTicks);
                        }
                    }
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Volume",
                    fontStyle: 600,
                    fontFamily: "Open Sans",
                    fontColor: "#404040"
                }
            }]
        },
        customOptions: {
            groupedBars: {
                leftOffset: 0
            }
        },
        plugins: {
        }
    };

    ngOnInit(): void {
        super.ngOnInit();
        this.groupBySelection = FilterName.dealers;
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.updateDropDown();
    }

    async cardOpened(): Promise<void> {
        this.cardOpen = true;
        this.updateSalesData();
    }

    /**
     * The user changed the value of a filter, here we update the currently selected values by the user
     *
     * @param filters {string[]}    An array of filter names that were updated
     *
     * @returns Promise<void>       Returns a void Promise
     */
    async filtersUpdated(filters: string[]): Promise<void> {

        let requiresUpdate = false;

        // No interactions to zips / models filter change.
        if (filters.length === 1 && (filters[0] === FilterName.zips.toString() || filters[0] === FilterName.models.toString())) {
            return;
        }

        const includesDateRangeType = filters.includes(FilterName.dateRangeType.toString());
        const includesDateRange = filters.includes(FilterName.dateRanges.toString());
        const dateRanges: string[] = this.filterStateService.getFilterValue(FilterName.dateRanges);

        if (filters.includes(FilterName.use_sales_data.toString())) {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
            this.minDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.minSalesDate : FilterName.minDate);
            this.maxDate = this.filterStateService.getFilterValue(this.useSalesData ? FilterName.maxSalesDate : FilterName.maxDate);
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);

            this.date = this.useSalesData ? this.maxDate.slice(0, 6) : this.maxDate;
            if (this.dateRangeType === DateRangeTypes.MONTHS && includesDateRange && dateRanges && dateRanges.length >= 5) {
                this.setupDateRanges();
                requiresUpdate = true;
            } else if (this.dateRangeType === DateRangeTypes.MONTHS) {
                requiresUpdate = false;
            } else {
                requiresUpdate = true;
            }
            requiresUpdate = true;
        }

        const makesLength = this.filterStateService.getFilterValue<string[]>(FilterName.makes).length;
        const segmentsLength = this.filterStateService.getFilterValue<string[]>(FilterName.segments).length
            + this.filterStateService.getFilterValue<string[]>(FilterName.ihs_segments).length;

        const conditions = {
            spotlit: {
                isValid: !!this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer),
                text: "spotlight a Dealer"
            },
            // dma: {
            //     isValid: !this.filterStateService.isMultiDma(),
            //     text: "select only 1 DMA"
            // },
            makeOrSegment: {
                isValid: (makesLength > 0 && segmentsLength === 0) || (makesLength === 0 && segmentsLength === 1),
                text: "select exactly at least one Make or Segment"
            },
            include_spotlit: {
                isValid: this.filterStateService.getFilterValue<string>(FilterName.include_spotlight) === "include",
                text: "toggle 'Spotlight' to Include"
            },
            // dateRangeType: {
            //     isValid: !(this.filterStateService.getFilterValue<string>(FilterName.dateRangeType) === "calendar"),
            //     text: "Set Years filter to Rolling 12 Month or YTD"
            // }
        };

        this.cardDescription = CardUtils.getRequirementDescription(conditions);
        this.cardDisabled = this.cardDescription.length > 0;
        // Add additional helper text that are not part of the card open requirements.


        if (includesDateRangeType || includesDateRange) {
            this.dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);
            // if this is a custom date range, the update does not include new date ranges, and has no old date ranges
            if (this.dateRangeType === DateRangeTypes.MONTHS && includesDateRange && dateRanges && dateRanges.length >= 5) {
                this.setupDateRanges();
                requiresUpdate = true;
            } else if (this.dateRangeType === DateRangeTypes.MONTHS) {
                requiresUpdate = false;
            } else {
                this.updateTitle();
                requiresUpdate = true;
            }
        }

        if (this.cardDisabled) {
            this.cardClosed();
            this.updateTitle();
            this.cardDescription += `${this.cardDescription.length > 0 ? ". " : ""}`;
        } else if (!this.cardDisabled && requiresUpdate) {
            await this.updateDropDown();
            this.updateSalesData();
            this.updateTitle();
            this.cardDescription = "Based on these selections the top 2 Ad Zones for the dealer will be displayed. If you wish to change the displayed Ad Zones please select different Ad Zones in the filter panel to the left.";
        }
    }

    updateSalesData(): void {
        if (this.cardDisabled || !this.cardOpen) {
            return;
        }

        this.cardLoading = true;
        const queryOptions: SalesQueryOptions = this.getSalesQueryOptions();
        const marketQueryOption = { sort: {}, group: ["sys_code", "ncc_name", "buyer_dma_code"], dateRangeStart: queryOptions.dateRangeStart, dateRangeEnd: queryOptions.dateRangeEnd };


        // sorting is accomplished by sending a object, with the key being the column to sort by, and the value being ASC or DESC.
        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            queryOptions.sort[`t${this.maxDate}_volume`] = "DESC";
            marketQueryOption.sort[`t${this.maxDate}_volume`] = "DESC";
        } else if (this.dateRangeType === DateRangeTypes.YEARS) {
            queryOptions.sort[`t${this.date.slice(0, 4)}_volume`] = "DESC";
            marketQueryOption.sort[`t${this.date.slice(0, 4)}_volume`] = "DESC";
        } else {
            queryOptions.sort[`t${this.date.slice()}_volume`] = "DESC";
            marketQueryOption.sort[`t${this.date.slice()}_volume`] = "DESC";
        }

        // Get sales for spotlit dealer.
        const dealerReq = this.salesDataService.getGroupBySalesDetails(queryOptions,
            [this.filterStateService.getFilterValue<number>(FilterName.spotlight_dealer)],
            this.groupBySelection, { /*zips: [],*/ models: [], zones: this.selectedZone ? [this.selectedZone.value] : [] });

        // Get sales for market.
        const marketReq = this.salesDataService.getGroupBySalesDetails(marketQueryOption, [], FilterName.buyer_dma_code,
            { dealers: [], /*zips: [],*/ models: [], zones: this.selectedZone ? [this.selectedZone.value] : [] });

        forkJoin([dealerReq, marketReq]).subscribe(sales => {
            const dealerMakeSales = sales[0];
            const marketMakeSales = sales[1];

            if (dealerMakeSales.length) {
                this.noResults = false;
                this.cardDisabled = false;
            } else {
                this.noResults = true;
                this.cardLoading = false;
                this.cardDisabled = true;
                this.cardClosed();
                this.cardDescription = "No results for current filters.";
                return;
            }

            this.adjustBarSpacing(dealerMakeSales.length);
            this.chartData = [];
            let dateKeys;
            let currentDateKey;

            // this.labelsRef = ["2 Years Ago", "1 Year Ago", "Current Year"];
            // Get volume keys by date type
            switch (this.dateRangeType) {
            // if custom groups merge data and parse
                case DateRangeTypes.YTD:
                    dateKeys = Object.keys(dealerMakeSales[0].sales);
                    this.labelsRef = dateKeys.map(key => this.formatDate(key));
                    break;
                case DateRangeTypes.MONTHS: {
                // get begin date group size
                    dateKeys = this.groupDates(Object.keys(dealerMakeSales[0].sales));
                    this.labelsRef = dateKeys.map(key => this.formatDate(key));
                    break;
                } case DateRangeTypes.YEARS: {
                    dateKeys = Object.keys(dealerMakeSales[0].sales);
                    this.labelsRef = dateKeys;
                    break;
                } default:
                // Volume keys by date ASC.
                    dateKeys = Object.keys(dealerMakeSales[0].sales).sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10));
                    currentDateKey = dateKeys[dateKeys.length - 1];
                    let suffix = "";
                    if (this.useSalesData) {
                        const oldestMoment = moment(this.oldestDate, "YYYYMMDD");
                        const oldestRealDate = DateUtils.monthYearAddMonths(dateKeys[0], -11);
                        if (oldestMoment.isSameOrAfter(oldestRealDate) && this.dateRangeType === DateRangeTypes.ROLLING12) {
                            suffix = " - n/a";
                        }
                    }
                    const twoYearsAgo = "2 Years Ago" + suffix;
                    this.labelsRef = [twoYearsAgo, "1 Year Ago", "Current Year"];
            }

            // Sort date ASC.
            const cardType = this.filterStateService.getFilterValue<string[]>(FilterName.makes).length > 0 ? "Make" : "Segment";
            const pivotMarket = this.getData(dateKeys, marketMakeSales[0].sales);
            this.chartData.push({
                data: pivotMarket,
                shares: dateKeys.map(d => 100),
                label: `Market ${cardType} Sales`,
                id: "market-sales",
                barThickness: 50,
                maxBarThickness: 65
            } as DataSet);

            const pivotDealer = this.getData(dateKeys, dealerMakeSales[0].sales);
            this.chartData.push({
                data: pivotDealer,
                shares: dateKeys.map((d, index) => {
                    const decimal = pivotDealer[index] / pivotMarket[index];
                    return isNaN(decimal) ? 0 : decimal * 100;
                }),
                label: `Dealer ${cardType} Sales`,
                id: "dealer-sales",
                barThickness: 50,
                maxBarThickness: 65
            } as DataSet);

            this.customTicks = [];
            for (let i = 0; i <= this.chartData[0].shares.length - 1; i++) {
                this.customTicks.push([this.chartData[0].shares[i], this.chartData[1].shares[i]]);
            }

            // Delay updateChartSize() until next event cycle to allow chart to render first.
            setTimeout(this.updateChartSize.bind(this), 0);
            this.cardLoading = false;
        });
    }

    updateChartSize(): void {
        // Don't attempt to update if the card is not opened.
        if (!this.cardOpen) {
            return;
        }

        const chartWidth = this.chart.elementRef.nativeElement.offsetWidth;
        // Chart width - approximate layout offsets / # of labels (pivot sections)
        const chartSegmentWidth = (chartWidth - 300) / this.labelsRef.length;
        // Segment with / 3 bars.
        const barSize = (chartSegmentWidth - (this.chartData.length * this.chart.chartOptions.scales.xAxes[0].barSpacing)) / this.chartData.length;
        this.chart.chartOptions.scales.xAxes[0].maxBarThickness = barSize;
        this.chart.chartOptions.layout.padding = { // padding for sides of chart
            right: 35,
            left: 15,
            top: 0
        };

        // Format label for chart and resize chart offset to support label height.
        this.chartLabels = ChartComponentUtils.formatLabel(this.labelsRef, this.chart, this.labelsRef.length);
        const longestLabel = this.chartLabels.reduce((m, l) => m = l.length > m && l[l.length - 1] !== "" ? l.length : m, 1);
        this.chart.chartOptions.scales.xAxes[0].gridLines.tickMarkLength = longestLabel * 60;
        this.chart.forceRedraw();
    }

    getData(keys: any[], data: any): any[] {
        let pivotDateData = [];
        for (let i = 0; i < keys.length; i++) {
            if (Array.isArray(keys[i])) {
                let sum = 0;

                keys[i].forEach(date => {
                    sum = sum + data[date] || 0;
                });
                pivotDateData.push(sum || 0);
            } else {
                pivotDateData = keys.map(d => data[d] || 0);
            }
        }
        return pivotDateData;

    }

    getSalesQueryOptions(): SalesQueryOptions {
        const isMultiDma = this.filterStateService.isMultiDma();
        const retVal = {
            sort: {},
            group: [this.groupBySelection.toString(), isMultiDma ? "" : FilterName.dma.toString()]
        };

        if (this.dateRangeType === DateRangeTypes.MONTHS) {
            retVal["dateRangeStart"] = this.minDate;
            retVal["dateRangeEnd"] = this.maxDate;
        }

        return retVal;
    }

    dropdownChanged(selected: DropDownItem): void {
        this.selectedZone = selected;
        this.updateSalesData();
    }

    async updateDropDown(): Promise<void> {
        const zones = await this.salesDataService.calculateTopZones(
            this.filterStateService.getFilterValue(FilterName.zones),
            2,
            {
                dealers: this.filterStateService.getFilterValue(FilterName.spotlight_dealer),
                makes: this.filterStateService.getFilterValue(FilterName.makes),
                segments: this.filterStateService.getFilterValue(FilterName.segments),
                ihs_segments: this.filterStateService.getFilterValue(FilterName.ihs_segments)
            }
        ).toPromise();

        // If two or more adzones were selected, set dropdown values to the top two.
        this.dropdown = zones.map((zone, i) => ({ label: `${i + 1}: ${zone.value}`, value: zone.id }));
        // See if previous zone selection is still present in drop down, otherwise set selected as top zone as default.
        this.selectedZone = this.defaultDropDown = this.selectedZone && this.dropdown.find(dd => dd.value === this.selectedZone.value) || this.dropdown[0];
    }
}
