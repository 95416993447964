import { Injectable } from "@angular/core";

@Injectable()
export class DownloadUtils {
    constructor() { }

    public downloadToBrowser(csvContent: string, title: string, type: string = "text/plain"): void {
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(new Blob([csvContent], { type }));
        // Use download attribute to set set desired file name
        a.setAttribute("download", title);
        // Trigger the download by simulating click
        a.click();
        // Cleanup
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }
}
