<div class="content-body" [class.navless]="hideNav">
    <ng-container *ngIf="noResults; else view">
        <div class="curtain">
            <div class="noResults"></div>
            <div>Filters set has resulted in no sales for the given dealer.</div>
        </div>
    </ng-container>

    <ng-template #view>
        <div class="curtain" [class.hide]="!isLoading">
            <div class="loading"></div>
        </div>
        <ng-content></ng-content>
    </ng-template>
</div>
