import { Component, Input, OnChanges, OnInit } from "@angular/core";

@Component({
    selector: "highlighted-text",
    templateUrl: "./highlighted-text.component.html",
    styleUrls: ["./highlighted-text.component.scss"]
})
export class HighlightedTextComponent implements OnChanges {
    @Input() needle: String;
    @Input() haystack: String;
    matched: String;
    leftUnmatched: String;
    rightUnmatched: String;

    ngOnChanges(changes: any): void {
        this.match();
    }

    match(): void {
        this.matched = undefined;
        this.rightUnmatched = undefined;
        this.leftUnmatched = this.haystack;
        if (this.needle && this.haystack) {
            const needle = String(this.needle);
            const haystack = String(this.haystack);
            const pos = this.findMatchPosition(needle, haystack);
            if (pos) {
                this.leftUnmatched = haystack.substr(0, pos.start);
                this.matched = haystack.substr(pos.start, pos.length);
                this.rightUnmatched = haystack.substr(pos.start + pos.length);
            }
        }
    }

    findMatchPosition(needle: string, haystack: string, start: number = 0): { start: number; length: number } {
        const strippedNeedle = needle.toLowerCase().replace(/[^a-z0-9]/g, "");
        if (!(strippedNeedle && strippedNeedle.length)) {
            return null;
        }
        if (start >= haystack.length) {
            return null;
        }
        let needlePtr = 0;
        let length = 0;

        for (let i = start; i < haystack.length; i++) {
            const letter = haystack[i].toLowerCase().replace(/[^a-z0-9]/g, "");
            if (letter && letter.length === 1) {
                if (strippedNeedle[needlePtr] === letter) {
                    needlePtr++;
                } else {
                    break;
                }
            } else if (i === start) {
                break; // if we've skipped the first char (a space or paren) advanced to the next spot so we don't bold it
            }
            length++;
            if (strippedNeedle.length === needlePtr) {
                break;
            }
        }
        if (needlePtr === strippedNeedle.length) {
            return { start, length };
        }
        return this.findMatchPosition(needle, haystack, start + 1);
    }



}
