import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpServiceBase } from "../base/services/http-service-base.service";

export class SalesArea {
    buyer_zip: string;
}

@Injectable()
export class DealerSalesAreaService extends HttpServiceBase {

    constructor(protected http: HttpClient) {
        super(http);
    }

    getSalesAreas(dealerId: number, area: string, used: number, useSalesData: boolean): Observable<SalesArea[]> {
        const params = {
            dealer_id: dealerId,
            used,
            area,
        };
        const url = `${this.rootUrl}/dealer-sales-area`;
        if (useSalesData) {
            params["use_sales_data"] = "true";
        }
        const options = this.createRequestOptions(params);
        return this.fetchInterfaces<SalesArea>(url, options);
    }
}
