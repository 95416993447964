import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from "@angular/core";
import { DateRangeTypes } from "app/core/models/date-range.enum";
import { DmaSales, Sales } from "app/core/models/dealer-sales";
import { Dealer } from "app/core/models/dealers.model";
import { FilterName } from "app/core/models/filter-name.enum";
import { DealerDataService } from "app/core/services/dealer-data.service";
import { DmaDataService } from "app/core/services/dma-data.service";
import { FilterStateService } from "app/core/services/filter-state.service";
import { UnknownSalesService } from "app/core/services/unknown-sales.service";
import { DateUtils } from "app/utils/date-utils";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { IFilterToggleState } from "../../filter/filter-toggle-button/filter-toggle-button.component";
import { FeatureFlagService, PresentationFilterService } from "@at/core";
import { FeatureFlags } from "app/core/services/feature-flag.service";

@Component({
    selector: "results-panel",
    templateUrl: "./results-panel.component.html",
    styleUrls: ["./results-panel.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ResultsPanelComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChildren("dealerResults", { read: ElementRef }) dealerResults: QueryList<ElementRef>;

    spotlightedDealer: Dealer = null;
    dealers: Dealer[] = [];
    scrolled = false;
    FilterName = FilterName; // this is here for proper template binding
    loading = true;
    dmas = [];  // The number of selected dmas changes how the market total card is displayed.
    showVolume = false; // Hides/Shows sales volume
    canViewSalesToggle: boolean; // feature flag for Weekly Sales toggle
    useSalesData: boolean;

    private dealerDetailsSubscription: Subscription;
    private dealerDetailsLoadingSubscription: Subscription;
    private spotlightDealerDetailsSubscription: Subscription;
    private filterStateSubscription: Subscription;
    private unknownSalesSubscription: Subscription;
    private unknownYearlySales: Sales[] = [];
    protected stream: Subscription;

    hasUnknown: boolean;

    get isMultiDma(): boolean {
        return this.dmas.length > 1;
    }

    constructor(
        private filterStateService: FilterStateService,
        private dealerDataService: DealerDataService,
        private dmaDataService: DmaDataService,
        private unknownSalesService: UnknownSalesService,
        private featureFlagService: FeatureFlagService
    ) { }


    ngAfterViewInit(): void {
        this.scrollToSpotlightedDealer();
    }

    async ngOnInit(): Promise<void> {
        this.dealerDetailsSubscription = this.dealerDataService.dealerDetails.subscribe(this.updateDealers.bind(this));
        this.dealerDetailsLoadingSubscription = this.dealerDataService.loadingDealerDetails.subscribe(this.updateLoading.bind(this));
        this.spotlightDealerDetailsSubscription = this.dealerDataService.spotlightDealerDetails.subscribe(this.updateSpotlightedDealer.bind(this));
        this.filterStateSubscription = this.filterStateService.filtersUpdated.subscribe(this.filtersChanged.bind(this));

        this.dmaDataService.allDmasLoaded.pipe(skipWhile(l => !l), take(1)).subscribe(() => {
            this.dmas = (this.filterStateService.getFilterValue(FilterName.dma) as number[]).map(id => ({ id, name: this.dmaDataService.getShortDmaName(id) }));
            this.updateUnknown();
        });
        this.canViewSalesToggle = await this.featureFlagService.viewSalesToggle();
        if (this.canViewSalesToggle) {
            const enabled_features: string[] = this.filterStateService.getFilterValue(FilterName.enabled_features);
            if (enabled_features) {
                this.filterStateService.setFilterValue(FilterName.enabled_features, [...enabled_features, FeatureFlags.AA_Enable_Sales_Toggle]);
            }
        }
        this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        this.filtersChanged([FilterName.show_volume.toString()]);
    }

    updateLoading(loading: boolean): void {
        this.loading = loading;
    }

    ngOnDestroy(): void {
        this.dealerDetailsSubscription.unsubscribe();
        this.dealerDetailsLoadingSubscription.unsubscribe();
        this.spotlightDealerDetailsSubscription.unsubscribe();
        this.filterStateSubscription.unsubscribe();
    }

    dealerLength(): number {
        return this.dealers.length;
    }

    updateDealers(dealers: Dealer[]): void {
        this.dealers = dealers.slice().sort((dealer1, dealer2) => dealer1.dealer_name.localeCompare(dealer2.dealer_name));
        if (dealers.length && this.spotlightedDealer) {
            this.scrollToSpotlightedDealer();
        }
    }

    filtersChanged(filters: string[]): void {
        if (filters.includes("dma")) {
            this.dmas = (this.filterStateService.getFilterValue(FilterName.dma) as number[]).map(id => ({ id, name: this.dmaDataService.getShortDmaName(id) }));
        }
        if (filters.includes(FilterName.show_volume.toString())) {
            this.showVolume = this.filterStateService.getFilterValue<boolean>(FilterName.show_volume);
        }
        if (filters.includes(FilterName.use_sales_data.toString())) {
            this.useSalesData = this.filterStateService.getFilterValue(FilterName.use_sales_data);
        }

        this.updateUnknown();
    }

    updateUnknown(): void {
        const dateRangeType = this.filterStateService.getFilterValue<DateRangeTypes>(FilterName.dateRangeType);

        if (dateRangeType === DateRangeTypes.MONTHS) {
            const dateGroupSales: DmaSales[][] = [];

            const dateRanges = this.filterStateService.getFilterValue<string[]>(FilterName.dateRanges);
            let dateGroups;

            if (dateRanges && dateRanges.length > 0) {
                dateGroups = DateUtils.generateDateGroupings(dateRangeType, dateRanges[4], dateRanges[1], 3, dateRanges[0]);
            }
            if(dateGroups){
                dateGroups.forEach(group => {
                    dateGroupSales.push(this.unknownSalesService.getUnknownSalesMultiDMA(this.dmas.map(dma => dma.id), { dateRangeStart: group.start, dateRangeEnd: group.end }));

                });
            }

            // Clean out old data stream.
            if (this.stream) {
                this.stream.unsubscribe();
                this.stream = undefined;
            }

            // this.unknownYearlySales = { makes: [], salesMonthly: {}, loaded: new BehaviorSubject(false) };
            this.stream = combineLatest([].concat(...dateGroupSales.map(dma => dma.map(sale => sale.loaded)))).pipe(skipWhile(vals => vals.some(v => v === false)), take(1)).subscribe(() => {
                const target = [];
                const prop = "make_description";
                const modelProp = "model_description";
                const targetModels = [];
                this.unknownYearlySales = [{ makes: [], loaded: new BehaviorSubject(false), salesMonthly: {} }];

                // sum all of the sales to groups
                dateGroupSales.forEach((dateGroup, dgInd) => {
                    // For each DMA

                    const lastDate = Object.keys(dateGroup[0].salesMonthly).slice(-1)[0];
                    if (lastDate) {
                        this.unknownYearlySales[0].salesMonthly[lastDate] = Object.values<number>(dateGroup[0].salesMonthly).reduce((acc, curr) => acc + curr);

                        // lookup each make in agg and add date sales to each make
                        dateGroup[0].makes.forEach((make) => {
                            const aggSales: any = { salesMonthly: {} };
                            aggSales.salesMonthly[lastDate] = Object.values<number>(make.salesMonthly).reduce((acc, curr) => acc + curr);

                            const targetMake = target.find(_make => make[prop] === _make[prop]);
                            if (targetMake) {
                                targetMake.salesMonthly = Object.assign(targetMake.salesMonthly, aggSales.salesMonthly);
                            } else {
                                target.push({ ...make, salesMonthly: aggSales.salesMonthly });
                            }
                            make.models.forEach(model => {
                                const targetMakeIndex = target.findIndex(_make => _make.make_description === make.make_description);
                                const aggModelSales: any = { salesMonthly: {} };
                                aggModelSales.salesMonthly[lastDate] = Object.values<number>(model.salesMonthly).reduce((acc, curr) => acc + curr);

                                const targetModel = target[targetMakeIndex].models.find(_model => model[modelProp] === _model[modelProp]);
                                if (targetModel) {
                                    targetModel.salesMonthly = Object.assign(targetModel.salesMonthly, aggModelSales.salesMonthly);
                                } else {
                                    target[targetMakeIndex].models.push({ ...model, salesMonthly: aggModelSales });
                                }
                            });
                        });
                    } else {
                        this.unknownYearlySales[0].salesMonthly[dateGroups[dgInd].end] = 0;
                    }
                });
                this.unknownYearlySales[0].makes = target;
                this.hasUnknown = false;

                this.unknownYearlySales.forEach(dmaSales => {
                    const sale = dmaSales.salesMonthly;
                    for (const date in sale) {
                        if (!this.hasUnknown && sale[date] > 0) {
                            this.hasUnknown = true;
                            return;
                        }
                    }
                });

                for (const makes in this.unknownYearlySales[0].makes) {
                    for (const sales in this.unknownYearlySales[0].makes[makes].salesMonthly) {
                        if (typeof this.unknownYearlySales[0].makes[makes].salesMonthly[sales] === "string") {
                            this.unknownYearlySales[0].makes[makes].salesMonthly[sales] = "n/a";
                            this.unknownYearlySales[0].makes[makes].sharesMonthly[sales] = "n/a";
                        } else if (typeof this.unknownYearlySales[0].makes[makes].sales[sales] === "string") {
                            this.unknownYearlySales[0].makes[makes].sales[sales] = "n/a";
                            this.unknownYearlySales[0].makes[makes].shares[sales] = "n/a";
                        }
                    }
                }
            });
        } else {
            this.unknownYearlySales = this.unknownSalesService.getUnknownSalesMultiDMA(this.dmas.map(dma => dma.id));
            this.hasUnknown = false;
            this.unknownSalesSubscription = combineLatest(this.unknownYearlySales.map(ds => ds.loaded))
                .pipe(skipWhile(list => !list.every(bool => bool)), take(1)).subscribe(() => {
                    const dateRange = this.filterStateService.getFilterValue(FilterName.dateRangeType);
                    const property = dateRange === "rolling" || dateRange === "ytd" ? "salesMonthly" : "sales";

                    this.unknownYearlySales.forEach(dmaSales => {
                        const sale = dmaSales[property];
                        for (const date in sale) {
                            if (!this.hasUnknown && sale[date] > 0) {
                                this.hasUnknown = true;
                                return;
                            }
                        }
                    });

                    for (const makes in this.unknownYearlySales[0].makes) {
                        for (const sales in this.unknownYearlySales[0].makes[makes].salesMonthly) {
                            if (typeof this.unknownYearlySales[0].makes[makes].salesMonthly[sales] === "string") {
                                this.unknownYearlySales[0].makes[makes].salesMonthly[sales] = "n/a";
                                this.unknownYearlySales[0].makes[makes].sharesMonthly[sales] = "n/a";
                            } else if (typeof this.unknownYearlySales[0].makes[makes].sales[sales] === "string") {
                                this.unknownYearlySales[0].makes[makes].sales[sales] = "n/a";
                                this.unknownYearlySales[0].makes[makes].shares[sales] = "n/a";
                            }
                        }
                    }
                });
        }
    }

    updateToggleValue(state: IFilterToggleState): void {
        if (!state) {
            return;
        }
        this.filterStateService.setFilterValue(state.name, state.value);
    }

    resultsPanelScrolled(event: MouseEvent): void {
        const scrollTop = (event.target as HTMLElement).scrollTop;
        this.scrolled = (scrollTop > 0);
    }

    updateSpotlightedDealer(dealer: Dealer): void {
        this.spotlightedDealer = dealer;
        if (this.spotlightedDealer) {
            this.scrollToSpotlightedDealer();
        }
    }

    scrollToSpotlightedDealer(): void {
        if (this.dealerResults) {
            this.dealerResults.changes.pipe(take(1)).subscribe((changes) => { // wait until the dealer results have finished rendering
                if (this.spotlightedDealer && changes.length) {
                    const elem = changes.find(ref => ref.nativeElement.id === `${this.spotlightedDealer.dealer_id}-results-expandable-btn`);
                    if (elem) {
                        setTimeout(() => {
                            elem.nativeElement.scrollIntoView(); // scroll spotlighted dealer results into view
                        }, 200);
                    }
                }
            });
        }
    }

    isDealerOpen(dealer: Dealer): boolean {
        if (this.dealers && this.dealers.length === 1) { // if only 1 dealer in results panel, then open it by default
            return true;
        }
        if (this.spotlightedDealer) {
            return dealer.dealer_id === this.spotlightedDealer.dealer_id; // if dealer is the spotlighted dealer, then open by default
        }
        return false;
    }
}
